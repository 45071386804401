import { GST } from "@/contains/contants";

export const PriceSummary = ({
  priceSummary
}) => {
  return (
    <div className="listingSection__wrap">
      {/* PRICE */}
      <div className="flex justify-between">
        <span className="text-2xl font-semibold">
            Price summary
        </span>

      </div>

      {/* SUM */}
      <div className="flex flex-col space-y-4 ">
        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
          <span>Subtotal</span>
          <span>{priceSummary?.totalRent}</span>
        </div>
        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
          <span>GST({GST})</span>
          <span>{priceSummary?.serviceCharge}</span>
        </div>
        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
          <span>Discount</span>
          <span>{priceSummary?.discount}</span>
        </div>

        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="flex justify-between font-semibold">
          <span>Total</span>
          <span>{priceSummary?.payableRent}</span>
        </div>
      </div>
    </div>
  );
};
