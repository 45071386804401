import { FileStatus } from "@/generated/zeus";

export const aspectRatioMap = {
  "Beyond long vertical": {
    "min": 0,
    "max": 0.43
  },
  "Long Vertical": {
    "min": 0.43,
    "max": 0.56
  },
  "Portrait": {
    "min": 0.56,
    "max": 0.8
  },
  "Square": {
    "min": 0.8,
    "max": 1.25
  },
  "Wide": {
    "min": 1.25,
    "max": 2.33
  },
  "Ultrawide": {
    "min": 2.33,
    "max": 3.6
  },
  "Beyond ultrawide": {
    "min": 3.6,
    "max": Infinity
  }
}

export type AspectType = keyof typeof aspectRatioMap;

export type Inputs = {
  gstin: string,
  phoneNumber?: string,
  name?: string,
  email?: string;
  additionalInformation?: string,
  assets: string[]
};

export type AssetFile = {
  name:string,
  type:string,
  id:string,
  size:number,
  status?:FileStatus|null,
  url:string,
  meta:{
    duration?:number,
    width:number,
    height:number,
  }
  aspectType?: AspectType
}