import React from 'react';
import { ButtonProps } from '@/shared/Button/Button';
import ButtonPrimary from '@/shared/Button/ButtonPrimary';

export default function LoadingButton(props:ButtonProps& { btnType?:"primary"}) {

  const {onClick} = props;
  const [loader,setLoader] = React.useState(false);

  async function handleClick(){
    setLoader(true)
    await (onClick?.() as Promise<void>)
      .finally(()=>{
        setLoader(false)
      });
  }

  return (
    <>
      <ButtonPrimary {...props} onClick={handleClick}  loading={loader}/>
    </>
  )
}
