import MapMarker from "@/components/AnyReactComponent/MapMarker";
// import Heading2 from "@/components/Heading/Heading2";
import StayCardH from "@/components/StayCardH/StayCardH";
import { GMAP_API_KEY } from "@/contains/contants";
import GoogleMapReact, { Bounds } from "google-map-react";
import React, { FC, useEffect, useState } from "react";
// import { useLocation, useParams } from "react-router-dom";
import ButtonClose from "@/shared/ButtonClose/ButtonClose";
import Pagination from "@/shared/Pagination/Pagination";
import TabFilters from "./TabFilters";
import { useCartContext } from "@/context/CartContext";
import ButtonSecondary from "@/shared/Button/ButtonSecondary";
import Loader from "@/shared/FullScreenLoader/Loading";
import { AdboardFragment } from "@/hooks/useAdboards";
import Box from "@/components/Box";
import { Dialog } from "@/components/Dialog/Dialog";
import { Filter } from "lucide-react";
import TabFiltersFull from "./TabFiltersFull";
import Checkbox from "@/shared/Checkbox/Checkbox";

export interface SectionGridHasMapProps {
  adboards : AdboardFragment[],
  loading:boolean,
  hasSearchChanged?: boolean;
  onMapDragged?: (bounds: Bounds) => void;
}

const SectionGridHasMap: FC<SectionGridHasMapProps> = ({adboards,onMapDragged,loading,hasSearchChanged}) => {
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const mapsRef = React.useRef<any>(null);
  const mapRef = React.useRef<any>(null);
  // const markerClustererRef = useRef<MarkerClusterer | null>(null);
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);
  const [listings,setListings] = React.useState<AdboardFragment[]>([]);
  const [mapDragged, setMapDragged] = useState(false);
  const [isDragSearchAllowed, setIsDragSearchAllowed] = useState(true);  
  const {addToCart,isInCart} = useCartContext();

  useEffect(()=>{
    // setListings([]);
    if(adboards){
      setListings(adboards)
    }
    if(!mapDragged){
      resetBounds() 
    }
  },[adboards])

  useEffect(()=>{
    resetBounds()
  },[hasSearchChanged])

  // const searchLocation = new URLSearchParams(location.search).get('location')||locationParam||"All"
  // const {isInCart} = useCartContext()
  
  function selectAll(){
    for(let i = 0; i < listings.length; i++){
      addToCart(listings[i].id)
    }
  }

  function resetBounds(){
    if(!mapsRef.current || !mapRef.current) return;
    const bounds = new mapsRef.current.LatLngBounds();
    
    for(const item of adboards){ 
      for(const player of item.players||[]){
        if(!player.map) continue
        bounds.extend(new mapsRef.current.LatLng(player.map.lat, player.map.lng),);
      }
    }

    mapRef.current.fitBounds(bounds);
  }

  function handleDragEnd(bounds){
  
    setTimeout(()=>{
      if(bounds && isDragSearchAllowed){
        onMapDragged?.(bounds)
      }
    })
  }

  function handleDragChange(value){
    setIsDragSearchAllowed(value)
  }

  return (
    <div className="relative">
      <div className="lg:p-8 py-4">
        <div className="hidden lg:flex">
          <Box classname="flex justify-between">
            <div className="flex gap-2 items-center justify-between w-full"> 
              <div className="hidden lg:block">
                <TabFilters/>
              </div>
              <Dialog title={"Filters"}>
                <Dialog.Trigger>
                  <ButtonSecondary className="flex gap-2 items-center">
                    <Filter size={14}></Filter>
                  Filters
                  </ButtonSecondary>
                </Dialog.Trigger>
                <Dialog.Body>
                  <TabFiltersFull/>
                </Dialog.Body>
              </Dialog>
            </div>
          </Box>
        </div>
        <div className="lg:hidden">
          <Dialog title={"Filters"}>
            <Dialog.Trigger>
              <ButtonSecondary className="flex gap-2 items-center">
                <Filter size={14}></Filter>
                  Filters
              </ButtonSecondary>
            </Dialog.Trigger>
            <Dialog.Body>
              <TabFiltersFull/>
            </Dialog.Body>
          </Dialog>
        </div>
      </div>
      <div className="relative flex min-h-screen">
        {/* CARDSSSS */}
        <div className="min-h-screen w-full xl:w-[780px] 2xl:w-[880px] flex-shrink-0 xl:px-8 ">
          <div className="grid grid-cols-1 gap-8 relative">
           
            {loading && adboards.length==0 && <Loader className="mt-40 opacity-70"/>}

            {!loading && !adboards.length &&<>
              <div>
                There are no listings available for your search.
              </div>
            </>}

            {<>
              {listings.length == 0 ? 
                <></>:
                <div className="relative">
                Showing {listings.length} adboards with {listings.reduce((acc,cur)=>acc+(cur.players.length||0),0)} screens. {loading && <Loader>Searching...</Loader>}
                </div>
              } 
            </>}
            
            <div className="flex flex-col gap-4">
              {listings.sort((a,b)=>{
                return (b.players.length||0) - (a.players.length||0)
              }).map((item) => (
                <div
                  key={item.id}
                  onMouseEnter={() => {
                    // setCenter(item.id);
                    setCurrentHoverID(() => item.id);
                  }}
                  onMouseLeave={() => setCurrentHoverID(() => -1)}
                >
                  <StayCardH data={item} />
                </div>
              ))}
            </div>
          </div>
          <div className="flex mt-16 justify-center items-center">
            <Pagination />
          </div>
        </div>

        {!showFullMapFixed && (
          <div
            className="flex xl:hidden items-center justify-center fixed bottom-8 left-1/2 transform -translate-x-1/2 px-6 py-2 bg-neutral-900 text-white shadow-2xl rounded-full z-30  space-x-3 text-sm cursor-pointer"
            onClick={() => setShowFullMapFixed(true)}
          >
            <i className="text-lg las la-map"></i>
            <span>Show map</span>
          </div>
        )}

        {/* MAPPPPP */}
        <div
          className={`xl:flex-grow xl:static xl:block ${
            showFullMapFixed ? "fixed inset-0 z-50" : "hidden"
          }`}
        >
          {showFullMapFixed && (
            <ButtonClose
              onClick={() => setShowFullMapFixed(false)}
              className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
            />
          )}

          <div className="fixed xl:sticky top-0 xl:top-[88px] left-0 w-full h-full xl:h-[calc(100vh-150px)] rounded-md overflow-hidden">
            <div className="absolute bottom-5 left-3 lg:bottom-auto lg:top-2.5 lg:left-1/2 transform lg:-translate-x-1/2 py-2 px-4 z-10 rounded-2xl min-w-max flex items-center gap-2">
              <ButtonSecondary
                onClick={selectAll}
              >Select all on map</ButtonSecondary>
              <ButtonSecondary>
                <Checkbox name="searchWithDrag" onChange={handleDragChange} checked={isDragSearchAllowed} label="Search on move"></Checkbox>
              </ButtonSecondary>
              
            </div>
          
            <GoogleMapReact
              resetBoundsOnResize
              defaultZoom={8}
              defaultCenter={listings[0]?.players[0]?.map||{
                lat: 28.5478273,
                lng: 77.1844975
              }}
              onDragEnd={()=>setMapDragged(true)}
              onChange={({bounds})=>{
                if(mapDragged){ 
                  handleDragEnd(bounds)
                }
              }}
              bootstrapURLKeys={{
                key: GMAP_API_KEY,
              }}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => {
                mapsRef.current = maps;
                mapRef.current = map;
                resetBounds();
              }}
            >
              {listings.map((item) => {
                return item.players.map((player,i)=>{
                  if(!player.map){
                    return null;
                  }
                  return <MapMarker
                    onHover={(hoveredItem)=>setCurrentHoverID(hoveredItem?hoveredItem.id:-1)}
                    isSelected={isInCart(item.id)}
                    isHovered={currentHoverID === item.id}
                    key={i}
                    lat={player.map.lat}
                    lng={player.map.lng}
                    listing={item}
                  />
                })
              })}
            </GoogleMapReact>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionGridHasMap;
