import Heading from "@/components/Heading/Heading";
import React, { FC } from "react";
import NcImage from "@/shared/NcImage/NcImage";
import HIW1img from "@/images/HIW2-1.png";
import HIW2img from "@/images/HIW2-2.png";
import HIW3img from "@/images/HIW2-3.png";
import VectorImg from "@/images/VectorHIW.svg";

export interface SectionHowItWorkProps {
  className?: string;
  data?: {
    id: number;
    title: string;
    desc: string;
    img: string;
    imgDark?: string;
  }[];
}

const data = {
  "title": "Quick Onboarding and How It Works Guide",
  "subtitle": "Transform Your Advertising in Three Simple Steps",
  "steps": [
    {
      "step": 1,
      img: HIW1img,
      "title": "Contact Us and Setup Your Adboards",
      "points": [
        "Reach out via contact form, call, or email.",
        "Sign the partnership agreement electronically.",
        "Coordinate hardware installation with our team."
      ]
    },
    {
      "step": 2,
      img: HIW2img,
      "title": "Manage Your Content",
      "points": [
        "Set up the content management system and user accounts.",
        "Upload ad content with our guidance.",
        "Organize and schedule your ads effortlessly."
      ]
    },
    {
      "step": 3,
      img: HIW3img,
      "title": "Monitor and Optimize",
      "points": [
        "Monitor billboards with live camera streaming.",
        "Access advanced analytics for performance tracking.",
        "Receive ongoing support and regular check-ins."
      ]
    }
  ],
  "closing": "Get started today and transform your advertising with our seamless digital signage solutions."
}

const SectionHowAdboardWork: FC<SectionHowItWorkProps> = ({
  className = "",
}) => {
  return (
    <div
      className={`nc-SectionHowItWork py-16 ${className}`}
      data-nc-id="SectionHowItWork"
    >
      <Heading isCenter desc="Easy Setup, Seamless Management, and Real-Time Monitoring">
      Transform Your Advertising in Three Simple Steps
      </Heading>
      <div className="mt-20 relative grid md:grid-cols-3 gap-2">
        <img
          className="hidden md:block absolute inset-x-0 top-10"
          src={VectorImg}
          alt=""
        />
        {data.steps.map((item) => (
          <div
            key={item.step}
            className="relative flex flex-col items-center max-w-xs mx-auto"
          >
            {(
              <NcImage
                containerClassName="mb-8 max-w-[200px] mx-auto"
                src={item.img}
              />
            )}
            <div className="text-center">
              <h3 className="text-xl font-semibold">{item.title}</h3>
              <span className="block mt-5 text-neutral-500 dark:text-neutral-400 text-left">
                <ul className="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
                  {item.points.map((point, index) => (
                    <li key={index}>{point}</li>
                  ))}
                </ul>
                {/* {item.points} */}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionHowAdboardWork;
