import React, { ReactElement, useEffect, useState } from 'react';
import { apiFetch } from '@/hooks/fetch';
import { useFirebaseContext } from './FirebaseContext';
import { NotificationChannel } from '@/generated/zeus';
import { requestLocationPermission } from '@/lib/push-notifications';
import { mutation } from '@/hooks/useZeus';
import { getLocation } from '@/lib/utils';

export interface APIResponse {
  config: Config
  user: User
  memberships: Membership[]
}

export type Config =  {
  apiUrl: string
  adminUrl: string
  pisignageUrl: string
  pisignageToken: string
  env: string
  gst: string
  cgst: string
  sgst: string
  paymentGateway: string
}|{
  apiUrl: string
  env: string
}

export interface User {
  stripe: Stripe
  refunds: any[]
  id: string
  uid: string
  email: string
  image: string
  phoneNumber: string
  displayName: string
  otherDetails: OtherDetails
  roles: any[]
  sharedBookingIds: string[]
  disclaimerAccepted: boolean
  notificationPreference?:{
    channels:NotificationChannel[]|undefined
  }
}

export interface Stripe {
  customerId: string
}

export interface OtherDetails {
  uid: string
  email: string
  emailVerified: boolean
  displayName: string
  photoURL: string
  phoneNumber: string
  disabled: boolean
  metadata: Metadata
  customClaims: CustomClaims
  tokensValidAfterTime: string
  providerData: ProviderDaum[]
}

export interface Metadata {
  lastSignInTime: string
  creationTime: string
  lastRefreshTime: string
}

export interface CustomClaims {
  roles: any[]
}

export interface ProviderDaum {
  uid: string
  displayName?: string
  email?: string
  photoURL?: string
  providerId: string
  phoneNumber?: string
}

export interface Membership {
  id: string
  createdAt: string
  userId: string
  workspaceId: string
  roleId: string
  workspace: Workspace
  role: Role
}

export interface Workspace {
  id: string
  type: string
  name: string
  key: string
  userIds: any[]
  createdAt: string
  updatedAt: string
}

export interface Role {
  id: string
  name: string
  permissions: string[]
  workspaceId: any
  createdAt: string
  updatedAt: string
}

export interface Data {
  config?:Config,
  flags:{
    paymentGateway: "razorpay"|"instamojo"|"stripe"
  }
  loading:boolean
  user?:User,
  position?:GeolocationPosition,
  location?:{
    address:string 
    city:string
    state:string
    country:string
  }
}

const defaults:Data={
  loading:true,
  flags:{
    paymentGateway:"instamojo"
  }  
}

export const UserContext = React.createContext<Data>(defaults);

export const UserProvider = ({ children }: { children: ReactElement }) => {
  const {token } = useFirebaseContext();
  const [data,setData] = useState<Data>(defaults);
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    requestLocationPermission().then(position=>{
      getLocation(position.coords.latitude,position.coords.longitude)
        .then(location=>{
          setData(prevData=>{
            return {
              ...prevData,
              position,
              location
            }
          })
        })
        .catch((err)=>{
          console.error(err);
          setData(prevData=>{
            return {
              ...prevData,
              position
            }
          })
        })
    });
  },[])

  useEffect(() => { 
    
    if(!token){
      return;
    } 
    setLoading(true);
    apiFetch(`/api/user`,{
      headers:{
        'Authorization':`Bearer ${token}`
      }
    })
      .then(res=>res.json())
      .then((res:APIResponse)=>{
        setData(prevData=>{
          return {
            ...prevData,
            config:res.config,
            user:res.user,
          }
        })
      }).finally(()=>{
        setLoading(false);
      });
  }, [token]);

  useEffect(()=>{
    if(data.position?.coords.latitude && data.user){
      mutation({
        createOneUserPosition:[{
          data:{
            user:{
              connect:{
                id:data.user.id
              }
            }, 
            coords: {
              latitude: data.position.coords.latitude,
              longitude: data.position.coords.longitude,
              accuracy: data.position.coords.accuracy,
            },
            location:{
              address: data.location?.address,
              city :data.location?.city,
              state :data.location?.state,
              country :data.location?.country,
            },
            timestamp: new Date(data.position.timestamp),
          },

        },{
          id:true
        }]
      })
    }
  },[data])
 
  return <UserContext.Provider value={{
    config:{
      ...data.config,
      apiUrl:import.meta.env.VITE_PUBLIC_API_URL||"",
      env:import.meta.env.VITE_PUBLIC_NODE_ENV != 'development' ? 'production' : 'development'
    },
    loading,
    location:data.location,
    position:data.position,
    user:data.user,
    flags:{
      paymentGateway:"instamojo"
    },
  }}>
    {children}
  </UserContext.Provider>
}
