import { AssetCard } from "@/containers/MediaAssetsPage/AssetCard";
import { useMediaAssets } from "@/containers/MediaAssetsPage/useMediaAssets";
import { useEffect } from "react";
import Loader from "@/shared/FullScreenLoader/Loading";
import { AssetFile as AssetFile } from "./types";
import { toast } from "react-toastify";
import { useFileUploader } from "@/hooks/useFileUploader";
import { mutation } from "@/hooks/useZeus";
import LoadingButton from "@/components/LoadingButton";
import { Card } from "@/components/ui/card";
import Select from "@/shared/Select/Select";
import Input from "@/shared/Input/Input";
import ButtonPrimary from "@/shared/Button/ButtonPrimary";
import { useForm } from "react-hook-form";

export function FileManager({onFileSelect,close}: {
  filterFn?:(file:AssetFile)=>boolean, 
  hideUploadBtn?:boolean,
  close?:()=>void,
  onFileSelect?: (file: AssetFile) => void 
}) {
  const {uploadFile} = useFileUploader()
  const {getFiles,data,loading,refetch,fetchMore} = useMediaAssets();
  const {register,getValues} = useForm<{
    search:string
    type:string
  }>();
  const uploadedFiles = data?.files;

  useEffect(()=>{
    getFiles();
  },[])

  async function handleDelete({id}:AssetFile){
    await mutation({
      deleteOneFile:[{
        where:{
          id
        }
      },{
        id:true
      }]
    })
    refetch()
  }

  async function handleFetchMore(){
    await fetchMore({
      variables:{
        skip:data?.files.length 
      },
      updateQuery(previousData, { fetchMoreResult,variables:{skip}}) {
        const updatedFeed = previousData.files.slice(0);
        for (let i = 0; i < fetchMoreResult.files.length; ++i) {
          updatedFeed[skip + i] = fetchMoreResult.files[i];
        }
        return { ...previousData, files: updatedFeed };
      },
    })
  }

  function renderTable(){
    const assets = uploadedFiles||[];

    if(!loading && !assets.length){
      return "No files found";
    }

    return  <div className="hidden lg:grid grid-col-1 gap-4 px-2">
      
      {assets.map(item => {
        return <AssetCard key={item.url} onDelete={handleDelete} data={item} onCardSelect={()=>deployAsset(item)} />
      })}
      {assets.length!=0 && 
      <div className="w-full text-center">
        <LoadingButton onClick={handleFetchMore}>Load more</LoadingButton>
      </div>
      }
    </div>
  }

  async function deployAsset(item:AssetFile){
    await onFileSelect?.(item);
    close?.();
  }

  function renderMobileView(){
    return <div className="lg:hidden">

      <div className="w-full max-w-md bg-white rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
            
        <div className="flow-root">
          <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700 gap-4 flex flex-col">
            {uploadedFiles?.map(item => {
              return <AssetCard key={item.url} data={item} onCardSelect={()=>deployAsset(item)} />
            })}
          </ul>
        </div>
      </div>

    </div>
  } 

  async function handleFileSelect(e:React.ChangeEvent<HTMLInputElement>){
    const file = e.target.files?.[0];
    if(!file){
      return;
    }
    const toastid = toast.loading('Uploading file...');
    await uploadFile(file,function progress(value){
      toast.update(toastid, { render: "Uploading file...", type: "info", progress:value/100});  
    });
    toast.update(toastid, { render: "File uploaded successfully.", type: "success", isLoading: false,autoClose:2000 });
    refetch();
  }

  function handleFilterApply(){
    const {search,type} = getValues();
    refetch({
      search,
      type
    })
  }

  return (
    <div className="">
      <div className="relative overflow-x-auto pb-4 h-full">
        <div className="border-dashed border flex items-center justify-center rounded-3xl lg:py-16 py-8 mb-4 mx-2 border-gray-400">
          <div>
            <label htmlFor={"file-upload-"} className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 rounded-3xl">Upload file
            </label>
            <input type="file" accept='image/*, video/*' id={"file-upload-"} className='hidden' onChange={handleFileSelect}/>
          </div>
        </div>
        <div className="p-2">

          <Card className="p-4 rounded-3xl flex gap-2 items-center">
            {/* <div className="text-lg font-semibold text-gray-900 dark:text-white">Filters</div> */}
            <div>
              <Input {...register('search')} placeholder="Search"></Input>
            </div>
            <div>
              <Select {...register('type')}>
                <option value="">Filter by type</option>
                <option value="image">Image</option>
                <option value="video">Video</option>
              </Select>
            </div>
            <div>
              <ButtonPrimary onClick={handleFilterApply}>Apply</ButtonPrimary>
            </div>
          </Card>
        </div>
        <div className="relative">
          {loading && <div className="p-10 relative"><Loader /></div>}
          {renderTable()}
          {renderMobileView()}
        </div>
         
      </div>
    </div>
  );
}

export default FileManager;
