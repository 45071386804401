import BgGlassmorphism from "@/components/BgGlassmorphism/BgGlassmorphism";
import SectionSubscribe2 from "@/components/SectionSubscribe2/SectionSubscribe2";
import { FC } from "react";
import { Helmet } from "react-helmet";
import SectionFounder from "./SectionFounder";
import SectionHero from "./SectionHero";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>About | AdBoard Booking</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero/>

        <SectionFounder />
        
        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default PageAbout;
