import BackgroundSection from "@/components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "@/components/BgGlassmorphism/BgGlassmorphism";
import SectionClientSay from "@/components/SectionClientSay/SectionClientSay";
import SectionHero2 from "@/components/SectionHero/SectionHero2";
import SectionHowItWork from "@/components/SectionHowItWork/SectionHowItWork";
import SectionOurFeatures from "@/components/SectionOurFeatures/SectionOurFeatures";
import SectionSliderNewCategories from "@/components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "@/components/SectionSubscribe2/SectionSubscribe2";

function PageHome() {
  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-4 mb-24 lg:space-y-8 lg:mb-28">
        {/* SECTION HERO */}
        <SectionHero2 className="pt-10 lg:pt-16 lg:pb-16" />

        {/* SECTION 1 */}
        <SectionSliderNewCategories
          uniqueClassName="PageHome_s1"
          categoryCardType="card5"
        />

        {/* SECTION2 */}
        <SectionOurFeatures />

        {/* SECTION */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionGridFeaturePlaces />
        </div> */}

        {/* SECTION */}
        <SectionHowItWork />

        {/* SECTION 1 */}
        {/* <div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionSliderNewCategories
            categories={DEMO_CATS_2}
            categoryCardType="card4"
            itemPerRow={4}
            heading="Suggestions for discovery"
            subHeading="Popular places to stay that Adboard Booking recommends for you"
            sliderStyle="style2"
            uniqueClassName="PageHome_s2"
          />
        </div> */}

        {/* SECTION */}
        <SectionSubscribe2 />

        {/* SECTION */}
        {/* <div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionGridAuthorBox />
        </div> */}

        {/* SECTION */}
        {/* <SectionGridCategoryBox /> */}

        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          {/* <SectionBecomeAnAuthor /> */}
        </div>

        {/* SECTION 1 */}
        {/* <SectionSliderNewCategories
          heading="Explore by types of stays"
          subHeading="Explore houses based on 10 types of stays"
          categoryCardType="card5"
          itemPerRow={5}
          uniqueClassName="PageHome_s3"
        /> */}

        {/* SECTION */}
        {/* <SectionVideos /> */}

        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageHome_" />
        </div>
      </div>
    </div>
  );
}

export default PageHome;
