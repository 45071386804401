import { useEffect, useState } from "react";
import { Filters } from "./type";

export const XClear = () => {
  return (
    <span className="w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-3 w-3"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clipRule="evenodd"
        />
      </svg>
    </span>
  );
};

export const TabOnSale = ({ filters, onFiltersChange }: { filters: Filters, onFiltersChange: (filters: Filters) => void }) => {
  const [isOnSale, setIsOnSale] = useState(false);

  useEffect(() => {
    onFiltersChange({
      ...filters,
      thumbnailView: isOnSale
    })
  }, [isOnSale])

  return (
    <div
      className={`flex items-center justify-center px-4 h-auto text-sm rounded-full border focus:outline-none cursor-pointer transition-all ${isOnSale
        ? "border-primary-500 bg-primary-50 text-primary-700"
        : "border-neutral-300 dark:border-neutral-700"
      }`}
      onClick={() => setIsOnSale(!isOnSale)}
    >
      <span>Toggle view</span>
      {isOnSale && <XClear />}
    </div>
  );
};