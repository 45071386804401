import React, { FC } from "react";
import NcImage from "@/shared/NcImage/NcImage";
import rightImgDemo from "@/images/BecomeAnAuthorImg.png";
// import ButtonPrimary from "@/shared/Button/ButtonPrimary";
// import Logo from "@/shared/Logo/Logo";

export interface SectionBecomeAnAuthorProps {
  className?: string;
  rightImg?: string;
}

const SectionBecomeAnAuthor: FC<SectionBecomeAnAuthorProps> = ({
  className = "",
  rightImg = rightImgDemo,
}) => {
  return (
    <div
      className={`nc-SectionBecomeAnAuthor relative flex flex-col lg:flex-row items-center  ${className}`}
      data-nc-id="SectionBecomeAnAuthor"
    >
      <div className="p-6 max-w-4xl bg-white rounded-md">
        <h2 className="text-3xl font-bold text-gray-800 mb-6">Why Choose Adboard Booking?</h2>

        {/* Advertisers Section */}
        <div className="mb-8">
          <h3 className="text-2xl font-semibold text-gray-700 mb-4">For Advertisers</h3>
          <p className="text-gray-600 mb-6">
          Adboard Booking provides advertisers with a simple and secure platform to find, book, and
          monitor billboard ad spaces. Reach your audience effectively while staying in control of
          your campaign’s performance.
          </p>
          <ul className="space-y-4 text-left">
            <li className="flex items-start">
              <span className="text-blue-500 font-bold mr-2">✔</span>
              <span>
                <strong>Real-Time Monitoring:</strong> Track your campaign through live camera feeds and performance metrics.
              </span>
            </li>
            <li className="flex items-start">
              <span className="text-blue-500 font-bold mr-2">✔</span>
              <span>
                <strong>Wide Selection:</strong> Access a variety of billboards across multiple cities and demographics.
              </span>
            </li>
            <li className="flex items-start">
              <span className="text-blue-500 font-bold mr-2">✔</span>
              <span>
                <strong>Seamless Booking:</strong> Book spaces quickly with our intuitive platform and secure payments.
              </span>
            </li>
          </ul>
        </div>

        {/* Publishers Section */}
        <div>
          <h3 className="text-2xl font-semibold text-gray-700 mb-4">For Publishers</h3>
          <p className="text-gray-600 mb-6">
          Maximize your revenue by listing your billboards on Adboard Booking. Attract a wide range
          of advertisers while we handle payments, availability, and campaign management for you.
          </p>
          <ul className="space-y-4 text-left">
            <li className="flex items-start">
              <span className="text-green-500 font-bold mr-2">✔</span>
              <span>
                <strong>Verified Advertisers:</strong> Connect with trusted advertisers to ensure smooth bookings.
              </span>
            </li>
            <li className="flex items-start">
              <span className="text-green-500 font-bold mr-2">✔</span>
              <span>
                <strong>Easy Management:</strong> Manage availability, bookings, and payments effortlessly from your dashboard.
              </span>
            </li>
        
            <li className="flex items-start">
              <span className="text-green-500 font-bold mr-2">✔</span>
              <span>
                <strong>Upfront Payments:</strong> We take payments from advertisers upfront, ensuring you receive payments at the end of the month or after the campaign completion without delays.
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
    </div>
  );
};

export default SectionBecomeAnAuthor;
