
import React, { useEffect } from "react";
import { apiFetch } from "./fetch";

export function useCustomFetch<T>(url,{
  lazy
}={
  lazy:false
}){

  const [data,setData]=  React.useState<T|null>(null);
  const [error,setError]=  React.useState(null);
  const [loading,setLoading]=  React.useState(true);

  function fetch(){
    apiFetch("/api"+url)
      .then(res=>res.json())
      .then((res)=>{
        setData(res);
      })
      .catch((err)=>{
        setError(err);
      })
      .finally(()=>{
        setLoading(false);
      })
  }

  useEffect(()=>{
    if(!lazy){
      fetch();
    }
  },[])

  return {
    fetch,
    error,
    data,
    loading,
  }
}