import React, { FC } from "react";

export interface LocationMarkerProps {
  lat: number;
  lng: number;
  isHovered?: boolean;
}

const LocationMarker: FC<LocationMarkerProps> = ({
  isHovered
}) => {
  return (
    <div className={(isHovered?'text-black scale-150 -translate-x-8 -translate-y-4':"text-primary-500")+ " transition-all translate-x-[-22px] translate-y-[-22px] " }>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-8 w-8"
        viewBox="0 0 20 20"
        fill="currentColor"
        stroke="white"      
        stroke-width="1"  
      >
        <path
          fillRule="evenodd"
          d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  );
};

export default LocationMarker;
