import React from 'react';
import { Button } from "@/components/ui/button";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import BgGlassmorphism from '@/components/BgGlassmorphism/BgGlassmorphism';
import SectionHero from '../PageHome/SectionHero';
import { Link } from 'react-router-dom';

const MediaPlayer = () => {
  return (
    <div className="min-h-screen bg-gray-100 text-gray-800">
      {/* Header Section */}
      <header className="bg-primary-6000 py-10 text-white">
        <div className="container mx-auto px-4 text-center">
          <h1 className="text-4xl font-bold">AdBoard Booking Media Player</h1>
          <p className="mt-4 text-lg">
            Elevate your digital signage with our cutting-edge media player, designed for seamless content management and multi-screen compatibility.
          </p>
          <p className="mt-4 text-md">
            From retail outlets to corporate offices, our media player empowers you to deliver dynamic, engaging content that captivates your audience.
          </p>
        </div>
      </header>

      {/* Features Section */}
      <section className="py-10">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center">Features</h2>
          <p className="mt-4 text-center text-lg">
            Our media player is packed with advanced features to meet all your digital signage needs.
          </p>
          <div className="mt-8 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4">
            {[
              { title: "Supports Various Media Formats", description: "Play videos, images, and HTML5 content seamlessly across all screens." },
              { title: "Remote Content Management", description: "Update and schedule content in real time from anywhere." },
              { title: "Real-Time Updates", description: "Make instant changes to your screens to reflect new offers or announcements." },
              { title: "Multi-Screen Compatibility", description: "Works with LED displays, video walls, kiosks, and more." },
              { title: "Interactive Templates", description: "Use customizable templates and widgets to create stunning visuals." },
              { title: "Corporate TV Solutions", description: "Streamline internal communication with corporate IPTV features." },
              { title: "Flexible Scheduling", description: "Plan your content for specific dates, times, or events." },
              { title: "Multi-Location Support", description: "Manage signage across multiple locations from a single dashboard." },
            ].map((feature, index) => (
              <Card key={index} className="shadow-lg">
                <CardHeader>
                  <CardTitle className="text-center text-lg font-semibold">{feature.title}</CardTitle>
                </CardHeader>
                <CardContent className="p-4 text-center">
                  <p className="text-sm">{feature.description}</p>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </section>

      {/* Industries Section */}
      <section className="bg-gray-200 py-10">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center">Industries Served</h2>
          <p className="mt-4 text-center text-lg">
            Our versatile media player is tailored to meet the needs of various industries.
          </p>
          <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {[
              { name: "Retail", description: "Boost sales with dynamic advertisements and promotions." },
              { name: "Banking", description: "Deliver real-time updates on services, rates, and offers." },
              { name: "Education", description: "Engage students with interactive content in classrooms and campuses." },
              { name: "Corporate Offices", description: "Enhance internal communication and showcase company achievements." },
              { name: "Hospitality", description: "Provide guests with event details, promotions, and local recommendations." },
              { name: "Sports & Fitness", description: "Keep members motivated with announcements, tips, and offers." },
              { name: "Gas Stations", description: "Advertise deals on fuel, snacks, and services directly at the pump." },
              { name: "Event Venues", description: "Display schedules, sponsors, and live updates during events." },
            ].map((industry, index) => (
              <Card key={index} className="shadow-lg">
                <CardHeader>
                  <CardTitle className="text-center text-lg font-semibold">{industry.name}</CardTitle>
                </CardHeader>
                <CardContent className="p-4 text-center">
                  <p className="text-sm">{industry.description}</p>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </section>

      {/* Get Started Section */}
      <section className="py-10">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold">Get Started Today</h2>
          <p className="mt-4 text-lg">
            Ready to revolutionize your digital signage? Partner with us and experience the difference.
          </p>
          <p className="mt-4 text-md">
            Contact our team to explore how our media player can cater to your business needs. We’ll guide you through the setup and ensure a seamless integration.
          </p>
          <div className="mt-6">
            <Link to="/contact">
              <Button className="bg-blue-600 hover:bg-blue-700 px-6 py-3 text-white text-lg rounded-lg">
              Contact Us
              </Button>
            </Link>
          </div>
        </div>
      </section>
     
    </div>
  );
};

export default function MediaPlayerPage() {
  return  <div>
    <div className="nc-PageHome relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        {/* SECTION HERO */}
        <SectionHero/>

        <MediaPlayer/>
      </div>
    </div>
  </div>
}
