import BgGlassmorphism from "@/components/BgGlassmorphism/BgGlassmorphism";
import SectionSubscribe2 from "@/components/SectionSubscribe2/SectionSubscribe2";
import { FC } from "react";
import { Helmet } from "react-helmet";
import BookingListTiles from "./SectionGridFilterCard";

export interface ListingFlightsPageProps {
  className?: string;
}

const ListingFlightsPage: FC<ListingFlightsPageProps> = ({
  className = "",
}) => {
  return (
    <div
      className={`nc-ListingFlightsPage relative overflow-hidden ${className}`}
      data-nc-id="ListingFlightsPage"
    >
      <Helmet>
        <title>Bookings | Adboard Booking</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative pt-4">
        {/* SECTION HERO */}

        {/* Cards */}
        <BookingListTiles className="pb-24 lg:pb-28" />
     
        {/* SECTION */}
        <SectionSubscribe2 className="py-24 lg:py-28" />
      </div>
    </div>
  );
};

export default ListingFlightsPage;