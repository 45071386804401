import { useContext } from 'react'
import { UserContext } from '@/context/UserContext'
import { useFirebaseContext } from '@/context/FirebaseContext';

export default function useUserContext() {
  
  const context = useContext(UserContext);
  const {token} = useFirebaseContext();
  return {
    ...context,
    token: token,
    user:context?.user
  };
}

export function useFlags(){
  const context = useContext(UserContext);
  return {
    flags: context?.flags||undefined
  }
}
