import React from 'react'
import SocialsList from "@/shared/SocialsList/SocialsList";
import Label from "@/components/Label/Label";
import Input from "@/shared/Input/Input";
import Textarea from "@/shared/Textarea/Textarea";
import ButtonPrimary from "@/shared/Button/ButtonPrimary";
import { toast } from 'react-toastify';
import { fireEvent } from '@/lib/utils';

const info = [
  // {
  //   title: "🗺 ADDRESS",
  //   desc: "Basement, 475, Sec 39, Gurugram",
  // },
  {
    title: "💌 EMAIL",
    desc: <a href="mailto:ankur.kus1@adboardbooking.com" target='_blank'>ankur.kus1@adboardbooking.com</a>,
  },
  {
    title: "☎ PHONE",
    desc: <a href="https://wa.me/9310854859" target='_blank'>+91-9310854859</a>,
  },
];

export default function ContactUs() {
  async function submitForm(e:any){
    
    e.preventDefault();
    const formData = new FormData(e.target);

    const details = {
      name:formData.get('name') as string,
      email:formData.get('email') as string,
      phone:formData.get('phone') as string,
      message:formData.get('message') as string,
    }

    if(!details.email || !details.name || !details.message){
      alert('Please enter all the details.')
      return;
    }

    fetch(import.meta.env.VITE_PUBLIC_API_URL+'/api/contact?name='+details.name+'&email='+details.email+'&message='+details.message+'&phone='+details.phone)
      .then(()=>{
        toast.success('Message sent successfully.')
        window.location.href="/contact/success"
      })
      .catch(()=>{
        toast.error('Something went wrong. Please try again later.')
      })
  }
  return (
    <div className="mb-24 lg:mb-32" id="contactUs">
      <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
      Contact
      </h2>
      <div className="container max-w-7xl mx-auto mb-10">
        <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
          <div className="max-w-sm space-y-8">
            {info.map((item, index) => (
              <div key={index}>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  {item.title}
                </h3>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                  {item.desc}
                </span>
              </div>
            ))}
            <div>
              <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
              🌏 SOCIALS
              </h3>
              <SocialsList className="mt-2" />
            </div>
          </div>
          <div>
            <form className="grid grid-cols-1 gap-6" onSubmit={submitForm}>
              <label className="block">
                <Label>Full name</Label>

                <Input
                  name="name"
                  placeholder="Name"
                  type="text"
                  className="mt-1"
                />
              </label>
              <label className="block">
                <Label>Email address</Label>

                <Input
                  name="email"
                  type="email"
                  placeholder="example@example.com"
                  className="mt-1"
                />
              </label>
              <label className="block">
                <Label>Phone</Label>

                <Input
                  name="phone"
                  type="text"
                  placeholder="+91-9999999999"
                  className="mt-1"
                />
              </label>
              <label className="block">
                <Label>Message</Label>

                <Textarea placeholder='Enter your message here' name="message" className="mt-1" rows={6} />
              </label>
              <div>
                <ButtonPrimary onClick={()=>{
                  fireEvent('contact.send.click')
                }} type="submit">Send Message</ButtonPrimary>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
