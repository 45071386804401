// import { Popover, Transition } from "@headlessui/react";
import Slider from "rc-slider";
import {useEffect, useState } from "react";
import ButtonPrimary from "@/shared/Button/ButtonPrimary";
import ButtonThird from "@/shared/Button/ButtonThird";
import Checkbox from "@/shared/Checkbox/Checkbox";
// import convertNumbThousand from "@/utils/convertNumbThousand";
import { useSearchParams } from "react-router-dom";
import { useAppContext } from "@/context/AppContext";
// import { XClear } from "@/shared/XClear";

export type TabFiltersType = {
  adBoardTypes: Record<string, boolean>,
  locationTypes: Record<string, boolean>,
  rangePrices: number[]
}

type TabFilterProps = {
  filters?: TabFiltersType,
  closeDialog?: () => void,
  onChange?: ({
    adBoardTypes,
    rangePrices
  }: TabFiltersType) => void
}

type SelectedTabs = Record<string, boolean>

const TabsTypeOfPlace = ({ 
  options,label,defaultSelectedTabs,onChange,
}: {
   options:{id:string,name:string}[],
   label?:string,
   onChange: (arg: SelectedTabs) => void, 
   defaultSelectedTabs: SelectedTabs }) => {

  const [selectedTabs, setSelectedTabs] = useState<SelectedTabs>(defaultSelectedTabs);

  useEffect(()=>{
    onChange(selectedTabs)
  },[selectedTabs])

  useEffect(()=>{
    setSelectedTabs(defaultSelectedTabs)
  },[defaultSelectedTabs])

  return (
    <div className="overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
      <div className="relative flex flex-col px-5 py-6 space-y-5">
        <span className="">
          {label}
        </span>
        {options?.map((item) => (
          <div key={item.name} className="">
            <Checkbox
              checked={!!selectedTabs[item.id]}
              onChange={(checked) => {
                setSelectedTabs({
                  ...selectedTabs,
                  [item.id]: checked
                })
              }}
              name={item.name}
              label={item.name}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const TabFiltersFull = ({
  closeDialog,
}: TabFilterProps) => {
  const [searchParams,setSearchParams] = useSearchParams();
  const {adBoardTypes} = useAppContext()
  const [range, setRange] = useState<number[]>([0, 100000]);
  
  // const optionMap = adBoardTypes.reduce<Record<string, any>>((a, b) => {
  //   a[b.id] = b
  //   return a;
  // }, {})||{}

  const selectedAdbaordTypes = searchParams.get('adBoardTypes')?.split(',')||[] 
  
  const [selectedScreens, setSelectedScreens] = useState<Record<string, boolean>>(
    selectedAdbaordTypes.reduce((a, b) => {
      a[b] = true
      return a  
    }
    ,{})
  );
  
  function handleClearClick(){
    setRange([0, 100000])
    setSelectedScreens({})
    setSearchParams({
      rangePrices:JSON.stringify([0, 0]), 
      adBoardTypes:""
    })
    closeDialog?.()
  }
  const renderTabsPriceRage = () => {

    return (
      <div className="overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
        <div className="relative flex flex-col px-5 py-6 space-y-8">
          <div className="space-y-5">
            <span className="font-medium">Price per day</span>
            <Slider
              range
              className="text-red-400"
              min={0}
              max={20000}
              value={range}
              allowCross={false}
              onChange={(e) => setRange(e as number[])}
            />
          </div>

          <div className="flex justify-between space-x-5">
            <div>
              <label
                htmlFor="minPrice"
                className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
              >
              Min price
              </label>
              <div className="mt-1 relative rounded-md">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-neutral-500 sm:text-sm">
                ₹
                  </span>
                </div>
                <input
                  type="text"
                  name="minPrice"
                  disabled
                  id="minPrice"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                  value={range[0]}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="maxPrice"
                className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
              >
              Max price
              </label>
              <div className="mt-1 relative rounded-md">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-neutral-500 sm:text-sm">
                ₹
                  </span>
                </div>
                <input
                  type="text"
                  disabled
                  name="maxPrice"
                  id="maxPrice"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                  value={range[1]}
                />
              </div>
            </div>
          </div>
        </div>
        
      </div>
  
    );
  };

  return (
    <div className="flex h-full flex-col lg:space-x-4">
      <div className="flex flex-1 overflow-auto lg:flex-row flex-col gap-4">
        <div className="w-full lg:mx-4 flex flex-col gap-2">
          <TabsTypeOfPlace
            label="Type of screen"
            defaultSelectedTabs={selectedScreens}
            onChange={(selectedTabs: any) => {  
              console.log(selectedTabs)
              setSelectedScreens(selectedTabs) 
            }}
            options = {adBoardTypes||[]} 
          />
          {renderTabsPriceRage()}    
        </div> 

      </div>
      <div className="p-5 mt-2 border-t border-gray-200 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
        <ButtonThird onClick={()=>{handleClearClick()}} sizeClass="px-4 py-2 sm:px-5">
          Clear
        </ButtonThird>
        <ButtonPrimary
          onClick={() => {
            setSearchParams({
              rangePrices:JSON.stringify(range), 
              adBoardTypes:Object.entries(selectedScreens).filter(item=>item[1] && item[0]).map(item=>item[0]).join(',')
            })
            closeDialog?.()
          }}
          sizeClass="px-4 py-2 sm:px-5"
        >
          Apply
        </ButtonPrimary>
      </div>  
    </div>
  );
};

export default TabFiltersFull;
