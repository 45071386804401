import BgGlassmorphism from "@/components/BgGlassmorphism/BgGlassmorphism";
import { FC } from "react";
import { Helmet } from "react-helmet";
import SectionGridFilterCard from "./SectionGridFilterCard";

export interface ListingFlightsPageProps {
  className?: string;
}

const MediaAssetsPage: FC<ListingFlightsPageProps> = ({
  className = "",
}) => {
  return (
    <div
      className={`nc-ListingFlightsPage relative overflow-hidden ${className}`}
      data-nc-id="ListingFlightsPage"
    >
      <Helmet>
        <title>Adbaord Booking || Assets</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative">
        {/* SECTION HERO */}

        {/* SECTION */}
        <SectionGridFilterCard className="pb-24 mt-8 lg:pb-28" />
      </div>
    </div>
  );
};

export default MediaAssetsPage;
