import useUserContext from "@/hooks/useUserContext";
import { toast } from "react-toastify";
import { PaymentGatewayName } from "@/generated/zeus";

type PaymentResponse={
  paymentGateway: PaymentGatewayName,
  orderId:string
  transactionId:string,
  signature?: string
}

export type Gateway = 'stripe' | 'razorpay'
type OrderSelector = {
  id:string
}

export function useGateway() {
  const { token } = useUserContext();

  async function initiatePayment(order: OrderSelector,bookingId:string) :Promise<void>{
    await initiateStripe(order,bookingId);
  }

  async function initiateStripe(order:OrderSelector,bookingId?:string){

    const url = `${import.meta.env.VITE_PUBLIC_API_URL}/api/stripe/create-checkout-session?bookingId=${bookingId}&orderId=${order?.id}`

    const res:{url:string} = await fetch(url,{
      method:"POST",
      headers:{
        'Content-Type': 'application/json',
        "Authorization":`Bearer ${token}`
      },
    }).then(res=>res.json())
      .catch(err=>{
        console.log(err)
        toast.error('Something went wrong')
        return Promise.reject(err)
      })
      
    window.location.href = res.url;

    return Promise.resolve({} as PaymentResponse);
  }

  async function initiateCampaignBooking(bookingIds?:string){

    const url = `${import.meta.env.VITE_PUBLIC_API_URL}/api/stripe/campaign-booking-session?bookingIds=${bookingIds}&pathName=${window.location.pathname}` 

    const res:{url:string} = await fetch(url,{
      method:"POST",
      headers:{
        'Content-Type': 'application/json',
        "Authorization":`Bearer ${token}`
      },
    }).then(res=>res.json())
      .catch(err=>{
        console.log(err)
        toast.error('Something went wrong')
        return Promise.reject(err)
      })

    if(res.url){
      window.location.href = res.url;
    }else{
      toast.error('Something went wrong')
    }

    return Promise.resolve({} as PaymentResponse);
  }
 
  return {
    initiateCampaignBooking,
    initiatePayment
  }
}