import { Helmet } from "react-helmet";

const FAQ = () => {
  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          {/* <Badge href="##" color="purple" name="Traveler" /> */}
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
           Frequently asked questions
          </h1>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
        </div>
      </header>
    );
  };

  const renderContent = () => {

    const faqs = [
      {
        "question": "What is AdBoardBooking.com?",
        "answer": "AdBoardBooking.com is a digital advertising platform that connects businesses with a network of digital adboards located in high-traffic areas. We offer a unique opportunity for businesses to enhance their visibility and reach a wider audience through strategic digital advertising."
      },
      {
        "question": "How does AdBoardBooking.com work?",
        "answer": "Businesses can browse our network of adboards located in various establishments like restaurants, salons, and clinics. Once you select an adboard, you can book advertising space and upload your advertisement content through our platform. We then display your ad on the selected adboard for the duration of your booking."
      },
      {
        "question": "What types of businesses can benefit from AdBoardBooking.com?",
        "answer": "Any business looking to increase its brand visibility can benefit from our services. This includes retail stores, restaurants, service providers, online businesses, and more. Our platform is particularly beneficial for local businesses aiming to target specific communities or areas."
      },
      {
        "question": "Can I choose specific locations for my advertisements?",
        "answer": "Yes, our platform allows you to select specific adboards based on their location. You can target your ads to specific neighborhoods, cities, or types of establishments to reach your desired audience."
      },
      {
        "question": "What are the advertising rates on AdBoardBooking.com?",
        "answer": "Our advertising rates vary depending on the location and size of the adboard, as well as the duration of the advertisement. Please visit our Pricing page for detailed information or contact us for a customized quote."
      },
      {
        "question": "How do I create an advertisement for the adboard?",
        "answer": "You can create your advertisement using any standard graphic design software. Ensure your ad meets the size and format specifications for the chosen adboard. If you need assistance, our team can help you design an effective ad."
      },
      {
        "question": "How long does it take for my ad to go live?",
        "answer": "Once you book an ad space and upload your content, your ad typically goes live within 24-48 hours, subject to content approval and adboard availability."
      },
      {
        "question": "Do you offer content management services for adboards?",
        "answer": "Yes, we offer content management services that allow you to schedule and manage your ads efficiently. This service is particularly useful for businesses that frequently update their advertisements."
      },
      {
        "question": "Can I track the performance of my advertisement?",
        "answer": "We provide basic analytics such as the number of views and engagement metrics for your advertisements. For more detailed analytics, please contact our support team."
      },
      {
        "question": "How can I get started with AdBoardBooking.com?",
        "answer": "Getting started is easy! Simply register on our website, browse available adboards, and book the space that suits your needs. If you need assistance, our customer support team is here to help."
      }
    ]
    
    return (
      <div
        id="single-entry-content"
        className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
      >
        {faqs.map((faq,i)=>{
          return <>
            <h4>
        Q{i}: {faq.question}
            </h4>
     
            <p>
              {faq.answer}
            </p>
          </>
        })}
        
      </div>
    );
  };

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>FAQ | AdBoard Booking</title>
      </Helmet>
      {renderHeader()}
     
      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
        {/* {renderTags()} */}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {/* {renderAuthor()} */}
        {/* {renderCommentForm()} */}
        {/* {renderCommentLists()} */}
      </div>
     
    </div>
  );
};

export default FAQ;
