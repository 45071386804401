import Heading2 from "@/components/Heading/Heading2";
import { useFileUploader } from "@/hooks/useFileUploader";
import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import ButtonPrimary from "@/shared/Button/ButtonPrimary";
import { FileData, useMediaAssets } from "./useMediaAssets";
import CheckboxFilter from "@/components/CheckboxFilter";
import UploadBlock from "@/components/UploadBlock";
import { AssetCard } from "@/containers/MediaAssetsPage/AssetCard";

import useUserContext from "@/hooks/useUserContext";
import { TabOnSale } from "./TabFilters";
import { Filters } from "./type";
import { FileStatus } from "@/generated/zeus";
import { AssetFile } from "../CheckOutPage/types";

export interface SectionGridFilterCardProps {
  className?: string;
  onCardSelect?:(file:AssetFile)=>void
}

const options: { value: FileStatus, name: string }[] = [{
  value: FileStatus.APPROVED,
  name: "Approved"
}, {
  value: FileStatus.REJECTED,
  name: "Rejected"
}, {
  value: FileStatus.REQUEST_APPROVAL,
  name: "Request Approval"
}]

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  onCardSelect
}) => {
  const { getFiles,data, loading, refetch, deleteFileMutation: [deleteFile] } = useMediaAssets()
  const { uploadFile } = useFileUploader();
  const files = [...data?.files||[]];
  const {user} = useUserContext();
  const [uploadingFile,setUploadingFile] = useState()
  const [progress,setProgress] = useState(0)

  const [filters, setFilters] = useState<Filters>({
    status: [],
    thumbnailView: false
  })

  useEffect(()=>{
    if(user){
      getFiles();
    }
  },[user])

  async function handleUpload(e: any) { 
    setUploadingFile(e.target.files[0]);

    if (files && files.length >= 10) {
      toast.error("You can only upload 10 files, Please delete some files to upload more");
    } else {
      const uploadedFiles: File[] = e.target.files;
      for (const file of uploadedFiles) {
        await uploadFile(
          file,(progress)=>{
            setProgress(progress)
          }
        )
      }

      if (uploadedFiles.length > 0) {
        await refetch();
        setUploadingFile(undefined);
      }
    }
  }

  function handleSelection(values: FileStatus[]) {
    setFilters({
      ...filters,
      status: values
    })
  }

  async function handleFileDelete(item: { url: string, name: string }) {
    if (!item.name) return;
    await deleteFile({
      variables:{
        where:{
          url: item.url
        }
      }
    })
    refetch()
  }

  const filesToDisplay = files?.sort((a,b)=>{
    return a?.name || "" > (b?.name || "") ? 1 : -1
  })
    .filter(file => filters.status.length == 0 || (file.status ? filters.status.includes(file.status) : false));

  return (
    <div
      className={`nc-SectionGridFilterCard ${className} min-h-screen`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2
        heading="Media library"
        subHeading={"You can manage your media assets here"
        }
      />

      <div className="lg:p-10 lg:bg-neutral-50 lg:dark:bg-black/20 grid grid-cols-1 gap-6  rounded-3xl">
        <div>
          <div className="flex justify-between mb-4 flex-col gap-2 lg:flex-row">

            <div className="flex flex-row gap-2">
              <CheckboxFilter title="Approval status" selectedStatuses={filters.status} options={options} onSave={handleSelection}/>
              <TabOnSale filters={filters} onFiltersChange={setFilters}/>
            </div>
            
          </div>
        </div>
        <UploadBlock onUpload={handleUpload} accept="image/jpg,image/jpeg,image/png, video/*" multiple />
        <div className={`grid grid-cols-1 ${filters.thumbnailView?'lg:grid-cols-2':'lg:grid-cols-1'} gap-4 `}>
          {loading &&
            <div className="flex mt-12 justify-center items-center">
              <ButtonPrimary loading>Loading</ButtonPrimary>
            </div>
          }
          
          {uploadingFile && <div>
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
              <div className="bg-blue-600 h-2.5 rounded-full" style={{"width": progress+"%"}}></div>
            </div>
            <AssetCard thumbnailView={filters.thumbnailView} data={uploadingFile as FileData} />
          </div>}
          {filesToDisplay?.map((item) => (
            <AssetCard onCardSelect={onCardSelect} key={item.url} onDelete={() => handleFileDelete(item)} data={item} />
          ))}

        </div>

      </div>
    </div>
  );
};

export default SectionGridFilterCard;
