import { Popover, Transition } from '@headlessui/react';
import React, { Fragment } from 'react'
import { XClear } from './RenderXClear';
import Checkbox from '@/shared/Checkbox/Checkbox';
import ButtonThird from '@/shared/Button/ButtonThird';
import ButtonPrimary from '@/shared/Button/ButtonPrimary';
import { BookingStatus } from '@/generated/zeus';

export default function BookingStatusFilter({onSave}: {onSave?:(selectedStatuses: BookingStatus[])=>void}) {

  const [selectedStatuses,setSelectedStatus] = React.useState<string[]>([])
   
  const typeOfExpriences = [
    {
      name: "Upcoming",
      value: [BookingStatus.PENDING_APPROVAL,BookingStatus.APPROVED,BookingStatus.SCHEDULED, BookingStatus.LIVE]
    },
    {
      name: "Rejected",
      value: [BookingStatus.REJECTED]
    },
    {
      name: "Completed",
      value: [BookingStatus.COMPLETED]
    },
    {
      name: "Cancelled",
      value: [BookingStatus.CANCELLED]
    }
  ];

  const typeOfAirlines = typeOfExpriences.map(item=>({name:item.name}));

  function handleChangeAirlines(checked: boolean, name: string) {
    
    if (checked) {
      if(name==="all"){
        setSelectedStatus(typeOfAirlines.map(item=>item.name));
        return;
      }
      setSelectedStatus([...selectedStatuses, name]);
    } else {
      if(name==="all"){
        setSelectedStatus([]);
        return;
      }
      setSelectedStatus(selectedStatuses.filter((item) => item !== name));
    }

  }
  function handleSave(){
    const bookingStatuses:BookingStatus[] = [];
    selectedStatuses.forEach((status)=>{
      bookingStatuses.push(...typeOfExpriences.find((item)=>item.name===status)?.value||[])
    })
    onSave?.([...new Set(bookingStatuses)]);
  }

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button
            className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none
               ${open ? "!border-primary-500 " : ""}
                ${
        !!selectedStatuses.length
          ? "!border-primary-500 bg-primary-50 text-primary-700"
          : ""
        }
                `}
          >
            <span>Booking status</span>
            {!selectedStatuses.length ? (
              <i className="las la-angle-down ml-2"></i>
            ) : (
              <span onClick={() => setSelectedStatus([])}>
                <XClear/>
              </span>
            )}
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
              <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                <div className="relative flex flex-col px-5 py-6 space-y-5">
                  <Checkbox
                    name="all"
                    label="All status"
                    defaultChecked={selectedStatuses.includes("all")}
                    onChange={(checked) =>
                      handleChangeAirlines(checked, "all")
                    }
                  />
                  <hr />
                  {typeOfAirlines.map((item) => (
                    <div key={item.name} className="">
                      <Checkbox
                        name={item.name}
                        label={item.name}
                        checked={selectedStatuses.includes(item.name)}
                        onChange={(checked) =>
                          handleChangeAirlines(checked, item.name)
                        }
                      />
                    </div>
                  ))}
                </div>
                <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                  <ButtonThird
                    onClick={() => {
                      close();
                      setSelectedStatus([]);
                      onSave?.([]);
                    }}
                    sizeClass="px-4 py-2 sm:px-5"
                  >
                      Clear
                  </ButtonThird>
                  <ButtonPrimary
                    onClick={()=>{
                      close();
                      handleSave()
                    }}

                    sizeClass="px-4 py-2 sm:px-5"
                  >
                      Apply
                  </ButtonPrimary>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
  
}
