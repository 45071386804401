import { AdBoardScalarFieldEnum, WorkspaceType } from '@/generated/zeus';
import useUserContext from '@/hooks/useUserContext';
import { useTypedQuery } from '@/hooks/useZeus';
import React,{useRef} from 'react'
import LoadingBar from 'react-top-loading-bar'

type LoadingBar={
  staticStart:()=>void;
  complete:()=>void;
  continuousStart:()=>void;
}

type LoadingBarRef={
  current:LoadingBar|null
}

type AppContextProps={
  show: boolean;
  cities:{
    city:string;
    _count?:{
      city:number
    }
  }[];
  adBoardTypes:{
    id:string;
    name:string;
  }[];
  businessCategories:{
    id:string;
    name:string;
  }[];
  loadingBarRef: LoadingBarRef
}

export const AppContext = React.createContext<AppContextProps>({} as AppContextProps);

export const AppProvider = ({children}:React.PropsWithChildren<{}>) => {
  const ref = useRef<LoadingBar>(null)
  const {config} = useUserContext()
  const {data} = useTypedQuery({
    adBoardTypes:[{},{
      id:true,
      name:true
    }],
    businessCategories:[{},{
      id:true,
      name:true
    }],
    groupByAdBoard:[{
      by:[AdBoardScalarFieldEnum.city],
      where:{
        city:{
          not:{
            in: ['']
          }
        },
        isLive:{
          equals:true
        },
        workspace:{
          is:{
            type:{
              in:[config?.env == 'production' ? WorkspaceType.PROD : WorkspaceType.TEST] 
            }
          }
        }
      }
    },{
      city:true,
      _count:{
        city:true
      }
    }]
  },{
    fetchPolicy:'cache-first'
  })

  return (
    <AppContext.Provider value={{
      show:false,
      loadingBarRef:ref,
      adBoardTypes:data?.adBoardTypes||[],
      businessCategories:data?.businessCategories||[],
      cities:[{
        city:'All',
        _count:{
          city:data?.groupByAdBoard?.reduce((a,b)=>a+(b._count?.city||0),0)||0
        }
      },...data?.groupByAdBoard||[]].sort((a,b)=>(b._count?.city||0)-(a._count?.city||0)),
    }}>
      <LoadingBar color="#5046e4" 
        height={4}
        background='5'
        ref={ref} shadow={true} />
      {children}
    </AppContext.Provider>
  )
}

export const useAppContext = () => React.useContext(AppContext);
export const useLoadingBar = () => React.useContext(AppContext).loadingBarRef.current;