import React, { FC } from "react";
import NcImage from "@/shared/NcImage/NcImage";
import { Link } from "react-router-dom";
import convertNumbThousand from "@/utils/convertNumbThousand";

export interface CardCategory5Props {
  className?: string;
  taxonomy: {city?:string,_count?:{city:number}};
}

const CardCategory5: FC<CardCategory5Props> = ({
  className = "",
  taxonomy,
}) => {
  const { _count,city } = taxonomy;
  return (
    <Link
      to={'/'}
      className={`nc-CardCategory5 flex flex-col ${className}`}
      data-nc-id="CardCategory5"
    >
      <div
        className={`flex-shrink-0 relative w-full aspect-w-4 aspect-h-3 h-0 rounded-2xl overflow-hidden group`}
      >
        <NcImage
          data-src={'https://aplivili.sirv.com/adboard-booking-web/istockphoto-1097491472-612x612.jpeg'}
          className="object-cover w-full h-full rounded-2xl"
        />
        <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span>
      </div>
      <div className="mt-4 px-3 truncate">
        <h2
          className={`text-base sm:text-lg text-neutral-900 dark:text-neutral-100 font-medium truncate`}
        >
          {city}
        </h2>
        <span
          className={`block mt-2 text-sm text-neutral-6000 dark:text-neutral-400`}
        >
          {convertNumbThousand(_count?.city)} properties
        </span>
      </div>
    </Link>
  );
};

export default CardCategory5;
