import { messaging } from '@/context/FirebaseContext';
import { apiFetch } from '@/hooks/fetch';
import { getToken } from 'firebase/messaging';

export async function requestNotificationPermission() {
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const token = await getToken(messaging, {
        vapidKey:'BAWfmR0Y8U3VzZAnKFzeXGTX6rFZh4TxYVDVW5Yow-j3vc128EqdcZ89SjMYyaPOoig9D8ag1VakXrgmE2XTem8' 
      });
      await sendTokenToServer(token);
      return token;
    } else {
      console.log('Unable to get permission to notify.');
      return null;
    }
  } catch (error) {
    console.error('An error occurred while retrieving token:', error);
    return null;
  }
}

export async function requestLocationPermission() {
  return new Promise<GeolocationPosition>((resolve, reject)=>{
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        // Permission granted
        console.log("Permission granted");
        // sendUserPosition(position);
        resolve(position)
      },
      (error) => {
        // Permission denied or error
        console.log("Permission denied or error:", error.message);
        reject(error);
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      }
    );
  })  
}

export async function sendTokenToServer(token) {
  // Implement your logic to send the token to your server
  const response = await apiFetch('/api/push/save-fcm-token', {
    method: 'POST',
    headers: {
      "content-type": 'application/json',
      "apollographql-client-name":"adboard-booking-web",
      authorization: `Bearer ${sessionStorage.getItem('token')}`
    },
    body: JSON.stringify({ token }),
  });
  if (!response.ok) {
    throw new Error('Failed to save token on server');
  }
}

export function onMessageReceived() {
  messaging.onMessage((payload) => {
    const notificationTitle = payload.data.title;
    const notificationOptions = {
      body: payload.data.body,
      icon: 'https://aplivili.sirv.com/adboard-booking-web/AdBoardLogo/icon3.png'
    };
    new Notification(notificationTitle, notificationOptions);
  });
}