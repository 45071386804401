import React from 'react'
import { PriceSummary } from './PriceSummary'
import { useGateway } from '../CheckOutPage/useGateway'
import LoadingButton from '@/components/LoadingButton';

export default function PaymentDialog({
  bookingId,
  priceSummary
}) {

  const {initiateCampaignBooking} =  useGateway();

  async function handleClick() {
    console.log('Pay now clicked')
    await initiateCampaignBooking(bookingId);
  }

  // initiatePayment({id:'123'});

  return (
    <div className='h-full flex flex-col justify-between gap-4'>
      <div>
        <PriceSummary priceSummary={priceSummary}></PriceSummary>
      </div>
      <div className='text-center'>
        <LoadingButton onClick={handleClick}>
            Pay Now
        </LoadingButton>
      </div>
    </div>
  )
}
