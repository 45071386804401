import Button, { ButtonProps } from "@/shared/Button/Button";
import React from "react";

export type ButtonPrimaryProps = ButtonProps & {
  bgClassName?: string;
};

const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({
  className = "",
  bgClassName = "",
  ...args
}) => {

  let background = ' bg-primary-6000 hover:bg-primary-700'

  if(bgClassName){
    background = bgClassName
  }
  return (
    <Button
      className={`ttnc-ButtonPrimary disabled:bg-opacity-70 text-neutral-50 ${background} ${className} ${args.disabled?' cursor-not-allowed':""}`}
      {...args}
    />
  );
};

export default ButtonPrimary;
