import {
  FC,
  ImgHTMLAttributes
} from "react";
import NcImageDefault from "./NcImageDefault";
import SirvImage from "./SirvImage";

export interface NcImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  containerClassName?: string;
}

const NcImage: FC<NcImageProps> = ({
  containerClassName = "",
  alt = "nc-imgs",
  src = "",
  className = "object-cover w-full h-full",
  ...args
}) => {
  if(src.includes('sirv.com')){
    return <SirvImage {...{ containerClassName, alt, src, className, ...args }} />;
  }
  return <NcImageDefault {...{ containerClassName, alt, src, className, ...args }} />;
};

export default NcImage;
