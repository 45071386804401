import { createPageContext } from "@/context/PageContext"
import { TabFiltersType } from "./TabFilters"

type ListingPageContext={
  filters:TabFiltersType,
  
}
const {PageContext:AdboardPageContext,PageProvider:AdboardPageProvider} = createPageContext<ListingPageContext>({
  filters:{
    locationTypes:{

    },
    adBoardTypes:{
      
    },
    rangePrices:[]
  }
})

export {
  AdboardPageProvider as AdboardPageProvider,
  AdboardPageContext as AdboardPageContext
}

