import React, { FC, useEffect } from "react";

import { Alert, AlertDescription } from "@/components/ui/alert";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useCartContext } from '@/context/CartContext';
import { useTypedQuery } from '@/hooks/useZeus';
import { fireEventOnce, getDateString, mapAdboardToGtagItem } from '@/lib/utils';
import Loader from "@/shared/FullScreenLoader/Loading";
import {
  AlertCircle,
  Clock,
  Info
} from "lucide-react";
import { Link, useParams, useSearchParams } from 'react-router-dom';
import AssetPreview from "@/components/AssetPreview";
import Box from "@/components/Box";

export interface PayPageProps {
  className?: string;
}

const PayDoneCampaignPage: FC<PayPageProps> = ({ className = "" }) => {

  const [searchParams] = useSearchParams();
  const  {clearCart} = useCartContext()
  const params = useParams();
  const campaignId = searchParams.get('campaignId')||params.campaignId;

  const { data } = useTypedQuery({
    campaign:[{
      where:{
        id:campaignId
      }
    },{
      name:true,
      assets:[{},{
        id:true,
        url:true,
        meta:{
          width:true,
          height:true
        },
        size:true,
        type:true,
        name:true
      }],
      user:{
        email:true,
      },
      priceSummary:{
        discount:true,
        days:true,
        serviceCharge:true,
        payableRent:true,
        rentPerDay:true,
        totalRent:true,
      },
      id:true,
      selectedDates:{
        startDate:true,
        endDate:true
      },
      bookings:[{},{
        id:true,
        priceSummary:{
          payableRent:true,
        },
        adBoard:{
          workspace:{
            name:true,
          },
          screenSize:{
            width:true,
            height:true
          },
          id:true,
          type:[{},{
            name:true
          }],
          pricePerDay:true,
          city:true,
          galleryImgs:true,
          title:true
        }
      }]
    }]
  })

  useEffect(()=>{
    if(data?.campaign?.id && data.campaign){
      fireEventOnce("purchase", {
        ecommerce: {
          transaction_id: data.campaign.id,
          value: data.campaign.priceSummary?.payableRent,
          tax: data.campaign.priceSummary?.serviceCharge,
          currency: "INR",
          items: data.campaign.bookings.map(booking=> 
            mapAdboardToGtagItem(booking.adBoard,data.campaign?.selectedDates as any)
          )
        }
      });
    }
  },[data?.campaign])
  
  React.useEffect(()=>{
    clearCart()
  },[])

  if (!data || !data.campaign) {
    return <Loader fullScreen />
  }

  // const {bookings,selectedDates,assets} = data.campaign;

  const BookingConfirmation = () => {

    const campaignDetails = data.campaign;

    if(!campaignDetails){
      return <Loader fullScreen />
    }
  
    return (
      <div className="container mx-auto px-4 py-8 max-w-5xl">
        <Card className="p-8">
          {/* Header Section */}
          <div className="text-center mb-8">
            <div className="flex justify-center mb-4">
              <Clock className="h-12 w-12 text-yellow-500" />
            </div>
            <h1 className="text-2xl font-bold mb-2">Campaign Booking Submitted</h1>
            <p className="text-gray-600">Campaign ID: {campaignDetails.id}</p>
          </div>
  
          {/* Campaign Summary */}
          <Card className="p-6 bg-blue-50 mb-8">
            <div className="grid md:grid-cols-3 gap-6">
              <div>
                <p className="text-sm text-gray-600">Campaign Name</p>
                <p className="font-semibold">{campaignDetails.name}</p>
              </div>
              <div>
                <p className="text-sm text-gray-600">Duration</p>
                <p className="font-semibold">{getDateString(campaignDetails.selectedDates.startDate)} to {getDateString(campaignDetails.selectedDates.endDate)}</p>
              </div>
              <div>
                <p className="text-sm text-gray-600">Total Amount</p>
                <p className="font-semibold">₹{campaignDetails.priceSummary?.totalRent}</p>
              </div>
            </div>
          </Card>
  
          {/* Tabs for Adboards and Creatives */}
          <Tabs defaultValue="adboards" className="mb-8">
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="adboards">Adboard Bookings</TabsTrigger>
              <TabsTrigger value="creatives">Campaign Creatives</TabsTrigger>
            </TabsList>
  
            <TabsContent value="adboards">
              <div className="space-y-4">
                {campaignDetails.bookings.map((booking,index) => (
                  <Box key={booking.id} >
                    <div className="flex justify-between items-start mb-4">
                      <div>
                        <h3 className="font-semibold">Booking #{index+1}</h3>
                        <p className="text-gray-600">{booking.adBoard.title}</p>
                      </div>
                      <Badge 
                        variant="outline" 
                        className="bg-yellow-50 text-yellow-700"
                      >
                        Pending Approval
                      </Badge>
                    </div>
  
                    <div className="grid md:grid-cols-3 gap-4 mb-4">
                      <div>
                        <p className="text-sm text-gray-600">Billboard Type</p>
                        <p>{booking.adBoard.type?.name}</p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-600">Size</p>
                        <p>{booking.adBoard.screenSize?.width} x {booking.adBoard.screenSize?.height} ft</p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-600">Amount</p>
                        <p>₹{booking.priceSummary?.payableRent}</p>
                      </div>
                    </div>
  
                    <div className="mb-4">
                      <p className="text-sm text-gray-600 mb-2">Assigned Creatives</p>
                      <div className="flex gap-2">
                        {campaignDetails.assets.map((creative, idx) => (
                          <Badge key={idx} variant="secondary">
                            {creative.name}
                          </Badge>
                        ))}
                      </div>
                    </div>
  
                    <div className="flex items-center gap-2 text-sm text-gray-600">
                      <Info className="h-4 w-4" />
                      <p>Awaiting approval from {booking.adBoard.workspace.name}</p>
                    </div>
                  </Box>
                ))}
              </div>
            </TabsContent>
  
            <TabsContent value="creatives">
              <div className="grid md:grid-cols-1 gap-4">
                {campaignDetails.assets.map((creative) => (
                  <Box key={creative.id}>
                    <div className="flex items-start gap-4">
                      <div className="bg-gray-100 p-3 rounded">
                        <div className="w-48 text-gray-600">
                          <AssetPreview url={creative.url} />
                        </div>
                      </div>
                      <div className="flex-1">
                        <h3 className="font-semibold">{creative.name}</h3>
                        <p className="text-sm text-gray-600">
                          {creative.type} • {creative.size}
                        </p>
                        <div className="mt-2">
                          <Badge variant="outline" className="bg-green-50">
                            {creative.type}
                          </Badge>
                        </div>
                      </div>
                      <Button variant="outline" size="sm">
                        Preview
                      </Button>
                    </div>
                  </Box>
                ))}
              </div>
            </TabsContent>
          </Tabs>
  
          {/* Next Steps */}
          <Alert className="mb-8 bg-blue-50">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription className="ml-2">
              Each billboard booking requires separate approval from its owner. You'll be notified as approvals are received.
            </AlertDescription>
          </Alert>
  
          <div className="space-y-4">
            <h2 className="text-xl font-semibold">Next Steps</h2>
            <ol className="space-y-4">
              <li className="flex items-center gap-3">
                <Badge variant="outline" className="bg-yellow-50">1</Badge>
                <span>Wait for billboard owners' approvals (typically within 24 hours)</span>
              </li>
              <li className="flex items-center gap-3">
                <Badge variant="outline" className="bg-gray-50">2</Badge>
                <span>Complete payment for approved bookings</span>
              </li>
              <li className="flex items-center gap-3">
                <Badge variant="outline" className="bg-gray-50">3</Badge>
                <span>Receive final campaign confirmation</span>
              </li>
            </ol>
          </div>
  
          {/* Action Buttons */}
          <div className="flex flex-col sm:flex-row gap-4 mt-8">
            <Link to={"/campaigns/"+data.campaign?.id}>
              <Button className="flex-1">
              Download Campaign Details
              </Button>
            </Link>
            <Link to="/contact" className="flex-1">
              <Button className="flex-1" variant="outline">
              Contact Support
              </Button>
            </Link>
          </div>
  
          {/* Support Information */}
          <div className="mt-8 text-center text-sm text-gray-600">
            <p>Need help? Contact our support team</p>
            <p>ankur.kus1@adboardbooking.com | +91 9310854859</p>
          </div>
        </Card>
      </div>
    );
  };

  return (
    <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">

      <main className="container mt-11 mb-24 lg:mb-32 ">
        <div className="max-w-4xl mx-auto">
          {/* <BookingConfirmation campaign={data.campaign}></BookingConfirmation> */}
          {BookingConfirmation()}
        </div>
      </main>
    </div>
  );
};

export default PayDoneCampaignPage;
