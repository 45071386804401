import { ApolloProviderWrapper } from '@/context/ApolloClientContext';
import { AppProvider } from '@/context/AppContext';
import CartProvider from '@/context/CartContext'; 
import { FirebaseProvider } from '@/context/FirebaseContext';
import { UserProvider } from '@/context/UserContext'; 
import { ToastContainer } from '@/lib/toast';
import MyRouter from "@/routers/index";

import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact,{BugsnagErrorBoundary} from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'

Bugsnag.start({
  apiKey: '4b9d4a8ea6de9d0446560ee6fa8a6ab3', 
  plugins: [new BugsnagPluginReact()]
})
BugsnagPerformance.start({ apiKey: '4b9d4a8ea6de9d0446560ee6fa8a6ab3' })

function App() { 
  const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React) as BugsnagErrorBoundary
  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <ErrorBoundary>
        <FirebaseProvider >
          <UserProvider>
            <ApolloProviderWrapper>
              <AppProvider>
                <CartProvider>
                  <MyRouter />
                  <ToastContainer
                    autoClose={2000}
                    position="top-right"
                    hideProgressBar={false}
                    newestOnTop={false}
                    rtl={false}
                    draggable
                    closeOnClick={true}
                  />
                </CartProvider>
              </AppProvider>
            </ApolloProviderWrapper>
          </UserProvider> 
        </FirebaseProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
