import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

const topicalMap = [
  { title: "Affordable Online Billboard Booking", url: "/affordable-online-billboard-booking-rates" },
  { title: "Easy Online Billboard Booking Process", url: "/easy-online-billboard-booking-process" },
  { title: "Benefits of Online Billboard Booking", url: "/benefits-of-online-billboard-booking-for-businesses" },
  { title: "Choosing Online Billboards", url: "/how-to-choose-online-billboard-for-advertising" },
  { title: "Best Online Billboard Booking Options", url: "/best-online-billboard-booking-options" },
  { title: "Common Online Billboard Booking Mistakes", url: "/common-mistakes-online-billboard-booking" },
  { title: "Quick Online Billboard Booking", url: "/quick-online-billboard-booking-service" },
  { title: "Online Billboard Advertising Platforms", url: "/platforms-for-online-billboard-advertising-booking" },
  { title: "Compare Online Billboard Services", url: "/compare-online-billboard-booking-services-reviews" },
  { title: "Online Billboard Booking and Sales", url: "/online-billboard-booking-impact-on-sales" }
];

const TopicalMap = () => {
  return (
    <Card className="w-full max-w-3xl mx-auto">
      <CardHeader>
        <CardTitle>Topical Map</CardTitle>
      </CardHeader>
      <CardContent>
        <ul className="space-y-2">
          {topicalMap.map((item, index) => (
            <li key={index}>
              <a 
                href={item.url} 
                className="text-blue-600 hover:underline"
              >
                {item.title}
              </a>
            </li>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
};

export default TopicalMap;