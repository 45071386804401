import React from 'react'

export default function SirvImage(props) {
  return (
    
    // <div data-src={props.src+"?cy=center"} className='Sirv image-fill'></div>
    <div className="w-full h-full rounded-2xl overflow-hidden">
      <img data-src={props.src} className="Sirv image-fill" data-options="fit:cover"/>
    </div>
  )
}
