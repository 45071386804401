import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

const blogsData = [
  { id: 'billboard-advertising-india', title: 'Billboard Advertising in India' },
  { id: 'make-money-using-billboard', title: 'Make Money Using Billboards' },
  { id: 'booking-billboard-online-ncr', title: 'Booking Billboards Online in NCR' },
  { id: 'rent-billboard-space-ncr', title: 'Rent Billboard Space in NCR' },
  { id: 'digital-hoarding-rates-in-gurgaon', title: 'Digital Hoarding Rates in Gurgaon' },
  { id: 'easy-online-billboard-booking', title: 'You Won\'t Believe How Easy Online Billboard Booking Can Be!' },
  { id: 'passive-income-billboard', title: 'Passive income using billboard' },
  { id: 'make-money-billboard', title: 'Make money from billboard' },
  { id: 'boost-society-income-using-billboard', title: "Boost Your Society's Income: AdBoard Booking's Innovative Advertising Solution" },
  { id: 'frustration-to-freedom', title: "From Frustration to Freedom: A Billboard Owner's Journey" },
  { id: 'chaos-to-clarity', title: "From Chaos to Clarity: An Agency's Billboard Booking Revolution" },
  { id: 'book-billboard-online', title: "Instant Online Billboard Booking with AdBoardBooking.com" },
  { id: 'start-a-billboard-business', title: "How to Start a Billboard Business in India" },
  { id: 'money-with-billboard', title: "How to Make Money with Billboard Advertising" },
  { id: 'passive-income-through-billboard-advertising', title: "How to Generate Passive Income Through Billboard Advertising in 2024" },
  { id: 'low-investment-ways-to-start-your-billboard-business', title: "5 Low-Investment Ways to Start Your Billboard Business in India" },
  { id: 'billboard-advertising-rates-in-india', title: "Billboard Advertising Rates in India: Pricing Guide for 2024" },
  { id: 'get-paid-for-billboard', title: "Get paid for billboard" },
  { id: 'billboard-advertising-in-delhi', title: "Billboard advertisement in delhi" },
];

const BlogsMap = () => {
  return (
    <Card className="w-full mx-auto">
      <CardHeader>
        <CardTitle>Blogs</CardTitle>
      </CardHeader>
      <CardContent>
        <ul className="space-y-2">
          {blogsData.map((item, index) => (
            <li key={index}>
              <a 
                href={"/blogs/"+item.id} 
                className="text-blue-600 hover:underline"
              >
                {item.title}
              </a>
            </li>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
};

export default BlogsMap;