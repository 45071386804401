import React, { FC, ReactNode, useEffect } from "react";
// import { DEMO_STAY_LISTINGS } from "@/data/listings";
// import { StayDataType } from "@/data/types";
import ButtonPrimary from "@/shared/Button/ButtonPrimary";
import HeaderFilter from "./HeaderFilter";
import useUserContext from "@/hooks/useUserContext";
import { useTypedLazyQuery } from "@/hooks/useZeus";
import { $, WorkspaceType } from "@/generated/zeus";
import StayCard from "@/components/StayCard/StayCard";
import { useAppContext } from "@/context/AppContext";
import { adboardFragment, AdboardFragment } from "@/hooks/useAdboards";
import { fireEvent } from "@/lib/utils";

export interface SectionGridFeaturePropertyProps {
  stayListings?: AdboardFragment[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
}

export const SectionGridFeatureAdboard: FC<SectionGridFeaturePropertyProps> = ({
  gridClass = "",
  heading = "Featured places to stay",
  subHeading = "Popular places to stay that Adboard Booking recommends for you",
}) => {
  const {config} = useUserContext()
  const {cities} = useAppContext()
  const topcities = cities.filter(item=>item.city!="All") ;
  const topcity = topcities?.map(item=>item.city)[0]

  const [getAdboards,{ data, loading,fetchMore ,refetch}] = useTypedLazyQuery({
    adBoards: [{
      take:$('take','Int'),
      skip:$('skip','Int'),
      where: {
        city:{
          equals: $('city','String')
        },
        isLive:{
          equals:true
        },
        workspace:{
          is:{
            type:{
              in:[config?.env == 'production' ? WorkspaceType.PROD : WorkspaceType.TEST] 
            }
          }
        }
      }
    }, adboardFragment]
  },{
    notifyOnNetworkStatusChange:true,
    variables:{
      take:8,
      city: topcity
    }
  })

  function handleShowMore(){ 
    fireEvent('featuredAdboards.showMore.click',{
      city: topcity
    })

    fetchMore({
      variables:{
        skip:data?.adBoards.length
      }      
      ,updateQuery(previousData, { fetchMoreResult,variables:{skip}}) {
        const updatedFeed = previousData.adBoards.slice(0);
        for (let i = 0; i < fetchMoreResult.adBoards.length; ++i) {
          updatedFeed[skip + i] = fetchMoreResult.adBoards[i];
        }
        return { ...previousData, adBoards: updatedFeed };
      },
    })
  }

  useEffect(()=>{
    if(topcity){
      getAdboards({
        variables:{
          city: topcity
        }
      })
    }
  },[topcity])
      
  return (
    <div className="nc-SectionGridFeatureProperty relative min-h-96 flex flex-col justify-between">
      
      <HeaderFilter
        onClickTab={(item)=>{
          refetch({
            city: item,
            take:4
          })
        }}
        tabActive={topcity||""}
        subHeading={subHeading}
        tabs={topcities.map(item=>item.city)||[]}
        heading={heading}
      />
      <div
        className={`grid gap-6 md:gap-8 grid-cols-1 sm:grid-cols-1 xl:grid-cols-4 ${gridClass}`}
      >
        {/* {loading && <Loader></Loader>} */}
        {data?.adBoards.map((stay)=>{
          return  <StayCard section="featured-adboards" target="_self" key={stay.id} data={stay} />
        })}
      </div>
      <div className=" flex mt-16 justify-center items-center">
        <ButtonPrimary onClick={handleShowMore} loading={loading}>{loading ? "Loading..." : 'Show me more'}</ButtonPrimary>
      </div>
    </div>
  );
};

