import AssetPreview from '@/components/AssetPreview'
import React from 'react'

export default function FilePreview({
  file,
  hideName
}:{
    hideName?:boolean,
    file:{
      url:string,
      type:string,
      name:string
    }
}) {

  return <div className='max-w-40 '>
    <div className='rounded-lg overflow-hidden'>
      <AssetPreview url={file.url}></AssetPreview>
    </div>
    {!hideName && 
    <div className='text-center'>
      {file.name.length>20?file.name.slice(0,20)+'...':file.name}
    </div>
    }
  </div>    
}
