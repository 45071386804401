import React from 'react';

const PricingComponent = () => {
 
  const additionalServices = [
    { name: 'IP Camera', price: '₹5,000 (one-time)' ,detail:"Hardware"},
    { name: 'Media Server', price: '₹7,500 (one-time)' ,detail:"Hardware"},
    { name: 'Remote Support	', price: '-' ,detail:"Included in all packages"},
    { name: 'Adboard marketplace', price: '-' ,detail:"Included in all packages"},
    { name: 'On-site Installation	', price: '₹5,000 per player' ,detail:"Installation"},
  ];

  return (
    <div className="">
      
      <h2 className="text-2xl font-bold mt-12 mb-4">Additional Services</h2>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                    Service
              </th>
              <th scope="col" className="px-6 py-3">
                    Detail
              </th>
              <th scope="col" className="px-6 py-3">
                    Price(₹)
              </th>
            </tr>
          </thead>
          <tbody>
            {additionalServices.map((service) => (
              <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {service.name}
                </th>
                <td className="px-6 py-4">
                  {service.detail}
                </td>
                <td className="px-6 py-4">
                  {service.price}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    
      <h2 className="text-2xl mt-8 font-bold mb-4">Marketplace Commission</h2>
      <p className="mb-8">Join our marketplace and reach a broader audience. Earn revenue through our adboard marketplace with a 10% commission on every booking made through the marketplace.</p>

    </div>
  );
};

export default PricingComponent;
