import { CheckIcon } from "@heroicons/react/24/solid";
import React, { FC } from "react";
import ButtonPrimary from "@/shared/Button/ButtonPrimary";
import ButtonSecondary from "@/shared/Button/ButtonSecondary";
import PricingComponent from "./Pricing";
import { useCustomFetch } from "@/hooks/useCustomFetch";

export interface PageSubcriptionProps {
  className?: string;
  // pricings?:PricingItem[];
  title?:string,
  subtitle?:string
}

export interface Package {
  id: string
  object: string
  active: boolean
  attributes: any[]
  created: number
  default_price?: DefaultPrice
  description?: string
  images: any[]
  livemode: boolean
  marketing_features: MarketingFeature[]
  name: string
  package_dimensions: any
  shippable: any
  statement_descriptor?: string
  tax_code: any
  type: string
  unit_label: any
  updated: number
  url: any
}

export interface DefaultPrice {
  id: string
  object: string
  active: boolean
  billing_scheme: string
  created: number
  currency: string
  custom_unit_amount: any
  livemode: boolean
  lookup_key: any
  nickname?: string
  product: string
  tax_behavior: string
  tiers_mode: any
  transform_quantity: any
  type: string
  unit_amount: number
  unit_amount_decimal: string
}

export interface MarketingFeature {
  name: string
}

const PageSubcription: FC<PageSubcriptionProps> = ({ className = "",title,subtitle }) => {

  const {data} = useCustomFetch<{
    list:Package[]
  }>('/stripe/packages')

  const renderPricingItem = (pricing: Package, index: number) => {
    const isPopular = pricing.name === "Standard package";
    return (
      <div
        key={index}
        className={`h-full relative px-6 py-8 rounded-3xl border-2 flex flex-col overflow-hidden ${
          isPopular
            ? "border-primary-500"
            : "border-neutral-100 dark:border-neutral-700"
        }`}
      >
        {isPopular && (
          <span className="bg-primary-500 text-white px-3 py-1 tracking-widest text-xs absolute right-3 top-3 rounded-full z-10">
            POPULAR
          </span>
        )}
        <div className="mb-8">
          <h3 className="block text-sm uppercase tracking-widest text-neutral-6000 dark:text-neutral-300 mb-2 font-medium">
            {pricing.name}
          </h3>
          <h2 className="text-5xl leading-none flex items-center text-neutral-900 dark:text-neutral-300">
            <span>₹{(pricing.default_price?.unit_amount||0)/100}</span>
            <span className="text-lg ml-1 font-normal text-neutral-500">
              /per month
            </span>
          </h2>
          /per screen
        </div>
        <nav className="space-y-4 mb-8">
          {pricing.marketing_features.map((item, index) => (
            <li className="flex items-center" key={index}>
              <span className="mr-4 inline-flex flex-shrink-0 text-primary-6000">
                <CheckIcon className="w-5 h-5" aria-hidden="true" />
              </span>
              <span className="text-neutral-700 dark:text-neutral-300">
                {item.name}
              </span>
            </li> 
          ))}
        </nav>
        <div className="flex flex-col mt-auto">
          <a className="w-full text-center" href={import.meta.env.VITE_PUBLIC_API_URL+"/new-subscription"} target='_blank'>
            {isPopular ? (
              <ButtonPrimary ><span className="font-medium">Choose {pricing.name}</span></ButtonPrimary>
            ) : (
              <ButtonSecondary >
                <span className="font-medium">Choose {pricing.name}</span>
              </ButtonSecondary>
            )}
          </a>
          <p className="text-xs text-neutral-500 dark:text-neutral-400 mt-3">
            {/* {pricing.description} */}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div
      id="packages"
      className={`nc-PageSubcription container pb-4 lg:pb-4 ${className}`}
      data-nc-id="PageSubcription"
    >
      <header className="text-center max-w-2xl mx-auto mb-10 lg:my-20 ">
        <h2 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {/* <span className="mr-4 text-3xl md:text-4xl leading-none">💎</span> */}
          {title||'Packages'}
        </h2>
        <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
          {subtitle||'Pricing to fit the needs of any companie size.'}
        </span>
      </header>
      <section className="text-neutral-600 text-sm md:text-base overflow-hidden">
        <div className="grid lg:grid-cols-3 gap-5 xl:gap-8">
          {data?.list?.filter(item=>item.active).sort((a,b)=>(a.default_price?.unit_amount||0)-(b.default_price?.unit_amount||0)).filter(item=>item.name.includes('package')).map(renderPricingItem)}
        </div>
      </section>
      <PricingComponent/>
    </div>

  );
};

export default PageSubcription;
