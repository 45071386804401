import { AvatarDropdownItems } from "@/components/Header/AvatarDropdown";
import useUserContext from "@/hooks/useUserContext";
import ButtonClose from "@/shared/ButtonClose/ButtonClose";
import Logo from "@/shared/Logo/Logo";
import SocialsList from "@/shared/SocialsList/SocialsList";
import SwitchDarkMode from "@/shared/SwitchDarkMode/SwitchDarkMode";
import React from "react";
import Avatar from "../Avatar/Avatar";
import { NavItemType } from "./NavigationItem";

export interface NavMobileProps {
  data?: NavItemType[];
  onClickClose?: () => void;
}

const NavMobile: React.FC<NavMobileProps> = ({
  onClickClose,
}) => {
  const {user} = useUserContext()

  return (
    <div className="overflow-y-auto w-full h-screen py-2 transition transform shadow-lg ring-1 dark:ring-neutral-700 bg-white dark:bg-neutral-900 divide-y-2 divide-neutral-100 dark:divide-neutral-800">
      <div className="py-6 px-5">
        <Logo />
        <div className="flex flex-col mt-5 text-neutral-700 dark:text-neutral-300 text-sm">

          <div className="flex justify-between items-center mt-4">
            <SocialsList itemClass="w-9 h-9 flex items-center justify-center rounded-full bg-neutral-100 text-xl dark:bg-neutral-800 dark:text-neutral-300" />
            <span className="block">
              <SwitchDarkMode className="bg-neutral-100 dark:bg-neutral-800" />
            </span>
            
          </div>
          {/* <Link
            to="/publisher"
            className="mt-2"
          >
            <ButtonPrimary>
                  List your AdBoard 
            </ButtonPrimary>
          </Link> */}
        </div>
        <span className="absolute right-2 top-2 p-1">
          <ButtonClose onClick={onClickClose} />
        </span>
      </div>
      <div className="flex gap-2 items-center px-2">
        <Avatar sizeClass="w-8 h-8 sm:w-9 sm:h-9" imgUrl={user?.image}/>
        <div className="flex flex-col py-6 px-6 space-y-1">
          {user?.displayName}
          <br />
          <span className="text-sm">
            {user?.email}
          </span>
        </div>
      </div>
      <div className="p-4">
        <AvatarDropdownItems />
      </div>
      {/* <div className="flex items-center justify-between py-6 px-5"> */}
      {/* <a
          className="inline-block"
          href="https://themeforest.net/item/Adboard Booking-online-booking-react-template/33515927"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ButtonPrimary>Get Template</ButtonPrimary>
        </a> */}

      {/* <LangDropdown panelClassName="z-10 w-screen max-w-[280px] px-4 mb-3 -right-3 bottom-full sm:px-0" /> */}
      {/* </div> */}
    </div>
  );
};

export default NavMobile;
