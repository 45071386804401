import React, { InputHTMLAttributes } from "react";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  sizeClass?: string;
  label?:string
  fontClass?: string;
  rounded?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className = "",
      sizeClass = "px-3 py-2",
      fontClass = "text-sm font-normal",
      rounded = "rounded-xl",
      type = "text",
      ...args
    },
    ref
  ) => {
    return (
      <>
        <label className="block text-neutral-500 dark:text-neutral-400 text-sm font-normal">
          {args.label}
        </label>
        <input  
          ref={ref}
          type={type}
          className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 ${rounded} ${fontClass} ${sizeClass} ${className} ${(args.disabled?"bg-gray-100":"")}`}
          {...args}
        />
      </>
    );
  } 
);

export default Input;
