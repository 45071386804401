const baseUrl = import.meta.env.VITE_PUBLIC_API_URL || 'https://railway.adboardbooking.com';

export function apiFetch(url:string, options:RequestInit = {
  
}){
  url = baseUrl + url;
  return fetch(url, options)
}

export function fetchWithProgress(url:string,options:RequestInit,progressCallback?:(arg:number)=>any){

  if(!url.startsWith('http')){
    url = baseUrl+url
  }

  return new Promise<Record<string,any>>((resolve)=>{
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);
    if(options.headers){      
      for (const [key, value] of Object.entries(options.headers)) {
        xhr.setRequestHeader(key,value);
      }
    }

    xhr.upload.onprogress = e => {
      if (e.lengthComputable) {
        const percentComplete = (e.loaded / e.total) * 100;
        progressCallback?.(percentComplete)
      }
    };
    xhr.onreadystatechange = function(){
      if (xhr.readyState == 4 && xhr.status == 200){
        try{
          resolve(JSON.parse(xhr.responseText) as Record<string,any>);
        }catch(e){
          resolve(xhr.responseText as any)        
        }
      }
    }; 
    //@ts-ignore
    xhr.send(options.body);
  })

}