import { workspaceSelector } from "@/components/CardAuthorBox/CardAuthorBox";
import { GraphQLTypes, InputType, Selector } from "@/generated/zeus";

export const adBoardSelector = Selector('AdBoard')({
  id:true,
  status:true,
  pricePerDay:true,
  href:true,
  numberOfDisplays:true,
  adsPerLoop:true,
  adsDuration:true,
  address:true,
  title:  true,
  offlineBookingsEnabled:true,
  featuredImage:true,
  originalPricePerDay:true,
  city:true,
  playbackTimes:{
    startTime:true,
    endTime:true,
    name:true
  },
  galleryImgs:true,
  resolution:{
    width : true,
    height: true
  },
  description:true,
  screenSize:{
    width:true,
    height:true,
    unit:true
  },
  map :{
    lat:true,
    lng:true
  },
  aboutSpecifications:{
    name:true,
    value:true
  },
  players:[{},{
    name:true,
    screenSize:{
      width:true,
      height:true,
    },
    resolution:{
      width:true,
      height:true
    },
    map:{
      address:true,
      city:true,
      lat:true,
      lng:true
    }
  }],
  adBoardGroup:[{},{
    id:true,
  }],
  workspace:workspaceSelector
});

export type AdboardFragment = InputType<GraphQLTypes['AdBoard'], typeof adBoardSelector>;