import { graphql } from "@/type-gql";

export const DELETE_FILE = graphql(`
  mutation DeleteOneFile($where: FileWhereUniqueInput!){
    deleteOneFile(where: $where) {
      url
    }
  }
`)

export const UploadedFilesFragment = graphql(/* GraphQL */ `
  fragment UploadedFilesFragment on File {
      id
      name,
      url,
      mtime
      status
      type
      size
      isDirectory
      meta {
        width 
        height 
        duration
      }
  }
`)

export const UPLOADED_FILES_QUERY = graphql(`
  query UploadedFiles($filesWhere2: FileWhereInput,$sort: [FileOrderByWithRelationInput!]) {
    files(where: $filesWhere2,orderBy:$sort) {
        ...UploadedFilesFragment
      }
    }
`)

export const GET_USER = graphql(`
query GetUser($userWhere2: UserWhereUniqueInput!){
  user(where: $userWhere2) {
    id
  }
}`)