import BackgroundSection from "@/components/BackgroundSection/BackgroundSection";
import SectionClientSay from "@/components/SectionClientSay/SectionClientSay";
import SectionSubscribe2 from "@/components/SectionSubscribe2/SectionSubscribe2";
import { FC } from "react";
import { Helmet } from "react-helmet";
import ContactUs from "./ContactUs";

export interface PageContactProps {
  className?: string;
}

const PageContact: FC<PageContactProps> = ({ className = "" }) => {

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Contact | AdBoard Booking</title>
      </Helmet>
      <ContactUs/>
      {/* OTHER SECTIONS */}
      <div className="container">
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="Pagecontact_" />
        </div>
        <SectionSubscribe2 className="py-24 lg:py-32" />
      </div>
    </div>
  );
};

export default PageContact;
