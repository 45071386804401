import React, { ReactNode } from 'react'

export default function Block(props:{title?:string,children?:ReactNode}) {
  return (
    <div className='p-6 border border-gray-200 dark:border-gray-600 lg:p-6 lg:bg-white/40 lg:dark:bg-black/20 rounded-3xl shadow-md'>
      <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{props.title}</h5>
      {props.children}
    </div>
  )
}
