import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { AdboardFragment } from '@/hooks/useAdboards'
import { TrashIcon } from '@heroicons/react/24/solid'
import { Check, FileWarning } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import FilePreview from './FilePreview'
import FileChooserDialog from '../CheckOutPage/FileChooserDialog'
import ButtonSecondary from '@/shared/Button/ButtonSecondary'
import { toast } from 'react-toastify'
import { aspectRatioMap, AspectType, AssetFile } from '../CheckOutPage/types'

function getTypeBasedOnAspectRatio(aspectRatio): AspectType|undefined { 
  for (const [key, value] of Object.entries(aspectRatioMap)) {
    if(aspectRatio >= value.min && aspectRatio < value.max){
      return key as AspectType
    }
  }
}

export default function CreativesBox({
  selectedAdboards,
  creatives,
  setCreatives,
}: {
  selectedAdboards?:  AdboardFragment[],
  creatives: AssetFile[],
  setCreatives?: (args:AssetFile[])=>void,
}) {
  const [tableData, setTableData] = useState<{
    resolution: string,
    players: number,
    aspectRatio: string
  }[]>([])

  const [creativeTypes,setCreativeTypes] = React.useState<AspectType[]>([]);

  useEffect(() => {

    const creativeAspectTypes = new Set<AspectType>();

    if (selectedAdboards) {
      // setSelectedAdboards(data.adBoards.r)
      const resolutionMap = selectedAdboards.map(item=>{
        return item.players.map(item=>item.resolution) as {width:number,height:number}[]
      }).flat().filter(item=>item).map(item => {
        return {
          resolution: item.width + ' x ' + item.height,
          aspectRatio: (item.width || 0) / (item.height || 1),
        }
      })

      const resolutionCoutner = resolutionMap.reduce((acc: any, item: any) => {
        if (!acc[item.resolution]) {
          acc[item.resolution] = {
            counter: 0,
            aspectRatio: item.aspectRatio
          }
        }
        acc[item.resolution].counter++;
        return acc;
      }, {});

      const tableData = Object.keys(resolutionCoutner).map((key) => {
        creativeAspectTypes.add(getTypeBasedOnAspectRatio(resolutionCoutner[key].aspectRatio) as AspectType);
        return {
          resolution: key,
          aspectRatio: resolutionCoutner[key].aspectRatio.toFixed(2),
          players: resolutionCoutner[key].counter,
        };
      }).sort((a, b) => {
        const diff = b.players - a.players
        if (diff === 0) {
          return b.aspectRatio - a.aspectRatio
        } else {
          return diff
        }
      })

      setTableData(tableData)
      setCreativeTypes(Array.from(creativeAspectTypes))

    }

  }, [selectedAdboards])

  async function handleChange(file:AssetFile,targetAspectType?:AspectType) {
    let aspectType = getTypeBasedOnAspectRatio(file.meta.width/file.meta.height)
    if(aspectType != targetAspectType){
      const sure = window.confirm(`The aspect ratio of the selected file is ${aspectType}. Are you sure you want to upload it as ${targetAspectType}?`);
      if(sure){
        aspectType = targetAspectType
      }  
    }

    setCreatives?.([...creatives,{
      ...file,
      aspectType
    }])
  }

  return (
    <>
      <label className="block text-neutral-500 dark:text-neutral-400 text-sm font-normal">
        Below are the resolutions of the selected players. Creatives will be mapped automatically based on closest aspect ratio.
      </label>
      {tableData.length > 0 &&
        <div className="">
          <Table className=''>
            <TableHeader>
              <TableRow>
                <TableHead className="w-[100px]">Resolution</TableHead>
                <TableHead>Players</TableHead>
                <TableHead>Aspect ratio</TableHead>
                <TableHead>Creative mapped</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody className=''>
              {tableData
                .sort((a,b)=>Number(a.aspectRatio)-Number(b.aspectRatio))
                .map((item) => (
                  <TableRow key="user1">
                    <TableCell>{item.resolution}</TableCell>
                    <TableCell>{item.players}</TableCell>
                    <TableCell>{item.aspectRatio}:1 ({getTypeBasedOnAspectRatio(item.aspectRatio)})</TableCell>
                    <TableCell>
                      {creatives.some(creative=>{
                        const creativeAspectType = creative.aspectType;
                        const itemAspectType = getTypeBasedOnAspectRatio(Number(item.aspectRatio))
                        return creativeAspectType == itemAspectType
                      })?<Check className='text-green-700'></Check>
                        : <>
                          <FileWarning className='text-orange-400'></FileWarning>
                        </>}
                    
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      }

      <div className='mt-4'>
        <div className="mb-2">
          <div className='text-lg font-bold'>Uploaded creatives</div>
          <div className='text-sm font-regular'>Creatives will be mapped to players automatically based on closest aspect ratio</div>
        </div>
        <Table className=''>
          <TableHeader>
            <TableRow>
              <TableHead>Aspect type</TableHead>
              <TableHead>Name</TableHead>
              <TableHead>Resolution</TableHead>
              <TableHead>Aspect ratio</TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody className=''>
            
            {creativeTypes.map((type) => {
              
              const item = creatives.find(item=>{
                return item.aspectType == type
              });

              return ( 
                <TableRow key="user1">
                  <TableCell>{type}</TableCell>
                  {item ? <>
                    <TableCell><FilePreview file={item}></FilePreview></TableCell>
                    {/* <TableCell>{item.file.name}</TableCell> */}
                    <TableCell>{item.meta.width} x {item.meta.height}</TableCell>
                    <TableCell>{Number(item.meta.width/item.meta.height).toFixed(2)}:1 </TableCell>
                    {setCreatives &&
                      <TableCell>
                        
                        <TrashIcon className='text-red-700' width={'20'} onClick={() => {
                          setCreatives?.(creatives.filter(creative => (creative.id !== item.id) || (creative.aspectType !== type)))
                        }} /></TableCell>
                    }
                  </>:<TableCell colSpan={4} className='flex gap-2 items-center'>
                    <FileChooserDialog onFileSelect={async (file:AssetFile)=>{
                      const id = toast.loading('Loading selected file resolution...');
                      await handleChange(file,type);
                      toast.update(id, { render: "File loaded.", type: "success", isLoading: false,autoClose:2000 });
                    }}>
                      <ButtonSecondary className='whitespace-nowrap'>Select creative</ ButtonSecondary>
                    </FileChooserDialog>
                  </TableCell>}
                </TableRow>
              )
              
            })}
          </TableBody>
        </Table>
      </div>
    </>
  )
}
