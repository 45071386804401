import { useTypedQuery } from '@/hooks/useZeus'
import Loader from '@/shared/FullScreenLoader/Loading'
import React from 'react'
import _ from 'lodash'
import LiveStream from './LiveStream'

export default function PlayerLivestream({adBoardId}:{adBoardId:string}) {

  const {data,loading} = useTypedQuery({
    adBoardGroups:[{
      where:{
        adBoard:{
          is:{
            id:{
              equals:adBoardId
            }
          }
        }
      }
    },{
      pisignagePlayers:[{

      },{
        name:true,
        cameras:[{},{
          cameraUrl:true,
        }]
      }]
    }]
  })

  if(loading){
    return <Loader/>
  }

  const livestreams = _.uniq(data?.adBoardGroups[0]?.pisignagePlayers.map(item=>item.cameras)).flat().map(item=>item.cameraUrl).filter(item=>item)
  
  return <div className='grid grid-cols-1'>
    <div className=''>
        This adboard has {livestreams?.length} live camera stream(s).
    </div>
    <div className='mt-3 grid lg:grid-cols-1 grid-cols-1 gap-2'>

      {livestreams?.map((url,index)=>{
        return <div className="bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <a href="#">
            {url &&  <div className="overflow-hidden relative w-full pt-[56.25%]">
              <LiveStream url={url}/>
            </div>}
          </a>
          <div className="p-5">
            <a href="#">
              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Camera {index+1}</h5>
            </a>
           
          </div>
        </div>
      })}
    </div>
  </div>
}
