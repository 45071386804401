import { Popover, Transition } from "@headlessui/react";
import Slider from "rc-slider";
import { Fragment, MouseEvent, useState } from "react";
import ButtonPrimary from "@/shared/Button/ButtonPrimary";
import ButtonThird from "@/shared/Button/ButtonThird";
import Checkbox from "@/shared/Checkbox/Checkbox";
import convertNumbThousand from "@/utils/convertNumbThousand";
import { useSearchParams } from "react-router-dom";
import { useAppContext } from "@/context/AppContext";
import { XClear } from "@/shared/XClear";

export type TabFiltersType = {
  adBoardTypes: Record<string, boolean>,
  locationTypes: Record<string, boolean>,
  rangePrices: number[]
}

type TabFilterProps = {
  filters?: TabFiltersType,
  onChange?: ({
    adBoardTypes,
    rangePrices
  }: TabFiltersType) => void
}

type SelectedTabs = Record<string, boolean>

const TabsTypeOfPlace = ({ 
  options,label,selectedTabs:defaultSelectedTabs,onChange
}: {
   options:{id:string,name:string}[],
   label?:string,
   onChange: (arg: SelectedTabs) => void, 
   selectedTabs: SelectedTabs }) => {

  const [selectedTabs, setSelectedTabs] = useState<SelectedTabs>(defaultSelectedTabs);

  const optionMap = options.reduce<Record<string, any>>((a, b) => {
    a[b.id] = b
    return a;
  }, {})||{}

  const selected = Object.entries(defaultSelectedTabs).filter(item => item[1]).map(item => optionMap[item[0]]?.name)

  function handleClick(e: MouseEvent) {
    e.stopPropagation();
    setSelectedTabs({});
    onChange({})
  }

  return (
    <Popover className="relative">
      {({ open, close: closeDialog }) => (
        <>
          <Popover.Button

            className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border ${selected.length ? "border-primary-500 bg-primary-50 text-primary-700 focus:outline-none " : "border-neutral-300 dark:border-neutral-700 focus:outline-none"} ${open ? "!border-primary-500 " : ""
            }`}
          >
            {selected.length ? <>
              <span>{selected.join(", ")}</span>
              <XClear onClick={handleClick} />
            </> : <>
              <span>{label||"Adboard type"}</span>
              <i className="las la-angle-down ml-2"></i>
            </>}
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
              <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                <div className="relative flex flex-col px-5 py-6 space-y-5">
                  {options?.map((item) => (
                    <div key={item.name} className="">
                      <Checkbox
                        defaultChecked={!!selectedTabs[item.id]}
                        onChange={(checked) => {
                          setSelectedTabs({
                            ...selectedTabs,
                            [item.id]: checked
                          })
                        }}
                        name={item.name}
                        label={item.name}
                      />
                    </div>
                  ))}
                </div>
                <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                  <ButtonThird onClick={closeDialog} sizeClass="px-4 py-2 sm:px-5">
                    Clear
                  </ButtonThird>
                  <ButtonPrimary
                    onClick={() => {
                      closeDialog();
                      onChange?.(selectedTabs)
                    }}
                    sizeClass="px-4 py-2 sm:px-5"
                  >
                    Apply
                  </ButtonPrimary>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

const TabFilters = ({
}: TabFilterProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {adBoardTypes} = useAppContext()
  const [range, setRange] = useState<number[]>([0, 100000]);

  const rangePrices = JSON.parse(searchParams.get('rangePrices') as string) || [0, 0]
  const selectedAdBoardTypes = searchParams.get('adBoardTypes')?.split(',').reduce((a, b) => {
    if(!b) return a;
    a[b] = true
    return a
  }, {})||{};
  
  function handleClearClick(type:string){
    if(type === "price"){
      setSearchParams({rangePrices:JSON.stringify([0,100000])})
    }
    close()
  }
  const renderTabsPriceRage = () => {

    return (
      <Popover className="relative">
        {({  close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border    focus:outline-none ${(rangePrices[0]||rangePrices[1]) ? 'border-primary-500 text-primary-700 bg-primary-50':"border-neutral-300 dark:border-neutral-700 focus:outline-none"}`}
            >
              {rangePrices[0]||rangePrices[1] ?
                <>
                  <span>
                    {`₹${convertNumbThousand(
                      rangePrices[0]
                    )} - ₹${convertNumbThousand(rangePrices[1])}`}{" "}
                  </span>
                  <XClear onClick={(e)=>{e.stopPropagation() ; setSearchParams({rangePrices:JSON.stringify([0,0])})}}/>
                </>:
                <>
                  <span>Price range</span>
                </>}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 ">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-8">
                    <div className="space-y-5">
                      <span className="font-medium">Price per day</span>
                      <Slider
                        range
                        className="text-red-400"
                        min={0}
                        max={20000}
                        value={range}
                        allowCross={false}
                        onChange={(e) => setRange(e as number[])}
                      />
                    </div>

                    <div className="flex justify-between space-x-5">
                      <div>
                        <label
                          htmlFor="minPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          Min price
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-neutral-500 sm:text-sm">
                            ₹
                            </span>
                          </div>
                          <input
                            type="text"
                            name="minPrice"
                            disabled
                            id="minPrice"
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                            value={range[0]}
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="maxPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          Max price
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-neutral-500 sm:text-sm">
                            ₹
                            </span>
                          </div>
                          <input
                            type="text"
                            disabled
                            name="maxPrice"
                            id="maxPrice"
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                            value={range[1]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird onClick={()=>{close();handleClearClick('price')}} sizeClass="px-4 py-2 sm:px-5">
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        setSearchParams({rangePrices:JSON.stringify(range)})
                        close();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  return (
    <div className="flex lg:space-x-4">
      <div className="flex lg:flex-row flex-col gap-4">
        <TabsTypeOfPlace
          selectedTabs={selectedAdBoardTypes}
          onChange={(selectedTabs: any) => {
            setSearchParams({
              ...Object.fromEntries(searchParams.entries()),
              adBoardTypes:Object.entries(selectedTabs).filter(item=>item[1]).map(item=>item[0]).join(',')
            })
          }}
          options = {adBoardTypes||[]} 
        />

        {/* <TabsTypeOfPlace
          options={businessCategories||[]}
          label="Location"
          selectedTabs={locationTypes}
          onChange={(selectedTabs: any) => {
            setSearchParams({
              ...Object.fromEntries(searchParams.entries()),
              locationTypes:Object.entries(selectedTabs).filter(item=>item[1]).map(item=>item[0]).join(',')
            })
          }}
        /> */}
        {renderTabsPriceRage()}        
      </div>
    </div>
  );
};

export default TabFilters;
