import rightImg from "@/images/hld.png";
import Badge from "@/shared/Badge/Badge";
import NcImage from "@/shared/NcImage/NcImage";
import { FC } from "react";

export interface SectionSubscribe2Props {
  className?: string;
}

const SectionMediaPlayer: FC<SectionSubscribe2Props> = ({ className = "" }) => {

  const data ={
    "title": "How AdBoard booking marketplace works",
    "shortDescription": "Adboard Booking is an innovative online platform that simplifies digital signage management. Using Raspberry Pi as media players and PiSignage for content management, our system supports high-definition 4K content to ensure your advertisements look stunning and professional.",

    "steps": [
      {
        "step": "Book a Billboard",
        "description": "User books a billboard through our platform.",
        "feature": "Wide range of billboard options to choose from."
      },
      {
        "step": "Request Sent to Admin",
        "description": "Request goes to the billboard admin through our server.",
        "feature": "Efficient request handling and notification system."
      },
      {
        "step": "Admin Approval",
        "description": "Admin reviews and approves the request.",
        "feature": "Quick and seamless approval process."
      },
      {
        "step": "Content Download and Scheduling",
        "description": "Once approved, the media player downloads the content and schedules it as per the booking.",
        "feature": "Automated content download and scheduling for timely display."
      },
      {
        "step": "Play and Monitor Content",
        "description": "Media player plays the content on the billboard; monitor performance and manage updates.",
        "feature": "Real-time playback and monitoring for high-quality viewing experience."
      }
    ]
  }

  return (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className}`}
      data-nc-id="SectionSubscribe2"
    >
      <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="font-semibold text-4xl">{data.title}</h2>
        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
          {data.shortDescription}
        </span>
        <ul className="space-y-4 mt-10">
          {data.steps.map((step, index) => (
            <li key={index} className="flex items-center space-x-4">
              <Badge name={`0${index + 1}`} />
              <div>
                <h3 className="font-semibold">{step.step}</h3>
                <p className="text-neutral-500 dark:text-neutral-400">{step.description}</p>
              </div>
            </li>
          ))}
         
        </ul> 
      </div>
      <div className="w-full"> 
        <NcImage src={rightImg} className="w-[500px] mx-auto"/>
      </div>
    </div>
  );
};

export default SectionMediaPlayer;
