import LocationPointerIcon from "@/components/icon/LocationPointerIcon";
import { DATE_FORMAT } from "@/contains/contants";
import { GraphQLTypes, InputType, Selector } from "@/generated/zeus";
import { getDateString } from "@/lib/utils";
import Avatar from "@/shared/Avatar/Avatar";
import NcImage from "@/shared/NcImage/NcImage";
import moment from "moment";
import React, { FC, useState } from "react";
import { Link } from "react-router-dom";

export interface CampaignCardProps {
  className?: string;
  data: CampaignListFragment
}

export const campaignListSelector = Selector('Campaign')({
  id: true,
  priceSummary:{
    totalRent:true,
    payableRent:true,
    serviceCharge:true,
    rentPerDay:true,
    days:true
  },
  name: true,
  selectedDates:{
    startDate:true,
    endDate:true
  },
  businessCategory: {
    name:true
  },
  createdAt:true,
  payment:{
    totalDetails:{
      total:true
    }
  },
  assets:[{},{
    url:true
  }],
  bookings:[{},{
    id:true,
    adBoardId:true,
    priceSummary:{
      totalRent:true
    },
    adBoard:{
      galleryImgs:true,
      title:true,
      city:true
    }
  }]
});

export type CampaignListFragment = InputType<GraphQLTypes['Campaign'], typeof campaignListSelector>;
export type BookingFragment = CampaignListFragment['bookings'][0]

const CampaignCard: FC<CampaignCardProps> = ({ className = "", data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const days = moment(data.selectedDates.endDate as string).diff(moment(data.selectedDates.startDate as string), 'days')+1;

  const renderDetailTop = (booking:BookingFragment) => {
    return (
      <div>
        <div className="grid grid-cols-3 md:flex-row ">
         
          <div className="col-span-2 flex gap-4 mb-4 md:mb-0">
            
            <div className="w-16 h-16 lg:block hidden">
              <NcImage src={booking.adBoard.galleryImgs[0]} className=""/>
            </div>
            <div className=" space-y-2 text-sm">
              <div className="flex flex-col space-y-1">
                <Link to={'/booking/'+booking.id}>
                  <span className=" font-semibold  dark:text-neutral-400">
                    {booking.adBoard.title}
                  </span>
                </Link>
                <span className="  truncate w-40 text-neutral-500 flex gap-2">
                  <LocationPointerIcon/>
                  {booking.adBoard.city}
                </span>
              </div>
             
            </div>
          </div>
          <div className="">
            {/* <div className="border-l border-neutral-200 dark:border-neutral-700 md:mx-6 lg:mx-10"></div> */}

            <ul className=" text-sm text-neutral-500 dark:text-neutral-400 space-y-1 md:space-y-2">
              <li>Total</li>
              <li>{booking.priceSummary?.totalRent}</li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const renderDetail = () => {
    if (!isOpen) return null;
    return (
      <div className="p-4 md:p-8 border border-neutral-200 dark:border-neutral-700 rounded-2xl ">
        {data.bookings.map((booking,index)=>{
          return <>
            {index != 0 && <div className="my-7 md:my-4 space-y-4">
              <div className="border-t border-neutral-200 dark:border-neutral-700" />
            </div>}
            {renderDetailTop(booking)}
          </>
        })}
        
      </div>
    );
  };

  return (
    <div
      className={`nc-FlightCardgroup p-4 sm:p-6 relative bg-white dark:bg-neutral-900 border border-neutral-100
     dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 ${className}`}
      data-nc-id="FlightCard"
    >
      <div
        className={` sm:pr-20 relative  ${className}`}
        data-nc-id="FlightCard"
      >
        <Link to={"/campaign/"+data.id} className="absolute inset-0" />

        <span
          className={`absolute right-0 bottom-0 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${
            isOpen ? "transform -rotate-180" : ""
          }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <i className="text-xl las la-angle-down"></i>
        </span>

        <div className="flex  flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
          {/* LOGO IMG */}
          <div className="w-16 lg:w-16 flex-shrink-0 rounded-full overflow-hidden mr-4">
            {data.assets[0]?.url ? <div data-src={data.assets[0]?.url} className="w-8 h-8 Sirv"  />:<Avatar/>}
            {/* // <img src={data.id} className="w-10" alt="" /> */}
            
          </div>

          {/* FOR MOBILE RESPONSIVE */}
          <div className="block lg:hidden space-y-1">
            <div className="flex font-semibold">
              <div>
                <span>{getDateString(data.selectedDates.startDate)}</span>
              </div>
              <span className="w-12 flex justify-center">
                <i className=" text-2xl las la-long-arrow-alt-right"></i>
              </span>
              <div>
                <span>{getDateString(data.selectedDates.endDate)}</span>
              </div>
            </div>

            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              <span className="VG3hNb">{data.name}</span>
              <span className="mx-2">·</span>
              <span>{days} day(s)</span>
              <span className="mx-2">·</span>
              <span>₹ {data.priceSummary?.totalRent}</span>
            </div>
          </div>

          <div className="hidden lg:block flex-[4] whitespace-nowrap">
            <div className="font-medium text-lg">{moment(data.createdAt||"").format(DATE_FORMAT)}</div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              Created at
            </div>
          </div>
          {/* TIME - NAME */}
          <div className="hidden lg:block  min-w-[150px] flex-[4] ">
            <div className="font-medium text-lg">{data.name}</div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              Title
            </div>
          </div>

          {/* TIMME */}

          {/* TYPE */}
          <div className="hidden lg:block flex-[4] whitespace-nowrap">
            <div className="font-medium text-lg">{data.priceSummary?.totalRent}</div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              Amount
            </div>
          </div>

          {/* PRICE */}
          <div className="flex-[4] whitespace-nowrap sm:text-right">
            <div>
              <span className="text-xl font-semibold text-secondary-6000">
                {data.bookings.length}
              </span>
            </div>
            <div className="text-xs sm:text-sm text-neutral-500 font-normal mt-0.5">
              Adboard(s)
            </div>
          </div>
        </div>
      </div>

      {/* DETAIL */}
      {renderDetail()}
    </div>
  );
};

export default CampaignCard;
