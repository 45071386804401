import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

export const keywords = [
  "Digital billboard",
  "Billboard booking",
  "Digital hoarding",
  "Digital signage booking",
  "Advertise on billboards",
  "Billboard booking online",
  "Book digital billboard in Delhi",
  "Rent billboard space NCR",
  "Outdoor advertising costs Delhi",
  "Digital hoarding rates in Gurgaon",
  "Best locations for billboards in Noida",
  "How to advertise on billboards in Delhi",
  "Digital outdoor advertising companies NCR",
  "Billboard advertising packages Delhi",
  "LED billboard booking online",
  "Affordable outdoor advertising Delhi NCR",
  "High-traffic billboard locations in Delhi",
  "Digital signage advertising Delhi metro",
  "Billboard advertising ROI Delhi",
  "Last-minute billboard deals NCR",
  "Compare billboard prices Delhi",
  "Get paid for billboard",
];

const SeoMap = () => {
  const sitemapData = keywords.map(keyword => ({
    url: '/' + keyword.toLowerCase().replace(/ /g, '-'),
    title: keyword
  }));

  return (
    <Card className="w-full max-w-3xl mx-auto">
      <CardHeader>
        <CardTitle>Links</CardTitle>
      </CardHeader>
      <CardContent>
        <ul className="space-y-2">
          {sitemapData.map((item, index) => (
            <li key={index}>
              <a 
                href={item.url} 
                className="text-blue-600 hover:underline"
              >
                {item.title}
              </a>
            </li>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
};

export default SeoMap;