import { usePMutation } from "@/hooks/usePQuery";
import useUserContext from "@/hooks/useUserContext";
import { useTypedLazyQuery } from "@/hooks/useZeus";
import { DELETE_FILE } from "./api.p";
import { $, QueryMode, SortOrder } from "@/generated/zeus";

export function useMediaAssets(){
  
  const deleteFileMutation = usePMutation(DELETE_FILE)
  const {user} = useUserContext();
  const [getFiles,{ data, refetch, loading,fetchMore }] = useTypedLazyQuery({
    files:[{
      orderBy:[{
        createdAt:SortOrder.desc
      }],
      skip:$('skip','Int'),
      take:10,
      where:{
        "createdBy": {
          "is": {
            "email": {
              "equals": user?.email
            }
          }
        },
        name:{
          contains:$('search','String') ,
          mode:QueryMode.insensitive
        },
        type:{
          contains:$('type','String')
        }
      }
    },{
      id:true,
      name:true,
      meta:{
        height:true,
        width:true,
        duration:true
      },
      url:true,
      type:true,
      status:true,
      size:true,
      bookings:[{},{
        bookingStatus:true
      }],
    }]
  }, {
    notifyOnNetworkStatusChange: true,
  });

  return {
    getFiles,
    deleteFileMutation,
    data,
    refetch,
    loading,
    fetchMore
  }
}

export type FileData = NonNullable<ReturnType<(typeof useMediaAssets)>['data']>['files'][0];