import { Tab } from "@headlessui/react";
import CarCard, { adBoardSelector } from "@/components/CarCard/CarCard";
// import CommentListing from "@/components/CommentListing/CommentListing";
// import StartRating from "@/components/StartRating/StartRating";
import React, { FC, Fragment, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import Avatar from "@/shared/Avatar/Avatar";
import ButtonSecondary from "@/shared/Button/ButtonSecondary";
// import SocialsList from "@/shared/SocialsList/SocialsList";
import { useParams } from "react-router-dom";
import { useTypedQuery } from "@/hooks/useZeus";
import Loader from "@/shared/FullScreenLoader/Loading";
import moment from "moment";
import GoogleMapReact from "google-map-react";
import { GMAP_API_KEY } from "@/contains/contants";
import LocationMarker from "@/components/AnyReactComponent/LocationMarker";
import { setPageTitle } from "@/lib/utils";

export interface AuthorPageProps {
  className?: string;
}

const PublisherPage: FC<AuthorPageProps> = ({ className = "" }) => {
  const [categories] = useState(["Adboards"]);
  const [currentHover, setCurrentHover] = useState<string | null>(null);
  const mapsRef = React.useRef<any>(null);
  const mapRef = React.useRef<any>(null);
  const [mapsLoaded, setMapsLoaded] = React.useState(false);  
  const {
    workspaceKey
  } = useParams<{
    workspaceKey:string
  }>();

  const {data} = useTypedQuery({
    workspace:[{
      where:{
        key:workspaceKey
      },
    },{
      createdAt:true,
      name:true,
      avatar:true,
      adBoards:[{
        where:{
          isLive:{
            equals:true
          }
        }
      },adBoardSelector]
    }]
  })

  useEffect(()=>{
    setPageTitle(`${workspaceKey} | AdBoard Booking`)
  },[])

  const players = useMemo(()=>data?.workspace?.adBoards.map(adBoard=>adBoard.players.map(item=>({...item,adBoardId:adBoard.id}))).flat()||[],
    [data?.workspace?.adBoards]
  )

  useEffect(()=>{
    if(players.length && mapsLoaded){
      const bounds = new mapsRef.current.LatLngBounds();
      for(const item of players){
        if(item.map){
          bounds.extend({ lat: item.map.lat, lng: item.map.lng })
        }
      }
      mapRef.current.fitBounds(bounds);
    }
  },[players,mapsLoaded])

  if(!data?.workspace){
    return <Loader/>
  }

  const renderSidebar = () => {
    return (
      <div className=" w-full flex flex-col items-center text-center sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-7 px-0 sm:p-6 xl:p-8">
        <Avatar
          imgUrl={data.workspace?.avatar}
          hasChecked
          hasCheckedClass="w-6 h-6 -top-0.5 right-2"
          sizeClass="w-28 h-28"
        />

        {/* ---- */}
        <div className="space-y-3 text-center flex flex-col items-center">
          <h2 className="text-3xl font-semibold">{data?.workspace?.name}</h2>
          {/* <StartRating className="!text-base" /> */}
        </div>

        {/* ---- */}
        {/* <p className="text-neutral-500 dark:text-neutral-400">
          Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides
          accommodation, an outdoor.
        </p> */}

        {/* ---- */}
        {/* <SocialsList
          className="!space-x-3"
          itemClass="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
        /> */}

        {/* ---- */}
        <div className="border-b border-neutral-200 dark:border-neutral-700 w-14"></div>

        {/* ---- */}
        <div className="space-y-4">
          {/* <div className="flex items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-neutral-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
              />
            </svg>
            <span className="text-neutral-6000 dark:text-neutral-300">
              Ha Noi, Viet Nam
            </span>
          </div>
          */}
          <div className="flex items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-neutral-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span className="text-neutral-6000 dark:text-neutral-300">
              {data.workspace?.adBoards.length} listings
            </span>
          </div> 

          <div className="flex items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-neutral-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span className="text-neutral-6000 dark:text-neutral-300">
              Joined in {moment(data.workspace?.createdAt).format('MMM-YYYY')}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderMap= ()=>{
    return <div className=" w-full sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700">
      <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
        <div className="rounded-xl overflow-hidden">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: GMAP_API_KEY,
            }}
            yesIWantToUseGoogleMapApiInternals
            defaultZoom={15}
            defaultCenter={{
              lat: 21.027763,
              lng: 105.83416,
            }}
            
            onGoogleApiLoaded={({maps,map}) => {
              mapsRef.current = maps  
              mapRef.current = map;
              setMapsLoaded(true)
            }}
          >
            {/* <LocationMarker lat={listingDetails.map.lat} lng={listingDetails.map.lng} /> */}
            {players.map(item=>{
              if(item.map){
                return <LocationMarker isHovered={item.adBoardId == currentHover} lat={item.map?.lat} lng={item.map.lng} />
              }
            })}
          </GoogleMapReact>
        </div>
      </div>
    </div>
  }

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">{data?.workspace?.name}'s listings</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {data.workspace?.name}'s listings is very rich and diverse in many categories such as: Adboards, Billboards, etc.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          <Tab.Group>
            <Tab.List className="flex space-x-1 overflow-x-auto">
              {categories.map((item) => (
                <Tab key={item} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                        selected
                          ? "bg-secondary-900 text-secondary-50 "
                          : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      } `}
                    >
                      {item}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                  
                  {data.workspace?.adBoards.map((stay) => (
                    <div 
                      onMouseLeave={()=>setCurrentHover(null)}
                      onMouseEnter={()=>setCurrentHover(stay.id)}>
                      <CarCard key={stay.id} data={stay} />
                    </div>
                  ))}
                </div>
                <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary>Show me more</ButtonSecondary>
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  };

  // const renderSection2 = () => {
  //   return (
  //     <div className="listingSection__wrap">
  //       {/* HEADING */}
  //       <h2 className="text-2xl font-semibold">Reviews (23 reviews)</h2>
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

  //       {/* comment */}
  //       <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
  //         <CommentListing hasListingTitle className="pb-8" />
  //         <CommentListing hasListingTitle className="py-8" />
  //         <CommentListing hasListingTitle className="py-8" />
  //         <CommentListing hasListingTitle className="py-8" />
  //         <div className="pt-8">
  //           <ButtonSecondary>View more 20 reviews</ButtonSecondary>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <div className={`nc-AuthorPage ${className}`} data-nc-id="AuthorPage">
      <Helmet>
        <title>Login | AdBoard Booking</title>
      </Helmet>
      <main className="container mt-12 mb-24 lg:mb-32 flex flex-col lg:flex-row">
        <div className="block flex-grow mb-24 lg:mb-0">
          <div className="lg:sticky lg:top-24 flex gap-4 flex-col">
            {renderSidebar()}

            {renderMap()}
          </div>
        </div>
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0">
          {renderSection1()}
          {/* {renderSection2()} */}
        </div>
      </main>
    </div>
  );
};

export default PublisherPage;
