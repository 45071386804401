import { FileStatus } from "@/generated/zeus";
import React from "react";
import { toast } from "react-toastify";
import useUserContext from "./useUserContext";
import { mutation } from "./useZeus";
import { AssetFile } from "@/containers/CheckOutPage/types";
import {  fetchWithProgress } from "./fetch";
export interface FileStat {
  mtime: string
  ctime: string
  contentType: string
  size: number
  isDirectory: boolean
  meta: Meta
}

export interface Meta {
  width: number
  height: number
  duration: number
}

export function useFileUploader(){
  
  const [loading,setLoading] = React.useState(false);
  const {user} = useUserContext();

  // async function uploadFileToSirv({
  //   file
  // }:{
  //   file:File,
  // },progressCallback?:(percent:number)=>void){

  //   const {token} = await fetch(import.meta.env.VITE_PUBLIC_API_URL+"/api/sirv/token").then(res=>res.json()).catch(e=>{
  //     console.error(e);
  //     toast.error("Internal server error, please try again later");
  //     throw new Error("Sirv token not found");
  //   });

  //   if(!token){
  //     throw new Error("Sirv token not found");
  //   }

  //   const filePath  = '/uploads/'+moment().format("MMM-YYYY")+'/' +ncNanoId()+"_"+ encodeURIComponent(file.name);
  //   const {url} = await fetchWithProgress('https://api.sirv.com/v2/files/upload?filename='+(filePath),{
  //     method: "POST",
  //     body: file,
  //     credentials:'omit',
  //     headers: {
  //       // "Content-length": file.size + "",
  //       'authorization': 'Bearer ' + token,
  //       'content-type': file.type
  //     }
  //   },progressCallback).then(()=>{
  //     const url =  "https://aplivili.sirv.com"+filePath
  //     return {
  //       url,filePath
  //     }
  //   }).finally(()=>{
  //     setLoading(false)
  //   })

  //   const fileStats = await getFileStats(filePath,{
  //     token
  //   })  

  //   return {
  //     url,
  //     filePath,
  //     fileStats
  //   }
  // }

  // async function getFileStats(filePath:string,{token}:{token:string}):Promise<FileStat>{
  //   return fetch("https://api.sirv.com/v2/files/stat?filename="+filePath, {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       "Content-Type": "application/json"
  //     }
  //   }).then(res=>res.json())
  // }

  async function getFileAttributes(file:File):Promise<{
    width:number,
    height:number,
    duration: number,
    size: number,
  }> {
    return new Promise((resolve, reject) => {
      const fileAttributes = {
        width: 0,
        height: 0,
        duration: 0,
        size: file.size
      };
  
      const fileType = file.type;
  
      // For Image
      if (fileType.startsWith("image")) {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
          fileAttributes.width = img.naturalWidth;
          fileAttributes.height = img.naturalHeight;
          URL.revokeObjectURL(img.src);
          resolve(fileAttributes);
        };
        img.onerror = (error) => {
          reject(`Error loading image: ${error}`);
        };
      }
      // For Video
      else if (fileType.startsWith("video")) {
        const video = document.createElement("video");
        video.src = URL.createObjectURL(file);
        video.preload = "metadata";
        
        video.onloadedmetadata = () => {
          fileAttributes.width = video.videoWidth;
          fileAttributes.height = video.videoHeight;
          fileAttributes.duration = video.duration;
          URL.revokeObjectURL(video.src);
          resolve(fileAttributes);
        };
        
        video.onerror = (error) => {
          reject(`Error loading video: ${error}`);
        };
      } else {
        reject("Unsupported file type");
      }
    });
  }

  async function uploadFileUsingApi(file:File,uploadFilepath:string, progressCallback?:(progress:number)=>void):Promise<{
    url:string
  }|undefined>{
    
    if (!file) {
      alert("Please select a file to upload.");
      return;
    }

    const form = new FormData();
    form.append("image", file);

    const data = await fetchWithProgress("/api/file/upload?uploadFilepath="+uploadFilepath, {
      method: "POST",
      body: form,
    },progressCallback) as {url:string};

    return {
      url:data.url
    }
  
  }
  
  async function uploadFile(file:File,progressCallback?:(percent:number)=>void):Promise<AssetFile|undefined>{
    setLoading(true);
    
    if(!user?.uid){
      toast.error("User not logged in");
      throw new Error("User not logged in");
    }

    // const {url,fileStats} = await uploadFileToSirv({
    //   file,
    // },progressCallback);
    const {width,height,duration} = await getFileAttributes(file)

    const res = await uploadFileUsingApi(file,'user-images/'+user.id,progressCallback)
    if(!res?.url){
      toast.error("Failed to upload file. Please try again.");
      return;
    }
    const url = res.url;

    const {createOneFile} = await mutation({
      createOneFile:[{
        data:{
          ctime:duration+"",
          mtime:duration+"",
          filename:file.name, 
          isDirectory:false,
          meta:{
            duration,
            width,
            height
          },
          contentType:file.type,
          name:file.name,
          url,
          status:FileStatus.REQUEST_APPROVAL,
          size:file.size,
          type:file.type,
          createdBy:{
            connect:{
              uid:user.uid
            }
          }
        }
      },{
        id:true
      }]
    }).catch(e=>{
      console.error(e);
      toast.error("Failed to upload file, please try again later");
      throw new Error("File not uploaded");
    })

    return {
      ...file,
      meta:{
        width,
        height,
        duration
      },
      url,
      id: createOneFile.id
    }
  }
  
  return {
    loading,
    uploadFile
  }
}