import React from 'react';
import { Button } from "@/components/ui/button";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import BgGlassmorphism from '@/components/BgGlassmorphism/BgGlassmorphism';
import SectionHero from '../PageHome/SectionHero';
import { Link } from 'react-router-dom';

const RetailBusinessPage = () => {
  return (
    <div className="min-h-screen bg-gray-100 text-gray-800">
      {/* Hero Section */}
      <header className="bg-gradient-to-r from-green-500 to-blue-600 py-12 text-white">
        <div className="container mx-auto px-4 text-center">
          <h1 className="text-4xl font-bold">Revolutionize Retail with AdBoard Booking</h1>
          <p className="mt-4 text-lg">
            Drive sales, engage customers, and elevate your retail environment with our dynamic digital signage solutions.
          </p>
          <div className="mt-6">
            <Button className="bg-white text-green-600 hover:text-green-700 hover:bg-gray-100 px-6 py-3 rounded-lg text-lg">
              Get Started Today
            </Button>
          </div>
        </div>
      </header>

      {/* Benefits Section */}
      <section className="py-10">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center">Why Digital Signage for Retail?</h2>
          <p className="mt-4 text-center text-lg">
            Transform the way you interact with customers through immersive and impactful displays.
          </p>
          <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {[
              {
                title: "Boost Sales",
                description: "Showcase promotions, discounts, and new arrivals in real-time to drive purchases.",
              },
              {
                title: "Engage Customers",
                description: "Create visually compelling content that grabs attention and keeps customers informed.",
              },
              {
                title: "Enhance Brand Loyalty",
                description: "Build trust with consistent branding and personalized messaging.",
              },
              {
                title: "Save Time and Costs",
                description: "Eliminate the need for printed materials with instant updates across screens.",
              },
            ].map((benefit, index) => (
              <Card key={index} className="shadow-lg">
                <CardHeader>
                  <CardTitle className="text-center text-lg font-semibold">{benefit.title}</CardTitle>
                </CardHeader>
                <CardContent className="p-4 text-center">
                  <p className="text-sm">{benefit.description}</p>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="bg-gray-200 py-10">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center">Key Features for Retail</h2>
          <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {[
              {
                title: "Real-Time Content Updates",
                description: "Keep your promotions and offers current with instant content changes.",
              },
              {
                title: "Interactive Displays",
                description: "Engage customers with touch-enabled screens for browsing products and offers.",
              },
              {
                title: "Remote Management",
                description: "Control and schedule content for multiple locations from a single dashboard.",
              },
              {
                title: "Custom Templates",
                description: "Use pre-designed layouts to create professional visuals effortlessly.",
              },
            ].map((feature, index) => (
              <Card key={index} className="shadow-lg">
                <CardHeader>
                  <CardTitle className="text-center text-lg font-semibold">{feature.title}</CardTitle>
                </CardHeader>
                <CardContent className="p-4 text-center">
                  <p className="text-sm">{feature.description}</p>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </section>

      {/* Use Cases Section */}
      <section className="py-10">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center">Retail Use Cases</h2>
          <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {[
              {
                title: "Storefront Displays",
                description: "Attract foot traffic with vibrant, eye-catching signage.",
              },
              {
                title: "In-Store Promotions",
                description: "Highlight discounts and exclusive deals to encourage impulse purchases.",
              },
              {
                title: "Interactive Catalogs",
                description: "Let customers explore your product catalog on interactive kiosks.",
              },
              {
                title: "Employee Communication",
                description: "Share announcements and updates with staff on dedicated screens.",
              },
              {
                title: "Queue Management",
                description: "Streamline checkout processes with screens showing estimated wait times.",
              },
              {
                title: "Event Announcements",
                description: "Promote upcoming events or special hours directly in your store.",
              },
            ].map((useCase, index) => (
              <Card key={index} className="shadow-lg">
                <CardHeader>
                  <CardTitle className="text-center text-lg font-semibold">{useCase.title}</CardTitle>
                </CardHeader>
                <CardContent className="p-4 text-center">
                  <p className="text-sm">{useCase.description}</p>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="bg-gray-200 py-10">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center">What Our Clients Say</h2>
          <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {[
              {
                client: "Urban Trends",
                feedback: "The dynamic displays have increased our foot traffic by 30% within the first month!",
              },
              {
                client: "Fashion Avenue",
                feedback: "We love how easy it is to update promotions across all our stores in real time.",
              },
              {
                client: "Tech Central",
                feedback: "Our customers spend more time browsing thanks to the engaging product showcases.",
              },
            ].map((testimonial, index) => (
              <Card key={index} className="shadow-lg">
                <CardContent className="p-6 text-center">
                  <p className="italic">"{testimonial.feedback}"</p>
                  <p className="mt-4 font-semibold">- {testimonial.client}</p>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-10">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold">Transform Your Retail Business Today</h2>
          <p className="mt-4 text-lg">
            Ready to make an impact with digital signage? Get in touch and let us show you how AdBoard Booking can revolutionize your retail experience.
          </p>
          <div className="mt-6">
            <Link to="/contact">
              <Button className="bg-green-600 hover:bg-green-700 px-6 py-3 text-white text-lg rounded-lg">
              Contact Us
              </Button>
            </Link> 
          </div>
        </div>
      </section>

    </div>
  );
};

export default function(){
  return <div className="nc-PageHome relative overflow-hidden">
    {/* GLASSMOPHIN */}
    <BgGlassmorphism />

    <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
      {/* SECTION HERO */}
      <SectionHero/>

      <RetailBusinessPage/>
    </div>
  </div>
}
