import React, { FC, useRef } from "react";
import ButtonCircle from "@/shared/Button/ButtonCircle";
import rightImg from "@/images/SVG-subcribe2.png";
import NcImage from "@/shared/NcImage/NcImage";
import Badge from "@/shared/Badge/Badge";
import Input from "@/shared/Input/Input";
import { useMutation } from "@apollo/client";
import { graphql } from "@/gql";
import { validateEmail } from "@/utils/validators";

export interface SectionSubscribe2Props {
  className?: string;
}

const SUBSCRIBE = graphql(`
mutation Subscribe($email: String!) {
  subscribe(email: $email) {
    success
  }
}
`)

function useSubscription() {
  const [subscribe] = useMutation(SUBSCRIBE);
  return {
    subscribe
  }
}

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({ className = "" }) => {
  const { subscribe } = useSubscription();
  const emailRef = useRef<HTMLInputElement>(null);

  async function handleClick() {
    if (emailRef.current && emailRef.current.value.length > 0 && validateEmail(emailRef.current.value)) {
      const email = emailRef.current?.value
      await subscribe({
        variables: {
          email
        }
      });
      alert('Subscribed successfully')
      emailRef.current.value='';
    }else{
      alert('Please enter a valid email address to subscribe.')
    }
  }

  return (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className}`}
      data-nc-id="SectionSubscribe2"
    >
      <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="font-semibold text-4xl">Join our newsletter 🎉</h2>
        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
          Stay updated with the latest in digital advertising. Subscribe now!
        </span>
        <ul className="space-y-4 mt-10">
          <li className="flex items-center space-x-4">
            <Badge name="01" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              Get more discount
            </span>
          </li>
        </ul>
        <form className="mt-10 relative max-w-sm" action="#">
          <Input
            ref={emailRef}
            required
            aria-required
            placeholder="Enter your email"
            type="email"
            rounded="rounded-full"
          />
          <ButtonCircle
            type="button"
            onClick={handleClick}
            className="absolute transform top-1/2 -translate-y-1/2 right-[5px]"
          >
            <i className="las la-arrow-right text-xl"></i>
          </ButtonCircle>
        </form>
      </div>
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
    </div>
  );
};

export default SectionSubscribe2;
