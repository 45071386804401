import BackgroundSection from "@/components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "@/components/BgGlassmorphism/BgGlassmorphism";
import SectionHero2 from "@/components/SectionHero/SectionHero2";
import ContactUs from "../PageContact/ContactUs";
// import PageHomeMap from "./PageHomeMap";
import { SectionGridFeatureAdboard } from "./SectionGripFeatureAdboard";
import { Helmet } from "react-helmet";
import _ from "lodash";
import { baseTitle } from "@/contains/contants";
// import SectionMediaPlayer from "./SectionMediaPlayer";
import { setPageTitle } from "@/lib/utils";
import { useEffect } from "react";
import SectionClientSay from "@/components/SectionClientSay/SectionClientSay";
import SectionGridAuthorBox from "@/components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionBecomeAnAuthor from "@/components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import PageHomeMap from "./PageHomeMap";

function PageHome2() {

  const title = window.location.pathname.substring(1)
  useEffect(()=>{
    setPageTitle(_.startCase(title)||"Home" +` | ${baseTitle}`)
  },[title])

  return (
    <div className="nc-PageHome2 relative overflow-hidden">
      <Helmet>
        <link rel="canonical" href="https://www.adboardbooking.com" />
      </Helmet>
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-10 mb-24 lg:space-y-28 lg:mb-28">
        {/* <SectionHero2 className="" /> */}
        <SectionHero2 className="pt-10 lg:pt-16 lg:pb-16" />

        {/* SECTION */}
        <SectionGridFeatureAdboard/>

        {/* SECTION2 */}
        {/* <SectionOurFeatures type="type2"  /> */}

        {/* SECTION */}
        {/* <SectionDowloadApp /> */}
       
        {/* <div className="relative lg:py-8 pb-8 lg:mt-8">
          <BackgroundSection />
          <SectionHowItWorkAdvertiser     className="pt-4 lg:pt-0"   />
          </div> */}
        
        <div className="relative py-16">
          <BackgroundSection className="bg-orange-50"/>
          <SectionGridAuthorBox />
          {/* <FAQ/> */}
          {/* <SectionMediaPlayer/> */}
        </div>
        <SectionBecomeAnAuthor/>
        <div className="relative py-16">
          <BackgroundSection />
          {/* <FAQ/> */}
          {/* <SectionMediaPlayer/> */}
          <SectionClientSay uniqueClassName="client-say"/>
        </div>
        <PageHomeMap/>
        <ContactUs/>
        {/* SECTION */}
        
      </div>
    </div>
  );
}

export default PageHome2;
