
import { useEffect } from 'react';
import firebase from 'firebase/compat/app';

export default function PageLogout() {
  
  useEffect(()=>{
    firebase.auth().signOut().then(()=>{
      window.location.href="/"
    })
  },[])

  return (
    <div></div>
  )
}
