import AssetPreview from '@/components/AssetPreview';
import { SortOrder } from '@/generated/zeus';
import { useTypedQuery } from '@/hooks/useZeus';
import Loader from '@/shared/FullScreenLoader/Loading';
import moment from 'moment';
import { useEffect } from 'react';

export default function BookingChangelog({bookingId,counter}:{bookingId:string,counter?:number}) {

  const {data,refetch,loading} = useTypedQuery({
    audits:[{
      take:5,
      orderBy:[{
        createdAt:SortOrder.desc
      }],
      where:{
        entity:{
          is:{
            type:{
              equals:'BOOKING',
            },
            id:{
              equals:bookingId
            }
          }
        }
      }
    },{
      message:true,
      action:true,
      createdAt:true,
      user:{
        displayName:true
      },
      updates:true
    }]

  })

  const changeLogs = [...data?.audits||[]]?.sort((a,b)=>new Date(a?.createdAt as string).getTime() > new Date(b?.createdAt as string).getTime()?-1:1)

  useEffect(()=>{
    refetch()
  },[counter] )

  function displayMetadata(item:any){
    switch(item?.action){
    case 'ASSET_UPDATED':{

      const url = JSON.parse(item?.updates).url
      if(!url){
        return <></>;
      }
      return <div className=''>
        <AssetPreview className="max-w-sm h-48 rounded-lg overflow-hidden bg-gray-100" url={url}/>
      </div>
    }
    case 'UPDATE_BOOKING':{
      if(item?.updates.bookingStatus == 'SYSTEM_REJECTED'){
        return <p>Booking rejected due to pending approval. You should get your refund withing 7 days.</p>
      }
    }      
    }
   
    return <></>
  }

  if(loading){

    return <div className='relative p-6'>
      <Loader></Loader>
    </div>
  }

  return (
    <>
      <div className='ml-2'>
        <ol className="relative border-l border-gray-200 dark:border-gray-700">                  
          {changeLogs?.map((item,i)=>{
            return <li className="mb-10 ml-4" key={i}>
              <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
              <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">{moment(item?.createdAt).format('LLL')}</time>
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">{item?.message}</h3>
              <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">Updated by: {item?.user?.displayName||"ADMIN"}</p>
              {item && displayMetadata(item)}
            </li>
          })}
        </ol>
      </div>
    </>
  )
}
