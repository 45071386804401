/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	Query:{
		aggregateAdBoard:{
			where:"AdBoardWhereInput",
			orderBy:"AdBoardOrderByWithRelationInput",
			cursor:"AdBoardWhereUniqueInput"
		},
		findFirstAdBoard:{
			where:"AdBoardWhereInput",
			orderBy:"AdBoardOrderByWithRelationInput",
			cursor:"AdBoardWhereUniqueInput",
			distinct:"AdBoardScalarFieldEnum"
		},
		findFirstAdBoardOrThrow:{
			where:"AdBoardWhereInput",
			orderBy:"AdBoardOrderByWithRelationInput",
			cursor:"AdBoardWhereUniqueInput",
			distinct:"AdBoardScalarFieldEnum"
		},
		adBoards:{
			where:"AdBoardWhereInput",
			orderBy:"AdBoardOrderByWithRelationInput",
			cursor:"AdBoardWhereUniqueInput",
			distinct:"AdBoardScalarFieldEnum"
		},
		adBoard:{
			where:"AdBoardWhereUniqueInput"
		},
		getAdBoard:{
			where:"AdBoardWhereUniqueInput"
		},
		groupByAdBoard:{
			where:"AdBoardWhereInput",
			orderBy:"AdBoardOrderByWithAggregationInput",
			by:"AdBoardScalarFieldEnum",
			having:"AdBoardScalarWhereWithAggregatesInput"
		},
		aggregateAdBoardGroup:{
			where:"AdBoardGroupWhereInput",
			orderBy:"AdBoardGroupOrderByWithRelationInput",
			cursor:"AdBoardGroupWhereUniqueInput"
		},
		findFirstAdBoardGroup:{
			where:"AdBoardGroupWhereInput",
			orderBy:"AdBoardGroupOrderByWithRelationInput",
			cursor:"AdBoardGroupWhereUniqueInput",
			distinct:"AdBoardGroupScalarFieldEnum"
		},
		findFirstAdBoardGroupOrThrow:{
			where:"AdBoardGroupWhereInput",
			orderBy:"AdBoardGroupOrderByWithRelationInput",
			cursor:"AdBoardGroupWhereUniqueInput",
			distinct:"AdBoardGroupScalarFieldEnum"
		},
		adBoardGroups:{
			where:"AdBoardGroupWhereInput",
			orderBy:"AdBoardGroupOrderByWithRelationInput",
			cursor:"AdBoardGroupWhereUniqueInput",
			distinct:"AdBoardGroupScalarFieldEnum"
		},
		adBoardGroup:{
			where:"AdBoardGroupWhereUniqueInput"
		},
		getAdBoardGroup:{
			where:"AdBoardGroupWhereUniqueInput"
		},
		groupByAdBoardGroup:{
			where:"AdBoardGroupWhereInput",
			orderBy:"AdBoardGroupOrderByWithAggregationInput",
			by:"AdBoardGroupScalarFieldEnum",
			having:"AdBoardGroupScalarWhereWithAggregatesInput"
		},
		aggregateAdBoardType:{
			where:"AdBoardTypeWhereInput",
			orderBy:"AdBoardTypeOrderByWithRelationInput",
			cursor:"AdBoardTypeWhereUniqueInput"
		},
		findFirstAdBoardType:{
			where:"AdBoardTypeWhereInput",
			orderBy:"AdBoardTypeOrderByWithRelationInput",
			cursor:"AdBoardTypeWhereUniqueInput",
			distinct:"AdBoardTypeScalarFieldEnum"
		},
		findFirstAdBoardTypeOrThrow:{
			where:"AdBoardTypeWhereInput",
			orderBy:"AdBoardTypeOrderByWithRelationInput",
			cursor:"AdBoardTypeWhereUniqueInput",
			distinct:"AdBoardTypeScalarFieldEnum"
		},
		adBoardTypes:{
			where:"AdBoardTypeWhereInput",
			orderBy:"AdBoardTypeOrderByWithRelationInput",
			cursor:"AdBoardTypeWhereUniqueInput",
			distinct:"AdBoardTypeScalarFieldEnum"
		},
		adBoardType:{
			where:"AdBoardTypeWhereUniqueInput"
		},
		getAdBoardType:{
			where:"AdBoardTypeWhereUniqueInput"
		},
		groupByAdBoardType:{
			where:"AdBoardTypeWhereInput",
			orderBy:"AdBoardTypeOrderByWithAggregationInput",
			by:"AdBoardTypeScalarFieldEnum",
			having:"AdBoardTypeScalarWhereWithAggregatesInput"
		},
		aggregateAudit:{
			where:"AuditWhereInput",
			orderBy:"AuditOrderByWithRelationInput",
			cursor:"AuditWhereUniqueInput"
		},
		findFirstAudit:{
			where:"AuditWhereInput",
			orderBy:"AuditOrderByWithRelationInput",
			cursor:"AuditWhereUniqueInput",
			distinct:"AuditScalarFieldEnum"
		},
		findFirstAuditOrThrow:{
			where:"AuditWhereInput",
			orderBy:"AuditOrderByWithRelationInput",
			cursor:"AuditWhereUniqueInput",
			distinct:"AuditScalarFieldEnum"
		},
		audits:{
			where:"AuditWhereInput",
			orderBy:"AuditOrderByWithRelationInput",
			cursor:"AuditWhereUniqueInput",
			distinct:"AuditScalarFieldEnum"
		},
		audit:{
			where:"AuditWhereUniqueInput"
		},
		getAudit:{
			where:"AuditWhereUniqueInput"
		},
		groupByAudit:{
			where:"AuditWhereInput",
			orderBy:"AuditOrderByWithAggregationInput",
			by:"AuditScalarFieldEnum",
			having:"AuditScalarWhereWithAggregatesInput"
		},
		aggregateBooking:{
			where:"BookingWhereInput",
			orderBy:"BookingOrderByWithRelationInput",
			cursor:"BookingWhereUniqueInput"
		},
		findFirstBooking:{
			where:"BookingWhereInput",
			orderBy:"BookingOrderByWithRelationInput",
			cursor:"BookingWhereUniqueInput",
			distinct:"BookingScalarFieldEnum"
		},
		findFirstBookingOrThrow:{
			where:"BookingWhereInput",
			orderBy:"BookingOrderByWithRelationInput",
			cursor:"BookingWhereUniqueInput",
			distinct:"BookingScalarFieldEnum"
		},
		bookings:{
			where:"BookingWhereInput",
			orderBy:"BookingOrderByWithRelationInput",
			cursor:"BookingWhereUniqueInput",
			distinct:"BookingScalarFieldEnum"
		},
		booking:{
			where:"BookingWhereUniqueInput"
		},
		getBooking:{
			where:"BookingWhereUniqueInput"
		},
		groupByBooking:{
			where:"BookingWhereInput",
			orderBy:"BookingOrderByWithAggregationInput",
			by:"BookingScalarFieldEnum",
			having:"BookingScalarWhereWithAggregatesInput"
		},
		aggregateBusinessCategory:{
			where:"BusinessCategoryWhereInput",
			orderBy:"BusinessCategoryOrderByWithRelationInput",
			cursor:"BusinessCategoryWhereUniqueInput"
		},
		findFirstBusinessCategory:{
			where:"BusinessCategoryWhereInput",
			orderBy:"BusinessCategoryOrderByWithRelationInput",
			cursor:"BusinessCategoryWhereUniqueInput",
			distinct:"BusinessCategoryScalarFieldEnum"
		},
		findFirstBusinessCategoryOrThrow:{
			where:"BusinessCategoryWhereInput",
			orderBy:"BusinessCategoryOrderByWithRelationInput",
			cursor:"BusinessCategoryWhereUniqueInput",
			distinct:"BusinessCategoryScalarFieldEnum"
		},
		businessCategories:{
			where:"BusinessCategoryWhereInput",
			orderBy:"BusinessCategoryOrderByWithRelationInput",
			cursor:"BusinessCategoryWhereUniqueInput",
			distinct:"BusinessCategoryScalarFieldEnum"
		},
		businessCategory:{
			where:"BusinessCategoryWhereUniqueInput"
		},
		getBusinessCategory:{
			where:"BusinessCategoryWhereUniqueInput"
		},
		groupByBusinessCategory:{
			where:"BusinessCategoryWhereInput",
			orderBy:"BusinessCategoryOrderByWithAggregationInput",
			by:"BusinessCategoryScalarFieldEnum",
			having:"BusinessCategoryScalarWhereWithAggregatesInput"
		},
		aggregateCamera:{
			where:"CameraWhereInput",
			orderBy:"CameraOrderByWithRelationInput",
			cursor:"CameraWhereUniqueInput"
		},
		findFirstCamera:{
			where:"CameraWhereInput",
			orderBy:"CameraOrderByWithRelationInput",
			cursor:"CameraWhereUniqueInput",
			distinct:"CameraScalarFieldEnum"
		},
		findFirstCameraOrThrow:{
			where:"CameraWhereInput",
			orderBy:"CameraOrderByWithRelationInput",
			cursor:"CameraWhereUniqueInput",
			distinct:"CameraScalarFieldEnum"
		},
		cameras:{
			where:"CameraWhereInput",
			orderBy:"CameraOrderByWithRelationInput",
			cursor:"CameraWhereUniqueInput",
			distinct:"CameraScalarFieldEnum"
		},
		camera:{
			where:"CameraWhereUniqueInput"
		},
		getCamera:{
			where:"CameraWhereUniqueInput"
		},
		groupByCamera:{
			where:"CameraWhereInput",
			orderBy:"CameraOrderByWithAggregationInput",
			by:"CameraScalarFieldEnum",
			having:"CameraScalarWhereWithAggregatesInput"
		},
		aggregateCameraFeed:{
			where:"CameraFeedWhereInput",
			orderBy:"CameraFeedOrderByWithRelationInput",
			cursor:"CameraFeedWhereUniqueInput"
		},
		findFirstCameraFeed:{
			where:"CameraFeedWhereInput",
			orderBy:"CameraFeedOrderByWithRelationInput",
			cursor:"CameraFeedWhereUniqueInput",
			distinct:"CameraFeedScalarFieldEnum"
		},
		findFirstCameraFeedOrThrow:{
			where:"CameraFeedWhereInput",
			orderBy:"CameraFeedOrderByWithRelationInput",
			cursor:"CameraFeedWhereUniqueInput",
			distinct:"CameraFeedScalarFieldEnum"
		},
		cameraFeeds:{
			where:"CameraFeedWhereInput",
			orderBy:"CameraFeedOrderByWithRelationInput",
			cursor:"CameraFeedWhereUniqueInput",
			distinct:"CameraFeedScalarFieldEnum"
		},
		cameraFeed:{
			where:"CameraFeedWhereUniqueInput"
		},
		getCameraFeed:{
			where:"CameraFeedWhereUniqueInput"
		},
		groupByCameraFeed:{
			where:"CameraFeedWhereInput",
			orderBy:"CameraFeedOrderByWithAggregationInput",
			by:"CameraFeedScalarFieldEnum",
			having:"CameraFeedScalarWhereWithAggregatesInput"
		},
		aggregateCameraServer:{
			where:"CameraServerWhereInput",
			orderBy:"CameraServerOrderByWithRelationInput",
			cursor:"CameraServerWhereUniqueInput"
		},
		findFirstCameraServer:{
			where:"CameraServerWhereInput",
			orderBy:"CameraServerOrderByWithRelationInput",
			cursor:"CameraServerWhereUniqueInput",
			distinct:"CameraServerScalarFieldEnum"
		},
		findFirstCameraServerOrThrow:{
			where:"CameraServerWhereInput",
			orderBy:"CameraServerOrderByWithRelationInput",
			cursor:"CameraServerWhereUniqueInput",
			distinct:"CameraServerScalarFieldEnum"
		},
		cameraServers:{
			where:"CameraServerWhereInput",
			orderBy:"CameraServerOrderByWithRelationInput",
			cursor:"CameraServerWhereUniqueInput",
			distinct:"CameraServerScalarFieldEnum"
		},
		cameraServer:{
			where:"CameraServerWhereUniqueInput"
		},
		getCameraServer:{
			where:"CameraServerWhereUniqueInput"
		},
		groupByCameraServer:{
			where:"CameraServerWhereInput",
			orderBy:"CameraServerOrderByWithAggregationInput",
			by:"CameraServerScalarFieldEnum",
			having:"CameraServerScalarWhereWithAggregatesInput"
		},
		aggregateCampaign:{
			where:"CampaignWhereInput",
			orderBy:"CampaignOrderByWithRelationInput",
			cursor:"CampaignWhereUniqueInput"
		},
		findFirstCampaign:{
			where:"CampaignWhereInput",
			orderBy:"CampaignOrderByWithRelationInput",
			cursor:"CampaignWhereUniqueInput",
			distinct:"CampaignScalarFieldEnum"
		},
		findFirstCampaignOrThrow:{
			where:"CampaignWhereInput",
			orderBy:"CampaignOrderByWithRelationInput",
			cursor:"CampaignWhereUniqueInput",
			distinct:"CampaignScalarFieldEnum"
		},
		campaigns:{
			where:"CampaignWhereInput",
			orderBy:"CampaignOrderByWithRelationInput",
			cursor:"CampaignWhereUniqueInput",
			distinct:"CampaignScalarFieldEnum"
		},
		campaign:{
			where:"CampaignWhereUniqueInput"
		},
		getCampaign:{
			where:"CampaignWhereUniqueInput"
		},
		groupByCampaign:{
			where:"CampaignWhereInput",
			orderBy:"CampaignOrderByWithAggregationInput",
			by:"CampaignScalarFieldEnum",
			having:"CampaignScalarWhereWithAggregatesInput"
		},
		aggregateFile:{
			where:"FileWhereInput",
			orderBy:"FileOrderByWithRelationInput",
			cursor:"FileWhereUniqueInput"
		},
		findFirstFile:{
			where:"FileWhereInput",
			orderBy:"FileOrderByWithRelationInput",
			cursor:"FileWhereUniqueInput",
			distinct:"FileScalarFieldEnum"
		},
		findFirstFileOrThrow:{
			where:"FileWhereInput",
			orderBy:"FileOrderByWithRelationInput",
			cursor:"FileWhereUniqueInput",
			distinct:"FileScalarFieldEnum"
		},
		files:{
			where:"FileWhereInput",
			orderBy:"FileOrderByWithRelationInput",
			cursor:"FileWhereUniqueInput",
			distinct:"FileScalarFieldEnum"
		},
		file:{
			where:"FileWhereUniqueInput"
		},
		getFile:{
			where:"FileWhereUniqueInput"
		},
		groupByFile:{
			where:"FileWhereInput",
			orderBy:"FileOrderByWithAggregationInput",
			by:"FileScalarFieldEnum",
			having:"FileScalarWhereWithAggregatesInput"
		},
		aggregateMembership:{
			where:"MembershipWhereInput",
			orderBy:"MembershipOrderByWithRelationInput",
			cursor:"MembershipWhereUniqueInput"
		},
		findFirstMembership:{
			where:"MembershipWhereInput",
			orderBy:"MembershipOrderByWithRelationInput",
			cursor:"MembershipWhereUniqueInput",
			distinct:"MembershipScalarFieldEnum"
		},
		findFirstMembershipOrThrow:{
			where:"MembershipWhereInput",
			orderBy:"MembershipOrderByWithRelationInput",
			cursor:"MembershipWhereUniqueInput",
			distinct:"MembershipScalarFieldEnum"
		},
		memberships:{
			where:"MembershipWhereInput",
			orderBy:"MembershipOrderByWithRelationInput",
			cursor:"MembershipWhereUniqueInput",
			distinct:"MembershipScalarFieldEnum"
		},
		membership:{
			where:"MembershipWhereUniqueInput"
		},
		getMembership:{
			where:"MembershipWhereUniqueInput"
		},
		groupByMembership:{
			where:"MembershipWhereInput",
			orderBy:"MembershipOrderByWithAggregationInput",
			by:"MembershipScalarFieldEnum",
			having:"MembershipScalarWhereWithAggregatesInput"
		},
		aggregateNotification:{
			where:"NotificationWhereInput",
			orderBy:"NotificationOrderByWithRelationInput",
			cursor:"NotificationWhereUniqueInput"
		},
		findFirstNotification:{
			where:"NotificationWhereInput",
			orderBy:"NotificationOrderByWithRelationInput",
			cursor:"NotificationWhereUniqueInput",
			distinct:"NotificationScalarFieldEnum"
		},
		findFirstNotificationOrThrow:{
			where:"NotificationWhereInput",
			orderBy:"NotificationOrderByWithRelationInput",
			cursor:"NotificationWhereUniqueInput",
			distinct:"NotificationScalarFieldEnum"
		},
		notifications:{
			where:"NotificationWhereInput",
			orderBy:"NotificationOrderByWithRelationInput",
			cursor:"NotificationWhereUniqueInput",
			distinct:"NotificationScalarFieldEnum"
		},
		notification:{
			where:"NotificationWhereUniqueInput"
		},
		getNotification:{
			where:"NotificationWhereUniqueInput"
		},
		groupByNotification:{
			where:"NotificationWhereInput",
			orderBy:"NotificationOrderByWithAggregationInput",
			by:"NotificationScalarFieldEnum",
			having:"NotificationScalarWhereWithAggregatesInput"
		},
		aggregateOrder:{
			where:"OrderWhereInput",
			orderBy:"OrderOrderByWithRelationInput",
			cursor:"OrderWhereUniqueInput"
		},
		findFirstOrder:{
			where:"OrderWhereInput",
			orderBy:"OrderOrderByWithRelationInput",
			cursor:"OrderWhereUniqueInput",
			distinct:"OrderScalarFieldEnum"
		},
		findFirstOrderOrThrow:{
			where:"OrderWhereInput",
			orderBy:"OrderOrderByWithRelationInput",
			cursor:"OrderWhereUniqueInput",
			distinct:"OrderScalarFieldEnum"
		},
		orders:{
			where:"OrderWhereInput",
			orderBy:"OrderOrderByWithRelationInput",
			cursor:"OrderWhereUniqueInput",
			distinct:"OrderScalarFieldEnum"
		},
		order:{
			where:"OrderWhereUniqueInput"
		},
		getOrder:{
			where:"OrderWhereUniqueInput"
		},
		groupByOrder:{
			where:"OrderWhereInput",
			orderBy:"OrderOrderByWithAggregationInput",
			by:"OrderScalarFieldEnum",
			having:"OrderScalarWhereWithAggregatesInput"
		},
		aggregatePlayer:{
			where:"PlayerWhereInput",
			orderBy:"PlayerOrderByWithRelationInput",
			cursor:"PlayerWhereUniqueInput"
		},
		findFirstPlayer:{
			where:"PlayerWhereInput",
			orderBy:"PlayerOrderByWithRelationInput",
			cursor:"PlayerWhereUniqueInput",
			distinct:"PlayerScalarFieldEnum"
		},
		findFirstPlayerOrThrow:{
			where:"PlayerWhereInput",
			orderBy:"PlayerOrderByWithRelationInput",
			cursor:"PlayerWhereUniqueInput",
			distinct:"PlayerScalarFieldEnum"
		},
		players:{
			where:"PlayerWhereInput",
			orderBy:"PlayerOrderByWithRelationInput",
			cursor:"PlayerWhereUniqueInput",
			distinct:"PlayerScalarFieldEnum"
		},
		player:{
			where:"PlayerWhereUniqueInput"
		},
		getPlayer:{
			where:"PlayerWhereUniqueInput"
		},
		groupByPlayer:{
			where:"PlayerWhereInput",
			orderBy:"PlayerOrderByWithAggregationInput",
			by:"PlayerScalarFieldEnum",
			having:"PlayerScalarWhereWithAggregatesInput"
		},
		aggregatePlaylist:{
			where:"PlaylistWhereInput",
			orderBy:"PlaylistOrderByWithRelationInput",
			cursor:"PlaylistWhereUniqueInput"
		},
		findFirstPlaylist:{
			where:"PlaylistWhereInput",
			orderBy:"PlaylistOrderByWithRelationInput",
			cursor:"PlaylistWhereUniqueInput",
			distinct:"PlaylistScalarFieldEnum"
		},
		findFirstPlaylistOrThrow:{
			where:"PlaylistWhereInput",
			orderBy:"PlaylistOrderByWithRelationInput",
			cursor:"PlaylistWhereUniqueInput",
			distinct:"PlaylistScalarFieldEnum"
		},
		playlists:{
			where:"PlaylistWhereInput",
			orderBy:"PlaylistOrderByWithRelationInput",
			cursor:"PlaylistWhereUniqueInput",
			distinct:"PlaylistScalarFieldEnum"
		},
		playlist:{
			where:"PlaylistWhereUniqueInput"
		},
		getPlaylist:{
			where:"PlaylistWhereUniqueInput"
		},
		groupByPlaylist:{
			where:"PlaylistWhereInput",
			orderBy:"PlaylistOrderByWithAggregationInput",
			by:"PlaylistScalarFieldEnum",
			having:"PlaylistScalarWhereWithAggregatesInput"
		},
		aggregateSlotConfiguration:{
			where:"SlotConfigurationWhereInput",
			orderBy:"SlotConfigurationOrderByWithRelationInput",
			cursor:"SlotConfigurationWhereUniqueInput"
		},
		findFirstSlotConfiguration:{
			where:"SlotConfigurationWhereInput",
			orderBy:"SlotConfigurationOrderByWithRelationInput",
			cursor:"SlotConfigurationWhereUniqueInput",
			distinct:"SlotConfigurationScalarFieldEnum"
		},
		findFirstSlotConfigurationOrThrow:{
			where:"SlotConfigurationWhereInput",
			orderBy:"SlotConfigurationOrderByWithRelationInput",
			cursor:"SlotConfigurationWhereUniqueInput",
			distinct:"SlotConfigurationScalarFieldEnum"
		},
		slotConfigurations:{
			where:"SlotConfigurationWhereInput",
			orderBy:"SlotConfigurationOrderByWithRelationInput",
			cursor:"SlotConfigurationWhereUniqueInput",
			distinct:"SlotConfigurationScalarFieldEnum"
		},
		slotConfiguration:{
			where:"SlotConfigurationWhereUniqueInput"
		},
		getSlotConfiguration:{
			where:"SlotConfigurationWhereUniqueInput"
		},
		groupBySlotConfiguration:{
			where:"SlotConfigurationWhereInput",
			orderBy:"SlotConfigurationOrderByWithAggregationInput",
			by:"SlotConfigurationScalarFieldEnum",
			having:"SlotConfigurationScalarWhereWithAggregatesInput"
		},
		aggregateSubscription:{
			where:"SubscriptionWhereInput",
			orderBy:"SubscriptionOrderByWithRelationInput",
			cursor:"SubscriptionWhereUniqueInput"
		},
		findFirstSubscription:{
			where:"SubscriptionWhereInput",
			orderBy:"SubscriptionOrderByWithRelationInput",
			cursor:"SubscriptionWhereUniqueInput",
			distinct:"SubscriptionScalarFieldEnum"
		},
		findFirstSubscriptionOrThrow:{
			where:"SubscriptionWhereInput",
			orderBy:"SubscriptionOrderByWithRelationInput",
			cursor:"SubscriptionWhereUniqueInput",
			distinct:"SubscriptionScalarFieldEnum"
		},
		subscriptions:{
			where:"SubscriptionWhereInput",
			orderBy:"SubscriptionOrderByWithRelationInput",
			cursor:"SubscriptionWhereUniqueInput",
			distinct:"SubscriptionScalarFieldEnum"
		},
		subscription:{
			where:"SubscriptionWhereUniqueInput"
		},
		getSubscription:{
			where:"SubscriptionWhereUniqueInput"
		},
		groupBySubscription:{
			where:"SubscriptionWhereInput",
			orderBy:"SubscriptionOrderByWithAggregationInput",
			by:"SubscriptionScalarFieldEnum",
			having:"SubscriptionScalarWhereWithAggregatesInput"
		},
		aggregateUser:{
			where:"UserWhereInput",
			orderBy:"UserOrderByWithRelationInput",
			cursor:"UserWhereUniqueInput"
		},
		findFirstUser:{
			where:"UserWhereInput",
			orderBy:"UserOrderByWithRelationInput",
			cursor:"UserWhereUniqueInput",
			distinct:"UserScalarFieldEnum"
		},
		findFirstUserOrThrow:{
			where:"UserWhereInput",
			orderBy:"UserOrderByWithRelationInput",
			cursor:"UserWhereUniqueInput",
			distinct:"UserScalarFieldEnum"
		},
		users:{
			where:"UserWhereInput",
			orderBy:"UserOrderByWithRelationInput",
			cursor:"UserWhereUniqueInput",
			distinct:"UserScalarFieldEnum"
		},
		user:{
			where:"UserWhereUniqueInput"
		},
		getUser:{
			where:"UserWhereUniqueInput"
		},
		groupByUser:{
			where:"UserWhereInput",
			orderBy:"UserOrderByWithAggregationInput",
			by:"UserScalarFieldEnum",
			having:"UserScalarWhereWithAggregatesInput"
		},
		aggregateUserPosition:{
			where:"UserPositionWhereInput",
			orderBy:"UserPositionOrderByWithRelationInput",
			cursor:"UserPositionWhereUniqueInput"
		},
		findFirstUserPosition:{
			where:"UserPositionWhereInput",
			orderBy:"UserPositionOrderByWithRelationInput",
			cursor:"UserPositionWhereUniqueInput",
			distinct:"UserPositionScalarFieldEnum"
		},
		findFirstUserPositionOrThrow:{
			where:"UserPositionWhereInput",
			orderBy:"UserPositionOrderByWithRelationInput",
			cursor:"UserPositionWhereUniqueInput",
			distinct:"UserPositionScalarFieldEnum"
		},
		userPositions:{
			where:"UserPositionWhereInput",
			orderBy:"UserPositionOrderByWithRelationInput",
			cursor:"UserPositionWhereUniqueInput",
			distinct:"UserPositionScalarFieldEnum"
		},
		userPosition:{
			where:"UserPositionWhereUniqueInput"
		},
		getUserPosition:{
			where:"UserPositionWhereUniqueInput"
		},
		groupByUserPosition:{
			where:"UserPositionWhereInput",
			orderBy:"UserPositionOrderByWithAggregationInput",
			by:"UserPositionScalarFieldEnum",
			having:"UserPositionScalarWhereWithAggregatesInput"
		},
		aggregateWorkspace:{
			where:"WorkspaceWhereInput",
			orderBy:"WorkspaceOrderByWithRelationInput",
			cursor:"WorkspaceWhereUniqueInput"
		},
		findFirstWorkspace:{
			where:"WorkspaceWhereInput",
			orderBy:"WorkspaceOrderByWithRelationInput",
			cursor:"WorkspaceWhereUniqueInput",
			distinct:"WorkspaceScalarFieldEnum"
		},
		findFirstWorkspaceOrThrow:{
			where:"WorkspaceWhereInput",
			orderBy:"WorkspaceOrderByWithRelationInput",
			cursor:"WorkspaceWhereUniqueInput",
			distinct:"WorkspaceScalarFieldEnum"
		},
		workspaces:{
			where:"WorkspaceWhereInput",
			orderBy:"WorkspaceOrderByWithRelationInput",
			cursor:"WorkspaceWhereUniqueInput",
			distinct:"WorkspaceScalarFieldEnum"
		},
		workspace:{
			where:"WorkspaceWhereUniqueInput"
		},
		getWorkspace:{
			where:"WorkspaceWhereUniqueInput"
		},
		groupByWorkspace:{
			where:"WorkspaceWhereInput",
			orderBy:"WorkspaceOrderByWithAggregationInput",
			by:"WorkspaceScalarFieldEnum",
			having:"WorkspaceScalarWhereWithAggregatesInput"
		},
		aggregateWorkspaceRole:{
			where:"WorkspaceRoleWhereInput",
			orderBy:"WorkspaceRoleOrderByWithRelationInput",
			cursor:"WorkspaceRoleWhereUniqueInput"
		},
		findFirstWorkspaceRole:{
			where:"WorkspaceRoleWhereInput",
			orderBy:"WorkspaceRoleOrderByWithRelationInput",
			cursor:"WorkspaceRoleWhereUniqueInput",
			distinct:"WorkspaceRoleScalarFieldEnum"
		},
		findFirstWorkspaceRoleOrThrow:{
			where:"WorkspaceRoleWhereInput",
			orderBy:"WorkspaceRoleOrderByWithRelationInput",
			cursor:"WorkspaceRoleWhereUniqueInput",
			distinct:"WorkspaceRoleScalarFieldEnum"
		},
		workspaceRoles:{
			where:"WorkspaceRoleWhereInput",
			orderBy:"WorkspaceRoleOrderByWithRelationInput",
			cursor:"WorkspaceRoleWhereUniqueInput",
			distinct:"WorkspaceRoleScalarFieldEnum"
		},
		workspaceRole:{
			where:"WorkspaceRoleWhereUniqueInput"
		},
		getWorkspaceRole:{
			where:"WorkspaceRoleWhereUniqueInput"
		},
		groupByWorkspaceRole:{
			where:"WorkspaceRoleWhereInput",
			orderBy:"WorkspaceRoleOrderByWithAggregationInput",
			by:"WorkspaceRoleScalarFieldEnum",
			having:"WorkspaceRoleScalarWhereWithAggregatesInput"
		},
		getAdBoardAvailability:{

		}
	},
	AdBoardStatus: "enum" as const,
	AdboardDraftStatus: "enum" as const,
	DateTime: `scalar.DateTime` as const,
	AdBoardWhereInput:{
		AND:"AdBoardWhereInput",
		OR:"AdBoardWhereInput",
		NOT:"AdBoardWhereInput",
		id:"StringFilter",
		address:"StringFilter",
		state:"StringFilter",
		city:"StringFilter",
		country:"StringFilter",
		map:"MapCompositeFilter",
		outdoor:"BoolFilter",
		locationCategoryId:"StringNullableFilter",
		locationSubCategoryId:"StringNullableFilter",
		alias:"StringNullableFilter",
		title:"StringFilter",
		pricePerDay:"IntFilter",
		originalPricePerDay:"IntNullableFilter",
		description:"StringNullableFilter",
		aboutSpecifications:"SpecificationObjectEqualityInput",
		adBoardTypeId:"StringNullableFilter",
		tags:"StringNullableListFilter",
		featuredImage:"StringNullableFilter",
		galleryImgs:"StringNullableListFilter",
		specifications:"SpecificationObjectEqualityInput",
		notes:"StringNullableListFilter",
		impressionMultiplier:"FloatNullableFilter",
		resolution:"ResolutionNullableCompositeFilter",
		screenSize:"ScreenSizeNullableCompositeFilter",
		categoryId:"StringNullableFilter",
		restrictedBusinessIds:"StringNullableListFilter",
		targetAudiences:"StringNullableListFilter",
		playbackTimes:"PlaybackTimeObjectEqualityInput",
		status:"EnumAdBoardStatusFilter",
		adboardDraftStatus:"EnumAdboardDraftStatusNullableFilter",
		isLive:"BoolFilter",
		webhooks:"WebhookObjectEqualityInput",
		numberOfDisplays:"IntFilter",
		dailyOperationHours:"IntFilter",
		adsPerLoop:"IntFilter",
		adsDuration:"IntFilter",
		monthlyPrice:"FloatNullableFilter",
		offlineBookingsEnabled:"BoolFilter",
		offlineBookingContactId:"StringNullableFilter",
		createdAt:"DateTimeNullableFilter",
		createdByUserId:"StringNullableFilter",
		href:"StringNullableFilter",
		workspaceId:"StringFilter",
		playerIds:"StringNullableListFilter",
		publisherScreenId:"StringNullableFilter",
		additionalData:"JsonNullableFilter",
		isDraft:"BoolFilter",
		publishedVersionId:"StringNullableFilter",
		lastPublishedAt:"DateTimeNullableFilter",
		isArchived:"BoolFilter",
		archivedAt:"DateTimeNullableFilter",
		archivedByUserId:"StringNullableFilter",
		updatedAt:"DateTimeNullableFilter",
		locationCategory:"LocationCategoryNullableRelationFilter",
		locationSubCategory:"LocationSubCategoryNullableRelationFilter",
		type:"AdBoardTypeNullableRelationFilter",
		category:"BusinessCategoryNullableRelationFilter",
		restrictedBusines:"BusinessCategoryListRelationFilter",
		bookings:"BookingListRelationFilter",
		orders:"OrderListRelationFilter",
		slotConfigurations:"SlotConfigurationListRelationFilter",
		offlineBookingsContact:"UserNullableRelationFilter",
		createdBy:"UserNullableRelationFilter",
		workspace:"WorkspaceRelationFilter",
		players:"PlayerListRelationFilter",
		adBoardGroup:"AdBoardGroupNullableRelationFilter",
		cameras:"CameraListRelationFilter",
		publishedVersion:"AdBoardNullableRelationFilter",
		draftAdboard:"AdBoardNullableRelationFilter",
		history:"AdBoardPublishHistoryListRelationFilter",
		reviewComments:"AdBoardReviewCommentListRelationFilter",
		archivedBy:"UserNullableRelationFilter",
		publicReviews:"AdBoardPublicReviewListRelationFilter"
	},
	StringFilter:{
		mode:"QueryMode",
		not:"NestedStringFilter"
	},
	QueryMode: "enum" as const,
	NestedStringFilter:{
		not:"NestedStringFilter"
	},
	MapCompositeFilter:{
		equals:"MapObjectEqualityInput",
		is:"MapWhereInput",
		isNot:"MapWhereInput"
	},
	MapObjectEqualityInput:{

	},
	MapWhereInput:{
		AND:"MapWhereInput",
		OR:"MapWhereInput",
		NOT:"MapWhereInput",
		lat:"FloatFilter",
		lng:"FloatFilter",
		address:"StringNullableFilter",
		city:"StringNullableFilter",
		state:"StringNullableFilter",
		country:"StringNullableFilter"
	},
	FloatFilter:{
		not:"NestedFloatFilter"
	},
	NestedFloatFilter:{
		not:"NestedFloatFilter"
	},
	StringNullableFilter:{
		mode:"QueryMode",
		not:"NestedStringNullableFilter"
	},
	NestedStringNullableFilter:{
		not:"NestedStringNullableFilter"
	},
	BoolFilter:{
		not:"NestedBoolFilter"
	},
	NestedBoolFilter:{
		not:"NestedBoolFilter"
	},
	IntFilter:{
		not:"NestedIntFilter"
	},
	NestedIntFilter:{
		not:"NestedIntFilter"
	},
	IntNullableFilter:{
		not:"NestedIntNullableFilter"
	},
	NestedIntNullableFilter:{
		not:"NestedIntNullableFilter"
	},
	SpecificationObjectEqualityInput:{

	},
	StringNullableListFilter:{

	},
	FloatNullableFilter:{
		not:"NestedFloatNullableFilter"
	},
	NestedFloatNullableFilter:{
		not:"NestedFloatNullableFilter"
	},
	ResolutionNullableCompositeFilter:{
		equals:"ResolutionObjectEqualityInput",
		is:"ResolutionWhereInput",
		isNot:"ResolutionWhereInput"
	},
	ResolutionObjectEqualityInput:{

	},
	ResolutionWhereInput:{
		AND:"ResolutionWhereInput",
		OR:"ResolutionWhereInput",
		NOT:"ResolutionWhereInput",
		height:"IntFilter",
		width:"IntFilter"
	},
	ScreenSizeNullableCompositeFilter:{
		equals:"ScreenSizeObjectEqualityInput",
		is:"ScreenSizeWhereInput",
		isNot:"ScreenSizeWhereInput"
	},
	ScreenSizeObjectEqualityInput:{
		unit:"MeasurementUnit"
	},
	MeasurementUnit: "enum" as const,
	ScreenSizeWhereInput:{
		AND:"ScreenSizeWhereInput",
		OR:"ScreenSizeWhereInput",
		NOT:"ScreenSizeWhereInput",
		height:"FloatFilter",
		unit:"EnumMeasurementUnitFilter",
		width:"FloatFilter"
	},
	EnumMeasurementUnitFilter:{
		equals:"MeasurementUnit",
		in:"MeasurementUnit",
		notIn:"MeasurementUnit",
		not:"NestedEnumMeasurementUnitFilter"
	},
	NestedEnumMeasurementUnitFilter:{
		equals:"MeasurementUnit",
		in:"MeasurementUnit",
		notIn:"MeasurementUnit",
		not:"NestedEnumMeasurementUnitFilter"
	},
	PlaybackTimeObjectEqualityInput:{

	},
	EnumAdBoardStatusFilter:{
		equals:"AdBoardStatus",
		in:"AdBoardStatus",
		notIn:"AdBoardStatus",
		not:"NestedEnumAdBoardStatusFilter"
	},
	NestedEnumAdBoardStatusFilter:{
		equals:"AdBoardStatus",
		in:"AdBoardStatus",
		notIn:"AdBoardStatus",
		not:"NestedEnumAdBoardStatusFilter"
	},
	EnumAdboardDraftStatusNullableFilter:{
		equals:"AdboardDraftStatus",
		in:"AdboardDraftStatus",
		notIn:"AdboardDraftStatus",
		not:"NestedEnumAdboardDraftStatusNullableFilter"
	},
	NestedEnumAdboardDraftStatusNullableFilter:{
		equals:"AdboardDraftStatus",
		in:"AdboardDraftStatus",
		notIn:"AdboardDraftStatus",
		not:"NestedEnumAdboardDraftStatusNullableFilter"
	},
	WebhookObjectEqualityInput:{
		headers:"JSON",
		body:"JSON"
	},
	JSON: `scalar.JSON` as const,
	DateTimeNullableFilter:{
		equals:"DateTime",
		in:"DateTime",
		notIn:"DateTime",
		lt:"DateTime",
		lte:"DateTime",
		gt:"DateTime",
		gte:"DateTime",
		not:"NestedDateTimeNullableFilter"
	},
	NestedDateTimeNullableFilter:{
		equals:"DateTime",
		in:"DateTime",
		notIn:"DateTime",
		lt:"DateTime",
		lte:"DateTime",
		gt:"DateTime",
		gte:"DateTime",
		not:"NestedDateTimeNullableFilter"
	},
	JsonNullableFilter:{
		equals:"JSON",
		not:"JSON"
	},
	LocationCategoryNullableRelationFilter:{
		is:"LocationCategoryWhereInput",
		isNot:"LocationCategoryWhereInput"
	},
	LocationCategoryWhereInput:{
		AND:"LocationCategoryWhereInput",
		OR:"LocationCategoryWhereInput",
		NOT:"LocationCategoryWhereInput",
		id:"StringFilter",
		name:"StringFilter",
		adBoards:"AdBoardListRelationFilter",
		locationSubCategory:"LocationSubCategoryListRelationFilter"
	},
	AdBoardListRelationFilter:{
		every:"AdBoardWhereInput",
		some:"AdBoardWhereInput",
		none:"AdBoardWhereInput"
	},
	LocationSubCategoryListRelationFilter:{
		every:"LocationSubCategoryWhereInput",
		some:"LocationSubCategoryWhereInput",
		none:"LocationSubCategoryWhereInput"
	},
	LocationSubCategoryWhereInput:{
		AND:"LocationSubCategoryWhereInput",
		OR:"LocationSubCategoryWhereInput",
		NOT:"LocationSubCategoryWhereInput",
		id:"StringFilter",
		name:"StringFilter",
		locationCategoryId:"StringNullableFilter",
		locationCategory:"LocationCategoryNullableRelationFilter",
		adBoards:"AdBoardListRelationFilter"
	},
	LocationSubCategoryNullableRelationFilter:{
		is:"LocationSubCategoryWhereInput",
		isNot:"LocationSubCategoryWhereInput"
	},
	AdBoardTypeNullableRelationFilter:{
		is:"AdBoardTypeWhereInput",
		isNot:"AdBoardTypeWhereInput"
	},
	AdBoardTypeWhereInput:{
		AND:"AdBoardTypeWhereInput",
		OR:"AdBoardTypeWhereInput",
		NOT:"AdBoardTypeWhereInput",
		id:"StringFilter",
		name:"StringFilter",
		description:"StringNullableFilter",
		adBoards:"AdBoardListRelationFilter"
	},
	BusinessCategoryNullableRelationFilter:{
		is:"BusinessCategoryWhereInput",
		isNot:"BusinessCategoryWhereInput"
	},
	BusinessCategoryWhereInput:{
		AND:"BusinessCategoryWhereInput",
		OR:"BusinessCategoryWhereInput",
		NOT:"BusinessCategoryWhereInput",
		id:"StringFilter",
		name:"StringFilter",
		description:"StringNullableFilter",
		restrictedByAdBoardIds:"StringNullableListFilter",
		adBoards:"AdBoardListRelationFilter",
		restrictedByAdBoards:"AdBoardListRelationFilter",
		campaigns:"CampaignListRelationFilter",
		bookings:"BookingListRelationFilter"
	},
	CampaignListRelationFilter:{
		every:"CampaignWhereInput",
		some:"CampaignWhereInput",
		none:"CampaignWhereInput"
	},
	CampaignWhereInput:{
		AND:"CampaignWhereInput",
		OR:"CampaignWhereInput",
		NOT:"CampaignWhereInput",
		id:"StringFilter",
		name:"StringFilter",
		userId:"StringFilter",
		createdAt:"DateTimeFilter",
		updatedAt:"DateTimeFilter",
		bookingFor:"StringFilter",
		purpose:"StringFilter",
		businessCategoryId:"StringFilter",
		payment:"BookingPaymentNullableCompositeFilter",
		paymentStatus:"EnumPaymentStatusFilter",
		priceSummary:"PriceSummaryNullableCompositeFilter",
		selectedDates:"OrderSelectedDateCompositeFilter",
		origin:"StringFilter",
		assetsIDs:"StringNullableListFilter",
		user:"UserRelationFilter",
		businessCategory:"BusinessCategoryRelationFilter",
		bookings:"BookingListRelationFilter",
		payments:"PaymentListRelationFilter",
		assets:"FileListRelationFilter"
	},
	DateTimeFilter:{
		equals:"DateTime",
		in:"DateTime",
		notIn:"DateTime",
		lt:"DateTime",
		lte:"DateTime",
		gt:"DateTime",
		gte:"DateTime",
		not:"NestedDateTimeFilter"
	},
	NestedDateTimeFilter:{
		equals:"DateTime",
		in:"DateTime",
		notIn:"DateTime",
		lt:"DateTime",
		lte:"DateTime",
		gt:"DateTime",
		gte:"DateTime",
		not:"NestedDateTimeFilter"
	},
	BookingPaymentNullableCompositeFilter:{
		equals:"BookingPaymentObjectEqualityInput",
		is:"BookingPaymentWhereInput",
		isNot:"BookingPaymentWhereInput"
	},
	BookingPaymentObjectEqualityInput:{
		customer:"BookingCustomerObjectEqualityInput",
		paymentGateway:"PaymentGatewayName",
		totalDetails:"PaymentTotalDetailsObjectEqualityInput",
		razorpay:"JSON",
		stripe:"JSON"
	},
	BookingCustomerObjectEqualityInput:{
		customerDetails:"PaymentCustomerDetailsObjectEqualityInput"
	},
	PaymentCustomerDetailsObjectEqualityInput:{

	},
	PaymentGatewayName: "enum" as const,
	PaymentTotalDetailsObjectEqualityInput:{

	},
	BookingPaymentWhereInput:{
		AND:"BookingPaymentWhereInput",
		OR:"BookingPaymentWhereInput",
		NOT:"BookingPaymentWhereInput",
		liveMode:"BoolFilter",
		checkoutSessionId:"StringNullableFilter",
		customer:"BookingCustomerNullableCompositeFilter",
		paymentGateway:"EnumPaymentGatewayNameFilter",
		paymentId:"StringFilter",
		sessionId:"StringNullableFilter",
		totalDetails:"PaymentTotalDetailsNullableCompositeFilter",
		razorpay:"JsonNullableFilter",
		stripe:"JsonNullableFilter"
	},
	BookingCustomerNullableCompositeFilter:{
		equals:"BookingCustomerObjectEqualityInput",
		is:"BookingCustomerWhereInput",
		isNot:"BookingCustomerWhereInput"
	},
	BookingCustomerWhereInput:{
		AND:"BookingCustomerWhereInput",
		OR:"BookingCustomerWhereInput",
		NOT:"BookingCustomerWhereInput",
		customerId:"StringFilter",
		customerDetails:"PaymentCustomerDetailsNullableCompositeFilter"
	},
	PaymentCustomerDetailsNullableCompositeFilter:{
		equals:"PaymentCustomerDetailsObjectEqualityInput",
		is:"PaymentCustomerDetailsWhereInput",
		isNot:"PaymentCustomerDetailsWhereInput"
	},
	PaymentCustomerDetailsWhereInput:{
		AND:"PaymentCustomerDetailsWhereInput",
		OR:"PaymentCustomerDetailsWhereInput",
		NOT:"PaymentCustomerDetailsWhereInput",
		email:"StringFilter",
		name:"StringFilter",
		phone:"StringNullableFilter"
	},
	EnumPaymentGatewayNameFilter:{
		equals:"PaymentGatewayName",
		in:"PaymentGatewayName",
		notIn:"PaymentGatewayName",
		not:"NestedEnumPaymentGatewayNameFilter"
	},
	NestedEnumPaymentGatewayNameFilter:{
		equals:"PaymentGatewayName",
		in:"PaymentGatewayName",
		notIn:"PaymentGatewayName",
		not:"NestedEnumPaymentGatewayNameFilter"
	},
	PaymentTotalDetailsNullableCompositeFilter:{
		equals:"PaymentTotalDetailsObjectEqualityInput",
		is:"PaymentTotalDetailsWhereInput",
		isNot:"PaymentTotalDetailsWhereInput"
	},
	PaymentTotalDetailsWhereInput:{
		AND:"PaymentTotalDetailsWhereInput",
		OR:"PaymentTotalDetailsWhereInput",
		NOT:"PaymentTotalDetailsWhereInput",
		discount:"FloatFilter",
		shipping:"FloatFilter",
		subtotal:"FloatFilter",
		tax:"FloatFilter",
		total:"FloatFilter"
	},
	EnumPaymentStatusFilter:{
		equals:"PaymentStatus",
		in:"PaymentStatus",
		notIn:"PaymentStatus",
		not:"NestedEnumPaymentStatusFilter"
	},
	PaymentStatus: "enum" as const,
	NestedEnumPaymentStatusFilter:{
		equals:"PaymentStatus",
		in:"PaymentStatus",
		notIn:"PaymentStatus",
		not:"NestedEnumPaymentStatusFilter"
	},
	PriceSummaryNullableCompositeFilter:{
		equals:"PriceSummaryObjectEqualityInput",
		is:"PriceSummaryWhereInput",
		isNot:"PriceSummaryWhereInput"
	},
	PriceSummaryObjectEqualityInput:{

	},
	PriceSummaryWhereInput:{
		AND:"PriceSummaryWhereInput",
		OR:"PriceSummaryWhereInput",
		NOT:"PriceSummaryWhereInput",
		days:"IntFilter",
		discount:"FloatNullableFilter",
		payableRent:"FloatFilter",
		rentPerDay:"IntFilter",
		serviceCharge:"FloatFilter",
		totalRent:"IntFilter"
	},
	OrderSelectedDateCompositeFilter:{
		equals:"OrderSelectedDateObjectEqualityInput",
		is:"OrderSelectedDateWhereInput",
		isNot:"OrderSelectedDateWhereInput"
	},
	OrderSelectedDateObjectEqualityInput:{
		endDate:"DateTime",
		startDate:"DateTime"
	},
	OrderSelectedDateWhereInput:{
		AND:"OrderSelectedDateWhereInput",
		OR:"OrderSelectedDateWhereInput",
		NOT:"OrderSelectedDateWhereInput",
		endDate:"DateTimeFilter",
		startDate:"DateTimeFilter"
	},
	UserRelationFilter:{
		is:"UserWhereInput",
		isNot:"UserWhereInput"
	},
	UserWhereInput:{
		AND:"UserWhereInput",
		OR:"UserWhereInput",
		NOT:"UserWhereInput",
		id:"StringFilter",
		uid:"StringNullableFilter",
		email:"StringFilter",
		image:"StringNullableFilter",
		phoneNumber:"StringNullableFilter",
		displayName:"StringNullableFilter",
		otherDetails:"JsonNullableFilter",
		stripe:"UserStripeNullableCompositeFilter",
		roles:"EnumRoleNullableListFilter",
		refunds:"BookingRefundObjectEqualityInput",
		sharedBookingIds:"StringNullableListFilter",
		disclaimerAccepted:"BoolFilter",
		notificationPreference:"NotificationPreferenceNullableCompositeFilter",
		leadWorkspaceIds:"StringNullableListFilter",
		bookings:"BookingListRelationFilter",
		createdFiles:"FileListRelationFilter",
		updatedFiles:"FileListRelationFilter",
		orders:"OrderListRelationFilter",
		changelogs:"ChangelogListRelationFilter",
		adBoards:"AdBoardListRelationFilter",
		audits:"AuditListRelationFilter",
		campaigns:"CampaignListRelationFilter",
		sharedBookings:"BookingListRelationFilter",
		memberships:"MembershipListRelationFilter",
		notifications:"NotificationListRelationFilter",
		publishAdboardVersion:"AdBoardPublishHistoryListRelationFilter",
		offlineBookingAdboards:"AdBoardListRelationFilter",
		reviewComments:"AdBoardReviewCommentListRelationFilter",
		archivedAdboards:"AdBoardListRelationFilter",
		ownedWorkspaces:"WorkspaceListRelationFilter",
		leadWorspaces:"WorkspaceListRelationFilter",
		publicReviews:"AdBoardPublicReviewListRelationFilter",
		userPositions:"UserPositionListRelationFilter"
	},
	UserStripeNullableCompositeFilter:{
		equals:"UserStripeObjectEqualityInput",
		is:"UserStripeWhereInput",
		isNot:"UserStripeWhereInput"
	},
	UserStripeObjectEqualityInput:{

	},
	UserStripeWhereInput:{
		AND:"UserStripeWhereInput",
		OR:"UserStripeWhereInput",
		NOT:"UserStripeWhereInput",
		customerId:"StringFilter"
	},
	EnumRoleNullableListFilter:{
		equals:"Role",
		has:"Role",
		hasEvery:"Role",
		hasSome:"Role"
	},
	Role: "enum" as const,
	BookingRefundObjectEqualityInput:{
		razorpay:"JSON",
		stripe:"JSON"
	},
	NotificationPreferenceNullableCompositeFilter:{
		equals:"NotificationPreferenceObjectEqualityInput",
		is:"NotificationPreferenceWhereInput",
		isNot:"NotificationPreferenceWhereInput"
	},
	NotificationPreferenceObjectEqualityInput:{
		type:"NotificationType",
		channels:"NotificationChannel",
		pushSetting:"PushSettingObjectEqualityInput"
	},
	NotificationType: "enum" as const,
	NotificationChannel: "enum" as const,
	PushSettingObjectEqualityInput:{

	},
	NotificationPreferenceWhereInput:{
		AND:"NotificationPreferenceWhereInput",
		OR:"NotificationPreferenceWhereInput",
		NOT:"NotificationPreferenceWhereInput",
		type:"EnumNotificationTypeNullableListFilter",
		channels:"EnumNotificationChannelNullableListFilter",
		pushSetting:"PushSettingNullableCompositeFilter",
		enabled:"BoolFilter"
	},
	EnumNotificationTypeNullableListFilter:{
		equals:"NotificationType",
		has:"NotificationType",
		hasEvery:"NotificationType",
		hasSome:"NotificationType"
	},
	EnumNotificationChannelNullableListFilter:{
		equals:"NotificationChannel",
		has:"NotificationChannel",
		hasEvery:"NotificationChannel",
		hasSome:"NotificationChannel"
	},
	PushSettingNullableCompositeFilter:{
		equals:"PushSettingObjectEqualityInput",
		is:"PushSettingWhereInput",
		isNot:"PushSettingWhereInput"
	},
	PushSettingWhereInput:{
		AND:"PushSettingWhereInput",
		OR:"PushSettingWhereInput",
		NOT:"PushSettingWhereInput",
		fcmToken:"StringFilter"
	},
	BookingListRelationFilter:{
		every:"BookingWhereInput",
		some:"BookingWhereInput",
		none:"BookingWhereInput"
	},
	BookingWhereInput:{
		AND:"BookingWhereInput",
		OR:"BookingWhereInput",
		NOT:"BookingWhereInput",
		id:"StringFilter",
		additionalInformation:"StringNullableFilter",
		origin:"StringNullableFilter",
		assetsIDs:"StringNullableListFilter",
		bookingCode:"StringFilter",
		bookingDate:"DateTimeFilter",
		bookingFor:"StringFilter",
		purpose:"StringFilter",
		businessCategoryId:"StringNullableFilter",
		adBoardId:"StringFilter",
		workspaceId:"StringNullableFilter",
		campaignId:"StringNullableFilter",
		bookingDetails:"BookingDetailsCompositeFilter",
		bookingStatus:"EnumBookingStatusFilter",
		paymentStatus:"EnumPaymentStatusNullableFilter",
		deploymentStatus:"EnumDeploymentStatusFilter",
		contentApprovalStatus:"EnumContentApprovalStatusFilter",
		selectedDates:"OrderSelectedDateCompositeFilter",
		payment:"BookingPaymentNullableCompositeFilter",
		priceSummary:"PriceSummaryNullableCompositeFilter",
		pisignage:"BookingPisignageDataNullableCompositeFilter",
		userId:"StringFilter",
		refund:"BookingRefundNullableCompositeFilter",
		sharedUserIds:"StringNullableListFilter",
		isOffline:"BoolFilter",
		isPaid:"BoolFilter",
		isScheduled:"BoolFilter",
		isDeployed:"BoolFilter",
		isCompleted:"BoolFilter",
		assets:"FileListRelationFilter",
		businessCategory:"BusinessCategoryNullableRelationFilter",
		proofs:"FileListRelationFilter",
		user:"UserRelationFilter",
		adBoard:"AdBoardRelationFilter",
		changelogs:"ChangelogListRelationFilter",
		workspace:"WorkspaceNullableRelationFilter",
		campaign:"CampaignNullableRelationFilter",
		payouts:"PayoutListRelationFilter",
		sharedUsers:"UserListRelationFilter",
		webhookLogs:"WebhookLogListRelationFilter",
		slotUsage:"SlotUsageListRelationFilter"
	},
	BookingDetailsCompositeFilter:{
		equals:"BookingDetailsObjectEqualityInput",
		is:"BookingDetailsWhereInput",
		isNot:"BookingDetailsWhereInput"
	},
	BookingDetailsObjectEqualityInput:{

	},
	BookingDetailsWhereInput:{
		AND:"BookingDetailsWhereInput",
		OR:"BookingDetailsWhereInput",
		NOT:"BookingDetailsWhereInput",
		additionalInformation:"StringNullableFilter",
		email:"StringFilter",
		name:"StringFilter",
		phone:"StringNullableFilter"
	},
	EnumBookingStatusFilter:{
		equals:"BookingStatus",
		in:"BookingStatus",
		notIn:"BookingStatus",
		not:"NestedEnumBookingStatusFilter"
	},
	BookingStatus: "enum" as const,
	NestedEnumBookingStatusFilter:{
		equals:"BookingStatus",
		in:"BookingStatus",
		notIn:"BookingStatus",
		not:"NestedEnumBookingStatusFilter"
	},
	EnumPaymentStatusNullableFilter:{
		equals:"PaymentStatus",
		in:"PaymentStatus",
		notIn:"PaymentStatus",
		not:"NestedEnumPaymentStatusNullableFilter"
	},
	NestedEnumPaymentStatusNullableFilter:{
		equals:"PaymentStatus",
		in:"PaymentStatus",
		notIn:"PaymentStatus",
		not:"NestedEnumPaymentStatusNullableFilter"
	},
	EnumDeploymentStatusFilter:{
		equals:"DeploymentStatus",
		in:"DeploymentStatus",
		notIn:"DeploymentStatus",
		not:"NestedEnumDeploymentStatusFilter"
	},
	DeploymentStatus: "enum" as const,
	NestedEnumDeploymentStatusFilter:{
		equals:"DeploymentStatus",
		in:"DeploymentStatus",
		notIn:"DeploymentStatus",
		not:"NestedEnumDeploymentStatusFilter"
	},
	EnumContentApprovalStatusFilter:{
		equals:"ContentApprovalStatus",
		in:"ContentApprovalStatus",
		notIn:"ContentApprovalStatus",
		not:"NestedEnumContentApprovalStatusFilter"
	},
	ContentApprovalStatus: "enum" as const,
	NestedEnumContentApprovalStatusFilter:{
		equals:"ContentApprovalStatus",
		in:"ContentApprovalStatus",
		notIn:"ContentApprovalStatus",
		not:"NestedEnumContentApprovalStatusFilter"
	},
	BookingPisignageDataNullableCompositeFilter:{
		equals:"BookingPisignageDataObjectEqualityInput",
		is:"BookingPisignageDataWhereInput",
		isNot:"BookingPisignageDataWhereInput"
	},
	BookingPisignageDataObjectEqualityInput:{

	},
	BookingPisignageDataWhereInput:{
		AND:"BookingPisignageDataWhereInput",
		OR:"BookingPisignageDataWhereInput",
		NOT:"BookingPisignageDataWhereInput",
		deployedFileName:"StringNullableFilter",
		playlistId:"StringNullableFilter"
	},
	BookingRefundNullableCompositeFilter:{
		equals:"BookingRefundObjectEqualityInput",
		is:"BookingRefundWhereInput",
		isNot:"BookingRefundWhereInput"
	},
	BookingRefundWhereInput:{
		AND:"BookingRefundWhereInput",
		OR:"BookingRefundWhereInput",
		NOT:"BookingRefundWhereInput",
		id:"StringFilter",
		razorpay:"JsonNullableFilter",
		stripe:"JsonNullableFilter",
		createdByUserId:"StringFilter"
	},
	FileListRelationFilter:{
		every:"FileWhereInput",
		some:"FileWhereInput",
		none:"FileWhereInput"
	},
	FileWhereInput:{
		AND:"FileWhereInput",
		OR:"FileWhereInput",
		NOT:"FileWhereInput",
		id:"StringFilter",
		contentType:"StringFilter",
		ctime:"StringFilter",
		filename:"StringFilter",
		filepath:"StringNullableFilter",
		isDirectory:"BoolFilter",
		meta:"FileMetaCompositeFilter",
		mtime:"StringFilter",
		name:"StringFilter",
		size:"IntFilter",
		status:"EnumFileStatusNullableFilter",
		type:"StringFilter",
		updatedAt:"DateTimeFilter",
		createdAt:"DateTimeFilter",
		updatedByUserId:"StringNullableFilter",
		createdByUserId:"StringFilter",
		url:"StringFilter",
		bookingsIDs:"StringNullableListFilter",
		workspaceId:"StringNullableFilter",
		proofBookingId:"StringNullableFilter",
		campaignIds:"StringNullableListFilter",
		updatedBy:"UserNullableRelationFilter",
		createdBy:"UserRelationFilter",
		bookings:"BookingListRelationFilter",
		workspace:"WorkspaceNullableRelationFilter",
		proofBooking:"BookingNullableRelationFilter",
		campaigns:"CampaignListRelationFilter"
	},
	FileMetaCompositeFilter:{
		equals:"FileMetaObjectEqualityInput",
		is:"FileMetaWhereInput",
		isNot:"FileMetaWhereInput"
	},
	FileMetaObjectEqualityInput:{

	},
	FileMetaWhereInput:{
		AND:"FileMetaWhereInput",
		OR:"FileMetaWhereInput",
		NOT:"FileMetaWhereInput",
		duration:"FloatFilter",
		height:"IntFilter",
		width:"IntFilter"
	},
	EnumFileStatusNullableFilter:{
		equals:"FileStatus",
		in:"FileStatus",
		notIn:"FileStatus",
		not:"NestedEnumFileStatusNullableFilter"
	},
	FileStatus: "enum" as const,
	NestedEnumFileStatusNullableFilter:{
		equals:"FileStatus",
		in:"FileStatus",
		notIn:"FileStatus",
		not:"NestedEnumFileStatusNullableFilter"
	},
	UserNullableRelationFilter:{
		is:"UserWhereInput",
		isNot:"UserWhereInput"
	},
	WorkspaceNullableRelationFilter:{
		is:"WorkspaceWhereInput",
		isNot:"WorkspaceWhereInput"
	},
	WorkspaceWhereInput:{
		AND:"WorkspaceWhereInput",
		OR:"WorkspaceWhereInput",
		NOT:"WorkspaceWhereInput",
		id:"StringFilter",
		type:"EnumWorkspaceTypeFilter",
		name:"StringFilter",
		key:"StringFilter",
		userIds:"StringNullableListFilter",
		ownerId:"StringNullableFilter",
		avatar:"StringNullableFilter",
		createdAt:"DateTimeFilter",
		updatedAt:"DateTimeFilter",
		stripeSubscriptionId:"StringNullableFilter",
		leadIds:"StringNullableListFilter",
		owner:"UserNullableRelationFilter",
		memberships:"MembershipListRelationFilter",
		adBoards:"AdBoardListRelationFilter",
		playlists:"PlaylistListRelationFilter",
		adBoardGroups:"AdBoardGroupListRelationFilter",
		audits:"AuditListRelationFilter",
		bookings:"BookingListRelationFilter",
		files:"FileListRelationFilter",
		players:"PlayerListRelationFilter",
		transactions:"TransactionListRelationFilter",
		activeSubscription:"SubscriptionNullableRelationFilter",
		cameraServers:"CameraServerListRelationFilter",
		workspaceRoles:"WorkspaceRoleListRelationFilter",
		webhookLogs:"WebhookLogListRelationFilter",
		leads:"UserListRelationFilter"
	},
	EnumWorkspaceTypeFilter:{
		equals:"WorkspaceType",
		in:"WorkspaceType",
		notIn:"WorkspaceType",
		not:"NestedEnumWorkspaceTypeFilter"
	},
	WorkspaceType: "enum" as const,
	NestedEnumWorkspaceTypeFilter:{
		equals:"WorkspaceType",
		in:"WorkspaceType",
		notIn:"WorkspaceType",
		not:"NestedEnumWorkspaceTypeFilter"
	},
	MembershipListRelationFilter:{
		every:"MembershipWhereInput",
		some:"MembershipWhereInput",
		none:"MembershipWhereInput"
	},
	MembershipWhereInput:{
		AND:"MembershipWhereInput",
		OR:"MembershipWhereInput",
		NOT:"MembershipWhereInput",
		id:"StringFilter",
		createdAt:"DateTimeFilter",
		userId:"StringFilter",
		workspaceId:"StringFilter",
		roleId:"StringFilter",
		user:"UserRelationFilter",
		workspace:"WorkspaceRelationFilter",
		role:"WorkspaceRoleRelationFilter"
	},
	WorkspaceRelationFilter:{
		is:"WorkspaceWhereInput",
		isNot:"WorkspaceWhereInput"
	},
	WorkspaceRoleRelationFilter:{
		is:"WorkspaceRoleWhereInput",
		isNot:"WorkspaceRoleWhereInput"
	},
	WorkspaceRoleWhereInput:{
		AND:"WorkspaceRoleWhereInput",
		OR:"WorkspaceRoleWhereInput",
		NOT:"WorkspaceRoleWhereInput",
		id:"StringFilter",
		name:"StringFilter",
		permissions:"EnumPermissionNullableListFilter",
		workspaceId:"StringNullableFilter",
		createdAt:"DateTimeFilter",
		updatedAt:"DateTimeFilter",
		workspace:"WorkspaceNullableRelationFilter",
		memberships:"MembershipListRelationFilter"
	},
	EnumPermissionNullableListFilter:{
		equals:"Permission",
		has:"Permission",
		hasEvery:"Permission",
		hasSome:"Permission"
	},
	Permission: "enum" as const,
	PlaylistListRelationFilter:{
		every:"PlaylistWhereInput",
		some:"PlaylistWhereInput",
		none:"PlaylistWhereInput"
	},
	PlaylistWhereInput:{
		AND:"PlaylistWhereInput",
		OR:"PlaylistWhereInput",
		NOT:"PlaylistWhereInput",
		id:"StringFilter",
		name:"StringFilter",
		displayName:"StringNullableFilter",
		assets:"PisignageFileObjectEqualityInput",
		isAdsPlaylist:"BoolNullableFilter",
		footerPlaylistId:"StringNullableFilter",
		sidebarPlaylistId:"StringNullableFilter",
		layout:"EnumScreenLayoutFilter",
		isDeployed:"BoolNullableFilter",
		adBoardGroupIds:"StringNullableListFilter",
		workspaceId:"StringFilter",
		footerPlaylist:"PlaylistNullableRelationFilter",
		sidebarPlaylist:"PlaylistNullableRelationFilter",
		adBoardGroupsForAds:"AdBoardGroupListRelationFilter",
		adBoardGroups:"AdBoardGroupListRelationFilter",
		workspace:"WorkspaceRelationFilter",
		footerParentPlaylists:"PlaylistListRelationFilter",
		sidebarParentPlaylists:"PlaylistListRelationFilter"
	},
	PisignageFileObjectEqualityInput:{

	},
	BoolNullableFilter:{
		not:"NestedBoolNullableFilter"
	},
	NestedBoolNullableFilter:{
		not:"NestedBoolNullableFilter"
	},
	EnumScreenLayoutFilter:{
		equals:"ScreenLayout",
		in:"ScreenLayout",
		notIn:"ScreenLayout",
		not:"NestedEnumScreenLayoutFilter"
	},
	ScreenLayout: "enum" as const,
	NestedEnumScreenLayoutFilter:{
		equals:"ScreenLayout",
		in:"ScreenLayout",
		notIn:"ScreenLayout",
		not:"NestedEnumScreenLayoutFilter"
	},
	PlaylistNullableRelationFilter:{
		is:"PlaylistWhereInput",
		isNot:"PlaylistWhereInput"
	},
	AdBoardGroupListRelationFilter:{
		every:"AdBoardGroupWhereInput",
		some:"AdBoardGroupWhereInput",
		none:"AdBoardGroupWhereInput"
	},
	AdBoardGroupWhereInput:{
		AND:"AdBoardGroupWhereInput",
		OR:"AdBoardGroupWhereInput",
		NOT:"AdBoardGroupWhereInput",
		id:"StringFilter",
		name:"StringFilter",
		adBoardId:"StringNullableFilter",
		schedules:"ScheduleObjectEqualityInput",
		scheduledPlaylistsIDs:"StringNullableListFilter",
		adsPlaylistID:"StringNullableFilter",
		pisignageGroupId:"StringFilter",
		fitImage:"EnumFitTypeFilter",
		fitVideo:"EnumFitTypeFilter",
		workspaceId:"StringFilter",
		adBoard:"AdBoardNullableRelationFilter",
		scheduledPlaylists:"PlaylistListRelationFilter",
		adsPlaylist:"PlaylistNullableRelationFilter",
		pisignagePlayers:"PlayerListRelationFilter",
		workspace:"WorkspaceRelationFilter"
	},
	ScheduleObjectEqualityInput:{

	},
	EnumFitTypeFilter:{
		equals:"FitType",
		in:"FitType",
		notIn:"FitType",
		not:"NestedEnumFitTypeFilter"
	},
	FitType: "enum" as const,
	NestedEnumFitTypeFilter:{
		equals:"FitType",
		in:"FitType",
		notIn:"FitType",
		not:"NestedEnumFitTypeFilter"
	},
	AdBoardNullableRelationFilter:{
		is:"AdBoardWhereInput",
		isNot:"AdBoardWhereInput"
	},
	PlayerListRelationFilter:{
		every:"PlayerWhereInput",
		some:"PlayerWhereInput",
		none:"PlayerWhereInput"
	},
	PlayerWhereInput:{
		AND:"PlayerWhereInput",
		OR:"PlayerWhereInput",
		NOT:"PlayerWhereInput",
		id:"StringFilter",
		name:"StringFilter",
		configLocation:"StringNullableFilter",
		pisignagePlayerId:"StringNullableFilter",
		pisignageGroupId:"StringNullableFilter",
		cpuSerialNumber:"StringNullableFilter",
		displayName:"StringNullableFilter",
		adBoardGroupId:"StringNullableFilter",
		workpaceId:"StringNullableFilter",
		resolution:"ResolutionNullableCompositeFilter",
		screenSize:"ScreenSizeNullableCompositeFilter",
		playbackDetails:"PlaybackTimeObjectEqualityInput",
		aspectRatioType:"EnumAspectRatioTypeFilter",
		customAspectRatioWidth:"IntNullableFilter",
		customAspectRatioHeight:"IntNullableFilter",
		map:"MapNullableCompositeFilter",
		isOnline:"BoolFilter",
		adBoardIds:"StringNullableListFilter",
		adBoardGroup:"AdBoardGroupNullableRelationFilter",
		workspace:"WorkspaceNullableRelationFilter",
		status:"PlayerUptimeStatusListRelationFilter",
		cameras:"CameraListRelationFilter",
		adBoards:"AdBoardListRelationFilter"
	},
	EnumAspectRatioTypeFilter:{
		equals:"AspectRatioType",
		in:"AspectRatioType",
		notIn:"AspectRatioType",
		not:"NestedEnumAspectRatioTypeFilter"
	},
	AspectRatioType: "enum" as const,
	NestedEnumAspectRatioTypeFilter:{
		equals:"AspectRatioType",
		in:"AspectRatioType",
		notIn:"AspectRatioType",
		not:"NestedEnumAspectRatioTypeFilter"
	},
	MapNullableCompositeFilter:{
		equals:"MapObjectEqualityInput",
		is:"MapWhereInput",
		isNot:"MapWhereInput"
	},
	AdBoardGroupNullableRelationFilter:{
		is:"AdBoardGroupWhereInput",
		isNot:"AdBoardGroupWhereInput"
	},
	PlayerUptimeStatusListRelationFilter:{
		every:"PlayerUptimeStatusWhereInput",
		some:"PlayerUptimeStatusWhereInput",
		none:"PlayerUptimeStatusWhereInput"
	},
	PlayerUptimeStatusWhereInput:{
		AND:"PlayerUptimeStatusWhereInput",
		OR:"PlayerUptimeStatusWhereInput",
		NOT:"PlayerUptimeStatusWhereInput",
		id:"StringFilter",
		playerId:"StringFilter",
		cpuSerialNumber:"StringFilter",
		date:"StringFilter",
		uptime:"FloatFilter",
		player:"PlayerRelationFilter"
	},
	PlayerRelationFilter:{
		is:"PlayerWhereInput",
		isNot:"PlayerWhereInput"
	},
	CameraListRelationFilter:{
		every:"CameraWhereInput",
		some:"CameraWhereInput",
		none:"CameraWhereInput"
	},
	CameraWhereInput:{
		AND:"CameraWhereInput",
		OR:"CameraWhereInput",
		NOT:"CameraWhereInput",
		id:"StringFilter",
		name:"StringNullableFilter",
		isOnline:"BoolFilter",
		cameraServerId:"StringFilter",
		cameraUrl:"StringFilter",
		rtspUrl:"StringFilter",
		cameraIp:"StringFilter",
		connectedCpuSerialNumbers:"StringNullableListFilter",
		playerId:"StringNullableFilter",
		updatedAt:"DateTimeFilter",
		isPublic:"BoolFilter",
		adBoardId:"StringNullableFilter",
		cameraServer:"CameraServerRelationFilter",
		player:"PlayerNullableRelationFilter",
		feeds:"CameraFeedListRelationFilter",
		adBoard:"AdBoardNullableRelationFilter"
	},
	CameraServerRelationFilter:{
		is:"CameraServerWhereInput",
		isNot:"CameraServerWhereInput"
	},
	CameraServerWhereInput:{
		AND:"CameraServerWhereInput",
		OR:"CameraServerWhereInput",
		NOT:"CameraServerWhereInput",
		id:"StringFilter",
		name:"StringNullableFilter",
		isOnline:"BoolNullableFilter",
		hostName:"StringNullableFilter",
		userName:"StringNullableFilter",
		deviceId:"StringFilter",
		workspaceId:"StringNullableFilter",
		publicIp:"StringFilter",
		privateIp:"StringFilter",
		workspace:"WorkspaceNullableRelationFilter",
		cameras:"CameraListRelationFilter",
		feeds:"CameraFeedListRelationFilter"
	},
	CameraFeedListRelationFilter:{
		every:"CameraFeedWhereInput",
		some:"CameraFeedWhereInput",
		none:"CameraFeedWhereInput"
	},
	CameraFeedWhereInput:{
		AND:"CameraFeedWhereInput",
		OR:"CameraFeedWhereInput",
		NOT:"CameraFeedWhereInput",
		id:"StringFilter",
		cameraId:"StringFilter",
		timestamp:"DateTimeFilter",
		cameraServerId:"StringFilter",
		data:"CameraFeedDataCompositeFilter",
		camera:"CameraRelationFilter",
		cameraServer:"CameraServerRelationFilter"
	},
	CameraFeedDataCompositeFilter:{
		equals:"CameraFeedDataObjectEqualityInput",
		is:"CameraFeedDataWhereInput",
		isNot:"CameraFeedDataWhereInput"
	},
	CameraFeedDataObjectEqualityInput:{

	},
	CameraFeedDataWhereInput:{
		AND:"CameraFeedDataWhereInput",
		OR:"CameraFeedDataWhereInput",
		NOT:"CameraFeedDataWhereInput",
		person:"IntNullableFilter",
		car:"IntNullableFilter",
		truck:"IntNullableFilter",
		motorcycle:"IntNullableFilter"
	},
	CameraRelationFilter:{
		is:"CameraWhereInput",
		isNot:"CameraWhereInput"
	},
	PlayerNullableRelationFilter:{
		is:"PlayerWhereInput",
		isNot:"PlayerWhereInput"
	},
	AuditListRelationFilter:{
		every:"AuditWhereInput",
		some:"AuditWhereInput",
		none:"AuditWhereInput"
	},
	AuditWhereInput:{
		AND:"AuditWhereInput",
		OR:"AuditWhereInput",
		NOT:"AuditWhereInput",
		id:"StringFilter",
		createdAt:"DateTimeFilter",
		message:"StringFilter",
		action:"StringFilter",
		entity:"EntityNullableCompositeFilter",
		userId:"StringFilter",
		workspaceId:"StringNullableFilter",
		updates:"JsonNullableFilter",
		user:"UserRelationFilter",
		workspace:"WorkspaceNullableRelationFilter"
	},
	EntityNullableCompositeFilter:{
		equals:"EntityObjectEqualityInput",
		is:"EntityWhereInput",
		isNot:"EntityWhereInput"
	},
	EntityObjectEqualityInput:{

	},
	EntityWhereInput:{
		AND:"EntityWhereInput",
		OR:"EntityWhereInput",
		NOT:"EntityWhereInput",
		type:"StringFilter",
		id:"StringFilter",
		name:"StringNullableFilter"
	},
	TransactionListRelationFilter:{
		every:"TransactionWhereInput",
		some:"TransactionWhereInput",
		none:"TransactionWhereInput"
	},
	TransactionWhereInput:{
		AND:"TransactionWhereInput",
		OR:"TransactionWhereInput",
		NOT:"TransactionWhereInput",
		workspaceId:"StringNullableFilter",
		id:"StringFilter",
		amount:"FloatFilter",
		createAt:"DateTimeFilter",
		processedAt:"DateTimeNullableFilter",
		relatedEntityId:"StringFilter",
		transactionStatus:"EnumTransactionStatusFilter",
		details:"JsonNullableFilter",
		workspace:"WorkspaceNullableRelationFilter",
		payouts:"PayoutListRelationFilter"
	},
	EnumTransactionStatusFilter:{
		equals:"TransactionStatus",
		in:"TransactionStatus",
		notIn:"TransactionStatus",
		not:"NestedEnumTransactionStatusFilter"
	},
	TransactionStatus: "enum" as const,
	NestedEnumTransactionStatusFilter:{
		equals:"TransactionStatus",
		in:"TransactionStatus",
		notIn:"TransactionStatus",
		not:"NestedEnumTransactionStatusFilter"
	},
	PayoutListRelationFilter:{
		every:"PayoutWhereInput",
		some:"PayoutWhereInput",
		none:"PayoutWhereInput"
	},
	PayoutWhereInput:{
		AND:"PayoutWhereInput",
		OR:"PayoutWhereInput",
		NOT:"PayoutWhereInput",
		id:"StringFilter",
		totalAmount:"FloatFilter",
		commissionRate:"FloatFilter",
		commissionAmount:"FloatFilter",
		netAmount:"FloatFilter",
		createdAt:"DateTimeFilter",
		bookingId:"StringFilter",
		status:"EnumPayoutStatusFilter",
		transactionId:"StringNullableFilter",
		booking:"BookingRelationFilter",
		transaction:"TransactionNullableRelationFilter"
	},
	EnumPayoutStatusFilter:{
		equals:"PayoutStatus",
		in:"PayoutStatus",
		notIn:"PayoutStatus",
		not:"NestedEnumPayoutStatusFilter"
	},
	PayoutStatus: "enum" as const,
	NestedEnumPayoutStatusFilter:{
		equals:"PayoutStatus",
		in:"PayoutStatus",
		notIn:"PayoutStatus",
		not:"NestedEnumPayoutStatusFilter"
	},
	BookingRelationFilter:{
		is:"BookingWhereInput",
		isNot:"BookingWhereInput"
	},
	TransactionNullableRelationFilter:{
		is:"TransactionWhereInput",
		isNot:"TransactionWhereInput"
	},
	SubscriptionNullableRelationFilter:{
		is:"SubscriptionWhereInput",
		isNot:"SubscriptionWhereInput"
	},
	SubscriptionWhereInput:{
		AND:"SubscriptionWhereInput",
		OR:"SubscriptionWhereInput",
		NOT:"SubscriptionWhereInput",
		id:"StringFilter",
		packageId:"StringNullableFilter",
		packageName:"StringNullableFilter",
		priceLookupKey:"StringNullableFilter",
		startDate:"DateTimeNullableFilter",
		endDate:"DateTimeNullableFilter",
		status:"StringFilter",
		stripePaymentIntentId:"StringNullableFilter",
		stripeSubscriptionId:"StringFilter",
		stripeCheckoutSessionId:"StringFilter",
		cancellationDetails:"CancellationDetailsNullableCompositeFilter",
		activeWorkspaceId:"StringFilter",
		activeWorkspace:"WorkspaceRelationFilter"
	},
	CancellationDetailsNullableCompositeFilter:{
		equals:"CancellationDetailsObjectEqualityInput",
		is:"CancellationDetailsWhereInput",
		isNot:"CancellationDetailsWhereInput"
	},
	CancellationDetailsObjectEqualityInput:{
		canceledAt:"DateTime",
		cancelAt:"DateTime"
	},
	CancellationDetailsWhereInput:{
		AND:"CancellationDetailsWhereInput",
		OR:"CancellationDetailsWhereInput",
		NOT:"CancellationDetailsWhereInput",
		canceledAt:"DateTimeNullableFilter",
		cancelAt:"DateTimeNullableFilter",
		cancelAtPeriodEnd:"BoolFilter",
		reason:"StringNullableFilter"
	},
	CameraServerListRelationFilter:{
		every:"CameraServerWhereInput",
		some:"CameraServerWhereInput",
		none:"CameraServerWhereInput"
	},
	WorkspaceRoleListRelationFilter:{
		every:"WorkspaceRoleWhereInput",
		some:"WorkspaceRoleWhereInput",
		none:"WorkspaceRoleWhereInput"
	},
	WebhookLogListRelationFilter:{
		every:"WebhookLogWhereInput",
		some:"WebhookLogWhereInput",
		none:"WebhookLogWhereInput"
	},
	WebhookLogWhereInput:{
		AND:"WebhookLogWhereInput",
		OR:"WebhookLogWhereInput",
		NOT:"WebhookLogWhereInput",
		id:"StringFilter",
		bookingId:"StringFilter",
		response:"JsonNullableFilter",
		request:"JsonNullableFilter",
		status:"IntFilter",
		createdAt:"DateTimeFilter",
		updatedAt:"DateTimeFilter",
		workspaceId:"StringNullableFilter",
		booking:"BookingRelationFilter",
		workspace:"WorkspaceNullableRelationFilter"
	},
	UserListRelationFilter:{
		every:"UserWhereInput",
		some:"UserWhereInput",
		none:"UserWhereInput"
	},
	BookingNullableRelationFilter:{
		is:"BookingWhereInput",
		isNot:"BookingWhereInput"
	},
	AdBoardRelationFilter:{
		is:"AdBoardWhereInput",
		isNot:"AdBoardWhereInput"
	},
	ChangelogListRelationFilter:{
		every:"ChangelogWhereInput",
		some:"ChangelogWhereInput",
		none:"ChangelogWhereInput"
	},
	ChangelogWhereInput:{
		AND:"ChangelogWhereInput",
		OR:"ChangelogWhereInput",
		NOT:"ChangelogWhereInput",
		id:"StringFilter",
		createdAt:"DateTimeFilter",
		message:"StringFilter",
		action:"StringFilter",
		bookingId:"StringNullableFilter",
		userId:"StringFilter",
		updates:"JsonNullableFilter",
		booking:"BookingNullableRelationFilter",
		user:"UserRelationFilter"
	},
	CampaignNullableRelationFilter:{
		is:"CampaignWhereInput",
		isNot:"CampaignWhereInput"
	},
	SlotUsageListRelationFilter:{
		every:"SlotUsageWhereInput",
		some:"SlotUsageWhereInput",
		none:"SlotUsageWhereInput"
	},
	SlotUsageWhereInput:{
		AND:"SlotUsageWhereInput",
		OR:"SlotUsageWhereInput",
		NOT:"SlotUsageWhereInput",
		id:"StringFilter",
		bookingId:"StringFilter",
		date:"DateTimeFilter",
		slotUsed:"IntFilter",
		booking:"BookingRelationFilter"
	},
	OrderListRelationFilter:{
		every:"OrderWhereInput",
		some:"OrderWhereInput",
		none:"OrderWhereInput"
	},
	OrderWhereInput:{
		AND:"OrderWhereInput",
		OR:"OrderWhereInput",
		NOT:"OrderWhereInput",
		id:"StringFilter",
		adBoardId:"StringFilter",
		createdDate:"DateTimeFilter",
		createdByUserId:"StringNullableFilter",
		origin:"StringFilter",
		paymentId:"StringNullableFilter",
		priceSummary:"PriceSummaryCompositeFilter",
		selectedDate:"OrderSelectedDateCompositeFilter",
		adBoard:"AdBoardRelationFilter",
		createdBy:"UserNullableRelationFilter"
	},
	PriceSummaryCompositeFilter:{
		equals:"PriceSummaryObjectEqualityInput",
		is:"PriceSummaryWhereInput",
		isNot:"PriceSummaryWhereInput"
	},
	NotificationListRelationFilter:{
		every:"NotificationWhereInput",
		some:"NotificationWhereInput",
		none:"NotificationWhereInput"
	},
	NotificationWhereInput:{
		AND:"NotificationWhereInput",
		OR:"NotificationWhereInput",
		NOT:"NotificationWhereInput",
		id:"StringFilter",
		type:"EnumNotificationTypeFilter",
		title:"StringFilter",
		content:"StringFilter",
		metadata:"JsonNullableFilter",
		createdAt:"DateTimeFilter",
		readAt:"DateTimeNullableFilter",
		recipientId:"StringFilter",
		channels:"EnumNotificationChannelNullableListFilter",
		recipient:"UserRelationFilter"
	},
	EnumNotificationTypeFilter:{
		equals:"NotificationType",
		in:"NotificationType",
		notIn:"NotificationType",
		not:"NestedEnumNotificationTypeFilter"
	},
	NestedEnumNotificationTypeFilter:{
		equals:"NotificationType",
		in:"NotificationType",
		notIn:"NotificationType",
		not:"NestedEnumNotificationTypeFilter"
	},
	AdBoardPublishHistoryListRelationFilter:{
		every:"AdBoardPublishHistoryWhereInput",
		some:"AdBoardPublishHistoryWhereInput",
		none:"AdBoardPublishHistoryWhereInput"
	},
	AdBoardPublishHistoryWhereInput:{
		AND:"AdBoardPublishHistoryWhereInput",
		OR:"AdBoardPublishHistoryWhereInput",
		NOT:"AdBoardPublishHistoryWhereInput",
		id:"StringFilter",
		adBoardId:"StringFilter",
		publishedAt:"DateTimeFilter",
		publishedByUserId:"StringFilter",
		changesSnapshot:"JsonFilter",
		adBoard:"AdBoardRelationFilter",
		publishedBy:"UserRelationFilter"
	},
	JsonFilter:{
		equals:"JSON",
		not:"JSON"
	},
	AdBoardReviewCommentListRelationFilter:{
		every:"AdBoardReviewCommentWhereInput",
		some:"AdBoardReviewCommentWhereInput",
		none:"AdBoardReviewCommentWhereInput"
	},
	AdBoardReviewCommentWhereInput:{
		AND:"AdBoardReviewCommentWhereInput",
		OR:"AdBoardReviewCommentWhereInput",
		NOT:"AdBoardReviewCommentWhereInput",
		id:"StringFilter",
		adBoardId:"StringFilter",
		userId:"StringFilter",
		comment:"StringFilter",
		action:"EnumReviewActionFilter",
		createdAt:"DateTimeFilter",
		adBoard:"AdBoardRelationFilter",
		user:"UserRelationFilter"
	},
	EnumReviewActionFilter:{
		equals:"ReviewAction",
		in:"ReviewAction",
		notIn:"ReviewAction",
		not:"NestedEnumReviewActionFilter"
	},
	ReviewAction: "enum" as const,
	NestedEnumReviewActionFilter:{
		equals:"ReviewAction",
		in:"ReviewAction",
		notIn:"ReviewAction",
		not:"NestedEnumReviewActionFilter"
	},
	WorkspaceListRelationFilter:{
		every:"WorkspaceWhereInput",
		some:"WorkspaceWhereInput",
		none:"WorkspaceWhereInput"
	},
	AdBoardPublicReviewListRelationFilter:{
		every:"AdBoardPublicReviewWhereInput",
		some:"AdBoardPublicReviewWhereInput",
		none:"AdBoardPublicReviewWhereInput"
	},
	AdBoardPublicReviewWhereInput:{
		AND:"AdBoardPublicReviewWhereInput",
		OR:"AdBoardPublicReviewWhereInput",
		NOT:"AdBoardPublicReviewWhereInput",
		id:"StringFilter",
		adBoardId:"StringFilter",
		userId:"StringFilter",
		rating:"IntFilter",
		comment:"StringNullableFilter",
		createdAt:"DateTimeFilter",
		adBoard:"AdBoardRelationFilter",
		user:"UserRelationFilter"
	},
	UserPositionListRelationFilter:{
		every:"UserPositionWhereInput",
		some:"UserPositionWhereInput",
		none:"UserPositionWhereInput"
	},
	UserPositionWhereInput:{
		AND:"UserPositionWhereInput",
		OR:"UserPositionWhereInput",
		NOT:"UserPositionWhereInput",
		id:"StringFilter",
		timestamp:"DateTimeFilter",
		createdAt:"DateTimeFilter",
		userId:"StringFilter",
		coords:"UserPositionCoordsCompositeFilter",
		location:"UserPositionLocationNullableCompositeFilter",
		user:"UserRelationFilter"
	},
	UserPositionCoordsCompositeFilter:{
		equals:"UserPositionCoordsObjectEqualityInput",
		is:"UserPositionCoordsWhereInput",
		isNot:"UserPositionCoordsWhereInput"
	},
	UserPositionCoordsObjectEqualityInput:{

	},
	UserPositionCoordsWhereInput:{
		AND:"UserPositionCoordsWhereInput",
		OR:"UserPositionCoordsWhereInput",
		NOT:"UserPositionCoordsWhereInput",
		accuracy:"FloatNullableFilter",
		altitude:"FloatNullableFilter",
		altitudeAccuracy:"FloatNullableFilter",
		heading:"FloatNullableFilter",
		latitude:"FloatFilter",
		longitude:"FloatFilter",
		speed:"FloatNullableFilter"
	},
	UserPositionLocationNullableCompositeFilter:{
		equals:"UserPositionLocationObjectEqualityInput",
		is:"UserPositionLocationWhereInput",
		isNot:"UserPositionLocationWhereInput"
	},
	UserPositionLocationObjectEqualityInput:{

	},
	UserPositionLocationWhereInput:{
		AND:"UserPositionLocationWhereInput",
		OR:"UserPositionLocationWhereInput",
		NOT:"UserPositionLocationWhereInput",
		address:"StringNullableFilter",
		city:"StringNullableFilter",
		state:"StringNullableFilter",
		country:"StringNullableFilter"
	},
	BusinessCategoryRelationFilter:{
		is:"BusinessCategoryWhereInput",
		isNot:"BusinessCategoryWhereInput"
	},
	PaymentListRelationFilter:{
		every:"PaymentWhereInput",
		some:"PaymentWhereInput",
		none:"PaymentWhereInput"
	},
	PaymentWhereInput:{
		AND:"PaymentWhereInput",
		OR:"PaymentWhereInput",
		NOT:"PaymentWhereInput",
		id:"StringFilter",
		paymentIntentId:"StringFilter",
		checkoutSessionId:"StringFilter",
		eventId:"StringFilter",
		liveMode:"BoolFilter",
		paymentGateway:"EnumPaymentGatewayNameFilter",
		subtotal:"FloatFilter",
		total:"FloatFilter",
		tax:"FloatFilter",
		discount:"FloatFilter",
		campaignId:"StringNullableFilter",
		createdAt:"DateTimeFilter",
		updatedAt:"DateTimeFilter",
		campaign:"CampaignNullableRelationFilter"
	},
	BusinessCategoryListRelationFilter:{
		every:"BusinessCategoryWhereInput",
		some:"BusinessCategoryWhereInput",
		none:"BusinessCategoryWhereInput"
	},
	SlotConfigurationListRelationFilter:{
		every:"SlotConfigurationWhereInput",
		some:"SlotConfigurationWhereInput",
		none:"SlotConfigurationWhereInput"
	},
	SlotConfigurationWhereInput:{
		AND:"SlotConfigurationWhereInput",
		OR:"SlotConfigurationWhereInput",
		NOT:"SlotConfigurationWhereInput",
		id:"StringFilter",
		adBoardId:"StringFilter",
		date:"DateTimeFilter",
		availableSlots:"IntFilter",
		adBoard:"AdBoardRelationFilter"
	},
	AdBoardOrderByWithRelationInput:{
		id:"SortOrder",
		address:"SortOrder",
		state:"SortOrder",
		city:"SortOrder",
		country:"SortOrder",
		map:"MapOrderByInput",
		outdoor:"SortOrder",
		locationCategoryId:"SortOrder",
		locationSubCategoryId:"SortOrder",
		alias:"SortOrder",
		title:"SortOrder",
		pricePerDay:"SortOrder",
		originalPricePerDay:"SortOrder",
		description:"SortOrder",
		aboutSpecifications:"SpecificationOrderByCompositeAggregateInput",
		adBoardTypeId:"SortOrder",
		tags:"SortOrder",
		featuredImage:"SortOrder",
		galleryImgs:"SortOrder",
		specifications:"SpecificationOrderByCompositeAggregateInput",
		notes:"SortOrder",
		impressionMultiplier:"SortOrder",
		resolution:"ResolutionOrderByInput",
		screenSize:"ScreenSizeOrderByInput",
		categoryId:"SortOrder",
		restrictedBusinessIds:"SortOrder",
		targetAudiences:"SortOrder",
		playbackTimes:"PlaybackTimeOrderByCompositeAggregateInput",
		status:"SortOrder",
		adboardDraftStatus:"SortOrder",
		isLive:"SortOrder",
		webhooks:"WebhookOrderByCompositeAggregateInput",
		numberOfDisplays:"SortOrder",
		dailyOperationHours:"SortOrder",
		adsPerLoop:"SortOrder",
		adsDuration:"SortOrder",
		monthlyPrice:"SortOrder",
		offlineBookingsEnabled:"SortOrder",
		offlineBookingContactId:"SortOrder",
		createdAt:"SortOrder",
		createdByUserId:"SortOrder",
		href:"SortOrder",
		workspaceId:"SortOrder",
		playerIds:"SortOrder",
		publisherScreenId:"SortOrder",
		additionalData:"SortOrder",
		isDraft:"SortOrder",
		publishedVersionId:"SortOrder",
		lastPublishedAt:"SortOrder",
		isArchived:"SortOrder",
		archivedAt:"SortOrder",
		archivedByUserId:"SortOrder",
		updatedAt:"SortOrder",
		locationCategory:"LocationCategoryOrderByWithRelationInput",
		locationSubCategory:"LocationSubCategoryOrderByWithRelationInput",
		type:"AdBoardTypeOrderByWithRelationInput",
		category:"BusinessCategoryOrderByWithRelationInput",
		restrictedBusines:"BusinessCategoryOrderByRelationAggregateInput",
		bookings:"BookingOrderByRelationAggregateInput",
		orders:"OrderOrderByRelationAggregateInput",
		slotConfigurations:"SlotConfigurationOrderByRelationAggregateInput",
		offlineBookingsContact:"UserOrderByWithRelationInput",
		createdBy:"UserOrderByWithRelationInput",
		workspace:"WorkspaceOrderByWithRelationInput",
		players:"PlayerOrderByRelationAggregateInput",
		adBoardGroup:"AdBoardGroupOrderByWithRelationInput",
		cameras:"CameraOrderByRelationAggregateInput",
		publishedVersion:"AdBoardOrderByWithRelationInput",
		draftAdboard:"AdBoardOrderByWithRelationInput",
		history:"AdBoardPublishHistoryOrderByRelationAggregateInput",
		reviewComments:"AdBoardReviewCommentOrderByRelationAggregateInput",
		archivedBy:"UserOrderByWithRelationInput",
		publicReviews:"AdBoardPublicReviewOrderByRelationAggregateInput"
	},
	SortOrder: "enum" as const,
	MapOrderByInput:{
		lat:"SortOrder",
		lng:"SortOrder",
		address:"SortOrder",
		city:"SortOrder",
		state:"SortOrder",
		country:"SortOrder"
	},
	SpecificationOrderByCompositeAggregateInput:{
		_count:"SortOrder"
	},
	ResolutionOrderByInput:{
		height:"SortOrder",
		width:"SortOrder"
	},
	ScreenSizeOrderByInput:{
		height:"SortOrder",
		unit:"SortOrder",
		width:"SortOrder"
	},
	PlaybackTimeOrderByCompositeAggregateInput:{
		_count:"SortOrder"
	},
	WebhookOrderByCompositeAggregateInput:{
		_count:"SortOrder"
	},
	LocationCategoryOrderByWithRelationInput:{
		id:"SortOrder",
		name:"SortOrder",
		adBoards:"AdBoardOrderByRelationAggregateInput",
		locationSubCategory:"LocationSubCategoryOrderByRelationAggregateInput"
	},
	AdBoardOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	LocationSubCategoryOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	LocationSubCategoryOrderByWithRelationInput:{
		id:"SortOrder",
		name:"SortOrder",
		locationCategoryId:"SortOrder",
		locationCategory:"LocationCategoryOrderByWithRelationInput",
		adBoards:"AdBoardOrderByRelationAggregateInput"
	},
	AdBoardTypeOrderByWithRelationInput:{
		id:"SortOrder",
		name:"SortOrder",
		description:"SortOrder",
		adBoards:"AdBoardOrderByRelationAggregateInput"
	},
	BusinessCategoryOrderByWithRelationInput:{
		id:"SortOrder",
		name:"SortOrder",
		description:"SortOrder",
		restrictedByAdBoardIds:"SortOrder",
		adBoards:"AdBoardOrderByRelationAggregateInput",
		restrictedByAdBoards:"AdBoardOrderByRelationAggregateInput",
		campaigns:"CampaignOrderByRelationAggregateInput",
		bookings:"BookingOrderByRelationAggregateInput"
	},
	CampaignOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	BookingOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	BusinessCategoryOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	OrderOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	SlotConfigurationOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	UserOrderByWithRelationInput:{
		id:"SortOrder",
		uid:"SortOrder",
		email:"SortOrder",
		image:"SortOrder",
		phoneNumber:"SortOrder",
		displayName:"SortOrder",
		otherDetails:"SortOrder",
		stripe:"UserStripeOrderByInput",
		roles:"SortOrder",
		refunds:"BookingRefundOrderByCompositeAggregateInput",
		sharedBookingIds:"SortOrder",
		disclaimerAccepted:"SortOrder",
		notificationPreference:"NotificationPreferenceOrderByInput",
		leadWorkspaceIds:"SortOrder",
		bookings:"BookingOrderByRelationAggregateInput",
		createdFiles:"FileOrderByRelationAggregateInput",
		updatedFiles:"FileOrderByRelationAggregateInput",
		orders:"OrderOrderByRelationAggregateInput",
		changelogs:"ChangelogOrderByRelationAggregateInput",
		adBoards:"AdBoardOrderByRelationAggregateInput",
		audits:"AuditOrderByRelationAggregateInput",
		campaigns:"CampaignOrderByRelationAggregateInput",
		sharedBookings:"BookingOrderByRelationAggregateInput",
		memberships:"MembershipOrderByRelationAggregateInput",
		notifications:"NotificationOrderByRelationAggregateInput",
		publishAdboardVersion:"AdBoardPublishHistoryOrderByRelationAggregateInput",
		offlineBookingAdboards:"AdBoardOrderByRelationAggregateInput",
		reviewComments:"AdBoardReviewCommentOrderByRelationAggregateInput",
		archivedAdboards:"AdBoardOrderByRelationAggregateInput",
		ownedWorkspaces:"WorkspaceOrderByRelationAggregateInput",
		leadWorspaces:"WorkspaceOrderByRelationAggregateInput",
		publicReviews:"AdBoardPublicReviewOrderByRelationAggregateInput",
		userPositions:"UserPositionOrderByRelationAggregateInput"
	},
	UserStripeOrderByInput:{
		customerId:"SortOrder"
	},
	BookingRefundOrderByCompositeAggregateInput:{
		_count:"SortOrder"
	},
	NotificationPreferenceOrderByInput:{
		type:"SortOrder",
		channels:"SortOrder",
		pushSetting:"PushSettingOrderByInput",
		enabled:"SortOrder"
	},
	PushSettingOrderByInput:{
		fcmToken:"SortOrder"
	},
	FileOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	ChangelogOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	AuditOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	MembershipOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	NotificationOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	AdBoardPublishHistoryOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	AdBoardReviewCommentOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	WorkspaceOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	AdBoardPublicReviewOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	UserPositionOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	WorkspaceOrderByWithRelationInput:{
		id:"SortOrder",
		type:"SortOrder",
		name:"SortOrder",
		key:"SortOrder",
		userIds:"SortOrder",
		ownerId:"SortOrder",
		avatar:"SortOrder",
		createdAt:"SortOrder",
		updatedAt:"SortOrder",
		stripeSubscriptionId:"SortOrder",
		leadIds:"SortOrder",
		owner:"UserOrderByWithRelationInput",
		memberships:"MembershipOrderByRelationAggregateInput",
		adBoards:"AdBoardOrderByRelationAggregateInput",
		playlists:"PlaylistOrderByRelationAggregateInput",
		adBoardGroups:"AdBoardGroupOrderByRelationAggregateInput",
		audits:"AuditOrderByRelationAggregateInput",
		bookings:"BookingOrderByRelationAggregateInput",
		files:"FileOrderByRelationAggregateInput",
		players:"PlayerOrderByRelationAggregateInput",
		transactions:"TransactionOrderByRelationAggregateInput",
		activeSubscription:"SubscriptionOrderByWithRelationInput",
		cameraServers:"CameraServerOrderByRelationAggregateInput",
		workspaceRoles:"WorkspaceRoleOrderByRelationAggregateInput",
		webhookLogs:"WebhookLogOrderByRelationAggregateInput",
		leads:"UserOrderByRelationAggregateInput"
	},
	PlaylistOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	AdBoardGroupOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	PlayerOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	TransactionOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	SubscriptionOrderByWithRelationInput:{
		id:"SortOrder",
		packageId:"SortOrder",
		packageName:"SortOrder",
		priceLookupKey:"SortOrder",
		startDate:"SortOrder",
		endDate:"SortOrder",
		status:"SortOrder",
		stripePaymentIntentId:"SortOrder",
		stripeSubscriptionId:"SortOrder",
		stripeCheckoutSessionId:"SortOrder",
		cancellationDetails:"CancellationDetailsOrderByInput",
		activeWorkspaceId:"SortOrder",
		activeWorkspace:"WorkspaceOrderByWithRelationInput"
	},
	CancellationDetailsOrderByInput:{
		canceledAt:"SortOrder",
		cancelAt:"SortOrder",
		cancelAtPeriodEnd:"SortOrder",
		reason:"SortOrder"
	},
	CameraServerOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	WorkspaceRoleOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	WebhookLogOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	UserOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	AdBoardGroupOrderByWithRelationInput:{
		id:"SortOrder",
		name:"SortOrder",
		adBoardId:"SortOrder",
		schedules:"ScheduleOrderByCompositeAggregateInput",
		scheduledPlaylistsIDs:"SortOrder",
		adsPlaylistID:"SortOrder",
		pisignageGroupId:"SortOrder",
		fitImage:"SortOrder",
		fitVideo:"SortOrder",
		workspaceId:"SortOrder",
		adBoard:"AdBoardOrderByWithRelationInput",
		scheduledPlaylists:"PlaylistOrderByRelationAggregateInput",
		adsPlaylist:"PlaylistOrderByWithRelationInput",
		pisignagePlayers:"PlayerOrderByRelationAggregateInput",
		workspace:"WorkspaceOrderByWithRelationInput"
	},
	ScheduleOrderByCompositeAggregateInput:{
		_count:"SortOrder"
	},
	PlaylistOrderByWithRelationInput:{
		id:"SortOrder",
		name:"SortOrder",
		displayName:"SortOrder",
		assets:"PisignageFileOrderByCompositeAggregateInput",
		isAdsPlaylist:"SortOrder",
		footerPlaylistId:"SortOrder",
		sidebarPlaylistId:"SortOrder",
		layout:"SortOrder",
		isDeployed:"SortOrder",
		adBoardGroupIds:"SortOrder",
		workspaceId:"SortOrder",
		footerPlaylist:"PlaylistOrderByWithRelationInput",
		sidebarPlaylist:"PlaylistOrderByWithRelationInput",
		adBoardGroupsForAds:"AdBoardGroupOrderByRelationAggregateInput",
		adBoardGroups:"AdBoardGroupOrderByRelationAggregateInput",
		workspace:"WorkspaceOrderByWithRelationInput",
		footerParentPlaylists:"PlaylistOrderByRelationAggregateInput",
		sidebarParentPlaylists:"PlaylistOrderByRelationAggregateInput"
	},
	PisignageFileOrderByCompositeAggregateInput:{
		_count:"SortOrder"
	},
	CameraOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	AdBoardWhereUniqueInput:{
		workspaceId_alias:"AdBoardWorkspaceIdAliasCompoundUniqueInput",
		AND:"AdBoardWhereInput",
		OR:"AdBoardWhereInput",
		NOT:"AdBoardWhereInput",
		address:"StringFilter",
		state:"StringFilter",
		city:"StringFilter",
		country:"StringFilter",
		map:"MapCompositeFilter",
		outdoor:"BoolFilter",
		locationCategoryId:"StringNullableFilter",
		locationSubCategoryId:"StringNullableFilter",
		alias:"StringNullableFilter",
		title:"StringFilter",
		pricePerDay:"IntFilter",
		originalPricePerDay:"IntNullableFilter",
		description:"StringNullableFilter",
		aboutSpecifications:"SpecificationObjectEqualityInput",
		adBoardTypeId:"StringNullableFilter",
		tags:"StringNullableListFilter",
		featuredImage:"StringNullableFilter",
		galleryImgs:"StringNullableListFilter",
		specifications:"SpecificationObjectEqualityInput",
		notes:"StringNullableListFilter",
		impressionMultiplier:"FloatNullableFilter",
		resolution:"ResolutionNullableCompositeFilter",
		screenSize:"ScreenSizeNullableCompositeFilter",
		categoryId:"StringNullableFilter",
		restrictedBusinessIds:"StringNullableListFilter",
		targetAudiences:"StringNullableListFilter",
		playbackTimes:"PlaybackTimeObjectEqualityInput",
		status:"EnumAdBoardStatusFilter",
		adboardDraftStatus:"EnumAdboardDraftStatusNullableFilter",
		isLive:"BoolFilter",
		webhooks:"WebhookObjectEqualityInput",
		numberOfDisplays:"IntFilter",
		dailyOperationHours:"IntFilter",
		adsPerLoop:"IntFilter",
		adsDuration:"IntFilter",
		monthlyPrice:"FloatNullableFilter",
		offlineBookingsEnabled:"BoolFilter",
		offlineBookingContactId:"StringNullableFilter",
		createdAt:"DateTimeNullableFilter",
		createdByUserId:"StringNullableFilter",
		href:"StringNullableFilter",
		workspaceId:"StringFilter",
		playerIds:"StringNullableListFilter",
		publisherScreenId:"StringNullableFilter",
		additionalData:"JsonNullableFilter",
		isDraft:"BoolFilter",
		lastPublishedAt:"DateTimeNullableFilter",
		isArchived:"BoolFilter",
		archivedAt:"DateTimeNullableFilter",
		archivedByUserId:"StringNullableFilter",
		updatedAt:"DateTimeNullableFilter",
		locationCategory:"LocationCategoryNullableRelationFilter",
		locationSubCategory:"LocationSubCategoryNullableRelationFilter",
		type:"AdBoardTypeNullableRelationFilter",
		category:"BusinessCategoryNullableRelationFilter",
		restrictedBusines:"BusinessCategoryListRelationFilter",
		bookings:"BookingListRelationFilter",
		orders:"OrderListRelationFilter",
		slotConfigurations:"SlotConfigurationListRelationFilter",
		offlineBookingsContact:"UserNullableRelationFilter",
		createdBy:"UserNullableRelationFilter",
		workspace:"WorkspaceRelationFilter",
		players:"PlayerListRelationFilter",
		adBoardGroup:"AdBoardGroupNullableRelationFilter",
		cameras:"CameraListRelationFilter",
		publishedVersion:"AdBoardNullableRelationFilter",
		draftAdboard:"AdBoardNullableRelationFilter",
		history:"AdBoardPublishHistoryListRelationFilter",
		reviewComments:"AdBoardReviewCommentListRelationFilter",
		archivedBy:"UserNullableRelationFilter",
		publicReviews:"AdBoardPublicReviewListRelationFilter"
	},
	AdBoardWorkspaceIdAliasCompoundUniqueInput:{

	},
	AdBoard:{
		locationCategory:{
			where:"LocationCategoryWhereInput"
		},
		locationSubCategory:{
			where:"LocationSubCategoryWhereInput"
		},
		type:{
			where:"AdBoardTypeWhereInput"
		},
		category:{
			where:"BusinessCategoryWhereInput"
		},
		restrictedBusines:{
			where:"BusinessCategoryWhereInput",
			orderBy:"BusinessCategoryOrderByWithRelationInput",
			cursor:"BusinessCategoryWhereUniqueInput",
			distinct:"BusinessCategoryScalarFieldEnum"
		},
		bookings:{
			where:"BookingWhereInput",
			orderBy:"BookingOrderByWithRelationInput",
			cursor:"BookingWhereUniqueInput",
			distinct:"BookingScalarFieldEnum"
		},
		orders:{
			where:"OrderWhereInput",
			orderBy:"OrderOrderByWithRelationInput",
			cursor:"OrderWhereUniqueInput",
			distinct:"OrderScalarFieldEnum"
		},
		slotConfigurations:{
			where:"SlotConfigurationWhereInput",
			orderBy:"SlotConfigurationOrderByWithRelationInput",
			cursor:"SlotConfigurationWhereUniqueInput",
			distinct:"SlotConfigurationScalarFieldEnum"
		},
		offlineBookingsContact:{
			where:"UserWhereInput"
		},
		createdBy:{
			where:"UserWhereInput"
		},
		players:{
			where:"PlayerWhereInput",
			orderBy:"PlayerOrderByWithRelationInput",
			cursor:"PlayerWhereUniqueInput",
			distinct:"PlayerScalarFieldEnum"
		},
		adBoardGroup:{
			where:"AdBoardGroupWhereInput"
		},
		cameras:{
			where:"CameraWhereInput",
			orderBy:"CameraOrderByWithRelationInput",
			cursor:"CameraWhereUniqueInput",
			distinct:"CameraScalarFieldEnum"
		},
		publishedVersion:{
			where:"AdBoardWhereInput"
		},
		draftAdboard:{
			where:"AdBoardWhereInput"
		},
		history:{
			where:"AdBoardPublishHistoryWhereInput",
			orderBy:"AdBoardPublishHistoryOrderByWithRelationInput",
			cursor:"AdBoardPublishHistoryWhereUniqueInput",
			distinct:"AdBoardPublishHistoryScalarFieldEnum"
		},
		reviewComments:{
			where:"AdBoardReviewCommentWhereInput",
			orderBy:"AdBoardReviewCommentOrderByWithRelationInput",
			cursor:"AdBoardReviewCommentWhereUniqueInput",
			distinct:"AdBoardReviewCommentScalarFieldEnum"
		},
		archivedBy:{
			where:"UserWhereInput"
		},
		publicReviews:{
			where:"AdBoardPublicReviewWhereInput",
			orderBy:"AdBoardPublicReviewOrderByWithRelationInput",
			cursor:"AdBoardPublicReviewWhereUniqueInput",
			distinct:"AdBoardPublicReviewScalarFieldEnum"
		}
	},
	AdBoardCount:{
		restrictedBusines:{
			where:"BusinessCategoryWhereInput"
		},
		bookings:{
			where:"BookingWhereInput"
		},
		orders:{
			where:"OrderWhereInput"
		},
		slotConfigurations:{
			where:"SlotConfigurationWhereInput"
		},
		players:{
			where:"PlayerWhereInput"
		},
		cameras:{
			where:"CameraWhereInput"
		},
		history:{
			where:"AdBoardPublishHistoryWhereInput"
		},
		reviewComments:{
			where:"AdBoardReviewCommentWhereInput"
		},
		publicReviews:{
			where:"AdBoardPublicReviewWhereInput"
		}
	},
	LocationCategory:{
		adBoards:{
			where:"AdBoardWhereInput",
			orderBy:"AdBoardOrderByWithRelationInput",
			cursor:"AdBoardWhereUniqueInput",
			distinct:"AdBoardScalarFieldEnum"
		},
		locationSubCategory:{
			where:"LocationSubCategoryWhereInput",
			orderBy:"LocationSubCategoryOrderByWithRelationInput",
			cursor:"LocationSubCategoryWhereUniqueInput",
			distinct:"LocationSubCategoryScalarFieldEnum"
		}
	},
	LocationCategoryCount:{
		adBoards:{
			where:"AdBoardWhereInput"
		},
		locationSubCategory:{
			where:"LocationSubCategoryWhereInput"
		}
	},
	AdBoardScalarFieldEnum: "enum" as const,
	LocationSubCategory:{
		locationCategory:{
			where:"LocationCategoryWhereInput"
		},
		adBoards:{
			where:"AdBoardWhereInput",
			orderBy:"AdBoardOrderByWithRelationInput",
			cursor:"AdBoardWhereUniqueInput",
			distinct:"AdBoardScalarFieldEnum"
		}
	},
	LocationSubCategoryCount:{
		adBoards:{
			where:"AdBoardWhereInput"
		}
	},
	LocationSubCategoryWhereUniqueInput:{
		AND:"LocationSubCategoryWhereInput",
		OR:"LocationSubCategoryWhereInput",
		NOT:"LocationSubCategoryWhereInput",
		name:"StringFilter",
		locationCategoryId:"StringNullableFilter",
		locationCategory:"LocationCategoryNullableRelationFilter",
		adBoards:"AdBoardListRelationFilter"
	},
	LocationSubCategoryScalarFieldEnum: "enum" as const,
	AdBoardType:{
		adBoards:{
			where:"AdBoardWhereInput",
			orderBy:"AdBoardOrderByWithRelationInput",
			cursor:"AdBoardWhereUniqueInput",
			distinct:"AdBoardScalarFieldEnum"
		}
	},
	AdBoardTypeCount:{
		adBoards:{
			where:"AdBoardWhereInput"
		}
	},
	BusinessCategory:{
		adBoards:{
			where:"AdBoardWhereInput",
			orderBy:"AdBoardOrderByWithRelationInput",
			cursor:"AdBoardWhereUniqueInput",
			distinct:"AdBoardScalarFieldEnum"
		},
		restrictedByAdBoards:{
			where:"AdBoardWhereInput",
			orderBy:"AdBoardOrderByWithRelationInput",
			cursor:"AdBoardWhereUniqueInput",
			distinct:"AdBoardScalarFieldEnum"
		},
		campaigns:{
			where:"CampaignWhereInput",
			orderBy:"CampaignOrderByWithRelationInput",
			cursor:"CampaignWhereUniqueInput",
			distinct:"CampaignScalarFieldEnum"
		},
		bookings:{
			where:"BookingWhereInput",
			orderBy:"BookingOrderByWithRelationInput",
			cursor:"BookingWhereUniqueInput",
			distinct:"BookingScalarFieldEnum"
		}
	},
	BusinessCategoryCount:{
		adBoards:{
			where:"AdBoardWhereInput"
		},
		restrictedByAdBoards:{
			where:"AdBoardWhereInput"
		},
		campaigns:{
			where:"CampaignWhereInput"
		},
		bookings:{
			where:"BookingWhereInput"
		}
	},
	Campaign:{
		bookings:{
			where:"BookingWhereInput",
			orderBy:"BookingOrderByWithRelationInput",
			cursor:"BookingWhereUniqueInput",
			distinct:"BookingScalarFieldEnum"
		},
		payments:{
			where:"PaymentWhereInput",
			orderBy:"PaymentOrderByWithRelationInput",
			cursor:"PaymentWhereUniqueInput",
			distinct:"PaymentScalarFieldEnum"
		},
		assets:{
			where:"FileWhereInput",
			orderBy:"FileOrderByWithRelationInput",
			cursor:"FileWhereUniqueInput",
			distinct:"FileScalarFieldEnum"
		}
	},
	CampaignCount:{
		bookings:{
			where:"BookingWhereInput"
		},
		payments:{
			where:"PaymentWhereInput"
		},
		assets:{
			where:"FileWhereInput"
		}
	},
	User:{
		bookings:{
			where:"BookingWhereInput",
			orderBy:"BookingOrderByWithRelationInput",
			cursor:"BookingWhereUniqueInput",
			distinct:"BookingScalarFieldEnum"
		},
		createdFiles:{
			where:"FileWhereInput",
			orderBy:"FileOrderByWithRelationInput",
			cursor:"FileWhereUniqueInput",
			distinct:"FileScalarFieldEnum"
		},
		updatedFiles:{
			where:"FileWhereInput",
			orderBy:"FileOrderByWithRelationInput",
			cursor:"FileWhereUniqueInput",
			distinct:"FileScalarFieldEnum"
		},
		orders:{
			where:"OrderWhereInput",
			orderBy:"OrderOrderByWithRelationInput",
			cursor:"OrderWhereUniqueInput",
			distinct:"OrderScalarFieldEnum"
		},
		changelogs:{
			where:"ChangelogWhereInput",
			orderBy:"ChangelogOrderByWithRelationInput",
			cursor:"ChangelogWhereUniqueInput",
			distinct:"ChangelogScalarFieldEnum"
		},
		adBoards:{
			where:"AdBoardWhereInput",
			orderBy:"AdBoardOrderByWithRelationInput",
			cursor:"AdBoardWhereUniqueInput",
			distinct:"AdBoardScalarFieldEnum"
		},
		audits:{
			where:"AuditWhereInput",
			orderBy:"AuditOrderByWithRelationInput",
			cursor:"AuditWhereUniqueInput",
			distinct:"AuditScalarFieldEnum"
		},
		campaigns:{
			where:"CampaignWhereInput",
			orderBy:"CampaignOrderByWithRelationInput",
			cursor:"CampaignWhereUniqueInput",
			distinct:"CampaignScalarFieldEnum"
		},
		sharedBookings:{
			where:"BookingWhereInput",
			orderBy:"BookingOrderByWithRelationInput",
			cursor:"BookingWhereUniqueInput",
			distinct:"BookingScalarFieldEnum"
		},
		memberships:{
			where:"MembershipWhereInput",
			orderBy:"MembershipOrderByWithRelationInput",
			cursor:"MembershipWhereUniqueInput",
			distinct:"MembershipScalarFieldEnum"
		},
		notifications:{
			where:"NotificationWhereInput",
			orderBy:"NotificationOrderByWithRelationInput",
			cursor:"NotificationWhereUniqueInput",
			distinct:"NotificationScalarFieldEnum"
		},
		publishAdboardVersion:{
			where:"AdBoardPublishHistoryWhereInput",
			orderBy:"AdBoardPublishHistoryOrderByWithRelationInput",
			cursor:"AdBoardPublishHistoryWhereUniqueInput",
			distinct:"AdBoardPublishHistoryScalarFieldEnum"
		},
		offlineBookingAdboards:{
			where:"AdBoardWhereInput",
			orderBy:"AdBoardOrderByWithRelationInput",
			cursor:"AdBoardWhereUniqueInput",
			distinct:"AdBoardScalarFieldEnum"
		},
		reviewComments:{
			where:"AdBoardReviewCommentWhereInput",
			orderBy:"AdBoardReviewCommentOrderByWithRelationInput",
			cursor:"AdBoardReviewCommentWhereUniqueInput",
			distinct:"AdBoardReviewCommentScalarFieldEnum"
		},
		archivedAdboards:{
			where:"AdBoardWhereInput",
			orderBy:"AdBoardOrderByWithRelationInput",
			cursor:"AdBoardWhereUniqueInput",
			distinct:"AdBoardScalarFieldEnum"
		},
		ownedWorkspaces:{
			where:"WorkspaceWhereInput",
			orderBy:"WorkspaceOrderByWithRelationInput",
			cursor:"WorkspaceWhereUniqueInput",
			distinct:"WorkspaceScalarFieldEnum"
		},
		leadWorspaces:{
			where:"WorkspaceWhereInput",
			orderBy:"WorkspaceOrderByWithRelationInput",
			cursor:"WorkspaceWhereUniqueInput",
			distinct:"WorkspaceScalarFieldEnum"
		},
		publicReviews:{
			where:"AdBoardPublicReviewWhereInput",
			orderBy:"AdBoardPublicReviewOrderByWithRelationInput",
			cursor:"AdBoardPublicReviewWhereUniqueInput",
			distinct:"AdBoardPublicReviewScalarFieldEnum"
		},
		userPositions:{
			where:"UserPositionWhereInput",
			orderBy:"UserPositionOrderByWithRelationInput",
			cursor:"UserPositionWhereUniqueInput",
			distinct:"UserPositionScalarFieldEnum"
		}
	},
	UserCount:{
		bookings:{
			where:"BookingWhereInput"
		},
		createdFiles:{
			where:"FileWhereInput"
		},
		updatedFiles:{
			where:"FileWhereInput"
		},
		orders:{
			where:"OrderWhereInput"
		},
		changelogs:{
			where:"ChangelogWhereInput"
		},
		adBoards:{
			where:"AdBoardWhereInput"
		},
		audits:{
			where:"AuditWhereInput"
		},
		campaigns:{
			where:"CampaignWhereInput"
		},
		sharedBookings:{
			where:"BookingWhereInput"
		},
		memberships:{
			where:"MembershipWhereInput"
		},
		notifications:{
			where:"NotificationWhereInput"
		},
		publishAdboardVersion:{
			where:"AdBoardPublishHistoryWhereInput"
		},
		offlineBookingAdboards:{
			where:"AdBoardWhereInput"
		},
		reviewComments:{
			where:"AdBoardReviewCommentWhereInput"
		},
		archivedAdboards:{
			where:"AdBoardWhereInput"
		},
		ownedWorkspaces:{
			where:"WorkspaceWhereInput"
		},
		leadWorspaces:{
			where:"WorkspaceWhereInput"
		},
		publicReviews:{
			where:"AdBoardPublicReviewWhereInput"
		},
		userPositions:{
			where:"UserPositionWhereInput"
		}
	},
	Booking:{
		assets:{
			where:"FileWhereInput",
			orderBy:"FileOrderByWithRelationInput",
			cursor:"FileWhereUniqueInput",
			distinct:"FileScalarFieldEnum"
		},
		businessCategory:{
			where:"BusinessCategoryWhereInput"
		},
		proofs:{
			where:"FileWhereInput",
			orderBy:"FileOrderByWithRelationInput",
			cursor:"FileWhereUniqueInput",
			distinct:"FileScalarFieldEnum"
		},
		changelogs:{
			where:"ChangelogWhereInput",
			orderBy:"ChangelogOrderByWithRelationInput",
			cursor:"ChangelogWhereUniqueInput",
			distinct:"ChangelogScalarFieldEnum"
		},
		workspace:{
			where:"WorkspaceWhereInput"
		},
		campaign:{
			where:"CampaignWhereInput"
		},
		payouts:{
			where:"PayoutWhereInput",
			orderBy:"PayoutOrderByWithRelationInput",
			cursor:"PayoutWhereUniqueInput",
			distinct:"PayoutScalarFieldEnum"
		},
		sharedUsers:{
			where:"UserWhereInput",
			orderBy:"UserOrderByWithRelationInput",
			cursor:"UserWhereUniqueInput",
			distinct:"UserScalarFieldEnum"
		},
		webhookLogs:{
			where:"WebhookLogWhereInput",
			orderBy:"WebhookLogOrderByWithRelationInput",
			cursor:"WebhookLogWhereUniqueInput",
			distinct:"WebhookLogScalarFieldEnum"
		},
		slotUsage:{
			where:"SlotUsageWhereInput",
			orderBy:"SlotUsageOrderByWithRelationInput",
			cursor:"SlotUsageWhereUniqueInput",
			distinct:"SlotUsageScalarFieldEnum"
		}
	},
	BookingCount:{
		assets:{
			where:"FileWhereInput"
		},
		proofs:{
			where:"FileWhereInput"
		},
		changelogs:{
			where:"ChangelogWhereInput"
		},
		payouts:{
			where:"PayoutWhereInput"
		},
		sharedUsers:{
			where:"UserWhereInput"
		},
		webhookLogs:{
			where:"WebhookLogWhereInput"
		},
		slotUsage:{
			where:"SlotUsageWhereInput"
		}
	},
	File:{
		updatedBy:{
			where:"UserWhereInput"
		},
		bookings:{
			where:"BookingWhereInput",
			orderBy:"BookingOrderByWithRelationInput",
			cursor:"BookingWhereUniqueInput",
			distinct:"BookingScalarFieldEnum"
		},
		workspace:{
			where:"WorkspaceWhereInput"
		},
		proofBooking:{
			where:"BookingWhereInput"
		},
		campaigns:{
			where:"CampaignWhereInput",
			orderBy:"CampaignOrderByWithRelationInput",
			cursor:"CampaignWhereUniqueInput",
			distinct:"CampaignScalarFieldEnum"
		}
	},
	FileCount:{
		bookings:{
			where:"BookingWhereInput"
		},
		campaigns:{
			where:"CampaignWhereInput"
		}
	},
	BookingOrderByWithRelationInput:{
		id:"SortOrder",
		additionalInformation:"SortOrder",
		origin:"SortOrder",
		assetsIDs:"SortOrder",
		bookingCode:"SortOrder",
		bookingDate:"SortOrder",
		bookingFor:"SortOrder",
		purpose:"SortOrder",
		businessCategoryId:"SortOrder",
		adBoardId:"SortOrder",
		workspaceId:"SortOrder",
		campaignId:"SortOrder",
		bookingDetails:"BookingDetailsOrderByInput",
		bookingStatus:"SortOrder",
		paymentStatus:"SortOrder",
		deploymentStatus:"SortOrder",
		contentApprovalStatus:"SortOrder",
		selectedDates:"OrderSelectedDateOrderByInput",
		payment:"BookingPaymentOrderByInput",
		priceSummary:"PriceSummaryOrderByInput",
		pisignage:"BookingPisignageDataOrderByInput",
		userId:"SortOrder",
		refund:"BookingRefundOrderByInput",
		sharedUserIds:"SortOrder",
		isOffline:"SortOrder",
		isPaid:"SortOrder",
		isScheduled:"SortOrder",
		isDeployed:"SortOrder",
		isCompleted:"SortOrder",
		assets:"FileOrderByRelationAggregateInput",
		businessCategory:"BusinessCategoryOrderByWithRelationInput",
		proofs:"FileOrderByRelationAggregateInput",
		user:"UserOrderByWithRelationInput",
		adBoard:"AdBoardOrderByWithRelationInput",
		changelogs:"ChangelogOrderByRelationAggregateInput",
		workspace:"WorkspaceOrderByWithRelationInput",
		campaign:"CampaignOrderByWithRelationInput",
		payouts:"PayoutOrderByRelationAggregateInput",
		sharedUsers:"UserOrderByRelationAggregateInput",
		webhookLogs:"WebhookLogOrderByRelationAggregateInput",
		slotUsage:"SlotUsageOrderByRelationAggregateInput"
	},
	BookingDetailsOrderByInput:{
		additionalInformation:"SortOrder",
		email:"SortOrder",
		name:"SortOrder",
		phone:"SortOrder"
	},
	OrderSelectedDateOrderByInput:{
		endDate:"SortOrder",
		startDate:"SortOrder"
	},
	BookingPaymentOrderByInput:{
		liveMode:"SortOrder",
		checkoutSessionId:"SortOrder",
		customer:"BookingCustomerOrderByInput",
		paymentGateway:"SortOrder",
		paymentId:"SortOrder",
		sessionId:"SortOrder",
		totalDetails:"PaymentTotalDetailsOrderByInput",
		razorpay:"SortOrder",
		stripe:"SortOrder"
	},
	BookingCustomerOrderByInput:{
		customerId:"SortOrder",
		customerDetails:"PaymentCustomerDetailsOrderByInput"
	},
	PaymentCustomerDetailsOrderByInput:{
		email:"SortOrder",
		name:"SortOrder",
		phone:"SortOrder"
	},
	PaymentTotalDetailsOrderByInput:{
		discount:"SortOrder",
		shipping:"SortOrder",
		subtotal:"SortOrder",
		tax:"SortOrder",
		total:"SortOrder"
	},
	PriceSummaryOrderByInput:{
		days:"SortOrder",
		discount:"SortOrder",
		payableRent:"SortOrder",
		rentPerDay:"SortOrder",
		serviceCharge:"SortOrder",
		totalRent:"SortOrder"
	},
	BookingPisignageDataOrderByInput:{
		deployedFileName:"SortOrder",
		playlistId:"SortOrder"
	},
	BookingRefundOrderByInput:{
		id:"SortOrder",
		razorpay:"SortOrder",
		stripe:"SortOrder",
		createdByUserId:"SortOrder"
	},
	CampaignOrderByWithRelationInput:{
		id:"SortOrder",
		name:"SortOrder",
		userId:"SortOrder",
		createdAt:"SortOrder",
		updatedAt:"SortOrder",
		bookingFor:"SortOrder",
		purpose:"SortOrder",
		businessCategoryId:"SortOrder",
		payment:"BookingPaymentOrderByInput",
		paymentStatus:"SortOrder",
		priceSummary:"PriceSummaryOrderByInput",
		selectedDates:"OrderSelectedDateOrderByInput",
		origin:"SortOrder",
		assetsIDs:"SortOrder",
		user:"UserOrderByWithRelationInput",
		businessCategory:"BusinessCategoryOrderByWithRelationInput",
		bookings:"BookingOrderByRelationAggregateInput",
		payments:"PaymentOrderByRelationAggregateInput",
		assets:"FileOrderByRelationAggregateInput"
	},
	PaymentOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	PayoutOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	SlotUsageOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	BookingWhereUniqueInput:{
		AND:"BookingWhereInput",
		OR:"BookingWhereInput",
		NOT:"BookingWhereInput",
		additionalInformation:"StringNullableFilter",
		origin:"StringNullableFilter",
		assetsIDs:"StringNullableListFilter",
		bookingDate:"DateTimeFilter",
		bookingFor:"StringFilter",
		purpose:"StringFilter",
		businessCategoryId:"StringNullableFilter",
		adBoardId:"StringFilter",
		workspaceId:"StringNullableFilter",
		campaignId:"StringNullableFilter",
		bookingDetails:"BookingDetailsCompositeFilter",
		bookingStatus:"EnumBookingStatusFilter",
		paymentStatus:"EnumPaymentStatusNullableFilter",
		deploymentStatus:"EnumDeploymentStatusFilter",
		contentApprovalStatus:"EnumContentApprovalStatusFilter",
		selectedDates:"OrderSelectedDateCompositeFilter",
		payment:"BookingPaymentNullableCompositeFilter",
		priceSummary:"PriceSummaryNullableCompositeFilter",
		pisignage:"BookingPisignageDataNullableCompositeFilter",
		userId:"StringFilter",
		refund:"BookingRefundNullableCompositeFilter",
		sharedUserIds:"StringNullableListFilter",
		isOffline:"BoolFilter",
		isPaid:"BoolFilter",
		isScheduled:"BoolFilter",
		isDeployed:"BoolFilter",
		isCompleted:"BoolFilter",
		assets:"FileListRelationFilter",
		businessCategory:"BusinessCategoryNullableRelationFilter",
		proofs:"FileListRelationFilter",
		user:"UserRelationFilter",
		adBoard:"AdBoardRelationFilter",
		changelogs:"ChangelogListRelationFilter",
		workspace:"WorkspaceNullableRelationFilter",
		campaign:"CampaignNullableRelationFilter",
		payouts:"PayoutListRelationFilter",
		sharedUsers:"UserListRelationFilter",
		webhookLogs:"WebhookLogListRelationFilter",
		slotUsage:"SlotUsageListRelationFilter"
	},
	BookingScalarFieldEnum: "enum" as const,
	Workspace:{
		owner:{
			where:"UserWhereInput"
		},
		memberships:{
			where:"MembershipWhereInput",
			orderBy:"MembershipOrderByWithRelationInput",
			cursor:"MembershipWhereUniqueInput",
			distinct:"MembershipScalarFieldEnum"
		},
		adBoards:{
			where:"AdBoardWhereInput",
			orderBy:"AdBoardOrderByWithRelationInput",
			cursor:"AdBoardWhereUniqueInput",
			distinct:"AdBoardScalarFieldEnum"
		},
		playlists:{
			where:"PlaylistWhereInput",
			orderBy:"PlaylistOrderByWithRelationInput",
			cursor:"PlaylistWhereUniqueInput",
			distinct:"PlaylistScalarFieldEnum"
		},
		adBoardGroups:{
			where:"AdBoardGroupWhereInput",
			orderBy:"AdBoardGroupOrderByWithRelationInput",
			cursor:"AdBoardGroupWhereUniqueInput",
			distinct:"AdBoardGroupScalarFieldEnum"
		},
		audits:{
			where:"AuditWhereInput",
			orderBy:"AuditOrderByWithRelationInput",
			cursor:"AuditWhereUniqueInput",
			distinct:"AuditScalarFieldEnum"
		},
		bookings:{
			where:"BookingWhereInput",
			orderBy:"BookingOrderByWithRelationInput",
			cursor:"BookingWhereUniqueInput",
			distinct:"BookingScalarFieldEnum"
		},
		files:{
			where:"FileWhereInput",
			orderBy:"FileOrderByWithRelationInput",
			cursor:"FileWhereUniqueInput",
			distinct:"FileScalarFieldEnum"
		},
		players:{
			where:"PlayerWhereInput",
			orderBy:"PlayerOrderByWithRelationInput",
			cursor:"PlayerWhereUniqueInput",
			distinct:"PlayerScalarFieldEnum"
		},
		transactions:{
			where:"TransactionWhereInput",
			orderBy:"TransactionOrderByWithRelationInput",
			cursor:"TransactionWhereUniqueInput",
			distinct:"TransactionScalarFieldEnum"
		},
		activeSubscription:{
			where:"SubscriptionWhereInput"
		},
		cameraServers:{
			where:"CameraServerWhereInput",
			orderBy:"CameraServerOrderByWithRelationInput",
			cursor:"CameraServerWhereUniqueInput",
			distinct:"CameraServerScalarFieldEnum"
		},
		workspaceRoles:{
			where:"WorkspaceRoleWhereInput",
			orderBy:"WorkspaceRoleOrderByWithRelationInput",
			cursor:"WorkspaceRoleWhereUniqueInput",
			distinct:"WorkspaceRoleScalarFieldEnum"
		},
		webhookLogs:{
			where:"WebhookLogWhereInput",
			orderBy:"WebhookLogOrderByWithRelationInput",
			cursor:"WebhookLogWhereUniqueInput",
			distinct:"WebhookLogScalarFieldEnum"
		},
		leads:{
			where:"UserWhereInput",
			orderBy:"UserOrderByWithRelationInput",
			cursor:"UserWhereUniqueInput",
			distinct:"UserScalarFieldEnum"
		}
	},
	WorkspaceCount:{
		memberships:{
			where:"MembershipWhereInput"
		},
		adBoards:{
			where:"AdBoardWhereInput"
		},
		playlists:{
			where:"PlaylistWhereInput"
		},
		adBoardGroups:{
			where:"AdBoardGroupWhereInput"
		},
		audits:{
			where:"AuditWhereInput"
		},
		bookings:{
			where:"BookingWhereInput"
		},
		files:{
			where:"FileWhereInput"
		},
		players:{
			where:"PlayerWhereInput"
		},
		transactions:{
			where:"TransactionWhereInput"
		},
		cameraServers:{
			where:"CameraServerWhereInput"
		},
		workspaceRoles:{
			where:"WorkspaceRoleWhereInput"
		},
		webhookLogs:{
			where:"WebhookLogWhereInput"
		},
		leads:{
			where:"UserWhereInput"
		}
	},
	WorkspaceRole:{
		workspace:{
			where:"WorkspaceWhereInput"
		},
		memberships:{
			where:"MembershipWhereInput",
			orderBy:"MembershipOrderByWithRelationInput",
			cursor:"MembershipWhereUniqueInput",
			distinct:"MembershipScalarFieldEnum"
		}
	},
	WorkspaceRoleCount:{
		memberships:{
			where:"MembershipWhereInput"
		}
	},
	MembershipOrderByWithRelationInput:{
		id:"SortOrder",
		createdAt:"SortOrder",
		userId:"SortOrder",
		workspaceId:"SortOrder",
		roleId:"SortOrder",
		user:"UserOrderByWithRelationInput",
		workspace:"WorkspaceOrderByWithRelationInput",
		role:"WorkspaceRoleOrderByWithRelationInput"
	},
	WorkspaceRoleOrderByWithRelationInput:{
		id:"SortOrder",
		name:"SortOrder",
		permissions:"SortOrder",
		workspaceId:"SortOrder",
		createdAt:"SortOrder",
		updatedAt:"SortOrder",
		workspace:"WorkspaceOrderByWithRelationInput",
		memberships:"MembershipOrderByRelationAggregateInput"
	},
	MembershipWhereUniqueInput:{
		userId_workspaceId:"MembershipUserIdWorkspaceIdCompoundUniqueInput",
		AND:"MembershipWhereInput",
		OR:"MembershipWhereInput",
		NOT:"MembershipWhereInput",
		createdAt:"DateTimeFilter",
		userId:"StringFilter",
		workspaceId:"StringFilter",
		roleId:"StringFilter",
		user:"UserRelationFilter",
		workspace:"WorkspaceRelationFilter",
		role:"WorkspaceRoleRelationFilter"
	},
	MembershipUserIdWorkspaceIdCompoundUniqueInput:{

	},
	MembershipScalarFieldEnum: "enum" as const,
	Playlist:{
		footerPlaylist:{
			where:"PlaylistWhereInput"
		},
		sidebarPlaylist:{
			where:"PlaylistWhereInput"
		},
		adBoardGroupsForAds:{
			where:"AdBoardGroupWhereInput",
			orderBy:"AdBoardGroupOrderByWithRelationInput",
			cursor:"AdBoardGroupWhereUniqueInput",
			distinct:"AdBoardGroupScalarFieldEnum"
		},
		adBoardGroups:{
			where:"AdBoardGroupWhereInput",
			orderBy:"AdBoardGroupOrderByWithRelationInput",
			cursor:"AdBoardGroupWhereUniqueInput",
			distinct:"AdBoardGroupScalarFieldEnum"
		},
		footerParentPlaylists:{
			where:"PlaylistWhereInput",
			orderBy:"PlaylistOrderByWithRelationInput",
			cursor:"PlaylistWhereUniqueInput",
			distinct:"PlaylistScalarFieldEnum"
		},
		sidebarParentPlaylists:{
			where:"PlaylistWhereInput",
			orderBy:"PlaylistOrderByWithRelationInput",
			cursor:"PlaylistWhereUniqueInput",
			distinct:"PlaylistScalarFieldEnum"
		}
	},
	PlaylistCount:{
		adBoardGroupsForAds:{
			where:"AdBoardGroupWhereInput"
		},
		adBoardGroups:{
			where:"AdBoardGroupWhereInput"
		},
		footerParentPlaylists:{
			where:"PlaylistWhereInput"
		},
		sidebarParentPlaylists:{
			where:"PlaylistWhereInput"
		}
	},
	AdBoardGroup:{
		adBoard:{
			where:"AdBoardWhereInput"
		},
		scheduledPlaylists:{
			where:"PlaylistWhereInput",
			orderBy:"PlaylistOrderByWithRelationInput",
			cursor:"PlaylistWhereUniqueInput",
			distinct:"PlaylistScalarFieldEnum"
		},
		adsPlaylist:{
			where:"PlaylistWhereInput"
		},
		pisignagePlayers:{
			where:"PlayerWhereInput",
			orderBy:"PlayerOrderByWithRelationInput",
			cursor:"PlayerWhereUniqueInput",
			distinct:"PlayerScalarFieldEnum"
		}
	},
	AdBoardGroupCount:{
		scheduledPlaylists:{
			where:"PlaylistWhereInput"
		},
		pisignagePlayers:{
			where:"PlayerWhereInput"
		}
	},
	PlaylistWhereUniqueInput:{
		AND:"PlaylistWhereInput",
		OR:"PlaylistWhereInput",
		NOT:"PlaylistWhereInput",
		displayName:"StringNullableFilter",
		assets:"PisignageFileObjectEqualityInput",
		isAdsPlaylist:"BoolNullableFilter",
		footerPlaylistId:"StringNullableFilter",
		sidebarPlaylistId:"StringNullableFilter",
		layout:"EnumScreenLayoutFilter",
		isDeployed:"BoolNullableFilter",
		adBoardGroupIds:"StringNullableListFilter",
		workspaceId:"StringFilter",
		footerPlaylist:"PlaylistNullableRelationFilter",
		sidebarPlaylist:"PlaylistNullableRelationFilter",
		adBoardGroupsForAds:"AdBoardGroupListRelationFilter",
		adBoardGroups:"AdBoardGroupListRelationFilter",
		workspace:"WorkspaceRelationFilter",
		footerParentPlaylists:"PlaylistListRelationFilter",
		sidebarParentPlaylists:"PlaylistListRelationFilter"
	},
	PlaylistScalarFieldEnum: "enum" as const,
	Player:{
		adBoardGroup:{
			where:"AdBoardGroupWhereInput"
		},
		workspace:{
			where:"WorkspaceWhereInput"
		},
		status:{
			where:"PlayerUptimeStatusWhereInput",
			orderBy:"PlayerUptimeStatusOrderByWithRelationInput",
			cursor:"PlayerUptimeStatusWhereUniqueInput",
			distinct:"PlayerUptimeStatusScalarFieldEnum"
		},
		cameras:{
			where:"CameraWhereInput",
			orderBy:"CameraOrderByWithRelationInput",
			cursor:"CameraWhereUniqueInput",
			distinct:"CameraScalarFieldEnum"
		},
		adBoards:{
			where:"AdBoardWhereInput",
			orderBy:"AdBoardOrderByWithRelationInput",
			cursor:"AdBoardWhereUniqueInput",
			distinct:"AdBoardScalarFieldEnum"
		}
	},
	PlayerCount:{
		status:{
			where:"PlayerUptimeStatusWhereInput"
		},
		cameras:{
			where:"CameraWhereInput"
		},
		adBoards:{
			where:"AdBoardWhereInput"
		}
	},
	PlayerUptimeStatusOrderByWithRelationInput:{
		id:"SortOrder",
		playerId:"SortOrder",
		cpuSerialNumber:"SortOrder",
		date:"SortOrder",
		uptime:"SortOrder",
		player:"PlayerOrderByWithRelationInput"
	},
	PlayerOrderByWithRelationInput:{
		id:"SortOrder",
		name:"SortOrder",
		configLocation:"SortOrder",
		pisignagePlayerId:"SortOrder",
		pisignageGroupId:"SortOrder",
		cpuSerialNumber:"SortOrder",
		displayName:"SortOrder",
		adBoardGroupId:"SortOrder",
		workpaceId:"SortOrder",
		resolution:"ResolutionOrderByInput",
		screenSize:"ScreenSizeOrderByInput",
		playbackDetails:"PlaybackTimeOrderByCompositeAggregateInput",
		aspectRatioType:"SortOrder",
		customAspectRatioWidth:"SortOrder",
		customAspectRatioHeight:"SortOrder",
		map:"MapOrderByInput",
		isOnline:"SortOrder",
		adBoardIds:"SortOrder",
		adBoardGroup:"AdBoardGroupOrderByWithRelationInput",
		workspace:"WorkspaceOrderByWithRelationInput",
		status:"PlayerUptimeStatusOrderByRelationAggregateInput",
		cameras:"CameraOrderByRelationAggregateInput",
		adBoards:"AdBoardOrderByRelationAggregateInput"
	},
	PlayerUptimeStatusOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	PlayerUptimeStatusWhereUniqueInput:{
		cpuSerialNumber_date:"PlayerUptimeStatusCpuSerialNumberDateCompoundUniqueInput",
		AND:"PlayerUptimeStatusWhereInput",
		OR:"PlayerUptimeStatusWhereInput",
		NOT:"PlayerUptimeStatusWhereInput",
		playerId:"StringFilter",
		cpuSerialNumber:"StringFilter",
		date:"StringFilter",
		uptime:"FloatFilter",
		player:"PlayerRelationFilter"
	},
	PlayerUptimeStatusCpuSerialNumberDateCompoundUniqueInput:{

	},
	PlayerUptimeStatusScalarFieldEnum: "enum" as const,
	Camera:{
		player:{
			where:"PlayerWhereInput"
		},
		feeds:{
			where:"CameraFeedWhereInput",
			orderBy:"CameraFeedOrderByWithRelationInput",
			cursor:"CameraFeedWhereUniqueInput",
			distinct:"CameraFeedScalarFieldEnum"
		},
		adBoard:{
			where:"AdBoardWhereInput"
		}
	},
	CameraCount:{
		feeds:{
			where:"CameraFeedWhereInput"
		}
	},
	CameraServer:{
		workspace:{
			where:"WorkspaceWhereInput"
		},
		cameras:{
			where:"CameraWhereInput",
			orderBy:"CameraOrderByWithRelationInput",
			cursor:"CameraWhereUniqueInput",
			distinct:"CameraScalarFieldEnum"
		},
		feeds:{
			where:"CameraFeedWhereInput",
			orderBy:"CameraFeedOrderByWithRelationInput",
			cursor:"CameraFeedWhereUniqueInput",
			distinct:"CameraFeedScalarFieldEnum"
		}
	},
	CameraServerCount:{
		cameras:{
			where:"CameraWhereInput"
		},
		feeds:{
			where:"CameraFeedWhereInput"
		}
	},
	CameraOrderByWithRelationInput:{
		id:"SortOrder",
		name:"SortOrder",
		isOnline:"SortOrder",
		cameraServerId:"SortOrder",
		cameraUrl:"SortOrder",
		rtspUrl:"SortOrder",
		cameraIp:"SortOrder",
		connectedCpuSerialNumbers:"SortOrder",
		playerId:"SortOrder",
		updatedAt:"SortOrder",
		isPublic:"SortOrder",
		adBoardId:"SortOrder",
		cameraServer:"CameraServerOrderByWithRelationInput",
		player:"PlayerOrderByWithRelationInput",
		feeds:"CameraFeedOrderByRelationAggregateInput",
		adBoard:"AdBoardOrderByWithRelationInput"
	},
	CameraServerOrderByWithRelationInput:{
		id:"SortOrder",
		name:"SortOrder",
		isOnline:"SortOrder",
		hostName:"SortOrder",
		userName:"SortOrder",
		deviceId:"SortOrder",
		workspaceId:"SortOrder",
		publicIp:"SortOrder",
		privateIp:"SortOrder",
		workspace:"WorkspaceOrderByWithRelationInput",
		cameras:"CameraOrderByRelationAggregateInput",
		feeds:"CameraFeedOrderByRelationAggregateInput"
	},
	CameraFeedOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	CameraWhereUniqueInput:{
		cameraIp_cameraServerId:"CameraCameraIpCameraServerIdCompoundUniqueInput",
		AND:"CameraWhereInput",
		OR:"CameraWhereInput",
		NOT:"CameraWhereInput",
		name:"StringNullableFilter",
		isOnline:"BoolFilter",
		cameraServerId:"StringFilter",
		rtspUrl:"StringFilter",
		cameraIp:"StringFilter",
		connectedCpuSerialNumbers:"StringNullableListFilter",
		playerId:"StringNullableFilter",
		updatedAt:"DateTimeFilter",
		isPublic:"BoolFilter",
		adBoardId:"StringNullableFilter",
		cameraServer:"CameraServerRelationFilter",
		player:"PlayerNullableRelationFilter",
		feeds:"CameraFeedListRelationFilter",
		adBoard:"AdBoardNullableRelationFilter"
	},
	CameraCameraIpCameraServerIdCompoundUniqueInput:{

	},
	CameraScalarFieldEnum: "enum" as const,
	CameraFeedOrderByWithRelationInput:{
		id:"SortOrder",
		cameraId:"SortOrder",
		timestamp:"SortOrder",
		cameraServerId:"SortOrder",
		data:"CameraFeedDataOrderByInput",
		camera:"CameraOrderByWithRelationInput",
		cameraServer:"CameraServerOrderByWithRelationInput"
	},
	CameraFeedDataOrderByInput:{
		person:"SortOrder",
		car:"SortOrder",
		truck:"SortOrder",
		motorcycle:"SortOrder"
	},
	CameraFeedWhereUniqueInput:{
		cameraId_timestamp:"CameraFeedCameraIdTimestampCompoundUniqueInput",
		AND:"CameraFeedWhereInput",
		OR:"CameraFeedWhereInput",
		NOT:"CameraFeedWhereInput",
		cameraId:"StringFilter",
		timestamp:"DateTimeFilter",
		cameraServerId:"StringFilter",
		data:"CameraFeedDataCompositeFilter",
		camera:"CameraRelationFilter",
		cameraServer:"CameraServerRelationFilter"
	},
	CameraFeedCameraIdTimestampCompoundUniqueInput:{
		timestamp:"DateTime"
	},
	CameraFeedScalarFieldEnum: "enum" as const,
	PlayerWhereUniqueInput:{
		AND:"PlayerWhereInput",
		OR:"PlayerWhereInput",
		NOT:"PlayerWhereInput",
		name:"StringFilter",
		configLocation:"StringNullableFilter",
		pisignagePlayerId:"StringNullableFilter",
		pisignageGroupId:"StringNullableFilter",
		displayName:"StringNullableFilter",
		adBoardGroupId:"StringNullableFilter",
		workpaceId:"StringNullableFilter",
		resolution:"ResolutionNullableCompositeFilter",
		screenSize:"ScreenSizeNullableCompositeFilter",
		playbackDetails:"PlaybackTimeObjectEqualityInput",
		aspectRatioType:"EnumAspectRatioTypeFilter",
		customAspectRatioWidth:"IntNullableFilter",
		customAspectRatioHeight:"IntNullableFilter",
		map:"MapNullableCompositeFilter",
		isOnline:"BoolFilter",
		adBoardIds:"StringNullableListFilter",
		adBoardGroup:"AdBoardGroupNullableRelationFilter",
		workspace:"WorkspaceNullableRelationFilter",
		status:"PlayerUptimeStatusListRelationFilter",
		cameras:"CameraListRelationFilter",
		adBoards:"AdBoardListRelationFilter"
	},
	PlayerScalarFieldEnum: "enum" as const,
	AdBoardGroupWhereUniqueInput:{
		AND:"AdBoardGroupWhereInput",
		OR:"AdBoardGroupWhereInput",
		NOT:"AdBoardGroupWhereInput",
		schedules:"ScheduleObjectEqualityInput",
		scheduledPlaylistsIDs:"StringNullableListFilter",
		adsPlaylistID:"StringNullableFilter",
		fitImage:"EnumFitTypeFilter",
		fitVideo:"EnumFitTypeFilter",
		workspaceId:"StringFilter",
		adBoard:"AdBoardNullableRelationFilter",
		scheduledPlaylists:"PlaylistListRelationFilter",
		adsPlaylist:"PlaylistNullableRelationFilter",
		pisignagePlayers:"PlayerListRelationFilter",
		workspace:"WorkspaceRelationFilter"
	},
	AdBoardGroupScalarFieldEnum: "enum" as const,
	Audit:{
		workspace:{
			where:"WorkspaceWhereInput"
		}
	},
	AuditOrderByWithRelationInput:{
		id:"SortOrder",
		createdAt:"SortOrder",
		message:"SortOrder",
		action:"SortOrder",
		entity:"EntityOrderByInput",
		userId:"SortOrder",
		workspaceId:"SortOrder",
		updates:"SortOrder",
		user:"UserOrderByWithRelationInput",
		workspace:"WorkspaceOrderByWithRelationInput"
	},
	EntityOrderByInput:{
		type:"SortOrder",
		id:"SortOrder",
		name:"SortOrder"
	},
	AuditWhereUniqueInput:{
		AND:"AuditWhereInput",
		OR:"AuditWhereInput",
		NOT:"AuditWhereInput",
		createdAt:"DateTimeFilter",
		message:"StringFilter",
		action:"StringFilter",
		entity:"EntityNullableCompositeFilter",
		userId:"StringFilter",
		workspaceId:"StringNullableFilter",
		updates:"JsonNullableFilter",
		user:"UserRelationFilter",
		workspace:"WorkspaceNullableRelationFilter"
	},
	AuditScalarFieldEnum: "enum" as const,
	FileOrderByWithRelationInput:{
		id:"SortOrder",
		contentType:"SortOrder",
		ctime:"SortOrder",
		filename:"SortOrder",
		filepath:"SortOrder",
		isDirectory:"SortOrder",
		meta:"FileMetaOrderByInput",
		mtime:"SortOrder",
		name:"SortOrder",
		size:"SortOrder",
		status:"SortOrder",
		type:"SortOrder",
		updatedAt:"SortOrder",
		createdAt:"SortOrder",
		updatedByUserId:"SortOrder",
		createdByUserId:"SortOrder",
		url:"SortOrder",
		bookingsIDs:"SortOrder",
		workspaceId:"SortOrder",
		proofBookingId:"SortOrder",
		campaignIds:"SortOrder",
		updatedBy:"UserOrderByWithRelationInput",
		createdBy:"UserOrderByWithRelationInput",
		bookings:"BookingOrderByRelationAggregateInput",
		workspace:"WorkspaceOrderByWithRelationInput",
		proofBooking:"BookingOrderByWithRelationInput",
		campaigns:"CampaignOrderByRelationAggregateInput"
	},
	FileMetaOrderByInput:{
		duration:"SortOrder",
		height:"SortOrder",
		width:"SortOrder"
	},
	FileWhereUniqueInput:{
		AND:"FileWhereInput",
		OR:"FileWhereInput",
		NOT:"FileWhereInput",
		contentType:"StringFilter",
		ctime:"StringFilter",
		filename:"StringFilter",
		filepath:"StringNullableFilter",
		isDirectory:"BoolFilter",
		meta:"FileMetaCompositeFilter",
		mtime:"StringFilter",
		name:"StringFilter",
		size:"IntFilter",
		status:"EnumFileStatusNullableFilter",
		type:"StringFilter",
		updatedAt:"DateTimeFilter",
		createdAt:"DateTimeFilter",
		updatedByUserId:"StringNullableFilter",
		createdByUserId:"StringFilter",
		bookingsIDs:"StringNullableListFilter",
		workspaceId:"StringNullableFilter",
		proofBookingId:"StringNullableFilter",
		campaignIds:"StringNullableListFilter",
		updatedBy:"UserNullableRelationFilter",
		createdBy:"UserRelationFilter",
		bookings:"BookingListRelationFilter",
		workspace:"WorkspaceNullableRelationFilter",
		proofBooking:"BookingNullableRelationFilter",
		campaigns:"CampaignListRelationFilter"
	},
	FileScalarFieldEnum: "enum" as const,
	Transaction:{
		workspace:{
			where:"WorkspaceWhereInput"
		},
		payouts:{
			where:"PayoutWhereInput",
			orderBy:"PayoutOrderByWithRelationInput",
			cursor:"PayoutWhereUniqueInput",
			distinct:"PayoutScalarFieldEnum"
		}
	},
	TransactionCount:{
		payouts:{
			where:"PayoutWhereInput"
		}
	},
	Payout:{
		transaction:{
			where:"TransactionWhereInput"
		}
	},
	PayoutOrderByWithRelationInput:{
		id:"SortOrder",
		totalAmount:"SortOrder",
		commissionRate:"SortOrder",
		commissionAmount:"SortOrder",
		netAmount:"SortOrder",
		createdAt:"SortOrder",
		bookingId:"SortOrder",
		status:"SortOrder",
		transactionId:"SortOrder",
		booking:"BookingOrderByWithRelationInput",
		transaction:"TransactionOrderByWithRelationInput"
	},
	TransactionOrderByWithRelationInput:{
		workspaceId:"SortOrder",
		id:"SortOrder",
		amount:"SortOrder",
		createAt:"SortOrder",
		processedAt:"SortOrder",
		relatedEntityId:"SortOrder",
		transactionStatus:"SortOrder",
		details:"SortOrder",
		workspace:"WorkspaceOrderByWithRelationInput",
		payouts:"PayoutOrderByRelationAggregateInput"
	},
	PayoutWhereUniqueInput:{
		AND:"PayoutWhereInput",
		OR:"PayoutWhereInput",
		NOT:"PayoutWhereInput",
		totalAmount:"FloatFilter",
		commissionRate:"FloatFilter",
		commissionAmount:"FloatFilter",
		netAmount:"FloatFilter",
		createdAt:"DateTimeFilter",
		bookingId:"StringFilter",
		status:"EnumPayoutStatusFilter",
		transactionId:"StringNullableFilter",
		booking:"BookingRelationFilter",
		transaction:"TransactionNullableRelationFilter"
	},
	PayoutScalarFieldEnum: "enum" as const,
	TransactionWhereUniqueInput:{
		AND:"TransactionWhereInput",
		OR:"TransactionWhereInput",
		NOT:"TransactionWhereInput",
		workspaceId:"StringNullableFilter",
		amount:"FloatFilter",
		createAt:"DateTimeFilter",
		processedAt:"DateTimeNullableFilter",
		relatedEntityId:"StringFilter",
		transactionStatus:"EnumTransactionStatusFilter",
		details:"JsonNullableFilter",
		workspace:"WorkspaceNullableRelationFilter",
		payouts:"PayoutListRelationFilter"
	},
	TransactionScalarFieldEnum: "enum" as const,
	CameraServerWhereUniqueInput:{
		AND:"CameraServerWhereInput",
		OR:"CameraServerWhereInput",
		NOT:"CameraServerWhereInput",
		name:"StringNullableFilter",
		isOnline:"BoolNullableFilter",
		hostName:"StringNullableFilter",
		userName:"StringNullableFilter",
		workspaceId:"StringNullableFilter",
		publicIp:"StringFilter",
		privateIp:"StringFilter",
		workspace:"WorkspaceNullableRelationFilter",
		cameras:"CameraListRelationFilter",
		feeds:"CameraFeedListRelationFilter"
	},
	CameraServerScalarFieldEnum: "enum" as const,
	WorkspaceRoleWhereUniqueInput:{
		name_workspaceId:"WorkspaceRoleNameWorkspaceIdCompoundUniqueInput",
		AND:"WorkspaceRoleWhereInput",
		OR:"WorkspaceRoleWhereInput",
		NOT:"WorkspaceRoleWhereInput",
		permissions:"EnumPermissionNullableListFilter",
		workspaceId:"StringNullableFilter",
		createdAt:"DateTimeFilter",
		updatedAt:"DateTimeFilter",
		workspace:"WorkspaceNullableRelationFilter",
		memberships:"MembershipListRelationFilter"
	},
	WorkspaceRoleNameWorkspaceIdCompoundUniqueInput:{

	},
	WorkspaceRoleScalarFieldEnum: "enum" as const,
	WebhookLog:{
		workspace:{
			where:"WorkspaceWhereInput"
		}
	},
	WebhookLogOrderByWithRelationInput:{
		id:"SortOrder",
		bookingId:"SortOrder",
		response:"SortOrder",
		request:"SortOrder",
		status:"SortOrder",
		createdAt:"SortOrder",
		updatedAt:"SortOrder",
		workspaceId:"SortOrder",
		booking:"BookingOrderByWithRelationInput",
		workspace:"WorkspaceOrderByWithRelationInput"
	},
	WebhookLogWhereUniqueInput:{
		AND:"WebhookLogWhereInput",
		OR:"WebhookLogWhereInput",
		NOT:"WebhookLogWhereInput",
		bookingId:"StringFilter",
		response:"JsonNullableFilter",
		request:"JsonNullableFilter",
		status:"IntFilter",
		createdAt:"DateTimeFilter",
		updatedAt:"DateTimeFilter",
		workspaceId:"StringNullableFilter",
		booking:"BookingRelationFilter",
		workspace:"WorkspaceNullableRelationFilter"
	},
	WebhookLogScalarFieldEnum: "enum" as const,
	UserWhereUniqueInput:{
		AND:"UserWhereInput",
		OR:"UserWhereInput",
		NOT:"UserWhereInput",
		image:"StringNullableFilter",
		phoneNumber:"StringNullableFilter",
		displayName:"StringNullableFilter",
		otherDetails:"JsonNullableFilter",
		stripe:"UserStripeNullableCompositeFilter",
		roles:"EnumRoleNullableListFilter",
		refunds:"BookingRefundObjectEqualityInput",
		sharedBookingIds:"StringNullableListFilter",
		disclaimerAccepted:"BoolFilter",
		notificationPreference:"NotificationPreferenceNullableCompositeFilter",
		leadWorkspaceIds:"StringNullableListFilter",
		bookings:"BookingListRelationFilter",
		createdFiles:"FileListRelationFilter",
		updatedFiles:"FileListRelationFilter",
		orders:"OrderListRelationFilter",
		changelogs:"ChangelogListRelationFilter",
		adBoards:"AdBoardListRelationFilter",
		audits:"AuditListRelationFilter",
		campaigns:"CampaignListRelationFilter",
		sharedBookings:"BookingListRelationFilter",
		memberships:"MembershipListRelationFilter",
		notifications:"NotificationListRelationFilter",
		publishAdboardVersion:"AdBoardPublishHistoryListRelationFilter",
		offlineBookingAdboards:"AdBoardListRelationFilter",
		reviewComments:"AdBoardReviewCommentListRelationFilter",
		archivedAdboards:"AdBoardListRelationFilter",
		ownedWorkspaces:"WorkspaceListRelationFilter",
		leadWorspaces:"WorkspaceListRelationFilter",
		publicReviews:"AdBoardPublicReviewListRelationFilter",
		userPositions:"UserPositionListRelationFilter"
	},
	UserScalarFieldEnum: "enum" as const,
	CampaignWhereUniqueInput:{
		AND:"CampaignWhereInput",
		OR:"CampaignWhereInput",
		NOT:"CampaignWhereInput",
		name:"StringFilter",
		userId:"StringFilter",
		createdAt:"DateTimeFilter",
		updatedAt:"DateTimeFilter",
		bookingFor:"StringFilter",
		purpose:"StringFilter",
		businessCategoryId:"StringFilter",
		payment:"BookingPaymentNullableCompositeFilter",
		paymentStatus:"EnumPaymentStatusFilter",
		priceSummary:"PriceSummaryNullableCompositeFilter",
		selectedDates:"OrderSelectedDateCompositeFilter",
		origin:"StringFilter",
		assetsIDs:"StringNullableListFilter",
		user:"UserRelationFilter",
		businessCategory:"BusinessCategoryRelationFilter",
		bookings:"BookingListRelationFilter",
		payments:"PaymentListRelationFilter",
		assets:"FileListRelationFilter"
	},
	CampaignScalarFieldEnum: "enum" as const,
	Changelog:{
		booking:{
			where:"BookingWhereInput"
		}
	},
	ChangelogOrderByWithRelationInput:{
		id:"SortOrder",
		createdAt:"SortOrder",
		message:"SortOrder",
		action:"SortOrder",
		bookingId:"SortOrder",
		userId:"SortOrder",
		updates:"SortOrder",
		booking:"BookingOrderByWithRelationInput",
		user:"UserOrderByWithRelationInput"
	},
	ChangelogWhereUniqueInput:{
		AND:"ChangelogWhereInput",
		OR:"ChangelogWhereInput",
		NOT:"ChangelogWhereInput",
		createdAt:"DateTimeFilter",
		message:"StringFilter",
		action:"StringFilter",
		bookingId:"StringNullableFilter",
		userId:"StringFilter",
		updates:"JsonNullableFilter",
		booking:"BookingNullableRelationFilter",
		user:"UserRelationFilter"
	},
	ChangelogScalarFieldEnum: "enum" as const,
	SlotUsageOrderByWithRelationInput:{
		id:"SortOrder",
		bookingId:"SortOrder",
		date:"SortOrder",
		slotUsed:"SortOrder",
		booking:"BookingOrderByWithRelationInput"
	},
	SlotUsageWhereUniqueInput:{
		bookingId_date:"SlotUsageBookingIdDateCompoundUniqueInput",
		AND:"SlotUsageWhereInput",
		OR:"SlotUsageWhereInput",
		NOT:"SlotUsageWhereInput",
		bookingId:"StringFilter",
		date:"DateTimeFilter",
		slotUsed:"IntFilter",
		booking:"BookingRelationFilter"
	},
	SlotUsageBookingIdDateCompoundUniqueInput:{
		date:"DateTime"
	},
	SlotUsageScalarFieldEnum: "enum" as const,
	Order:{
		createdBy:{
			where:"UserWhereInput"
		}
	},
	OrderOrderByWithRelationInput:{
		id:"SortOrder",
		adBoardId:"SortOrder",
		createdDate:"SortOrder",
		createdByUserId:"SortOrder",
		origin:"SortOrder",
		paymentId:"SortOrder",
		priceSummary:"PriceSummaryOrderByInput",
		selectedDate:"OrderSelectedDateOrderByInput",
		adBoard:"AdBoardOrderByWithRelationInput",
		createdBy:"UserOrderByWithRelationInput"
	},
	OrderWhereUniqueInput:{
		AND:"OrderWhereInput",
		OR:"OrderWhereInput",
		NOT:"OrderWhereInput",
		adBoardId:"StringFilter",
		createdDate:"DateTimeFilter",
		createdByUserId:"StringNullableFilter",
		origin:"StringFilter",
		paymentId:"StringNullableFilter",
		priceSummary:"PriceSummaryCompositeFilter",
		selectedDate:"OrderSelectedDateCompositeFilter",
		adBoard:"AdBoardRelationFilter",
		createdBy:"UserNullableRelationFilter"
	},
	OrderScalarFieldEnum: "enum" as const,
	NotificationOrderByWithRelationInput:{
		id:"SortOrder",
		type:"SortOrder",
		title:"SortOrder",
		content:"SortOrder",
		metadata:"SortOrder",
		createdAt:"SortOrder",
		readAt:"SortOrder",
		recipientId:"SortOrder",
		channels:"SortOrder",
		recipient:"UserOrderByWithRelationInput"
	},
	NotificationWhereUniqueInput:{
		AND:"NotificationWhereInput",
		OR:"NotificationWhereInput",
		NOT:"NotificationWhereInput",
		type:"EnumNotificationTypeFilter",
		title:"StringFilter",
		content:"StringFilter",
		metadata:"JsonNullableFilter",
		createdAt:"DateTimeFilter",
		readAt:"DateTimeNullableFilter",
		recipientId:"StringFilter",
		channels:"EnumNotificationChannelNullableListFilter",
		recipient:"UserRelationFilter"
	},
	NotificationScalarFieldEnum: "enum" as const,
	AdBoardPublishHistoryOrderByWithRelationInput:{
		id:"SortOrder",
		adBoardId:"SortOrder",
		publishedAt:"SortOrder",
		publishedByUserId:"SortOrder",
		changesSnapshot:"SortOrder",
		adBoard:"AdBoardOrderByWithRelationInput",
		publishedBy:"UserOrderByWithRelationInput"
	},
	AdBoardPublishHistoryWhereUniqueInput:{
		AND:"AdBoardPublishHistoryWhereInput",
		OR:"AdBoardPublishHistoryWhereInput",
		NOT:"AdBoardPublishHistoryWhereInput",
		adBoardId:"StringFilter",
		publishedAt:"DateTimeFilter",
		publishedByUserId:"StringFilter",
		changesSnapshot:"JsonFilter",
		adBoard:"AdBoardRelationFilter",
		publishedBy:"UserRelationFilter"
	},
	AdBoardPublishHistoryScalarFieldEnum: "enum" as const,
	AdBoardReviewCommentOrderByWithRelationInput:{
		id:"SortOrder",
		adBoardId:"SortOrder",
		userId:"SortOrder",
		comment:"SortOrder",
		action:"SortOrder",
		createdAt:"SortOrder",
		adBoard:"AdBoardOrderByWithRelationInput",
		user:"UserOrderByWithRelationInput"
	},
	AdBoardReviewCommentWhereUniqueInput:{
		AND:"AdBoardReviewCommentWhereInput",
		OR:"AdBoardReviewCommentWhereInput",
		NOT:"AdBoardReviewCommentWhereInput",
		adBoardId:"StringFilter",
		userId:"StringFilter",
		comment:"StringFilter",
		action:"EnumReviewActionFilter",
		createdAt:"DateTimeFilter",
		adBoard:"AdBoardRelationFilter",
		user:"UserRelationFilter"
	},
	AdBoardReviewCommentScalarFieldEnum: "enum" as const,
	WorkspaceWhereUniqueInput:{
		AND:"WorkspaceWhereInput",
		OR:"WorkspaceWhereInput",
		NOT:"WorkspaceWhereInput",
		type:"EnumWorkspaceTypeFilter",
		name:"StringFilter",
		userIds:"StringNullableListFilter",
		ownerId:"StringNullableFilter",
		avatar:"StringNullableFilter",
		createdAt:"DateTimeFilter",
		updatedAt:"DateTimeFilter",
		leadIds:"StringNullableListFilter",
		owner:"UserNullableRelationFilter",
		memberships:"MembershipListRelationFilter",
		adBoards:"AdBoardListRelationFilter",
		playlists:"PlaylistListRelationFilter",
		adBoardGroups:"AdBoardGroupListRelationFilter",
		audits:"AuditListRelationFilter",
		bookings:"BookingListRelationFilter",
		files:"FileListRelationFilter",
		players:"PlayerListRelationFilter",
		transactions:"TransactionListRelationFilter",
		activeSubscription:"SubscriptionNullableRelationFilter",
		cameraServers:"CameraServerListRelationFilter",
		workspaceRoles:"WorkspaceRoleListRelationFilter",
		webhookLogs:"WebhookLogListRelationFilter",
		leads:"UserListRelationFilter"
	},
	WorkspaceScalarFieldEnum: "enum" as const,
	AdBoardPublicReviewOrderByWithRelationInput:{
		id:"SortOrder",
		adBoardId:"SortOrder",
		userId:"SortOrder",
		rating:"SortOrder",
		comment:"SortOrder",
		createdAt:"SortOrder",
		adBoard:"AdBoardOrderByWithRelationInput",
		user:"UserOrderByWithRelationInput"
	},
	AdBoardPublicReviewWhereUniqueInput:{
		AND:"AdBoardPublicReviewWhereInput",
		OR:"AdBoardPublicReviewWhereInput",
		NOT:"AdBoardPublicReviewWhereInput",
		adBoardId:"StringFilter",
		userId:"StringFilter",
		rating:"IntFilter",
		comment:"StringNullableFilter",
		createdAt:"DateTimeFilter",
		adBoard:"AdBoardRelationFilter",
		user:"UserRelationFilter"
	},
	AdBoardPublicReviewScalarFieldEnum: "enum" as const,
	UserPositionOrderByWithRelationInput:{
		id:"SortOrder",
		timestamp:"SortOrder",
		createdAt:"SortOrder",
		userId:"SortOrder",
		coords:"UserPositionCoordsOrderByInput",
		location:"UserPositionLocationOrderByInput",
		user:"UserOrderByWithRelationInput"
	},
	UserPositionCoordsOrderByInput:{
		accuracy:"SortOrder",
		altitude:"SortOrder",
		altitudeAccuracy:"SortOrder",
		heading:"SortOrder",
		latitude:"SortOrder",
		longitude:"SortOrder",
		speed:"SortOrder"
	},
	UserPositionLocationOrderByInput:{
		address:"SortOrder",
		city:"SortOrder",
		state:"SortOrder",
		country:"SortOrder"
	},
	UserPositionWhereUniqueInput:{
		AND:"UserPositionWhereInput",
		OR:"UserPositionWhereInput",
		NOT:"UserPositionWhereInput",
		timestamp:"DateTimeFilter",
		createdAt:"DateTimeFilter",
		userId:"StringFilter",
		coords:"UserPositionCoordsCompositeFilter",
		location:"UserPositionLocationNullableCompositeFilter",
		user:"UserRelationFilter"
	},
	UserPositionScalarFieldEnum: "enum" as const,
	Payment:{
		campaign:{
			where:"CampaignWhereInput"
		}
	},
	PaymentOrderByWithRelationInput:{
		id:"SortOrder",
		paymentIntentId:"SortOrder",
		checkoutSessionId:"SortOrder",
		eventId:"SortOrder",
		liveMode:"SortOrder",
		paymentGateway:"SortOrder",
		subtotal:"SortOrder",
		total:"SortOrder",
		tax:"SortOrder",
		discount:"SortOrder",
		campaignId:"SortOrder",
		createdAt:"SortOrder",
		updatedAt:"SortOrder",
		campaign:"CampaignOrderByWithRelationInput"
	},
	PaymentWhereUniqueInput:{
		AND:"PaymentWhereInput",
		OR:"PaymentWhereInput",
		NOT:"PaymentWhereInput",
		liveMode:"BoolFilter",
		paymentGateway:"EnumPaymentGatewayNameFilter",
		subtotal:"FloatFilter",
		total:"FloatFilter",
		tax:"FloatFilter",
		discount:"FloatFilter",
		campaignId:"StringNullableFilter",
		createdAt:"DateTimeFilter",
		updatedAt:"DateTimeFilter",
		campaign:"CampaignNullableRelationFilter"
	},
	PaymentScalarFieldEnum: "enum" as const,
	BusinessCategoryWhereUniqueInput:{
		AND:"BusinessCategoryWhereInput",
		OR:"BusinessCategoryWhereInput",
		NOT:"BusinessCategoryWhereInput",
		description:"StringNullableFilter",
		restrictedByAdBoardIds:"StringNullableListFilter",
		adBoards:"AdBoardListRelationFilter",
		restrictedByAdBoards:"AdBoardListRelationFilter",
		campaigns:"CampaignListRelationFilter",
		bookings:"BookingListRelationFilter"
	},
	BusinessCategoryScalarFieldEnum: "enum" as const,
	SlotConfigurationOrderByWithRelationInput:{
		id:"SortOrder",
		adBoardId:"SortOrder",
		date:"SortOrder",
		availableSlots:"SortOrder",
		adBoard:"AdBoardOrderByWithRelationInput"
	},
	SlotConfigurationWhereUniqueInput:{
		adBoardId_date:"SlotConfigurationAdBoardIdDateCompoundUniqueInput",
		AND:"SlotConfigurationWhereInput",
		OR:"SlotConfigurationWhereInput",
		NOT:"SlotConfigurationWhereInput",
		adBoardId:"StringFilter",
		date:"DateTimeFilter",
		availableSlots:"IntFilter",
		adBoard:"AdBoardRelationFilter"
	},
	SlotConfigurationAdBoardIdDateCompoundUniqueInput:{
		date:"DateTime"
	},
	SlotConfigurationScalarFieldEnum: "enum" as const,
	AdBoardOrderByWithAggregationInput:{
		id:"SortOrder",
		address:"SortOrder",
		state:"SortOrder",
		city:"SortOrder",
		country:"SortOrder",
		outdoor:"SortOrder",
		locationCategoryId:"SortOrder",
		locationSubCategoryId:"SortOrder",
		alias:"SortOrder",
		title:"SortOrder",
		pricePerDay:"SortOrder",
		originalPricePerDay:"SortOrder",
		description:"SortOrder",
		adBoardTypeId:"SortOrder",
		tags:"SortOrder",
		featuredImage:"SortOrder",
		galleryImgs:"SortOrder",
		notes:"SortOrder",
		impressionMultiplier:"SortOrder",
		categoryId:"SortOrder",
		restrictedBusinessIds:"SortOrder",
		targetAudiences:"SortOrder",
		status:"SortOrder",
		adboardDraftStatus:"SortOrder",
		isLive:"SortOrder",
		numberOfDisplays:"SortOrder",
		dailyOperationHours:"SortOrder",
		adsPerLoop:"SortOrder",
		adsDuration:"SortOrder",
		monthlyPrice:"SortOrder",
		offlineBookingsEnabled:"SortOrder",
		offlineBookingContactId:"SortOrder",
		createdAt:"SortOrder",
		createdByUserId:"SortOrder",
		href:"SortOrder",
		workspaceId:"SortOrder",
		playerIds:"SortOrder",
		publisherScreenId:"SortOrder",
		additionalData:"SortOrder",
		isDraft:"SortOrder",
		publishedVersionId:"SortOrder",
		lastPublishedAt:"SortOrder",
		isArchived:"SortOrder",
		archivedAt:"SortOrder",
		archivedByUserId:"SortOrder",
		updatedAt:"SortOrder",
		_count:"AdBoardCountOrderByAggregateInput",
		_avg:"AdBoardAvgOrderByAggregateInput",
		_max:"AdBoardMaxOrderByAggregateInput",
		_min:"AdBoardMinOrderByAggregateInput",
		_sum:"AdBoardSumOrderByAggregateInput"
	},
	AdBoardCountOrderByAggregateInput:{
		id:"SortOrder",
		address:"SortOrder",
		state:"SortOrder",
		city:"SortOrder",
		country:"SortOrder",
		outdoor:"SortOrder",
		locationCategoryId:"SortOrder",
		locationSubCategoryId:"SortOrder",
		alias:"SortOrder",
		title:"SortOrder",
		pricePerDay:"SortOrder",
		originalPricePerDay:"SortOrder",
		description:"SortOrder",
		adBoardTypeId:"SortOrder",
		tags:"SortOrder",
		featuredImage:"SortOrder",
		galleryImgs:"SortOrder",
		notes:"SortOrder",
		impressionMultiplier:"SortOrder",
		categoryId:"SortOrder",
		restrictedBusinessIds:"SortOrder",
		targetAudiences:"SortOrder",
		status:"SortOrder",
		adboardDraftStatus:"SortOrder",
		isLive:"SortOrder",
		numberOfDisplays:"SortOrder",
		dailyOperationHours:"SortOrder",
		adsPerLoop:"SortOrder",
		adsDuration:"SortOrder",
		monthlyPrice:"SortOrder",
		offlineBookingsEnabled:"SortOrder",
		offlineBookingContactId:"SortOrder",
		createdAt:"SortOrder",
		createdByUserId:"SortOrder",
		href:"SortOrder",
		workspaceId:"SortOrder",
		playerIds:"SortOrder",
		publisherScreenId:"SortOrder",
		additionalData:"SortOrder",
		isDraft:"SortOrder",
		publishedVersionId:"SortOrder",
		lastPublishedAt:"SortOrder",
		isArchived:"SortOrder",
		archivedAt:"SortOrder",
		archivedByUserId:"SortOrder",
		updatedAt:"SortOrder"
	},
	AdBoardAvgOrderByAggregateInput:{
		pricePerDay:"SortOrder",
		originalPricePerDay:"SortOrder",
		impressionMultiplier:"SortOrder",
		numberOfDisplays:"SortOrder",
		dailyOperationHours:"SortOrder",
		adsPerLoop:"SortOrder",
		adsDuration:"SortOrder",
		monthlyPrice:"SortOrder"
	},
	AdBoardMaxOrderByAggregateInput:{
		id:"SortOrder",
		address:"SortOrder",
		state:"SortOrder",
		city:"SortOrder",
		country:"SortOrder",
		outdoor:"SortOrder",
		locationCategoryId:"SortOrder",
		locationSubCategoryId:"SortOrder",
		alias:"SortOrder",
		title:"SortOrder",
		pricePerDay:"SortOrder",
		originalPricePerDay:"SortOrder",
		description:"SortOrder",
		adBoardTypeId:"SortOrder",
		featuredImage:"SortOrder",
		impressionMultiplier:"SortOrder",
		categoryId:"SortOrder",
		status:"SortOrder",
		adboardDraftStatus:"SortOrder",
		isLive:"SortOrder",
		numberOfDisplays:"SortOrder",
		dailyOperationHours:"SortOrder",
		adsPerLoop:"SortOrder",
		adsDuration:"SortOrder",
		monthlyPrice:"SortOrder",
		offlineBookingsEnabled:"SortOrder",
		offlineBookingContactId:"SortOrder",
		createdAt:"SortOrder",
		createdByUserId:"SortOrder",
		href:"SortOrder",
		workspaceId:"SortOrder",
		publisherScreenId:"SortOrder",
		isDraft:"SortOrder",
		publishedVersionId:"SortOrder",
		lastPublishedAt:"SortOrder",
		isArchived:"SortOrder",
		archivedAt:"SortOrder",
		archivedByUserId:"SortOrder",
		updatedAt:"SortOrder"
	},
	AdBoardMinOrderByAggregateInput:{
		id:"SortOrder",
		address:"SortOrder",
		state:"SortOrder",
		city:"SortOrder",
		country:"SortOrder",
		outdoor:"SortOrder",
		locationCategoryId:"SortOrder",
		locationSubCategoryId:"SortOrder",
		alias:"SortOrder",
		title:"SortOrder",
		pricePerDay:"SortOrder",
		originalPricePerDay:"SortOrder",
		description:"SortOrder",
		adBoardTypeId:"SortOrder",
		featuredImage:"SortOrder",
		impressionMultiplier:"SortOrder",
		categoryId:"SortOrder",
		status:"SortOrder",
		adboardDraftStatus:"SortOrder",
		isLive:"SortOrder",
		numberOfDisplays:"SortOrder",
		dailyOperationHours:"SortOrder",
		adsPerLoop:"SortOrder",
		adsDuration:"SortOrder",
		monthlyPrice:"SortOrder",
		offlineBookingsEnabled:"SortOrder",
		offlineBookingContactId:"SortOrder",
		createdAt:"SortOrder",
		createdByUserId:"SortOrder",
		href:"SortOrder",
		workspaceId:"SortOrder",
		publisherScreenId:"SortOrder",
		isDraft:"SortOrder",
		publishedVersionId:"SortOrder",
		lastPublishedAt:"SortOrder",
		isArchived:"SortOrder",
		archivedAt:"SortOrder",
		archivedByUserId:"SortOrder",
		updatedAt:"SortOrder"
	},
	AdBoardSumOrderByAggregateInput:{
		pricePerDay:"SortOrder",
		originalPricePerDay:"SortOrder",
		impressionMultiplier:"SortOrder",
		numberOfDisplays:"SortOrder",
		dailyOperationHours:"SortOrder",
		adsPerLoop:"SortOrder",
		adsDuration:"SortOrder",
		monthlyPrice:"SortOrder"
	},
	AdBoardScalarWhereWithAggregatesInput:{
		AND:"AdBoardScalarWhereWithAggregatesInput",
		OR:"AdBoardScalarWhereWithAggregatesInput",
		NOT:"AdBoardScalarWhereWithAggregatesInput",
		id:"StringWithAggregatesFilter",
		address:"StringWithAggregatesFilter",
		state:"StringWithAggregatesFilter",
		city:"StringWithAggregatesFilter",
		country:"StringWithAggregatesFilter",
		outdoor:"BoolWithAggregatesFilter",
		locationCategoryId:"StringNullableWithAggregatesFilter",
		locationSubCategoryId:"StringNullableWithAggregatesFilter",
		alias:"StringNullableWithAggregatesFilter",
		title:"StringWithAggregatesFilter",
		pricePerDay:"IntWithAggregatesFilter",
		originalPricePerDay:"IntNullableWithAggregatesFilter",
		description:"StringNullableWithAggregatesFilter",
		adBoardTypeId:"StringNullableWithAggregatesFilter",
		tags:"StringNullableListFilter",
		featuredImage:"StringNullableWithAggregatesFilter",
		galleryImgs:"StringNullableListFilter",
		notes:"StringNullableListFilter",
		impressionMultiplier:"FloatNullableWithAggregatesFilter",
		categoryId:"StringNullableWithAggregatesFilter",
		restrictedBusinessIds:"StringNullableListFilter",
		targetAudiences:"StringNullableListFilter",
		status:"EnumAdBoardStatusWithAggregatesFilter",
		adboardDraftStatus:"EnumAdboardDraftStatusNullableWithAggregatesFilter",
		isLive:"BoolWithAggregatesFilter",
		numberOfDisplays:"IntWithAggregatesFilter",
		dailyOperationHours:"IntWithAggregatesFilter",
		adsPerLoop:"IntWithAggregatesFilter",
		adsDuration:"IntWithAggregatesFilter",
		monthlyPrice:"FloatNullableWithAggregatesFilter",
		offlineBookingsEnabled:"BoolWithAggregatesFilter",
		offlineBookingContactId:"StringNullableWithAggregatesFilter",
		createdAt:"DateTimeNullableWithAggregatesFilter",
		createdByUserId:"StringNullableWithAggregatesFilter",
		href:"StringNullableWithAggregatesFilter",
		workspaceId:"StringWithAggregatesFilter",
		playerIds:"StringNullableListFilter",
		publisherScreenId:"StringNullableWithAggregatesFilter",
		additionalData:"JsonNullableWithAggregatesFilter",
		isDraft:"BoolWithAggregatesFilter",
		publishedVersionId:"StringNullableWithAggregatesFilter",
		lastPublishedAt:"DateTimeNullableWithAggregatesFilter",
		isArchived:"BoolWithAggregatesFilter",
		archivedAt:"DateTimeNullableWithAggregatesFilter",
		archivedByUserId:"StringNullableWithAggregatesFilter",
		updatedAt:"DateTimeNullableWithAggregatesFilter"
	},
	StringWithAggregatesFilter:{
		mode:"QueryMode",
		not:"NestedStringWithAggregatesFilter",
		_count:"NestedIntFilter",
		_min:"NestedStringFilter",
		_max:"NestedStringFilter"
	},
	NestedStringWithAggregatesFilter:{
		not:"NestedStringWithAggregatesFilter",
		_count:"NestedIntFilter",
		_min:"NestedStringFilter",
		_max:"NestedStringFilter"
	},
	BoolWithAggregatesFilter:{
		not:"NestedBoolWithAggregatesFilter",
		_count:"NestedIntFilter",
		_min:"NestedBoolFilter",
		_max:"NestedBoolFilter"
	},
	NestedBoolWithAggregatesFilter:{
		not:"NestedBoolWithAggregatesFilter",
		_count:"NestedIntFilter",
		_min:"NestedBoolFilter",
		_max:"NestedBoolFilter"
	},
	StringNullableWithAggregatesFilter:{
		mode:"QueryMode",
		not:"NestedStringNullableWithAggregatesFilter",
		_count:"NestedIntNullableFilter",
		_min:"NestedStringNullableFilter",
		_max:"NestedStringNullableFilter"
	},
	NestedStringNullableWithAggregatesFilter:{
		not:"NestedStringNullableWithAggregatesFilter",
		_count:"NestedIntNullableFilter",
		_min:"NestedStringNullableFilter",
		_max:"NestedStringNullableFilter"
	},
	IntWithAggregatesFilter:{
		not:"NestedIntWithAggregatesFilter",
		_count:"NestedIntFilter",
		_avg:"NestedFloatFilter",
		_sum:"NestedIntFilter",
		_min:"NestedIntFilter",
		_max:"NestedIntFilter"
	},
	NestedIntWithAggregatesFilter:{
		not:"NestedIntWithAggregatesFilter",
		_count:"NestedIntFilter",
		_avg:"NestedFloatFilter",
		_sum:"NestedIntFilter",
		_min:"NestedIntFilter",
		_max:"NestedIntFilter"
	},
	IntNullableWithAggregatesFilter:{
		not:"NestedIntNullableWithAggregatesFilter",
		_count:"NestedIntNullableFilter",
		_avg:"NestedFloatNullableFilter",
		_sum:"NestedIntNullableFilter",
		_min:"NestedIntNullableFilter",
		_max:"NestedIntNullableFilter"
	},
	NestedIntNullableWithAggregatesFilter:{
		not:"NestedIntNullableWithAggregatesFilter",
		_count:"NestedIntNullableFilter",
		_avg:"NestedFloatNullableFilter",
		_sum:"NestedIntNullableFilter",
		_min:"NestedIntNullableFilter",
		_max:"NestedIntNullableFilter"
	},
	FloatNullableWithAggregatesFilter:{
		not:"NestedFloatNullableWithAggregatesFilter",
		_count:"NestedIntNullableFilter",
		_avg:"NestedFloatNullableFilter",
		_sum:"NestedFloatNullableFilter",
		_min:"NestedFloatNullableFilter",
		_max:"NestedFloatNullableFilter"
	},
	NestedFloatNullableWithAggregatesFilter:{
		not:"NestedFloatNullableWithAggregatesFilter",
		_count:"NestedIntNullableFilter",
		_avg:"NestedFloatNullableFilter",
		_sum:"NestedFloatNullableFilter",
		_min:"NestedFloatNullableFilter",
		_max:"NestedFloatNullableFilter"
	},
	EnumAdBoardStatusWithAggregatesFilter:{
		equals:"AdBoardStatus",
		in:"AdBoardStatus",
		notIn:"AdBoardStatus",
		not:"NestedEnumAdBoardStatusWithAggregatesFilter",
		_count:"NestedIntFilter",
		_min:"NestedEnumAdBoardStatusFilter",
		_max:"NestedEnumAdBoardStatusFilter"
	},
	NestedEnumAdBoardStatusWithAggregatesFilter:{
		equals:"AdBoardStatus",
		in:"AdBoardStatus",
		notIn:"AdBoardStatus",
		not:"NestedEnumAdBoardStatusWithAggregatesFilter",
		_count:"NestedIntFilter",
		_min:"NestedEnumAdBoardStatusFilter",
		_max:"NestedEnumAdBoardStatusFilter"
	},
	EnumAdboardDraftStatusNullableWithAggregatesFilter:{
		equals:"AdboardDraftStatus",
		in:"AdboardDraftStatus",
		notIn:"AdboardDraftStatus",
		not:"NestedEnumAdboardDraftStatusNullableWithAggregatesFilter",
		_count:"NestedIntNullableFilter",
		_min:"NestedEnumAdboardDraftStatusNullableFilter",
		_max:"NestedEnumAdboardDraftStatusNullableFilter"
	},
	NestedEnumAdboardDraftStatusNullableWithAggregatesFilter:{
		equals:"AdboardDraftStatus",
		in:"AdboardDraftStatus",
		notIn:"AdboardDraftStatus",
		not:"NestedEnumAdboardDraftStatusNullableWithAggregatesFilter",
		_count:"NestedIntNullableFilter",
		_min:"NestedEnumAdboardDraftStatusNullableFilter",
		_max:"NestedEnumAdboardDraftStatusNullableFilter"
	},
	DateTimeNullableWithAggregatesFilter:{
		equals:"DateTime",
		in:"DateTime",
		notIn:"DateTime",
		lt:"DateTime",
		lte:"DateTime",
		gt:"DateTime",
		gte:"DateTime",
		not:"NestedDateTimeNullableWithAggregatesFilter",
		_count:"NestedIntNullableFilter",
		_min:"NestedDateTimeNullableFilter",
		_max:"NestedDateTimeNullableFilter"
	},
	NestedDateTimeNullableWithAggregatesFilter:{
		equals:"DateTime",
		in:"DateTime",
		notIn:"DateTime",
		lt:"DateTime",
		lte:"DateTime",
		gt:"DateTime",
		gte:"DateTime",
		not:"NestedDateTimeNullableWithAggregatesFilter",
		_count:"NestedIntNullableFilter",
		_min:"NestedDateTimeNullableFilter",
		_max:"NestedDateTimeNullableFilter"
	},
	JsonNullableWithAggregatesFilter:{
		equals:"JSON",
		not:"JSON",
		_count:"NestedIntNullableFilter",
		_min:"NestedJsonNullableFilter",
		_max:"NestedJsonNullableFilter"
	},
	NestedJsonNullableFilter:{
		equals:"JSON",
		not:"JSON"
	},
	AdBoardGroupOrderByWithAggregationInput:{
		id:"SortOrder",
		name:"SortOrder",
		adBoardId:"SortOrder",
		scheduledPlaylistsIDs:"SortOrder",
		adsPlaylistID:"SortOrder",
		pisignageGroupId:"SortOrder",
		fitImage:"SortOrder",
		fitVideo:"SortOrder",
		workspaceId:"SortOrder",
		_count:"AdBoardGroupCountOrderByAggregateInput",
		_max:"AdBoardGroupMaxOrderByAggregateInput",
		_min:"AdBoardGroupMinOrderByAggregateInput"
	},
	AdBoardGroupCountOrderByAggregateInput:{
		id:"SortOrder",
		name:"SortOrder",
		adBoardId:"SortOrder",
		scheduledPlaylistsIDs:"SortOrder",
		adsPlaylistID:"SortOrder",
		pisignageGroupId:"SortOrder",
		fitImage:"SortOrder",
		fitVideo:"SortOrder",
		workspaceId:"SortOrder"
	},
	AdBoardGroupMaxOrderByAggregateInput:{
		id:"SortOrder",
		name:"SortOrder",
		adBoardId:"SortOrder",
		adsPlaylistID:"SortOrder",
		pisignageGroupId:"SortOrder",
		fitImage:"SortOrder",
		fitVideo:"SortOrder",
		workspaceId:"SortOrder"
	},
	AdBoardGroupMinOrderByAggregateInput:{
		id:"SortOrder",
		name:"SortOrder",
		adBoardId:"SortOrder",
		adsPlaylistID:"SortOrder",
		pisignageGroupId:"SortOrder",
		fitImage:"SortOrder",
		fitVideo:"SortOrder",
		workspaceId:"SortOrder"
	},
	AdBoardGroupScalarWhereWithAggregatesInput:{
		AND:"AdBoardGroupScalarWhereWithAggregatesInput",
		OR:"AdBoardGroupScalarWhereWithAggregatesInput",
		NOT:"AdBoardGroupScalarWhereWithAggregatesInput",
		id:"StringWithAggregatesFilter",
		name:"StringWithAggregatesFilter",
		adBoardId:"StringNullableWithAggregatesFilter",
		scheduledPlaylistsIDs:"StringNullableListFilter",
		adsPlaylistID:"StringNullableWithAggregatesFilter",
		pisignageGroupId:"StringWithAggregatesFilter",
		fitImage:"EnumFitTypeWithAggregatesFilter",
		fitVideo:"EnumFitTypeWithAggregatesFilter",
		workspaceId:"StringWithAggregatesFilter"
	},
	EnumFitTypeWithAggregatesFilter:{
		equals:"FitType",
		in:"FitType",
		notIn:"FitType",
		not:"NestedEnumFitTypeWithAggregatesFilter",
		_count:"NestedIntFilter",
		_min:"NestedEnumFitTypeFilter",
		_max:"NestedEnumFitTypeFilter"
	},
	NestedEnumFitTypeWithAggregatesFilter:{
		equals:"FitType",
		in:"FitType",
		notIn:"FitType",
		not:"NestedEnumFitTypeWithAggregatesFilter",
		_count:"NestedIntFilter",
		_min:"NestedEnumFitTypeFilter",
		_max:"NestedEnumFitTypeFilter"
	},
	AdBoardTypeWhereUniqueInput:{
		AND:"AdBoardTypeWhereInput",
		OR:"AdBoardTypeWhereInput",
		NOT:"AdBoardTypeWhereInput",
		name:"StringFilter",
		description:"StringNullableFilter",
		adBoards:"AdBoardListRelationFilter"
	},
	AdBoardTypeScalarFieldEnum: "enum" as const,
	AdBoardTypeOrderByWithAggregationInput:{
		id:"SortOrder",
		name:"SortOrder",
		description:"SortOrder",
		_count:"AdBoardTypeCountOrderByAggregateInput",
		_max:"AdBoardTypeMaxOrderByAggregateInput",
		_min:"AdBoardTypeMinOrderByAggregateInput"
	},
	AdBoardTypeCountOrderByAggregateInput:{
		id:"SortOrder",
		name:"SortOrder",
		description:"SortOrder"
	},
	AdBoardTypeMaxOrderByAggregateInput:{
		id:"SortOrder",
		name:"SortOrder",
		description:"SortOrder"
	},
	AdBoardTypeMinOrderByAggregateInput:{
		id:"SortOrder",
		name:"SortOrder",
		description:"SortOrder"
	},
	AdBoardTypeScalarWhereWithAggregatesInput:{
		AND:"AdBoardTypeScalarWhereWithAggregatesInput",
		OR:"AdBoardTypeScalarWhereWithAggregatesInput",
		NOT:"AdBoardTypeScalarWhereWithAggregatesInput",
		id:"StringWithAggregatesFilter",
		name:"StringWithAggregatesFilter",
		description:"StringNullableWithAggregatesFilter"
	},
	AuditOrderByWithAggregationInput:{
		id:"SortOrder",
		createdAt:"SortOrder",
		message:"SortOrder",
		action:"SortOrder",
		userId:"SortOrder",
		workspaceId:"SortOrder",
		updates:"SortOrder",
		_count:"AuditCountOrderByAggregateInput",
		_max:"AuditMaxOrderByAggregateInput",
		_min:"AuditMinOrderByAggregateInput"
	},
	AuditCountOrderByAggregateInput:{
		id:"SortOrder",
		createdAt:"SortOrder",
		message:"SortOrder",
		action:"SortOrder",
		userId:"SortOrder",
		workspaceId:"SortOrder",
		updates:"SortOrder"
	},
	AuditMaxOrderByAggregateInput:{
		id:"SortOrder",
		createdAt:"SortOrder",
		message:"SortOrder",
		action:"SortOrder",
		userId:"SortOrder",
		workspaceId:"SortOrder"
	},
	AuditMinOrderByAggregateInput:{
		id:"SortOrder",
		createdAt:"SortOrder",
		message:"SortOrder",
		action:"SortOrder",
		userId:"SortOrder",
		workspaceId:"SortOrder"
	},
	AuditScalarWhereWithAggregatesInput:{
		AND:"AuditScalarWhereWithAggregatesInput",
		OR:"AuditScalarWhereWithAggregatesInput",
		NOT:"AuditScalarWhereWithAggregatesInput",
		id:"StringWithAggregatesFilter",
		createdAt:"DateTimeWithAggregatesFilter",
		message:"StringWithAggregatesFilter",
		action:"StringWithAggregatesFilter",
		userId:"StringWithAggregatesFilter",
		workspaceId:"StringNullableWithAggregatesFilter",
		updates:"JsonNullableWithAggregatesFilter"
	},
	DateTimeWithAggregatesFilter:{
		equals:"DateTime",
		in:"DateTime",
		notIn:"DateTime",
		lt:"DateTime",
		lte:"DateTime",
		gt:"DateTime",
		gte:"DateTime",
		not:"NestedDateTimeWithAggregatesFilter",
		_count:"NestedIntFilter",
		_min:"NestedDateTimeFilter",
		_max:"NestedDateTimeFilter"
	},
	NestedDateTimeWithAggregatesFilter:{
		equals:"DateTime",
		in:"DateTime",
		notIn:"DateTime",
		lt:"DateTime",
		lte:"DateTime",
		gt:"DateTime",
		gte:"DateTime",
		not:"NestedDateTimeWithAggregatesFilter",
		_count:"NestedIntFilter",
		_min:"NestedDateTimeFilter",
		_max:"NestedDateTimeFilter"
	},
	BookingOrderByWithAggregationInput:{
		id:"SortOrder",
		additionalInformation:"SortOrder",
		origin:"SortOrder",
		assetsIDs:"SortOrder",
		bookingCode:"SortOrder",
		bookingDate:"SortOrder",
		bookingFor:"SortOrder",
		purpose:"SortOrder",
		businessCategoryId:"SortOrder",
		adBoardId:"SortOrder",
		workspaceId:"SortOrder",
		campaignId:"SortOrder",
		bookingStatus:"SortOrder",
		paymentStatus:"SortOrder",
		deploymentStatus:"SortOrder",
		contentApprovalStatus:"SortOrder",
		userId:"SortOrder",
		sharedUserIds:"SortOrder",
		isOffline:"SortOrder",
		isPaid:"SortOrder",
		isScheduled:"SortOrder",
		isDeployed:"SortOrder",
		isCompleted:"SortOrder",
		_count:"BookingCountOrderByAggregateInput",
		_max:"BookingMaxOrderByAggregateInput",
		_min:"BookingMinOrderByAggregateInput"
	},
	BookingCountOrderByAggregateInput:{
		id:"SortOrder",
		additionalInformation:"SortOrder",
		origin:"SortOrder",
		assetsIDs:"SortOrder",
		bookingCode:"SortOrder",
		bookingDate:"SortOrder",
		bookingFor:"SortOrder",
		purpose:"SortOrder",
		businessCategoryId:"SortOrder",
		adBoardId:"SortOrder",
		workspaceId:"SortOrder",
		campaignId:"SortOrder",
		bookingStatus:"SortOrder",
		paymentStatus:"SortOrder",
		deploymentStatus:"SortOrder",
		contentApprovalStatus:"SortOrder",
		userId:"SortOrder",
		sharedUserIds:"SortOrder",
		isOffline:"SortOrder",
		isPaid:"SortOrder",
		isScheduled:"SortOrder",
		isDeployed:"SortOrder",
		isCompleted:"SortOrder"
	},
	BookingMaxOrderByAggregateInput:{
		id:"SortOrder",
		additionalInformation:"SortOrder",
		origin:"SortOrder",
		bookingCode:"SortOrder",
		bookingDate:"SortOrder",
		bookingFor:"SortOrder",
		purpose:"SortOrder",
		businessCategoryId:"SortOrder",
		adBoardId:"SortOrder",
		workspaceId:"SortOrder",
		campaignId:"SortOrder",
		bookingStatus:"SortOrder",
		paymentStatus:"SortOrder",
		deploymentStatus:"SortOrder",
		contentApprovalStatus:"SortOrder",
		userId:"SortOrder",
		isOffline:"SortOrder",
		isPaid:"SortOrder",
		isScheduled:"SortOrder",
		isDeployed:"SortOrder",
		isCompleted:"SortOrder"
	},
	BookingMinOrderByAggregateInput:{
		id:"SortOrder",
		additionalInformation:"SortOrder",
		origin:"SortOrder",
		bookingCode:"SortOrder",
		bookingDate:"SortOrder",
		bookingFor:"SortOrder",
		purpose:"SortOrder",
		businessCategoryId:"SortOrder",
		adBoardId:"SortOrder",
		workspaceId:"SortOrder",
		campaignId:"SortOrder",
		bookingStatus:"SortOrder",
		paymentStatus:"SortOrder",
		deploymentStatus:"SortOrder",
		contentApprovalStatus:"SortOrder",
		userId:"SortOrder",
		isOffline:"SortOrder",
		isPaid:"SortOrder",
		isScheduled:"SortOrder",
		isDeployed:"SortOrder",
		isCompleted:"SortOrder"
	},
	BookingScalarWhereWithAggregatesInput:{
		AND:"BookingScalarWhereWithAggregatesInput",
		OR:"BookingScalarWhereWithAggregatesInput",
		NOT:"BookingScalarWhereWithAggregatesInput",
		id:"StringWithAggregatesFilter",
		additionalInformation:"StringNullableWithAggregatesFilter",
		origin:"StringNullableWithAggregatesFilter",
		assetsIDs:"StringNullableListFilter",
		bookingCode:"StringWithAggregatesFilter",
		bookingDate:"DateTimeWithAggregatesFilter",
		bookingFor:"StringWithAggregatesFilter",
		purpose:"StringWithAggregatesFilter",
		businessCategoryId:"StringNullableWithAggregatesFilter",
		adBoardId:"StringWithAggregatesFilter",
		workspaceId:"StringNullableWithAggregatesFilter",
		campaignId:"StringNullableWithAggregatesFilter",
		bookingStatus:"EnumBookingStatusWithAggregatesFilter",
		paymentStatus:"EnumPaymentStatusNullableWithAggregatesFilter",
		deploymentStatus:"EnumDeploymentStatusWithAggregatesFilter",
		contentApprovalStatus:"EnumContentApprovalStatusWithAggregatesFilter",
		userId:"StringWithAggregatesFilter",
		sharedUserIds:"StringNullableListFilter",
		isOffline:"BoolWithAggregatesFilter",
		isPaid:"BoolWithAggregatesFilter",
		isScheduled:"BoolWithAggregatesFilter",
		isDeployed:"BoolWithAggregatesFilter",
		isCompleted:"BoolWithAggregatesFilter"
	},
	EnumBookingStatusWithAggregatesFilter:{
		equals:"BookingStatus",
		in:"BookingStatus",
		notIn:"BookingStatus",
		not:"NestedEnumBookingStatusWithAggregatesFilter",
		_count:"NestedIntFilter",
		_min:"NestedEnumBookingStatusFilter",
		_max:"NestedEnumBookingStatusFilter"
	},
	NestedEnumBookingStatusWithAggregatesFilter:{
		equals:"BookingStatus",
		in:"BookingStatus",
		notIn:"BookingStatus",
		not:"NestedEnumBookingStatusWithAggregatesFilter",
		_count:"NestedIntFilter",
		_min:"NestedEnumBookingStatusFilter",
		_max:"NestedEnumBookingStatusFilter"
	},
	EnumPaymentStatusNullableWithAggregatesFilter:{
		equals:"PaymentStatus",
		in:"PaymentStatus",
		notIn:"PaymentStatus",
		not:"NestedEnumPaymentStatusNullableWithAggregatesFilter",
		_count:"NestedIntNullableFilter",
		_min:"NestedEnumPaymentStatusNullableFilter",
		_max:"NestedEnumPaymentStatusNullableFilter"
	},
	NestedEnumPaymentStatusNullableWithAggregatesFilter:{
		equals:"PaymentStatus",
		in:"PaymentStatus",
		notIn:"PaymentStatus",
		not:"NestedEnumPaymentStatusNullableWithAggregatesFilter",
		_count:"NestedIntNullableFilter",
		_min:"NestedEnumPaymentStatusNullableFilter",
		_max:"NestedEnumPaymentStatusNullableFilter"
	},
	EnumDeploymentStatusWithAggregatesFilter:{
		equals:"DeploymentStatus",
		in:"DeploymentStatus",
		notIn:"DeploymentStatus",
		not:"NestedEnumDeploymentStatusWithAggregatesFilter",
		_count:"NestedIntFilter",
		_min:"NestedEnumDeploymentStatusFilter",
		_max:"NestedEnumDeploymentStatusFilter"
	},
	NestedEnumDeploymentStatusWithAggregatesFilter:{
		equals:"DeploymentStatus",
		in:"DeploymentStatus",
		notIn:"DeploymentStatus",
		not:"NestedEnumDeploymentStatusWithAggregatesFilter",
		_count:"NestedIntFilter",
		_min:"NestedEnumDeploymentStatusFilter",
		_max:"NestedEnumDeploymentStatusFilter"
	},
	EnumContentApprovalStatusWithAggregatesFilter:{
		equals:"ContentApprovalStatus",
		in:"ContentApprovalStatus",
		notIn:"ContentApprovalStatus",
		not:"NestedEnumContentApprovalStatusWithAggregatesFilter",
		_count:"NestedIntFilter",
		_min:"NestedEnumContentApprovalStatusFilter",
		_max:"NestedEnumContentApprovalStatusFilter"
	},
	NestedEnumContentApprovalStatusWithAggregatesFilter:{
		equals:"ContentApprovalStatus",
		in:"ContentApprovalStatus",
		notIn:"ContentApprovalStatus",
		not:"NestedEnumContentApprovalStatusWithAggregatesFilter",
		_count:"NestedIntFilter",
		_min:"NestedEnumContentApprovalStatusFilter",
		_max:"NestedEnumContentApprovalStatusFilter"
	},
	BusinessCategoryOrderByWithAggregationInput:{
		id:"SortOrder",
		name:"SortOrder",
		description:"SortOrder",
		restrictedByAdBoardIds:"SortOrder",
		_count:"BusinessCategoryCountOrderByAggregateInput",
		_max:"BusinessCategoryMaxOrderByAggregateInput",
		_min:"BusinessCategoryMinOrderByAggregateInput"
	},
	BusinessCategoryCountOrderByAggregateInput:{
		id:"SortOrder",
		name:"SortOrder",
		description:"SortOrder",
		restrictedByAdBoardIds:"SortOrder"
	},
	BusinessCategoryMaxOrderByAggregateInput:{
		id:"SortOrder",
		name:"SortOrder",
		description:"SortOrder"
	},
	BusinessCategoryMinOrderByAggregateInput:{
		id:"SortOrder",
		name:"SortOrder",
		description:"SortOrder"
	},
	BusinessCategoryScalarWhereWithAggregatesInput:{
		AND:"BusinessCategoryScalarWhereWithAggregatesInput",
		OR:"BusinessCategoryScalarWhereWithAggregatesInput",
		NOT:"BusinessCategoryScalarWhereWithAggregatesInput",
		id:"StringWithAggregatesFilter",
		name:"StringWithAggregatesFilter",
		description:"StringNullableWithAggregatesFilter",
		restrictedByAdBoardIds:"StringNullableListFilter"
	},
	CameraOrderByWithAggregationInput:{
		id:"SortOrder",
		name:"SortOrder",
		isOnline:"SortOrder",
		cameraServerId:"SortOrder",
		cameraUrl:"SortOrder",
		rtspUrl:"SortOrder",
		cameraIp:"SortOrder",
		connectedCpuSerialNumbers:"SortOrder",
		playerId:"SortOrder",
		updatedAt:"SortOrder",
		isPublic:"SortOrder",
		adBoardId:"SortOrder",
		_count:"CameraCountOrderByAggregateInput",
		_max:"CameraMaxOrderByAggregateInput",
		_min:"CameraMinOrderByAggregateInput"
	},
	CameraCountOrderByAggregateInput:{
		id:"SortOrder",
		name:"SortOrder",
		isOnline:"SortOrder",
		cameraServerId:"SortOrder",
		cameraUrl:"SortOrder",
		rtspUrl:"SortOrder",
		cameraIp:"SortOrder",
		connectedCpuSerialNumbers:"SortOrder",
		playerId:"SortOrder",
		updatedAt:"SortOrder",
		isPublic:"SortOrder",
		adBoardId:"SortOrder"
	},
	CameraMaxOrderByAggregateInput:{
		id:"SortOrder",
		name:"SortOrder",
		isOnline:"SortOrder",
		cameraServerId:"SortOrder",
		cameraUrl:"SortOrder",
		rtspUrl:"SortOrder",
		cameraIp:"SortOrder",
		playerId:"SortOrder",
		updatedAt:"SortOrder",
		isPublic:"SortOrder",
		adBoardId:"SortOrder"
	},
	CameraMinOrderByAggregateInput:{
		id:"SortOrder",
		name:"SortOrder",
		isOnline:"SortOrder",
		cameraServerId:"SortOrder",
		cameraUrl:"SortOrder",
		rtspUrl:"SortOrder",
		cameraIp:"SortOrder",
		playerId:"SortOrder",
		updatedAt:"SortOrder",
		isPublic:"SortOrder",
		adBoardId:"SortOrder"
	},
	CameraScalarWhereWithAggregatesInput:{
		AND:"CameraScalarWhereWithAggregatesInput",
		OR:"CameraScalarWhereWithAggregatesInput",
		NOT:"CameraScalarWhereWithAggregatesInput",
		id:"StringWithAggregatesFilter",
		name:"StringNullableWithAggregatesFilter",
		isOnline:"BoolWithAggregatesFilter",
		cameraServerId:"StringWithAggregatesFilter",
		cameraUrl:"StringWithAggregatesFilter",
		rtspUrl:"StringWithAggregatesFilter",
		cameraIp:"StringWithAggregatesFilter",
		connectedCpuSerialNumbers:"StringNullableListFilter",
		playerId:"StringNullableWithAggregatesFilter",
		updatedAt:"DateTimeWithAggregatesFilter",
		isPublic:"BoolWithAggregatesFilter",
		adBoardId:"StringNullableWithAggregatesFilter"
	},
	CameraFeedOrderByWithAggregationInput:{
		id:"SortOrder",
		cameraId:"SortOrder",
		timestamp:"SortOrder",
		cameraServerId:"SortOrder",
		_count:"CameraFeedCountOrderByAggregateInput",
		_max:"CameraFeedMaxOrderByAggregateInput",
		_min:"CameraFeedMinOrderByAggregateInput"
	},
	CameraFeedCountOrderByAggregateInput:{
		id:"SortOrder",
		cameraId:"SortOrder",
		timestamp:"SortOrder",
		cameraServerId:"SortOrder"
	},
	CameraFeedMaxOrderByAggregateInput:{
		id:"SortOrder",
		cameraId:"SortOrder",
		timestamp:"SortOrder",
		cameraServerId:"SortOrder"
	},
	CameraFeedMinOrderByAggregateInput:{
		id:"SortOrder",
		cameraId:"SortOrder",
		timestamp:"SortOrder",
		cameraServerId:"SortOrder"
	},
	CameraFeedScalarWhereWithAggregatesInput:{
		AND:"CameraFeedScalarWhereWithAggregatesInput",
		OR:"CameraFeedScalarWhereWithAggregatesInput",
		NOT:"CameraFeedScalarWhereWithAggregatesInput",
		id:"StringWithAggregatesFilter",
		cameraId:"StringWithAggregatesFilter",
		timestamp:"DateTimeWithAggregatesFilter",
		cameraServerId:"StringWithAggregatesFilter"
	},
	CameraServerOrderByWithAggregationInput:{
		id:"SortOrder",
		name:"SortOrder",
		isOnline:"SortOrder",
		hostName:"SortOrder",
		userName:"SortOrder",
		deviceId:"SortOrder",
		workspaceId:"SortOrder",
		publicIp:"SortOrder",
		privateIp:"SortOrder",
		_count:"CameraServerCountOrderByAggregateInput",
		_max:"CameraServerMaxOrderByAggregateInput",
		_min:"CameraServerMinOrderByAggregateInput"
	},
	CameraServerCountOrderByAggregateInput:{
		id:"SortOrder",
		name:"SortOrder",
		isOnline:"SortOrder",
		hostName:"SortOrder",
		userName:"SortOrder",
		deviceId:"SortOrder",
		workspaceId:"SortOrder",
		publicIp:"SortOrder",
		privateIp:"SortOrder"
	},
	CameraServerMaxOrderByAggregateInput:{
		id:"SortOrder",
		name:"SortOrder",
		isOnline:"SortOrder",
		hostName:"SortOrder",
		userName:"SortOrder",
		deviceId:"SortOrder",
		workspaceId:"SortOrder",
		publicIp:"SortOrder",
		privateIp:"SortOrder"
	},
	CameraServerMinOrderByAggregateInput:{
		id:"SortOrder",
		name:"SortOrder",
		isOnline:"SortOrder",
		hostName:"SortOrder",
		userName:"SortOrder",
		deviceId:"SortOrder",
		workspaceId:"SortOrder",
		publicIp:"SortOrder",
		privateIp:"SortOrder"
	},
	CameraServerScalarWhereWithAggregatesInput:{
		AND:"CameraServerScalarWhereWithAggregatesInput",
		OR:"CameraServerScalarWhereWithAggregatesInput",
		NOT:"CameraServerScalarWhereWithAggregatesInput",
		id:"StringWithAggregatesFilter",
		name:"StringNullableWithAggregatesFilter",
		isOnline:"BoolNullableWithAggregatesFilter",
		hostName:"StringNullableWithAggregatesFilter",
		userName:"StringNullableWithAggregatesFilter",
		deviceId:"StringWithAggregatesFilter",
		workspaceId:"StringNullableWithAggregatesFilter",
		publicIp:"StringWithAggregatesFilter",
		privateIp:"StringWithAggregatesFilter"
	},
	BoolNullableWithAggregatesFilter:{
		not:"NestedBoolNullableWithAggregatesFilter",
		_count:"NestedIntNullableFilter",
		_min:"NestedBoolNullableFilter",
		_max:"NestedBoolNullableFilter"
	},
	NestedBoolNullableWithAggregatesFilter:{
		not:"NestedBoolNullableWithAggregatesFilter",
		_count:"NestedIntNullableFilter",
		_min:"NestedBoolNullableFilter",
		_max:"NestedBoolNullableFilter"
	},
	CampaignOrderByWithAggregationInput:{
		id:"SortOrder",
		name:"SortOrder",
		userId:"SortOrder",
		createdAt:"SortOrder",
		updatedAt:"SortOrder",
		bookingFor:"SortOrder",
		purpose:"SortOrder",
		businessCategoryId:"SortOrder",
		paymentStatus:"SortOrder",
		origin:"SortOrder",
		assetsIDs:"SortOrder",
		_count:"CampaignCountOrderByAggregateInput",
		_max:"CampaignMaxOrderByAggregateInput",
		_min:"CampaignMinOrderByAggregateInput"
	},
	CampaignCountOrderByAggregateInput:{
		id:"SortOrder",
		name:"SortOrder",
		userId:"SortOrder",
		createdAt:"SortOrder",
		updatedAt:"SortOrder",
		bookingFor:"SortOrder",
		purpose:"SortOrder",
		businessCategoryId:"SortOrder",
		paymentStatus:"SortOrder",
		origin:"SortOrder",
		assetsIDs:"SortOrder"
	},
	CampaignMaxOrderByAggregateInput:{
		id:"SortOrder",
		name:"SortOrder",
		userId:"SortOrder",
		createdAt:"SortOrder",
		updatedAt:"SortOrder",
		bookingFor:"SortOrder",
		purpose:"SortOrder",
		businessCategoryId:"SortOrder",
		paymentStatus:"SortOrder",
		origin:"SortOrder"
	},
	CampaignMinOrderByAggregateInput:{
		id:"SortOrder",
		name:"SortOrder",
		userId:"SortOrder",
		createdAt:"SortOrder",
		updatedAt:"SortOrder",
		bookingFor:"SortOrder",
		purpose:"SortOrder",
		businessCategoryId:"SortOrder",
		paymentStatus:"SortOrder",
		origin:"SortOrder"
	},
	CampaignScalarWhereWithAggregatesInput:{
		AND:"CampaignScalarWhereWithAggregatesInput",
		OR:"CampaignScalarWhereWithAggregatesInput",
		NOT:"CampaignScalarWhereWithAggregatesInput",
		id:"StringWithAggregatesFilter",
		name:"StringWithAggregatesFilter",
		userId:"StringWithAggregatesFilter",
		createdAt:"DateTimeWithAggregatesFilter",
		updatedAt:"DateTimeWithAggregatesFilter",
		bookingFor:"StringWithAggregatesFilter",
		purpose:"StringWithAggregatesFilter",
		businessCategoryId:"StringWithAggregatesFilter",
		paymentStatus:"EnumPaymentStatusWithAggregatesFilter",
		origin:"StringWithAggregatesFilter",
		assetsIDs:"StringNullableListFilter"
	},
	EnumPaymentStatusWithAggregatesFilter:{
		equals:"PaymentStatus",
		in:"PaymentStatus",
		notIn:"PaymentStatus",
		not:"NestedEnumPaymentStatusWithAggregatesFilter",
		_count:"NestedIntFilter",
		_min:"NestedEnumPaymentStatusFilter",
		_max:"NestedEnumPaymentStatusFilter"
	},
	NestedEnumPaymentStatusWithAggregatesFilter:{
		equals:"PaymentStatus",
		in:"PaymentStatus",
		notIn:"PaymentStatus",
		not:"NestedEnumPaymentStatusWithAggregatesFilter",
		_count:"NestedIntFilter",
		_min:"NestedEnumPaymentStatusFilter",
		_max:"NestedEnumPaymentStatusFilter"
	},
	FileOrderByWithAggregationInput:{
		id:"SortOrder",
		contentType:"SortOrder",
		ctime:"SortOrder",
		filename:"SortOrder",
		filepath:"SortOrder",
		isDirectory:"SortOrder",
		mtime:"SortOrder",
		name:"SortOrder",
		size:"SortOrder",
		status:"SortOrder",
		type:"SortOrder",
		updatedAt:"SortOrder",
		createdAt:"SortOrder",
		updatedByUserId:"SortOrder",
		createdByUserId:"SortOrder",
		url:"SortOrder",
		bookingsIDs:"SortOrder",
		workspaceId:"SortOrder",
		proofBookingId:"SortOrder",
		campaignIds:"SortOrder",
		_count:"FileCountOrderByAggregateInput",
		_avg:"FileAvgOrderByAggregateInput",
		_max:"FileMaxOrderByAggregateInput",
		_min:"FileMinOrderByAggregateInput",
		_sum:"FileSumOrderByAggregateInput"
	},
	FileCountOrderByAggregateInput:{
		id:"SortOrder",
		contentType:"SortOrder",
		ctime:"SortOrder",
		filename:"SortOrder",
		filepath:"SortOrder",
		isDirectory:"SortOrder",
		mtime:"SortOrder",
		name:"SortOrder",
		size:"SortOrder",
		status:"SortOrder",
		type:"SortOrder",
		updatedAt:"SortOrder",
		createdAt:"SortOrder",
		updatedByUserId:"SortOrder",
		createdByUserId:"SortOrder",
		url:"SortOrder",
		bookingsIDs:"SortOrder",
		workspaceId:"SortOrder",
		proofBookingId:"SortOrder",
		campaignIds:"SortOrder"
	},
	FileAvgOrderByAggregateInput:{
		size:"SortOrder"
	},
	FileMaxOrderByAggregateInput:{
		id:"SortOrder",
		contentType:"SortOrder",
		ctime:"SortOrder",
		filename:"SortOrder",
		filepath:"SortOrder",
		isDirectory:"SortOrder",
		mtime:"SortOrder",
		name:"SortOrder",
		size:"SortOrder",
		status:"SortOrder",
		type:"SortOrder",
		updatedAt:"SortOrder",
		createdAt:"SortOrder",
		updatedByUserId:"SortOrder",
		createdByUserId:"SortOrder",
		url:"SortOrder",
		workspaceId:"SortOrder",
		proofBookingId:"SortOrder"
	},
	FileMinOrderByAggregateInput:{
		id:"SortOrder",
		contentType:"SortOrder",
		ctime:"SortOrder",
		filename:"SortOrder",
		filepath:"SortOrder",
		isDirectory:"SortOrder",
		mtime:"SortOrder",
		name:"SortOrder",
		size:"SortOrder",
		status:"SortOrder",
		type:"SortOrder",
		updatedAt:"SortOrder",
		createdAt:"SortOrder",
		updatedByUserId:"SortOrder",
		createdByUserId:"SortOrder",
		url:"SortOrder",
		workspaceId:"SortOrder",
		proofBookingId:"SortOrder"
	},
	FileSumOrderByAggregateInput:{
		size:"SortOrder"
	},
	FileScalarWhereWithAggregatesInput:{
		AND:"FileScalarWhereWithAggregatesInput",
		OR:"FileScalarWhereWithAggregatesInput",
		NOT:"FileScalarWhereWithAggregatesInput",
		id:"StringWithAggregatesFilter",
		contentType:"StringWithAggregatesFilter",
		ctime:"StringWithAggregatesFilter",
		filename:"StringWithAggregatesFilter",
		filepath:"StringNullableWithAggregatesFilter",
		isDirectory:"BoolWithAggregatesFilter",
		mtime:"StringWithAggregatesFilter",
		name:"StringWithAggregatesFilter",
		size:"IntWithAggregatesFilter",
		status:"EnumFileStatusNullableWithAggregatesFilter",
		type:"StringWithAggregatesFilter",
		updatedAt:"DateTimeWithAggregatesFilter",
		createdAt:"DateTimeWithAggregatesFilter",
		updatedByUserId:"StringNullableWithAggregatesFilter",
		createdByUserId:"StringWithAggregatesFilter",
		url:"StringWithAggregatesFilter",
		bookingsIDs:"StringNullableListFilter",
		workspaceId:"StringNullableWithAggregatesFilter",
		proofBookingId:"StringNullableWithAggregatesFilter",
		campaignIds:"StringNullableListFilter"
	},
	EnumFileStatusNullableWithAggregatesFilter:{
		equals:"FileStatus",
		in:"FileStatus",
		notIn:"FileStatus",
		not:"NestedEnumFileStatusNullableWithAggregatesFilter",
		_count:"NestedIntNullableFilter",
		_min:"NestedEnumFileStatusNullableFilter",
		_max:"NestedEnumFileStatusNullableFilter"
	},
	NestedEnumFileStatusNullableWithAggregatesFilter:{
		equals:"FileStatus",
		in:"FileStatus",
		notIn:"FileStatus",
		not:"NestedEnumFileStatusNullableWithAggregatesFilter",
		_count:"NestedIntNullableFilter",
		_min:"NestedEnumFileStatusNullableFilter",
		_max:"NestedEnumFileStatusNullableFilter"
	},
	MembershipOrderByWithAggregationInput:{
		id:"SortOrder",
		createdAt:"SortOrder",
		userId:"SortOrder",
		workspaceId:"SortOrder",
		roleId:"SortOrder",
		_count:"MembershipCountOrderByAggregateInput",
		_max:"MembershipMaxOrderByAggregateInput",
		_min:"MembershipMinOrderByAggregateInput"
	},
	MembershipCountOrderByAggregateInput:{
		id:"SortOrder",
		createdAt:"SortOrder",
		userId:"SortOrder",
		workspaceId:"SortOrder",
		roleId:"SortOrder"
	},
	MembershipMaxOrderByAggregateInput:{
		id:"SortOrder",
		createdAt:"SortOrder",
		userId:"SortOrder",
		workspaceId:"SortOrder",
		roleId:"SortOrder"
	},
	MembershipMinOrderByAggregateInput:{
		id:"SortOrder",
		createdAt:"SortOrder",
		userId:"SortOrder",
		workspaceId:"SortOrder",
		roleId:"SortOrder"
	},
	MembershipScalarWhereWithAggregatesInput:{
		AND:"MembershipScalarWhereWithAggregatesInput",
		OR:"MembershipScalarWhereWithAggregatesInput",
		NOT:"MembershipScalarWhereWithAggregatesInput",
		id:"StringWithAggregatesFilter",
		createdAt:"DateTimeWithAggregatesFilter",
		userId:"StringWithAggregatesFilter",
		workspaceId:"StringWithAggregatesFilter",
		roleId:"StringWithAggregatesFilter"
	},
	NotificationOrderByWithAggregationInput:{
		id:"SortOrder",
		type:"SortOrder",
		title:"SortOrder",
		content:"SortOrder",
		metadata:"SortOrder",
		createdAt:"SortOrder",
		readAt:"SortOrder",
		recipientId:"SortOrder",
		channels:"SortOrder",
		_count:"NotificationCountOrderByAggregateInput",
		_max:"NotificationMaxOrderByAggregateInput",
		_min:"NotificationMinOrderByAggregateInput"
	},
	NotificationCountOrderByAggregateInput:{
		id:"SortOrder",
		type:"SortOrder",
		title:"SortOrder",
		content:"SortOrder",
		metadata:"SortOrder",
		createdAt:"SortOrder",
		readAt:"SortOrder",
		recipientId:"SortOrder",
		channels:"SortOrder"
	},
	NotificationMaxOrderByAggregateInput:{
		id:"SortOrder",
		type:"SortOrder",
		title:"SortOrder",
		content:"SortOrder",
		createdAt:"SortOrder",
		readAt:"SortOrder",
		recipientId:"SortOrder"
	},
	NotificationMinOrderByAggregateInput:{
		id:"SortOrder",
		type:"SortOrder",
		title:"SortOrder",
		content:"SortOrder",
		createdAt:"SortOrder",
		readAt:"SortOrder",
		recipientId:"SortOrder"
	},
	NotificationScalarWhereWithAggregatesInput:{
		AND:"NotificationScalarWhereWithAggregatesInput",
		OR:"NotificationScalarWhereWithAggregatesInput",
		NOT:"NotificationScalarWhereWithAggregatesInput",
		id:"StringWithAggregatesFilter",
		type:"EnumNotificationTypeWithAggregatesFilter",
		title:"StringWithAggregatesFilter",
		content:"StringWithAggregatesFilter",
		metadata:"JsonNullableWithAggregatesFilter",
		createdAt:"DateTimeWithAggregatesFilter",
		readAt:"DateTimeNullableWithAggregatesFilter",
		recipientId:"StringWithAggregatesFilter",
		channels:"EnumNotificationChannelNullableListFilter"
	},
	EnumNotificationTypeWithAggregatesFilter:{
		equals:"NotificationType",
		in:"NotificationType",
		notIn:"NotificationType",
		not:"NestedEnumNotificationTypeWithAggregatesFilter",
		_count:"NestedIntFilter",
		_min:"NestedEnumNotificationTypeFilter",
		_max:"NestedEnumNotificationTypeFilter"
	},
	NestedEnumNotificationTypeWithAggregatesFilter:{
		equals:"NotificationType",
		in:"NotificationType",
		notIn:"NotificationType",
		not:"NestedEnumNotificationTypeWithAggregatesFilter",
		_count:"NestedIntFilter",
		_min:"NestedEnumNotificationTypeFilter",
		_max:"NestedEnumNotificationTypeFilter"
	},
	OrderOrderByWithAggregationInput:{
		id:"SortOrder",
		adBoardId:"SortOrder",
		createdDate:"SortOrder",
		createdByUserId:"SortOrder",
		origin:"SortOrder",
		paymentId:"SortOrder",
		_count:"OrderCountOrderByAggregateInput",
		_max:"OrderMaxOrderByAggregateInput",
		_min:"OrderMinOrderByAggregateInput"
	},
	OrderCountOrderByAggregateInput:{
		id:"SortOrder",
		adBoardId:"SortOrder",
		createdDate:"SortOrder",
		createdByUserId:"SortOrder",
		origin:"SortOrder",
		paymentId:"SortOrder"
	},
	OrderMaxOrderByAggregateInput:{
		id:"SortOrder",
		adBoardId:"SortOrder",
		createdDate:"SortOrder",
		createdByUserId:"SortOrder",
		origin:"SortOrder",
		paymentId:"SortOrder"
	},
	OrderMinOrderByAggregateInput:{
		id:"SortOrder",
		adBoardId:"SortOrder",
		createdDate:"SortOrder",
		createdByUserId:"SortOrder",
		origin:"SortOrder",
		paymentId:"SortOrder"
	},
	OrderScalarWhereWithAggregatesInput:{
		AND:"OrderScalarWhereWithAggregatesInput",
		OR:"OrderScalarWhereWithAggregatesInput",
		NOT:"OrderScalarWhereWithAggregatesInput",
		id:"StringWithAggregatesFilter",
		adBoardId:"StringWithAggregatesFilter",
		createdDate:"DateTimeWithAggregatesFilter",
		createdByUserId:"StringNullableWithAggregatesFilter",
		origin:"StringWithAggregatesFilter",
		paymentId:"StringNullableWithAggregatesFilter"
	},
	PlayerOrderByWithAggregationInput:{
		id:"SortOrder",
		name:"SortOrder",
		configLocation:"SortOrder",
		pisignagePlayerId:"SortOrder",
		pisignageGroupId:"SortOrder",
		cpuSerialNumber:"SortOrder",
		displayName:"SortOrder",
		adBoardGroupId:"SortOrder",
		workpaceId:"SortOrder",
		aspectRatioType:"SortOrder",
		customAspectRatioWidth:"SortOrder",
		customAspectRatioHeight:"SortOrder",
		isOnline:"SortOrder",
		adBoardIds:"SortOrder",
		_count:"PlayerCountOrderByAggregateInput",
		_avg:"PlayerAvgOrderByAggregateInput",
		_max:"PlayerMaxOrderByAggregateInput",
		_min:"PlayerMinOrderByAggregateInput",
		_sum:"PlayerSumOrderByAggregateInput"
	},
	PlayerCountOrderByAggregateInput:{
		id:"SortOrder",
		name:"SortOrder",
		configLocation:"SortOrder",
		pisignagePlayerId:"SortOrder",
		pisignageGroupId:"SortOrder",
		cpuSerialNumber:"SortOrder",
		displayName:"SortOrder",
		adBoardGroupId:"SortOrder",
		workpaceId:"SortOrder",
		aspectRatioType:"SortOrder",
		customAspectRatioWidth:"SortOrder",
		customAspectRatioHeight:"SortOrder",
		isOnline:"SortOrder",
		adBoardIds:"SortOrder"
	},
	PlayerAvgOrderByAggregateInput:{
		customAspectRatioWidth:"SortOrder",
		customAspectRatioHeight:"SortOrder"
	},
	PlayerMaxOrderByAggregateInput:{
		id:"SortOrder",
		name:"SortOrder",
		configLocation:"SortOrder",
		pisignagePlayerId:"SortOrder",
		pisignageGroupId:"SortOrder",
		cpuSerialNumber:"SortOrder",
		displayName:"SortOrder",
		adBoardGroupId:"SortOrder",
		workpaceId:"SortOrder",
		aspectRatioType:"SortOrder",
		customAspectRatioWidth:"SortOrder",
		customAspectRatioHeight:"SortOrder",
		isOnline:"SortOrder"
	},
	PlayerMinOrderByAggregateInput:{
		id:"SortOrder",
		name:"SortOrder",
		configLocation:"SortOrder",
		pisignagePlayerId:"SortOrder",
		pisignageGroupId:"SortOrder",
		cpuSerialNumber:"SortOrder",
		displayName:"SortOrder",
		adBoardGroupId:"SortOrder",
		workpaceId:"SortOrder",
		aspectRatioType:"SortOrder",
		customAspectRatioWidth:"SortOrder",
		customAspectRatioHeight:"SortOrder",
		isOnline:"SortOrder"
	},
	PlayerSumOrderByAggregateInput:{
		customAspectRatioWidth:"SortOrder",
		customAspectRatioHeight:"SortOrder"
	},
	PlayerScalarWhereWithAggregatesInput:{
		AND:"PlayerScalarWhereWithAggregatesInput",
		OR:"PlayerScalarWhereWithAggregatesInput",
		NOT:"PlayerScalarWhereWithAggregatesInput",
		id:"StringWithAggregatesFilter",
		name:"StringWithAggregatesFilter",
		configLocation:"StringNullableWithAggregatesFilter",
		pisignagePlayerId:"StringNullableWithAggregatesFilter",
		pisignageGroupId:"StringNullableWithAggregatesFilter",
		cpuSerialNumber:"StringNullableWithAggregatesFilter",
		displayName:"StringNullableWithAggregatesFilter",
		adBoardGroupId:"StringNullableWithAggregatesFilter",
		workpaceId:"StringNullableWithAggregatesFilter",
		aspectRatioType:"EnumAspectRatioTypeWithAggregatesFilter",
		customAspectRatioWidth:"IntNullableWithAggregatesFilter",
		customAspectRatioHeight:"IntNullableWithAggregatesFilter",
		isOnline:"BoolWithAggregatesFilter",
		adBoardIds:"StringNullableListFilter"
	},
	EnumAspectRatioTypeWithAggregatesFilter:{
		equals:"AspectRatioType",
		in:"AspectRatioType",
		notIn:"AspectRatioType",
		not:"NestedEnumAspectRatioTypeWithAggregatesFilter",
		_count:"NestedIntFilter",
		_min:"NestedEnumAspectRatioTypeFilter",
		_max:"NestedEnumAspectRatioTypeFilter"
	},
	NestedEnumAspectRatioTypeWithAggregatesFilter:{
		equals:"AspectRatioType",
		in:"AspectRatioType",
		notIn:"AspectRatioType",
		not:"NestedEnumAspectRatioTypeWithAggregatesFilter",
		_count:"NestedIntFilter",
		_min:"NestedEnumAspectRatioTypeFilter",
		_max:"NestedEnumAspectRatioTypeFilter"
	},
	PlaylistOrderByWithAggregationInput:{
		id:"SortOrder",
		name:"SortOrder",
		displayName:"SortOrder",
		isAdsPlaylist:"SortOrder",
		footerPlaylistId:"SortOrder",
		sidebarPlaylistId:"SortOrder",
		layout:"SortOrder",
		isDeployed:"SortOrder",
		adBoardGroupIds:"SortOrder",
		workspaceId:"SortOrder",
		_count:"PlaylistCountOrderByAggregateInput",
		_max:"PlaylistMaxOrderByAggregateInput",
		_min:"PlaylistMinOrderByAggregateInput"
	},
	PlaylistCountOrderByAggregateInput:{
		id:"SortOrder",
		name:"SortOrder",
		displayName:"SortOrder",
		isAdsPlaylist:"SortOrder",
		footerPlaylistId:"SortOrder",
		sidebarPlaylistId:"SortOrder",
		layout:"SortOrder",
		isDeployed:"SortOrder",
		adBoardGroupIds:"SortOrder",
		workspaceId:"SortOrder"
	},
	PlaylistMaxOrderByAggregateInput:{
		id:"SortOrder",
		name:"SortOrder",
		displayName:"SortOrder",
		isAdsPlaylist:"SortOrder",
		footerPlaylistId:"SortOrder",
		sidebarPlaylistId:"SortOrder",
		layout:"SortOrder",
		isDeployed:"SortOrder",
		workspaceId:"SortOrder"
	},
	PlaylistMinOrderByAggregateInput:{
		id:"SortOrder",
		name:"SortOrder",
		displayName:"SortOrder",
		isAdsPlaylist:"SortOrder",
		footerPlaylistId:"SortOrder",
		sidebarPlaylistId:"SortOrder",
		layout:"SortOrder",
		isDeployed:"SortOrder",
		workspaceId:"SortOrder"
	},
	PlaylistScalarWhereWithAggregatesInput:{
		AND:"PlaylistScalarWhereWithAggregatesInput",
		OR:"PlaylistScalarWhereWithAggregatesInput",
		NOT:"PlaylistScalarWhereWithAggregatesInput",
		id:"StringWithAggregatesFilter",
		name:"StringWithAggregatesFilter",
		displayName:"StringNullableWithAggregatesFilter",
		isAdsPlaylist:"BoolNullableWithAggregatesFilter",
		footerPlaylistId:"StringNullableWithAggregatesFilter",
		sidebarPlaylistId:"StringNullableWithAggregatesFilter",
		layout:"EnumScreenLayoutWithAggregatesFilter",
		isDeployed:"BoolNullableWithAggregatesFilter",
		adBoardGroupIds:"StringNullableListFilter",
		workspaceId:"StringWithAggregatesFilter"
	},
	EnumScreenLayoutWithAggregatesFilter:{
		equals:"ScreenLayout",
		in:"ScreenLayout",
		notIn:"ScreenLayout",
		not:"NestedEnumScreenLayoutWithAggregatesFilter",
		_count:"NestedIntFilter",
		_min:"NestedEnumScreenLayoutFilter",
		_max:"NestedEnumScreenLayoutFilter"
	},
	NestedEnumScreenLayoutWithAggregatesFilter:{
		equals:"ScreenLayout",
		in:"ScreenLayout",
		notIn:"ScreenLayout",
		not:"NestedEnumScreenLayoutWithAggregatesFilter",
		_count:"NestedIntFilter",
		_min:"NestedEnumScreenLayoutFilter",
		_max:"NestedEnumScreenLayoutFilter"
	},
	SlotConfigurationOrderByWithAggregationInput:{
		id:"SortOrder",
		adBoardId:"SortOrder",
		date:"SortOrder",
		availableSlots:"SortOrder",
		_count:"SlotConfigurationCountOrderByAggregateInput",
		_avg:"SlotConfigurationAvgOrderByAggregateInput",
		_max:"SlotConfigurationMaxOrderByAggregateInput",
		_min:"SlotConfigurationMinOrderByAggregateInput",
		_sum:"SlotConfigurationSumOrderByAggregateInput"
	},
	SlotConfigurationCountOrderByAggregateInput:{
		id:"SortOrder",
		adBoardId:"SortOrder",
		date:"SortOrder",
		availableSlots:"SortOrder"
	},
	SlotConfigurationAvgOrderByAggregateInput:{
		availableSlots:"SortOrder"
	},
	SlotConfigurationMaxOrderByAggregateInput:{
		id:"SortOrder",
		adBoardId:"SortOrder",
		date:"SortOrder",
		availableSlots:"SortOrder"
	},
	SlotConfigurationMinOrderByAggregateInput:{
		id:"SortOrder",
		adBoardId:"SortOrder",
		date:"SortOrder",
		availableSlots:"SortOrder"
	},
	SlotConfigurationSumOrderByAggregateInput:{
		availableSlots:"SortOrder"
	},
	SlotConfigurationScalarWhereWithAggregatesInput:{
		AND:"SlotConfigurationScalarWhereWithAggregatesInput",
		OR:"SlotConfigurationScalarWhereWithAggregatesInput",
		NOT:"SlotConfigurationScalarWhereWithAggregatesInput",
		id:"StringWithAggregatesFilter",
		adBoardId:"StringWithAggregatesFilter",
		date:"DateTimeWithAggregatesFilter",
		availableSlots:"IntWithAggregatesFilter"
	},
	SubscriptionWhereUniqueInput:{
		AND:"SubscriptionWhereInput",
		OR:"SubscriptionWhereInput",
		NOT:"SubscriptionWhereInput",
		packageId:"StringNullableFilter",
		packageName:"StringNullableFilter",
		priceLookupKey:"StringNullableFilter",
		startDate:"DateTimeNullableFilter",
		endDate:"DateTimeNullableFilter",
		status:"StringFilter",
		stripePaymentIntentId:"StringNullableFilter",
		cancellationDetails:"CancellationDetailsNullableCompositeFilter",
		activeWorkspace:"WorkspaceRelationFilter"
	},
	SubscriptionScalarFieldEnum: "enum" as const,
	SubscriptionOrderByWithAggregationInput:{
		id:"SortOrder",
		packageId:"SortOrder",
		packageName:"SortOrder",
		priceLookupKey:"SortOrder",
		startDate:"SortOrder",
		endDate:"SortOrder",
		status:"SortOrder",
		stripePaymentIntentId:"SortOrder",
		stripeSubscriptionId:"SortOrder",
		stripeCheckoutSessionId:"SortOrder",
		activeWorkspaceId:"SortOrder",
		_count:"SubscriptionCountOrderByAggregateInput",
		_max:"SubscriptionMaxOrderByAggregateInput",
		_min:"SubscriptionMinOrderByAggregateInput"
	},
	SubscriptionCountOrderByAggregateInput:{
		id:"SortOrder",
		packageId:"SortOrder",
		packageName:"SortOrder",
		priceLookupKey:"SortOrder",
		startDate:"SortOrder",
		endDate:"SortOrder",
		status:"SortOrder",
		stripePaymentIntentId:"SortOrder",
		stripeSubscriptionId:"SortOrder",
		stripeCheckoutSessionId:"SortOrder",
		activeWorkspaceId:"SortOrder"
	},
	SubscriptionMaxOrderByAggregateInput:{
		id:"SortOrder",
		packageId:"SortOrder",
		packageName:"SortOrder",
		priceLookupKey:"SortOrder",
		startDate:"SortOrder",
		endDate:"SortOrder",
		status:"SortOrder",
		stripePaymentIntentId:"SortOrder",
		stripeSubscriptionId:"SortOrder",
		stripeCheckoutSessionId:"SortOrder",
		activeWorkspaceId:"SortOrder"
	},
	SubscriptionMinOrderByAggregateInput:{
		id:"SortOrder",
		packageId:"SortOrder",
		packageName:"SortOrder",
		priceLookupKey:"SortOrder",
		startDate:"SortOrder",
		endDate:"SortOrder",
		status:"SortOrder",
		stripePaymentIntentId:"SortOrder",
		stripeSubscriptionId:"SortOrder",
		stripeCheckoutSessionId:"SortOrder",
		activeWorkspaceId:"SortOrder"
	},
	SubscriptionScalarWhereWithAggregatesInput:{
		AND:"SubscriptionScalarWhereWithAggregatesInput",
		OR:"SubscriptionScalarWhereWithAggregatesInput",
		NOT:"SubscriptionScalarWhereWithAggregatesInput",
		id:"StringWithAggregatesFilter",
		packageId:"StringNullableWithAggregatesFilter",
		packageName:"StringNullableWithAggregatesFilter",
		priceLookupKey:"StringNullableWithAggregatesFilter",
		startDate:"DateTimeNullableWithAggregatesFilter",
		endDate:"DateTimeNullableWithAggregatesFilter",
		status:"StringWithAggregatesFilter",
		stripePaymentIntentId:"StringNullableWithAggregatesFilter",
		stripeSubscriptionId:"StringWithAggregatesFilter",
		stripeCheckoutSessionId:"StringWithAggregatesFilter",
		activeWorkspaceId:"StringWithAggregatesFilter"
	},
	UserOrderByWithAggregationInput:{
		id:"SortOrder",
		uid:"SortOrder",
		email:"SortOrder",
		image:"SortOrder",
		phoneNumber:"SortOrder",
		displayName:"SortOrder",
		otherDetails:"SortOrder",
		roles:"SortOrder",
		sharedBookingIds:"SortOrder",
		disclaimerAccepted:"SortOrder",
		leadWorkspaceIds:"SortOrder",
		_count:"UserCountOrderByAggregateInput",
		_max:"UserMaxOrderByAggregateInput",
		_min:"UserMinOrderByAggregateInput"
	},
	UserCountOrderByAggregateInput:{
		id:"SortOrder",
		uid:"SortOrder",
		email:"SortOrder",
		image:"SortOrder",
		phoneNumber:"SortOrder",
		displayName:"SortOrder",
		otherDetails:"SortOrder",
		roles:"SortOrder",
		sharedBookingIds:"SortOrder",
		disclaimerAccepted:"SortOrder",
		leadWorkspaceIds:"SortOrder"
	},
	UserMaxOrderByAggregateInput:{
		id:"SortOrder",
		uid:"SortOrder",
		email:"SortOrder",
		image:"SortOrder",
		phoneNumber:"SortOrder",
		displayName:"SortOrder",
		disclaimerAccepted:"SortOrder"
	},
	UserMinOrderByAggregateInput:{
		id:"SortOrder",
		uid:"SortOrder",
		email:"SortOrder",
		image:"SortOrder",
		phoneNumber:"SortOrder",
		displayName:"SortOrder",
		disclaimerAccepted:"SortOrder"
	},
	UserScalarWhereWithAggregatesInput:{
		AND:"UserScalarWhereWithAggregatesInput",
		OR:"UserScalarWhereWithAggregatesInput",
		NOT:"UserScalarWhereWithAggregatesInput",
		id:"StringWithAggregatesFilter",
		uid:"StringNullableWithAggregatesFilter",
		email:"StringWithAggregatesFilter",
		image:"StringNullableWithAggregatesFilter",
		phoneNumber:"StringNullableWithAggregatesFilter",
		displayName:"StringNullableWithAggregatesFilter",
		otherDetails:"JsonNullableWithAggregatesFilter",
		roles:"EnumRoleNullableListFilter",
		sharedBookingIds:"StringNullableListFilter",
		disclaimerAccepted:"BoolWithAggregatesFilter",
		leadWorkspaceIds:"StringNullableListFilter"
	},
	UserPositionOrderByWithAggregationInput:{
		id:"SortOrder",
		timestamp:"SortOrder",
		createdAt:"SortOrder",
		userId:"SortOrder",
		_count:"UserPositionCountOrderByAggregateInput",
		_max:"UserPositionMaxOrderByAggregateInput",
		_min:"UserPositionMinOrderByAggregateInput"
	},
	UserPositionCountOrderByAggregateInput:{
		id:"SortOrder",
		timestamp:"SortOrder",
		createdAt:"SortOrder",
		userId:"SortOrder"
	},
	UserPositionMaxOrderByAggregateInput:{
		id:"SortOrder",
		timestamp:"SortOrder",
		createdAt:"SortOrder",
		userId:"SortOrder"
	},
	UserPositionMinOrderByAggregateInput:{
		id:"SortOrder",
		timestamp:"SortOrder",
		createdAt:"SortOrder",
		userId:"SortOrder"
	},
	UserPositionScalarWhereWithAggregatesInput:{
		AND:"UserPositionScalarWhereWithAggregatesInput",
		OR:"UserPositionScalarWhereWithAggregatesInput",
		NOT:"UserPositionScalarWhereWithAggregatesInput",
		id:"StringWithAggregatesFilter",
		timestamp:"DateTimeWithAggregatesFilter",
		createdAt:"DateTimeWithAggregatesFilter",
		userId:"StringWithAggregatesFilter"
	},
	WorkspaceOrderByWithAggregationInput:{
		id:"SortOrder",
		type:"SortOrder",
		name:"SortOrder",
		key:"SortOrder",
		userIds:"SortOrder",
		ownerId:"SortOrder",
		avatar:"SortOrder",
		createdAt:"SortOrder",
		updatedAt:"SortOrder",
		stripeSubscriptionId:"SortOrder",
		leadIds:"SortOrder",
		_count:"WorkspaceCountOrderByAggregateInput",
		_max:"WorkspaceMaxOrderByAggregateInput",
		_min:"WorkspaceMinOrderByAggregateInput"
	},
	WorkspaceCountOrderByAggregateInput:{
		id:"SortOrder",
		type:"SortOrder",
		name:"SortOrder",
		key:"SortOrder",
		userIds:"SortOrder",
		ownerId:"SortOrder",
		avatar:"SortOrder",
		createdAt:"SortOrder",
		updatedAt:"SortOrder",
		stripeSubscriptionId:"SortOrder",
		leadIds:"SortOrder"
	},
	WorkspaceMaxOrderByAggregateInput:{
		id:"SortOrder",
		type:"SortOrder",
		name:"SortOrder",
		key:"SortOrder",
		ownerId:"SortOrder",
		avatar:"SortOrder",
		createdAt:"SortOrder",
		updatedAt:"SortOrder",
		stripeSubscriptionId:"SortOrder"
	},
	WorkspaceMinOrderByAggregateInput:{
		id:"SortOrder",
		type:"SortOrder",
		name:"SortOrder",
		key:"SortOrder",
		ownerId:"SortOrder",
		avatar:"SortOrder",
		createdAt:"SortOrder",
		updatedAt:"SortOrder",
		stripeSubscriptionId:"SortOrder"
	},
	WorkspaceScalarWhereWithAggregatesInput:{
		AND:"WorkspaceScalarWhereWithAggregatesInput",
		OR:"WorkspaceScalarWhereWithAggregatesInput",
		NOT:"WorkspaceScalarWhereWithAggregatesInput",
		id:"StringWithAggregatesFilter",
		type:"EnumWorkspaceTypeWithAggregatesFilter",
		name:"StringWithAggregatesFilter",
		key:"StringWithAggregatesFilter",
		userIds:"StringNullableListFilter",
		ownerId:"StringNullableWithAggregatesFilter",
		avatar:"StringNullableWithAggregatesFilter",
		createdAt:"DateTimeWithAggregatesFilter",
		updatedAt:"DateTimeWithAggregatesFilter",
		stripeSubscriptionId:"StringNullableWithAggregatesFilter",
		leadIds:"StringNullableListFilter"
	},
	EnumWorkspaceTypeWithAggregatesFilter:{
		equals:"WorkspaceType",
		in:"WorkspaceType",
		notIn:"WorkspaceType",
		not:"NestedEnumWorkspaceTypeWithAggregatesFilter",
		_count:"NestedIntFilter",
		_min:"NestedEnumWorkspaceTypeFilter",
		_max:"NestedEnumWorkspaceTypeFilter"
	},
	NestedEnumWorkspaceTypeWithAggregatesFilter:{
		equals:"WorkspaceType",
		in:"WorkspaceType",
		notIn:"WorkspaceType",
		not:"NestedEnumWorkspaceTypeWithAggregatesFilter",
		_count:"NestedIntFilter",
		_min:"NestedEnumWorkspaceTypeFilter",
		_max:"NestedEnumWorkspaceTypeFilter"
	},
	WorkspaceRoleOrderByWithAggregationInput:{
		id:"SortOrder",
		name:"SortOrder",
		permissions:"SortOrder",
		workspaceId:"SortOrder",
		createdAt:"SortOrder",
		updatedAt:"SortOrder",
		_count:"WorkspaceRoleCountOrderByAggregateInput",
		_max:"WorkspaceRoleMaxOrderByAggregateInput",
		_min:"WorkspaceRoleMinOrderByAggregateInput"
	},
	WorkspaceRoleCountOrderByAggregateInput:{
		id:"SortOrder",
		name:"SortOrder",
		permissions:"SortOrder",
		workspaceId:"SortOrder",
		createdAt:"SortOrder",
		updatedAt:"SortOrder"
	},
	WorkspaceRoleMaxOrderByAggregateInput:{
		id:"SortOrder",
		name:"SortOrder",
		workspaceId:"SortOrder",
		createdAt:"SortOrder",
		updatedAt:"SortOrder"
	},
	WorkspaceRoleMinOrderByAggregateInput:{
		id:"SortOrder",
		name:"SortOrder",
		workspaceId:"SortOrder",
		createdAt:"SortOrder",
		updatedAt:"SortOrder"
	},
	WorkspaceRoleScalarWhereWithAggregatesInput:{
		AND:"WorkspaceRoleScalarWhereWithAggregatesInput",
		OR:"WorkspaceRoleScalarWhereWithAggregatesInput",
		NOT:"WorkspaceRoleScalarWhereWithAggregatesInput",
		id:"StringWithAggregatesFilter",
		name:"StringWithAggregatesFilter",
		permissions:"EnumPermissionNullableListFilter",
		workspaceId:"StringNullableWithAggregatesFilter",
		createdAt:"DateTimeWithAggregatesFilter",
		updatedAt:"DateTimeWithAggregatesFilter"
	},
	Mutation:{
		createManyAdBoard:{
			data:"AdBoardCreateManyInput"
		},
		createOneAdBoard:{
			data:"AdBoardCreateInput"
		},
		deleteManyAdBoard:{
			where:"AdBoardWhereInput"
		},
		deleteOneAdBoard:{
			where:"AdBoardWhereUniqueInput"
		},
		updateManyAdBoard:{
			data:"AdBoardUpdateManyMutationInput",
			where:"AdBoardWhereInput"
		},
		updateOneAdBoard:{
			data:"AdBoardUpdateInput",
			where:"AdBoardWhereUniqueInput"
		},
		upsertOneAdBoard:{
			where:"AdBoardWhereUniqueInput",
			create:"AdBoardCreateInput",
			update:"AdBoardUpdateInput"
		},
		createManyAdBoardGroup:{
			data:"AdBoardGroupCreateManyInput"
		},
		createOneAdBoardGroup:{
			data:"AdBoardGroupCreateInput"
		},
		deleteManyAdBoardGroup:{
			where:"AdBoardGroupWhereInput"
		},
		deleteOneAdBoardGroup:{
			where:"AdBoardGroupWhereUniqueInput"
		},
		updateManyAdBoardGroup:{
			data:"AdBoardGroupUpdateManyMutationInput",
			where:"AdBoardGroupWhereInput"
		},
		updateOneAdBoardGroup:{
			data:"AdBoardGroupUpdateInput",
			where:"AdBoardGroupWhereUniqueInput"
		},
		upsertOneAdBoardGroup:{
			where:"AdBoardGroupWhereUniqueInput",
			create:"AdBoardGroupCreateInput",
			update:"AdBoardGroupUpdateInput"
		},
		createManyAdBoardType:{
			data:"AdBoardTypeCreateManyInput"
		},
		createOneAdBoardType:{
			data:"AdBoardTypeCreateInput"
		},
		deleteManyAdBoardType:{
			where:"AdBoardTypeWhereInput"
		},
		deleteOneAdBoardType:{
			where:"AdBoardTypeWhereUniqueInput"
		},
		updateManyAdBoardType:{
			data:"AdBoardTypeUpdateManyMutationInput",
			where:"AdBoardTypeWhereInput"
		},
		updateOneAdBoardType:{
			data:"AdBoardTypeUpdateInput",
			where:"AdBoardTypeWhereUniqueInput"
		},
		upsertOneAdBoardType:{
			where:"AdBoardTypeWhereUniqueInput",
			create:"AdBoardTypeCreateInput",
			update:"AdBoardTypeUpdateInput"
		},
		createManyAudit:{
			data:"AuditCreateManyInput"
		},
		createOneAudit:{
			data:"AuditCreateInput"
		},
		deleteManyAudit:{
			where:"AuditWhereInput"
		},
		deleteOneAudit:{
			where:"AuditWhereUniqueInput"
		},
		updateManyAudit:{
			data:"AuditUpdateManyMutationInput",
			where:"AuditWhereInput"
		},
		updateOneAudit:{
			data:"AuditUpdateInput",
			where:"AuditWhereUniqueInput"
		},
		upsertOneAudit:{
			where:"AuditWhereUniqueInput",
			create:"AuditCreateInput",
			update:"AuditUpdateInput"
		},
		createManyBooking:{
			data:"BookingCreateManyInput"
		},
		createOneBooking:{
			data:"BookingCreateInput"
		},
		deleteManyBooking:{
			where:"BookingWhereInput"
		},
		deleteOneBooking:{
			where:"BookingWhereUniqueInput"
		},
		updateManyBooking:{
			data:"BookingUpdateManyMutationInput",
			where:"BookingWhereInput"
		},
		updateOneBooking:{
			data:"BookingUpdateInput",
			where:"BookingWhereUniqueInput"
		},
		upsertOneBooking:{
			where:"BookingWhereUniqueInput",
			create:"BookingCreateInput",
			update:"BookingUpdateInput"
		},
		createManyBusinessCategory:{
			data:"BusinessCategoryCreateManyInput"
		},
		createOneBusinessCategory:{
			data:"BusinessCategoryCreateInput"
		},
		deleteManyBusinessCategory:{
			where:"BusinessCategoryWhereInput"
		},
		deleteOneBusinessCategory:{
			where:"BusinessCategoryWhereUniqueInput"
		},
		updateManyBusinessCategory:{
			data:"BusinessCategoryUpdateManyMutationInput",
			where:"BusinessCategoryWhereInput"
		},
		updateOneBusinessCategory:{
			data:"BusinessCategoryUpdateInput",
			where:"BusinessCategoryWhereUniqueInput"
		},
		upsertOneBusinessCategory:{
			where:"BusinessCategoryWhereUniqueInput",
			create:"BusinessCategoryCreateInput",
			update:"BusinessCategoryUpdateInput"
		},
		createManyCamera:{
			data:"CameraCreateManyInput"
		},
		createOneCamera:{
			data:"CameraCreateInput"
		},
		deleteManyCamera:{
			where:"CameraWhereInput"
		},
		deleteOneCamera:{
			where:"CameraWhereUniqueInput"
		},
		updateManyCamera:{
			data:"CameraUpdateManyMutationInput",
			where:"CameraWhereInput"
		},
		updateOneCamera:{
			data:"CameraUpdateInput",
			where:"CameraWhereUniqueInput"
		},
		upsertOneCamera:{
			where:"CameraWhereUniqueInput",
			create:"CameraCreateInput",
			update:"CameraUpdateInput"
		},
		createManyCameraFeed:{
			data:"CameraFeedCreateManyInput"
		},
		createOneCameraFeed:{
			data:"CameraFeedCreateInput"
		},
		deleteManyCameraFeed:{
			where:"CameraFeedWhereInput"
		},
		deleteOneCameraFeed:{
			where:"CameraFeedWhereUniqueInput"
		},
		updateManyCameraFeed:{
			data:"CameraFeedUpdateManyMutationInput",
			where:"CameraFeedWhereInput"
		},
		updateOneCameraFeed:{
			data:"CameraFeedUpdateInput",
			where:"CameraFeedWhereUniqueInput"
		},
		upsertOneCameraFeed:{
			where:"CameraFeedWhereUniqueInput",
			create:"CameraFeedCreateInput",
			update:"CameraFeedUpdateInput"
		},
		createManyCameraServer:{
			data:"CameraServerCreateManyInput"
		},
		createOneCameraServer:{
			data:"CameraServerCreateInput"
		},
		deleteManyCameraServer:{
			where:"CameraServerWhereInput"
		},
		deleteOneCameraServer:{
			where:"CameraServerWhereUniqueInput"
		},
		updateManyCameraServer:{
			data:"CameraServerUpdateManyMutationInput",
			where:"CameraServerWhereInput"
		},
		updateOneCameraServer:{
			data:"CameraServerUpdateInput",
			where:"CameraServerWhereUniqueInput"
		},
		upsertOneCameraServer:{
			where:"CameraServerWhereUniqueInput",
			create:"CameraServerCreateInput",
			update:"CameraServerUpdateInput"
		},
		createManyCampaign:{
			data:"CampaignCreateManyInput"
		},
		createOneCampaign:{
			data:"CampaignCreateInput"
		},
		deleteManyCampaign:{
			where:"CampaignWhereInput"
		},
		deleteOneCampaign:{
			where:"CampaignWhereUniqueInput"
		},
		updateManyCampaign:{
			data:"CampaignUpdateManyMutationInput",
			where:"CampaignWhereInput"
		},
		updateOneCampaign:{
			data:"CampaignUpdateInput",
			where:"CampaignWhereUniqueInput"
		},
		upsertOneCampaign:{
			where:"CampaignWhereUniqueInput",
			create:"CampaignCreateInput",
			update:"CampaignUpdateInput"
		},
		createManyFile:{
			data:"FileCreateManyInput"
		},
		createOneFile:{
			data:"FileCreateInput"
		},
		deleteManyFile:{
			where:"FileWhereInput"
		},
		deleteOneFile:{
			where:"FileWhereUniqueInput"
		},
		updateManyFile:{
			data:"FileUpdateManyMutationInput",
			where:"FileWhereInput"
		},
		updateOneFile:{
			data:"FileUpdateInput",
			where:"FileWhereUniqueInput"
		},
		upsertOneFile:{
			where:"FileWhereUniqueInput",
			create:"FileCreateInput",
			update:"FileUpdateInput"
		},
		createManyMembership:{
			data:"MembershipCreateManyInput"
		},
		createOneMembership:{
			data:"MembershipCreateInput"
		},
		deleteManyMembership:{
			where:"MembershipWhereInput"
		},
		deleteOneMembership:{
			where:"MembershipWhereUniqueInput"
		},
		updateManyMembership:{
			data:"MembershipUpdateManyMutationInput",
			where:"MembershipWhereInput"
		},
		updateOneMembership:{
			data:"MembershipUpdateInput",
			where:"MembershipWhereUniqueInput"
		},
		upsertOneMembership:{
			where:"MembershipWhereUniqueInput",
			create:"MembershipCreateInput",
			update:"MembershipUpdateInput"
		},
		createManyNotification:{
			data:"NotificationCreateManyInput"
		},
		createOneNotification:{
			data:"NotificationCreateInput"
		},
		deleteManyNotification:{
			where:"NotificationWhereInput"
		},
		deleteOneNotification:{
			where:"NotificationWhereUniqueInput"
		},
		updateManyNotification:{
			data:"NotificationUpdateManyMutationInput",
			where:"NotificationWhereInput"
		},
		updateOneNotification:{
			data:"NotificationUpdateInput",
			where:"NotificationWhereUniqueInput"
		},
		upsertOneNotification:{
			where:"NotificationWhereUniqueInput",
			create:"NotificationCreateInput",
			update:"NotificationUpdateInput"
		},
		createManyOrder:{
			data:"OrderCreateManyInput"
		},
		createOneOrder:{
			data:"OrderCreateInput"
		},
		deleteManyOrder:{
			where:"OrderWhereInput"
		},
		deleteOneOrder:{
			where:"OrderWhereUniqueInput"
		},
		updateManyOrder:{
			data:"OrderUpdateManyMutationInput",
			where:"OrderWhereInput"
		},
		updateOneOrder:{
			data:"OrderUpdateInput",
			where:"OrderWhereUniqueInput"
		},
		upsertOneOrder:{
			where:"OrderWhereUniqueInput",
			create:"OrderCreateInput",
			update:"OrderUpdateInput"
		},
		createManyPlayer:{
			data:"PlayerCreateManyInput"
		},
		createOnePlayer:{
			data:"PlayerCreateInput"
		},
		deleteManyPlayer:{
			where:"PlayerWhereInput"
		},
		deleteOnePlayer:{
			where:"PlayerWhereUniqueInput"
		},
		updateManyPlayer:{
			data:"PlayerUpdateManyMutationInput",
			where:"PlayerWhereInput"
		},
		updateOnePlayer:{
			data:"PlayerUpdateInput",
			where:"PlayerWhereUniqueInput"
		},
		upsertOnePlayer:{
			where:"PlayerWhereUniqueInput",
			create:"PlayerCreateInput",
			update:"PlayerUpdateInput"
		},
		createManyPlaylist:{
			data:"PlaylistCreateManyInput"
		},
		createOnePlaylist:{
			data:"PlaylistCreateInput"
		},
		deleteManyPlaylist:{
			where:"PlaylistWhereInput"
		},
		deleteOnePlaylist:{
			where:"PlaylistWhereUniqueInput"
		},
		updateManyPlaylist:{
			data:"PlaylistUpdateManyMutationInput",
			where:"PlaylistWhereInput"
		},
		updateOnePlaylist:{
			data:"PlaylistUpdateInput",
			where:"PlaylistWhereUniqueInput"
		},
		upsertOnePlaylist:{
			where:"PlaylistWhereUniqueInput",
			create:"PlaylistCreateInput",
			update:"PlaylistUpdateInput"
		},
		createManySlotConfiguration:{
			data:"SlotConfigurationCreateManyInput"
		},
		createOneSlotConfiguration:{
			data:"SlotConfigurationCreateInput"
		},
		deleteManySlotConfiguration:{
			where:"SlotConfigurationWhereInput"
		},
		deleteOneSlotConfiguration:{
			where:"SlotConfigurationWhereUniqueInput"
		},
		updateManySlotConfiguration:{
			data:"SlotConfigurationUpdateManyMutationInput",
			where:"SlotConfigurationWhereInput"
		},
		updateOneSlotConfiguration:{
			data:"SlotConfigurationUpdateInput",
			where:"SlotConfigurationWhereUniqueInput"
		},
		upsertOneSlotConfiguration:{
			where:"SlotConfigurationWhereUniqueInput",
			create:"SlotConfigurationCreateInput",
			update:"SlotConfigurationUpdateInput"
		},
		createManySubscription:{
			data:"SubscriptionCreateManyInput"
		},
		createOneSubscription:{
			data:"SubscriptionCreateInput"
		},
		deleteManySubscription:{
			where:"SubscriptionWhereInput"
		},
		deleteOneSubscription:{
			where:"SubscriptionWhereUniqueInput"
		},
		updateManySubscription:{
			data:"SubscriptionUpdateManyMutationInput",
			where:"SubscriptionWhereInput"
		},
		updateOneSubscription:{
			data:"SubscriptionUpdateInput",
			where:"SubscriptionWhereUniqueInput"
		},
		upsertOneSubscription:{
			where:"SubscriptionWhereUniqueInput",
			create:"SubscriptionCreateInput",
			update:"SubscriptionUpdateInput"
		},
		createManyUser:{
			data:"UserCreateManyInput"
		},
		createOneUser:{
			data:"UserCreateInput"
		},
		deleteManyUser:{
			where:"UserWhereInput"
		},
		deleteOneUser:{
			where:"UserWhereUniqueInput"
		},
		updateManyUser:{
			data:"UserUpdateManyMutationInput",
			where:"UserWhereInput"
		},
		updateOneUser:{
			data:"UserUpdateInput",
			where:"UserWhereUniqueInput"
		},
		upsertOneUser:{
			where:"UserWhereUniqueInput",
			create:"UserCreateInput",
			update:"UserUpdateInput"
		},
		createManyUserPosition:{
			data:"UserPositionCreateManyInput"
		},
		createOneUserPosition:{
			data:"UserPositionCreateInput"
		},
		deleteManyUserPosition:{
			where:"UserPositionWhereInput"
		},
		deleteOneUserPosition:{
			where:"UserPositionWhereUniqueInput"
		},
		updateManyUserPosition:{
			data:"UserPositionUpdateManyMutationInput",
			where:"UserPositionWhereInput"
		},
		updateOneUserPosition:{
			data:"UserPositionUpdateInput",
			where:"UserPositionWhereUniqueInput"
		},
		upsertOneUserPosition:{
			where:"UserPositionWhereUniqueInput",
			create:"UserPositionCreateInput",
			update:"UserPositionUpdateInput"
		},
		createManyWorkspace:{
			data:"WorkspaceCreateManyInput"
		},
		createOneWorkspace:{
			data:"WorkspaceCreateInput"
		},
		deleteManyWorkspace:{
			where:"WorkspaceWhereInput"
		},
		deleteOneWorkspace:{
			where:"WorkspaceWhereUniqueInput"
		},
		updateManyWorkspace:{
			data:"WorkspaceUpdateManyMutationInput",
			where:"WorkspaceWhereInput"
		},
		updateOneWorkspace:{
			data:"WorkspaceUpdateInput",
			where:"WorkspaceWhereUniqueInput"
		},
		upsertOneWorkspace:{
			where:"WorkspaceWhereUniqueInput",
			create:"WorkspaceCreateInput",
			update:"WorkspaceUpdateInput"
		},
		createManyWorkspaceRole:{
			data:"WorkspaceRoleCreateManyInput"
		},
		createOneWorkspaceRole:{
			data:"WorkspaceRoleCreateInput"
		},
		deleteManyWorkspaceRole:{
			where:"WorkspaceRoleWhereInput"
		},
		deleteOneWorkspaceRole:{
			where:"WorkspaceRoleWhereUniqueInput"
		},
		updateManyWorkspaceRole:{
			data:"WorkspaceRoleUpdateManyMutationInput",
			where:"WorkspaceRoleWhereInput"
		},
		updateOneWorkspaceRole:{
			data:"WorkspaceRoleUpdateInput",
			where:"WorkspaceRoleWhereUniqueInput"
		},
		upsertOneWorkspaceRole:{
			where:"WorkspaceRoleWhereUniqueInput",
			create:"WorkspaceRoleCreateInput",
			update:"WorkspaceRoleUpdateInput"
		},
		updateUser:{
			accountDetails:"AccountDetails"
		},
		createCustomUser:{
			user:"UserInput"
		},
		deleteUserCustom:{

		},
		sendMail:{
			templateId:"TemplateIds"
		},
		createOneCampaignBooking:{
			data:"CampaignCreateInput"
		},
		createOneAdboardPublicReview:{
			data:"AdBoardPublicReviewCreateInput"
		},
		refreshSubscription:{

		},
		cancelSubscription:{

		},
		updateBookingStatus:{
			status:"BookingStatus",
			where:"BookingWhereUniqueInput"
		},
		updateBookingAsset:{
			where:"BookingWhereUniqueInput"
		},
		prepareBooking:{
			bookingRequest:"BookingRequest"
		},
		cancelBooking:{
			where:"BookingWhereUniqueInput"
		},
		deployBooking:{
			where:"BookingWhereUniqueInput"
		},
		updateDraft:{
			data:"AdBoardUpdateInput"
		},
		deleteDraft:{

		},
		addReviewComment:{
			action:"ReviewAction"
		},
		updateAdBoardStatus:{
			status:"AdBoardStatus"
		},
		publishApprovedDraft:{

		},
		addPlayersToAdboard:{

		},
		createOneAdboardWithGroup:{
			data:"AdBoardCreateInput"
		},
		deleteOneAdboardWithGroup:{

		},
		updateOneScheduler:{
			data:"AdBoardGroupUpdateInput",
			where:"AdBoardGroupWhereUniqueInput"
		},
		setupOnePlayer:{

		}
	},
	AdBoardCreateManyInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime"
	},
	MapCreateInput:{

	},
	SpecificationCreateInput:{

	},
	ResolutionCreateInput:{

	},
	ScreenSizeCreateInput:{
		unit:"MeasurementUnit"
	},
	PlaybackTimeCreateInput:{

	},
	WebhookCreateInput:{
		headers:"JSON",
		body:"JSON"
	},
	AdBoardCreateInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryCreateNestedOneWithoutAdBoardsInput",
		locationSubCategory:"LocationSubCategoryCreateNestedOneWithoutAdBoardsInput",
		type:"AdBoardTypeCreateNestedOneWithoutAdBoardsInput",
		category:"BusinessCategoryCreateNestedOneWithoutAdBoardsInput",
		restrictedBusines:"BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput",
		bookings:"BookingCreateNestedManyWithoutAdBoardInput",
		orders:"OrderCreateNestedManyWithoutAdBoardInput",
		slotConfigurations:"SlotConfigurationCreateNestedManyWithoutAdBoardInput",
		offlineBookingsContact:"UserCreateNestedOneWithoutOfflineBookingAdboardsInput",
		createdBy:"UserCreateNestedOneWithoutAdBoardsInput",
		workspace:"WorkspaceCreateNestedOneWithoutAdBoardsInput",
		players:"PlayerCreateNestedManyWithoutAdBoardsInput",
		adBoardGroup:"AdBoardGroupCreateNestedOneWithoutAdBoardInput",
		cameras:"CameraCreateNestedManyWithoutAdBoardInput",
		publishedVersion:"AdBoardCreateNestedOneWithoutDraftAdboardInput",
		draftAdboard:"AdBoardCreateNestedOneWithoutPublishedVersionInput",
		history:"AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput",
		archivedBy:"UserCreateNestedOneWithoutArchivedAdboardsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"
	},
	LocationCategoryCreateNestedOneWithoutAdBoardsInput:{
		create:"LocationCategoryCreateWithoutAdBoardsInput",
		connectOrCreate:"LocationCategoryCreateOrConnectWithoutAdBoardsInput",
		connect:"LocationCategoryWhereUniqueInput"
	},
	LocationCategoryCreateWithoutAdBoardsInput:{
		locationSubCategory:"LocationSubCategoryCreateNestedManyWithoutLocationCategoryInput"
	},
	LocationSubCategoryCreateNestedManyWithoutLocationCategoryInput:{
		create:"LocationSubCategoryCreateWithoutLocationCategoryInput",
		connectOrCreate:"LocationSubCategoryCreateOrConnectWithoutLocationCategoryInput",
		createMany:"LocationSubCategoryCreateManyLocationCategoryInputEnvelope",
		connect:"LocationSubCategoryWhereUniqueInput"
	},
	LocationSubCategoryCreateWithoutLocationCategoryInput:{
		adBoards:"AdBoardCreateNestedManyWithoutLocationSubCategoryInput"
	},
	AdBoardCreateNestedManyWithoutLocationSubCategoryInput:{
		create:"AdBoardCreateWithoutLocationSubCategoryInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutLocationSubCategoryInput",
		createMany:"AdBoardCreateManyLocationSubCategoryInputEnvelope",
		connect:"AdBoardWhereUniqueInput"
	},
	AdBoardCreateWithoutLocationSubCategoryInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryCreateNestedOneWithoutAdBoardsInput",
		type:"AdBoardTypeCreateNestedOneWithoutAdBoardsInput",
		category:"BusinessCategoryCreateNestedOneWithoutAdBoardsInput",
		restrictedBusines:"BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput",
		bookings:"BookingCreateNestedManyWithoutAdBoardInput",
		orders:"OrderCreateNestedManyWithoutAdBoardInput",
		slotConfigurations:"SlotConfigurationCreateNestedManyWithoutAdBoardInput",
		offlineBookingsContact:"UserCreateNestedOneWithoutOfflineBookingAdboardsInput",
		createdBy:"UserCreateNestedOneWithoutAdBoardsInput",
		workspace:"WorkspaceCreateNestedOneWithoutAdBoardsInput",
		players:"PlayerCreateNestedManyWithoutAdBoardsInput",
		adBoardGroup:"AdBoardGroupCreateNestedOneWithoutAdBoardInput",
		cameras:"CameraCreateNestedManyWithoutAdBoardInput",
		publishedVersion:"AdBoardCreateNestedOneWithoutDraftAdboardInput",
		draftAdboard:"AdBoardCreateNestedOneWithoutPublishedVersionInput",
		history:"AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput",
		archivedBy:"UserCreateNestedOneWithoutArchivedAdboardsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"
	},
	AdBoardTypeCreateNestedOneWithoutAdBoardsInput:{
		create:"AdBoardTypeCreateWithoutAdBoardsInput",
		connectOrCreate:"AdBoardTypeCreateOrConnectWithoutAdBoardsInput",
		connect:"AdBoardTypeWhereUniqueInput"
	},
	AdBoardTypeCreateWithoutAdBoardsInput:{

	},
	AdBoardTypeCreateOrConnectWithoutAdBoardsInput:{
		where:"AdBoardTypeWhereUniqueInput",
		create:"AdBoardTypeCreateWithoutAdBoardsInput"
	},
	BusinessCategoryCreateNestedOneWithoutAdBoardsInput:{
		create:"BusinessCategoryCreateWithoutAdBoardsInput",
		connectOrCreate:"BusinessCategoryCreateOrConnectWithoutAdBoardsInput",
		connect:"BusinessCategoryWhereUniqueInput"
	},
	BusinessCategoryCreateWithoutAdBoardsInput:{
		restrictedByAdBoards:"AdBoardCreateNestedManyWithoutRestrictedBusinesInput",
		campaigns:"CampaignCreateNestedManyWithoutBusinessCategoryInput",
		bookings:"BookingCreateNestedManyWithoutBusinessCategoryInput"
	},
	AdBoardCreateNestedManyWithoutRestrictedBusinesInput:{
		create:"AdBoardCreateWithoutRestrictedBusinesInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutRestrictedBusinesInput",
		connect:"AdBoardWhereUniqueInput"
	},
	AdBoardCreateWithoutRestrictedBusinesInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryCreateNestedOneWithoutAdBoardsInput",
		locationSubCategory:"LocationSubCategoryCreateNestedOneWithoutAdBoardsInput",
		type:"AdBoardTypeCreateNestedOneWithoutAdBoardsInput",
		category:"BusinessCategoryCreateNestedOneWithoutAdBoardsInput",
		bookings:"BookingCreateNestedManyWithoutAdBoardInput",
		orders:"OrderCreateNestedManyWithoutAdBoardInput",
		slotConfigurations:"SlotConfigurationCreateNestedManyWithoutAdBoardInput",
		offlineBookingsContact:"UserCreateNestedOneWithoutOfflineBookingAdboardsInput",
		createdBy:"UserCreateNestedOneWithoutAdBoardsInput",
		workspace:"WorkspaceCreateNestedOneWithoutAdBoardsInput",
		players:"PlayerCreateNestedManyWithoutAdBoardsInput",
		adBoardGroup:"AdBoardGroupCreateNestedOneWithoutAdBoardInput",
		cameras:"CameraCreateNestedManyWithoutAdBoardInput",
		publishedVersion:"AdBoardCreateNestedOneWithoutDraftAdboardInput",
		draftAdboard:"AdBoardCreateNestedOneWithoutPublishedVersionInput",
		history:"AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput",
		archivedBy:"UserCreateNestedOneWithoutArchivedAdboardsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"
	},
	LocationSubCategoryCreateNestedOneWithoutAdBoardsInput:{
		create:"LocationSubCategoryCreateWithoutAdBoardsInput",
		connectOrCreate:"LocationSubCategoryCreateOrConnectWithoutAdBoardsInput",
		connect:"LocationSubCategoryWhereUniqueInput"
	},
	LocationSubCategoryCreateWithoutAdBoardsInput:{
		locationCategory:"LocationCategoryCreateNestedOneWithoutLocationSubCategoryInput"
	},
	LocationCategoryCreateNestedOneWithoutLocationSubCategoryInput:{
		create:"LocationCategoryCreateWithoutLocationSubCategoryInput",
		connectOrCreate:"LocationCategoryCreateOrConnectWithoutLocationSubCategoryInput",
		connect:"LocationCategoryWhereUniqueInput"
	},
	LocationCategoryCreateWithoutLocationSubCategoryInput:{
		adBoards:"AdBoardCreateNestedManyWithoutLocationCategoryInput"
	},
	AdBoardCreateNestedManyWithoutLocationCategoryInput:{
		create:"AdBoardCreateWithoutLocationCategoryInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutLocationCategoryInput",
		createMany:"AdBoardCreateManyLocationCategoryInputEnvelope",
		connect:"AdBoardWhereUniqueInput"
	},
	AdBoardCreateWithoutLocationCategoryInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationSubCategory:"LocationSubCategoryCreateNestedOneWithoutAdBoardsInput",
		type:"AdBoardTypeCreateNestedOneWithoutAdBoardsInput",
		category:"BusinessCategoryCreateNestedOneWithoutAdBoardsInput",
		restrictedBusines:"BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput",
		bookings:"BookingCreateNestedManyWithoutAdBoardInput",
		orders:"OrderCreateNestedManyWithoutAdBoardInput",
		slotConfigurations:"SlotConfigurationCreateNestedManyWithoutAdBoardInput",
		offlineBookingsContact:"UserCreateNestedOneWithoutOfflineBookingAdboardsInput",
		createdBy:"UserCreateNestedOneWithoutAdBoardsInput",
		workspace:"WorkspaceCreateNestedOneWithoutAdBoardsInput",
		players:"PlayerCreateNestedManyWithoutAdBoardsInput",
		adBoardGroup:"AdBoardGroupCreateNestedOneWithoutAdBoardInput",
		cameras:"CameraCreateNestedManyWithoutAdBoardInput",
		publishedVersion:"AdBoardCreateNestedOneWithoutDraftAdboardInput",
		draftAdboard:"AdBoardCreateNestedOneWithoutPublishedVersionInput",
		history:"AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput",
		archivedBy:"UserCreateNestedOneWithoutArchivedAdboardsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"
	},
	BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput:{
		create:"BusinessCategoryCreateWithoutRestrictedByAdBoardsInput",
		connectOrCreate:"BusinessCategoryCreateOrConnectWithoutRestrictedByAdBoardsInput",
		connect:"BusinessCategoryWhereUniqueInput"
	},
	BusinessCategoryCreateWithoutRestrictedByAdBoardsInput:{
		adBoards:"AdBoardCreateNestedManyWithoutCategoryInput",
		campaigns:"CampaignCreateNestedManyWithoutBusinessCategoryInput",
		bookings:"BookingCreateNestedManyWithoutBusinessCategoryInput"
	},
	AdBoardCreateNestedManyWithoutCategoryInput:{
		create:"AdBoardCreateWithoutCategoryInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutCategoryInput",
		createMany:"AdBoardCreateManyCategoryInputEnvelope",
		connect:"AdBoardWhereUniqueInput"
	},
	AdBoardCreateWithoutCategoryInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryCreateNestedOneWithoutAdBoardsInput",
		locationSubCategory:"LocationSubCategoryCreateNestedOneWithoutAdBoardsInput",
		type:"AdBoardTypeCreateNestedOneWithoutAdBoardsInput",
		restrictedBusines:"BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput",
		bookings:"BookingCreateNestedManyWithoutAdBoardInput",
		orders:"OrderCreateNestedManyWithoutAdBoardInput",
		slotConfigurations:"SlotConfigurationCreateNestedManyWithoutAdBoardInput",
		offlineBookingsContact:"UserCreateNestedOneWithoutOfflineBookingAdboardsInput",
		createdBy:"UserCreateNestedOneWithoutAdBoardsInput",
		workspace:"WorkspaceCreateNestedOneWithoutAdBoardsInput",
		players:"PlayerCreateNestedManyWithoutAdBoardsInput",
		adBoardGroup:"AdBoardGroupCreateNestedOneWithoutAdBoardInput",
		cameras:"CameraCreateNestedManyWithoutAdBoardInput",
		publishedVersion:"AdBoardCreateNestedOneWithoutDraftAdboardInput",
		draftAdboard:"AdBoardCreateNestedOneWithoutPublishedVersionInput",
		history:"AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput",
		archivedBy:"UserCreateNestedOneWithoutArchivedAdboardsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"
	},
	BookingCreateNestedManyWithoutAdBoardInput:{
		create:"BookingCreateWithoutAdBoardInput",
		connectOrCreate:"BookingCreateOrConnectWithoutAdBoardInput",
		createMany:"BookingCreateManyAdBoardInputEnvelope",
		connect:"BookingWhereUniqueInput"
	},
	BookingCreateWithoutAdBoardInput:{
		bookingDate:"DateTime",
		bookingDetails:"BookingDetailsCreateInput",
		bookingStatus:"BookingStatus",
		paymentStatus:"PaymentStatus",
		deploymentStatus:"DeploymentStatus",
		contentApprovalStatus:"ContentApprovalStatus",
		selectedDates:"OrderSelectedDateCreateInput",
		payment:"BookingPaymentCreateInput",
		priceSummary:"PriceSummaryCreateInput",
		pisignage:"BookingPisignageDataCreateInput",
		refund:"BookingRefundCreateInput",
		assets:"FileCreateNestedManyWithoutBookingsInput",
		businessCategory:"BusinessCategoryCreateNestedOneWithoutBookingsInput",
		proofs:"FileCreateNestedManyWithoutProofBookingInput",
		user:"UserCreateNestedOneWithoutBookingsInput",
		changelogs:"ChangelogCreateNestedManyWithoutBookingInput",
		workspace:"WorkspaceCreateNestedOneWithoutBookingsInput",
		campaign:"CampaignCreateNestedOneWithoutBookingsInput",
		payouts:"PayoutCreateNestedManyWithoutBookingInput",
		sharedUsers:"UserCreateNestedManyWithoutSharedBookingsInput",
		webhookLogs:"WebhookLogCreateNestedManyWithoutBookingInput",
		slotUsage:"SlotUsageCreateNestedManyWithoutBookingInput"
	},
	BookingDetailsCreateInput:{

	},
	OrderSelectedDateCreateInput:{
		endDate:"DateTime",
		startDate:"DateTime"
	},
	BookingPaymentCreateInput:{
		customer:"BookingCustomerCreateInput",
		paymentGateway:"PaymentGatewayName",
		totalDetails:"PaymentTotalDetailsCreateInput",
		razorpay:"JSON",
		stripe:"JSON"
	},
	BookingCustomerCreateInput:{
		customerDetails:"PaymentCustomerDetailsCreateInput"
	},
	PaymentCustomerDetailsCreateInput:{

	},
	PaymentTotalDetailsCreateInput:{

	},
	PriceSummaryCreateInput:{

	},
	BookingPisignageDataCreateInput:{

	},
	BookingRefundCreateInput:{
		razorpay:"JSON",
		stripe:"JSON"
	},
	FileCreateNestedManyWithoutBookingsInput:{
		create:"FileCreateWithoutBookingsInput",
		connectOrCreate:"FileCreateOrConnectWithoutBookingsInput",
		connect:"FileWhereUniqueInput"
	},
	FileCreateWithoutBookingsInput:{
		meta:"FileMetaCreateInput",
		status:"FileStatus",
		updatedAt:"DateTime",
		createdAt:"DateTime",
		updatedBy:"UserCreateNestedOneWithoutUpdatedFilesInput",
		createdBy:"UserCreateNestedOneWithoutCreatedFilesInput",
		workspace:"WorkspaceCreateNestedOneWithoutFilesInput",
		proofBooking:"BookingCreateNestedOneWithoutProofsInput",
		campaigns:"CampaignCreateNestedManyWithoutAssetsInput"
	},
	FileMetaCreateInput:{

	},
	UserCreateNestedOneWithoutUpdatedFilesInput:{
		create:"UserCreateWithoutUpdatedFilesInput",
		connectOrCreate:"UserCreateOrConnectWithoutUpdatedFilesInput",
		connect:"UserWhereUniqueInput"
	},
	UserCreateWithoutUpdatedFilesInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingCreateNestedManyWithoutUserInput",
		createdFiles:"FileCreateNestedManyWithoutCreatedByInput",
		orders:"OrderCreateNestedManyWithoutCreatedByInput",
		changelogs:"ChangelogCreateNestedManyWithoutUserInput",
		adBoards:"AdBoardCreateNestedManyWithoutCreatedByInput",
		audits:"AuditCreateNestedManyWithoutUserInput",
		campaigns:"CampaignCreateNestedManyWithoutUserInput",
		sharedBookings:"BookingCreateNestedManyWithoutSharedUsersInput",
		memberships:"MembershipCreateNestedManyWithoutUserInput",
		notifications:"NotificationCreateNestedManyWithoutRecipientInput",
		publishAdboardVersion:"AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput",
		offlineBookingAdboards:"AdBoardCreateNestedManyWithoutOfflineBookingsContactInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutUserInput",
		archivedAdboards:"AdBoardCreateNestedManyWithoutArchivedByInput",
		ownedWorkspaces:"WorkspaceCreateNestedManyWithoutOwnerInput",
		leadWorspaces:"WorkspaceCreateNestedManyWithoutLeadsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutUserInput",
		userPositions:"UserPositionCreateNestedManyWithoutUserInput"
	},
	UserStripeCreateInput:{

	},
	NotificationPreferenceCreateInput:{
		type:"NotificationType",
		channels:"NotificationChannel",
		pushSetting:"PushSettingCreateInput"
	},
	PushSettingCreateInput:{

	},
	BookingCreateNestedManyWithoutUserInput:{
		create:"BookingCreateWithoutUserInput",
		connectOrCreate:"BookingCreateOrConnectWithoutUserInput",
		createMany:"BookingCreateManyUserInputEnvelope",
		connect:"BookingWhereUniqueInput"
	},
	BookingCreateWithoutUserInput:{
		bookingDate:"DateTime",
		bookingDetails:"BookingDetailsCreateInput",
		bookingStatus:"BookingStatus",
		paymentStatus:"PaymentStatus",
		deploymentStatus:"DeploymentStatus",
		contentApprovalStatus:"ContentApprovalStatus",
		selectedDates:"OrderSelectedDateCreateInput",
		payment:"BookingPaymentCreateInput",
		priceSummary:"PriceSummaryCreateInput",
		pisignage:"BookingPisignageDataCreateInput",
		refund:"BookingRefundCreateInput",
		assets:"FileCreateNestedManyWithoutBookingsInput",
		businessCategory:"BusinessCategoryCreateNestedOneWithoutBookingsInput",
		proofs:"FileCreateNestedManyWithoutProofBookingInput",
		adBoard:"AdBoardCreateNestedOneWithoutBookingsInput",
		changelogs:"ChangelogCreateNestedManyWithoutBookingInput",
		workspace:"WorkspaceCreateNestedOneWithoutBookingsInput",
		campaign:"CampaignCreateNestedOneWithoutBookingsInput",
		payouts:"PayoutCreateNestedManyWithoutBookingInput",
		sharedUsers:"UserCreateNestedManyWithoutSharedBookingsInput",
		webhookLogs:"WebhookLogCreateNestedManyWithoutBookingInput",
		slotUsage:"SlotUsageCreateNestedManyWithoutBookingInput"
	},
	BusinessCategoryCreateNestedOneWithoutBookingsInput:{
		create:"BusinessCategoryCreateWithoutBookingsInput",
		connectOrCreate:"BusinessCategoryCreateOrConnectWithoutBookingsInput",
		connect:"BusinessCategoryWhereUniqueInput"
	},
	BusinessCategoryCreateWithoutBookingsInput:{
		adBoards:"AdBoardCreateNestedManyWithoutCategoryInput",
		restrictedByAdBoards:"AdBoardCreateNestedManyWithoutRestrictedBusinesInput",
		campaigns:"CampaignCreateNestedManyWithoutBusinessCategoryInput"
	},
	CampaignCreateNestedManyWithoutBusinessCategoryInput:{
		create:"CampaignCreateWithoutBusinessCategoryInput",
		connectOrCreate:"CampaignCreateOrConnectWithoutBusinessCategoryInput",
		createMany:"CampaignCreateManyBusinessCategoryInputEnvelope",
		connect:"CampaignWhereUniqueInput"
	},
	CampaignCreateWithoutBusinessCategoryInput:{
		createdAt:"DateTime",
		updatedAt:"DateTime",
		payment:"BookingPaymentCreateInput",
		paymentStatus:"PaymentStatus",
		priceSummary:"PriceSummaryCreateInput",
		selectedDates:"OrderSelectedDateCreateInput",
		user:"UserCreateNestedOneWithoutCampaignsInput",
		bookings:"BookingCreateNestedManyWithoutCampaignInput",
		payments:"PaymentCreateNestedManyWithoutCampaignInput",
		assets:"FileCreateNestedManyWithoutCampaignsInput"
	},
	UserCreateNestedOneWithoutCampaignsInput:{
		create:"UserCreateWithoutCampaignsInput",
		connectOrCreate:"UserCreateOrConnectWithoutCampaignsInput",
		connect:"UserWhereUniqueInput"
	},
	UserCreateWithoutCampaignsInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingCreateNestedManyWithoutUserInput",
		createdFiles:"FileCreateNestedManyWithoutCreatedByInput",
		updatedFiles:"FileCreateNestedManyWithoutUpdatedByInput",
		orders:"OrderCreateNestedManyWithoutCreatedByInput",
		changelogs:"ChangelogCreateNestedManyWithoutUserInput",
		adBoards:"AdBoardCreateNestedManyWithoutCreatedByInput",
		audits:"AuditCreateNestedManyWithoutUserInput",
		sharedBookings:"BookingCreateNestedManyWithoutSharedUsersInput",
		memberships:"MembershipCreateNestedManyWithoutUserInput",
		notifications:"NotificationCreateNestedManyWithoutRecipientInput",
		publishAdboardVersion:"AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput",
		offlineBookingAdboards:"AdBoardCreateNestedManyWithoutOfflineBookingsContactInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutUserInput",
		archivedAdboards:"AdBoardCreateNestedManyWithoutArchivedByInput",
		ownedWorkspaces:"WorkspaceCreateNestedManyWithoutOwnerInput",
		leadWorspaces:"WorkspaceCreateNestedManyWithoutLeadsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutUserInput",
		userPositions:"UserPositionCreateNestedManyWithoutUserInput"
	},
	FileCreateNestedManyWithoutCreatedByInput:{
		create:"FileCreateWithoutCreatedByInput",
		connectOrCreate:"FileCreateOrConnectWithoutCreatedByInput",
		createMany:"FileCreateManyCreatedByInputEnvelope",
		connect:"FileWhereUniqueInput"
	},
	FileCreateWithoutCreatedByInput:{
		meta:"FileMetaCreateInput",
		status:"FileStatus",
		updatedAt:"DateTime",
		createdAt:"DateTime",
		updatedBy:"UserCreateNestedOneWithoutUpdatedFilesInput",
		bookings:"BookingCreateNestedManyWithoutAssetsInput",
		workspace:"WorkspaceCreateNestedOneWithoutFilesInput",
		proofBooking:"BookingCreateNestedOneWithoutProofsInput",
		campaigns:"CampaignCreateNestedManyWithoutAssetsInput"
	},
	BookingCreateNestedManyWithoutAssetsInput:{
		create:"BookingCreateWithoutAssetsInput",
		connectOrCreate:"BookingCreateOrConnectWithoutAssetsInput",
		connect:"BookingWhereUniqueInput"
	},
	BookingCreateWithoutAssetsInput:{
		bookingDate:"DateTime",
		bookingDetails:"BookingDetailsCreateInput",
		bookingStatus:"BookingStatus",
		paymentStatus:"PaymentStatus",
		deploymentStatus:"DeploymentStatus",
		contentApprovalStatus:"ContentApprovalStatus",
		selectedDates:"OrderSelectedDateCreateInput",
		payment:"BookingPaymentCreateInput",
		priceSummary:"PriceSummaryCreateInput",
		pisignage:"BookingPisignageDataCreateInput",
		refund:"BookingRefundCreateInput",
		businessCategory:"BusinessCategoryCreateNestedOneWithoutBookingsInput",
		proofs:"FileCreateNestedManyWithoutProofBookingInput",
		user:"UserCreateNestedOneWithoutBookingsInput",
		adBoard:"AdBoardCreateNestedOneWithoutBookingsInput",
		changelogs:"ChangelogCreateNestedManyWithoutBookingInput",
		workspace:"WorkspaceCreateNestedOneWithoutBookingsInput",
		campaign:"CampaignCreateNestedOneWithoutBookingsInput",
		payouts:"PayoutCreateNestedManyWithoutBookingInput",
		sharedUsers:"UserCreateNestedManyWithoutSharedBookingsInput",
		webhookLogs:"WebhookLogCreateNestedManyWithoutBookingInput",
		slotUsage:"SlotUsageCreateNestedManyWithoutBookingInput"
	},
	FileCreateNestedManyWithoutProofBookingInput:{
		create:"FileCreateWithoutProofBookingInput",
		connectOrCreate:"FileCreateOrConnectWithoutProofBookingInput",
		createMany:"FileCreateManyProofBookingInputEnvelope",
		connect:"FileWhereUniqueInput"
	},
	FileCreateWithoutProofBookingInput:{
		meta:"FileMetaCreateInput",
		status:"FileStatus",
		updatedAt:"DateTime",
		createdAt:"DateTime",
		updatedBy:"UserCreateNestedOneWithoutUpdatedFilesInput",
		createdBy:"UserCreateNestedOneWithoutCreatedFilesInput",
		bookings:"BookingCreateNestedManyWithoutAssetsInput",
		workspace:"WorkspaceCreateNestedOneWithoutFilesInput",
		campaigns:"CampaignCreateNestedManyWithoutAssetsInput"
	},
	UserCreateNestedOneWithoutCreatedFilesInput:{
		create:"UserCreateWithoutCreatedFilesInput",
		connectOrCreate:"UserCreateOrConnectWithoutCreatedFilesInput",
		connect:"UserWhereUniqueInput"
	},
	UserCreateWithoutCreatedFilesInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingCreateNestedManyWithoutUserInput",
		updatedFiles:"FileCreateNestedManyWithoutUpdatedByInput",
		orders:"OrderCreateNestedManyWithoutCreatedByInput",
		changelogs:"ChangelogCreateNestedManyWithoutUserInput",
		adBoards:"AdBoardCreateNestedManyWithoutCreatedByInput",
		audits:"AuditCreateNestedManyWithoutUserInput",
		campaigns:"CampaignCreateNestedManyWithoutUserInput",
		sharedBookings:"BookingCreateNestedManyWithoutSharedUsersInput",
		memberships:"MembershipCreateNestedManyWithoutUserInput",
		notifications:"NotificationCreateNestedManyWithoutRecipientInput",
		publishAdboardVersion:"AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput",
		offlineBookingAdboards:"AdBoardCreateNestedManyWithoutOfflineBookingsContactInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutUserInput",
		archivedAdboards:"AdBoardCreateNestedManyWithoutArchivedByInput",
		ownedWorkspaces:"WorkspaceCreateNestedManyWithoutOwnerInput",
		leadWorspaces:"WorkspaceCreateNestedManyWithoutLeadsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutUserInput",
		userPositions:"UserPositionCreateNestedManyWithoutUserInput"
	},
	FileCreateNestedManyWithoutUpdatedByInput:{
		create:"FileCreateWithoutUpdatedByInput",
		connectOrCreate:"FileCreateOrConnectWithoutUpdatedByInput",
		createMany:"FileCreateManyUpdatedByInputEnvelope",
		connect:"FileWhereUniqueInput"
	},
	FileCreateWithoutUpdatedByInput:{
		meta:"FileMetaCreateInput",
		status:"FileStatus",
		updatedAt:"DateTime",
		createdAt:"DateTime",
		createdBy:"UserCreateNestedOneWithoutCreatedFilesInput",
		bookings:"BookingCreateNestedManyWithoutAssetsInput",
		workspace:"WorkspaceCreateNestedOneWithoutFilesInput",
		proofBooking:"BookingCreateNestedOneWithoutProofsInput",
		campaigns:"CampaignCreateNestedManyWithoutAssetsInput"
	},
	WorkspaceCreateNestedOneWithoutFilesInput:{
		create:"WorkspaceCreateWithoutFilesInput",
		connectOrCreate:"WorkspaceCreateOrConnectWithoutFilesInput",
		connect:"WorkspaceWhereUniqueInput"
	},
	WorkspaceCreateWithoutFilesInput:{
		type:"WorkspaceType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		owner:"UserCreateNestedOneWithoutOwnedWorkspacesInput",
		memberships:"MembershipCreateNestedManyWithoutWorkspaceInput",
		adBoards:"AdBoardCreateNestedManyWithoutWorkspaceInput",
		playlists:"PlaylistCreateNestedManyWithoutWorkspaceInput",
		adBoardGroups:"AdBoardGroupCreateNestedManyWithoutWorkspaceInput",
		audits:"AuditCreateNestedManyWithoutWorkspaceInput",
		bookings:"BookingCreateNestedManyWithoutWorkspaceInput",
		players:"PlayerCreateNestedManyWithoutWorkspaceInput",
		transactions:"TransactionCreateNestedManyWithoutWorkspaceInput",
		activeSubscription:"SubscriptionCreateNestedOneWithoutActiveWorkspaceInput",
		cameraServers:"CameraServerCreateNestedManyWithoutWorkspaceInput",
		workspaceRoles:"WorkspaceRoleCreateNestedManyWithoutWorkspaceInput",
		webhookLogs:"WebhookLogCreateNestedManyWithoutWorkspaceInput",
		leads:"UserCreateNestedManyWithoutLeadWorspacesInput"
	},
	UserCreateNestedOneWithoutOwnedWorkspacesInput:{
		create:"UserCreateWithoutOwnedWorkspacesInput",
		connectOrCreate:"UserCreateOrConnectWithoutOwnedWorkspacesInput",
		connect:"UserWhereUniqueInput"
	},
	UserCreateWithoutOwnedWorkspacesInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingCreateNestedManyWithoutUserInput",
		createdFiles:"FileCreateNestedManyWithoutCreatedByInput",
		updatedFiles:"FileCreateNestedManyWithoutUpdatedByInput",
		orders:"OrderCreateNestedManyWithoutCreatedByInput",
		changelogs:"ChangelogCreateNestedManyWithoutUserInput",
		adBoards:"AdBoardCreateNestedManyWithoutCreatedByInput",
		audits:"AuditCreateNestedManyWithoutUserInput",
		campaigns:"CampaignCreateNestedManyWithoutUserInput",
		sharedBookings:"BookingCreateNestedManyWithoutSharedUsersInput",
		memberships:"MembershipCreateNestedManyWithoutUserInput",
		notifications:"NotificationCreateNestedManyWithoutRecipientInput",
		publishAdboardVersion:"AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput",
		offlineBookingAdboards:"AdBoardCreateNestedManyWithoutOfflineBookingsContactInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutUserInput",
		archivedAdboards:"AdBoardCreateNestedManyWithoutArchivedByInput",
		leadWorspaces:"WorkspaceCreateNestedManyWithoutLeadsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutUserInput",
		userPositions:"UserPositionCreateNestedManyWithoutUserInput"
	},
	OrderCreateNestedManyWithoutCreatedByInput:{
		create:"OrderCreateWithoutCreatedByInput",
		connectOrCreate:"OrderCreateOrConnectWithoutCreatedByInput",
		createMany:"OrderCreateManyCreatedByInputEnvelope",
		connect:"OrderWhereUniqueInput"
	},
	OrderCreateWithoutCreatedByInput:{
		createdDate:"DateTime",
		priceSummary:"PriceSummaryCreateInput",
		selectedDate:"OrderSelectedDateCreateInput",
		adBoard:"AdBoardCreateNestedOneWithoutOrdersInput"
	},
	AdBoardCreateNestedOneWithoutOrdersInput:{
		create:"AdBoardCreateWithoutOrdersInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutOrdersInput",
		connect:"AdBoardWhereUniqueInput"
	},
	AdBoardCreateWithoutOrdersInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryCreateNestedOneWithoutAdBoardsInput",
		locationSubCategory:"LocationSubCategoryCreateNestedOneWithoutAdBoardsInput",
		type:"AdBoardTypeCreateNestedOneWithoutAdBoardsInput",
		category:"BusinessCategoryCreateNestedOneWithoutAdBoardsInput",
		restrictedBusines:"BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput",
		bookings:"BookingCreateNestedManyWithoutAdBoardInput",
		slotConfigurations:"SlotConfigurationCreateNestedManyWithoutAdBoardInput",
		offlineBookingsContact:"UserCreateNestedOneWithoutOfflineBookingAdboardsInput",
		createdBy:"UserCreateNestedOneWithoutAdBoardsInput",
		workspace:"WorkspaceCreateNestedOneWithoutAdBoardsInput",
		players:"PlayerCreateNestedManyWithoutAdBoardsInput",
		adBoardGroup:"AdBoardGroupCreateNestedOneWithoutAdBoardInput",
		cameras:"CameraCreateNestedManyWithoutAdBoardInput",
		publishedVersion:"AdBoardCreateNestedOneWithoutDraftAdboardInput",
		draftAdboard:"AdBoardCreateNestedOneWithoutPublishedVersionInput",
		history:"AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput",
		archivedBy:"UserCreateNestedOneWithoutArchivedAdboardsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"
	},
	SlotConfigurationCreateNestedManyWithoutAdBoardInput:{
		create:"SlotConfigurationCreateWithoutAdBoardInput",
		connectOrCreate:"SlotConfigurationCreateOrConnectWithoutAdBoardInput",
		createMany:"SlotConfigurationCreateManyAdBoardInputEnvelope",
		connect:"SlotConfigurationWhereUniqueInput"
	},
	SlotConfigurationCreateWithoutAdBoardInput:{
		date:"DateTime"
	},
	SlotConfigurationCreateOrConnectWithoutAdBoardInput:{
		where:"SlotConfigurationWhereUniqueInput",
		create:"SlotConfigurationCreateWithoutAdBoardInput"
	},
	SlotConfigurationCreateManyAdBoardInputEnvelope:{
		data:"SlotConfigurationCreateManyAdBoardInput"
	},
	SlotConfigurationCreateManyAdBoardInput:{
		date:"DateTime"
	},
	UserCreateNestedOneWithoutOfflineBookingAdboardsInput:{
		create:"UserCreateWithoutOfflineBookingAdboardsInput",
		connectOrCreate:"UserCreateOrConnectWithoutOfflineBookingAdboardsInput",
		connect:"UserWhereUniqueInput"
	},
	UserCreateWithoutOfflineBookingAdboardsInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingCreateNestedManyWithoutUserInput",
		createdFiles:"FileCreateNestedManyWithoutCreatedByInput",
		updatedFiles:"FileCreateNestedManyWithoutUpdatedByInput",
		orders:"OrderCreateNestedManyWithoutCreatedByInput",
		changelogs:"ChangelogCreateNestedManyWithoutUserInput",
		adBoards:"AdBoardCreateNestedManyWithoutCreatedByInput",
		audits:"AuditCreateNestedManyWithoutUserInput",
		campaigns:"CampaignCreateNestedManyWithoutUserInput",
		sharedBookings:"BookingCreateNestedManyWithoutSharedUsersInput",
		memberships:"MembershipCreateNestedManyWithoutUserInput",
		notifications:"NotificationCreateNestedManyWithoutRecipientInput",
		publishAdboardVersion:"AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutUserInput",
		archivedAdboards:"AdBoardCreateNestedManyWithoutArchivedByInput",
		ownedWorkspaces:"WorkspaceCreateNestedManyWithoutOwnerInput",
		leadWorspaces:"WorkspaceCreateNestedManyWithoutLeadsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutUserInput",
		userPositions:"UserPositionCreateNestedManyWithoutUserInput"
	},
	ChangelogCreateNestedManyWithoutUserInput:{
		create:"ChangelogCreateWithoutUserInput",
		connectOrCreate:"ChangelogCreateOrConnectWithoutUserInput",
		createMany:"ChangelogCreateManyUserInputEnvelope",
		connect:"ChangelogWhereUniqueInput"
	},
	ChangelogCreateWithoutUserInput:{
		createdAt:"DateTime",
		updates:"JSON",
		booking:"BookingCreateNestedOneWithoutChangelogsInput"
	},
	BookingCreateNestedOneWithoutChangelogsInput:{
		create:"BookingCreateWithoutChangelogsInput",
		connectOrCreate:"BookingCreateOrConnectWithoutChangelogsInput",
		connect:"BookingWhereUniqueInput"
	},
	BookingCreateWithoutChangelogsInput:{
		bookingDate:"DateTime",
		bookingDetails:"BookingDetailsCreateInput",
		bookingStatus:"BookingStatus",
		paymentStatus:"PaymentStatus",
		deploymentStatus:"DeploymentStatus",
		contentApprovalStatus:"ContentApprovalStatus",
		selectedDates:"OrderSelectedDateCreateInput",
		payment:"BookingPaymentCreateInput",
		priceSummary:"PriceSummaryCreateInput",
		pisignage:"BookingPisignageDataCreateInput",
		refund:"BookingRefundCreateInput",
		assets:"FileCreateNestedManyWithoutBookingsInput",
		businessCategory:"BusinessCategoryCreateNestedOneWithoutBookingsInput",
		proofs:"FileCreateNestedManyWithoutProofBookingInput",
		user:"UserCreateNestedOneWithoutBookingsInput",
		adBoard:"AdBoardCreateNestedOneWithoutBookingsInput",
		workspace:"WorkspaceCreateNestedOneWithoutBookingsInput",
		campaign:"CampaignCreateNestedOneWithoutBookingsInput",
		payouts:"PayoutCreateNestedManyWithoutBookingInput",
		sharedUsers:"UserCreateNestedManyWithoutSharedBookingsInput",
		webhookLogs:"WebhookLogCreateNestedManyWithoutBookingInput",
		slotUsage:"SlotUsageCreateNestedManyWithoutBookingInput"
	},
	UserCreateNestedOneWithoutBookingsInput:{
		create:"UserCreateWithoutBookingsInput",
		connectOrCreate:"UserCreateOrConnectWithoutBookingsInput",
		connect:"UserWhereUniqueInput"
	},
	UserCreateWithoutBookingsInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		createdFiles:"FileCreateNestedManyWithoutCreatedByInput",
		updatedFiles:"FileCreateNestedManyWithoutUpdatedByInput",
		orders:"OrderCreateNestedManyWithoutCreatedByInput",
		changelogs:"ChangelogCreateNestedManyWithoutUserInput",
		adBoards:"AdBoardCreateNestedManyWithoutCreatedByInput",
		audits:"AuditCreateNestedManyWithoutUserInput",
		campaigns:"CampaignCreateNestedManyWithoutUserInput",
		sharedBookings:"BookingCreateNestedManyWithoutSharedUsersInput",
		memberships:"MembershipCreateNestedManyWithoutUserInput",
		notifications:"NotificationCreateNestedManyWithoutRecipientInput",
		publishAdboardVersion:"AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput",
		offlineBookingAdboards:"AdBoardCreateNestedManyWithoutOfflineBookingsContactInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutUserInput",
		archivedAdboards:"AdBoardCreateNestedManyWithoutArchivedByInput",
		ownedWorkspaces:"WorkspaceCreateNestedManyWithoutOwnerInput",
		leadWorspaces:"WorkspaceCreateNestedManyWithoutLeadsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutUserInput",
		userPositions:"UserPositionCreateNestedManyWithoutUserInput"
	},
	AdBoardCreateNestedManyWithoutCreatedByInput:{
		create:"AdBoardCreateWithoutCreatedByInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutCreatedByInput",
		createMany:"AdBoardCreateManyCreatedByInputEnvelope",
		connect:"AdBoardWhereUniqueInput"
	},
	AdBoardCreateWithoutCreatedByInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryCreateNestedOneWithoutAdBoardsInput",
		locationSubCategory:"LocationSubCategoryCreateNestedOneWithoutAdBoardsInput",
		type:"AdBoardTypeCreateNestedOneWithoutAdBoardsInput",
		category:"BusinessCategoryCreateNestedOneWithoutAdBoardsInput",
		restrictedBusines:"BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput",
		bookings:"BookingCreateNestedManyWithoutAdBoardInput",
		orders:"OrderCreateNestedManyWithoutAdBoardInput",
		slotConfigurations:"SlotConfigurationCreateNestedManyWithoutAdBoardInput",
		offlineBookingsContact:"UserCreateNestedOneWithoutOfflineBookingAdboardsInput",
		workspace:"WorkspaceCreateNestedOneWithoutAdBoardsInput",
		players:"PlayerCreateNestedManyWithoutAdBoardsInput",
		adBoardGroup:"AdBoardGroupCreateNestedOneWithoutAdBoardInput",
		cameras:"CameraCreateNestedManyWithoutAdBoardInput",
		publishedVersion:"AdBoardCreateNestedOneWithoutDraftAdboardInput",
		draftAdboard:"AdBoardCreateNestedOneWithoutPublishedVersionInput",
		history:"AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput",
		archivedBy:"UserCreateNestedOneWithoutArchivedAdboardsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"
	},
	OrderCreateNestedManyWithoutAdBoardInput:{
		create:"OrderCreateWithoutAdBoardInput",
		connectOrCreate:"OrderCreateOrConnectWithoutAdBoardInput",
		createMany:"OrderCreateManyAdBoardInputEnvelope",
		connect:"OrderWhereUniqueInput"
	},
	OrderCreateWithoutAdBoardInput:{
		createdDate:"DateTime",
		priceSummary:"PriceSummaryCreateInput",
		selectedDate:"OrderSelectedDateCreateInput",
		createdBy:"UserCreateNestedOneWithoutOrdersInput"
	},
	UserCreateNestedOneWithoutOrdersInput:{
		create:"UserCreateWithoutOrdersInput",
		connectOrCreate:"UserCreateOrConnectWithoutOrdersInput",
		connect:"UserWhereUniqueInput"
	},
	UserCreateWithoutOrdersInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingCreateNestedManyWithoutUserInput",
		createdFiles:"FileCreateNestedManyWithoutCreatedByInput",
		updatedFiles:"FileCreateNestedManyWithoutUpdatedByInput",
		changelogs:"ChangelogCreateNestedManyWithoutUserInput",
		adBoards:"AdBoardCreateNestedManyWithoutCreatedByInput",
		audits:"AuditCreateNestedManyWithoutUserInput",
		campaigns:"CampaignCreateNestedManyWithoutUserInput",
		sharedBookings:"BookingCreateNestedManyWithoutSharedUsersInput",
		memberships:"MembershipCreateNestedManyWithoutUserInput",
		notifications:"NotificationCreateNestedManyWithoutRecipientInput",
		publishAdboardVersion:"AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput",
		offlineBookingAdboards:"AdBoardCreateNestedManyWithoutOfflineBookingsContactInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutUserInput",
		archivedAdboards:"AdBoardCreateNestedManyWithoutArchivedByInput",
		ownedWorkspaces:"WorkspaceCreateNestedManyWithoutOwnerInput",
		leadWorspaces:"WorkspaceCreateNestedManyWithoutLeadsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutUserInput",
		userPositions:"UserPositionCreateNestedManyWithoutUserInput"
	},
	AuditCreateNestedManyWithoutUserInput:{
		create:"AuditCreateWithoutUserInput",
		connectOrCreate:"AuditCreateOrConnectWithoutUserInput",
		createMany:"AuditCreateManyUserInputEnvelope",
		connect:"AuditWhereUniqueInput"
	},
	AuditCreateWithoutUserInput:{
		createdAt:"DateTime",
		entity:"EntityCreateInput",
		updates:"JSON",
		workspace:"WorkspaceCreateNestedOneWithoutAuditsInput"
	},
	EntityCreateInput:{

	},
	WorkspaceCreateNestedOneWithoutAuditsInput:{
		create:"WorkspaceCreateWithoutAuditsInput",
		connectOrCreate:"WorkspaceCreateOrConnectWithoutAuditsInput",
		connect:"WorkspaceWhereUniqueInput"
	},
	WorkspaceCreateWithoutAuditsInput:{
		type:"WorkspaceType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		owner:"UserCreateNestedOneWithoutOwnedWorkspacesInput",
		memberships:"MembershipCreateNestedManyWithoutWorkspaceInput",
		adBoards:"AdBoardCreateNestedManyWithoutWorkspaceInput",
		playlists:"PlaylistCreateNestedManyWithoutWorkspaceInput",
		adBoardGroups:"AdBoardGroupCreateNestedManyWithoutWorkspaceInput",
		bookings:"BookingCreateNestedManyWithoutWorkspaceInput",
		files:"FileCreateNestedManyWithoutWorkspaceInput",
		players:"PlayerCreateNestedManyWithoutWorkspaceInput",
		transactions:"TransactionCreateNestedManyWithoutWorkspaceInput",
		activeSubscription:"SubscriptionCreateNestedOneWithoutActiveWorkspaceInput",
		cameraServers:"CameraServerCreateNestedManyWithoutWorkspaceInput",
		workspaceRoles:"WorkspaceRoleCreateNestedManyWithoutWorkspaceInput",
		webhookLogs:"WebhookLogCreateNestedManyWithoutWorkspaceInput",
		leads:"UserCreateNestedManyWithoutLeadWorspacesInput"
	},
	MembershipCreateNestedManyWithoutWorkspaceInput:{
		create:"MembershipCreateWithoutWorkspaceInput",
		connectOrCreate:"MembershipCreateOrConnectWithoutWorkspaceInput",
		createMany:"MembershipCreateManyWorkspaceInputEnvelope",
		connect:"MembershipWhereUniqueInput"
	},
	MembershipCreateWithoutWorkspaceInput:{
		createdAt:"DateTime",
		user:"UserCreateNestedOneWithoutMembershipsInput",
		role:"WorkspaceRoleCreateNestedOneWithoutMembershipsInput"
	},
	UserCreateNestedOneWithoutMembershipsInput:{
		create:"UserCreateWithoutMembershipsInput",
		connectOrCreate:"UserCreateOrConnectWithoutMembershipsInput",
		connect:"UserWhereUniqueInput"
	},
	UserCreateWithoutMembershipsInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingCreateNestedManyWithoutUserInput",
		createdFiles:"FileCreateNestedManyWithoutCreatedByInput",
		updatedFiles:"FileCreateNestedManyWithoutUpdatedByInput",
		orders:"OrderCreateNestedManyWithoutCreatedByInput",
		changelogs:"ChangelogCreateNestedManyWithoutUserInput",
		adBoards:"AdBoardCreateNestedManyWithoutCreatedByInput",
		audits:"AuditCreateNestedManyWithoutUserInput",
		campaigns:"CampaignCreateNestedManyWithoutUserInput",
		sharedBookings:"BookingCreateNestedManyWithoutSharedUsersInput",
		notifications:"NotificationCreateNestedManyWithoutRecipientInput",
		publishAdboardVersion:"AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput",
		offlineBookingAdboards:"AdBoardCreateNestedManyWithoutOfflineBookingsContactInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutUserInput",
		archivedAdboards:"AdBoardCreateNestedManyWithoutArchivedByInput",
		ownedWorkspaces:"WorkspaceCreateNestedManyWithoutOwnerInput",
		leadWorspaces:"WorkspaceCreateNestedManyWithoutLeadsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutUserInput",
		userPositions:"UserPositionCreateNestedManyWithoutUserInput"
	},
	CampaignCreateNestedManyWithoutUserInput:{
		create:"CampaignCreateWithoutUserInput",
		connectOrCreate:"CampaignCreateOrConnectWithoutUserInput",
		createMany:"CampaignCreateManyUserInputEnvelope",
		connect:"CampaignWhereUniqueInput"
	},
	CampaignCreateWithoutUserInput:{
		createdAt:"DateTime",
		updatedAt:"DateTime",
		payment:"BookingPaymentCreateInput",
		paymentStatus:"PaymentStatus",
		priceSummary:"PriceSummaryCreateInput",
		selectedDates:"OrderSelectedDateCreateInput",
		businessCategory:"BusinessCategoryCreateNestedOneWithoutCampaignsInput",
		bookings:"BookingCreateNestedManyWithoutCampaignInput",
		payments:"PaymentCreateNestedManyWithoutCampaignInput",
		assets:"FileCreateNestedManyWithoutCampaignsInput"
	},
	BusinessCategoryCreateNestedOneWithoutCampaignsInput:{
		create:"BusinessCategoryCreateWithoutCampaignsInput",
		connectOrCreate:"BusinessCategoryCreateOrConnectWithoutCampaignsInput",
		connect:"BusinessCategoryWhereUniqueInput"
	},
	BusinessCategoryCreateWithoutCampaignsInput:{
		adBoards:"AdBoardCreateNestedManyWithoutCategoryInput",
		restrictedByAdBoards:"AdBoardCreateNestedManyWithoutRestrictedBusinesInput",
		bookings:"BookingCreateNestedManyWithoutBusinessCategoryInput"
	},
	BookingCreateNestedManyWithoutBusinessCategoryInput:{
		create:"BookingCreateWithoutBusinessCategoryInput",
		connectOrCreate:"BookingCreateOrConnectWithoutBusinessCategoryInput",
		createMany:"BookingCreateManyBusinessCategoryInputEnvelope",
		connect:"BookingWhereUniqueInput"
	},
	BookingCreateWithoutBusinessCategoryInput:{
		bookingDate:"DateTime",
		bookingDetails:"BookingDetailsCreateInput",
		bookingStatus:"BookingStatus",
		paymentStatus:"PaymentStatus",
		deploymentStatus:"DeploymentStatus",
		contentApprovalStatus:"ContentApprovalStatus",
		selectedDates:"OrderSelectedDateCreateInput",
		payment:"BookingPaymentCreateInput",
		priceSummary:"PriceSummaryCreateInput",
		pisignage:"BookingPisignageDataCreateInput",
		refund:"BookingRefundCreateInput",
		assets:"FileCreateNestedManyWithoutBookingsInput",
		proofs:"FileCreateNestedManyWithoutProofBookingInput",
		user:"UserCreateNestedOneWithoutBookingsInput",
		adBoard:"AdBoardCreateNestedOneWithoutBookingsInput",
		changelogs:"ChangelogCreateNestedManyWithoutBookingInput",
		workspace:"WorkspaceCreateNestedOneWithoutBookingsInput",
		campaign:"CampaignCreateNestedOneWithoutBookingsInput",
		payouts:"PayoutCreateNestedManyWithoutBookingInput",
		sharedUsers:"UserCreateNestedManyWithoutSharedBookingsInput",
		webhookLogs:"WebhookLogCreateNestedManyWithoutBookingInput",
		slotUsage:"SlotUsageCreateNestedManyWithoutBookingInput"
	},
	AdBoardCreateNestedOneWithoutBookingsInput:{
		create:"AdBoardCreateWithoutBookingsInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutBookingsInput",
		connect:"AdBoardWhereUniqueInput"
	},
	AdBoardCreateWithoutBookingsInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryCreateNestedOneWithoutAdBoardsInput",
		locationSubCategory:"LocationSubCategoryCreateNestedOneWithoutAdBoardsInput",
		type:"AdBoardTypeCreateNestedOneWithoutAdBoardsInput",
		category:"BusinessCategoryCreateNestedOneWithoutAdBoardsInput",
		restrictedBusines:"BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput",
		orders:"OrderCreateNestedManyWithoutAdBoardInput",
		slotConfigurations:"SlotConfigurationCreateNestedManyWithoutAdBoardInput",
		offlineBookingsContact:"UserCreateNestedOneWithoutOfflineBookingAdboardsInput",
		createdBy:"UserCreateNestedOneWithoutAdBoardsInput",
		workspace:"WorkspaceCreateNestedOneWithoutAdBoardsInput",
		players:"PlayerCreateNestedManyWithoutAdBoardsInput",
		adBoardGroup:"AdBoardGroupCreateNestedOneWithoutAdBoardInput",
		cameras:"CameraCreateNestedManyWithoutAdBoardInput",
		publishedVersion:"AdBoardCreateNestedOneWithoutDraftAdboardInput",
		draftAdboard:"AdBoardCreateNestedOneWithoutPublishedVersionInput",
		history:"AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput",
		archivedBy:"UserCreateNestedOneWithoutArchivedAdboardsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"
	},
	UserCreateNestedOneWithoutAdBoardsInput:{
		create:"UserCreateWithoutAdBoardsInput",
		connectOrCreate:"UserCreateOrConnectWithoutAdBoardsInput",
		connect:"UserWhereUniqueInput"
	},
	UserCreateWithoutAdBoardsInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingCreateNestedManyWithoutUserInput",
		createdFiles:"FileCreateNestedManyWithoutCreatedByInput",
		updatedFiles:"FileCreateNestedManyWithoutUpdatedByInput",
		orders:"OrderCreateNestedManyWithoutCreatedByInput",
		changelogs:"ChangelogCreateNestedManyWithoutUserInput",
		audits:"AuditCreateNestedManyWithoutUserInput",
		campaigns:"CampaignCreateNestedManyWithoutUserInput",
		sharedBookings:"BookingCreateNestedManyWithoutSharedUsersInput",
		memberships:"MembershipCreateNestedManyWithoutUserInput",
		notifications:"NotificationCreateNestedManyWithoutRecipientInput",
		publishAdboardVersion:"AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput",
		offlineBookingAdboards:"AdBoardCreateNestedManyWithoutOfflineBookingsContactInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutUserInput",
		archivedAdboards:"AdBoardCreateNestedManyWithoutArchivedByInput",
		ownedWorkspaces:"WorkspaceCreateNestedManyWithoutOwnerInput",
		leadWorspaces:"WorkspaceCreateNestedManyWithoutLeadsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutUserInput",
		userPositions:"UserPositionCreateNestedManyWithoutUserInput"
	},
	BookingCreateNestedManyWithoutSharedUsersInput:{
		create:"BookingCreateWithoutSharedUsersInput",
		connectOrCreate:"BookingCreateOrConnectWithoutSharedUsersInput",
		connect:"BookingWhereUniqueInput"
	},
	BookingCreateWithoutSharedUsersInput:{
		bookingDate:"DateTime",
		bookingDetails:"BookingDetailsCreateInput",
		bookingStatus:"BookingStatus",
		paymentStatus:"PaymentStatus",
		deploymentStatus:"DeploymentStatus",
		contentApprovalStatus:"ContentApprovalStatus",
		selectedDates:"OrderSelectedDateCreateInput",
		payment:"BookingPaymentCreateInput",
		priceSummary:"PriceSummaryCreateInput",
		pisignage:"BookingPisignageDataCreateInput",
		refund:"BookingRefundCreateInput",
		assets:"FileCreateNestedManyWithoutBookingsInput",
		businessCategory:"BusinessCategoryCreateNestedOneWithoutBookingsInput",
		proofs:"FileCreateNestedManyWithoutProofBookingInput",
		user:"UserCreateNestedOneWithoutBookingsInput",
		adBoard:"AdBoardCreateNestedOneWithoutBookingsInput",
		changelogs:"ChangelogCreateNestedManyWithoutBookingInput",
		workspace:"WorkspaceCreateNestedOneWithoutBookingsInput",
		campaign:"CampaignCreateNestedOneWithoutBookingsInput",
		payouts:"PayoutCreateNestedManyWithoutBookingInput",
		webhookLogs:"WebhookLogCreateNestedManyWithoutBookingInput",
		slotUsage:"SlotUsageCreateNestedManyWithoutBookingInput"
	},
	ChangelogCreateNestedManyWithoutBookingInput:{
		create:"ChangelogCreateWithoutBookingInput",
		connectOrCreate:"ChangelogCreateOrConnectWithoutBookingInput",
		createMany:"ChangelogCreateManyBookingInputEnvelope",
		connect:"ChangelogWhereUniqueInput"
	},
	ChangelogCreateWithoutBookingInput:{
		createdAt:"DateTime",
		updates:"JSON",
		user:"UserCreateNestedOneWithoutChangelogsInput"
	},
	UserCreateNestedOneWithoutChangelogsInput:{
		create:"UserCreateWithoutChangelogsInput",
		connectOrCreate:"UserCreateOrConnectWithoutChangelogsInput",
		connect:"UserWhereUniqueInput"
	},
	UserCreateWithoutChangelogsInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingCreateNestedManyWithoutUserInput",
		createdFiles:"FileCreateNestedManyWithoutCreatedByInput",
		updatedFiles:"FileCreateNestedManyWithoutUpdatedByInput",
		orders:"OrderCreateNestedManyWithoutCreatedByInput",
		adBoards:"AdBoardCreateNestedManyWithoutCreatedByInput",
		audits:"AuditCreateNestedManyWithoutUserInput",
		campaigns:"CampaignCreateNestedManyWithoutUserInput",
		sharedBookings:"BookingCreateNestedManyWithoutSharedUsersInput",
		memberships:"MembershipCreateNestedManyWithoutUserInput",
		notifications:"NotificationCreateNestedManyWithoutRecipientInput",
		publishAdboardVersion:"AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput",
		offlineBookingAdboards:"AdBoardCreateNestedManyWithoutOfflineBookingsContactInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutUserInput",
		archivedAdboards:"AdBoardCreateNestedManyWithoutArchivedByInput",
		ownedWorkspaces:"WorkspaceCreateNestedManyWithoutOwnerInput",
		leadWorspaces:"WorkspaceCreateNestedManyWithoutLeadsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutUserInput",
		userPositions:"UserPositionCreateNestedManyWithoutUserInput"
	},
	MembershipCreateNestedManyWithoutUserInput:{
		create:"MembershipCreateWithoutUserInput",
		connectOrCreate:"MembershipCreateOrConnectWithoutUserInput",
		createMany:"MembershipCreateManyUserInputEnvelope",
		connect:"MembershipWhereUniqueInput"
	},
	MembershipCreateWithoutUserInput:{
		createdAt:"DateTime",
		workspace:"WorkspaceCreateNestedOneWithoutMembershipsInput",
		role:"WorkspaceRoleCreateNestedOneWithoutMembershipsInput"
	},
	WorkspaceCreateNestedOneWithoutMembershipsInput:{
		create:"WorkspaceCreateWithoutMembershipsInput",
		connectOrCreate:"WorkspaceCreateOrConnectWithoutMembershipsInput",
		connect:"WorkspaceWhereUniqueInput"
	},
	WorkspaceCreateWithoutMembershipsInput:{
		type:"WorkspaceType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		owner:"UserCreateNestedOneWithoutOwnedWorkspacesInput",
		adBoards:"AdBoardCreateNestedManyWithoutWorkspaceInput",
		playlists:"PlaylistCreateNestedManyWithoutWorkspaceInput",
		adBoardGroups:"AdBoardGroupCreateNestedManyWithoutWorkspaceInput",
		audits:"AuditCreateNestedManyWithoutWorkspaceInput",
		bookings:"BookingCreateNestedManyWithoutWorkspaceInput",
		files:"FileCreateNestedManyWithoutWorkspaceInput",
		players:"PlayerCreateNestedManyWithoutWorkspaceInput",
		transactions:"TransactionCreateNestedManyWithoutWorkspaceInput",
		activeSubscription:"SubscriptionCreateNestedOneWithoutActiveWorkspaceInput",
		cameraServers:"CameraServerCreateNestedManyWithoutWorkspaceInput",
		workspaceRoles:"WorkspaceRoleCreateNestedManyWithoutWorkspaceInput",
		webhookLogs:"WebhookLogCreateNestedManyWithoutWorkspaceInput",
		leads:"UserCreateNestedManyWithoutLeadWorspacesInput"
	},
	AdBoardCreateNestedManyWithoutWorkspaceInput:{
		create:"AdBoardCreateWithoutWorkspaceInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutWorkspaceInput",
		createMany:"AdBoardCreateManyWorkspaceInputEnvelope",
		connect:"AdBoardWhereUniqueInput"
	},
	AdBoardCreateWithoutWorkspaceInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryCreateNestedOneWithoutAdBoardsInput",
		locationSubCategory:"LocationSubCategoryCreateNestedOneWithoutAdBoardsInput",
		type:"AdBoardTypeCreateNestedOneWithoutAdBoardsInput",
		category:"BusinessCategoryCreateNestedOneWithoutAdBoardsInput",
		restrictedBusines:"BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput",
		bookings:"BookingCreateNestedManyWithoutAdBoardInput",
		orders:"OrderCreateNestedManyWithoutAdBoardInput",
		slotConfigurations:"SlotConfigurationCreateNestedManyWithoutAdBoardInput",
		offlineBookingsContact:"UserCreateNestedOneWithoutOfflineBookingAdboardsInput",
		createdBy:"UserCreateNestedOneWithoutAdBoardsInput",
		players:"PlayerCreateNestedManyWithoutAdBoardsInput",
		adBoardGroup:"AdBoardGroupCreateNestedOneWithoutAdBoardInput",
		cameras:"CameraCreateNestedManyWithoutAdBoardInput",
		publishedVersion:"AdBoardCreateNestedOneWithoutDraftAdboardInput",
		draftAdboard:"AdBoardCreateNestedOneWithoutPublishedVersionInput",
		history:"AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput",
		archivedBy:"UserCreateNestedOneWithoutArchivedAdboardsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"
	},
	PlayerCreateNestedManyWithoutAdBoardsInput:{
		create:"PlayerCreateWithoutAdBoardsInput",
		connectOrCreate:"PlayerCreateOrConnectWithoutAdBoardsInput",
		connect:"PlayerWhereUniqueInput"
	},
	PlayerCreateWithoutAdBoardsInput:{
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackDetails:"PlaybackTimeCreateInput",
		aspectRatioType:"AspectRatioType",
		map:"MapCreateInput",
		adBoardGroup:"AdBoardGroupCreateNestedOneWithoutPisignagePlayersInput",
		workspace:"WorkspaceCreateNestedOneWithoutPlayersInput",
		status:"PlayerUptimeStatusCreateNestedManyWithoutPlayerInput",
		cameras:"CameraCreateNestedManyWithoutPlayerInput"
	},
	AdBoardGroupCreateNestedOneWithoutPisignagePlayersInput:{
		create:"AdBoardGroupCreateWithoutPisignagePlayersInput",
		connectOrCreate:"AdBoardGroupCreateOrConnectWithoutPisignagePlayersInput",
		connect:"AdBoardGroupWhereUniqueInput"
	},
	AdBoardGroupCreateWithoutPisignagePlayersInput:{
		schedules:"ScheduleCreateInput",
		fitImage:"FitType",
		fitVideo:"FitType",
		adBoard:"AdBoardCreateNestedOneWithoutAdBoardGroupInput",
		scheduledPlaylists:"PlaylistCreateNestedManyWithoutAdBoardGroupsInput",
		adsPlaylist:"PlaylistCreateNestedOneWithoutAdBoardGroupsForAdsInput",
		workspace:"WorkspaceCreateNestedOneWithoutAdBoardGroupsInput"
	},
	ScheduleCreateInput:{

	},
	AdBoardCreateNestedOneWithoutAdBoardGroupInput:{
		create:"AdBoardCreateWithoutAdBoardGroupInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutAdBoardGroupInput",
		connect:"AdBoardWhereUniqueInput"
	},
	AdBoardCreateWithoutAdBoardGroupInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryCreateNestedOneWithoutAdBoardsInput",
		locationSubCategory:"LocationSubCategoryCreateNestedOneWithoutAdBoardsInput",
		type:"AdBoardTypeCreateNestedOneWithoutAdBoardsInput",
		category:"BusinessCategoryCreateNestedOneWithoutAdBoardsInput",
		restrictedBusines:"BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput",
		bookings:"BookingCreateNestedManyWithoutAdBoardInput",
		orders:"OrderCreateNestedManyWithoutAdBoardInput",
		slotConfigurations:"SlotConfigurationCreateNestedManyWithoutAdBoardInput",
		offlineBookingsContact:"UserCreateNestedOneWithoutOfflineBookingAdboardsInput",
		createdBy:"UserCreateNestedOneWithoutAdBoardsInput",
		workspace:"WorkspaceCreateNestedOneWithoutAdBoardsInput",
		players:"PlayerCreateNestedManyWithoutAdBoardsInput",
		cameras:"CameraCreateNestedManyWithoutAdBoardInput",
		publishedVersion:"AdBoardCreateNestedOneWithoutDraftAdboardInput",
		draftAdboard:"AdBoardCreateNestedOneWithoutPublishedVersionInput",
		history:"AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput",
		archivedBy:"UserCreateNestedOneWithoutArchivedAdboardsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"
	},
	WorkspaceCreateNestedOneWithoutAdBoardsInput:{
		create:"WorkspaceCreateWithoutAdBoardsInput",
		connectOrCreate:"WorkspaceCreateOrConnectWithoutAdBoardsInput",
		connect:"WorkspaceWhereUniqueInput"
	},
	WorkspaceCreateWithoutAdBoardsInput:{
		type:"WorkspaceType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		owner:"UserCreateNestedOneWithoutOwnedWorkspacesInput",
		memberships:"MembershipCreateNestedManyWithoutWorkspaceInput",
		playlists:"PlaylistCreateNestedManyWithoutWorkspaceInput",
		adBoardGroups:"AdBoardGroupCreateNestedManyWithoutWorkspaceInput",
		audits:"AuditCreateNestedManyWithoutWorkspaceInput",
		bookings:"BookingCreateNestedManyWithoutWorkspaceInput",
		files:"FileCreateNestedManyWithoutWorkspaceInput",
		players:"PlayerCreateNestedManyWithoutWorkspaceInput",
		transactions:"TransactionCreateNestedManyWithoutWorkspaceInput",
		activeSubscription:"SubscriptionCreateNestedOneWithoutActiveWorkspaceInput",
		cameraServers:"CameraServerCreateNestedManyWithoutWorkspaceInput",
		workspaceRoles:"WorkspaceRoleCreateNestedManyWithoutWorkspaceInput",
		webhookLogs:"WebhookLogCreateNestedManyWithoutWorkspaceInput",
		leads:"UserCreateNestedManyWithoutLeadWorspacesInput"
	},
	PlaylistCreateNestedManyWithoutWorkspaceInput:{
		create:"PlaylistCreateWithoutWorkspaceInput",
		connectOrCreate:"PlaylistCreateOrConnectWithoutWorkspaceInput",
		createMany:"PlaylistCreateManyWorkspaceInputEnvelope",
		connect:"PlaylistWhereUniqueInput"
	},
	PlaylistCreateWithoutWorkspaceInput:{
		assets:"PisignageFileCreateInput",
		layout:"ScreenLayout",
		footerPlaylist:"PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput",
		sidebarPlaylist:"PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput",
		adBoardGroupsForAds:"AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput",
		adBoardGroups:"AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput",
		footerParentPlaylists:"PlaylistCreateNestedManyWithoutFooterPlaylistInput",
		sidebarParentPlaylists:"PlaylistCreateNestedManyWithoutSidebarPlaylistInput"
	},
	PisignageFileCreateInput:{

	},
	PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput:{
		create:"PlaylistCreateWithoutFooterParentPlaylistsInput",
		connectOrCreate:"PlaylistCreateOrConnectWithoutFooterParentPlaylistsInput",
		connect:"PlaylistWhereUniqueInput"
	},
	PlaylistCreateWithoutFooterParentPlaylistsInput:{
		assets:"PisignageFileCreateInput",
		layout:"ScreenLayout",
		footerPlaylist:"PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput",
		sidebarPlaylist:"PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput",
		adBoardGroupsForAds:"AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput",
		adBoardGroups:"AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput",
		workspace:"WorkspaceCreateNestedOneWithoutPlaylistsInput",
		sidebarParentPlaylists:"PlaylistCreateNestedManyWithoutSidebarPlaylistInput"
	},
	PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput:{
		create:"PlaylistCreateWithoutSidebarParentPlaylistsInput",
		connectOrCreate:"PlaylistCreateOrConnectWithoutSidebarParentPlaylistsInput",
		connect:"PlaylistWhereUniqueInput"
	},
	PlaylistCreateWithoutSidebarParentPlaylistsInput:{
		assets:"PisignageFileCreateInput",
		layout:"ScreenLayout",
		footerPlaylist:"PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput",
		sidebarPlaylist:"PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput",
		adBoardGroupsForAds:"AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput",
		adBoardGroups:"AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput",
		workspace:"WorkspaceCreateNestedOneWithoutPlaylistsInput",
		footerParentPlaylists:"PlaylistCreateNestedManyWithoutFooterPlaylistInput"
	},
	AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput:{
		create:"AdBoardGroupCreateWithoutAdsPlaylistInput",
		connectOrCreate:"AdBoardGroupCreateOrConnectWithoutAdsPlaylistInput",
		createMany:"AdBoardGroupCreateManyAdsPlaylistInputEnvelope",
		connect:"AdBoardGroupWhereUniqueInput"
	},
	AdBoardGroupCreateWithoutAdsPlaylistInput:{
		schedules:"ScheduleCreateInput",
		fitImage:"FitType",
		fitVideo:"FitType",
		adBoard:"AdBoardCreateNestedOneWithoutAdBoardGroupInput",
		scheduledPlaylists:"PlaylistCreateNestedManyWithoutAdBoardGroupsInput",
		pisignagePlayers:"PlayerCreateNestedManyWithoutAdBoardGroupInput",
		workspace:"WorkspaceCreateNestedOneWithoutAdBoardGroupsInput"
	},
	PlaylistCreateNestedManyWithoutAdBoardGroupsInput:{
		create:"PlaylistCreateWithoutAdBoardGroupsInput",
		connectOrCreate:"PlaylistCreateOrConnectWithoutAdBoardGroupsInput",
		connect:"PlaylistWhereUniqueInput"
	},
	PlaylistCreateWithoutAdBoardGroupsInput:{
		assets:"PisignageFileCreateInput",
		layout:"ScreenLayout",
		footerPlaylist:"PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput",
		sidebarPlaylist:"PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput",
		adBoardGroupsForAds:"AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput",
		workspace:"WorkspaceCreateNestedOneWithoutPlaylistsInput",
		footerParentPlaylists:"PlaylistCreateNestedManyWithoutFooterPlaylistInput",
		sidebarParentPlaylists:"PlaylistCreateNestedManyWithoutSidebarPlaylistInput"
	},
	WorkspaceCreateNestedOneWithoutPlaylistsInput:{
		create:"WorkspaceCreateWithoutPlaylistsInput",
		connectOrCreate:"WorkspaceCreateOrConnectWithoutPlaylistsInput",
		connect:"WorkspaceWhereUniqueInput"
	},
	WorkspaceCreateWithoutPlaylistsInput:{
		type:"WorkspaceType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		owner:"UserCreateNestedOneWithoutOwnedWorkspacesInput",
		memberships:"MembershipCreateNestedManyWithoutWorkspaceInput",
		adBoards:"AdBoardCreateNestedManyWithoutWorkspaceInput",
		adBoardGroups:"AdBoardGroupCreateNestedManyWithoutWorkspaceInput",
		audits:"AuditCreateNestedManyWithoutWorkspaceInput",
		bookings:"BookingCreateNestedManyWithoutWorkspaceInput",
		files:"FileCreateNestedManyWithoutWorkspaceInput",
		players:"PlayerCreateNestedManyWithoutWorkspaceInput",
		transactions:"TransactionCreateNestedManyWithoutWorkspaceInput",
		activeSubscription:"SubscriptionCreateNestedOneWithoutActiveWorkspaceInput",
		cameraServers:"CameraServerCreateNestedManyWithoutWorkspaceInput",
		workspaceRoles:"WorkspaceRoleCreateNestedManyWithoutWorkspaceInput",
		webhookLogs:"WebhookLogCreateNestedManyWithoutWorkspaceInput",
		leads:"UserCreateNestedManyWithoutLeadWorspacesInput"
	},
	AdBoardGroupCreateNestedManyWithoutWorkspaceInput:{
		create:"AdBoardGroupCreateWithoutWorkspaceInput",
		connectOrCreate:"AdBoardGroupCreateOrConnectWithoutWorkspaceInput",
		createMany:"AdBoardGroupCreateManyWorkspaceInputEnvelope",
		connect:"AdBoardGroupWhereUniqueInput"
	},
	AdBoardGroupCreateWithoutWorkspaceInput:{
		schedules:"ScheduleCreateInput",
		fitImage:"FitType",
		fitVideo:"FitType",
		adBoard:"AdBoardCreateNestedOneWithoutAdBoardGroupInput",
		scheduledPlaylists:"PlaylistCreateNestedManyWithoutAdBoardGroupsInput",
		adsPlaylist:"PlaylistCreateNestedOneWithoutAdBoardGroupsForAdsInput",
		pisignagePlayers:"PlayerCreateNestedManyWithoutAdBoardGroupInput"
	},
	PlaylistCreateNestedOneWithoutAdBoardGroupsForAdsInput:{
		create:"PlaylistCreateWithoutAdBoardGroupsForAdsInput",
		connectOrCreate:"PlaylistCreateOrConnectWithoutAdBoardGroupsForAdsInput",
		connect:"PlaylistWhereUniqueInput"
	},
	PlaylistCreateWithoutAdBoardGroupsForAdsInput:{
		assets:"PisignageFileCreateInput",
		layout:"ScreenLayout",
		footerPlaylist:"PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput",
		sidebarPlaylist:"PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput",
		adBoardGroups:"AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput",
		workspace:"WorkspaceCreateNestedOneWithoutPlaylistsInput",
		footerParentPlaylists:"PlaylistCreateNestedManyWithoutFooterPlaylistInput",
		sidebarParentPlaylists:"PlaylistCreateNestedManyWithoutSidebarPlaylistInput"
	},
	AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput:{
		create:"AdBoardGroupCreateWithoutScheduledPlaylistsInput",
		connectOrCreate:"AdBoardGroupCreateOrConnectWithoutScheduledPlaylistsInput",
		connect:"AdBoardGroupWhereUniqueInput"
	},
	AdBoardGroupCreateWithoutScheduledPlaylistsInput:{
		schedules:"ScheduleCreateInput",
		fitImage:"FitType",
		fitVideo:"FitType",
		adBoard:"AdBoardCreateNestedOneWithoutAdBoardGroupInput",
		adsPlaylist:"PlaylistCreateNestedOneWithoutAdBoardGroupsForAdsInput",
		pisignagePlayers:"PlayerCreateNestedManyWithoutAdBoardGroupInput",
		workspace:"WorkspaceCreateNestedOneWithoutAdBoardGroupsInput"
	},
	PlayerCreateNestedManyWithoutAdBoardGroupInput:{
		create:"PlayerCreateWithoutAdBoardGroupInput",
		connectOrCreate:"PlayerCreateOrConnectWithoutAdBoardGroupInput",
		createMany:"PlayerCreateManyAdBoardGroupInputEnvelope",
		connect:"PlayerWhereUniqueInput"
	},
	PlayerCreateWithoutAdBoardGroupInput:{
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackDetails:"PlaybackTimeCreateInput",
		aspectRatioType:"AspectRatioType",
		map:"MapCreateInput",
		workspace:"WorkspaceCreateNestedOneWithoutPlayersInput",
		status:"PlayerUptimeStatusCreateNestedManyWithoutPlayerInput",
		cameras:"CameraCreateNestedManyWithoutPlayerInput",
		adBoards:"AdBoardCreateNestedManyWithoutPlayersInput"
	},
	WorkspaceCreateNestedOneWithoutPlayersInput:{
		create:"WorkspaceCreateWithoutPlayersInput",
		connectOrCreate:"WorkspaceCreateOrConnectWithoutPlayersInput",
		connect:"WorkspaceWhereUniqueInput"
	},
	WorkspaceCreateWithoutPlayersInput:{
		type:"WorkspaceType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		owner:"UserCreateNestedOneWithoutOwnedWorkspacesInput",
		memberships:"MembershipCreateNestedManyWithoutWorkspaceInput",
		adBoards:"AdBoardCreateNestedManyWithoutWorkspaceInput",
		playlists:"PlaylistCreateNestedManyWithoutWorkspaceInput",
		adBoardGroups:"AdBoardGroupCreateNestedManyWithoutWorkspaceInput",
		audits:"AuditCreateNestedManyWithoutWorkspaceInput",
		bookings:"BookingCreateNestedManyWithoutWorkspaceInput",
		files:"FileCreateNestedManyWithoutWorkspaceInput",
		transactions:"TransactionCreateNestedManyWithoutWorkspaceInput",
		activeSubscription:"SubscriptionCreateNestedOneWithoutActiveWorkspaceInput",
		cameraServers:"CameraServerCreateNestedManyWithoutWorkspaceInput",
		workspaceRoles:"WorkspaceRoleCreateNestedManyWithoutWorkspaceInput",
		webhookLogs:"WebhookLogCreateNestedManyWithoutWorkspaceInput",
		leads:"UserCreateNestedManyWithoutLeadWorspacesInput"
	},
	AuditCreateNestedManyWithoutWorkspaceInput:{
		create:"AuditCreateWithoutWorkspaceInput",
		connectOrCreate:"AuditCreateOrConnectWithoutWorkspaceInput",
		createMany:"AuditCreateManyWorkspaceInputEnvelope",
		connect:"AuditWhereUniqueInput"
	},
	AuditCreateWithoutWorkspaceInput:{
		createdAt:"DateTime",
		entity:"EntityCreateInput",
		updates:"JSON",
		user:"UserCreateNestedOneWithoutAuditsInput"
	},
	UserCreateNestedOneWithoutAuditsInput:{
		create:"UserCreateWithoutAuditsInput",
		connectOrCreate:"UserCreateOrConnectWithoutAuditsInput",
		connect:"UserWhereUniqueInput"
	},
	UserCreateWithoutAuditsInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingCreateNestedManyWithoutUserInput",
		createdFiles:"FileCreateNestedManyWithoutCreatedByInput",
		updatedFiles:"FileCreateNestedManyWithoutUpdatedByInput",
		orders:"OrderCreateNestedManyWithoutCreatedByInput",
		changelogs:"ChangelogCreateNestedManyWithoutUserInput",
		adBoards:"AdBoardCreateNestedManyWithoutCreatedByInput",
		campaigns:"CampaignCreateNestedManyWithoutUserInput",
		sharedBookings:"BookingCreateNestedManyWithoutSharedUsersInput",
		memberships:"MembershipCreateNestedManyWithoutUserInput",
		notifications:"NotificationCreateNestedManyWithoutRecipientInput",
		publishAdboardVersion:"AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput",
		offlineBookingAdboards:"AdBoardCreateNestedManyWithoutOfflineBookingsContactInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutUserInput",
		archivedAdboards:"AdBoardCreateNestedManyWithoutArchivedByInput",
		ownedWorkspaces:"WorkspaceCreateNestedManyWithoutOwnerInput",
		leadWorspaces:"WorkspaceCreateNestedManyWithoutLeadsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutUserInput",
		userPositions:"UserPositionCreateNestedManyWithoutUserInput"
	},
	NotificationCreateNestedManyWithoutRecipientInput:{
		create:"NotificationCreateWithoutRecipientInput",
		connectOrCreate:"NotificationCreateOrConnectWithoutRecipientInput",
		createMany:"NotificationCreateManyRecipientInputEnvelope",
		connect:"NotificationWhereUniqueInput"
	},
	NotificationCreateWithoutRecipientInput:{
		type:"NotificationType",
		metadata:"JSON",
		createdAt:"DateTime",
		readAt:"DateTime",
		channels:"NotificationChannel"
	},
	NotificationCreateOrConnectWithoutRecipientInput:{
		where:"NotificationWhereUniqueInput",
		create:"NotificationCreateWithoutRecipientInput"
	},
	NotificationCreateManyRecipientInputEnvelope:{
		data:"NotificationCreateManyRecipientInput"
	},
	NotificationCreateManyRecipientInput:{
		type:"NotificationType",
		metadata:"JSON",
		createdAt:"DateTime",
		readAt:"DateTime",
		channels:"NotificationChannel"
	},
	AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput:{
		create:"AdBoardPublishHistoryCreateWithoutPublishedByInput",
		connectOrCreate:"AdBoardPublishHistoryCreateOrConnectWithoutPublishedByInput",
		createMany:"AdBoardPublishHistoryCreateManyPublishedByInputEnvelope",
		connect:"AdBoardPublishHistoryWhereUniqueInput"
	},
	AdBoardPublishHistoryCreateWithoutPublishedByInput:{
		publishedAt:"DateTime",
		changesSnapshot:"JSON",
		adBoard:"AdBoardCreateNestedOneWithoutHistoryInput"
	},
	AdBoardCreateNestedOneWithoutHistoryInput:{
		create:"AdBoardCreateWithoutHistoryInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutHistoryInput",
		connect:"AdBoardWhereUniqueInput"
	},
	AdBoardCreateWithoutHistoryInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryCreateNestedOneWithoutAdBoardsInput",
		locationSubCategory:"LocationSubCategoryCreateNestedOneWithoutAdBoardsInput",
		type:"AdBoardTypeCreateNestedOneWithoutAdBoardsInput",
		category:"BusinessCategoryCreateNestedOneWithoutAdBoardsInput",
		restrictedBusines:"BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput",
		bookings:"BookingCreateNestedManyWithoutAdBoardInput",
		orders:"OrderCreateNestedManyWithoutAdBoardInput",
		slotConfigurations:"SlotConfigurationCreateNestedManyWithoutAdBoardInput",
		offlineBookingsContact:"UserCreateNestedOneWithoutOfflineBookingAdboardsInput",
		createdBy:"UserCreateNestedOneWithoutAdBoardsInput",
		workspace:"WorkspaceCreateNestedOneWithoutAdBoardsInput",
		players:"PlayerCreateNestedManyWithoutAdBoardsInput",
		adBoardGroup:"AdBoardGroupCreateNestedOneWithoutAdBoardInput",
		cameras:"CameraCreateNestedManyWithoutAdBoardInput",
		publishedVersion:"AdBoardCreateNestedOneWithoutDraftAdboardInput",
		draftAdboard:"AdBoardCreateNestedOneWithoutPublishedVersionInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput",
		archivedBy:"UserCreateNestedOneWithoutArchivedAdboardsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"
	},
	AdBoardGroupCreateNestedOneWithoutAdBoardInput:{
		create:"AdBoardGroupCreateWithoutAdBoardInput",
		connectOrCreate:"AdBoardGroupCreateOrConnectWithoutAdBoardInput",
		connect:"AdBoardGroupWhereUniqueInput"
	},
	AdBoardGroupCreateWithoutAdBoardInput:{
		schedules:"ScheduleCreateInput",
		fitImage:"FitType",
		fitVideo:"FitType",
		scheduledPlaylists:"PlaylistCreateNestedManyWithoutAdBoardGroupsInput",
		adsPlaylist:"PlaylistCreateNestedOneWithoutAdBoardGroupsForAdsInput",
		pisignagePlayers:"PlayerCreateNestedManyWithoutAdBoardGroupInput",
		workspace:"WorkspaceCreateNestedOneWithoutAdBoardGroupsInput"
	},
	WorkspaceCreateNestedOneWithoutAdBoardGroupsInput:{
		create:"WorkspaceCreateWithoutAdBoardGroupsInput",
		connectOrCreate:"WorkspaceCreateOrConnectWithoutAdBoardGroupsInput",
		connect:"WorkspaceWhereUniqueInput"
	},
	WorkspaceCreateWithoutAdBoardGroupsInput:{
		type:"WorkspaceType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		owner:"UserCreateNestedOneWithoutOwnedWorkspacesInput",
		memberships:"MembershipCreateNestedManyWithoutWorkspaceInput",
		adBoards:"AdBoardCreateNestedManyWithoutWorkspaceInput",
		playlists:"PlaylistCreateNestedManyWithoutWorkspaceInput",
		audits:"AuditCreateNestedManyWithoutWorkspaceInput",
		bookings:"BookingCreateNestedManyWithoutWorkspaceInput",
		files:"FileCreateNestedManyWithoutWorkspaceInput",
		players:"PlayerCreateNestedManyWithoutWorkspaceInput",
		transactions:"TransactionCreateNestedManyWithoutWorkspaceInput",
		activeSubscription:"SubscriptionCreateNestedOneWithoutActiveWorkspaceInput",
		cameraServers:"CameraServerCreateNestedManyWithoutWorkspaceInput",
		workspaceRoles:"WorkspaceRoleCreateNestedManyWithoutWorkspaceInput",
		webhookLogs:"WebhookLogCreateNestedManyWithoutWorkspaceInput",
		leads:"UserCreateNestedManyWithoutLeadWorspacesInput"
	},
	BookingCreateNestedManyWithoutWorkspaceInput:{
		create:"BookingCreateWithoutWorkspaceInput",
		connectOrCreate:"BookingCreateOrConnectWithoutWorkspaceInput",
		createMany:"BookingCreateManyWorkspaceInputEnvelope",
		connect:"BookingWhereUniqueInput"
	},
	BookingCreateWithoutWorkspaceInput:{
		bookingDate:"DateTime",
		bookingDetails:"BookingDetailsCreateInput",
		bookingStatus:"BookingStatus",
		paymentStatus:"PaymentStatus",
		deploymentStatus:"DeploymentStatus",
		contentApprovalStatus:"ContentApprovalStatus",
		selectedDates:"OrderSelectedDateCreateInput",
		payment:"BookingPaymentCreateInput",
		priceSummary:"PriceSummaryCreateInput",
		pisignage:"BookingPisignageDataCreateInput",
		refund:"BookingRefundCreateInput",
		assets:"FileCreateNestedManyWithoutBookingsInput",
		businessCategory:"BusinessCategoryCreateNestedOneWithoutBookingsInput",
		proofs:"FileCreateNestedManyWithoutProofBookingInput",
		user:"UserCreateNestedOneWithoutBookingsInput",
		adBoard:"AdBoardCreateNestedOneWithoutBookingsInput",
		changelogs:"ChangelogCreateNestedManyWithoutBookingInput",
		campaign:"CampaignCreateNestedOneWithoutBookingsInput",
		payouts:"PayoutCreateNestedManyWithoutBookingInput",
		sharedUsers:"UserCreateNestedManyWithoutSharedBookingsInput",
		webhookLogs:"WebhookLogCreateNestedManyWithoutBookingInput",
		slotUsage:"SlotUsageCreateNestedManyWithoutBookingInput"
	},
	CampaignCreateNestedOneWithoutBookingsInput:{
		create:"CampaignCreateWithoutBookingsInput",
		connectOrCreate:"CampaignCreateOrConnectWithoutBookingsInput",
		connect:"CampaignWhereUniqueInput"
	},
	CampaignCreateWithoutBookingsInput:{
		createdAt:"DateTime",
		updatedAt:"DateTime",
		payment:"BookingPaymentCreateInput",
		paymentStatus:"PaymentStatus",
		priceSummary:"PriceSummaryCreateInput",
		selectedDates:"OrderSelectedDateCreateInput",
		user:"UserCreateNestedOneWithoutCampaignsInput",
		businessCategory:"BusinessCategoryCreateNestedOneWithoutCampaignsInput",
		payments:"PaymentCreateNestedManyWithoutCampaignInput",
		assets:"FileCreateNestedManyWithoutCampaignsInput"
	},
	PaymentCreateNestedManyWithoutCampaignInput:{
		create:"PaymentCreateWithoutCampaignInput",
		connectOrCreate:"PaymentCreateOrConnectWithoutCampaignInput",
		createMany:"PaymentCreateManyCampaignInputEnvelope",
		connect:"PaymentWhereUniqueInput"
	},
	PaymentCreateWithoutCampaignInput:{
		paymentGateway:"PaymentGatewayName",
		createdAt:"DateTime",
		updatedAt:"DateTime"
	},
	PaymentCreateOrConnectWithoutCampaignInput:{
		where:"PaymentWhereUniqueInput",
		create:"PaymentCreateWithoutCampaignInput"
	},
	PaymentCreateManyCampaignInputEnvelope:{
		data:"PaymentCreateManyCampaignInput"
	},
	PaymentCreateManyCampaignInput:{
		paymentGateway:"PaymentGatewayName",
		createdAt:"DateTime",
		updatedAt:"DateTime"
	},
	FileCreateNestedManyWithoutCampaignsInput:{
		create:"FileCreateWithoutCampaignsInput",
		connectOrCreate:"FileCreateOrConnectWithoutCampaignsInput",
		connect:"FileWhereUniqueInput"
	},
	FileCreateWithoutCampaignsInput:{
		meta:"FileMetaCreateInput",
		status:"FileStatus",
		updatedAt:"DateTime",
		createdAt:"DateTime",
		updatedBy:"UserCreateNestedOneWithoutUpdatedFilesInput",
		createdBy:"UserCreateNestedOneWithoutCreatedFilesInput",
		bookings:"BookingCreateNestedManyWithoutAssetsInput",
		workspace:"WorkspaceCreateNestedOneWithoutFilesInput",
		proofBooking:"BookingCreateNestedOneWithoutProofsInput"
	},
	BookingCreateNestedOneWithoutProofsInput:{
		create:"BookingCreateWithoutProofsInput",
		connectOrCreate:"BookingCreateOrConnectWithoutProofsInput",
		connect:"BookingWhereUniqueInput"
	},
	BookingCreateWithoutProofsInput:{
		bookingDate:"DateTime",
		bookingDetails:"BookingDetailsCreateInput",
		bookingStatus:"BookingStatus",
		paymentStatus:"PaymentStatus",
		deploymentStatus:"DeploymentStatus",
		contentApprovalStatus:"ContentApprovalStatus",
		selectedDates:"OrderSelectedDateCreateInput",
		payment:"BookingPaymentCreateInput",
		priceSummary:"PriceSummaryCreateInput",
		pisignage:"BookingPisignageDataCreateInput",
		refund:"BookingRefundCreateInput",
		assets:"FileCreateNestedManyWithoutBookingsInput",
		businessCategory:"BusinessCategoryCreateNestedOneWithoutBookingsInput",
		user:"UserCreateNestedOneWithoutBookingsInput",
		adBoard:"AdBoardCreateNestedOneWithoutBookingsInput",
		changelogs:"ChangelogCreateNestedManyWithoutBookingInput",
		workspace:"WorkspaceCreateNestedOneWithoutBookingsInput",
		campaign:"CampaignCreateNestedOneWithoutBookingsInput",
		payouts:"PayoutCreateNestedManyWithoutBookingInput",
		sharedUsers:"UserCreateNestedManyWithoutSharedBookingsInput",
		webhookLogs:"WebhookLogCreateNestedManyWithoutBookingInput",
		slotUsage:"SlotUsageCreateNestedManyWithoutBookingInput"
	},
	WorkspaceCreateNestedOneWithoutBookingsInput:{
		create:"WorkspaceCreateWithoutBookingsInput",
		connectOrCreate:"WorkspaceCreateOrConnectWithoutBookingsInput",
		connect:"WorkspaceWhereUniqueInput"
	},
	WorkspaceCreateWithoutBookingsInput:{
		type:"WorkspaceType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		owner:"UserCreateNestedOneWithoutOwnedWorkspacesInput",
		memberships:"MembershipCreateNestedManyWithoutWorkspaceInput",
		adBoards:"AdBoardCreateNestedManyWithoutWorkspaceInput",
		playlists:"PlaylistCreateNestedManyWithoutWorkspaceInput",
		adBoardGroups:"AdBoardGroupCreateNestedManyWithoutWorkspaceInput",
		audits:"AuditCreateNestedManyWithoutWorkspaceInput",
		files:"FileCreateNestedManyWithoutWorkspaceInput",
		players:"PlayerCreateNestedManyWithoutWorkspaceInput",
		transactions:"TransactionCreateNestedManyWithoutWorkspaceInput",
		activeSubscription:"SubscriptionCreateNestedOneWithoutActiveWorkspaceInput",
		cameraServers:"CameraServerCreateNestedManyWithoutWorkspaceInput",
		workspaceRoles:"WorkspaceRoleCreateNestedManyWithoutWorkspaceInput",
		webhookLogs:"WebhookLogCreateNestedManyWithoutWorkspaceInput",
		leads:"UserCreateNestedManyWithoutLeadWorspacesInput"
	},
	FileCreateNestedManyWithoutWorkspaceInput:{
		create:"FileCreateWithoutWorkspaceInput",
		connectOrCreate:"FileCreateOrConnectWithoutWorkspaceInput",
		createMany:"FileCreateManyWorkspaceInputEnvelope",
		connect:"FileWhereUniqueInput"
	},
	FileCreateWithoutWorkspaceInput:{
		meta:"FileMetaCreateInput",
		status:"FileStatus",
		updatedAt:"DateTime",
		createdAt:"DateTime",
		updatedBy:"UserCreateNestedOneWithoutUpdatedFilesInput",
		createdBy:"UserCreateNestedOneWithoutCreatedFilesInput",
		bookings:"BookingCreateNestedManyWithoutAssetsInput",
		proofBooking:"BookingCreateNestedOneWithoutProofsInput",
		campaigns:"CampaignCreateNestedManyWithoutAssetsInput"
	},
	CampaignCreateNestedManyWithoutAssetsInput:{
		create:"CampaignCreateWithoutAssetsInput",
		connectOrCreate:"CampaignCreateOrConnectWithoutAssetsInput",
		connect:"CampaignWhereUniqueInput"
	},
	CampaignCreateWithoutAssetsInput:{
		createdAt:"DateTime",
		updatedAt:"DateTime",
		payment:"BookingPaymentCreateInput",
		paymentStatus:"PaymentStatus",
		priceSummary:"PriceSummaryCreateInput",
		selectedDates:"OrderSelectedDateCreateInput",
		user:"UserCreateNestedOneWithoutCampaignsInput",
		businessCategory:"BusinessCategoryCreateNestedOneWithoutCampaignsInput",
		bookings:"BookingCreateNestedManyWithoutCampaignInput",
		payments:"PaymentCreateNestedManyWithoutCampaignInput"
	},
	BookingCreateNestedManyWithoutCampaignInput:{
		create:"BookingCreateWithoutCampaignInput",
		connectOrCreate:"BookingCreateOrConnectWithoutCampaignInput",
		createMany:"BookingCreateManyCampaignInputEnvelope",
		connect:"BookingWhereUniqueInput"
	},
	BookingCreateWithoutCampaignInput:{
		bookingDate:"DateTime",
		bookingDetails:"BookingDetailsCreateInput",
		bookingStatus:"BookingStatus",
		paymentStatus:"PaymentStatus",
		deploymentStatus:"DeploymentStatus",
		contentApprovalStatus:"ContentApprovalStatus",
		selectedDates:"OrderSelectedDateCreateInput",
		payment:"BookingPaymentCreateInput",
		priceSummary:"PriceSummaryCreateInput",
		pisignage:"BookingPisignageDataCreateInput",
		refund:"BookingRefundCreateInput",
		assets:"FileCreateNestedManyWithoutBookingsInput",
		businessCategory:"BusinessCategoryCreateNestedOneWithoutBookingsInput",
		proofs:"FileCreateNestedManyWithoutProofBookingInput",
		user:"UserCreateNestedOneWithoutBookingsInput",
		adBoard:"AdBoardCreateNestedOneWithoutBookingsInput",
		changelogs:"ChangelogCreateNestedManyWithoutBookingInput",
		workspace:"WorkspaceCreateNestedOneWithoutBookingsInput",
		payouts:"PayoutCreateNestedManyWithoutBookingInput",
		sharedUsers:"UserCreateNestedManyWithoutSharedBookingsInput",
		webhookLogs:"WebhookLogCreateNestedManyWithoutBookingInput",
		slotUsage:"SlotUsageCreateNestedManyWithoutBookingInput"
	},
	PayoutCreateNestedManyWithoutBookingInput:{
		create:"PayoutCreateWithoutBookingInput",
		connectOrCreate:"PayoutCreateOrConnectWithoutBookingInput",
		createMany:"PayoutCreateManyBookingInputEnvelope",
		connect:"PayoutWhereUniqueInput"
	},
	PayoutCreateWithoutBookingInput:{
		createdAt:"DateTime",
		status:"PayoutStatus",
		transaction:"TransactionCreateNestedOneWithoutPayoutsInput"
	},
	TransactionCreateNestedOneWithoutPayoutsInput:{
		create:"TransactionCreateWithoutPayoutsInput",
		connectOrCreate:"TransactionCreateOrConnectWithoutPayoutsInput",
		connect:"TransactionWhereUniqueInput"
	},
	TransactionCreateWithoutPayoutsInput:{
		createAt:"DateTime",
		processedAt:"DateTime",
		transactionStatus:"TransactionStatus",
		details:"JSON",
		workspace:"WorkspaceCreateNestedOneWithoutTransactionsInput"
	},
	WorkspaceCreateNestedOneWithoutTransactionsInput:{
		create:"WorkspaceCreateWithoutTransactionsInput",
		connectOrCreate:"WorkspaceCreateOrConnectWithoutTransactionsInput",
		connect:"WorkspaceWhereUniqueInput"
	},
	WorkspaceCreateWithoutTransactionsInput:{
		type:"WorkspaceType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		owner:"UserCreateNestedOneWithoutOwnedWorkspacesInput",
		memberships:"MembershipCreateNestedManyWithoutWorkspaceInput",
		adBoards:"AdBoardCreateNestedManyWithoutWorkspaceInput",
		playlists:"PlaylistCreateNestedManyWithoutWorkspaceInput",
		adBoardGroups:"AdBoardGroupCreateNestedManyWithoutWorkspaceInput",
		audits:"AuditCreateNestedManyWithoutWorkspaceInput",
		bookings:"BookingCreateNestedManyWithoutWorkspaceInput",
		files:"FileCreateNestedManyWithoutWorkspaceInput",
		players:"PlayerCreateNestedManyWithoutWorkspaceInput",
		activeSubscription:"SubscriptionCreateNestedOneWithoutActiveWorkspaceInput",
		cameraServers:"CameraServerCreateNestedManyWithoutWorkspaceInput",
		workspaceRoles:"WorkspaceRoleCreateNestedManyWithoutWorkspaceInput",
		webhookLogs:"WebhookLogCreateNestedManyWithoutWorkspaceInput",
		leads:"UserCreateNestedManyWithoutLeadWorspacesInput"
	},
	PlayerCreateNestedManyWithoutWorkspaceInput:{
		create:"PlayerCreateWithoutWorkspaceInput",
		connectOrCreate:"PlayerCreateOrConnectWithoutWorkspaceInput",
		createMany:"PlayerCreateManyWorkspaceInputEnvelope",
		connect:"PlayerWhereUniqueInput"
	},
	PlayerCreateWithoutWorkspaceInput:{
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackDetails:"PlaybackTimeCreateInput",
		aspectRatioType:"AspectRatioType",
		map:"MapCreateInput",
		adBoardGroup:"AdBoardGroupCreateNestedOneWithoutPisignagePlayersInput",
		status:"PlayerUptimeStatusCreateNestedManyWithoutPlayerInput",
		cameras:"CameraCreateNestedManyWithoutPlayerInput",
		adBoards:"AdBoardCreateNestedManyWithoutPlayersInput"
	},
	PlayerUptimeStatusCreateNestedManyWithoutPlayerInput:{
		create:"PlayerUptimeStatusCreateWithoutPlayerInput",
		connectOrCreate:"PlayerUptimeStatusCreateOrConnectWithoutPlayerInput",
		createMany:"PlayerUptimeStatusCreateManyPlayerInputEnvelope",
		connect:"PlayerUptimeStatusWhereUniqueInput"
	},
	PlayerUptimeStatusCreateWithoutPlayerInput:{

	},
	PlayerUptimeStatusCreateOrConnectWithoutPlayerInput:{
		where:"PlayerUptimeStatusWhereUniqueInput",
		create:"PlayerUptimeStatusCreateWithoutPlayerInput"
	},
	PlayerUptimeStatusCreateManyPlayerInputEnvelope:{
		data:"PlayerUptimeStatusCreateManyPlayerInput"
	},
	PlayerUptimeStatusCreateManyPlayerInput:{

	},
	CameraCreateNestedManyWithoutPlayerInput:{
		create:"CameraCreateWithoutPlayerInput",
		connectOrCreate:"CameraCreateOrConnectWithoutPlayerInput",
		createMany:"CameraCreateManyPlayerInputEnvelope",
		connect:"CameraWhereUniqueInput"
	},
	CameraCreateWithoutPlayerInput:{
		updatedAt:"DateTime",
		cameraServer:"CameraServerCreateNestedOneWithoutCamerasInput",
		feeds:"CameraFeedCreateNestedManyWithoutCameraInput",
		adBoard:"AdBoardCreateNestedOneWithoutCamerasInput"
	},
	CameraServerCreateNestedOneWithoutCamerasInput:{
		create:"CameraServerCreateWithoutCamerasInput",
		connectOrCreate:"CameraServerCreateOrConnectWithoutCamerasInput",
		connect:"CameraServerWhereUniqueInput"
	},
	CameraServerCreateWithoutCamerasInput:{
		workspace:"WorkspaceCreateNestedOneWithoutCameraServersInput",
		feeds:"CameraFeedCreateNestedManyWithoutCameraServerInput"
	},
	WorkspaceCreateNestedOneWithoutCameraServersInput:{
		create:"WorkspaceCreateWithoutCameraServersInput",
		connectOrCreate:"WorkspaceCreateOrConnectWithoutCameraServersInput",
		connect:"WorkspaceWhereUniqueInput"
	},
	WorkspaceCreateWithoutCameraServersInput:{
		type:"WorkspaceType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		owner:"UserCreateNestedOneWithoutOwnedWorkspacesInput",
		memberships:"MembershipCreateNestedManyWithoutWorkspaceInput",
		adBoards:"AdBoardCreateNestedManyWithoutWorkspaceInput",
		playlists:"PlaylistCreateNestedManyWithoutWorkspaceInput",
		adBoardGroups:"AdBoardGroupCreateNestedManyWithoutWorkspaceInput",
		audits:"AuditCreateNestedManyWithoutWorkspaceInput",
		bookings:"BookingCreateNestedManyWithoutWorkspaceInput",
		files:"FileCreateNestedManyWithoutWorkspaceInput",
		players:"PlayerCreateNestedManyWithoutWorkspaceInput",
		transactions:"TransactionCreateNestedManyWithoutWorkspaceInput",
		activeSubscription:"SubscriptionCreateNestedOneWithoutActiveWorkspaceInput",
		workspaceRoles:"WorkspaceRoleCreateNestedManyWithoutWorkspaceInput",
		webhookLogs:"WebhookLogCreateNestedManyWithoutWorkspaceInput",
		leads:"UserCreateNestedManyWithoutLeadWorspacesInput"
	},
	TransactionCreateNestedManyWithoutWorkspaceInput:{
		create:"TransactionCreateWithoutWorkspaceInput",
		connectOrCreate:"TransactionCreateOrConnectWithoutWorkspaceInput",
		createMany:"TransactionCreateManyWorkspaceInputEnvelope",
		connect:"TransactionWhereUniqueInput"
	},
	TransactionCreateWithoutWorkspaceInput:{
		createAt:"DateTime",
		processedAt:"DateTime",
		transactionStatus:"TransactionStatus",
		details:"JSON",
		payouts:"PayoutCreateNestedManyWithoutTransactionInput"
	},
	PayoutCreateNestedManyWithoutTransactionInput:{
		create:"PayoutCreateWithoutTransactionInput",
		connectOrCreate:"PayoutCreateOrConnectWithoutTransactionInput",
		createMany:"PayoutCreateManyTransactionInputEnvelope",
		connect:"PayoutWhereUniqueInput"
	},
	PayoutCreateWithoutTransactionInput:{
		createdAt:"DateTime",
		status:"PayoutStatus",
		booking:"BookingCreateNestedOneWithoutPayoutsInput"
	},
	BookingCreateNestedOneWithoutPayoutsInput:{
		create:"BookingCreateWithoutPayoutsInput",
		connectOrCreate:"BookingCreateOrConnectWithoutPayoutsInput",
		connect:"BookingWhereUniqueInput"
	},
	BookingCreateWithoutPayoutsInput:{
		bookingDate:"DateTime",
		bookingDetails:"BookingDetailsCreateInput",
		bookingStatus:"BookingStatus",
		paymentStatus:"PaymentStatus",
		deploymentStatus:"DeploymentStatus",
		contentApprovalStatus:"ContentApprovalStatus",
		selectedDates:"OrderSelectedDateCreateInput",
		payment:"BookingPaymentCreateInput",
		priceSummary:"PriceSummaryCreateInput",
		pisignage:"BookingPisignageDataCreateInput",
		refund:"BookingRefundCreateInput",
		assets:"FileCreateNestedManyWithoutBookingsInput",
		businessCategory:"BusinessCategoryCreateNestedOneWithoutBookingsInput",
		proofs:"FileCreateNestedManyWithoutProofBookingInput",
		user:"UserCreateNestedOneWithoutBookingsInput",
		adBoard:"AdBoardCreateNestedOneWithoutBookingsInput",
		changelogs:"ChangelogCreateNestedManyWithoutBookingInput",
		workspace:"WorkspaceCreateNestedOneWithoutBookingsInput",
		campaign:"CampaignCreateNestedOneWithoutBookingsInput",
		sharedUsers:"UserCreateNestedManyWithoutSharedBookingsInput",
		webhookLogs:"WebhookLogCreateNestedManyWithoutBookingInput",
		slotUsage:"SlotUsageCreateNestedManyWithoutBookingInput"
	},
	UserCreateNestedManyWithoutSharedBookingsInput:{
		create:"UserCreateWithoutSharedBookingsInput",
		connectOrCreate:"UserCreateOrConnectWithoutSharedBookingsInput",
		connect:"UserWhereUniqueInput"
	},
	UserCreateWithoutSharedBookingsInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingCreateNestedManyWithoutUserInput",
		createdFiles:"FileCreateNestedManyWithoutCreatedByInput",
		updatedFiles:"FileCreateNestedManyWithoutUpdatedByInput",
		orders:"OrderCreateNestedManyWithoutCreatedByInput",
		changelogs:"ChangelogCreateNestedManyWithoutUserInput",
		adBoards:"AdBoardCreateNestedManyWithoutCreatedByInput",
		audits:"AuditCreateNestedManyWithoutUserInput",
		campaigns:"CampaignCreateNestedManyWithoutUserInput",
		memberships:"MembershipCreateNestedManyWithoutUserInput",
		notifications:"NotificationCreateNestedManyWithoutRecipientInput",
		publishAdboardVersion:"AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput",
		offlineBookingAdboards:"AdBoardCreateNestedManyWithoutOfflineBookingsContactInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutUserInput",
		archivedAdboards:"AdBoardCreateNestedManyWithoutArchivedByInput",
		ownedWorkspaces:"WorkspaceCreateNestedManyWithoutOwnerInput",
		leadWorspaces:"WorkspaceCreateNestedManyWithoutLeadsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutUserInput",
		userPositions:"UserPositionCreateNestedManyWithoutUserInput"
	},
	AdBoardCreateNestedManyWithoutOfflineBookingsContactInput:{
		create:"AdBoardCreateWithoutOfflineBookingsContactInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutOfflineBookingsContactInput",
		createMany:"AdBoardCreateManyOfflineBookingsContactInputEnvelope",
		connect:"AdBoardWhereUniqueInput"
	},
	AdBoardCreateWithoutOfflineBookingsContactInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryCreateNestedOneWithoutAdBoardsInput",
		locationSubCategory:"LocationSubCategoryCreateNestedOneWithoutAdBoardsInput",
		type:"AdBoardTypeCreateNestedOneWithoutAdBoardsInput",
		category:"BusinessCategoryCreateNestedOneWithoutAdBoardsInput",
		restrictedBusines:"BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput",
		bookings:"BookingCreateNestedManyWithoutAdBoardInput",
		orders:"OrderCreateNestedManyWithoutAdBoardInput",
		slotConfigurations:"SlotConfigurationCreateNestedManyWithoutAdBoardInput",
		createdBy:"UserCreateNestedOneWithoutAdBoardsInput",
		workspace:"WorkspaceCreateNestedOneWithoutAdBoardsInput",
		players:"PlayerCreateNestedManyWithoutAdBoardsInput",
		adBoardGroup:"AdBoardGroupCreateNestedOneWithoutAdBoardInput",
		cameras:"CameraCreateNestedManyWithoutAdBoardInput",
		publishedVersion:"AdBoardCreateNestedOneWithoutDraftAdboardInput",
		draftAdboard:"AdBoardCreateNestedOneWithoutPublishedVersionInput",
		history:"AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput",
		archivedBy:"UserCreateNestedOneWithoutArchivedAdboardsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"
	},
	CameraCreateNestedManyWithoutAdBoardInput:{
		create:"CameraCreateWithoutAdBoardInput",
		connectOrCreate:"CameraCreateOrConnectWithoutAdBoardInput",
		createMany:"CameraCreateManyAdBoardInputEnvelope",
		connect:"CameraWhereUniqueInput"
	},
	CameraCreateWithoutAdBoardInput:{
		updatedAt:"DateTime",
		cameraServer:"CameraServerCreateNestedOneWithoutCamerasInput",
		player:"PlayerCreateNestedOneWithoutCamerasInput",
		feeds:"CameraFeedCreateNestedManyWithoutCameraInput"
	},
	PlayerCreateNestedOneWithoutCamerasInput:{
		create:"PlayerCreateWithoutCamerasInput",
		connectOrCreate:"PlayerCreateOrConnectWithoutCamerasInput",
		connect:"PlayerWhereUniqueInput"
	},
	PlayerCreateWithoutCamerasInput:{
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackDetails:"PlaybackTimeCreateInput",
		aspectRatioType:"AspectRatioType",
		map:"MapCreateInput",
		adBoardGroup:"AdBoardGroupCreateNestedOneWithoutPisignagePlayersInput",
		workspace:"WorkspaceCreateNestedOneWithoutPlayersInput",
		status:"PlayerUptimeStatusCreateNestedManyWithoutPlayerInput",
		adBoards:"AdBoardCreateNestedManyWithoutPlayersInput"
	},
	AdBoardCreateNestedManyWithoutPlayersInput:{
		create:"AdBoardCreateWithoutPlayersInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutPlayersInput",
		connect:"AdBoardWhereUniqueInput"
	},
	AdBoardCreateWithoutPlayersInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryCreateNestedOneWithoutAdBoardsInput",
		locationSubCategory:"LocationSubCategoryCreateNestedOneWithoutAdBoardsInput",
		type:"AdBoardTypeCreateNestedOneWithoutAdBoardsInput",
		category:"BusinessCategoryCreateNestedOneWithoutAdBoardsInput",
		restrictedBusines:"BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput",
		bookings:"BookingCreateNestedManyWithoutAdBoardInput",
		orders:"OrderCreateNestedManyWithoutAdBoardInput",
		slotConfigurations:"SlotConfigurationCreateNestedManyWithoutAdBoardInput",
		offlineBookingsContact:"UserCreateNestedOneWithoutOfflineBookingAdboardsInput",
		createdBy:"UserCreateNestedOneWithoutAdBoardsInput",
		workspace:"WorkspaceCreateNestedOneWithoutAdBoardsInput",
		adBoardGroup:"AdBoardGroupCreateNestedOneWithoutAdBoardInput",
		cameras:"CameraCreateNestedManyWithoutAdBoardInput",
		publishedVersion:"AdBoardCreateNestedOneWithoutDraftAdboardInput",
		draftAdboard:"AdBoardCreateNestedOneWithoutPublishedVersionInput",
		history:"AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput",
		archivedBy:"UserCreateNestedOneWithoutArchivedAdboardsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"
	},
	AdBoardCreateNestedOneWithoutDraftAdboardInput:{
		create:"AdBoardCreateWithoutDraftAdboardInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutDraftAdboardInput",
		connect:"AdBoardWhereUniqueInput"
	},
	AdBoardCreateWithoutDraftAdboardInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryCreateNestedOneWithoutAdBoardsInput",
		locationSubCategory:"LocationSubCategoryCreateNestedOneWithoutAdBoardsInput",
		type:"AdBoardTypeCreateNestedOneWithoutAdBoardsInput",
		category:"BusinessCategoryCreateNestedOneWithoutAdBoardsInput",
		restrictedBusines:"BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput",
		bookings:"BookingCreateNestedManyWithoutAdBoardInput",
		orders:"OrderCreateNestedManyWithoutAdBoardInput",
		slotConfigurations:"SlotConfigurationCreateNestedManyWithoutAdBoardInput",
		offlineBookingsContact:"UserCreateNestedOneWithoutOfflineBookingAdboardsInput",
		createdBy:"UserCreateNestedOneWithoutAdBoardsInput",
		workspace:"WorkspaceCreateNestedOneWithoutAdBoardsInput",
		players:"PlayerCreateNestedManyWithoutAdBoardsInput",
		adBoardGroup:"AdBoardGroupCreateNestedOneWithoutAdBoardInput",
		cameras:"CameraCreateNestedManyWithoutAdBoardInput",
		publishedVersion:"AdBoardCreateNestedOneWithoutDraftAdboardInput",
		history:"AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput",
		archivedBy:"UserCreateNestedOneWithoutArchivedAdboardsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"
	},
	AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput:{
		create:"AdBoardPublishHistoryCreateWithoutAdBoardInput",
		connectOrCreate:"AdBoardPublishHistoryCreateOrConnectWithoutAdBoardInput",
		createMany:"AdBoardPublishHistoryCreateManyAdBoardInputEnvelope",
		connect:"AdBoardPublishHistoryWhereUniqueInput"
	},
	AdBoardPublishHistoryCreateWithoutAdBoardInput:{
		publishedAt:"DateTime",
		changesSnapshot:"JSON",
		publishedBy:"UserCreateNestedOneWithoutPublishAdboardVersionInput"
	},
	UserCreateNestedOneWithoutPublishAdboardVersionInput:{
		create:"UserCreateWithoutPublishAdboardVersionInput",
		connectOrCreate:"UserCreateOrConnectWithoutPublishAdboardVersionInput",
		connect:"UserWhereUniqueInput"
	},
	UserCreateWithoutPublishAdboardVersionInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingCreateNestedManyWithoutUserInput",
		createdFiles:"FileCreateNestedManyWithoutCreatedByInput",
		updatedFiles:"FileCreateNestedManyWithoutUpdatedByInput",
		orders:"OrderCreateNestedManyWithoutCreatedByInput",
		changelogs:"ChangelogCreateNestedManyWithoutUserInput",
		adBoards:"AdBoardCreateNestedManyWithoutCreatedByInput",
		audits:"AuditCreateNestedManyWithoutUserInput",
		campaigns:"CampaignCreateNestedManyWithoutUserInput",
		sharedBookings:"BookingCreateNestedManyWithoutSharedUsersInput",
		memberships:"MembershipCreateNestedManyWithoutUserInput",
		notifications:"NotificationCreateNestedManyWithoutRecipientInput",
		offlineBookingAdboards:"AdBoardCreateNestedManyWithoutOfflineBookingsContactInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutUserInput",
		archivedAdboards:"AdBoardCreateNestedManyWithoutArchivedByInput",
		ownedWorkspaces:"WorkspaceCreateNestedManyWithoutOwnerInput",
		leadWorspaces:"WorkspaceCreateNestedManyWithoutLeadsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutUserInput",
		userPositions:"UserPositionCreateNestedManyWithoutUserInput"
	},
	AdBoardReviewCommentCreateNestedManyWithoutUserInput:{
		create:"AdBoardReviewCommentCreateWithoutUserInput",
		connectOrCreate:"AdBoardReviewCommentCreateOrConnectWithoutUserInput",
		createMany:"AdBoardReviewCommentCreateManyUserInputEnvelope",
		connect:"AdBoardReviewCommentWhereUniqueInput"
	},
	AdBoardReviewCommentCreateWithoutUserInput:{
		action:"ReviewAction",
		createdAt:"DateTime",
		adBoard:"AdBoardCreateNestedOneWithoutReviewCommentsInput"
	},
	AdBoardCreateNestedOneWithoutReviewCommentsInput:{
		create:"AdBoardCreateWithoutReviewCommentsInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutReviewCommentsInput",
		connect:"AdBoardWhereUniqueInput"
	},
	AdBoardCreateWithoutReviewCommentsInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryCreateNestedOneWithoutAdBoardsInput",
		locationSubCategory:"LocationSubCategoryCreateNestedOneWithoutAdBoardsInput",
		type:"AdBoardTypeCreateNestedOneWithoutAdBoardsInput",
		category:"BusinessCategoryCreateNestedOneWithoutAdBoardsInput",
		restrictedBusines:"BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput",
		bookings:"BookingCreateNestedManyWithoutAdBoardInput",
		orders:"OrderCreateNestedManyWithoutAdBoardInput",
		slotConfigurations:"SlotConfigurationCreateNestedManyWithoutAdBoardInput",
		offlineBookingsContact:"UserCreateNestedOneWithoutOfflineBookingAdboardsInput",
		createdBy:"UserCreateNestedOneWithoutAdBoardsInput",
		workspace:"WorkspaceCreateNestedOneWithoutAdBoardsInput",
		players:"PlayerCreateNestedManyWithoutAdBoardsInput",
		adBoardGroup:"AdBoardGroupCreateNestedOneWithoutAdBoardInput",
		cameras:"CameraCreateNestedManyWithoutAdBoardInput",
		publishedVersion:"AdBoardCreateNestedOneWithoutDraftAdboardInput",
		draftAdboard:"AdBoardCreateNestedOneWithoutPublishedVersionInput",
		history:"AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput",
		archivedBy:"UserCreateNestedOneWithoutArchivedAdboardsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"
	},
	AdBoardCreateNestedOneWithoutPublishedVersionInput:{
		create:"AdBoardCreateWithoutPublishedVersionInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutPublishedVersionInput",
		connect:"AdBoardWhereUniqueInput"
	},
	AdBoardCreateWithoutPublishedVersionInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryCreateNestedOneWithoutAdBoardsInput",
		locationSubCategory:"LocationSubCategoryCreateNestedOneWithoutAdBoardsInput",
		type:"AdBoardTypeCreateNestedOneWithoutAdBoardsInput",
		category:"BusinessCategoryCreateNestedOneWithoutAdBoardsInput",
		restrictedBusines:"BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput",
		bookings:"BookingCreateNestedManyWithoutAdBoardInput",
		orders:"OrderCreateNestedManyWithoutAdBoardInput",
		slotConfigurations:"SlotConfigurationCreateNestedManyWithoutAdBoardInput",
		offlineBookingsContact:"UserCreateNestedOneWithoutOfflineBookingAdboardsInput",
		createdBy:"UserCreateNestedOneWithoutAdBoardsInput",
		workspace:"WorkspaceCreateNestedOneWithoutAdBoardsInput",
		players:"PlayerCreateNestedManyWithoutAdBoardsInput",
		adBoardGroup:"AdBoardGroupCreateNestedOneWithoutAdBoardInput",
		cameras:"CameraCreateNestedManyWithoutAdBoardInput",
		draftAdboard:"AdBoardCreateNestedOneWithoutPublishedVersionInput",
		history:"AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput",
		archivedBy:"UserCreateNestedOneWithoutArchivedAdboardsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"
	},
	AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput:{
		create:"AdBoardReviewCommentCreateWithoutAdBoardInput",
		connectOrCreate:"AdBoardReviewCommentCreateOrConnectWithoutAdBoardInput",
		createMany:"AdBoardReviewCommentCreateManyAdBoardInputEnvelope",
		connect:"AdBoardReviewCommentWhereUniqueInput"
	},
	AdBoardReviewCommentCreateWithoutAdBoardInput:{
		action:"ReviewAction",
		createdAt:"DateTime",
		user:"UserCreateNestedOneWithoutReviewCommentsInput"
	},
	UserCreateNestedOneWithoutReviewCommentsInput:{
		create:"UserCreateWithoutReviewCommentsInput",
		connectOrCreate:"UserCreateOrConnectWithoutReviewCommentsInput",
		connect:"UserWhereUniqueInput"
	},
	UserCreateWithoutReviewCommentsInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingCreateNestedManyWithoutUserInput",
		createdFiles:"FileCreateNestedManyWithoutCreatedByInput",
		updatedFiles:"FileCreateNestedManyWithoutUpdatedByInput",
		orders:"OrderCreateNestedManyWithoutCreatedByInput",
		changelogs:"ChangelogCreateNestedManyWithoutUserInput",
		adBoards:"AdBoardCreateNestedManyWithoutCreatedByInput",
		audits:"AuditCreateNestedManyWithoutUserInput",
		campaigns:"CampaignCreateNestedManyWithoutUserInput",
		sharedBookings:"BookingCreateNestedManyWithoutSharedUsersInput",
		memberships:"MembershipCreateNestedManyWithoutUserInput",
		notifications:"NotificationCreateNestedManyWithoutRecipientInput",
		publishAdboardVersion:"AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput",
		offlineBookingAdboards:"AdBoardCreateNestedManyWithoutOfflineBookingsContactInput",
		archivedAdboards:"AdBoardCreateNestedManyWithoutArchivedByInput",
		ownedWorkspaces:"WorkspaceCreateNestedManyWithoutOwnerInput",
		leadWorspaces:"WorkspaceCreateNestedManyWithoutLeadsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutUserInput",
		userPositions:"UserPositionCreateNestedManyWithoutUserInput"
	},
	AdBoardCreateNestedManyWithoutArchivedByInput:{
		create:"AdBoardCreateWithoutArchivedByInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutArchivedByInput",
		createMany:"AdBoardCreateManyArchivedByInputEnvelope",
		connect:"AdBoardWhereUniqueInput"
	},
	AdBoardCreateWithoutArchivedByInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryCreateNestedOneWithoutAdBoardsInput",
		locationSubCategory:"LocationSubCategoryCreateNestedOneWithoutAdBoardsInput",
		type:"AdBoardTypeCreateNestedOneWithoutAdBoardsInput",
		category:"BusinessCategoryCreateNestedOneWithoutAdBoardsInput",
		restrictedBusines:"BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput",
		bookings:"BookingCreateNestedManyWithoutAdBoardInput",
		orders:"OrderCreateNestedManyWithoutAdBoardInput",
		slotConfigurations:"SlotConfigurationCreateNestedManyWithoutAdBoardInput",
		offlineBookingsContact:"UserCreateNestedOneWithoutOfflineBookingAdboardsInput",
		createdBy:"UserCreateNestedOneWithoutAdBoardsInput",
		workspace:"WorkspaceCreateNestedOneWithoutAdBoardsInput",
		players:"PlayerCreateNestedManyWithoutAdBoardsInput",
		adBoardGroup:"AdBoardGroupCreateNestedOneWithoutAdBoardInput",
		cameras:"CameraCreateNestedManyWithoutAdBoardInput",
		publishedVersion:"AdBoardCreateNestedOneWithoutDraftAdboardInput",
		draftAdboard:"AdBoardCreateNestedOneWithoutPublishedVersionInput",
		history:"AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"
	},
	AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput:{
		create:"AdBoardPublicReviewCreateWithoutAdBoardInput",
		connectOrCreate:"AdBoardPublicReviewCreateOrConnectWithoutAdBoardInput",
		createMany:"AdBoardPublicReviewCreateManyAdBoardInputEnvelope",
		connect:"AdBoardPublicReviewWhereUniqueInput"
	},
	AdBoardPublicReviewCreateWithoutAdBoardInput:{
		createdAt:"DateTime",
		user:"UserCreateNestedOneWithoutPublicReviewsInput"
	},
	UserCreateNestedOneWithoutPublicReviewsInput:{
		create:"UserCreateWithoutPublicReviewsInput",
		connectOrCreate:"UserCreateOrConnectWithoutPublicReviewsInput",
		connect:"UserWhereUniqueInput"
	},
	UserCreateWithoutPublicReviewsInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingCreateNestedManyWithoutUserInput",
		createdFiles:"FileCreateNestedManyWithoutCreatedByInput",
		updatedFiles:"FileCreateNestedManyWithoutUpdatedByInput",
		orders:"OrderCreateNestedManyWithoutCreatedByInput",
		changelogs:"ChangelogCreateNestedManyWithoutUserInput",
		adBoards:"AdBoardCreateNestedManyWithoutCreatedByInput",
		audits:"AuditCreateNestedManyWithoutUserInput",
		campaigns:"CampaignCreateNestedManyWithoutUserInput",
		sharedBookings:"BookingCreateNestedManyWithoutSharedUsersInput",
		memberships:"MembershipCreateNestedManyWithoutUserInput",
		notifications:"NotificationCreateNestedManyWithoutRecipientInput",
		publishAdboardVersion:"AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput",
		offlineBookingAdboards:"AdBoardCreateNestedManyWithoutOfflineBookingsContactInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutUserInput",
		archivedAdboards:"AdBoardCreateNestedManyWithoutArchivedByInput",
		ownedWorkspaces:"WorkspaceCreateNestedManyWithoutOwnerInput",
		leadWorspaces:"WorkspaceCreateNestedManyWithoutLeadsInput",
		userPositions:"UserPositionCreateNestedManyWithoutUserInput"
	},
	WorkspaceCreateNestedManyWithoutOwnerInput:{
		create:"WorkspaceCreateWithoutOwnerInput",
		connectOrCreate:"WorkspaceCreateOrConnectWithoutOwnerInput",
		createMany:"WorkspaceCreateManyOwnerInputEnvelope",
		connect:"WorkspaceWhereUniqueInput"
	},
	WorkspaceCreateWithoutOwnerInput:{
		type:"WorkspaceType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		memberships:"MembershipCreateNestedManyWithoutWorkspaceInput",
		adBoards:"AdBoardCreateNestedManyWithoutWorkspaceInput",
		playlists:"PlaylistCreateNestedManyWithoutWorkspaceInput",
		adBoardGroups:"AdBoardGroupCreateNestedManyWithoutWorkspaceInput",
		audits:"AuditCreateNestedManyWithoutWorkspaceInput",
		bookings:"BookingCreateNestedManyWithoutWorkspaceInput",
		files:"FileCreateNestedManyWithoutWorkspaceInput",
		players:"PlayerCreateNestedManyWithoutWorkspaceInput",
		transactions:"TransactionCreateNestedManyWithoutWorkspaceInput",
		activeSubscription:"SubscriptionCreateNestedOneWithoutActiveWorkspaceInput",
		cameraServers:"CameraServerCreateNestedManyWithoutWorkspaceInput",
		workspaceRoles:"WorkspaceRoleCreateNestedManyWithoutWorkspaceInput",
		webhookLogs:"WebhookLogCreateNestedManyWithoutWorkspaceInput",
		leads:"UserCreateNestedManyWithoutLeadWorspacesInput"
	},
	SubscriptionCreateNestedOneWithoutActiveWorkspaceInput:{
		create:"SubscriptionCreateWithoutActiveWorkspaceInput",
		connectOrCreate:"SubscriptionCreateOrConnectWithoutActiveWorkspaceInput",
		connect:"SubscriptionWhereUniqueInput"
	},
	SubscriptionCreateWithoutActiveWorkspaceInput:{
		startDate:"DateTime",
		endDate:"DateTime",
		cancellationDetails:"CancellationDetailsCreateInput"
	},
	CancellationDetailsCreateInput:{
		canceledAt:"DateTime",
		cancelAt:"DateTime"
	},
	SubscriptionCreateOrConnectWithoutActiveWorkspaceInput:{
		where:"SubscriptionWhereUniqueInput",
		create:"SubscriptionCreateWithoutActiveWorkspaceInput"
	},
	CameraServerCreateNestedManyWithoutWorkspaceInput:{
		create:"CameraServerCreateWithoutWorkspaceInput",
		connectOrCreate:"CameraServerCreateOrConnectWithoutWorkspaceInput",
		createMany:"CameraServerCreateManyWorkspaceInputEnvelope",
		connect:"CameraServerWhereUniqueInput"
	},
	CameraServerCreateWithoutWorkspaceInput:{
		cameras:"CameraCreateNestedManyWithoutCameraServerInput",
		feeds:"CameraFeedCreateNestedManyWithoutCameraServerInput"
	},
	CameraCreateNestedManyWithoutCameraServerInput:{
		create:"CameraCreateWithoutCameraServerInput",
		connectOrCreate:"CameraCreateOrConnectWithoutCameraServerInput",
		createMany:"CameraCreateManyCameraServerInputEnvelope",
		connect:"CameraWhereUniqueInput"
	},
	CameraCreateWithoutCameraServerInput:{
		updatedAt:"DateTime",
		player:"PlayerCreateNestedOneWithoutCamerasInput",
		feeds:"CameraFeedCreateNestedManyWithoutCameraInput",
		adBoard:"AdBoardCreateNestedOneWithoutCamerasInput"
	},
	CameraFeedCreateNestedManyWithoutCameraInput:{
		create:"CameraFeedCreateWithoutCameraInput",
		connectOrCreate:"CameraFeedCreateOrConnectWithoutCameraInput",
		createMany:"CameraFeedCreateManyCameraInputEnvelope",
		connect:"CameraFeedWhereUniqueInput"
	},
	CameraFeedCreateWithoutCameraInput:{
		timestamp:"DateTime",
		data:"CameraFeedDataCreateInput",
		cameraServer:"CameraServerCreateNestedOneWithoutFeedsInput"
	},
	CameraFeedDataCreateInput:{

	},
	CameraServerCreateNestedOneWithoutFeedsInput:{
		create:"CameraServerCreateWithoutFeedsInput",
		connectOrCreate:"CameraServerCreateOrConnectWithoutFeedsInput",
		connect:"CameraServerWhereUniqueInput"
	},
	CameraServerCreateWithoutFeedsInput:{
		workspace:"WorkspaceCreateNestedOneWithoutCameraServersInput",
		cameras:"CameraCreateNestedManyWithoutCameraServerInput"
	},
	CameraServerCreateOrConnectWithoutFeedsInput:{
		where:"CameraServerWhereUniqueInput",
		create:"CameraServerCreateWithoutFeedsInput"
	},
	CameraFeedCreateOrConnectWithoutCameraInput:{
		where:"CameraFeedWhereUniqueInput",
		create:"CameraFeedCreateWithoutCameraInput"
	},
	CameraFeedCreateManyCameraInputEnvelope:{
		data:"CameraFeedCreateManyCameraInput"
	},
	CameraFeedCreateManyCameraInput:{
		timestamp:"DateTime",
		data:"CameraFeedDataCreateInput"
	},
	AdBoardCreateNestedOneWithoutCamerasInput:{
		create:"AdBoardCreateWithoutCamerasInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutCamerasInput",
		connect:"AdBoardWhereUniqueInput"
	},
	AdBoardCreateWithoutCamerasInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryCreateNestedOneWithoutAdBoardsInput",
		locationSubCategory:"LocationSubCategoryCreateNestedOneWithoutAdBoardsInput",
		type:"AdBoardTypeCreateNestedOneWithoutAdBoardsInput",
		category:"BusinessCategoryCreateNestedOneWithoutAdBoardsInput",
		restrictedBusines:"BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput",
		bookings:"BookingCreateNestedManyWithoutAdBoardInput",
		orders:"OrderCreateNestedManyWithoutAdBoardInput",
		slotConfigurations:"SlotConfigurationCreateNestedManyWithoutAdBoardInput",
		offlineBookingsContact:"UserCreateNestedOneWithoutOfflineBookingAdboardsInput",
		createdBy:"UserCreateNestedOneWithoutAdBoardsInput",
		workspace:"WorkspaceCreateNestedOneWithoutAdBoardsInput",
		players:"PlayerCreateNestedManyWithoutAdBoardsInput",
		adBoardGroup:"AdBoardGroupCreateNestedOneWithoutAdBoardInput",
		publishedVersion:"AdBoardCreateNestedOneWithoutDraftAdboardInput",
		draftAdboard:"AdBoardCreateNestedOneWithoutPublishedVersionInput",
		history:"AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput",
		archivedBy:"UserCreateNestedOneWithoutArchivedAdboardsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"
	},
	UserCreateNestedOneWithoutArchivedAdboardsInput:{
		create:"UserCreateWithoutArchivedAdboardsInput",
		connectOrCreate:"UserCreateOrConnectWithoutArchivedAdboardsInput",
		connect:"UserWhereUniqueInput"
	},
	UserCreateWithoutArchivedAdboardsInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingCreateNestedManyWithoutUserInput",
		createdFiles:"FileCreateNestedManyWithoutCreatedByInput",
		updatedFiles:"FileCreateNestedManyWithoutUpdatedByInput",
		orders:"OrderCreateNestedManyWithoutCreatedByInput",
		changelogs:"ChangelogCreateNestedManyWithoutUserInput",
		adBoards:"AdBoardCreateNestedManyWithoutCreatedByInput",
		audits:"AuditCreateNestedManyWithoutUserInput",
		campaigns:"CampaignCreateNestedManyWithoutUserInput",
		sharedBookings:"BookingCreateNestedManyWithoutSharedUsersInput",
		memberships:"MembershipCreateNestedManyWithoutUserInput",
		notifications:"NotificationCreateNestedManyWithoutRecipientInput",
		publishAdboardVersion:"AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput",
		offlineBookingAdboards:"AdBoardCreateNestedManyWithoutOfflineBookingsContactInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutUserInput",
		ownedWorkspaces:"WorkspaceCreateNestedManyWithoutOwnerInput",
		leadWorspaces:"WorkspaceCreateNestedManyWithoutLeadsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutUserInput",
		userPositions:"UserPositionCreateNestedManyWithoutUserInput"
	},
	WorkspaceCreateNestedManyWithoutLeadsInput:{
		create:"WorkspaceCreateWithoutLeadsInput",
		connectOrCreate:"WorkspaceCreateOrConnectWithoutLeadsInput",
		connect:"WorkspaceWhereUniqueInput"
	},
	WorkspaceCreateWithoutLeadsInput:{
		type:"WorkspaceType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		owner:"UserCreateNestedOneWithoutOwnedWorkspacesInput",
		memberships:"MembershipCreateNestedManyWithoutWorkspaceInput",
		adBoards:"AdBoardCreateNestedManyWithoutWorkspaceInput",
		playlists:"PlaylistCreateNestedManyWithoutWorkspaceInput",
		adBoardGroups:"AdBoardGroupCreateNestedManyWithoutWorkspaceInput",
		audits:"AuditCreateNestedManyWithoutWorkspaceInput",
		bookings:"BookingCreateNestedManyWithoutWorkspaceInput",
		files:"FileCreateNestedManyWithoutWorkspaceInput",
		players:"PlayerCreateNestedManyWithoutWorkspaceInput",
		transactions:"TransactionCreateNestedManyWithoutWorkspaceInput",
		activeSubscription:"SubscriptionCreateNestedOneWithoutActiveWorkspaceInput",
		cameraServers:"CameraServerCreateNestedManyWithoutWorkspaceInput",
		workspaceRoles:"WorkspaceRoleCreateNestedManyWithoutWorkspaceInput",
		webhookLogs:"WebhookLogCreateNestedManyWithoutWorkspaceInput"
	},
	WorkspaceRoleCreateNestedManyWithoutWorkspaceInput:{
		create:"WorkspaceRoleCreateWithoutWorkspaceInput",
		connectOrCreate:"WorkspaceRoleCreateOrConnectWithoutWorkspaceInput",
		createMany:"WorkspaceRoleCreateManyWorkspaceInputEnvelope",
		connect:"WorkspaceRoleWhereUniqueInput"
	},
	WorkspaceRoleCreateWithoutWorkspaceInput:{
		permissions:"Permission",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		memberships:"MembershipCreateNestedManyWithoutRoleInput"
	},
	MembershipCreateNestedManyWithoutRoleInput:{
		create:"MembershipCreateWithoutRoleInput",
		connectOrCreate:"MembershipCreateOrConnectWithoutRoleInput",
		createMany:"MembershipCreateManyRoleInputEnvelope",
		connect:"MembershipWhereUniqueInput"
	},
	MembershipCreateWithoutRoleInput:{
		createdAt:"DateTime",
		user:"UserCreateNestedOneWithoutMembershipsInput",
		workspace:"WorkspaceCreateNestedOneWithoutMembershipsInput"
	},
	MembershipCreateOrConnectWithoutRoleInput:{
		where:"MembershipWhereUniqueInput",
		create:"MembershipCreateWithoutRoleInput"
	},
	MembershipCreateManyRoleInputEnvelope:{
		data:"MembershipCreateManyRoleInput"
	},
	MembershipCreateManyRoleInput:{
		createdAt:"DateTime"
	},
	WorkspaceRoleCreateOrConnectWithoutWorkspaceInput:{
		where:"WorkspaceRoleWhereUniqueInput",
		create:"WorkspaceRoleCreateWithoutWorkspaceInput"
	},
	WorkspaceRoleCreateManyWorkspaceInputEnvelope:{
		data:"WorkspaceRoleCreateManyWorkspaceInput"
	},
	WorkspaceRoleCreateManyWorkspaceInput:{
		permissions:"Permission",
		createdAt:"DateTime",
		updatedAt:"DateTime"
	},
	WebhookLogCreateNestedManyWithoutWorkspaceInput:{
		create:"WebhookLogCreateWithoutWorkspaceInput",
		connectOrCreate:"WebhookLogCreateOrConnectWithoutWorkspaceInput",
		createMany:"WebhookLogCreateManyWorkspaceInputEnvelope",
		connect:"WebhookLogWhereUniqueInput"
	},
	WebhookLogCreateWithoutWorkspaceInput:{
		response:"JSON",
		request:"JSON",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		booking:"BookingCreateNestedOneWithoutWebhookLogsInput"
	},
	BookingCreateNestedOneWithoutWebhookLogsInput:{
		create:"BookingCreateWithoutWebhookLogsInput",
		connectOrCreate:"BookingCreateOrConnectWithoutWebhookLogsInput",
		connect:"BookingWhereUniqueInput"
	},
	BookingCreateWithoutWebhookLogsInput:{
		bookingDate:"DateTime",
		bookingDetails:"BookingDetailsCreateInput",
		bookingStatus:"BookingStatus",
		paymentStatus:"PaymentStatus",
		deploymentStatus:"DeploymentStatus",
		contentApprovalStatus:"ContentApprovalStatus",
		selectedDates:"OrderSelectedDateCreateInput",
		payment:"BookingPaymentCreateInput",
		priceSummary:"PriceSummaryCreateInput",
		pisignage:"BookingPisignageDataCreateInput",
		refund:"BookingRefundCreateInput",
		assets:"FileCreateNestedManyWithoutBookingsInput",
		businessCategory:"BusinessCategoryCreateNestedOneWithoutBookingsInput",
		proofs:"FileCreateNestedManyWithoutProofBookingInput",
		user:"UserCreateNestedOneWithoutBookingsInput",
		adBoard:"AdBoardCreateNestedOneWithoutBookingsInput",
		changelogs:"ChangelogCreateNestedManyWithoutBookingInput",
		workspace:"WorkspaceCreateNestedOneWithoutBookingsInput",
		campaign:"CampaignCreateNestedOneWithoutBookingsInput",
		payouts:"PayoutCreateNestedManyWithoutBookingInput",
		sharedUsers:"UserCreateNestedManyWithoutSharedBookingsInput",
		slotUsage:"SlotUsageCreateNestedManyWithoutBookingInput"
	},
	SlotUsageCreateNestedManyWithoutBookingInput:{
		create:"SlotUsageCreateWithoutBookingInput",
		connectOrCreate:"SlotUsageCreateOrConnectWithoutBookingInput",
		createMany:"SlotUsageCreateManyBookingInputEnvelope",
		connect:"SlotUsageWhereUniqueInput"
	},
	SlotUsageCreateWithoutBookingInput:{
		date:"DateTime"
	},
	SlotUsageCreateOrConnectWithoutBookingInput:{
		where:"SlotUsageWhereUniqueInput",
		create:"SlotUsageCreateWithoutBookingInput"
	},
	SlotUsageCreateManyBookingInputEnvelope:{
		data:"SlotUsageCreateManyBookingInput"
	},
	SlotUsageCreateManyBookingInput:{
		date:"DateTime"
	},
	BookingCreateOrConnectWithoutWebhookLogsInput:{
		where:"BookingWhereUniqueInput",
		create:"BookingCreateWithoutWebhookLogsInput"
	},
	WebhookLogCreateOrConnectWithoutWorkspaceInput:{
		where:"WebhookLogWhereUniqueInput",
		create:"WebhookLogCreateWithoutWorkspaceInput"
	},
	WebhookLogCreateManyWorkspaceInputEnvelope:{
		data:"WebhookLogCreateManyWorkspaceInput"
	},
	WebhookLogCreateManyWorkspaceInput:{
		response:"JSON",
		request:"JSON",
		createdAt:"DateTime",
		updatedAt:"DateTime"
	},
	WorkspaceCreateOrConnectWithoutLeadsInput:{
		where:"WorkspaceWhereUniqueInput",
		create:"WorkspaceCreateWithoutLeadsInput"
	},
	AdBoardPublicReviewCreateNestedManyWithoutUserInput:{
		create:"AdBoardPublicReviewCreateWithoutUserInput",
		connectOrCreate:"AdBoardPublicReviewCreateOrConnectWithoutUserInput",
		createMany:"AdBoardPublicReviewCreateManyUserInputEnvelope",
		connect:"AdBoardPublicReviewWhereUniqueInput"
	},
	AdBoardPublicReviewCreateWithoutUserInput:{
		createdAt:"DateTime",
		adBoard:"AdBoardCreateNestedOneWithoutPublicReviewsInput"
	},
	AdBoardCreateNestedOneWithoutPublicReviewsInput:{
		create:"AdBoardCreateWithoutPublicReviewsInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutPublicReviewsInput",
		connect:"AdBoardWhereUniqueInput"
	},
	AdBoardCreateWithoutPublicReviewsInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryCreateNestedOneWithoutAdBoardsInput",
		locationSubCategory:"LocationSubCategoryCreateNestedOneWithoutAdBoardsInput",
		type:"AdBoardTypeCreateNestedOneWithoutAdBoardsInput",
		category:"BusinessCategoryCreateNestedOneWithoutAdBoardsInput",
		restrictedBusines:"BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput",
		bookings:"BookingCreateNestedManyWithoutAdBoardInput",
		orders:"OrderCreateNestedManyWithoutAdBoardInput",
		slotConfigurations:"SlotConfigurationCreateNestedManyWithoutAdBoardInput",
		offlineBookingsContact:"UserCreateNestedOneWithoutOfflineBookingAdboardsInput",
		createdBy:"UserCreateNestedOneWithoutAdBoardsInput",
		workspace:"WorkspaceCreateNestedOneWithoutAdBoardsInput",
		players:"PlayerCreateNestedManyWithoutAdBoardsInput",
		adBoardGroup:"AdBoardGroupCreateNestedOneWithoutAdBoardInput",
		cameras:"CameraCreateNestedManyWithoutAdBoardInput",
		publishedVersion:"AdBoardCreateNestedOneWithoutDraftAdboardInput",
		draftAdboard:"AdBoardCreateNestedOneWithoutPublishedVersionInput",
		history:"AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput",
		archivedBy:"UserCreateNestedOneWithoutArchivedAdboardsInput"
	},
	AdBoardCreateOrConnectWithoutPublicReviewsInput:{
		where:"AdBoardWhereUniqueInput",
		create:"AdBoardCreateWithoutPublicReviewsInput"
	},
	AdBoardPublicReviewCreateOrConnectWithoutUserInput:{
		where:"AdBoardPublicReviewWhereUniqueInput",
		create:"AdBoardPublicReviewCreateWithoutUserInput"
	},
	AdBoardPublicReviewCreateManyUserInputEnvelope:{
		data:"AdBoardPublicReviewCreateManyUserInput"
	},
	AdBoardPublicReviewCreateManyUserInput:{
		createdAt:"DateTime"
	},
	UserPositionCreateNestedManyWithoutUserInput:{
		create:"UserPositionCreateWithoutUserInput",
		connectOrCreate:"UserPositionCreateOrConnectWithoutUserInput",
		createMany:"UserPositionCreateManyUserInputEnvelope",
		connect:"UserPositionWhereUniqueInput"
	},
	UserPositionCreateWithoutUserInput:{
		timestamp:"DateTime",
		createdAt:"DateTime",
		coords:"UserPositionCoordsCreateInput",
		location:"UserPositionLocationCreateInput"
	},
	UserPositionCoordsCreateInput:{

	},
	UserPositionLocationCreateInput:{

	},
	UserPositionCreateOrConnectWithoutUserInput:{
		where:"UserPositionWhereUniqueInput",
		create:"UserPositionCreateWithoutUserInput"
	},
	UserPositionCreateManyUserInputEnvelope:{
		data:"UserPositionCreateManyUserInput"
	},
	UserPositionCreateManyUserInput:{
		timestamp:"DateTime",
		createdAt:"DateTime",
		coords:"UserPositionCoordsCreateInput",
		location:"UserPositionLocationCreateInput"
	},
	UserCreateOrConnectWithoutArchivedAdboardsInput:{
		where:"UserWhereUniqueInput",
		create:"UserCreateWithoutArchivedAdboardsInput"
	},
	AdBoardCreateOrConnectWithoutCamerasInput:{
		where:"AdBoardWhereUniqueInput",
		create:"AdBoardCreateWithoutCamerasInput"
	},
	CameraCreateOrConnectWithoutCameraServerInput:{
		where:"CameraWhereUniqueInput",
		create:"CameraCreateWithoutCameraServerInput"
	},
	CameraCreateManyCameraServerInputEnvelope:{
		data:"CameraCreateManyCameraServerInput"
	},
	CameraCreateManyCameraServerInput:{
		updatedAt:"DateTime"
	},
	CameraFeedCreateNestedManyWithoutCameraServerInput:{
		create:"CameraFeedCreateWithoutCameraServerInput",
		connectOrCreate:"CameraFeedCreateOrConnectWithoutCameraServerInput",
		createMany:"CameraFeedCreateManyCameraServerInputEnvelope",
		connect:"CameraFeedWhereUniqueInput"
	},
	CameraFeedCreateWithoutCameraServerInput:{
		timestamp:"DateTime",
		data:"CameraFeedDataCreateInput",
		camera:"CameraCreateNestedOneWithoutFeedsInput"
	},
	CameraCreateNestedOneWithoutFeedsInput:{
		create:"CameraCreateWithoutFeedsInput",
		connectOrCreate:"CameraCreateOrConnectWithoutFeedsInput",
		connect:"CameraWhereUniqueInput"
	},
	CameraCreateWithoutFeedsInput:{
		updatedAt:"DateTime",
		cameraServer:"CameraServerCreateNestedOneWithoutCamerasInput",
		player:"PlayerCreateNestedOneWithoutCamerasInput",
		adBoard:"AdBoardCreateNestedOneWithoutCamerasInput"
	},
	CameraCreateOrConnectWithoutFeedsInput:{
		where:"CameraWhereUniqueInput",
		create:"CameraCreateWithoutFeedsInput"
	},
	CameraFeedCreateOrConnectWithoutCameraServerInput:{
		where:"CameraFeedWhereUniqueInput",
		create:"CameraFeedCreateWithoutCameraServerInput"
	},
	CameraFeedCreateManyCameraServerInputEnvelope:{
		data:"CameraFeedCreateManyCameraServerInput"
	},
	CameraFeedCreateManyCameraServerInput:{
		timestamp:"DateTime",
		data:"CameraFeedDataCreateInput"
	},
	CameraServerCreateOrConnectWithoutWorkspaceInput:{
		where:"CameraServerWhereUniqueInput",
		create:"CameraServerCreateWithoutWorkspaceInput"
	},
	CameraServerCreateManyWorkspaceInputEnvelope:{
		data:"CameraServerCreateManyWorkspaceInput"
	},
	CameraServerCreateManyWorkspaceInput:{

	},
	UserCreateNestedManyWithoutLeadWorspacesInput:{
		create:"UserCreateWithoutLeadWorspacesInput",
		connectOrCreate:"UserCreateOrConnectWithoutLeadWorspacesInput",
		connect:"UserWhereUniqueInput"
	},
	UserCreateWithoutLeadWorspacesInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingCreateNestedManyWithoutUserInput",
		createdFiles:"FileCreateNestedManyWithoutCreatedByInput",
		updatedFiles:"FileCreateNestedManyWithoutUpdatedByInput",
		orders:"OrderCreateNestedManyWithoutCreatedByInput",
		changelogs:"ChangelogCreateNestedManyWithoutUserInput",
		adBoards:"AdBoardCreateNestedManyWithoutCreatedByInput",
		audits:"AuditCreateNestedManyWithoutUserInput",
		campaigns:"CampaignCreateNestedManyWithoutUserInput",
		sharedBookings:"BookingCreateNestedManyWithoutSharedUsersInput",
		memberships:"MembershipCreateNestedManyWithoutUserInput",
		notifications:"NotificationCreateNestedManyWithoutRecipientInput",
		publishAdboardVersion:"AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput",
		offlineBookingAdboards:"AdBoardCreateNestedManyWithoutOfflineBookingsContactInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutUserInput",
		archivedAdboards:"AdBoardCreateNestedManyWithoutArchivedByInput",
		ownedWorkspaces:"WorkspaceCreateNestedManyWithoutOwnerInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutUserInput",
		userPositions:"UserPositionCreateNestedManyWithoutUserInput"
	},
	UserCreateOrConnectWithoutLeadWorspacesInput:{
		where:"UserWhereUniqueInput",
		create:"UserCreateWithoutLeadWorspacesInput"
	},
	WorkspaceCreateOrConnectWithoutOwnerInput:{
		where:"WorkspaceWhereUniqueInput",
		create:"WorkspaceCreateWithoutOwnerInput"
	},
	WorkspaceCreateManyOwnerInputEnvelope:{
		data:"WorkspaceCreateManyOwnerInput"
	},
	WorkspaceCreateManyOwnerInput:{
		type:"WorkspaceType",
		createdAt:"DateTime",
		updatedAt:"DateTime"
	},
	UserCreateOrConnectWithoutPublicReviewsInput:{
		where:"UserWhereUniqueInput",
		create:"UserCreateWithoutPublicReviewsInput"
	},
	AdBoardPublicReviewCreateOrConnectWithoutAdBoardInput:{
		where:"AdBoardPublicReviewWhereUniqueInput",
		create:"AdBoardPublicReviewCreateWithoutAdBoardInput"
	},
	AdBoardPublicReviewCreateManyAdBoardInputEnvelope:{
		data:"AdBoardPublicReviewCreateManyAdBoardInput"
	},
	AdBoardPublicReviewCreateManyAdBoardInput:{
		createdAt:"DateTime"
	},
	AdBoardCreateOrConnectWithoutArchivedByInput:{
		where:"AdBoardWhereUniqueInput",
		create:"AdBoardCreateWithoutArchivedByInput"
	},
	AdBoardCreateManyArchivedByInputEnvelope:{
		data:"AdBoardCreateManyArchivedByInput"
	},
	AdBoardCreateManyArchivedByInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime"
	},
	UserCreateOrConnectWithoutReviewCommentsInput:{
		where:"UserWhereUniqueInput",
		create:"UserCreateWithoutReviewCommentsInput"
	},
	AdBoardReviewCommentCreateOrConnectWithoutAdBoardInput:{
		where:"AdBoardReviewCommentWhereUniqueInput",
		create:"AdBoardReviewCommentCreateWithoutAdBoardInput"
	},
	AdBoardReviewCommentCreateManyAdBoardInputEnvelope:{
		data:"AdBoardReviewCommentCreateManyAdBoardInput"
	},
	AdBoardReviewCommentCreateManyAdBoardInput:{
		action:"ReviewAction",
		createdAt:"DateTime"
	},
	AdBoardCreateOrConnectWithoutPublishedVersionInput:{
		where:"AdBoardWhereUniqueInput",
		create:"AdBoardCreateWithoutPublishedVersionInput"
	},
	AdBoardCreateOrConnectWithoutReviewCommentsInput:{
		where:"AdBoardWhereUniqueInput",
		create:"AdBoardCreateWithoutReviewCommentsInput"
	},
	AdBoardReviewCommentCreateOrConnectWithoutUserInput:{
		where:"AdBoardReviewCommentWhereUniqueInput",
		create:"AdBoardReviewCommentCreateWithoutUserInput"
	},
	AdBoardReviewCommentCreateManyUserInputEnvelope:{
		data:"AdBoardReviewCommentCreateManyUserInput"
	},
	AdBoardReviewCommentCreateManyUserInput:{
		action:"ReviewAction",
		createdAt:"DateTime"
	},
	UserCreateOrConnectWithoutPublishAdboardVersionInput:{
		where:"UserWhereUniqueInput",
		create:"UserCreateWithoutPublishAdboardVersionInput"
	},
	AdBoardPublishHistoryCreateOrConnectWithoutAdBoardInput:{
		where:"AdBoardPublishHistoryWhereUniqueInput",
		create:"AdBoardPublishHistoryCreateWithoutAdBoardInput"
	},
	AdBoardPublishHistoryCreateManyAdBoardInputEnvelope:{
		data:"AdBoardPublishHistoryCreateManyAdBoardInput"
	},
	AdBoardPublishHistoryCreateManyAdBoardInput:{
		publishedAt:"DateTime",
		changesSnapshot:"JSON"
	},
	AdBoardCreateOrConnectWithoutDraftAdboardInput:{
		where:"AdBoardWhereUniqueInput",
		create:"AdBoardCreateWithoutDraftAdboardInput"
	},
	AdBoardCreateOrConnectWithoutPlayersInput:{
		where:"AdBoardWhereUniqueInput",
		create:"AdBoardCreateWithoutPlayersInput"
	},
	PlayerCreateOrConnectWithoutCamerasInput:{
		where:"PlayerWhereUniqueInput",
		create:"PlayerCreateWithoutCamerasInput"
	},
	CameraCreateOrConnectWithoutAdBoardInput:{
		where:"CameraWhereUniqueInput",
		create:"CameraCreateWithoutAdBoardInput"
	},
	CameraCreateManyAdBoardInputEnvelope:{
		data:"CameraCreateManyAdBoardInput"
	},
	CameraCreateManyAdBoardInput:{
		updatedAt:"DateTime"
	},
	AdBoardCreateOrConnectWithoutOfflineBookingsContactInput:{
		where:"AdBoardWhereUniqueInput",
		create:"AdBoardCreateWithoutOfflineBookingsContactInput"
	},
	AdBoardCreateManyOfflineBookingsContactInputEnvelope:{
		data:"AdBoardCreateManyOfflineBookingsContactInput"
	},
	AdBoardCreateManyOfflineBookingsContactInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime"
	},
	UserCreateOrConnectWithoutSharedBookingsInput:{
		where:"UserWhereUniqueInput",
		create:"UserCreateWithoutSharedBookingsInput"
	},
	WebhookLogCreateNestedManyWithoutBookingInput:{
		create:"WebhookLogCreateWithoutBookingInput",
		connectOrCreate:"WebhookLogCreateOrConnectWithoutBookingInput",
		createMany:"WebhookLogCreateManyBookingInputEnvelope",
		connect:"WebhookLogWhereUniqueInput"
	},
	WebhookLogCreateWithoutBookingInput:{
		response:"JSON",
		request:"JSON",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		workspace:"WorkspaceCreateNestedOneWithoutWebhookLogsInput"
	},
	WorkspaceCreateNestedOneWithoutWebhookLogsInput:{
		create:"WorkspaceCreateWithoutWebhookLogsInput",
		connectOrCreate:"WorkspaceCreateOrConnectWithoutWebhookLogsInput",
		connect:"WorkspaceWhereUniqueInput"
	},
	WorkspaceCreateWithoutWebhookLogsInput:{
		type:"WorkspaceType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		owner:"UserCreateNestedOneWithoutOwnedWorkspacesInput",
		memberships:"MembershipCreateNestedManyWithoutWorkspaceInput",
		adBoards:"AdBoardCreateNestedManyWithoutWorkspaceInput",
		playlists:"PlaylistCreateNestedManyWithoutWorkspaceInput",
		adBoardGroups:"AdBoardGroupCreateNestedManyWithoutWorkspaceInput",
		audits:"AuditCreateNestedManyWithoutWorkspaceInput",
		bookings:"BookingCreateNestedManyWithoutWorkspaceInput",
		files:"FileCreateNestedManyWithoutWorkspaceInput",
		players:"PlayerCreateNestedManyWithoutWorkspaceInput",
		transactions:"TransactionCreateNestedManyWithoutWorkspaceInput",
		activeSubscription:"SubscriptionCreateNestedOneWithoutActiveWorkspaceInput",
		cameraServers:"CameraServerCreateNestedManyWithoutWorkspaceInput",
		workspaceRoles:"WorkspaceRoleCreateNestedManyWithoutWorkspaceInput",
		leads:"UserCreateNestedManyWithoutLeadWorspacesInput"
	},
	WorkspaceCreateOrConnectWithoutWebhookLogsInput:{
		where:"WorkspaceWhereUniqueInput",
		create:"WorkspaceCreateWithoutWebhookLogsInput"
	},
	WebhookLogCreateOrConnectWithoutBookingInput:{
		where:"WebhookLogWhereUniqueInput",
		create:"WebhookLogCreateWithoutBookingInput"
	},
	WebhookLogCreateManyBookingInputEnvelope:{
		data:"WebhookLogCreateManyBookingInput"
	},
	WebhookLogCreateManyBookingInput:{
		response:"JSON",
		request:"JSON",
		createdAt:"DateTime",
		updatedAt:"DateTime"
	},
	BookingCreateOrConnectWithoutPayoutsInput:{
		where:"BookingWhereUniqueInput",
		create:"BookingCreateWithoutPayoutsInput"
	},
	PayoutCreateOrConnectWithoutTransactionInput:{
		where:"PayoutWhereUniqueInput",
		create:"PayoutCreateWithoutTransactionInput"
	},
	PayoutCreateManyTransactionInputEnvelope:{
		data:"PayoutCreateManyTransactionInput"
	},
	PayoutCreateManyTransactionInput:{
		createdAt:"DateTime",
		status:"PayoutStatus"
	},
	TransactionCreateOrConnectWithoutWorkspaceInput:{
		where:"TransactionWhereUniqueInput",
		create:"TransactionCreateWithoutWorkspaceInput"
	},
	TransactionCreateManyWorkspaceInputEnvelope:{
		data:"TransactionCreateManyWorkspaceInput"
	},
	TransactionCreateManyWorkspaceInput:{
		createAt:"DateTime",
		processedAt:"DateTime",
		transactionStatus:"TransactionStatus",
		details:"JSON"
	},
	WorkspaceCreateOrConnectWithoutCameraServersInput:{
		where:"WorkspaceWhereUniqueInput",
		create:"WorkspaceCreateWithoutCameraServersInput"
	},
	CameraServerCreateOrConnectWithoutCamerasInput:{
		where:"CameraServerWhereUniqueInput",
		create:"CameraServerCreateWithoutCamerasInput"
	},
	CameraCreateOrConnectWithoutPlayerInput:{
		where:"CameraWhereUniqueInput",
		create:"CameraCreateWithoutPlayerInput"
	},
	CameraCreateManyPlayerInputEnvelope:{
		data:"CameraCreateManyPlayerInput"
	},
	CameraCreateManyPlayerInput:{
		updatedAt:"DateTime"
	},
	PlayerCreateOrConnectWithoutWorkspaceInput:{
		where:"PlayerWhereUniqueInput",
		create:"PlayerCreateWithoutWorkspaceInput"
	},
	PlayerCreateManyWorkspaceInputEnvelope:{
		data:"PlayerCreateManyWorkspaceInput"
	},
	PlayerCreateManyWorkspaceInput:{
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackDetails:"PlaybackTimeCreateInput",
		aspectRatioType:"AspectRatioType",
		map:"MapCreateInput"
	},
	WorkspaceCreateOrConnectWithoutTransactionsInput:{
		where:"WorkspaceWhereUniqueInput",
		create:"WorkspaceCreateWithoutTransactionsInput"
	},
	TransactionCreateOrConnectWithoutPayoutsInput:{
		where:"TransactionWhereUniqueInput",
		create:"TransactionCreateWithoutPayoutsInput"
	},
	PayoutCreateOrConnectWithoutBookingInput:{
		where:"PayoutWhereUniqueInput",
		create:"PayoutCreateWithoutBookingInput"
	},
	PayoutCreateManyBookingInputEnvelope:{
		data:"PayoutCreateManyBookingInput"
	},
	PayoutCreateManyBookingInput:{
		createdAt:"DateTime",
		status:"PayoutStatus"
	},
	BookingCreateOrConnectWithoutCampaignInput:{
		where:"BookingWhereUniqueInput",
		create:"BookingCreateWithoutCampaignInput"
	},
	BookingCreateManyCampaignInputEnvelope:{
		data:"BookingCreateManyCampaignInput"
	},
	BookingCreateManyCampaignInput:{
		bookingDate:"DateTime",
		bookingDetails:"BookingDetailsCreateInput",
		bookingStatus:"BookingStatus",
		paymentStatus:"PaymentStatus",
		deploymentStatus:"DeploymentStatus",
		contentApprovalStatus:"ContentApprovalStatus",
		selectedDates:"OrderSelectedDateCreateInput",
		payment:"BookingPaymentCreateInput",
		priceSummary:"PriceSummaryCreateInput",
		pisignage:"BookingPisignageDataCreateInput",
		refund:"BookingRefundCreateInput"
	},
	CampaignCreateOrConnectWithoutAssetsInput:{
		where:"CampaignWhereUniqueInput",
		create:"CampaignCreateWithoutAssetsInput"
	},
	FileCreateOrConnectWithoutWorkspaceInput:{
		where:"FileWhereUniqueInput",
		create:"FileCreateWithoutWorkspaceInput"
	},
	FileCreateManyWorkspaceInputEnvelope:{
		data:"FileCreateManyWorkspaceInput"
	},
	FileCreateManyWorkspaceInput:{
		meta:"FileMetaCreateInput",
		status:"FileStatus",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	WorkspaceCreateOrConnectWithoutBookingsInput:{
		where:"WorkspaceWhereUniqueInput",
		create:"WorkspaceCreateWithoutBookingsInput"
	},
	BookingCreateOrConnectWithoutProofsInput:{
		where:"BookingWhereUniqueInput",
		create:"BookingCreateWithoutProofsInput"
	},
	FileCreateOrConnectWithoutCampaignsInput:{
		where:"FileWhereUniqueInput",
		create:"FileCreateWithoutCampaignsInput"
	},
	CampaignCreateOrConnectWithoutBookingsInput:{
		where:"CampaignWhereUniqueInput",
		create:"CampaignCreateWithoutBookingsInput"
	},
	BookingCreateOrConnectWithoutWorkspaceInput:{
		where:"BookingWhereUniqueInput",
		create:"BookingCreateWithoutWorkspaceInput"
	},
	BookingCreateManyWorkspaceInputEnvelope:{
		data:"BookingCreateManyWorkspaceInput"
	},
	BookingCreateManyWorkspaceInput:{
		bookingDate:"DateTime",
		bookingDetails:"BookingDetailsCreateInput",
		bookingStatus:"BookingStatus",
		paymentStatus:"PaymentStatus",
		deploymentStatus:"DeploymentStatus",
		contentApprovalStatus:"ContentApprovalStatus",
		selectedDates:"OrderSelectedDateCreateInput",
		payment:"BookingPaymentCreateInput",
		priceSummary:"PriceSummaryCreateInput",
		pisignage:"BookingPisignageDataCreateInput",
		refund:"BookingRefundCreateInput"
	},
	WorkspaceCreateOrConnectWithoutAdBoardGroupsInput:{
		where:"WorkspaceWhereUniqueInput",
		create:"WorkspaceCreateWithoutAdBoardGroupsInput"
	},
	AdBoardGroupCreateOrConnectWithoutAdBoardInput:{
		where:"AdBoardGroupWhereUniqueInput",
		create:"AdBoardGroupCreateWithoutAdBoardInput"
	},
	AdBoardCreateOrConnectWithoutHistoryInput:{
		where:"AdBoardWhereUniqueInput",
		create:"AdBoardCreateWithoutHistoryInput"
	},
	AdBoardPublishHistoryCreateOrConnectWithoutPublishedByInput:{
		where:"AdBoardPublishHistoryWhereUniqueInput",
		create:"AdBoardPublishHistoryCreateWithoutPublishedByInput"
	},
	AdBoardPublishHistoryCreateManyPublishedByInputEnvelope:{
		data:"AdBoardPublishHistoryCreateManyPublishedByInput"
	},
	AdBoardPublishHistoryCreateManyPublishedByInput:{
		publishedAt:"DateTime",
		changesSnapshot:"JSON"
	},
	UserCreateOrConnectWithoutAuditsInput:{
		where:"UserWhereUniqueInput",
		create:"UserCreateWithoutAuditsInput"
	},
	AuditCreateOrConnectWithoutWorkspaceInput:{
		where:"AuditWhereUniqueInput",
		create:"AuditCreateWithoutWorkspaceInput"
	},
	AuditCreateManyWorkspaceInputEnvelope:{
		data:"AuditCreateManyWorkspaceInput"
	},
	AuditCreateManyWorkspaceInput:{
		createdAt:"DateTime",
		entity:"EntityCreateInput",
		updates:"JSON"
	},
	WorkspaceCreateOrConnectWithoutPlayersInput:{
		where:"WorkspaceWhereUniqueInput",
		create:"WorkspaceCreateWithoutPlayersInput"
	},
	PlayerCreateOrConnectWithoutAdBoardGroupInput:{
		where:"PlayerWhereUniqueInput",
		create:"PlayerCreateWithoutAdBoardGroupInput"
	},
	PlayerCreateManyAdBoardGroupInputEnvelope:{
		data:"PlayerCreateManyAdBoardGroupInput"
	},
	PlayerCreateManyAdBoardGroupInput:{
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackDetails:"PlaybackTimeCreateInput",
		aspectRatioType:"AspectRatioType",
		map:"MapCreateInput"
	},
	AdBoardGroupCreateOrConnectWithoutScheduledPlaylistsInput:{
		where:"AdBoardGroupWhereUniqueInput",
		create:"AdBoardGroupCreateWithoutScheduledPlaylistsInput"
	},
	PlaylistCreateNestedManyWithoutFooterPlaylistInput:{
		create:"PlaylistCreateWithoutFooterPlaylistInput",
		connectOrCreate:"PlaylistCreateOrConnectWithoutFooterPlaylistInput",
		createMany:"PlaylistCreateManyFooterPlaylistInputEnvelope",
		connect:"PlaylistWhereUniqueInput"
	},
	PlaylistCreateWithoutFooterPlaylistInput:{
		assets:"PisignageFileCreateInput",
		layout:"ScreenLayout",
		sidebarPlaylist:"PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput",
		adBoardGroupsForAds:"AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput",
		adBoardGroups:"AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput",
		workspace:"WorkspaceCreateNestedOneWithoutPlaylistsInput",
		footerParentPlaylists:"PlaylistCreateNestedManyWithoutFooterPlaylistInput",
		sidebarParentPlaylists:"PlaylistCreateNestedManyWithoutSidebarPlaylistInput"
	},
	PlaylistCreateNestedManyWithoutSidebarPlaylistInput:{
		create:"PlaylistCreateWithoutSidebarPlaylistInput",
		connectOrCreate:"PlaylistCreateOrConnectWithoutSidebarPlaylistInput",
		createMany:"PlaylistCreateManySidebarPlaylistInputEnvelope",
		connect:"PlaylistWhereUniqueInput"
	},
	PlaylistCreateWithoutSidebarPlaylistInput:{
		assets:"PisignageFileCreateInput",
		layout:"ScreenLayout",
		footerPlaylist:"PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput",
		adBoardGroupsForAds:"AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput",
		adBoardGroups:"AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput",
		workspace:"WorkspaceCreateNestedOneWithoutPlaylistsInput",
		footerParentPlaylists:"PlaylistCreateNestedManyWithoutFooterPlaylistInput",
		sidebarParentPlaylists:"PlaylistCreateNestedManyWithoutSidebarPlaylistInput"
	},
	PlaylistCreateOrConnectWithoutSidebarPlaylistInput:{
		where:"PlaylistWhereUniqueInput",
		create:"PlaylistCreateWithoutSidebarPlaylistInput"
	},
	PlaylistCreateManySidebarPlaylistInputEnvelope:{
		data:"PlaylistCreateManySidebarPlaylistInput"
	},
	PlaylistCreateManySidebarPlaylistInput:{
		assets:"PisignageFileCreateInput",
		layout:"ScreenLayout"
	},
	PlaylistCreateOrConnectWithoutFooterPlaylistInput:{
		where:"PlaylistWhereUniqueInput",
		create:"PlaylistCreateWithoutFooterPlaylistInput"
	},
	PlaylistCreateManyFooterPlaylistInputEnvelope:{
		data:"PlaylistCreateManyFooterPlaylistInput"
	},
	PlaylistCreateManyFooterPlaylistInput:{
		assets:"PisignageFileCreateInput",
		layout:"ScreenLayout"
	},
	PlaylistCreateOrConnectWithoutAdBoardGroupsForAdsInput:{
		where:"PlaylistWhereUniqueInput",
		create:"PlaylistCreateWithoutAdBoardGroupsForAdsInput"
	},
	AdBoardGroupCreateOrConnectWithoutWorkspaceInput:{
		where:"AdBoardGroupWhereUniqueInput",
		create:"AdBoardGroupCreateWithoutWorkspaceInput"
	},
	AdBoardGroupCreateManyWorkspaceInputEnvelope:{
		data:"AdBoardGroupCreateManyWorkspaceInput"
	},
	AdBoardGroupCreateManyWorkspaceInput:{
		schedules:"ScheduleCreateInput",
		fitImage:"FitType",
		fitVideo:"FitType"
	},
	WorkspaceCreateOrConnectWithoutPlaylistsInput:{
		where:"WorkspaceWhereUniqueInput",
		create:"WorkspaceCreateWithoutPlaylistsInput"
	},
	PlaylistCreateOrConnectWithoutAdBoardGroupsInput:{
		where:"PlaylistWhereUniqueInput",
		create:"PlaylistCreateWithoutAdBoardGroupsInput"
	},
	AdBoardGroupCreateOrConnectWithoutAdsPlaylistInput:{
		where:"AdBoardGroupWhereUniqueInput",
		create:"AdBoardGroupCreateWithoutAdsPlaylistInput"
	},
	AdBoardGroupCreateManyAdsPlaylistInputEnvelope:{
		data:"AdBoardGroupCreateManyAdsPlaylistInput"
	},
	AdBoardGroupCreateManyAdsPlaylistInput:{
		schedules:"ScheduleCreateInput",
		fitImage:"FitType",
		fitVideo:"FitType"
	},
	PlaylistCreateOrConnectWithoutSidebarParentPlaylistsInput:{
		where:"PlaylistWhereUniqueInput",
		create:"PlaylistCreateWithoutSidebarParentPlaylistsInput"
	},
	PlaylistCreateOrConnectWithoutFooterParentPlaylistsInput:{
		where:"PlaylistWhereUniqueInput",
		create:"PlaylistCreateWithoutFooterParentPlaylistsInput"
	},
	PlaylistCreateOrConnectWithoutWorkspaceInput:{
		where:"PlaylistWhereUniqueInput",
		create:"PlaylistCreateWithoutWorkspaceInput"
	},
	PlaylistCreateManyWorkspaceInputEnvelope:{
		data:"PlaylistCreateManyWorkspaceInput"
	},
	PlaylistCreateManyWorkspaceInput:{
		assets:"PisignageFileCreateInput",
		layout:"ScreenLayout"
	},
	WorkspaceCreateOrConnectWithoutAdBoardsInput:{
		where:"WorkspaceWhereUniqueInput",
		create:"WorkspaceCreateWithoutAdBoardsInput"
	},
	AdBoardCreateOrConnectWithoutAdBoardGroupInput:{
		where:"AdBoardWhereUniqueInput",
		create:"AdBoardCreateWithoutAdBoardGroupInput"
	},
	AdBoardGroupCreateOrConnectWithoutPisignagePlayersInput:{
		where:"AdBoardGroupWhereUniqueInput",
		create:"AdBoardGroupCreateWithoutPisignagePlayersInput"
	},
	PlayerCreateOrConnectWithoutAdBoardsInput:{
		where:"PlayerWhereUniqueInput",
		create:"PlayerCreateWithoutAdBoardsInput"
	},
	AdBoardCreateOrConnectWithoutWorkspaceInput:{
		where:"AdBoardWhereUniqueInput",
		create:"AdBoardCreateWithoutWorkspaceInput"
	},
	AdBoardCreateManyWorkspaceInputEnvelope:{
		data:"AdBoardCreateManyWorkspaceInput"
	},
	AdBoardCreateManyWorkspaceInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime"
	},
	WorkspaceCreateOrConnectWithoutMembershipsInput:{
		where:"WorkspaceWhereUniqueInput",
		create:"WorkspaceCreateWithoutMembershipsInput"
	},
	WorkspaceRoleCreateNestedOneWithoutMembershipsInput:{
		create:"WorkspaceRoleCreateWithoutMembershipsInput",
		connectOrCreate:"WorkspaceRoleCreateOrConnectWithoutMembershipsInput",
		connect:"WorkspaceRoleWhereUniqueInput"
	},
	WorkspaceRoleCreateWithoutMembershipsInput:{
		permissions:"Permission",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		workspace:"WorkspaceCreateNestedOneWithoutWorkspaceRolesInput"
	},
	WorkspaceCreateNestedOneWithoutWorkspaceRolesInput:{
		create:"WorkspaceCreateWithoutWorkspaceRolesInput",
		connectOrCreate:"WorkspaceCreateOrConnectWithoutWorkspaceRolesInput",
		connect:"WorkspaceWhereUniqueInput"
	},
	WorkspaceCreateWithoutWorkspaceRolesInput:{
		type:"WorkspaceType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		owner:"UserCreateNestedOneWithoutOwnedWorkspacesInput",
		memberships:"MembershipCreateNestedManyWithoutWorkspaceInput",
		adBoards:"AdBoardCreateNestedManyWithoutWorkspaceInput",
		playlists:"PlaylistCreateNestedManyWithoutWorkspaceInput",
		adBoardGroups:"AdBoardGroupCreateNestedManyWithoutWorkspaceInput",
		audits:"AuditCreateNestedManyWithoutWorkspaceInput",
		bookings:"BookingCreateNestedManyWithoutWorkspaceInput",
		files:"FileCreateNestedManyWithoutWorkspaceInput",
		players:"PlayerCreateNestedManyWithoutWorkspaceInput",
		transactions:"TransactionCreateNestedManyWithoutWorkspaceInput",
		activeSubscription:"SubscriptionCreateNestedOneWithoutActiveWorkspaceInput",
		cameraServers:"CameraServerCreateNestedManyWithoutWorkspaceInput",
		webhookLogs:"WebhookLogCreateNestedManyWithoutWorkspaceInput",
		leads:"UserCreateNestedManyWithoutLeadWorspacesInput"
	},
	WorkspaceCreateOrConnectWithoutWorkspaceRolesInput:{
		where:"WorkspaceWhereUniqueInput",
		create:"WorkspaceCreateWithoutWorkspaceRolesInput"
	},
	WorkspaceRoleCreateOrConnectWithoutMembershipsInput:{
		where:"WorkspaceRoleWhereUniqueInput",
		create:"WorkspaceRoleCreateWithoutMembershipsInput"
	},
	MembershipCreateOrConnectWithoutUserInput:{
		where:"MembershipWhereUniqueInput",
		create:"MembershipCreateWithoutUserInput"
	},
	MembershipCreateManyUserInputEnvelope:{
		data:"MembershipCreateManyUserInput"
	},
	MembershipCreateManyUserInput:{
		createdAt:"DateTime"
	},
	UserCreateOrConnectWithoutChangelogsInput:{
		where:"UserWhereUniqueInput",
		create:"UserCreateWithoutChangelogsInput"
	},
	ChangelogCreateOrConnectWithoutBookingInput:{
		where:"ChangelogWhereUniqueInput",
		create:"ChangelogCreateWithoutBookingInput"
	},
	ChangelogCreateManyBookingInputEnvelope:{
		data:"ChangelogCreateManyBookingInput"
	},
	ChangelogCreateManyBookingInput:{
		createdAt:"DateTime",
		updates:"JSON"
	},
	BookingCreateOrConnectWithoutSharedUsersInput:{
		where:"BookingWhereUniqueInput",
		create:"BookingCreateWithoutSharedUsersInput"
	},
	UserCreateOrConnectWithoutAdBoardsInput:{
		where:"UserWhereUniqueInput",
		create:"UserCreateWithoutAdBoardsInput"
	},
	AdBoardCreateOrConnectWithoutBookingsInput:{
		where:"AdBoardWhereUniqueInput",
		create:"AdBoardCreateWithoutBookingsInput"
	},
	BookingCreateOrConnectWithoutBusinessCategoryInput:{
		where:"BookingWhereUniqueInput",
		create:"BookingCreateWithoutBusinessCategoryInput"
	},
	BookingCreateManyBusinessCategoryInputEnvelope:{
		data:"BookingCreateManyBusinessCategoryInput"
	},
	BookingCreateManyBusinessCategoryInput:{
		bookingDate:"DateTime",
		bookingDetails:"BookingDetailsCreateInput",
		bookingStatus:"BookingStatus",
		paymentStatus:"PaymentStatus",
		deploymentStatus:"DeploymentStatus",
		contentApprovalStatus:"ContentApprovalStatus",
		selectedDates:"OrderSelectedDateCreateInput",
		payment:"BookingPaymentCreateInput",
		priceSummary:"PriceSummaryCreateInput",
		pisignage:"BookingPisignageDataCreateInput",
		refund:"BookingRefundCreateInput"
	},
	BusinessCategoryCreateOrConnectWithoutCampaignsInput:{
		where:"BusinessCategoryWhereUniqueInput",
		create:"BusinessCategoryCreateWithoutCampaignsInput"
	},
	CampaignCreateOrConnectWithoutUserInput:{
		where:"CampaignWhereUniqueInput",
		create:"CampaignCreateWithoutUserInput"
	},
	CampaignCreateManyUserInputEnvelope:{
		data:"CampaignCreateManyUserInput"
	},
	CampaignCreateManyUserInput:{
		createdAt:"DateTime",
		updatedAt:"DateTime",
		payment:"BookingPaymentCreateInput",
		paymentStatus:"PaymentStatus",
		priceSummary:"PriceSummaryCreateInput",
		selectedDates:"OrderSelectedDateCreateInput"
	},
	UserCreateOrConnectWithoutMembershipsInput:{
		where:"UserWhereUniqueInput",
		create:"UserCreateWithoutMembershipsInput"
	},
	MembershipCreateOrConnectWithoutWorkspaceInput:{
		where:"MembershipWhereUniqueInput",
		create:"MembershipCreateWithoutWorkspaceInput"
	},
	MembershipCreateManyWorkspaceInputEnvelope:{
		data:"MembershipCreateManyWorkspaceInput"
	},
	MembershipCreateManyWorkspaceInput:{
		createdAt:"DateTime"
	},
	WorkspaceCreateOrConnectWithoutAuditsInput:{
		where:"WorkspaceWhereUniqueInput",
		create:"WorkspaceCreateWithoutAuditsInput"
	},
	AuditCreateOrConnectWithoutUserInput:{
		where:"AuditWhereUniqueInput",
		create:"AuditCreateWithoutUserInput"
	},
	AuditCreateManyUserInputEnvelope:{
		data:"AuditCreateManyUserInput"
	},
	AuditCreateManyUserInput:{
		createdAt:"DateTime",
		entity:"EntityCreateInput",
		updates:"JSON"
	},
	UserCreateOrConnectWithoutOrdersInput:{
		where:"UserWhereUniqueInput",
		create:"UserCreateWithoutOrdersInput"
	},
	OrderCreateOrConnectWithoutAdBoardInput:{
		where:"OrderWhereUniqueInput",
		create:"OrderCreateWithoutAdBoardInput"
	},
	OrderCreateManyAdBoardInputEnvelope:{
		data:"OrderCreateManyAdBoardInput"
	},
	OrderCreateManyAdBoardInput:{
		createdDate:"DateTime",
		priceSummary:"PriceSummaryCreateInput",
		selectedDate:"OrderSelectedDateCreateInput"
	},
	AdBoardCreateOrConnectWithoutCreatedByInput:{
		where:"AdBoardWhereUniqueInput",
		create:"AdBoardCreateWithoutCreatedByInput"
	},
	AdBoardCreateManyCreatedByInputEnvelope:{
		data:"AdBoardCreateManyCreatedByInput"
	},
	AdBoardCreateManyCreatedByInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime"
	},
	UserCreateOrConnectWithoutBookingsInput:{
		where:"UserWhereUniqueInput",
		create:"UserCreateWithoutBookingsInput"
	},
	BookingCreateOrConnectWithoutChangelogsInput:{
		where:"BookingWhereUniqueInput",
		create:"BookingCreateWithoutChangelogsInput"
	},
	ChangelogCreateOrConnectWithoutUserInput:{
		where:"ChangelogWhereUniqueInput",
		create:"ChangelogCreateWithoutUserInput"
	},
	ChangelogCreateManyUserInputEnvelope:{
		data:"ChangelogCreateManyUserInput"
	},
	ChangelogCreateManyUserInput:{
		createdAt:"DateTime",
		updates:"JSON"
	},
	UserCreateOrConnectWithoutOfflineBookingAdboardsInput:{
		where:"UserWhereUniqueInput",
		create:"UserCreateWithoutOfflineBookingAdboardsInput"
	},
	AdBoardCreateOrConnectWithoutOrdersInput:{
		where:"AdBoardWhereUniqueInput",
		create:"AdBoardCreateWithoutOrdersInput"
	},
	OrderCreateOrConnectWithoutCreatedByInput:{
		where:"OrderWhereUniqueInput",
		create:"OrderCreateWithoutCreatedByInput"
	},
	OrderCreateManyCreatedByInputEnvelope:{
		data:"OrderCreateManyCreatedByInput"
	},
	OrderCreateManyCreatedByInput:{
		createdDate:"DateTime",
		priceSummary:"PriceSummaryCreateInput",
		selectedDate:"OrderSelectedDateCreateInput"
	},
	UserCreateOrConnectWithoutOwnedWorkspacesInput:{
		where:"UserWhereUniqueInput",
		create:"UserCreateWithoutOwnedWorkspacesInput"
	},
	WorkspaceCreateOrConnectWithoutFilesInput:{
		where:"WorkspaceWhereUniqueInput",
		create:"WorkspaceCreateWithoutFilesInput"
	},
	FileCreateOrConnectWithoutUpdatedByInput:{
		where:"FileWhereUniqueInput",
		create:"FileCreateWithoutUpdatedByInput"
	},
	FileCreateManyUpdatedByInputEnvelope:{
		data:"FileCreateManyUpdatedByInput"
	},
	FileCreateManyUpdatedByInput:{
		meta:"FileMetaCreateInput",
		status:"FileStatus",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	UserCreateOrConnectWithoutCreatedFilesInput:{
		where:"UserWhereUniqueInput",
		create:"UserCreateWithoutCreatedFilesInput"
	},
	FileCreateOrConnectWithoutProofBookingInput:{
		where:"FileWhereUniqueInput",
		create:"FileCreateWithoutProofBookingInput"
	},
	FileCreateManyProofBookingInputEnvelope:{
		data:"FileCreateManyProofBookingInput"
	},
	FileCreateManyProofBookingInput:{
		meta:"FileMetaCreateInput",
		status:"FileStatus",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	BookingCreateOrConnectWithoutAssetsInput:{
		where:"BookingWhereUniqueInput",
		create:"BookingCreateWithoutAssetsInput"
	},
	FileCreateOrConnectWithoutCreatedByInput:{
		where:"FileWhereUniqueInput",
		create:"FileCreateWithoutCreatedByInput"
	},
	FileCreateManyCreatedByInputEnvelope:{
		data:"FileCreateManyCreatedByInput"
	},
	FileCreateManyCreatedByInput:{
		meta:"FileMetaCreateInput",
		status:"FileStatus",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	UserCreateOrConnectWithoutCampaignsInput:{
		where:"UserWhereUniqueInput",
		create:"UserCreateWithoutCampaignsInput"
	},
	CampaignCreateOrConnectWithoutBusinessCategoryInput:{
		where:"CampaignWhereUniqueInput",
		create:"CampaignCreateWithoutBusinessCategoryInput"
	},
	CampaignCreateManyBusinessCategoryInputEnvelope:{
		data:"CampaignCreateManyBusinessCategoryInput"
	},
	CampaignCreateManyBusinessCategoryInput:{
		createdAt:"DateTime",
		updatedAt:"DateTime",
		payment:"BookingPaymentCreateInput",
		paymentStatus:"PaymentStatus",
		priceSummary:"PriceSummaryCreateInput",
		selectedDates:"OrderSelectedDateCreateInput"
	},
	BusinessCategoryCreateOrConnectWithoutBookingsInput:{
		where:"BusinessCategoryWhereUniqueInput",
		create:"BusinessCategoryCreateWithoutBookingsInput"
	},
	BookingCreateOrConnectWithoutUserInput:{
		where:"BookingWhereUniqueInput",
		create:"BookingCreateWithoutUserInput"
	},
	BookingCreateManyUserInputEnvelope:{
		data:"BookingCreateManyUserInput"
	},
	BookingCreateManyUserInput:{
		bookingDate:"DateTime",
		bookingDetails:"BookingDetailsCreateInput",
		bookingStatus:"BookingStatus",
		paymentStatus:"PaymentStatus",
		deploymentStatus:"DeploymentStatus",
		contentApprovalStatus:"ContentApprovalStatus",
		selectedDates:"OrderSelectedDateCreateInput",
		payment:"BookingPaymentCreateInput",
		priceSummary:"PriceSummaryCreateInput",
		pisignage:"BookingPisignageDataCreateInput",
		refund:"BookingRefundCreateInput"
	},
	UserCreateOrConnectWithoutUpdatedFilesInput:{
		where:"UserWhereUniqueInput",
		create:"UserCreateWithoutUpdatedFilesInput"
	},
	FileCreateOrConnectWithoutBookingsInput:{
		where:"FileWhereUniqueInput",
		create:"FileCreateWithoutBookingsInput"
	},
	BookingCreateOrConnectWithoutAdBoardInput:{
		where:"BookingWhereUniqueInput",
		create:"BookingCreateWithoutAdBoardInput"
	},
	BookingCreateManyAdBoardInputEnvelope:{
		data:"BookingCreateManyAdBoardInput"
	},
	BookingCreateManyAdBoardInput:{
		bookingDate:"DateTime",
		bookingDetails:"BookingDetailsCreateInput",
		bookingStatus:"BookingStatus",
		paymentStatus:"PaymentStatus",
		deploymentStatus:"DeploymentStatus",
		contentApprovalStatus:"ContentApprovalStatus",
		selectedDates:"OrderSelectedDateCreateInput",
		payment:"BookingPaymentCreateInput",
		priceSummary:"PriceSummaryCreateInput",
		pisignage:"BookingPisignageDataCreateInput",
		refund:"BookingRefundCreateInput"
	},
	AdBoardCreateOrConnectWithoutCategoryInput:{
		where:"AdBoardWhereUniqueInput",
		create:"AdBoardCreateWithoutCategoryInput"
	},
	AdBoardCreateManyCategoryInputEnvelope:{
		data:"AdBoardCreateManyCategoryInput"
	},
	AdBoardCreateManyCategoryInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime"
	},
	BusinessCategoryCreateOrConnectWithoutRestrictedByAdBoardsInput:{
		where:"BusinessCategoryWhereUniqueInput",
		create:"BusinessCategoryCreateWithoutRestrictedByAdBoardsInput"
	},
	AdBoardCreateOrConnectWithoutLocationCategoryInput:{
		where:"AdBoardWhereUniqueInput",
		create:"AdBoardCreateWithoutLocationCategoryInput"
	},
	AdBoardCreateManyLocationCategoryInputEnvelope:{
		data:"AdBoardCreateManyLocationCategoryInput"
	},
	AdBoardCreateManyLocationCategoryInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime"
	},
	LocationCategoryCreateOrConnectWithoutLocationSubCategoryInput:{
		where:"LocationCategoryWhereUniqueInput",
		create:"LocationCategoryCreateWithoutLocationSubCategoryInput"
	},
	LocationCategoryWhereUniqueInput:{
		AND:"LocationCategoryWhereInput",
		OR:"LocationCategoryWhereInput",
		NOT:"LocationCategoryWhereInput",
		name:"StringFilter",
		adBoards:"AdBoardListRelationFilter",
		locationSubCategory:"LocationSubCategoryListRelationFilter"
	},
	LocationSubCategoryCreateOrConnectWithoutAdBoardsInput:{
		where:"LocationSubCategoryWhereUniqueInput",
		create:"LocationSubCategoryCreateWithoutAdBoardsInput"
	},
	AdBoardCreateOrConnectWithoutRestrictedBusinesInput:{
		where:"AdBoardWhereUniqueInput",
		create:"AdBoardCreateWithoutRestrictedBusinesInput"
	},
	BusinessCategoryCreateOrConnectWithoutAdBoardsInput:{
		where:"BusinessCategoryWhereUniqueInput",
		create:"BusinessCategoryCreateWithoutAdBoardsInput"
	},
	AdBoardCreateOrConnectWithoutLocationSubCategoryInput:{
		where:"AdBoardWhereUniqueInput",
		create:"AdBoardCreateWithoutLocationSubCategoryInput"
	},
	AdBoardCreateManyLocationSubCategoryInputEnvelope:{
		data:"AdBoardCreateManyLocationSubCategoryInput"
	},
	AdBoardCreateManyLocationSubCategoryInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime"
	},
	LocationSubCategoryCreateOrConnectWithoutLocationCategoryInput:{
		where:"LocationSubCategoryWhereUniqueInput",
		create:"LocationSubCategoryCreateWithoutLocationCategoryInput"
	},
	LocationSubCategoryCreateManyLocationCategoryInputEnvelope:{
		data:"LocationSubCategoryCreateManyLocationCategoryInput"
	},
	LocationSubCategoryCreateManyLocationCategoryInput:{

	},
	LocationCategoryCreateOrConnectWithoutAdBoardsInput:{
		where:"LocationCategoryWhereUniqueInput",
		create:"LocationCategoryCreateWithoutAdBoardsInput"
	},
	AdBoardUpdateManyMutationInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime"
	},
	AdBoardUpdateInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryUpdateOneWithoutAdBoardsNestedInput",
		locationSubCategory:"LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput",
		type:"AdBoardTypeUpdateOneWithoutAdBoardsNestedInput",
		category:"BusinessCategoryUpdateOneWithoutAdBoardsNestedInput",
		restrictedBusines:"BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput",
		bookings:"BookingUpdateManyWithoutAdBoardNestedInput",
		orders:"OrderUpdateManyWithoutAdBoardNestedInput",
		slotConfigurations:"SlotConfigurationUpdateManyWithoutAdBoardNestedInput",
		offlineBookingsContact:"UserUpdateOneWithoutOfflineBookingAdboardsNestedInput",
		createdBy:"UserUpdateOneWithoutAdBoardsNestedInput",
		workspace:"WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput",
		players:"PlayerUpdateManyWithoutAdBoardsNestedInput",
		adBoardGroup:"AdBoardGroupUpdateOneWithoutAdBoardNestedInput",
		cameras:"CameraUpdateManyWithoutAdBoardNestedInput",
		publishedVersion:"AdBoardUpdateOneWithoutDraftAdboardNestedInput",
		draftAdboard:"AdBoardUpdateOneWithoutPublishedVersionNestedInput",
		history:"AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput",
		archivedBy:"UserUpdateOneWithoutArchivedAdboardsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"
	},
	LocationCategoryUpdateOneWithoutAdBoardsNestedInput:{
		create:"LocationCategoryCreateWithoutAdBoardsInput",
		connectOrCreate:"LocationCategoryCreateOrConnectWithoutAdBoardsInput",
		upsert:"LocationCategoryUpsertWithoutAdBoardsInput",
		delete:"LocationCategoryWhereInput",
		connect:"LocationCategoryWhereUniqueInput",
		update:"LocationCategoryUpdateToOneWithWhereWithoutAdBoardsInput"
	},
	LocationCategoryUpsertWithoutAdBoardsInput:{
		update:"LocationCategoryUpdateWithoutAdBoardsInput",
		create:"LocationCategoryCreateWithoutAdBoardsInput",
		where:"LocationCategoryWhereInput"
	},
	LocationCategoryUpdateWithoutAdBoardsInput:{
		locationSubCategory:"LocationSubCategoryUpdateManyWithoutLocationCategoryNestedInput"
	},
	LocationSubCategoryUpdateManyWithoutLocationCategoryNestedInput:{
		create:"LocationSubCategoryCreateWithoutLocationCategoryInput",
		connectOrCreate:"LocationSubCategoryCreateOrConnectWithoutLocationCategoryInput",
		upsert:"LocationSubCategoryUpsertWithWhereUniqueWithoutLocationCategoryInput",
		createMany:"LocationSubCategoryCreateManyLocationCategoryInputEnvelope",
		set:"LocationSubCategoryWhereUniqueInput",
		disconnect:"LocationSubCategoryWhereUniqueInput",
		delete:"LocationSubCategoryWhereUniqueInput",
		connect:"LocationSubCategoryWhereUniqueInput",
		update:"LocationSubCategoryUpdateWithWhereUniqueWithoutLocationCategoryInput",
		updateMany:"LocationSubCategoryUpdateManyWithWhereWithoutLocationCategoryInput",
		deleteMany:"LocationSubCategoryScalarWhereInput"
	},
	LocationSubCategoryUpsertWithWhereUniqueWithoutLocationCategoryInput:{
		where:"LocationSubCategoryWhereUniqueInput",
		update:"LocationSubCategoryUpdateWithoutLocationCategoryInput",
		create:"LocationSubCategoryCreateWithoutLocationCategoryInput"
	},
	LocationSubCategoryUpdateWithoutLocationCategoryInput:{
		adBoards:"AdBoardUpdateManyWithoutLocationSubCategoryNestedInput"
	},
	AdBoardUpdateManyWithoutLocationSubCategoryNestedInput:{
		create:"AdBoardCreateWithoutLocationSubCategoryInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutLocationSubCategoryInput",
		upsert:"AdBoardUpsertWithWhereUniqueWithoutLocationSubCategoryInput",
		createMany:"AdBoardCreateManyLocationSubCategoryInputEnvelope",
		set:"AdBoardWhereUniqueInput",
		disconnect:"AdBoardWhereUniqueInput",
		delete:"AdBoardWhereUniqueInput",
		connect:"AdBoardWhereUniqueInput",
		update:"AdBoardUpdateWithWhereUniqueWithoutLocationSubCategoryInput",
		updateMany:"AdBoardUpdateManyWithWhereWithoutLocationSubCategoryInput",
		deleteMany:"AdBoardScalarWhereInput"
	},
	AdBoardUpsertWithWhereUniqueWithoutLocationSubCategoryInput:{
		where:"AdBoardWhereUniqueInput",
		update:"AdBoardUpdateWithoutLocationSubCategoryInput",
		create:"AdBoardCreateWithoutLocationSubCategoryInput"
	},
	AdBoardUpdateWithoutLocationSubCategoryInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryUpdateOneWithoutAdBoardsNestedInput",
		type:"AdBoardTypeUpdateOneWithoutAdBoardsNestedInput",
		category:"BusinessCategoryUpdateOneWithoutAdBoardsNestedInput",
		restrictedBusines:"BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput",
		bookings:"BookingUpdateManyWithoutAdBoardNestedInput",
		orders:"OrderUpdateManyWithoutAdBoardNestedInput",
		slotConfigurations:"SlotConfigurationUpdateManyWithoutAdBoardNestedInput",
		offlineBookingsContact:"UserUpdateOneWithoutOfflineBookingAdboardsNestedInput",
		createdBy:"UserUpdateOneWithoutAdBoardsNestedInput",
		workspace:"WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput",
		players:"PlayerUpdateManyWithoutAdBoardsNestedInput",
		adBoardGroup:"AdBoardGroupUpdateOneWithoutAdBoardNestedInput",
		cameras:"CameraUpdateManyWithoutAdBoardNestedInput",
		publishedVersion:"AdBoardUpdateOneWithoutDraftAdboardNestedInput",
		draftAdboard:"AdBoardUpdateOneWithoutPublishedVersionNestedInput",
		history:"AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput",
		archivedBy:"UserUpdateOneWithoutArchivedAdboardsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"
	},
	AdBoardTypeUpdateOneWithoutAdBoardsNestedInput:{
		create:"AdBoardTypeCreateWithoutAdBoardsInput",
		connectOrCreate:"AdBoardTypeCreateOrConnectWithoutAdBoardsInput",
		upsert:"AdBoardTypeUpsertWithoutAdBoardsInput",
		delete:"AdBoardTypeWhereInput",
		connect:"AdBoardTypeWhereUniqueInput",
		update:"AdBoardTypeUpdateToOneWithWhereWithoutAdBoardsInput"
	},
	AdBoardTypeUpsertWithoutAdBoardsInput:{
		update:"AdBoardTypeUpdateWithoutAdBoardsInput",
		create:"AdBoardTypeCreateWithoutAdBoardsInput",
		where:"AdBoardTypeWhereInput"
	},
	AdBoardTypeUpdateWithoutAdBoardsInput:{

	},
	AdBoardTypeUpdateToOneWithWhereWithoutAdBoardsInput:{
		where:"AdBoardTypeWhereInput",
		data:"AdBoardTypeUpdateWithoutAdBoardsInput"
	},
	BusinessCategoryUpdateOneWithoutAdBoardsNestedInput:{
		create:"BusinessCategoryCreateWithoutAdBoardsInput",
		connectOrCreate:"BusinessCategoryCreateOrConnectWithoutAdBoardsInput",
		upsert:"BusinessCategoryUpsertWithoutAdBoardsInput",
		delete:"BusinessCategoryWhereInput",
		connect:"BusinessCategoryWhereUniqueInput",
		update:"BusinessCategoryUpdateToOneWithWhereWithoutAdBoardsInput"
	},
	BusinessCategoryUpsertWithoutAdBoardsInput:{
		update:"BusinessCategoryUpdateWithoutAdBoardsInput",
		create:"BusinessCategoryCreateWithoutAdBoardsInput",
		where:"BusinessCategoryWhereInput"
	},
	BusinessCategoryUpdateWithoutAdBoardsInput:{
		restrictedByAdBoards:"AdBoardUpdateManyWithoutRestrictedBusinesNestedInput",
		campaigns:"CampaignUpdateManyWithoutBusinessCategoryNestedInput",
		bookings:"BookingUpdateManyWithoutBusinessCategoryNestedInput"
	},
	AdBoardUpdateManyWithoutRestrictedBusinesNestedInput:{
		create:"AdBoardCreateWithoutRestrictedBusinesInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutRestrictedBusinesInput",
		upsert:"AdBoardUpsertWithWhereUniqueWithoutRestrictedBusinesInput",
		set:"AdBoardWhereUniqueInput",
		disconnect:"AdBoardWhereUniqueInput",
		delete:"AdBoardWhereUniqueInput",
		connect:"AdBoardWhereUniqueInput",
		update:"AdBoardUpdateWithWhereUniqueWithoutRestrictedBusinesInput",
		updateMany:"AdBoardUpdateManyWithWhereWithoutRestrictedBusinesInput",
		deleteMany:"AdBoardScalarWhereInput"
	},
	AdBoardUpsertWithWhereUniqueWithoutRestrictedBusinesInput:{
		where:"AdBoardWhereUniqueInput",
		update:"AdBoardUpdateWithoutRestrictedBusinesInput",
		create:"AdBoardCreateWithoutRestrictedBusinesInput"
	},
	AdBoardUpdateWithoutRestrictedBusinesInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryUpdateOneWithoutAdBoardsNestedInput",
		locationSubCategory:"LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput",
		type:"AdBoardTypeUpdateOneWithoutAdBoardsNestedInput",
		category:"BusinessCategoryUpdateOneWithoutAdBoardsNestedInput",
		bookings:"BookingUpdateManyWithoutAdBoardNestedInput",
		orders:"OrderUpdateManyWithoutAdBoardNestedInput",
		slotConfigurations:"SlotConfigurationUpdateManyWithoutAdBoardNestedInput",
		offlineBookingsContact:"UserUpdateOneWithoutOfflineBookingAdboardsNestedInput",
		createdBy:"UserUpdateOneWithoutAdBoardsNestedInput",
		workspace:"WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput",
		players:"PlayerUpdateManyWithoutAdBoardsNestedInput",
		adBoardGroup:"AdBoardGroupUpdateOneWithoutAdBoardNestedInput",
		cameras:"CameraUpdateManyWithoutAdBoardNestedInput",
		publishedVersion:"AdBoardUpdateOneWithoutDraftAdboardNestedInput",
		draftAdboard:"AdBoardUpdateOneWithoutPublishedVersionNestedInput",
		history:"AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput",
		archivedBy:"UserUpdateOneWithoutArchivedAdboardsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"
	},
	LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput:{
		create:"LocationSubCategoryCreateWithoutAdBoardsInput",
		connectOrCreate:"LocationSubCategoryCreateOrConnectWithoutAdBoardsInput",
		upsert:"LocationSubCategoryUpsertWithoutAdBoardsInput",
		delete:"LocationSubCategoryWhereInput",
		connect:"LocationSubCategoryWhereUniqueInput",
		update:"LocationSubCategoryUpdateToOneWithWhereWithoutAdBoardsInput"
	},
	LocationSubCategoryUpsertWithoutAdBoardsInput:{
		update:"LocationSubCategoryUpdateWithoutAdBoardsInput",
		create:"LocationSubCategoryCreateWithoutAdBoardsInput",
		where:"LocationSubCategoryWhereInput"
	},
	LocationSubCategoryUpdateWithoutAdBoardsInput:{
		locationCategory:"LocationCategoryUpdateOneWithoutLocationSubCategoryNestedInput"
	},
	LocationCategoryUpdateOneWithoutLocationSubCategoryNestedInput:{
		create:"LocationCategoryCreateWithoutLocationSubCategoryInput",
		connectOrCreate:"LocationCategoryCreateOrConnectWithoutLocationSubCategoryInput",
		upsert:"LocationCategoryUpsertWithoutLocationSubCategoryInput",
		delete:"LocationCategoryWhereInput",
		connect:"LocationCategoryWhereUniqueInput",
		update:"LocationCategoryUpdateToOneWithWhereWithoutLocationSubCategoryInput"
	},
	LocationCategoryUpsertWithoutLocationSubCategoryInput:{
		update:"LocationCategoryUpdateWithoutLocationSubCategoryInput",
		create:"LocationCategoryCreateWithoutLocationSubCategoryInput",
		where:"LocationCategoryWhereInput"
	},
	LocationCategoryUpdateWithoutLocationSubCategoryInput:{
		adBoards:"AdBoardUpdateManyWithoutLocationCategoryNestedInput"
	},
	AdBoardUpdateManyWithoutLocationCategoryNestedInput:{
		create:"AdBoardCreateWithoutLocationCategoryInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutLocationCategoryInput",
		upsert:"AdBoardUpsertWithWhereUniqueWithoutLocationCategoryInput",
		createMany:"AdBoardCreateManyLocationCategoryInputEnvelope",
		set:"AdBoardWhereUniqueInput",
		disconnect:"AdBoardWhereUniqueInput",
		delete:"AdBoardWhereUniqueInput",
		connect:"AdBoardWhereUniqueInput",
		update:"AdBoardUpdateWithWhereUniqueWithoutLocationCategoryInput",
		updateMany:"AdBoardUpdateManyWithWhereWithoutLocationCategoryInput",
		deleteMany:"AdBoardScalarWhereInput"
	},
	AdBoardUpsertWithWhereUniqueWithoutLocationCategoryInput:{
		where:"AdBoardWhereUniqueInput",
		update:"AdBoardUpdateWithoutLocationCategoryInput",
		create:"AdBoardCreateWithoutLocationCategoryInput"
	},
	AdBoardUpdateWithoutLocationCategoryInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationSubCategory:"LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput",
		type:"AdBoardTypeUpdateOneWithoutAdBoardsNestedInput",
		category:"BusinessCategoryUpdateOneWithoutAdBoardsNestedInput",
		restrictedBusines:"BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput",
		bookings:"BookingUpdateManyWithoutAdBoardNestedInput",
		orders:"OrderUpdateManyWithoutAdBoardNestedInput",
		slotConfigurations:"SlotConfigurationUpdateManyWithoutAdBoardNestedInput",
		offlineBookingsContact:"UserUpdateOneWithoutOfflineBookingAdboardsNestedInput",
		createdBy:"UserUpdateOneWithoutAdBoardsNestedInput",
		workspace:"WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput",
		players:"PlayerUpdateManyWithoutAdBoardsNestedInput",
		adBoardGroup:"AdBoardGroupUpdateOneWithoutAdBoardNestedInput",
		cameras:"CameraUpdateManyWithoutAdBoardNestedInput",
		publishedVersion:"AdBoardUpdateOneWithoutDraftAdboardNestedInput",
		draftAdboard:"AdBoardUpdateOneWithoutPublishedVersionNestedInput",
		history:"AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput",
		archivedBy:"UserUpdateOneWithoutArchivedAdboardsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"
	},
	BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput:{
		create:"BusinessCategoryCreateWithoutRestrictedByAdBoardsInput",
		connectOrCreate:"BusinessCategoryCreateOrConnectWithoutRestrictedByAdBoardsInput",
		upsert:"BusinessCategoryUpsertWithWhereUniqueWithoutRestrictedByAdBoardsInput",
		set:"BusinessCategoryWhereUniqueInput",
		disconnect:"BusinessCategoryWhereUniqueInput",
		delete:"BusinessCategoryWhereUniqueInput",
		connect:"BusinessCategoryWhereUniqueInput",
		update:"BusinessCategoryUpdateWithWhereUniqueWithoutRestrictedByAdBoardsInput",
		updateMany:"BusinessCategoryUpdateManyWithWhereWithoutRestrictedByAdBoardsInput",
		deleteMany:"BusinessCategoryScalarWhereInput"
	},
	BusinessCategoryUpsertWithWhereUniqueWithoutRestrictedByAdBoardsInput:{
		where:"BusinessCategoryWhereUniqueInput",
		update:"BusinessCategoryUpdateWithoutRestrictedByAdBoardsInput",
		create:"BusinessCategoryCreateWithoutRestrictedByAdBoardsInput"
	},
	BusinessCategoryUpdateWithoutRestrictedByAdBoardsInput:{
		adBoards:"AdBoardUpdateManyWithoutCategoryNestedInput",
		campaigns:"CampaignUpdateManyWithoutBusinessCategoryNestedInput",
		bookings:"BookingUpdateManyWithoutBusinessCategoryNestedInput"
	},
	AdBoardUpdateManyWithoutCategoryNestedInput:{
		create:"AdBoardCreateWithoutCategoryInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutCategoryInput",
		upsert:"AdBoardUpsertWithWhereUniqueWithoutCategoryInput",
		createMany:"AdBoardCreateManyCategoryInputEnvelope",
		set:"AdBoardWhereUniqueInput",
		disconnect:"AdBoardWhereUniqueInput",
		delete:"AdBoardWhereUniqueInput",
		connect:"AdBoardWhereUniqueInput",
		update:"AdBoardUpdateWithWhereUniqueWithoutCategoryInput",
		updateMany:"AdBoardUpdateManyWithWhereWithoutCategoryInput",
		deleteMany:"AdBoardScalarWhereInput"
	},
	AdBoardUpsertWithWhereUniqueWithoutCategoryInput:{
		where:"AdBoardWhereUniqueInput",
		update:"AdBoardUpdateWithoutCategoryInput",
		create:"AdBoardCreateWithoutCategoryInput"
	},
	AdBoardUpdateWithoutCategoryInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryUpdateOneWithoutAdBoardsNestedInput",
		locationSubCategory:"LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput",
		type:"AdBoardTypeUpdateOneWithoutAdBoardsNestedInput",
		restrictedBusines:"BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput",
		bookings:"BookingUpdateManyWithoutAdBoardNestedInput",
		orders:"OrderUpdateManyWithoutAdBoardNestedInput",
		slotConfigurations:"SlotConfigurationUpdateManyWithoutAdBoardNestedInput",
		offlineBookingsContact:"UserUpdateOneWithoutOfflineBookingAdboardsNestedInput",
		createdBy:"UserUpdateOneWithoutAdBoardsNestedInput",
		workspace:"WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput",
		players:"PlayerUpdateManyWithoutAdBoardsNestedInput",
		adBoardGroup:"AdBoardGroupUpdateOneWithoutAdBoardNestedInput",
		cameras:"CameraUpdateManyWithoutAdBoardNestedInput",
		publishedVersion:"AdBoardUpdateOneWithoutDraftAdboardNestedInput",
		draftAdboard:"AdBoardUpdateOneWithoutPublishedVersionNestedInput",
		history:"AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput",
		archivedBy:"UserUpdateOneWithoutArchivedAdboardsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"
	},
	BookingUpdateManyWithoutAdBoardNestedInput:{
		create:"BookingCreateWithoutAdBoardInput",
		connectOrCreate:"BookingCreateOrConnectWithoutAdBoardInput",
		upsert:"BookingUpsertWithWhereUniqueWithoutAdBoardInput",
		createMany:"BookingCreateManyAdBoardInputEnvelope",
		set:"BookingWhereUniqueInput",
		disconnect:"BookingWhereUniqueInput",
		delete:"BookingWhereUniqueInput",
		connect:"BookingWhereUniqueInput",
		update:"BookingUpdateWithWhereUniqueWithoutAdBoardInput",
		updateMany:"BookingUpdateManyWithWhereWithoutAdBoardInput",
		deleteMany:"BookingScalarWhereInput"
	},
	BookingUpsertWithWhereUniqueWithoutAdBoardInput:{
		where:"BookingWhereUniqueInput",
		update:"BookingUpdateWithoutAdBoardInput",
		create:"BookingCreateWithoutAdBoardInput"
	},
	BookingUpdateWithoutAdBoardInput:{
		bookingDate:"DateTime",
		bookingDetails:"BookingDetailsCreateInput",
		bookingStatus:"BookingStatus",
		paymentStatus:"PaymentStatus",
		deploymentStatus:"DeploymentStatus",
		contentApprovalStatus:"ContentApprovalStatus",
		selectedDates:"OrderSelectedDateCreateInput",
		payment:"BookingPaymentCreateInput",
		priceSummary:"PriceSummaryCreateInput",
		pisignage:"BookingPisignageDataCreateInput",
		refund:"BookingRefundCreateInput",
		assets:"FileUpdateManyWithoutBookingsNestedInput",
		businessCategory:"BusinessCategoryUpdateOneWithoutBookingsNestedInput",
		proofs:"FileUpdateManyWithoutProofBookingNestedInput",
		user:"UserUpdateOneRequiredWithoutBookingsNestedInput",
		changelogs:"ChangelogUpdateManyWithoutBookingNestedInput",
		workspace:"WorkspaceUpdateOneWithoutBookingsNestedInput",
		campaign:"CampaignUpdateOneWithoutBookingsNestedInput",
		payouts:"PayoutUpdateManyWithoutBookingNestedInput",
		sharedUsers:"UserUpdateManyWithoutSharedBookingsNestedInput",
		webhookLogs:"WebhookLogUpdateManyWithoutBookingNestedInput",
		slotUsage:"SlotUsageUpdateManyWithoutBookingNestedInput"
	},
	FileUpdateManyWithoutBookingsNestedInput:{
		create:"FileCreateWithoutBookingsInput",
		connectOrCreate:"FileCreateOrConnectWithoutBookingsInput",
		upsert:"FileUpsertWithWhereUniqueWithoutBookingsInput",
		set:"FileWhereUniqueInput",
		disconnect:"FileWhereUniqueInput",
		delete:"FileWhereUniqueInput",
		connect:"FileWhereUniqueInput",
		update:"FileUpdateWithWhereUniqueWithoutBookingsInput",
		updateMany:"FileUpdateManyWithWhereWithoutBookingsInput",
		deleteMany:"FileScalarWhereInput"
	},
	FileUpsertWithWhereUniqueWithoutBookingsInput:{
		where:"FileWhereUniqueInput",
		update:"FileUpdateWithoutBookingsInput",
		create:"FileCreateWithoutBookingsInput"
	},
	FileUpdateWithoutBookingsInput:{
		meta:"FileMetaCreateInput",
		status:"FileStatus",
		updatedAt:"DateTime",
		createdAt:"DateTime",
		updatedBy:"UserUpdateOneWithoutUpdatedFilesNestedInput",
		createdBy:"UserUpdateOneRequiredWithoutCreatedFilesNestedInput",
		workspace:"WorkspaceUpdateOneWithoutFilesNestedInput",
		proofBooking:"BookingUpdateOneWithoutProofsNestedInput",
		campaigns:"CampaignUpdateManyWithoutAssetsNestedInput"
	},
	UserUpdateOneWithoutUpdatedFilesNestedInput:{
		create:"UserCreateWithoutUpdatedFilesInput",
		connectOrCreate:"UserCreateOrConnectWithoutUpdatedFilesInput",
		upsert:"UserUpsertWithoutUpdatedFilesInput",
		delete:"UserWhereInput",
		connect:"UserWhereUniqueInput",
		update:"UserUpdateToOneWithWhereWithoutUpdatedFilesInput"
	},
	UserUpsertWithoutUpdatedFilesInput:{
		update:"UserUpdateWithoutUpdatedFilesInput",
		create:"UserCreateWithoutUpdatedFilesInput",
		where:"UserWhereInput"
	},
	UserUpdateWithoutUpdatedFilesInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingUpdateManyWithoutUserNestedInput",
		createdFiles:"FileUpdateManyWithoutCreatedByNestedInput",
		orders:"OrderUpdateManyWithoutCreatedByNestedInput",
		changelogs:"ChangelogUpdateManyWithoutUserNestedInput",
		adBoards:"AdBoardUpdateManyWithoutCreatedByNestedInput",
		audits:"AuditUpdateManyWithoutUserNestedInput",
		campaigns:"CampaignUpdateManyWithoutUserNestedInput",
		sharedBookings:"BookingUpdateManyWithoutSharedUsersNestedInput",
		memberships:"MembershipUpdateManyWithoutUserNestedInput",
		notifications:"NotificationUpdateManyWithoutRecipientNestedInput",
		publishAdboardVersion:"AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput",
		offlineBookingAdboards:"AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutUserNestedInput",
		archivedAdboards:"AdBoardUpdateManyWithoutArchivedByNestedInput",
		ownedWorkspaces:"WorkspaceUpdateManyWithoutOwnerNestedInput",
		leadWorspaces:"WorkspaceUpdateManyWithoutLeadsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutUserNestedInput",
		userPositions:"UserPositionUpdateManyWithoutUserNestedInput"
	},
	BookingUpdateManyWithoutUserNestedInput:{
		create:"BookingCreateWithoutUserInput",
		connectOrCreate:"BookingCreateOrConnectWithoutUserInput",
		upsert:"BookingUpsertWithWhereUniqueWithoutUserInput",
		createMany:"BookingCreateManyUserInputEnvelope",
		set:"BookingWhereUniqueInput",
		disconnect:"BookingWhereUniqueInput",
		delete:"BookingWhereUniqueInput",
		connect:"BookingWhereUniqueInput",
		update:"BookingUpdateWithWhereUniqueWithoutUserInput",
		updateMany:"BookingUpdateManyWithWhereWithoutUserInput",
		deleteMany:"BookingScalarWhereInput"
	},
	BookingUpsertWithWhereUniqueWithoutUserInput:{
		where:"BookingWhereUniqueInput",
		update:"BookingUpdateWithoutUserInput",
		create:"BookingCreateWithoutUserInput"
	},
	BookingUpdateWithoutUserInput:{
		bookingDate:"DateTime",
		bookingDetails:"BookingDetailsCreateInput",
		bookingStatus:"BookingStatus",
		paymentStatus:"PaymentStatus",
		deploymentStatus:"DeploymentStatus",
		contentApprovalStatus:"ContentApprovalStatus",
		selectedDates:"OrderSelectedDateCreateInput",
		payment:"BookingPaymentCreateInput",
		priceSummary:"PriceSummaryCreateInput",
		pisignage:"BookingPisignageDataCreateInput",
		refund:"BookingRefundCreateInput",
		assets:"FileUpdateManyWithoutBookingsNestedInput",
		businessCategory:"BusinessCategoryUpdateOneWithoutBookingsNestedInput",
		proofs:"FileUpdateManyWithoutProofBookingNestedInput",
		adBoard:"AdBoardUpdateOneRequiredWithoutBookingsNestedInput",
		changelogs:"ChangelogUpdateManyWithoutBookingNestedInput",
		workspace:"WorkspaceUpdateOneWithoutBookingsNestedInput",
		campaign:"CampaignUpdateOneWithoutBookingsNestedInput",
		payouts:"PayoutUpdateManyWithoutBookingNestedInput",
		sharedUsers:"UserUpdateManyWithoutSharedBookingsNestedInput",
		webhookLogs:"WebhookLogUpdateManyWithoutBookingNestedInput",
		slotUsage:"SlotUsageUpdateManyWithoutBookingNestedInput"
	},
	BusinessCategoryUpdateOneWithoutBookingsNestedInput:{
		create:"BusinessCategoryCreateWithoutBookingsInput",
		connectOrCreate:"BusinessCategoryCreateOrConnectWithoutBookingsInput",
		upsert:"BusinessCategoryUpsertWithoutBookingsInput",
		delete:"BusinessCategoryWhereInput",
		connect:"BusinessCategoryWhereUniqueInput",
		update:"BusinessCategoryUpdateToOneWithWhereWithoutBookingsInput"
	},
	BusinessCategoryUpsertWithoutBookingsInput:{
		update:"BusinessCategoryUpdateWithoutBookingsInput",
		create:"BusinessCategoryCreateWithoutBookingsInput",
		where:"BusinessCategoryWhereInput"
	},
	BusinessCategoryUpdateWithoutBookingsInput:{
		adBoards:"AdBoardUpdateManyWithoutCategoryNestedInput",
		restrictedByAdBoards:"AdBoardUpdateManyWithoutRestrictedBusinesNestedInput",
		campaigns:"CampaignUpdateManyWithoutBusinessCategoryNestedInput"
	},
	CampaignUpdateManyWithoutBusinessCategoryNestedInput:{
		create:"CampaignCreateWithoutBusinessCategoryInput",
		connectOrCreate:"CampaignCreateOrConnectWithoutBusinessCategoryInput",
		upsert:"CampaignUpsertWithWhereUniqueWithoutBusinessCategoryInput",
		createMany:"CampaignCreateManyBusinessCategoryInputEnvelope",
		set:"CampaignWhereUniqueInput",
		disconnect:"CampaignWhereUniqueInput",
		delete:"CampaignWhereUniqueInput",
		connect:"CampaignWhereUniqueInput",
		update:"CampaignUpdateWithWhereUniqueWithoutBusinessCategoryInput",
		updateMany:"CampaignUpdateManyWithWhereWithoutBusinessCategoryInput",
		deleteMany:"CampaignScalarWhereInput"
	},
	CampaignUpsertWithWhereUniqueWithoutBusinessCategoryInput:{
		where:"CampaignWhereUniqueInput",
		update:"CampaignUpdateWithoutBusinessCategoryInput",
		create:"CampaignCreateWithoutBusinessCategoryInput"
	},
	CampaignUpdateWithoutBusinessCategoryInput:{
		createdAt:"DateTime",
		updatedAt:"DateTime",
		payment:"BookingPaymentCreateInput",
		paymentStatus:"PaymentStatus",
		priceSummary:"PriceSummaryCreateInput",
		selectedDates:"OrderSelectedDateCreateInput",
		user:"UserUpdateOneRequiredWithoutCampaignsNestedInput",
		bookings:"BookingUpdateManyWithoutCampaignNestedInput",
		payments:"PaymentUpdateManyWithoutCampaignNestedInput",
		assets:"FileUpdateManyWithoutCampaignsNestedInput"
	},
	UserUpdateOneRequiredWithoutCampaignsNestedInput:{
		create:"UserCreateWithoutCampaignsInput",
		connectOrCreate:"UserCreateOrConnectWithoutCampaignsInput",
		upsert:"UserUpsertWithoutCampaignsInput",
		connect:"UserWhereUniqueInput",
		update:"UserUpdateToOneWithWhereWithoutCampaignsInput"
	},
	UserUpsertWithoutCampaignsInput:{
		update:"UserUpdateWithoutCampaignsInput",
		create:"UserCreateWithoutCampaignsInput",
		where:"UserWhereInput"
	},
	UserUpdateWithoutCampaignsInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingUpdateManyWithoutUserNestedInput",
		createdFiles:"FileUpdateManyWithoutCreatedByNestedInput",
		updatedFiles:"FileUpdateManyWithoutUpdatedByNestedInput",
		orders:"OrderUpdateManyWithoutCreatedByNestedInput",
		changelogs:"ChangelogUpdateManyWithoutUserNestedInput",
		adBoards:"AdBoardUpdateManyWithoutCreatedByNestedInput",
		audits:"AuditUpdateManyWithoutUserNestedInput",
		sharedBookings:"BookingUpdateManyWithoutSharedUsersNestedInput",
		memberships:"MembershipUpdateManyWithoutUserNestedInput",
		notifications:"NotificationUpdateManyWithoutRecipientNestedInput",
		publishAdboardVersion:"AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput",
		offlineBookingAdboards:"AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutUserNestedInput",
		archivedAdboards:"AdBoardUpdateManyWithoutArchivedByNestedInput",
		ownedWorkspaces:"WorkspaceUpdateManyWithoutOwnerNestedInput",
		leadWorspaces:"WorkspaceUpdateManyWithoutLeadsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutUserNestedInput",
		userPositions:"UserPositionUpdateManyWithoutUserNestedInput"
	},
	FileUpdateManyWithoutCreatedByNestedInput:{
		create:"FileCreateWithoutCreatedByInput",
		connectOrCreate:"FileCreateOrConnectWithoutCreatedByInput",
		upsert:"FileUpsertWithWhereUniqueWithoutCreatedByInput",
		createMany:"FileCreateManyCreatedByInputEnvelope",
		set:"FileWhereUniqueInput",
		disconnect:"FileWhereUniqueInput",
		delete:"FileWhereUniqueInput",
		connect:"FileWhereUniqueInput",
		update:"FileUpdateWithWhereUniqueWithoutCreatedByInput",
		updateMany:"FileUpdateManyWithWhereWithoutCreatedByInput",
		deleteMany:"FileScalarWhereInput"
	},
	FileUpsertWithWhereUniqueWithoutCreatedByInput:{
		where:"FileWhereUniqueInput",
		update:"FileUpdateWithoutCreatedByInput",
		create:"FileCreateWithoutCreatedByInput"
	},
	FileUpdateWithoutCreatedByInput:{
		meta:"FileMetaCreateInput",
		status:"FileStatus",
		updatedAt:"DateTime",
		createdAt:"DateTime",
		updatedBy:"UserUpdateOneWithoutUpdatedFilesNestedInput",
		bookings:"BookingUpdateManyWithoutAssetsNestedInput",
		workspace:"WorkspaceUpdateOneWithoutFilesNestedInput",
		proofBooking:"BookingUpdateOneWithoutProofsNestedInput",
		campaigns:"CampaignUpdateManyWithoutAssetsNestedInput"
	},
	BookingUpdateManyWithoutAssetsNestedInput:{
		create:"BookingCreateWithoutAssetsInput",
		connectOrCreate:"BookingCreateOrConnectWithoutAssetsInput",
		upsert:"BookingUpsertWithWhereUniqueWithoutAssetsInput",
		set:"BookingWhereUniqueInput",
		disconnect:"BookingWhereUniqueInput",
		delete:"BookingWhereUniqueInput",
		connect:"BookingWhereUniqueInput",
		update:"BookingUpdateWithWhereUniqueWithoutAssetsInput",
		updateMany:"BookingUpdateManyWithWhereWithoutAssetsInput",
		deleteMany:"BookingScalarWhereInput"
	},
	BookingUpsertWithWhereUniqueWithoutAssetsInput:{
		where:"BookingWhereUniqueInput",
		update:"BookingUpdateWithoutAssetsInput",
		create:"BookingCreateWithoutAssetsInput"
	},
	BookingUpdateWithoutAssetsInput:{
		bookingDate:"DateTime",
		bookingDetails:"BookingDetailsCreateInput",
		bookingStatus:"BookingStatus",
		paymentStatus:"PaymentStatus",
		deploymentStatus:"DeploymentStatus",
		contentApprovalStatus:"ContentApprovalStatus",
		selectedDates:"OrderSelectedDateCreateInput",
		payment:"BookingPaymentCreateInput",
		priceSummary:"PriceSummaryCreateInput",
		pisignage:"BookingPisignageDataCreateInput",
		refund:"BookingRefundCreateInput",
		businessCategory:"BusinessCategoryUpdateOneWithoutBookingsNestedInput",
		proofs:"FileUpdateManyWithoutProofBookingNestedInput",
		user:"UserUpdateOneRequiredWithoutBookingsNestedInput",
		adBoard:"AdBoardUpdateOneRequiredWithoutBookingsNestedInput",
		changelogs:"ChangelogUpdateManyWithoutBookingNestedInput",
		workspace:"WorkspaceUpdateOneWithoutBookingsNestedInput",
		campaign:"CampaignUpdateOneWithoutBookingsNestedInput",
		payouts:"PayoutUpdateManyWithoutBookingNestedInput",
		sharedUsers:"UserUpdateManyWithoutSharedBookingsNestedInput",
		webhookLogs:"WebhookLogUpdateManyWithoutBookingNestedInput",
		slotUsage:"SlotUsageUpdateManyWithoutBookingNestedInput"
	},
	FileUpdateManyWithoutProofBookingNestedInput:{
		create:"FileCreateWithoutProofBookingInput",
		connectOrCreate:"FileCreateOrConnectWithoutProofBookingInput",
		upsert:"FileUpsertWithWhereUniqueWithoutProofBookingInput",
		createMany:"FileCreateManyProofBookingInputEnvelope",
		set:"FileWhereUniqueInput",
		disconnect:"FileWhereUniqueInput",
		delete:"FileWhereUniqueInput",
		connect:"FileWhereUniqueInput",
		update:"FileUpdateWithWhereUniqueWithoutProofBookingInput",
		updateMany:"FileUpdateManyWithWhereWithoutProofBookingInput",
		deleteMany:"FileScalarWhereInput"
	},
	FileUpsertWithWhereUniqueWithoutProofBookingInput:{
		where:"FileWhereUniqueInput",
		update:"FileUpdateWithoutProofBookingInput",
		create:"FileCreateWithoutProofBookingInput"
	},
	FileUpdateWithoutProofBookingInput:{
		meta:"FileMetaCreateInput",
		status:"FileStatus",
		updatedAt:"DateTime",
		createdAt:"DateTime",
		updatedBy:"UserUpdateOneWithoutUpdatedFilesNestedInput",
		createdBy:"UserUpdateOneRequiredWithoutCreatedFilesNestedInput",
		bookings:"BookingUpdateManyWithoutAssetsNestedInput",
		workspace:"WorkspaceUpdateOneWithoutFilesNestedInput",
		campaigns:"CampaignUpdateManyWithoutAssetsNestedInput"
	},
	UserUpdateOneRequiredWithoutCreatedFilesNestedInput:{
		create:"UserCreateWithoutCreatedFilesInput",
		connectOrCreate:"UserCreateOrConnectWithoutCreatedFilesInput",
		upsert:"UserUpsertWithoutCreatedFilesInput",
		connect:"UserWhereUniqueInput",
		update:"UserUpdateToOneWithWhereWithoutCreatedFilesInput"
	},
	UserUpsertWithoutCreatedFilesInput:{
		update:"UserUpdateWithoutCreatedFilesInput",
		create:"UserCreateWithoutCreatedFilesInput",
		where:"UserWhereInput"
	},
	UserUpdateWithoutCreatedFilesInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingUpdateManyWithoutUserNestedInput",
		updatedFiles:"FileUpdateManyWithoutUpdatedByNestedInput",
		orders:"OrderUpdateManyWithoutCreatedByNestedInput",
		changelogs:"ChangelogUpdateManyWithoutUserNestedInput",
		adBoards:"AdBoardUpdateManyWithoutCreatedByNestedInput",
		audits:"AuditUpdateManyWithoutUserNestedInput",
		campaigns:"CampaignUpdateManyWithoutUserNestedInput",
		sharedBookings:"BookingUpdateManyWithoutSharedUsersNestedInput",
		memberships:"MembershipUpdateManyWithoutUserNestedInput",
		notifications:"NotificationUpdateManyWithoutRecipientNestedInput",
		publishAdboardVersion:"AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput",
		offlineBookingAdboards:"AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutUserNestedInput",
		archivedAdboards:"AdBoardUpdateManyWithoutArchivedByNestedInput",
		ownedWorkspaces:"WorkspaceUpdateManyWithoutOwnerNestedInput",
		leadWorspaces:"WorkspaceUpdateManyWithoutLeadsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutUserNestedInput",
		userPositions:"UserPositionUpdateManyWithoutUserNestedInput"
	},
	FileUpdateManyWithoutUpdatedByNestedInput:{
		create:"FileCreateWithoutUpdatedByInput",
		connectOrCreate:"FileCreateOrConnectWithoutUpdatedByInput",
		upsert:"FileUpsertWithWhereUniqueWithoutUpdatedByInput",
		createMany:"FileCreateManyUpdatedByInputEnvelope",
		set:"FileWhereUniqueInput",
		disconnect:"FileWhereUniqueInput",
		delete:"FileWhereUniqueInput",
		connect:"FileWhereUniqueInput",
		update:"FileUpdateWithWhereUniqueWithoutUpdatedByInput",
		updateMany:"FileUpdateManyWithWhereWithoutUpdatedByInput",
		deleteMany:"FileScalarWhereInput"
	},
	FileUpsertWithWhereUniqueWithoutUpdatedByInput:{
		where:"FileWhereUniqueInput",
		update:"FileUpdateWithoutUpdatedByInput",
		create:"FileCreateWithoutUpdatedByInput"
	},
	FileUpdateWithoutUpdatedByInput:{
		meta:"FileMetaCreateInput",
		status:"FileStatus",
		updatedAt:"DateTime",
		createdAt:"DateTime",
		createdBy:"UserUpdateOneRequiredWithoutCreatedFilesNestedInput",
		bookings:"BookingUpdateManyWithoutAssetsNestedInput",
		workspace:"WorkspaceUpdateOneWithoutFilesNestedInput",
		proofBooking:"BookingUpdateOneWithoutProofsNestedInput",
		campaigns:"CampaignUpdateManyWithoutAssetsNestedInput"
	},
	WorkspaceUpdateOneWithoutFilesNestedInput:{
		create:"WorkspaceCreateWithoutFilesInput",
		connectOrCreate:"WorkspaceCreateOrConnectWithoutFilesInput",
		upsert:"WorkspaceUpsertWithoutFilesInput",
		delete:"WorkspaceWhereInput",
		connect:"WorkspaceWhereUniqueInput",
		update:"WorkspaceUpdateToOneWithWhereWithoutFilesInput"
	},
	WorkspaceUpsertWithoutFilesInput:{
		update:"WorkspaceUpdateWithoutFilesInput",
		create:"WorkspaceCreateWithoutFilesInput",
		where:"WorkspaceWhereInput"
	},
	WorkspaceUpdateWithoutFilesInput:{
		type:"WorkspaceType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		owner:"UserUpdateOneWithoutOwnedWorkspacesNestedInput",
		memberships:"MembershipUpdateManyWithoutWorkspaceNestedInput",
		adBoards:"AdBoardUpdateManyWithoutWorkspaceNestedInput",
		playlists:"PlaylistUpdateManyWithoutWorkspaceNestedInput",
		adBoardGroups:"AdBoardGroupUpdateManyWithoutWorkspaceNestedInput",
		audits:"AuditUpdateManyWithoutWorkspaceNestedInput",
		bookings:"BookingUpdateManyWithoutWorkspaceNestedInput",
		players:"PlayerUpdateManyWithoutWorkspaceNestedInput",
		transactions:"TransactionUpdateManyWithoutWorkspaceNestedInput",
		activeSubscription:"SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput",
		cameraServers:"CameraServerUpdateManyWithoutWorkspaceNestedInput",
		workspaceRoles:"WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput",
		webhookLogs:"WebhookLogUpdateManyWithoutWorkspaceNestedInput",
		leads:"UserUpdateManyWithoutLeadWorspacesNestedInput"
	},
	UserUpdateOneWithoutOwnedWorkspacesNestedInput:{
		create:"UserCreateWithoutOwnedWorkspacesInput",
		connectOrCreate:"UserCreateOrConnectWithoutOwnedWorkspacesInput",
		upsert:"UserUpsertWithoutOwnedWorkspacesInput",
		delete:"UserWhereInput",
		connect:"UserWhereUniqueInput",
		update:"UserUpdateToOneWithWhereWithoutOwnedWorkspacesInput"
	},
	UserUpsertWithoutOwnedWorkspacesInput:{
		update:"UserUpdateWithoutOwnedWorkspacesInput",
		create:"UserCreateWithoutOwnedWorkspacesInput",
		where:"UserWhereInput"
	},
	UserUpdateWithoutOwnedWorkspacesInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingUpdateManyWithoutUserNestedInput",
		createdFiles:"FileUpdateManyWithoutCreatedByNestedInput",
		updatedFiles:"FileUpdateManyWithoutUpdatedByNestedInput",
		orders:"OrderUpdateManyWithoutCreatedByNestedInput",
		changelogs:"ChangelogUpdateManyWithoutUserNestedInput",
		adBoards:"AdBoardUpdateManyWithoutCreatedByNestedInput",
		audits:"AuditUpdateManyWithoutUserNestedInput",
		campaigns:"CampaignUpdateManyWithoutUserNestedInput",
		sharedBookings:"BookingUpdateManyWithoutSharedUsersNestedInput",
		memberships:"MembershipUpdateManyWithoutUserNestedInput",
		notifications:"NotificationUpdateManyWithoutRecipientNestedInput",
		publishAdboardVersion:"AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput",
		offlineBookingAdboards:"AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutUserNestedInput",
		archivedAdboards:"AdBoardUpdateManyWithoutArchivedByNestedInput",
		leadWorspaces:"WorkspaceUpdateManyWithoutLeadsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutUserNestedInput",
		userPositions:"UserPositionUpdateManyWithoutUserNestedInput"
	},
	OrderUpdateManyWithoutCreatedByNestedInput:{
		create:"OrderCreateWithoutCreatedByInput",
		connectOrCreate:"OrderCreateOrConnectWithoutCreatedByInput",
		upsert:"OrderUpsertWithWhereUniqueWithoutCreatedByInput",
		createMany:"OrderCreateManyCreatedByInputEnvelope",
		set:"OrderWhereUniqueInput",
		disconnect:"OrderWhereUniqueInput",
		delete:"OrderWhereUniqueInput",
		connect:"OrderWhereUniqueInput",
		update:"OrderUpdateWithWhereUniqueWithoutCreatedByInput",
		updateMany:"OrderUpdateManyWithWhereWithoutCreatedByInput",
		deleteMany:"OrderScalarWhereInput"
	},
	OrderUpsertWithWhereUniqueWithoutCreatedByInput:{
		where:"OrderWhereUniqueInput",
		update:"OrderUpdateWithoutCreatedByInput",
		create:"OrderCreateWithoutCreatedByInput"
	},
	OrderUpdateWithoutCreatedByInput:{
		createdDate:"DateTime",
		priceSummary:"PriceSummaryCreateInput",
		selectedDate:"OrderSelectedDateCreateInput",
		adBoard:"AdBoardUpdateOneRequiredWithoutOrdersNestedInput"
	},
	AdBoardUpdateOneRequiredWithoutOrdersNestedInput:{
		create:"AdBoardCreateWithoutOrdersInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutOrdersInput",
		upsert:"AdBoardUpsertWithoutOrdersInput",
		connect:"AdBoardWhereUniqueInput",
		update:"AdBoardUpdateToOneWithWhereWithoutOrdersInput"
	},
	AdBoardUpsertWithoutOrdersInput:{
		update:"AdBoardUpdateWithoutOrdersInput",
		create:"AdBoardCreateWithoutOrdersInput",
		where:"AdBoardWhereInput"
	},
	AdBoardUpdateWithoutOrdersInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryUpdateOneWithoutAdBoardsNestedInput",
		locationSubCategory:"LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput",
		type:"AdBoardTypeUpdateOneWithoutAdBoardsNestedInput",
		category:"BusinessCategoryUpdateOneWithoutAdBoardsNestedInput",
		restrictedBusines:"BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput",
		bookings:"BookingUpdateManyWithoutAdBoardNestedInput",
		slotConfigurations:"SlotConfigurationUpdateManyWithoutAdBoardNestedInput",
		offlineBookingsContact:"UserUpdateOneWithoutOfflineBookingAdboardsNestedInput",
		createdBy:"UserUpdateOneWithoutAdBoardsNestedInput",
		workspace:"WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput",
		players:"PlayerUpdateManyWithoutAdBoardsNestedInput",
		adBoardGroup:"AdBoardGroupUpdateOneWithoutAdBoardNestedInput",
		cameras:"CameraUpdateManyWithoutAdBoardNestedInput",
		publishedVersion:"AdBoardUpdateOneWithoutDraftAdboardNestedInput",
		draftAdboard:"AdBoardUpdateOneWithoutPublishedVersionNestedInput",
		history:"AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput",
		archivedBy:"UserUpdateOneWithoutArchivedAdboardsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"
	},
	SlotConfigurationUpdateManyWithoutAdBoardNestedInput:{
		create:"SlotConfigurationCreateWithoutAdBoardInput",
		connectOrCreate:"SlotConfigurationCreateOrConnectWithoutAdBoardInput",
		upsert:"SlotConfigurationUpsertWithWhereUniqueWithoutAdBoardInput",
		createMany:"SlotConfigurationCreateManyAdBoardInputEnvelope",
		set:"SlotConfigurationWhereUniqueInput",
		disconnect:"SlotConfigurationWhereUniqueInput",
		delete:"SlotConfigurationWhereUniqueInput",
		connect:"SlotConfigurationWhereUniqueInput",
		update:"SlotConfigurationUpdateWithWhereUniqueWithoutAdBoardInput",
		updateMany:"SlotConfigurationUpdateManyWithWhereWithoutAdBoardInput",
		deleteMany:"SlotConfigurationScalarWhereInput"
	},
	SlotConfigurationUpsertWithWhereUniqueWithoutAdBoardInput:{
		where:"SlotConfigurationWhereUniqueInput",
		update:"SlotConfigurationUpdateWithoutAdBoardInput",
		create:"SlotConfigurationCreateWithoutAdBoardInput"
	},
	SlotConfigurationUpdateWithoutAdBoardInput:{
		date:"DateTime"
	},
	SlotConfigurationUpdateWithWhereUniqueWithoutAdBoardInput:{
		where:"SlotConfigurationWhereUniqueInput",
		data:"SlotConfigurationUpdateWithoutAdBoardInput"
	},
	SlotConfigurationUpdateManyWithWhereWithoutAdBoardInput:{
		where:"SlotConfigurationScalarWhereInput",
		data:"SlotConfigurationUpdateManyMutationInput"
	},
	SlotConfigurationScalarWhereInput:{
		AND:"SlotConfigurationScalarWhereInput",
		OR:"SlotConfigurationScalarWhereInput",
		NOT:"SlotConfigurationScalarWhereInput",
		id:"StringFilter",
		adBoardId:"StringFilter",
		date:"DateTimeFilter",
		availableSlots:"IntFilter"
	},
	SlotConfigurationUpdateManyMutationInput:{
		date:"DateTime"
	},
	UserUpdateOneWithoutOfflineBookingAdboardsNestedInput:{
		create:"UserCreateWithoutOfflineBookingAdboardsInput",
		connectOrCreate:"UserCreateOrConnectWithoutOfflineBookingAdboardsInput",
		upsert:"UserUpsertWithoutOfflineBookingAdboardsInput",
		delete:"UserWhereInput",
		connect:"UserWhereUniqueInput",
		update:"UserUpdateToOneWithWhereWithoutOfflineBookingAdboardsInput"
	},
	UserUpsertWithoutOfflineBookingAdboardsInput:{
		update:"UserUpdateWithoutOfflineBookingAdboardsInput",
		create:"UserCreateWithoutOfflineBookingAdboardsInput",
		where:"UserWhereInput"
	},
	UserUpdateWithoutOfflineBookingAdboardsInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingUpdateManyWithoutUserNestedInput",
		createdFiles:"FileUpdateManyWithoutCreatedByNestedInput",
		updatedFiles:"FileUpdateManyWithoutUpdatedByNestedInput",
		orders:"OrderUpdateManyWithoutCreatedByNestedInput",
		changelogs:"ChangelogUpdateManyWithoutUserNestedInput",
		adBoards:"AdBoardUpdateManyWithoutCreatedByNestedInput",
		audits:"AuditUpdateManyWithoutUserNestedInput",
		campaigns:"CampaignUpdateManyWithoutUserNestedInput",
		sharedBookings:"BookingUpdateManyWithoutSharedUsersNestedInput",
		memberships:"MembershipUpdateManyWithoutUserNestedInput",
		notifications:"NotificationUpdateManyWithoutRecipientNestedInput",
		publishAdboardVersion:"AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutUserNestedInput",
		archivedAdboards:"AdBoardUpdateManyWithoutArchivedByNestedInput",
		ownedWorkspaces:"WorkspaceUpdateManyWithoutOwnerNestedInput",
		leadWorspaces:"WorkspaceUpdateManyWithoutLeadsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutUserNestedInput",
		userPositions:"UserPositionUpdateManyWithoutUserNestedInput"
	},
	ChangelogUpdateManyWithoutUserNestedInput:{
		create:"ChangelogCreateWithoutUserInput",
		connectOrCreate:"ChangelogCreateOrConnectWithoutUserInput",
		upsert:"ChangelogUpsertWithWhereUniqueWithoutUserInput",
		createMany:"ChangelogCreateManyUserInputEnvelope",
		set:"ChangelogWhereUniqueInput",
		disconnect:"ChangelogWhereUniqueInput",
		delete:"ChangelogWhereUniqueInput",
		connect:"ChangelogWhereUniqueInput",
		update:"ChangelogUpdateWithWhereUniqueWithoutUserInput",
		updateMany:"ChangelogUpdateManyWithWhereWithoutUserInput",
		deleteMany:"ChangelogScalarWhereInput"
	},
	ChangelogUpsertWithWhereUniqueWithoutUserInput:{
		where:"ChangelogWhereUniqueInput",
		update:"ChangelogUpdateWithoutUserInput",
		create:"ChangelogCreateWithoutUserInput"
	},
	ChangelogUpdateWithoutUserInput:{
		createdAt:"DateTime",
		updates:"JSON",
		booking:"BookingUpdateOneWithoutChangelogsNestedInput"
	},
	BookingUpdateOneWithoutChangelogsNestedInput:{
		create:"BookingCreateWithoutChangelogsInput",
		connectOrCreate:"BookingCreateOrConnectWithoutChangelogsInput",
		upsert:"BookingUpsertWithoutChangelogsInput",
		delete:"BookingWhereInput",
		connect:"BookingWhereUniqueInput",
		update:"BookingUpdateToOneWithWhereWithoutChangelogsInput"
	},
	BookingUpsertWithoutChangelogsInput:{
		update:"BookingUpdateWithoutChangelogsInput",
		create:"BookingCreateWithoutChangelogsInput",
		where:"BookingWhereInput"
	},
	BookingUpdateWithoutChangelogsInput:{
		bookingDate:"DateTime",
		bookingDetails:"BookingDetailsCreateInput",
		bookingStatus:"BookingStatus",
		paymentStatus:"PaymentStatus",
		deploymentStatus:"DeploymentStatus",
		contentApprovalStatus:"ContentApprovalStatus",
		selectedDates:"OrderSelectedDateCreateInput",
		payment:"BookingPaymentCreateInput",
		priceSummary:"PriceSummaryCreateInput",
		pisignage:"BookingPisignageDataCreateInput",
		refund:"BookingRefundCreateInput",
		assets:"FileUpdateManyWithoutBookingsNestedInput",
		businessCategory:"BusinessCategoryUpdateOneWithoutBookingsNestedInput",
		proofs:"FileUpdateManyWithoutProofBookingNestedInput",
		user:"UserUpdateOneRequiredWithoutBookingsNestedInput",
		adBoard:"AdBoardUpdateOneRequiredWithoutBookingsNestedInput",
		workspace:"WorkspaceUpdateOneWithoutBookingsNestedInput",
		campaign:"CampaignUpdateOneWithoutBookingsNestedInput",
		payouts:"PayoutUpdateManyWithoutBookingNestedInput",
		sharedUsers:"UserUpdateManyWithoutSharedBookingsNestedInput",
		webhookLogs:"WebhookLogUpdateManyWithoutBookingNestedInput",
		slotUsage:"SlotUsageUpdateManyWithoutBookingNestedInput"
	},
	UserUpdateOneRequiredWithoutBookingsNestedInput:{
		create:"UserCreateWithoutBookingsInput",
		connectOrCreate:"UserCreateOrConnectWithoutBookingsInput",
		upsert:"UserUpsertWithoutBookingsInput",
		connect:"UserWhereUniqueInput",
		update:"UserUpdateToOneWithWhereWithoutBookingsInput"
	},
	UserUpsertWithoutBookingsInput:{
		update:"UserUpdateWithoutBookingsInput",
		create:"UserCreateWithoutBookingsInput",
		where:"UserWhereInput"
	},
	UserUpdateWithoutBookingsInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		createdFiles:"FileUpdateManyWithoutCreatedByNestedInput",
		updatedFiles:"FileUpdateManyWithoutUpdatedByNestedInput",
		orders:"OrderUpdateManyWithoutCreatedByNestedInput",
		changelogs:"ChangelogUpdateManyWithoutUserNestedInput",
		adBoards:"AdBoardUpdateManyWithoutCreatedByNestedInput",
		audits:"AuditUpdateManyWithoutUserNestedInput",
		campaigns:"CampaignUpdateManyWithoutUserNestedInput",
		sharedBookings:"BookingUpdateManyWithoutSharedUsersNestedInput",
		memberships:"MembershipUpdateManyWithoutUserNestedInput",
		notifications:"NotificationUpdateManyWithoutRecipientNestedInput",
		publishAdboardVersion:"AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput",
		offlineBookingAdboards:"AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutUserNestedInput",
		archivedAdboards:"AdBoardUpdateManyWithoutArchivedByNestedInput",
		ownedWorkspaces:"WorkspaceUpdateManyWithoutOwnerNestedInput",
		leadWorspaces:"WorkspaceUpdateManyWithoutLeadsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutUserNestedInput",
		userPositions:"UserPositionUpdateManyWithoutUserNestedInput"
	},
	AdBoardUpdateManyWithoutCreatedByNestedInput:{
		create:"AdBoardCreateWithoutCreatedByInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutCreatedByInput",
		upsert:"AdBoardUpsertWithWhereUniqueWithoutCreatedByInput",
		createMany:"AdBoardCreateManyCreatedByInputEnvelope",
		set:"AdBoardWhereUniqueInput",
		disconnect:"AdBoardWhereUniqueInput",
		delete:"AdBoardWhereUniqueInput",
		connect:"AdBoardWhereUniqueInput",
		update:"AdBoardUpdateWithWhereUniqueWithoutCreatedByInput",
		updateMany:"AdBoardUpdateManyWithWhereWithoutCreatedByInput",
		deleteMany:"AdBoardScalarWhereInput"
	},
	AdBoardUpsertWithWhereUniqueWithoutCreatedByInput:{
		where:"AdBoardWhereUniqueInput",
		update:"AdBoardUpdateWithoutCreatedByInput",
		create:"AdBoardCreateWithoutCreatedByInput"
	},
	AdBoardUpdateWithoutCreatedByInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryUpdateOneWithoutAdBoardsNestedInput",
		locationSubCategory:"LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput",
		type:"AdBoardTypeUpdateOneWithoutAdBoardsNestedInput",
		category:"BusinessCategoryUpdateOneWithoutAdBoardsNestedInput",
		restrictedBusines:"BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput",
		bookings:"BookingUpdateManyWithoutAdBoardNestedInput",
		orders:"OrderUpdateManyWithoutAdBoardNestedInput",
		slotConfigurations:"SlotConfigurationUpdateManyWithoutAdBoardNestedInput",
		offlineBookingsContact:"UserUpdateOneWithoutOfflineBookingAdboardsNestedInput",
		workspace:"WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput",
		players:"PlayerUpdateManyWithoutAdBoardsNestedInput",
		adBoardGroup:"AdBoardGroupUpdateOneWithoutAdBoardNestedInput",
		cameras:"CameraUpdateManyWithoutAdBoardNestedInput",
		publishedVersion:"AdBoardUpdateOneWithoutDraftAdboardNestedInput",
		draftAdboard:"AdBoardUpdateOneWithoutPublishedVersionNestedInput",
		history:"AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput",
		archivedBy:"UserUpdateOneWithoutArchivedAdboardsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"
	},
	OrderUpdateManyWithoutAdBoardNestedInput:{
		create:"OrderCreateWithoutAdBoardInput",
		connectOrCreate:"OrderCreateOrConnectWithoutAdBoardInput",
		upsert:"OrderUpsertWithWhereUniqueWithoutAdBoardInput",
		createMany:"OrderCreateManyAdBoardInputEnvelope",
		set:"OrderWhereUniqueInput",
		disconnect:"OrderWhereUniqueInput",
		delete:"OrderWhereUniqueInput",
		connect:"OrderWhereUniqueInput",
		update:"OrderUpdateWithWhereUniqueWithoutAdBoardInput",
		updateMany:"OrderUpdateManyWithWhereWithoutAdBoardInput",
		deleteMany:"OrderScalarWhereInput"
	},
	OrderUpsertWithWhereUniqueWithoutAdBoardInput:{
		where:"OrderWhereUniqueInput",
		update:"OrderUpdateWithoutAdBoardInput",
		create:"OrderCreateWithoutAdBoardInput"
	},
	OrderUpdateWithoutAdBoardInput:{
		createdDate:"DateTime",
		priceSummary:"PriceSummaryCreateInput",
		selectedDate:"OrderSelectedDateCreateInput",
		createdBy:"UserUpdateOneWithoutOrdersNestedInput"
	},
	UserUpdateOneWithoutOrdersNestedInput:{
		create:"UserCreateWithoutOrdersInput",
		connectOrCreate:"UserCreateOrConnectWithoutOrdersInput",
		upsert:"UserUpsertWithoutOrdersInput",
		delete:"UserWhereInput",
		connect:"UserWhereUniqueInput",
		update:"UserUpdateToOneWithWhereWithoutOrdersInput"
	},
	UserUpsertWithoutOrdersInput:{
		update:"UserUpdateWithoutOrdersInput",
		create:"UserCreateWithoutOrdersInput",
		where:"UserWhereInput"
	},
	UserUpdateWithoutOrdersInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingUpdateManyWithoutUserNestedInput",
		createdFiles:"FileUpdateManyWithoutCreatedByNestedInput",
		updatedFiles:"FileUpdateManyWithoutUpdatedByNestedInput",
		changelogs:"ChangelogUpdateManyWithoutUserNestedInput",
		adBoards:"AdBoardUpdateManyWithoutCreatedByNestedInput",
		audits:"AuditUpdateManyWithoutUserNestedInput",
		campaigns:"CampaignUpdateManyWithoutUserNestedInput",
		sharedBookings:"BookingUpdateManyWithoutSharedUsersNestedInput",
		memberships:"MembershipUpdateManyWithoutUserNestedInput",
		notifications:"NotificationUpdateManyWithoutRecipientNestedInput",
		publishAdboardVersion:"AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput",
		offlineBookingAdboards:"AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutUserNestedInput",
		archivedAdboards:"AdBoardUpdateManyWithoutArchivedByNestedInput",
		ownedWorkspaces:"WorkspaceUpdateManyWithoutOwnerNestedInput",
		leadWorspaces:"WorkspaceUpdateManyWithoutLeadsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutUserNestedInput",
		userPositions:"UserPositionUpdateManyWithoutUserNestedInput"
	},
	AuditUpdateManyWithoutUserNestedInput:{
		create:"AuditCreateWithoutUserInput",
		connectOrCreate:"AuditCreateOrConnectWithoutUserInput",
		upsert:"AuditUpsertWithWhereUniqueWithoutUserInput",
		createMany:"AuditCreateManyUserInputEnvelope",
		set:"AuditWhereUniqueInput",
		disconnect:"AuditWhereUniqueInput",
		delete:"AuditWhereUniqueInput",
		connect:"AuditWhereUniqueInput",
		update:"AuditUpdateWithWhereUniqueWithoutUserInput",
		updateMany:"AuditUpdateManyWithWhereWithoutUserInput",
		deleteMany:"AuditScalarWhereInput"
	},
	AuditUpsertWithWhereUniqueWithoutUserInput:{
		where:"AuditWhereUniqueInput",
		update:"AuditUpdateWithoutUserInput",
		create:"AuditCreateWithoutUserInput"
	},
	AuditUpdateWithoutUserInput:{
		createdAt:"DateTime",
		entity:"EntityCreateInput",
		updates:"JSON",
		workspace:"WorkspaceUpdateOneWithoutAuditsNestedInput"
	},
	WorkspaceUpdateOneWithoutAuditsNestedInput:{
		create:"WorkspaceCreateWithoutAuditsInput",
		connectOrCreate:"WorkspaceCreateOrConnectWithoutAuditsInput",
		upsert:"WorkspaceUpsertWithoutAuditsInput",
		delete:"WorkspaceWhereInput",
		connect:"WorkspaceWhereUniqueInput",
		update:"WorkspaceUpdateToOneWithWhereWithoutAuditsInput"
	},
	WorkspaceUpsertWithoutAuditsInput:{
		update:"WorkspaceUpdateWithoutAuditsInput",
		create:"WorkspaceCreateWithoutAuditsInput",
		where:"WorkspaceWhereInput"
	},
	WorkspaceUpdateWithoutAuditsInput:{
		type:"WorkspaceType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		owner:"UserUpdateOneWithoutOwnedWorkspacesNestedInput",
		memberships:"MembershipUpdateManyWithoutWorkspaceNestedInput",
		adBoards:"AdBoardUpdateManyWithoutWorkspaceNestedInput",
		playlists:"PlaylistUpdateManyWithoutWorkspaceNestedInput",
		adBoardGroups:"AdBoardGroupUpdateManyWithoutWorkspaceNestedInput",
		bookings:"BookingUpdateManyWithoutWorkspaceNestedInput",
		files:"FileUpdateManyWithoutWorkspaceNestedInput",
		players:"PlayerUpdateManyWithoutWorkspaceNestedInput",
		transactions:"TransactionUpdateManyWithoutWorkspaceNestedInput",
		activeSubscription:"SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput",
		cameraServers:"CameraServerUpdateManyWithoutWorkspaceNestedInput",
		workspaceRoles:"WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput",
		webhookLogs:"WebhookLogUpdateManyWithoutWorkspaceNestedInput",
		leads:"UserUpdateManyWithoutLeadWorspacesNestedInput"
	},
	MembershipUpdateManyWithoutWorkspaceNestedInput:{
		create:"MembershipCreateWithoutWorkspaceInput",
		connectOrCreate:"MembershipCreateOrConnectWithoutWorkspaceInput",
		upsert:"MembershipUpsertWithWhereUniqueWithoutWorkspaceInput",
		createMany:"MembershipCreateManyWorkspaceInputEnvelope",
		set:"MembershipWhereUniqueInput",
		disconnect:"MembershipWhereUniqueInput",
		delete:"MembershipWhereUniqueInput",
		connect:"MembershipWhereUniqueInput",
		update:"MembershipUpdateWithWhereUniqueWithoutWorkspaceInput",
		updateMany:"MembershipUpdateManyWithWhereWithoutWorkspaceInput",
		deleteMany:"MembershipScalarWhereInput"
	},
	MembershipUpsertWithWhereUniqueWithoutWorkspaceInput:{
		where:"MembershipWhereUniqueInput",
		update:"MembershipUpdateWithoutWorkspaceInput",
		create:"MembershipCreateWithoutWorkspaceInput"
	},
	MembershipUpdateWithoutWorkspaceInput:{
		createdAt:"DateTime",
		user:"UserUpdateOneRequiredWithoutMembershipsNestedInput",
		role:"WorkspaceRoleUpdateOneRequiredWithoutMembershipsNestedInput"
	},
	UserUpdateOneRequiredWithoutMembershipsNestedInput:{
		create:"UserCreateWithoutMembershipsInput",
		connectOrCreate:"UserCreateOrConnectWithoutMembershipsInput",
		upsert:"UserUpsertWithoutMembershipsInput",
		connect:"UserWhereUniqueInput",
		update:"UserUpdateToOneWithWhereWithoutMembershipsInput"
	},
	UserUpsertWithoutMembershipsInput:{
		update:"UserUpdateWithoutMembershipsInput",
		create:"UserCreateWithoutMembershipsInput",
		where:"UserWhereInput"
	},
	UserUpdateWithoutMembershipsInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingUpdateManyWithoutUserNestedInput",
		createdFiles:"FileUpdateManyWithoutCreatedByNestedInput",
		updatedFiles:"FileUpdateManyWithoutUpdatedByNestedInput",
		orders:"OrderUpdateManyWithoutCreatedByNestedInput",
		changelogs:"ChangelogUpdateManyWithoutUserNestedInput",
		adBoards:"AdBoardUpdateManyWithoutCreatedByNestedInput",
		audits:"AuditUpdateManyWithoutUserNestedInput",
		campaigns:"CampaignUpdateManyWithoutUserNestedInput",
		sharedBookings:"BookingUpdateManyWithoutSharedUsersNestedInput",
		notifications:"NotificationUpdateManyWithoutRecipientNestedInput",
		publishAdboardVersion:"AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput",
		offlineBookingAdboards:"AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutUserNestedInput",
		archivedAdboards:"AdBoardUpdateManyWithoutArchivedByNestedInput",
		ownedWorkspaces:"WorkspaceUpdateManyWithoutOwnerNestedInput",
		leadWorspaces:"WorkspaceUpdateManyWithoutLeadsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutUserNestedInput",
		userPositions:"UserPositionUpdateManyWithoutUserNestedInput"
	},
	CampaignUpdateManyWithoutUserNestedInput:{
		create:"CampaignCreateWithoutUserInput",
		connectOrCreate:"CampaignCreateOrConnectWithoutUserInput",
		upsert:"CampaignUpsertWithWhereUniqueWithoutUserInput",
		createMany:"CampaignCreateManyUserInputEnvelope",
		set:"CampaignWhereUniqueInput",
		disconnect:"CampaignWhereUniqueInput",
		delete:"CampaignWhereUniqueInput",
		connect:"CampaignWhereUniqueInput",
		update:"CampaignUpdateWithWhereUniqueWithoutUserInput",
		updateMany:"CampaignUpdateManyWithWhereWithoutUserInput",
		deleteMany:"CampaignScalarWhereInput"
	},
	CampaignUpsertWithWhereUniqueWithoutUserInput:{
		where:"CampaignWhereUniqueInput",
		update:"CampaignUpdateWithoutUserInput",
		create:"CampaignCreateWithoutUserInput"
	},
	CampaignUpdateWithoutUserInput:{
		createdAt:"DateTime",
		updatedAt:"DateTime",
		payment:"BookingPaymentCreateInput",
		paymentStatus:"PaymentStatus",
		priceSummary:"PriceSummaryCreateInput",
		selectedDates:"OrderSelectedDateCreateInput",
		businessCategory:"BusinessCategoryUpdateOneRequiredWithoutCampaignsNestedInput",
		bookings:"BookingUpdateManyWithoutCampaignNestedInput",
		payments:"PaymentUpdateManyWithoutCampaignNestedInput",
		assets:"FileUpdateManyWithoutCampaignsNestedInput"
	},
	BusinessCategoryUpdateOneRequiredWithoutCampaignsNestedInput:{
		create:"BusinessCategoryCreateWithoutCampaignsInput",
		connectOrCreate:"BusinessCategoryCreateOrConnectWithoutCampaignsInput",
		upsert:"BusinessCategoryUpsertWithoutCampaignsInput",
		connect:"BusinessCategoryWhereUniqueInput",
		update:"BusinessCategoryUpdateToOneWithWhereWithoutCampaignsInput"
	},
	BusinessCategoryUpsertWithoutCampaignsInput:{
		update:"BusinessCategoryUpdateWithoutCampaignsInput",
		create:"BusinessCategoryCreateWithoutCampaignsInput",
		where:"BusinessCategoryWhereInput"
	},
	BusinessCategoryUpdateWithoutCampaignsInput:{
		adBoards:"AdBoardUpdateManyWithoutCategoryNestedInput",
		restrictedByAdBoards:"AdBoardUpdateManyWithoutRestrictedBusinesNestedInput",
		bookings:"BookingUpdateManyWithoutBusinessCategoryNestedInput"
	},
	BookingUpdateManyWithoutBusinessCategoryNestedInput:{
		create:"BookingCreateWithoutBusinessCategoryInput",
		connectOrCreate:"BookingCreateOrConnectWithoutBusinessCategoryInput",
		upsert:"BookingUpsertWithWhereUniqueWithoutBusinessCategoryInput",
		createMany:"BookingCreateManyBusinessCategoryInputEnvelope",
		set:"BookingWhereUniqueInput",
		disconnect:"BookingWhereUniqueInput",
		delete:"BookingWhereUniqueInput",
		connect:"BookingWhereUniqueInput",
		update:"BookingUpdateWithWhereUniqueWithoutBusinessCategoryInput",
		updateMany:"BookingUpdateManyWithWhereWithoutBusinessCategoryInput",
		deleteMany:"BookingScalarWhereInput"
	},
	BookingUpsertWithWhereUniqueWithoutBusinessCategoryInput:{
		where:"BookingWhereUniqueInput",
		update:"BookingUpdateWithoutBusinessCategoryInput",
		create:"BookingCreateWithoutBusinessCategoryInput"
	},
	BookingUpdateWithoutBusinessCategoryInput:{
		bookingDate:"DateTime",
		bookingDetails:"BookingDetailsCreateInput",
		bookingStatus:"BookingStatus",
		paymentStatus:"PaymentStatus",
		deploymentStatus:"DeploymentStatus",
		contentApprovalStatus:"ContentApprovalStatus",
		selectedDates:"OrderSelectedDateCreateInput",
		payment:"BookingPaymentCreateInput",
		priceSummary:"PriceSummaryCreateInput",
		pisignage:"BookingPisignageDataCreateInput",
		refund:"BookingRefundCreateInput",
		assets:"FileUpdateManyWithoutBookingsNestedInput",
		proofs:"FileUpdateManyWithoutProofBookingNestedInput",
		user:"UserUpdateOneRequiredWithoutBookingsNestedInput",
		adBoard:"AdBoardUpdateOneRequiredWithoutBookingsNestedInput",
		changelogs:"ChangelogUpdateManyWithoutBookingNestedInput",
		workspace:"WorkspaceUpdateOneWithoutBookingsNestedInput",
		campaign:"CampaignUpdateOneWithoutBookingsNestedInput",
		payouts:"PayoutUpdateManyWithoutBookingNestedInput",
		sharedUsers:"UserUpdateManyWithoutSharedBookingsNestedInput",
		webhookLogs:"WebhookLogUpdateManyWithoutBookingNestedInput",
		slotUsage:"SlotUsageUpdateManyWithoutBookingNestedInput"
	},
	AdBoardUpdateOneRequiredWithoutBookingsNestedInput:{
		create:"AdBoardCreateWithoutBookingsInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutBookingsInput",
		upsert:"AdBoardUpsertWithoutBookingsInput",
		connect:"AdBoardWhereUniqueInput",
		update:"AdBoardUpdateToOneWithWhereWithoutBookingsInput"
	},
	AdBoardUpsertWithoutBookingsInput:{
		update:"AdBoardUpdateWithoutBookingsInput",
		create:"AdBoardCreateWithoutBookingsInput",
		where:"AdBoardWhereInput"
	},
	AdBoardUpdateWithoutBookingsInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryUpdateOneWithoutAdBoardsNestedInput",
		locationSubCategory:"LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput",
		type:"AdBoardTypeUpdateOneWithoutAdBoardsNestedInput",
		category:"BusinessCategoryUpdateOneWithoutAdBoardsNestedInput",
		restrictedBusines:"BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput",
		orders:"OrderUpdateManyWithoutAdBoardNestedInput",
		slotConfigurations:"SlotConfigurationUpdateManyWithoutAdBoardNestedInput",
		offlineBookingsContact:"UserUpdateOneWithoutOfflineBookingAdboardsNestedInput",
		createdBy:"UserUpdateOneWithoutAdBoardsNestedInput",
		workspace:"WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput",
		players:"PlayerUpdateManyWithoutAdBoardsNestedInput",
		adBoardGroup:"AdBoardGroupUpdateOneWithoutAdBoardNestedInput",
		cameras:"CameraUpdateManyWithoutAdBoardNestedInput",
		publishedVersion:"AdBoardUpdateOneWithoutDraftAdboardNestedInput",
		draftAdboard:"AdBoardUpdateOneWithoutPublishedVersionNestedInput",
		history:"AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput",
		archivedBy:"UserUpdateOneWithoutArchivedAdboardsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"
	},
	UserUpdateOneWithoutAdBoardsNestedInput:{
		create:"UserCreateWithoutAdBoardsInput",
		connectOrCreate:"UserCreateOrConnectWithoutAdBoardsInput",
		upsert:"UserUpsertWithoutAdBoardsInput",
		delete:"UserWhereInput",
		connect:"UserWhereUniqueInput",
		update:"UserUpdateToOneWithWhereWithoutAdBoardsInput"
	},
	UserUpsertWithoutAdBoardsInput:{
		update:"UserUpdateWithoutAdBoardsInput",
		create:"UserCreateWithoutAdBoardsInput",
		where:"UserWhereInput"
	},
	UserUpdateWithoutAdBoardsInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingUpdateManyWithoutUserNestedInput",
		createdFiles:"FileUpdateManyWithoutCreatedByNestedInput",
		updatedFiles:"FileUpdateManyWithoutUpdatedByNestedInput",
		orders:"OrderUpdateManyWithoutCreatedByNestedInput",
		changelogs:"ChangelogUpdateManyWithoutUserNestedInput",
		audits:"AuditUpdateManyWithoutUserNestedInput",
		campaigns:"CampaignUpdateManyWithoutUserNestedInput",
		sharedBookings:"BookingUpdateManyWithoutSharedUsersNestedInput",
		memberships:"MembershipUpdateManyWithoutUserNestedInput",
		notifications:"NotificationUpdateManyWithoutRecipientNestedInput",
		publishAdboardVersion:"AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput",
		offlineBookingAdboards:"AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutUserNestedInput",
		archivedAdboards:"AdBoardUpdateManyWithoutArchivedByNestedInput",
		ownedWorkspaces:"WorkspaceUpdateManyWithoutOwnerNestedInput",
		leadWorspaces:"WorkspaceUpdateManyWithoutLeadsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutUserNestedInput",
		userPositions:"UserPositionUpdateManyWithoutUserNestedInput"
	},
	BookingUpdateManyWithoutSharedUsersNestedInput:{
		create:"BookingCreateWithoutSharedUsersInput",
		connectOrCreate:"BookingCreateOrConnectWithoutSharedUsersInput",
		upsert:"BookingUpsertWithWhereUniqueWithoutSharedUsersInput",
		set:"BookingWhereUniqueInput",
		disconnect:"BookingWhereUniqueInput",
		delete:"BookingWhereUniqueInput",
		connect:"BookingWhereUniqueInput",
		update:"BookingUpdateWithWhereUniqueWithoutSharedUsersInput",
		updateMany:"BookingUpdateManyWithWhereWithoutSharedUsersInput",
		deleteMany:"BookingScalarWhereInput"
	},
	BookingUpsertWithWhereUniqueWithoutSharedUsersInput:{
		where:"BookingWhereUniqueInput",
		update:"BookingUpdateWithoutSharedUsersInput",
		create:"BookingCreateWithoutSharedUsersInput"
	},
	BookingUpdateWithoutSharedUsersInput:{
		bookingDate:"DateTime",
		bookingDetails:"BookingDetailsCreateInput",
		bookingStatus:"BookingStatus",
		paymentStatus:"PaymentStatus",
		deploymentStatus:"DeploymentStatus",
		contentApprovalStatus:"ContentApprovalStatus",
		selectedDates:"OrderSelectedDateCreateInput",
		payment:"BookingPaymentCreateInput",
		priceSummary:"PriceSummaryCreateInput",
		pisignage:"BookingPisignageDataCreateInput",
		refund:"BookingRefundCreateInput",
		assets:"FileUpdateManyWithoutBookingsNestedInput",
		businessCategory:"BusinessCategoryUpdateOneWithoutBookingsNestedInput",
		proofs:"FileUpdateManyWithoutProofBookingNestedInput",
		user:"UserUpdateOneRequiredWithoutBookingsNestedInput",
		adBoard:"AdBoardUpdateOneRequiredWithoutBookingsNestedInput",
		changelogs:"ChangelogUpdateManyWithoutBookingNestedInput",
		workspace:"WorkspaceUpdateOneWithoutBookingsNestedInput",
		campaign:"CampaignUpdateOneWithoutBookingsNestedInput",
		payouts:"PayoutUpdateManyWithoutBookingNestedInput",
		webhookLogs:"WebhookLogUpdateManyWithoutBookingNestedInput",
		slotUsage:"SlotUsageUpdateManyWithoutBookingNestedInput"
	},
	ChangelogUpdateManyWithoutBookingNestedInput:{
		create:"ChangelogCreateWithoutBookingInput",
		connectOrCreate:"ChangelogCreateOrConnectWithoutBookingInput",
		upsert:"ChangelogUpsertWithWhereUniqueWithoutBookingInput",
		createMany:"ChangelogCreateManyBookingInputEnvelope",
		set:"ChangelogWhereUniqueInput",
		disconnect:"ChangelogWhereUniqueInput",
		delete:"ChangelogWhereUniqueInput",
		connect:"ChangelogWhereUniqueInput",
		update:"ChangelogUpdateWithWhereUniqueWithoutBookingInput",
		updateMany:"ChangelogUpdateManyWithWhereWithoutBookingInput",
		deleteMany:"ChangelogScalarWhereInput"
	},
	ChangelogUpsertWithWhereUniqueWithoutBookingInput:{
		where:"ChangelogWhereUniqueInput",
		update:"ChangelogUpdateWithoutBookingInput",
		create:"ChangelogCreateWithoutBookingInput"
	},
	ChangelogUpdateWithoutBookingInput:{
		createdAt:"DateTime",
		updates:"JSON",
		user:"UserUpdateOneRequiredWithoutChangelogsNestedInput"
	},
	UserUpdateOneRequiredWithoutChangelogsNestedInput:{
		create:"UserCreateWithoutChangelogsInput",
		connectOrCreate:"UserCreateOrConnectWithoutChangelogsInput",
		upsert:"UserUpsertWithoutChangelogsInput",
		connect:"UserWhereUniqueInput",
		update:"UserUpdateToOneWithWhereWithoutChangelogsInput"
	},
	UserUpsertWithoutChangelogsInput:{
		update:"UserUpdateWithoutChangelogsInput",
		create:"UserCreateWithoutChangelogsInput",
		where:"UserWhereInput"
	},
	UserUpdateWithoutChangelogsInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingUpdateManyWithoutUserNestedInput",
		createdFiles:"FileUpdateManyWithoutCreatedByNestedInput",
		updatedFiles:"FileUpdateManyWithoutUpdatedByNestedInput",
		orders:"OrderUpdateManyWithoutCreatedByNestedInput",
		adBoards:"AdBoardUpdateManyWithoutCreatedByNestedInput",
		audits:"AuditUpdateManyWithoutUserNestedInput",
		campaigns:"CampaignUpdateManyWithoutUserNestedInput",
		sharedBookings:"BookingUpdateManyWithoutSharedUsersNestedInput",
		memberships:"MembershipUpdateManyWithoutUserNestedInput",
		notifications:"NotificationUpdateManyWithoutRecipientNestedInput",
		publishAdboardVersion:"AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput",
		offlineBookingAdboards:"AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutUserNestedInput",
		archivedAdboards:"AdBoardUpdateManyWithoutArchivedByNestedInput",
		ownedWorkspaces:"WorkspaceUpdateManyWithoutOwnerNestedInput",
		leadWorspaces:"WorkspaceUpdateManyWithoutLeadsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutUserNestedInput",
		userPositions:"UserPositionUpdateManyWithoutUserNestedInput"
	},
	MembershipUpdateManyWithoutUserNestedInput:{
		create:"MembershipCreateWithoutUserInput",
		connectOrCreate:"MembershipCreateOrConnectWithoutUserInput",
		upsert:"MembershipUpsertWithWhereUniqueWithoutUserInput",
		createMany:"MembershipCreateManyUserInputEnvelope",
		set:"MembershipWhereUniqueInput",
		disconnect:"MembershipWhereUniqueInput",
		delete:"MembershipWhereUniqueInput",
		connect:"MembershipWhereUniqueInput",
		update:"MembershipUpdateWithWhereUniqueWithoutUserInput",
		updateMany:"MembershipUpdateManyWithWhereWithoutUserInput",
		deleteMany:"MembershipScalarWhereInput"
	},
	MembershipUpsertWithWhereUniqueWithoutUserInput:{
		where:"MembershipWhereUniqueInput",
		update:"MembershipUpdateWithoutUserInput",
		create:"MembershipCreateWithoutUserInput"
	},
	MembershipUpdateWithoutUserInput:{
		createdAt:"DateTime",
		workspace:"WorkspaceUpdateOneRequiredWithoutMembershipsNestedInput",
		role:"WorkspaceRoleUpdateOneRequiredWithoutMembershipsNestedInput"
	},
	WorkspaceUpdateOneRequiredWithoutMembershipsNestedInput:{
		create:"WorkspaceCreateWithoutMembershipsInput",
		connectOrCreate:"WorkspaceCreateOrConnectWithoutMembershipsInput",
		upsert:"WorkspaceUpsertWithoutMembershipsInput",
		connect:"WorkspaceWhereUniqueInput",
		update:"WorkspaceUpdateToOneWithWhereWithoutMembershipsInput"
	},
	WorkspaceUpsertWithoutMembershipsInput:{
		update:"WorkspaceUpdateWithoutMembershipsInput",
		create:"WorkspaceCreateWithoutMembershipsInput",
		where:"WorkspaceWhereInput"
	},
	WorkspaceUpdateWithoutMembershipsInput:{
		type:"WorkspaceType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		owner:"UserUpdateOneWithoutOwnedWorkspacesNestedInput",
		adBoards:"AdBoardUpdateManyWithoutWorkspaceNestedInput",
		playlists:"PlaylistUpdateManyWithoutWorkspaceNestedInput",
		adBoardGroups:"AdBoardGroupUpdateManyWithoutWorkspaceNestedInput",
		audits:"AuditUpdateManyWithoutWorkspaceNestedInput",
		bookings:"BookingUpdateManyWithoutWorkspaceNestedInput",
		files:"FileUpdateManyWithoutWorkspaceNestedInput",
		players:"PlayerUpdateManyWithoutWorkspaceNestedInput",
		transactions:"TransactionUpdateManyWithoutWorkspaceNestedInput",
		activeSubscription:"SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput",
		cameraServers:"CameraServerUpdateManyWithoutWorkspaceNestedInput",
		workspaceRoles:"WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput",
		webhookLogs:"WebhookLogUpdateManyWithoutWorkspaceNestedInput",
		leads:"UserUpdateManyWithoutLeadWorspacesNestedInput"
	},
	AdBoardUpdateManyWithoutWorkspaceNestedInput:{
		create:"AdBoardCreateWithoutWorkspaceInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutWorkspaceInput",
		upsert:"AdBoardUpsertWithWhereUniqueWithoutWorkspaceInput",
		createMany:"AdBoardCreateManyWorkspaceInputEnvelope",
		set:"AdBoardWhereUniqueInput",
		disconnect:"AdBoardWhereUniqueInput",
		delete:"AdBoardWhereUniqueInput",
		connect:"AdBoardWhereUniqueInput",
		update:"AdBoardUpdateWithWhereUniqueWithoutWorkspaceInput",
		updateMany:"AdBoardUpdateManyWithWhereWithoutWorkspaceInput",
		deleteMany:"AdBoardScalarWhereInput"
	},
	AdBoardUpsertWithWhereUniqueWithoutWorkspaceInput:{
		where:"AdBoardWhereUniqueInput",
		update:"AdBoardUpdateWithoutWorkspaceInput",
		create:"AdBoardCreateWithoutWorkspaceInput"
	},
	AdBoardUpdateWithoutWorkspaceInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryUpdateOneWithoutAdBoardsNestedInput",
		locationSubCategory:"LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput",
		type:"AdBoardTypeUpdateOneWithoutAdBoardsNestedInput",
		category:"BusinessCategoryUpdateOneWithoutAdBoardsNestedInput",
		restrictedBusines:"BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput",
		bookings:"BookingUpdateManyWithoutAdBoardNestedInput",
		orders:"OrderUpdateManyWithoutAdBoardNestedInput",
		slotConfigurations:"SlotConfigurationUpdateManyWithoutAdBoardNestedInput",
		offlineBookingsContact:"UserUpdateOneWithoutOfflineBookingAdboardsNestedInput",
		createdBy:"UserUpdateOneWithoutAdBoardsNestedInput",
		players:"PlayerUpdateManyWithoutAdBoardsNestedInput",
		adBoardGroup:"AdBoardGroupUpdateOneWithoutAdBoardNestedInput",
		cameras:"CameraUpdateManyWithoutAdBoardNestedInput",
		publishedVersion:"AdBoardUpdateOneWithoutDraftAdboardNestedInput",
		draftAdboard:"AdBoardUpdateOneWithoutPublishedVersionNestedInput",
		history:"AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput",
		archivedBy:"UserUpdateOneWithoutArchivedAdboardsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"
	},
	PlayerUpdateManyWithoutAdBoardsNestedInput:{
		create:"PlayerCreateWithoutAdBoardsInput",
		connectOrCreate:"PlayerCreateOrConnectWithoutAdBoardsInput",
		upsert:"PlayerUpsertWithWhereUniqueWithoutAdBoardsInput",
		set:"PlayerWhereUniqueInput",
		disconnect:"PlayerWhereUniqueInput",
		delete:"PlayerWhereUniqueInput",
		connect:"PlayerWhereUniqueInput",
		update:"PlayerUpdateWithWhereUniqueWithoutAdBoardsInput",
		updateMany:"PlayerUpdateManyWithWhereWithoutAdBoardsInput",
		deleteMany:"PlayerScalarWhereInput"
	},
	PlayerUpsertWithWhereUniqueWithoutAdBoardsInput:{
		where:"PlayerWhereUniqueInput",
		update:"PlayerUpdateWithoutAdBoardsInput",
		create:"PlayerCreateWithoutAdBoardsInput"
	},
	PlayerUpdateWithoutAdBoardsInput:{
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackDetails:"PlaybackTimeCreateInput",
		aspectRatioType:"AspectRatioType",
		map:"MapCreateInput",
		adBoardGroup:"AdBoardGroupUpdateOneWithoutPisignagePlayersNestedInput",
		workspace:"WorkspaceUpdateOneWithoutPlayersNestedInput",
		status:"PlayerUptimeStatusUpdateManyWithoutPlayerNestedInput",
		cameras:"CameraUpdateManyWithoutPlayerNestedInput"
	},
	AdBoardGroupUpdateOneWithoutPisignagePlayersNestedInput:{
		create:"AdBoardGroupCreateWithoutPisignagePlayersInput",
		connectOrCreate:"AdBoardGroupCreateOrConnectWithoutPisignagePlayersInput",
		upsert:"AdBoardGroupUpsertWithoutPisignagePlayersInput",
		delete:"AdBoardGroupWhereInput",
		connect:"AdBoardGroupWhereUniqueInput",
		update:"AdBoardGroupUpdateToOneWithWhereWithoutPisignagePlayersInput"
	},
	AdBoardGroupUpsertWithoutPisignagePlayersInput:{
		update:"AdBoardGroupUpdateWithoutPisignagePlayersInput",
		create:"AdBoardGroupCreateWithoutPisignagePlayersInput",
		where:"AdBoardGroupWhereInput"
	},
	AdBoardGroupUpdateWithoutPisignagePlayersInput:{
		schedules:"ScheduleCreateInput",
		fitImage:"FitType",
		fitVideo:"FitType",
		adBoard:"AdBoardUpdateOneWithoutAdBoardGroupNestedInput",
		scheduledPlaylists:"PlaylistUpdateManyWithoutAdBoardGroupsNestedInput",
		adsPlaylist:"PlaylistUpdateOneWithoutAdBoardGroupsForAdsNestedInput",
		workspace:"WorkspaceUpdateOneRequiredWithoutAdBoardGroupsNestedInput"
	},
	AdBoardUpdateOneWithoutAdBoardGroupNestedInput:{
		create:"AdBoardCreateWithoutAdBoardGroupInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutAdBoardGroupInput",
		upsert:"AdBoardUpsertWithoutAdBoardGroupInput",
		delete:"AdBoardWhereInput",
		connect:"AdBoardWhereUniqueInput",
		update:"AdBoardUpdateToOneWithWhereWithoutAdBoardGroupInput"
	},
	AdBoardUpsertWithoutAdBoardGroupInput:{
		update:"AdBoardUpdateWithoutAdBoardGroupInput",
		create:"AdBoardCreateWithoutAdBoardGroupInput",
		where:"AdBoardWhereInput"
	},
	AdBoardUpdateWithoutAdBoardGroupInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryUpdateOneWithoutAdBoardsNestedInput",
		locationSubCategory:"LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput",
		type:"AdBoardTypeUpdateOneWithoutAdBoardsNestedInput",
		category:"BusinessCategoryUpdateOneWithoutAdBoardsNestedInput",
		restrictedBusines:"BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput",
		bookings:"BookingUpdateManyWithoutAdBoardNestedInput",
		orders:"OrderUpdateManyWithoutAdBoardNestedInput",
		slotConfigurations:"SlotConfigurationUpdateManyWithoutAdBoardNestedInput",
		offlineBookingsContact:"UserUpdateOneWithoutOfflineBookingAdboardsNestedInput",
		createdBy:"UserUpdateOneWithoutAdBoardsNestedInput",
		workspace:"WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput",
		players:"PlayerUpdateManyWithoutAdBoardsNestedInput",
		cameras:"CameraUpdateManyWithoutAdBoardNestedInput",
		publishedVersion:"AdBoardUpdateOneWithoutDraftAdboardNestedInput",
		draftAdboard:"AdBoardUpdateOneWithoutPublishedVersionNestedInput",
		history:"AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput",
		archivedBy:"UserUpdateOneWithoutArchivedAdboardsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"
	},
	WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput:{
		create:"WorkspaceCreateWithoutAdBoardsInput",
		connectOrCreate:"WorkspaceCreateOrConnectWithoutAdBoardsInput",
		upsert:"WorkspaceUpsertWithoutAdBoardsInput",
		connect:"WorkspaceWhereUniqueInput",
		update:"WorkspaceUpdateToOneWithWhereWithoutAdBoardsInput"
	},
	WorkspaceUpsertWithoutAdBoardsInput:{
		update:"WorkspaceUpdateWithoutAdBoardsInput",
		create:"WorkspaceCreateWithoutAdBoardsInput",
		where:"WorkspaceWhereInput"
	},
	WorkspaceUpdateWithoutAdBoardsInput:{
		type:"WorkspaceType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		owner:"UserUpdateOneWithoutOwnedWorkspacesNestedInput",
		memberships:"MembershipUpdateManyWithoutWorkspaceNestedInput",
		playlists:"PlaylistUpdateManyWithoutWorkspaceNestedInput",
		adBoardGroups:"AdBoardGroupUpdateManyWithoutWorkspaceNestedInput",
		audits:"AuditUpdateManyWithoutWorkspaceNestedInput",
		bookings:"BookingUpdateManyWithoutWorkspaceNestedInput",
		files:"FileUpdateManyWithoutWorkspaceNestedInput",
		players:"PlayerUpdateManyWithoutWorkspaceNestedInput",
		transactions:"TransactionUpdateManyWithoutWorkspaceNestedInput",
		activeSubscription:"SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput",
		cameraServers:"CameraServerUpdateManyWithoutWorkspaceNestedInput",
		workspaceRoles:"WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput",
		webhookLogs:"WebhookLogUpdateManyWithoutWorkspaceNestedInput",
		leads:"UserUpdateManyWithoutLeadWorspacesNestedInput"
	},
	PlaylistUpdateManyWithoutWorkspaceNestedInput:{
		create:"PlaylistCreateWithoutWorkspaceInput",
		connectOrCreate:"PlaylistCreateOrConnectWithoutWorkspaceInput",
		upsert:"PlaylistUpsertWithWhereUniqueWithoutWorkspaceInput",
		createMany:"PlaylistCreateManyWorkspaceInputEnvelope",
		set:"PlaylistWhereUniqueInput",
		disconnect:"PlaylistWhereUniqueInput",
		delete:"PlaylistWhereUniqueInput",
		connect:"PlaylistWhereUniqueInput",
		update:"PlaylistUpdateWithWhereUniqueWithoutWorkspaceInput",
		updateMany:"PlaylistUpdateManyWithWhereWithoutWorkspaceInput",
		deleteMany:"PlaylistScalarWhereInput"
	},
	PlaylistUpsertWithWhereUniqueWithoutWorkspaceInput:{
		where:"PlaylistWhereUniqueInput",
		update:"PlaylistUpdateWithoutWorkspaceInput",
		create:"PlaylistCreateWithoutWorkspaceInput"
	},
	PlaylistUpdateWithoutWorkspaceInput:{
		assets:"PisignageFileCreateInput",
		layout:"ScreenLayout",
		footerPlaylist:"PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput",
		sidebarPlaylist:"PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput",
		adBoardGroupsForAds:"AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput",
		adBoardGroups:"AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput",
		footerParentPlaylists:"PlaylistUpdateManyWithoutFooterPlaylistNestedInput",
		sidebarParentPlaylists:"PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"
	},
	PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput:{
		create:"PlaylistCreateWithoutFooterParentPlaylistsInput",
		connectOrCreate:"PlaylistCreateOrConnectWithoutFooterParentPlaylistsInput",
		upsert:"PlaylistUpsertWithoutFooterParentPlaylistsInput",
		delete:"PlaylistWhereInput",
		connect:"PlaylistWhereUniqueInput",
		update:"PlaylistUpdateToOneWithWhereWithoutFooterParentPlaylistsInput"
	},
	PlaylistUpsertWithoutFooterParentPlaylistsInput:{
		update:"PlaylistUpdateWithoutFooterParentPlaylistsInput",
		create:"PlaylistCreateWithoutFooterParentPlaylistsInput",
		where:"PlaylistWhereInput"
	},
	PlaylistUpdateWithoutFooterParentPlaylistsInput:{
		assets:"PisignageFileCreateInput",
		layout:"ScreenLayout",
		footerPlaylist:"PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput",
		sidebarPlaylist:"PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput",
		adBoardGroupsForAds:"AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput",
		adBoardGroups:"AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput",
		workspace:"WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput",
		sidebarParentPlaylists:"PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"
	},
	PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput:{
		create:"PlaylistCreateWithoutSidebarParentPlaylistsInput",
		connectOrCreate:"PlaylistCreateOrConnectWithoutSidebarParentPlaylistsInput",
		upsert:"PlaylistUpsertWithoutSidebarParentPlaylistsInput",
		delete:"PlaylistWhereInput",
		connect:"PlaylistWhereUniqueInput",
		update:"PlaylistUpdateToOneWithWhereWithoutSidebarParentPlaylistsInput"
	},
	PlaylistUpsertWithoutSidebarParentPlaylistsInput:{
		update:"PlaylistUpdateWithoutSidebarParentPlaylistsInput",
		create:"PlaylistCreateWithoutSidebarParentPlaylistsInput",
		where:"PlaylistWhereInput"
	},
	PlaylistUpdateWithoutSidebarParentPlaylistsInput:{
		assets:"PisignageFileCreateInput",
		layout:"ScreenLayout",
		footerPlaylist:"PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput",
		sidebarPlaylist:"PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput",
		adBoardGroupsForAds:"AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput",
		adBoardGroups:"AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput",
		workspace:"WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput",
		footerParentPlaylists:"PlaylistUpdateManyWithoutFooterPlaylistNestedInput"
	},
	AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput:{
		create:"AdBoardGroupCreateWithoutAdsPlaylistInput",
		connectOrCreate:"AdBoardGroupCreateOrConnectWithoutAdsPlaylistInput",
		upsert:"AdBoardGroupUpsertWithWhereUniqueWithoutAdsPlaylistInput",
		createMany:"AdBoardGroupCreateManyAdsPlaylistInputEnvelope",
		set:"AdBoardGroupWhereUniqueInput",
		disconnect:"AdBoardGroupWhereUniqueInput",
		delete:"AdBoardGroupWhereUniqueInput",
		connect:"AdBoardGroupWhereUniqueInput",
		update:"AdBoardGroupUpdateWithWhereUniqueWithoutAdsPlaylistInput",
		updateMany:"AdBoardGroupUpdateManyWithWhereWithoutAdsPlaylistInput",
		deleteMany:"AdBoardGroupScalarWhereInput"
	},
	AdBoardGroupUpsertWithWhereUniqueWithoutAdsPlaylistInput:{
		where:"AdBoardGroupWhereUniqueInput",
		update:"AdBoardGroupUpdateWithoutAdsPlaylistInput",
		create:"AdBoardGroupCreateWithoutAdsPlaylistInput"
	},
	AdBoardGroupUpdateWithoutAdsPlaylistInput:{
		schedules:"ScheduleCreateInput",
		fitImage:"FitType",
		fitVideo:"FitType",
		adBoard:"AdBoardUpdateOneWithoutAdBoardGroupNestedInput",
		scheduledPlaylists:"PlaylistUpdateManyWithoutAdBoardGroupsNestedInput",
		pisignagePlayers:"PlayerUpdateManyWithoutAdBoardGroupNestedInput",
		workspace:"WorkspaceUpdateOneRequiredWithoutAdBoardGroupsNestedInput"
	},
	PlaylistUpdateManyWithoutAdBoardGroupsNestedInput:{
		create:"PlaylistCreateWithoutAdBoardGroupsInput",
		connectOrCreate:"PlaylistCreateOrConnectWithoutAdBoardGroupsInput",
		upsert:"PlaylistUpsertWithWhereUniqueWithoutAdBoardGroupsInput",
		set:"PlaylistWhereUniqueInput",
		disconnect:"PlaylistWhereUniqueInput",
		delete:"PlaylistWhereUniqueInput",
		connect:"PlaylistWhereUniqueInput",
		update:"PlaylistUpdateWithWhereUniqueWithoutAdBoardGroupsInput",
		updateMany:"PlaylistUpdateManyWithWhereWithoutAdBoardGroupsInput",
		deleteMany:"PlaylistScalarWhereInput"
	},
	PlaylistUpsertWithWhereUniqueWithoutAdBoardGroupsInput:{
		where:"PlaylistWhereUniqueInput",
		update:"PlaylistUpdateWithoutAdBoardGroupsInput",
		create:"PlaylistCreateWithoutAdBoardGroupsInput"
	},
	PlaylistUpdateWithoutAdBoardGroupsInput:{
		assets:"PisignageFileCreateInput",
		layout:"ScreenLayout",
		footerPlaylist:"PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput",
		sidebarPlaylist:"PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput",
		adBoardGroupsForAds:"AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput",
		workspace:"WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput",
		footerParentPlaylists:"PlaylistUpdateManyWithoutFooterPlaylistNestedInput",
		sidebarParentPlaylists:"PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"
	},
	WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput:{
		create:"WorkspaceCreateWithoutPlaylistsInput",
		connectOrCreate:"WorkspaceCreateOrConnectWithoutPlaylistsInput",
		upsert:"WorkspaceUpsertWithoutPlaylistsInput",
		connect:"WorkspaceWhereUniqueInput",
		update:"WorkspaceUpdateToOneWithWhereWithoutPlaylistsInput"
	},
	WorkspaceUpsertWithoutPlaylistsInput:{
		update:"WorkspaceUpdateWithoutPlaylistsInput",
		create:"WorkspaceCreateWithoutPlaylistsInput",
		where:"WorkspaceWhereInput"
	},
	WorkspaceUpdateWithoutPlaylistsInput:{
		type:"WorkspaceType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		owner:"UserUpdateOneWithoutOwnedWorkspacesNestedInput",
		memberships:"MembershipUpdateManyWithoutWorkspaceNestedInput",
		adBoards:"AdBoardUpdateManyWithoutWorkspaceNestedInput",
		adBoardGroups:"AdBoardGroupUpdateManyWithoutWorkspaceNestedInput",
		audits:"AuditUpdateManyWithoutWorkspaceNestedInput",
		bookings:"BookingUpdateManyWithoutWorkspaceNestedInput",
		files:"FileUpdateManyWithoutWorkspaceNestedInput",
		players:"PlayerUpdateManyWithoutWorkspaceNestedInput",
		transactions:"TransactionUpdateManyWithoutWorkspaceNestedInput",
		activeSubscription:"SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput",
		cameraServers:"CameraServerUpdateManyWithoutWorkspaceNestedInput",
		workspaceRoles:"WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput",
		webhookLogs:"WebhookLogUpdateManyWithoutWorkspaceNestedInput",
		leads:"UserUpdateManyWithoutLeadWorspacesNestedInput"
	},
	AdBoardGroupUpdateManyWithoutWorkspaceNestedInput:{
		create:"AdBoardGroupCreateWithoutWorkspaceInput",
		connectOrCreate:"AdBoardGroupCreateOrConnectWithoutWorkspaceInput",
		upsert:"AdBoardGroupUpsertWithWhereUniqueWithoutWorkspaceInput",
		createMany:"AdBoardGroupCreateManyWorkspaceInputEnvelope",
		set:"AdBoardGroupWhereUniqueInput",
		disconnect:"AdBoardGroupWhereUniqueInput",
		delete:"AdBoardGroupWhereUniqueInput",
		connect:"AdBoardGroupWhereUniqueInput",
		update:"AdBoardGroupUpdateWithWhereUniqueWithoutWorkspaceInput",
		updateMany:"AdBoardGroupUpdateManyWithWhereWithoutWorkspaceInput",
		deleteMany:"AdBoardGroupScalarWhereInput"
	},
	AdBoardGroupUpsertWithWhereUniqueWithoutWorkspaceInput:{
		where:"AdBoardGroupWhereUniqueInput",
		update:"AdBoardGroupUpdateWithoutWorkspaceInput",
		create:"AdBoardGroupCreateWithoutWorkspaceInput"
	},
	AdBoardGroupUpdateWithoutWorkspaceInput:{
		schedules:"ScheduleCreateInput",
		fitImage:"FitType",
		fitVideo:"FitType",
		adBoard:"AdBoardUpdateOneWithoutAdBoardGroupNestedInput",
		scheduledPlaylists:"PlaylistUpdateManyWithoutAdBoardGroupsNestedInput",
		adsPlaylist:"PlaylistUpdateOneWithoutAdBoardGroupsForAdsNestedInput",
		pisignagePlayers:"PlayerUpdateManyWithoutAdBoardGroupNestedInput"
	},
	PlaylistUpdateOneWithoutAdBoardGroupsForAdsNestedInput:{
		create:"PlaylistCreateWithoutAdBoardGroupsForAdsInput",
		connectOrCreate:"PlaylistCreateOrConnectWithoutAdBoardGroupsForAdsInput",
		upsert:"PlaylistUpsertWithoutAdBoardGroupsForAdsInput",
		delete:"PlaylistWhereInput",
		connect:"PlaylistWhereUniqueInput",
		update:"PlaylistUpdateToOneWithWhereWithoutAdBoardGroupsForAdsInput"
	},
	PlaylistUpsertWithoutAdBoardGroupsForAdsInput:{
		update:"PlaylistUpdateWithoutAdBoardGroupsForAdsInput",
		create:"PlaylistCreateWithoutAdBoardGroupsForAdsInput",
		where:"PlaylistWhereInput"
	},
	PlaylistUpdateWithoutAdBoardGroupsForAdsInput:{
		assets:"PisignageFileCreateInput",
		layout:"ScreenLayout",
		footerPlaylist:"PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput",
		sidebarPlaylist:"PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput",
		adBoardGroups:"AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput",
		workspace:"WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput",
		footerParentPlaylists:"PlaylistUpdateManyWithoutFooterPlaylistNestedInput",
		sidebarParentPlaylists:"PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"
	},
	AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput:{
		create:"AdBoardGroupCreateWithoutScheduledPlaylistsInput",
		connectOrCreate:"AdBoardGroupCreateOrConnectWithoutScheduledPlaylistsInput",
		upsert:"AdBoardGroupUpsertWithWhereUniqueWithoutScheduledPlaylistsInput",
		set:"AdBoardGroupWhereUniqueInput",
		disconnect:"AdBoardGroupWhereUniqueInput",
		delete:"AdBoardGroupWhereUniqueInput",
		connect:"AdBoardGroupWhereUniqueInput",
		update:"AdBoardGroupUpdateWithWhereUniqueWithoutScheduledPlaylistsInput",
		updateMany:"AdBoardGroupUpdateManyWithWhereWithoutScheduledPlaylistsInput",
		deleteMany:"AdBoardGroupScalarWhereInput"
	},
	AdBoardGroupUpsertWithWhereUniqueWithoutScheduledPlaylistsInput:{
		where:"AdBoardGroupWhereUniqueInput",
		update:"AdBoardGroupUpdateWithoutScheduledPlaylistsInput",
		create:"AdBoardGroupCreateWithoutScheduledPlaylistsInput"
	},
	AdBoardGroupUpdateWithoutScheduledPlaylistsInput:{
		schedules:"ScheduleCreateInput",
		fitImage:"FitType",
		fitVideo:"FitType",
		adBoard:"AdBoardUpdateOneWithoutAdBoardGroupNestedInput",
		adsPlaylist:"PlaylistUpdateOneWithoutAdBoardGroupsForAdsNestedInput",
		pisignagePlayers:"PlayerUpdateManyWithoutAdBoardGroupNestedInput",
		workspace:"WorkspaceUpdateOneRequiredWithoutAdBoardGroupsNestedInput"
	},
	PlayerUpdateManyWithoutAdBoardGroupNestedInput:{
		create:"PlayerCreateWithoutAdBoardGroupInput",
		connectOrCreate:"PlayerCreateOrConnectWithoutAdBoardGroupInput",
		upsert:"PlayerUpsertWithWhereUniqueWithoutAdBoardGroupInput",
		createMany:"PlayerCreateManyAdBoardGroupInputEnvelope",
		set:"PlayerWhereUniqueInput",
		disconnect:"PlayerWhereUniqueInput",
		delete:"PlayerWhereUniqueInput",
		connect:"PlayerWhereUniqueInput",
		update:"PlayerUpdateWithWhereUniqueWithoutAdBoardGroupInput",
		updateMany:"PlayerUpdateManyWithWhereWithoutAdBoardGroupInput",
		deleteMany:"PlayerScalarWhereInput"
	},
	PlayerUpsertWithWhereUniqueWithoutAdBoardGroupInput:{
		where:"PlayerWhereUniqueInput",
		update:"PlayerUpdateWithoutAdBoardGroupInput",
		create:"PlayerCreateWithoutAdBoardGroupInput"
	},
	PlayerUpdateWithoutAdBoardGroupInput:{
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackDetails:"PlaybackTimeCreateInput",
		aspectRatioType:"AspectRatioType",
		map:"MapCreateInput",
		workspace:"WorkspaceUpdateOneWithoutPlayersNestedInput",
		status:"PlayerUptimeStatusUpdateManyWithoutPlayerNestedInput",
		cameras:"CameraUpdateManyWithoutPlayerNestedInput",
		adBoards:"AdBoardUpdateManyWithoutPlayersNestedInput"
	},
	WorkspaceUpdateOneWithoutPlayersNestedInput:{
		create:"WorkspaceCreateWithoutPlayersInput",
		connectOrCreate:"WorkspaceCreateOrConnectWithoutPlayersInput",
		upsert:"WorkspaceUpsertWithoutPlayersInput",
		delete:"WorkspaceWhereInput",
		connect:"WorkspaceWhereUniqueInput",
		update:"WorkspaceUpdateToOneWithWhereWithoutPlayersInput"
	},
	WorkspaceUpsertWithoutPlayersInput:{
		update:"WorkspaceUpdateWithoutPlayersInput",
		create:"WorkspaceCreateWithoutPlayersInput",
		where:"WorkspaceWhereInput"
	},
	WorkspaceUpdateWithoutPlayersInput:{
		type:"WorkspaceType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		owner:"UserUpdateOneWithoutOwnedWorkspacesNestedInput",
		memberships:"MembershipUpdateManyWithoutWorkspaceNestedInput",
		adBoards:"AdBoardUpdateManyWithoutWorkspaceNestedInput",
		playlists:"PlaylistUpdateManyWithoutWorkspaceNestedInput",
		adBoardGroups:"AdBoardGroupUpdateManyWithoutWorkspaceNestedInput",
		audits:"AuditUpdateManyWithoutWorkspaceNestedInput",
		bookings:"BookingUpdateManyWithoutWorkspaceNestedInput",
		files:"FileUpdateManyWithoutWorkspaceNestedInput",
		transactions:"TransactionUpdateManyWithoutWorkspaceNestedInput",
		activeSubscription:"SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput",
		cameraServers:"CameraServerUpdateManyWithoutWorkspaceNestedInput",
		workspaceRoles:"WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput",
		webhookLogs:"WebhookLogUpdateManyWithoutWorkspaceNestedInput",
		leads:"UserUpdateManyWithoutLeadWorspacesNestedInput"
	},
	AuditUpdateManyWithoutWorkspaceNestedInput:{
		create:"AuditCreateWithoutWorkspaceInput",
		connectOrCreate:"AuditCreateOrConnectWithoutWorkspaceInput",
		upsert:"AuditUpsertWithWhereUniqueWithoutWorkspaceInput",
		createMany:"AuditCreateManyWorkspaceInputEnvelope",
		set:"AuditWhereUniqueInput",
		disconnect:"AuditWhereUniqueInput",
		delete:"AuditWhereUniqueInput",
		connect:"AuditWhereUniqueInput",
		update:"AuditUpdateWithWhereUniqueWithoutWorkspaceInput",
		updateMany:"AuditUpdateManyWithWhereWithoutWorkspaceInput",
		deleteMany:"AuditScalarWhereInput"
	},
	AuditUpsertWithWhereUniqueWithoutWorkspaceInput:{
		where:"AuditWhereUniqueInput",
		update:"AuditUpdateWithoutWorkspaceInput",
		create:"AuditCreateWithoutWorkspaceInput"
	},
	AuditUpdateWithoutWorkspaceInput:{
		createdAt:"DateTime",
		entity:"EntityCreateInput",
		updates:"JSON",
		user:"UserUpdateOneRequiredWithoutAuditsNestedInput"
	},
	UserUpdateOneRequiredWithoutAuditsNestedInput:{
		create:"UserCreateWithoutAuditsInput",
		connectOrCreate:"UserCreateOrConnectWithoutAuditsInput",
		upsert:"UserUpsertWithoutAuditsInput",
		connect:"UserWhereUniqueInput",
		update:"UserUpdateToOneWithWhereWithoutAuditsInput"
	},
	UserUpsertWithoutAuditsInput:{
		update:"UserUpdateWithoutAuditsInput",
		create:"UserCreateWithoutAuditsInput",
		where:"UserWhereInput"
	},
	UserUpdateWithoutAuditsInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingUpdateManyWithoutUserNestedInput",
		createdFiles:"FileUpdateManyWithoutCreatedByNestedInput",
		updatedFiles:"FileUpdateManyWithoutUpdatedByNestedInput",
		orders:"OrderUpdateManyWithoutCreatedByNestedInput",
		changelogs:"ChangelogUpdateManyWithoutUserNestedInput",
		adBoards:"AdBoardUpdateManyWithoutCreatedByNestedInput",
		campaigns:"CampaignUpdateManyWithoutUserNestedInput",
		sharedBookings:"BookingUpdateManyWithoutSharedUsersNestedInput",
		memberships:"MembershipUpdateManyWithoutUserNestedInput",
		notifications:"NotificationUpdateManyWithoutRecipientNestedInput",
		publishAdboardVersion:"AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput",
		offlineBookingAdboards:"AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutUserNestedInput",
		archivedAdboards:"AdBoardUpdateManyWithoutArchivedByNestedInput",
		ownedWorkspaces:"WorkspaceUpdateManyWithoutOwnerNestedInput",
		leadWorspaces:"WorkspaceUpdateManyWithoutLeadsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutUserNestedInput",
		userPositions:"UserPositionUpdateManyWithoutUserNestedInput"
	},
	NotificationUpdateManyWithoutRecipientNestedInput:{
		create:"NotificationCreateWithoutRecipientInput",
		connectOrCreate:"NotificationCreateOrConnectWithoutRecipientInput",
		upsert:"NotificationUpsertWithWhereUniqueWithoutRecipientInput",
		createMany:"NotificationCreateManyRecipientInputEnvelope",
		set:"NotificationWhereUniqueInput",
		disconnect:"NotificationWhereUniqueInput",
		delete:"NotificationWhereUniqueInput",
		connect:"NotificationWhereUniqueInput",
		update:"NotificationUpdateWithWhereUniqueWithoutRecipientInput",
		updateMany:"NotificationUpdateManyWithWhereWithoutRecipientInput",
		deleteMany:"NotificationScalarWhereInput"
	},
	NotificationUpsertWithWhereUniqueWithoutRecipientInput:{
		where:"NotificationWhereUniqueInput",
		update:"NotificationUpdateWithoutRecipientInput",
		create:"NotificationCreateWithoutRecipientInput"
	},
	NotificationUpdateWithoutRecipientInput:{
		type:"NotificationType",
		metadata:"JSON",
		createdAt:"DateTime",
		readAt:"DateTime",
		channels:"NotificationChannel"
	},
	NotificationUpdateWithWhereUniqueWithoutRecipientInput:{
		where:"NotificationWhereUniqueInput",
		data:"NotificationUpdateWithoutRecipientInput"
	},
	NotificationUpdateManyWithWhereWithoutRecipientInput:{
		where:"NotificationScalarWhereInput",
		data:"NotificationUpdateManyMutationInput"
	},
	NotificationScalarWhereInput:{
		AND:"NotificationScalarWhereInput",
		OR:"NotificationScalarWhereInput",
		NOT:"NotificationScalarWhereInput",
		id:"StringFilter",
		type:"EnumNotificationTypeFilter",
		title:"StringFilter",
		content:"StringFilter",
		metadata:"JsonNullableFilter",
		createdAt:"DateTimeFilter",
		readAt:"DateTimeNullableFilter",
		recipientId:"StringFilter",
		channels:"EnumNotificationChannelNullableListFilter"
	},
	NotificationUpdateManyMutationInput:{
		type:"NotificationType",
		metadata:"JSON",
		createdAt:"DateTime",
		readAt:"DateTime",
		channels:"NotificationChannel"
	},
	AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput:{
		create:"AdBoardPublishHistoryCreateWithoutPublishedByInput",
		connectOrCreate:"AdBoardPublishHistoryCreateOrConnectWithoutPublishedByInput",
		upsert:"AdBoardPublishHistoryUpsertWithWhereUniqueWithoutPublishedByInput",
		createMany:"AdBoardPublishHistoryCreateManyPublishedByInputEnvelope",
		set:"AdBoardPublishHistoryWhereUniqueInput",
		disconnect:"AdBoardPublishHistoryWhereUniqueInput",
		delete:"AdBoardPublishHistoryWhereUniqueInput",
		connect:"AdBoardPublishHistoryWhereUniqueInput",
		update:"AdBoardPublishHistoryUpdateWithWhereUniqueWithoutPublishedByInput",
		updateMany:"AdBoardPublishHistoryUpdateManyWithWhereWithoutPublishedByInput",
		deleteMany:"AdBoardPublishHistoryScalarWhereInput"
	},
	AdBoardPublishHistoryUpsertWithWhereUniqueWithoutPublishedByInput:{
		where:"AdBoardPublishHistoryWhereUniqueInput",
		update:"AdBoardPublishHistoryUpdateWithoutPublishedByInput",
		create:"AdBoardPublishHistoryCreateWithoutPublishedByInput"
	},
	AdBoardPublishHistoryUpdateWithoutPublishedByInput:{
		publishedAt:"DateTime",
		changesSnapshot:"JSON",
		adBoard:"AdBoardUpdateOneRequiredWithoutHistoryNestedInput"
	},
	AdBoardUpdateOneRequiredWithoutHistoryNestedInput:{
		create:"AdBoardCreateWithoutHistoryInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutHistoryInput",
		upsert:"AdBoardUpsertWithoutHistoryInput",
		connect:"AdBoardWhereUniqueInput",
		update:"AdBoardUpdateToOneWithWhereWithoutHistoryInput"
	},
	AdBoardUpsertWithoutHistoryInput:{
		update:"AdBoardUpdateWithoutHistoryInput",
		create:"AdBoardCreateWithoutHistoryInput",
		where:"AdBoardWhereInput"
	},
	AdBoardUpdateWithoutHistoryInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryUpdateOneWithoutAdBoardsNestedInput",
		locationSubCategory:"LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput",
		type:"AdBoardTypeUpdateOneWithoutAdBoardsNestedInput",
		category:"BusinessCategoryUpdateOneWithoutAdBoardsNestedInput",
		restrictedBusines:"BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput",
		bookings:"BookingUpdateManyWithoutAdBoardNestedInput",
		orders:"OrderUpdateManyWithoutAdBoardNestedInput",
		slotConfigurations:"SlotConfigurationUpdateManyWithoutAdBoardNestedInput",
		offlineBookingsContact:"UserUpdateOneWithoutOfflineBookingAdboardsNestedInput",
		createdBy:"UserUpdateOneWithoutAdBoardsNestedInput",
		workspace:"WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput",
		players:"PlayerUpdateManyWithoutAdBoardsNestedInput",
		adBoardGroup:"AdBoardGroupUpdateOneWithoutAdBoardNestedInput",
		cameras:"CameraUpdateManyWithoutAdBoardNestedInput",
		publishedVersion:"AdBoardUpdateOneWithoutDraftAdboardNestedInput",
		draftAdboard:"AdBoardUpdateOneWithoutPublishedVersionNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput",
		archivedBy:"UserUpdateOneWithoutArchivedAdboardsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"
	},
	AdBoardGroupUpdateOneWithoutAdBoardNestedInput:{
		create:"AdBoardGroupCreateWithoutAdBoardInput",
		connectOrCreate:"AdBoardGroupCreateOrConnectWithoutAdBoardInput",
		upsert:"AdBoardGroupUpsertWithoutAdBoardInput",
		disconnect:"AdBoardGroupWhereInput",
		delete:"AdBoardGroupWhereInput",
		connect:"AdBoardGroupWhereUniqueInput",
		update:"AdBoardGroupUpdateToOneWithWhereWithoutAdBoardInput"
	},
	AdBoardGroupUpsertWithoutAdBoardInput:{
		update:"AdBoardGroupUpdateWithoutAdBoardInput",
		create:"AdBoardGroupCreateWithoutAdBoardInput",
		where:"AdBoardGroupWhereInput"
	},
	AdBoardGroupUpdateWithoutAdBoardInput:{
		schedules:"ScheduleCreateInput",
		fitImage:"FitType",
		fitVideo:"FitType",
		scheduledPlaylists:"PlaylistUpdateManyWithoutAdBoardGroupsNestedInput",
		adsPlaylist:"PlaylistUpdateOneWithoutAdBoardGroupsForAdsNestedInput",
		pisignagePlayers:"PlayerUpdateManyWithoutAdBoardGroupNestedInput",
		workspace:"WorkspaceUpdateOneRequiredWithoutAdBoardGroupsNestedInput"
	},
	WorkspaceUpdateOneRequiredWithoutAdBoardGroupsNestedInput:{
		create:"WorkspaceCreateWithoutAdBoardGroupsInput",
		connectOrCreate:"WorkspaceCreateOrConnectWithoutAdBoardGroupsInput",
		upsert:"WorkspaceUpsertWithoutAdBoardGroupsInput",
		connect:"WorkspaceWhereUniqueInput",
		update:"WorkspaceUpdateToOneWithWhereWithoutAdBoardGroupsInput"
	},
	WorkspaceUpsertWithoutAdBoardGroupsInput:{
		update:"WorkspaceUpdateWithoutAdBoardGroupsInput",
		create:"WorkspaceCreateWithoutAdBoardGroupsInput",
		where:"WorkspaceWhereInput"
	},
	WorkspaceUpdateWithoutAdBoardGroupsInput:{
		type:"WorkspaceType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		owner:"UserUpdateOneWithoutOwnedWorkspacesNestedInput",
		memberships:"MembershipUpdateManyWithoutWorkspaceNestedInput",
		adBoards:"AdBoardUpdateManyWithoutWorkspaceNestedInput",
		playlists:"PlaylistUpdateManyWithoutWorkspaceNestedInput",
		audits:"AuditUpdateManyWithoutWorkspaceNestedInput",
		bookings:"BookingUpdateManyWithoutWorkspaceNestedInput",
		files:"FileUpdateManyWithoutWorkspaceNestedInput",
		players:"PlayerUpdateManyWithoutWorkspaceNestedInput",
		transactions:"TransactionUpdateManyWithoutWorkspaceNestedInput",
		activeSubscription:"SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput",
		cameraServers:"CameraServerUpdateManyWithoutWorkspaceNestedInput",
		workspaceRoles:"WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput",
		webhookLogs:"WebhookLogUpdateManyWithoutWorkspaceNestedInput",
		leads:"UserUpdateManyWithoutLeadWorspacesNestedInput"
	},
	BookingUpdateManyWithoutWorkspaceNestedInput:{
		create:"BookingCreateWithoutWorkspaceInput",
		connectOrCreate:"BookingCreateOrConnectWithoutWorkspaceInput",
		upsert:"BookingUpsertWithWhereUniqueWithoutWorkspaceInput",
		createMany:"BookingCreateManyWorkspaceInputEnvelope",
		set:"BookingWhereUniqueInput",
		disconnect:"BookingWhereUniqueInput",
		delete:"BookingWhereUniqueInput",
		connect:"BookingWhereUniqueInput",
		update:"BookingUpdateWithWhereUniqueWithoutWorkspaceInput",
		updateMany:"BookingUpdateManyWithWhereWithoutWorkspaceInput",
		deleteMany:"BookingScalarWhereInput"
	},
	BookingUpsertWithWhereUniqueWithoutWorkspaceInput:{
		where:"BookingWhereUniqueInput",
		update:"BookingUpdateWithoutWorkspaceInput",
		create:"BookingCreateWithoutWorkspaceInput"
	},
	BookingUpdateWithoutWorkspaceInput:{
		bookingDate:"DateTime",
		bookingDetails:"BookingDetailsCreateInput",
		bookingStatus:"BookingStatus",
		paymentStatus:"PaymentStatus",
		deploymentStatus:"DeploymentStatus",
		contentApprovalStatus:"ContentApprovalStatus",
		selectedDates:"OrderSelectedDateCreateInput",
		payment:"BookingPaymentCreateInput",
		priceSummary:"PriceSummaryCreateInput",
		pisignage:"BookingPisignageDataCreateInput",
		refund:"BookingRefundCreateInput",
		assets:"FileUpdateManyWithoutBookingsNestedInput",
		businessCategory:"BusinessCategoryUpdateOneWithoutBookingsNestedInput",
		proofs:"FileUpdateManyWithoutProofBookingNestedInput",
		user:"UserUpdateOneRequiredWithoutBookingsNestedInput",
		adBoard:"AdBoardUpdateOneRequiredWithoutBookingsNestedInput",
		changelogs:"ChangelogUpdateManyWithoutBookingNestedInput",
		campaign:"CampaignUpdateOneWithoutBookingsNestedInput",
		payouts:"PayoutUpdateManyWithoutBookingNestedInput",
		sharedUsers:"UserUpdateManyWithoutSharedBookingsNestedInput",
		webhookLogs:"WebhookLogUpdateManyWithoutBookingNestedInput",
		slotUsage:"SlotUsageUpdateManyWithoutBookingNestedInput"
	},
	CampaignUpdateOneWithoutBookingsNestedInput:{
		create:"CampaignCreateWithoutBookingsInput",
		connectOrCreate:"CampaignCreateOrConnectWithoutBookingsInput",
		upsert:"CampaignUpsertWithoutBookingsInput",
		delete:"CampaignWhereInput",
		connect:"CampaignWhereUniqueInput",
		update:"CampaignUpdateToOneWithWhereWithoutBookingsInput"
	},
	CampaignUpsertWithoutBookingsInput:{
		update:"CampaignUpdateWithoutBookingsInput",
		create:"CampaignCreateWithoutBookingsInput",
		where:"CampaignWhereInput"
	},
	CampaignUpdateWithoutBookingsInput:{
		createdAt:"DateTime",
		updatedAt:"DateTime",
		payment:"BookingPaymentCreateInput",
		paymentStatus:"PaymentStatus",
		priceSummary:"PriceSummaryCreateInput",
		selectedDates:"OrderSelectedDateCreateInput",
		user:"UserUpdateOneRequiredWithoutCampaignsNestedInput",
		businessCategory:"BusinessCategoryUpdateOneRequiredWithoutCampaignsNestedInput",
		payments:"PaymentUpdateManyWithoutCampaignNestedInput",
		assets:"FileUpdateManyWithoutCampaignsNestedInput"
	},
	PaymentUpdateManyWithoutCampaignNestedInput:{
		create:"PaymentCreateWithoutCampaignInput",
		connectOrCreate:"PaymentCreateOrConnectWithoutCampaignInput",
		upsert:"PaymentUpsertWithWhereUniqueWithoutCampaignInput",
		createMany:"PaymentCreateManyCampaignInputEnvelope",
		set:"PaymentWhereUniqueInput",
		disconnect:"PaymentWhereUniqueInput",
		delete:"PaymentWhereUniqueInput",
		connect:"PaymentWhereUniqueInput",
		update:"PaymentUpdateWithWhereUniqueWithoutCampaignInput",
		updateMany:"PaymentUpdateManyWithWhereWithoutCampaignInput",
		deleteMany:"PaymentScalarWhereInput"
	},
	PaymentUpsertWithWhereUniqueWithoutCampaignInput:{
		where:"PaymentWhereUniqueInput",
		update:"PaymentUpdateWithoutCampaignInput",
		create:"PaymentCreateWithoutCampaignInput"
	},
	PaymentUpdateWithoutCampaignInput:{
		paymentGateway:"PaymentGatewayName",
		createdAt:"DateTime",
		updatedAt:"DateTime"
	},
	PaymentUpdateWithWhereUniqueWithoutCampaignInput:{
		where:"PaymentWhereUniqueInput",
		data:"PaymentUpdateWithoutCampaignInput"
	},
	PaymentUpdateManyWithWhereWithoutCampaignInput:{
		where:"PaymentScalarWhereInput",
		data:"PaymentUpdateManyMutationInput"
	},
	PaymentScalarWhereInput:{
		AND:"PaymentScalarWhereInput",
		OR:"PaymentScalarWhereInput",
		NOT:"PaymentScalarWhereInput",
		id:"StringFilter",
		paymentIntentId:"StringFilter",
		checkoutSessionId:"StringFilter",
		eventId:"StringFilter",
		liveMode:"BoolFilter",
		paymentGateway:"EnumPaymentGatewayNameFilter",
		subtotal:"FloatFilter",
		total:"FloatFilter",
		tax:"FloatFilter",
		discount:"FloatFilter",
		campaignId:"StringNullableFilter",
		createdAt:"DateTimeFilter",
		updatedAt:"DateTimeFilter"
	},
	PaymentUpdateManyMutationInput:{
		paymentGateway:"PaymentGatewayName",
		createdAt:"DateTime",
		updatedAt:"DateTime"
	},
	FileUpdateManyWithoutCampaignsNestedInput:{
		create:"FileCreateWithoutCampaignsInput",
		connectOrCreate:"FileCreateOrConnectWithoutCampaignsInput",
		upsert:"FileUpsertWithWhereUniqueWithoutCampaignsInput",
		set:"FileWhereUniqueInput",
		disconnect:"FileWhereUniqueInput",
		delete:"FileWhereUniqueInput",
		connect:"FileWhereUniqueInput",
		update:"FileUpdateWithWhereUniqueWithoutCampaignsInput",
		updateMany:"FileUpdateManyWithWhereWithoutCampaignsInput",
		deleteMany:"FileScalarWhereInput"
	},
	FileUpsertWithWhereUniqueWithoutCampaignsInput:{
		where:"FileWhereUniqueInput",
		update:"FileUpdateWithoutCampaignsInput",
		create:"FileCreateWithoutCampaignsInput"
	},
	FileUpdateWithoutCampaignsInput:{
		meta:"FileMetaCreateInput",
		status:"FileStatus",
		updatedAt:"DateTime",
		createdAt:"DateTime",
		updatedBy:"UserUpdateOneWithoutUpdatedFilesNestedInput",
		createdBy:"UserUpdateOneRequiredWithoutCreatedFilesNestedInput",
		bookings:"BookingUpdateManyWithoutAssetsNestedInput",
		workspace:"WorkspaceUpdateOneWithoutFilesNestedInput",
		proofBooking:"BookingUpdateOneWithoutProofsNestedInput"
	},
	BookingUpdateOneWithoutProofsNestedInput:{
		create:"BookingCreateWithoutProofsInput",
		connectOrCreate:"BookingCreateOrConnectWithoutProofsInput",
		upsert:"BookingUpsertWithoutProofsInput",
		delete:"BookingWhereInput",
		connect:"BookingWhereUniqueInput",
		update:"BookingUpdateToOneWithWhereWithoutProofsInput"
	},
	BookingUpsertWithoutProofsInput:{
		update:"BookingUpdateWithoutProofsInput",
		create:"BookingCreateWithoutProofsInput",
		where:"BookingWhereInput"
	},
	BookingUpdateWithoutProofsInput:{
		bookingDate:"DateTime",
		bookingDetails:"BookingDetailsCreateInput",
		bookingStatus:"BookingStatus",
		paymentStatus:"PaymentStatus",
		deploymentStatus:"DeploymentStatus",
		contentApprovalStatus:"ContentApprovalStatus",
		selectedDates:"OrderSelectedDateCreateInput",
		payment:"BookingPaymentCreateInput",
		priceSummary:"PriceSummaryCreateInput",
		pisignage:"BookingPisignageDataCreateInput",
		refund:"BookingRefundCreateInput",
		assets:"FileUpdateManyWithoutBookingsNestedInput",
		businessCategory:"BusinessCategoryUpdateOneWithoutBookingsNestedInput",
		user:"UserUpdateOneRequiredWithoutBookingsNestedInput",
		adBoard:"AdBoardUpdateOneRequiredWithoutBookingsNestedInput",
		changelogs:"ChangelogUpdateManyWithoutBookingNestedInput",
		workspace:"WorkspaceUpdateOneWithoutBookingsNestedInput",
		campaign:"CampaignUpdateOneWithoutBookingsNestedInput",
		payouts:"PayoutUpdateManyWithoutBookingNestedInput",
		sharedUsers:"UserUpdateManyWithoutSharedBookingsNestedInput",
		webhookLogs:"WebhookLogUpdateManyWithoutBookingNestedInput",
		slotUsage:"SlotUsageUpdateManyWithoutBookingNestedInput"
	},
	WorkspaceUpdateOneWithoutBookingsNestedInput:{
		create:"WorkspaceCreateWithoutBookingsInput",
		connectOrCreate:"WorkspaceCreateOrConnectWithoutBookingsInput",
		upsert:"WorkspaceUpsertWithoutBookingsInput",
		delete:"WorkspaceWhereInput",
		connect:"WorkspaceWhereUniqueInput",
		update:"WorkspaceUpdateToOneWithWhereWithoutBookingsInput"
	},
	WorkspaceUpsertWithoutBookingsInput:{
		update:"WorkspaceUpdateWithoutBookingsInput",
		create:"WorkspaceCreateWithoutBookingsInput",
		where:"WorkspaceWhereInput"
	},
	WorkspaceUpdateWithoutBookingsInput:{
		type:"WorkspaceType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		owner:"UserUpdateOneWithoutOwnedWorkspacesNestedInput",
		memberships:"MembershipUpdateManyWithoutWorkspaceNestedInput",
		adBoards:"AdBoardUpdateManyWithoutWorkspaceNestedInput",
		playlists:"PlaylistUpdateManyWithoutWorkspaceNestedInput",
		adBoardGroups:"AdBoardGroupUpdateManyWithoutWorkspaceNestedInput",
		audits:"AuditUpdateManyWithoutWorkspaceNestedInput",
		files:"FileUpdateManyWithoutWorkspaceNestedInput",
		players:"PlayerUpdateManyWithoutWorkspaceNestedInput",
		transactions:"TransactionUpdateManyWithoutWorkspaceNestedInput",
		activeSubscription:"SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput",
		cameraServers:"CameraServerUpdateManyWithoutWorkspaceNestedInput",
		workspaceRoles:"WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput",
		webhookLogs:"WebhookLogUpdateManyWithoutWorkspaceNestedInput",
		leads:"UserUpdateManyWithoutLeadWorspacesNestedInput"
	},
	FileUpdateManyWithoutWorkspaceNestedInput:{
		create:"FileCreateWithoutWorkspaceInput",
		connectOrCreate:"FileCreateOrConnectWithoutWorkspaceInput",
		upsert:"FileUpsertWithWhereUniqueWithoutWorkspaceInput",
		createMany:"FileCreateManyWorkspaceInputEnvelope",
		set:"FileWhereUniqueInput",
		disconnect:"FileWhereUniqueInput",
		delete:"FileWhereUniqueInput",
		connect:"FileWhereUniqueInput",
		update:"FileUpdateWithWhereUniqueWithoutWorkspaceInput",
		updateMany:"FileUpdateManyWithWhereWithoutWorkspaceInput",
		deleteMany:"FileScalarWhereInput"
	},
	FileUpsertWithWhereUniqueWithoutWorkspaceInput:{
		where:"FileWhereUniqueInput",
		update:"FileUpdateWithoutWorkspaceInput",
		create:"FileCreateWithoutWorkspaceInput"
	},
	FileUpdateWithoutWorkspaceInput:{
		meta:"FileMetaCreateInput",
		status:"FileStatus",
		updatedAt:"DateTime",
		createdAt:"DateTime",
		updatedBy:"UserUpdateOneWithoutUpdatedFilesNestedInput",
		createdBy:"UserUpdateOneRequiredWithoutCreatedFilesNestedInput",
		bookings:"BookingUpdateManyWithoutAssetsNestedInput",
		proofBooking:"BookingUpdateOneWithoutProofsNestedInput",
		campaigns:"CampaignUpdateManyWithoutAssetsNestedInput"
	},
	CampaignUpdateManyWithoutAssetsNestedInput:{
		create:"CampaignCreateWithoutAssetsInput",
		connectOrCreate:"CampaignCreateOrConnectWithoutAssetsInput",
		upsert:"CampaignUpsertWithWhereUniqueWithoutAssetsInput",
		set:"CampaignWhereUniqueInput",
		disconnect:"CampaignWhereUniqueInput",
		delete:"CampaignWhereUniqueInput",
		connect:"CampaignWhereUniqueInput",
		update:"CampaignUpdateWithWhereUniqueWithoutAssetsInput",
		updateMany:"CampaignUpdateManyWithWhereWithoutAssetsInput",
		deleteMany:"CampaignScalarWhereInput"
	},
	CampaignUpsertWithWhereUniqueWithoutAssetsInput:{
		where:"CampaignWhereUniqueInput",
		update:"CampaignUpdateWithoutAssetsInput",
		create:"CampaignCreateWithoutAssetsInput"
	},
	CampaignUpdateWithoutAssetsInput:{
		createdAt:"DateTime",
		updatedAt:"DateTime",
		payment:"BookingPaymentCreateInput",
		paymentStatus:"PaymentStatus",
		priceSummary:"PriceSummaryCreateInput",
		selectedDates:"OrderSelectedDateCreateInput",
		user:"UserUpdateOneRequiredWithoutCampaignsNestedInput",
		businessCategory:"BusinessCategoryUpdateOneRequiredWithoutCampaignsNestedInput",
		bookings:"BookingUpdateManyWithoutCampaignNestedInput",
		payments:"PaymentUpdateManyWithoutCampaignNestedInput"
	},
	BookingUpdateManyWithoutCampaignNestedInput:{
		create:"BookingCreateWithoutCampaignInput",
		connectOrCreate:"BookingCreateOrConnectWithoutCampaignInput",
		upsert:"BookingUpsertWithWhereUniqueWithoutCampaignInput",
		createMany:"BookingCreateManyCampaignInputEnvelope",
		set:"BookingWhereUniqueInput",
		disconnect:"BookingWhereUniqueInput",
		delete:"BookingWhereUniqueInput",
		connect:"BookingWhereUniqueInput",
		update:"BookingUpdateWithWhereUniqueWithoutCampaignInput",
		updateMany:"BookingUpdateManyWithWhereWithoutCampaignInput",
		deleteMany:"BookingScalarWhereInput"
	},
	BookingUpsertWithWhereUniqueWithoutCampaignInput:{
		where:"BookingWhereUniqueInput",
		update:"BookingUpdateWithoutCampaignInput",
		create:"BookingCreateWithoutCampaignInput"
	},
	BookingUpdateWithoutCampaignInput:{
		bookingDate:"DateTime",
		bookingDetails:"BookingDetailsCreateInput",
		bookingStatus:"BookingStatus",
		paymentStatus:"PaymentStatus",
		deploymentStatus:"DeploymentStatus",
		contentApprovalStatus:"ContentApprovalStatus",
		selectedDates:"OrderSelectedDateCreateInput",
		payment:"BookingPaymentCreateInput",
		priceSummary:"PriceSummaryCreateInput",
		pisignage:"BookingPisignageDataCreateInput",
		refund:"BookingRefundCreateInput",
		assets:"FileUpdateManyWithoutBookingsNestedInput",
		businessCategory:"BusinessCategoryUpdateOneWithoutBookingsNestedInput",
		proofs:"FileUpdateManyWithoutProofBookingNestedInput",
		user:"UserUpdateOneRequiredWithoutBookingsNestedInput",
		adBoard:"AdBoardUpdateOneRequiredWithoutBookingsNestedInput",
		changelogs:"ChangelogUpdateManyWithoutBookingNestedInput",
		workspace:"WorkspaceUpdateOneWithoutBookingsNestedInput",
		payouts:"PayoutUpdateManyWithoutBookingNestedInput",
		sharedUsers:"UserUpdateManyWithoutSharedBookingsNestedInput",
		webhookLogs:"WebhookLogUpdateManyWithoutBookingNestedInput",
		slotUsage:"SlotUsageUpdateManyWithoutBookingNestedInput"
	},
	PayoutUpdateManyWithoutBookingNestedInput:{
		create:"PayoutCreateWithoutBookingInput",
		connectOrCreate:"PayoutCreateOrConnectWithoutBookingInput",
		upsert:"PayoutUpsertWithWhereUniqueWithoutBookingInput",
		createMany:"PayoutCreateManyBookingInputEnvelope",
		set:"PayoutWhereUniqueInput",
		disconnect:"PayoutWhereUniqueInput",
		delete:"PayoutWhereUniqueInput",
		connect:"PayoutWhereUniqueInput",
		update:"PayoutUpdateWithWhereUniqueWithoutBookingInput",
		updateMany:"PayoutUpdateManyWithWhereWithoutBookingInput",
		deleteMany:"PayoutScalarWhereInput"
	},
	PayoutUpsertWithWhereUniqueWithoutBookingInput:{
		where:"PayoutWhereUniqueInput",
		update:"PayoutUpdateWithoutBookingInput",
		create:"PayoutCreateWithoutBookingInput"
	},
	PayoutUpdateWithoutBookingInput:{
		createdAt:"DateTime",
		status:"PayoutStatus",
		transaction:"TransactionUpdateOneWithoutPayoutsNestedInput"
	},
	TransactionUpdateOneWithoutPayoutsNestedInput:{
		create:"TransactionCreateWithoutPayoutsInput",
		connectOrCreate:"TransactionCreateOrConnectWithoutPayoutsInput",
		upsert:"TransactionUpsertWithoutPayoutsInput",
		delete:"TransactionWhereInput",
		connect:"TransactionWhereUniqueInput",
		update:"TransactionUpdateToOneWithWhereWithoutPayoutsInput"
	},
	TransactionUpsertWithoutPayoutsInput:{
		update:"TransactionUpdateWithoutPayoutsInput",
		create:"TransactionCreateWithoutPayoutsInput",
		where:"TransactionWhereInput"
	},
	TransactionUpdateWithoutPayoutsInput:{
		createAt:"DateTime",
		processedAt:"DateTime",
		transactionStatus:"TransactionStatus",
		details:"JSON",
		workspace:"WorkspaceUpdateOneWithoutTransactionsNestedInput"
	},
	WorkspaceUpdateOneWithoutTransactionsNestedInput:{
		create:"WorkspaceCreateWithoutTransactionsInput",
		connectOrCreate:"WorkspaceCreateOrConnectWithoutTransactionsInput",
		upsert:"WorkspaceUpsertWithoutTransactionsInput",
		delete:"WorkspaceWhereInput",
		connect:"WorkspaceWhereUniqueInput",
		update:"WorkspaceUpdateToOneWithWhereWithoutTransactionsInput"
	},
	WorkspaceUpsertWithoutTransactionsInput:{
		update:"WorkspaceUpdateWithoutTransactionsInput",
		create:"WorkspaceCreateWithoutTransactionsInput",
		where:"WorkspaceWhereInput"
	},
	WorkspaceUpdateWithoutTransactionsInput:{
		type:"WorkspaceType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		owner:"UserUpdateOneWithoutOwnedWorkspacesNestedInput",
		memberships:"MembershipUpdateManyWithoutWorkspaceNestedInput",
		adBoards:"AdBoardUpdateManyWithoutWorkspaceNestedInput",
		playlists:"PlaylistUpdateManyWithoutWorkspaceNestedInput",
		adBoardGroups:"AdBoardGroupUpdateManyWithoutWorkspaceNestedInput",
		audits:"AuditUpdateManyWithoutWorkspaceNestedInput",
		bookings:"BookingUpdateManyWithoutWorkspaceNestedInput",
		files:"FileUpdateManyWithoutWorkspaceNestedInput",
		players:"PlayerUpdateManyWithoutWorkspaceNestedInput",
		activeSubscription:"SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput",
		cameraServers:"CameraServerUpdateManyWithoutWorkspaceNestedInput",
		workspaceRoles:"WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput",
		webhookLogs:"WebhookLogUpdateManyWithoutWorkspaceNestedInput",
		leads:"UserUpdateManyWithoutLeadWorspacesNestedInput"
	},
	PlayerUpdateManyWithoutWorkspaceNestedInput:{
		create:"PlayerCreateWithoutWorkspaceInput",
		connectOrCreate:"PlayerCreateOrConnectWithoutWorkspaceInput",
		upsert:"PlayerUpsertWithWhereUniqueWithoutWorkspaceInput",
		createMany:"PlayerCreateManyWorkspaceInputEnvelope",
		set:"PlayerWhereUniqueInput",
		disconnect:"PlayerWhereUniqueInput",
		delete:"PlayerWhereUniqueInput",
		connect:"PlayerWhereUniqueInput",
		update:"PlayerUpdateWithWhereUniqueWithoutWorkspaceInput",
		updateMany:"PlayerUpdateManyWithWhereWithoutWorkspaceInput",
		deleteMany:"PlayerScalarWhereInput"
	},
	PlayerUpsertWithWhereUniqueWithoutWorkspaceInput:{
		where:"PlayerWhereUniqueInput",
		update:"PlayerUpdateWithoutWorkspaceInput",
		create:"PlayerCreateWithoutWorkspaceInput"
	},
	PlayerUpdateWithoutWorkspaceInput:{
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackDetails:"PlaybackTimeCreateInput",
		aspectRatioType:"AspectRatioType",
		map:"MapCreateInput",
		adBoardGroup:"AdBoardGroupUpdateOneWithoutPisignagePlayersNestedInput",
		status:"PlayerUptimeStatusUpdateManyWithoutPlayerNestedInput",
		cameras:"CameraUpdateManyWithoutPlayerNestedInput",
		adBoards:"AdBoardUpdateManyWithoutPlayersNestedInput"
	},
	PlayerUptimeStatusUpdateManyWithoutPlayerNestedInput:{
		create:"PlayerUptimeStatusCreateWithoutPlayerInput",
		connectOrCreate:"PlayerUptimeStatusCreateOrConnectWithoutPlayerInput",
		upsert:"PlayerUptimeStatusUpsertWithWhereUniqueWithoutPlayerInput",
		createMany:"PlayerUptimeStatusCreateManyPlayerInputEnvelope",
		set:"PlayerUptimeStatusWhereUniqueInput",
		disconnect:"PlayerUptimeStatusWhereUniqueInput",
		delete:"PlayerUptimeStatusWhereUniqueInput",
		connect:"PlayerUptimeStatusWhereUniqueInput",
		update:"PlayerUptimeStatusUpdateWithWhereUniqueWithoutPlayerInput",
		updateMany:"PlayerUptimeStatusUpdateManyWithWhereWithoutPlayerInput",
		deleteMany:"PlayerUptimeStatusScalarWhereInput"
	},
	PlayerUptimeStatusUpsertWithWhereUniqueWithoutPlayerInput:{
		where:"PlayerUptimeStatusWhereUniqueInput",
		update:"PlayerUptimeStatusUpdateWithoutPlayerInput",
		create:"PlayerUptimeStatusCreateWithoutPlayerInput"
	},
	PlayerUptimeStatusUpdateWithoutPlayerInput:{

	},
	PlayerUptimeStatusUpdateWithWhereUniqueWithoutPlayerInput:{
		where:"PlayerUptimeStatusWhereUniqueInput",
		data:"PlayerUptimeStatusUpdateWithoutPlayerInput"
	},
	PlayerUptimeStatusUpdateManyWithWhereWithoutPlayerInput:{
		where:"PlayerUptimeStatusScalarWhereInput",
		data:"PlayerUptimeStatusUpdateManyMutationInput"
	},
	PlayerUptimeStatusScalarWhereInput:{
		AND:"PlayerUptimeStatusScalarWhereInput",
		OR:"PlayerUptimeStatusScalarWhereInput",
		NOT:"PlayerUptimeStatusScalarWhereInput",
		id:"StringFilter",
		playerId:"StringFilter",
		cpuSerialNumber:"StringFilter",
		date:"StringFilter",
		uptime:"FloatFilter"
	},
	PlayerUptimeStatusUpdateManyMutationInput:{

	},
	CameraUpdateManyWithoutPlayerNestedInput:{
		create:"CameraCreateWithoutPlayerInput",
		connectOrCreate:"CameraCreateOrConnectWithoutPlayerInput",
		upsert:"CameraUpsertWithWhereUniqueWithoutPlayerInput",
		createMany:"CameraCreateManyPlayerInputEnvelope",
		set:"CameraWhereUniqueInput",
		disconnect:"CameraWhereUniqueInput",
		delete:"CameraWhereUniqueInput",
		connect:"CameraWhereUniqueInput",
		update:"CameraUpdateWithWhereUniqueWithoutPlayerInput",
		updateMany:"CameraUpdateManyWithWhereWithoutPlayerInput",
		deleteMany:"CameraScalarWhereInput"
	},
	CameraUpsertWithWhereUniqueWithoutPlayerInput:{
		where:"CameraWhereUniqueInput",
		update:"CameraUpdateWithoutPlayerInput",
		create:"CameraCreateWithoutPlayerInput"
	},
	CameraUpdateWithoutPlayerInput:{
		updatedAt:"DateTime",
		cameraServer:"CameraServerUpdateOneRequiredWithoutCamerasNestedInput",
		feeds:"CameraFeedUpdateManyWithoutCameraNestedInput",
		adBoard:"AdBoardUpdateOneWithoutCamerasNestedInput"
	},
	CameraServerUpdateOneRequiredWithoutCamerasNestedInput:{
		create:"CameraServerCreateWithoutCamerasInput",
		connectOrCreate:"CameraServerCreateOrConnectWithoutCamerasInput",
		upsert:"CameraServerUpsertWithoutCamerasInput",
		connect:"CameraServerWhereUniqueInput",
		update:"CameraServerUpdateToOneWithWhereWithoutCamerasInput"
	},
	CameraServerUpsertWithoutCamerasInput:{
		update:"CameraServerUpdateWithoutCamerasInput",
		create:"CameraServerCreateWithoutCamerasInput",
		where:"CameraServerWhereInput"
	},
	CameraServerUpdateWithoutCamerasInput:{
		workspace:"WorkspaceUpdateOneWithoutCameraServersNestedInput",
		feeds:"CameraFeedUpdateManyWithoutCameraServerNestedInput"
	},
	WorkspaceUpdateOneWithoutCameraServersNestedInput:{
		create:"WorkspaceCreateWithoutCameraServersInput",
		connectOrCreate:"WorkspaceCreateOrConnectWithoutCameraServersInput",
		upsert:"WorkspaceUpsertWithoutCameraServersInput",
		delete:"WorkspaceWhereInput",
		connect:"WorkspaceWhereUniqueInput",
		update:"WorkspaceUpdateToOneWithWhereWithoutCameraServersInput"
	},
	WorkspaceUpsertWithoutCameraServersInput:{
		update:"WorkspaceUpdateWithoutCameraServersInput",
		create:"WorkspaceCreateWithoutCameraServersInput",
		where:"WorkspaceWhereInput"
	},
	WorkspaceUpdateWithoutCameraServersInput:{
		type:"WorkspaceType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		owner:"UserUpdateOneWithoutOwnedWorkspacesNestedInput",
		memberships:"MembershipUpdateManyWithoutWorkspaceNestedInput",
		adBoards:"AdBoardUpdateManyWithoutWorkspaceNestedInput",
		playlists:"PlaylistUpdateManyWithoutWorkspaceNestedInput",
		adBoardGroups:"AdBoardGroupUpdateManyWithoutWorkspaceNestedInput",
		audits:"AuditUpdateManyWithoutWorkspaceNestedInput",
		bookings:"BookingUpdateManyWithoutWorkspaceNestedInput",
		files:"FileUpdateManyWithoutWorkspaceNestedInput",
		players:"PlayerUpdateManyWithoutWorkspaceNestedInput",
		transactions:"TransactionUpdateManyWithoutWorkspaceNestedInput",
		activeSubscription:"SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput",
		workspaceRoles:"WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput",
		webhookLogs:"WebhookLogUpdateManyWithoutWorkspaceNestedInput",
		leads:"UserUpdateManyWithoutLeadWorspacesNestedInput"
	},
	TransactionUpdateManyWithoutWorkspaceNestedInput:{
		create:"TransactionCreateWithoutWorkspaceInput",
		connectOrCreate:"TransactionCreateOrConnectWithoutWorkspaceInput",
		upsert:"TransactionUpsertWithWhereUniqueWithoutWorkspaceInput",
		createMany:"TransactionCreateManyWorkspaceInputEnvelope",
		set:"TransactionWhereUniqueInput",
		disconnect:"TransactionWhereUniqueInput",
		delete:"TransactionWhereUniqueInput",
		connect:"TransactionWhereUniqueInput",
		update:"TransactionUpdateWithWhereUniqueWithoutWorkspaceInput",
		updateMany:"TransactionUpdateManyWithWhereWithoutWorkspaceInput",
		deleteMany:"TransactionScalarWhereInput"
	},
	TransactionUpsertWithWhereUniqueWithoutWorkspaceInput:{
		where:"TransactionWhereUniqueInput",
		update:"TransactionUpdateWithoutWorkspaceInput",
		create:"TransactionCreateWithoutWorkspaceInput"
	},
	TransactionUpdateWithoutWorkspaceInput:{
		createAt:"DateTime",
		processedAt:"DateTime",
		transactionStatus:"TransactionStatus",
		details:"JSON",
		payouts:"PayoutUpdateManyWithoutTransactionNestedInput"
	},
	PayoutUpdateManyWithoutTransactionNestedInput:{
		create:"PayoutCreateWithoutTransactionInput",
		connectOrCreate:"PayoutCreateOrConnectWithoutTransactionInput",
		upsert:"PayoutUpsertWithWhereUniqueWithoutTransactionInput",
		createMany:"PayoutCreateManyTransactionInputEnvelope",
		set:"PayoutWhereUniqueInput",
		disconnect:"PayoutWhereUniqueInput",
		delete:"PayoutWhereUniqueInput",
		connect:"PayoutWhereUniqueInput",
		update:"PayoutUpdateWithWhereUniqueWithoutTransactionInput",
		updateMany:"PayoutUpdateManyWithWhereWithoutTransactionInput",
		deleteMany:"PayoutScalarWhereInput"
	},
	PayoutUpsertWithWhereUniqueWithoutTransactionInput:{
		where:"PayoutWhereUniqueInput",
		update:"PayoutUpdateWithoutTransactionInput",
		create:"PayoutCreateWithoutTransactionInput"
	},
	PayoutUpdateWithoutTransactionInput:{
		createdAt:"DateTime",
		status:"PayoutStatus",
		booking:"BookingUpdateOneRequiredWithoutPayoutsNestedInput"
	},
	BookingUpdateOneRequiredWithoutPayoutsNestedInput:{
		create:"BookingCreateWithoutPayoutsInput",
		connectOrCreate:"BookingCreateOrConnectWithoutPayoutsInput",
		upsert:"BookingUpsertWithoutPayoutsInput",
		connect:"BookingWhereUniqueInput",
		update:"BookingUpdateToOneWithWhereWithoutPayoutsInput"
	},
	BookingUpsertWithoutPayoutsInput:{
		update:"BookingUpdateWithoutPayoutsInput",
		create:"BookingCreateWithoutPayoutsInput",
		where:"BookingWhereInput"
	},
	BookingUpdateWithoutPayoutsInput:{
		bookingDate:"DateTime",
		bookingDetails:"BookingDetailsCreateInput",
		bookingStatus:"BookingStatus",
		paymentStatus:"PaymentStatus",
		deploymentStatus:"DeploymentStatus",
		contentApprovalStatus:"ContentApprovalStatus",
		selectedDates:"OrderSelectedDateCreateInput",
		payment:"BookingPaymentCreateInput",
		priceSummary:"PriceSummaryCreateInput",
		pisignage:"BookingPisignageDataCreateInput",
		refund:"BookingRefundCreateInput",
		assets:"FileUpdateManyWithoutBookingsNestedInput",
		businessCategory:"BusinessCategoryUpdateOneWithoutBookingsNestedInput",
		proofs:"FileUpdateManyWithoutProofBookingNestedInput",
		user:"UserUpdateOneRequiredWithoutBookingsNestedInput",
		adBoard:"AdBoardUpdateOneRequiredWithoutBookingsNestedInput",
		changelogs:"ChangelogUpdateManyWithoutBookingNestedInput",
		workspace:"WorkspaceUpdateOneWithoutBookingsNestedInput",
		campaign:"CampaignUpdateOneWithoutBookingsNestedInput",
		sharedUsers:"UserUpdateManyWithoutSharedBookingsNestedInput",
		webhookLogs:"WebhookLogUpdateManyWithoutBookingNestedInput",
		slotUsage:"SlotUsageUpdateManyWithoutBookingNestedInput"
	},
	UserUpdateManyWithoutSharedBookingsNestedInput:{
		create:"UserCreateWithoutSharedBookingsInput",
		connectOrCreate:"UserCreateOrConnectWithoutSharedBookingsInput",
		upsert:"UserUpsertWithWhereUniqueWithoutSharedBookingsInput",
		set:"UserWhereUniqueInput",
		disconnect:"UserWhereUniqueInput",
		delete:"UserWhereUniqueInput",
		connect:"UserWhereUniqueInput",
		update:"UserUpdateWithWhereUniqueWithoutSharedBookingsInput",
		updateMany:"UserUpdateManyWithWhereWithoutSharedBookingsInput",
		deleteMany:"UserScalarWhereInput"
	},
	UserUpsertWithWhereUniqueWithoutSharedBookingsInput:{
		where:"UserWhereUniqueInput",
		update:"UserUpdateWithoutSharedBookingsInput",
		create:"UserCreateWithoutSharedBookingsInput"
	},
	UserUpdateWithoutSharedBookingsInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingUpdateManyWithoutUserNestedInput",
		createdFiles:"FileUpdateManyWithoutCreatedByNestedInput",
		updatedFiles:"FileUpdateManyWithoutUpdatedByNestedInput",
		orders:"OrderUpdateManyWithoutCreatedByNestedInput",
		changelogs:"ChangelogUpdateManyWithoutUserNestedInput",
		adBoards:"AdBoardUpdateManyWithoutCreatedByNestedInput",
		audits:"AuditUpdateManyWithoutUserNestedInput",
		campaigns:"CampaignUpdateManyWithoutUserNestedInput",
		memberships:"MembershipUpdateManyWithoutUserNestedInput",
		notifications:"NotificationUpdateManyWithoutRecipientNestedInput",
		publishAdboardVersion:"AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput",
		offlineBookingAdboards:"AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutUserNestedInput",
		archivedAdboards:"AdBoardUpdateManyWithoutArchivedByNestedInput",
		ownedWorkspaces:"WorkspaceUpdateManyWithoutOwnerNestedInput",
		leadWorspaces:"WorkspaceUpdateManyWithoutLeadsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutUserNestedInput",
		userPositions:"UserPositionUpdateManyWithoutUserNestedInput"
	},
	AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput:{
		create:"AdBoardCreateWithoutOfflineBookingsContactInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutOfflineBookingsContactInput",
		upsert:"AdBoardUpsertWithWhereUniqueWithoutOfflineBookingsContactInput",
		createMany:"AdBoardCreateManyOfflineBookingsContactInputEnvelope",
		set:"AdBoardWhereUniqueInput",
		disconnect:"AdBoardWhereUniqueInput",
		delete:"AdBoardWhereUniqueInput",
		connect:"AdBoardWhereUniqueInput",
		update:"AdBoardUpdateWithWhereUniqueWithoutOfflineBookingsContactInput",
		updateMany:"AdBoardUpdateManyWithWhereWithoutOfflineBookingsContactInput",
		deleteMany:"AdBoardScalarWhereInput"
	},
	AdBoardUpsertWithWhereUniqueWithoutOfflineBookingsContactInput:{
		where:"AdBoardWhereUniqueInput",
		update:"AdBoardUpdateWithoutOfflineBookingsContactInput",
		create:"AdBoardCreateWithoutOfflineBookingsContactInput"
	},
	AdBoardUpdateWithoutOfflineBookingsContactInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryUpdateOneWithoutAdBoardsNestedInput",
		locationSubCategory:"LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput",
		type:"AdBoardTypeUpdateOneWithoutAdBoardsNestedInput",
		category:"BusinessCategoryUpdateOneWithoutAdBoardsNestedInput",
		restrictedBusines:"BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput",
		bookings:"BookingUpdateManyWithoutAdBoardNestedInput",
		orders:"OrderUpdateManyWithoutAdBoardNestedInput",
		slotConfigurations:"SlotConfigurationUpdateManyWithoutAdBoardNestedInput",
		createdBy:"UserUpdateOneWithoutAdBoardsNestedInput",
		workspace:"WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput",
		players:"PlayerUpdateManyWithoutAdBoardsNestedInput",
		adBoardGroup:"AdBoardGroupUpdateOneWithoutAdBoardNestedInput",
		cameras:"CameraUpdateManyWithoutAdBoardNestedInput",
		publishedVersion:"AdBoardUpdateOneWithoutDraftAdboardNestedInput",
		draftAdboard:"AdBoardUpdateOneWithoutPublishedVersionNestedInput",
		history:"AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput",
		archivedBy:"UserUpdateOneWithoutArchivedAdboardsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"
	},
	CameraUpdateManyWithoutAdBoardNestedInput:{
		create:"CameraCreateWithoutAdBoardInput",
		connectOrCreate:"CameraCreateOrConnectWithoutAdBoardInput",
		upsert:"CameraUpsertWithWhereUniqueWithoutAdBoardInput",
		createMany:"CameraCreateManyAdBoardInputEnvelope",
		set:"CameraWhereUniqueInput",
		disconnect:"CameraWhereUniqueInput",
		delete:"CameraWhereUniqueInput",
		connect:"CameraWhereUniqueInput",
		update:"CameraUpdateWithWhereUniqueWithoutAdBoardInput",
		updateMany:"CameraUpdateManyWithWhereWithoutAdBoardInput",
		deleteMany:"CameraScalarWhereInput"
	},
	CameraUpsertWithWhereUniqueWithoutAdBoardInput:{
		where:"CameraWhereUniqueInput",
		update:"CameraUpdateWithoutAdBoardInput",
		create:"CameraCreateWithoutAdBoardInput"
	},
	CameraUpdateWithoutAdBoardInput:{
		updatedAt:"DateTime",
		cameraServer:"CameraServerUpdateOneRequiredWithoutCamerasNestedInput",
		player:"PlayerUpdateOneWithoutCamerasNestedInput",
		feeds:"CameraFeedUpdateManyWithoutCameraNestedInput"
	},
	PlayerUpdateOneWithoutCamerasNestedInput:{
		create:"PlayerCreateWithoutCamerasInput",
		connectOrCreate:"PlayerCreateOrConnectWithoutCamerasInput",
		upsert:"PlayerUpsertWithoutCamerasInput",
		delete:"PlayerWhereInput",
		connect:"PlayerWhereUniqueInput",
		update:"PlayerUpdateToOneWithWhereWithoutCamerasInput"
	},
	PlayerUpsertWithoutCamerasInput:{
		update:"PlayerUpdateWithoutCamerasInput",
		create:"PlayerCreateWithoutCamerasInput",
		where:"PlayerWhereInput"
	},
	PlayerUpdateWithoutCamerasInput:{
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackDetails:"PlaybackTimeCreateInput",
		aspectRatioType:"AspectRatioType",
		map:"MapCreateInput",
		adBoardGroup:"AdBoardGroupUpdateOneWithoutPisignagePlayersNestedInput",
		workspace:"WorkspaceUpdateOneWithoutPlayersNestedInput",
		status:"PlayerUptimeStatusUpdateManyWithoutPlayerNestedInput",
		adBoards:"AdBoardUpdateManyWithoutPlayersNestedInput"
	},
	AdBoardUpdateManyWithoutPlayersNestedInput:{
		create:"AdBoardCreateWithoutPlayersInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutPlayersInput",
		upsert:"AdBoardUpsertWithWhereUniqueWithoutPlayersInput",
		set:"AdBoardWhereUniqueInput",
		disconnect:"AdBoardWhereUniqueInput",
		delete:"AdBoardWhereUniqueInput",
		connect:"AdBoardWhereUniqueInput",
		update:"AdBoardUpdateWithWhereUniqueWithoutPlayersInput",
		updateMany:"AdBoardUpdateManyWithWhereWithoutPlayersInput",
		deleteMany:"AdBoardScalarWhereInput"
	},
	AdBoardUpsertWithWhereUniqueWithoutPlayersInput:{
		where:"AdBoardWhereUniqueInput",
		update:"AdBoardUpdateWithoutPlayersInput",
		create:"AdBoardCreateWithoutPlayersInput"
	},
	AdBoardUpdateWithoutPlayersInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryUpdateOneWithoutAdBoardsNestedInput",
		locationSubCategory:"LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput",
		type:"AdBoardTypeUpdateOneWithoutAdBoardsNestedInput",
		category:"BusinessCategoryUpdateOneWithoutAdBoardsNestedInput",
		restrictedBusines:"BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput",
		bookings:"BookingUpdateManyWithoutAdBoardNestedInput",
		orders:"OrderUpdateManyWithoutAdBoardNestedInput",
		slotConfigurations:"SlotConfigurationUpdateManyWithoutAdBoardNestedInput",
		offlineBookingsContact:"UserUpdateOneWithoutOfflineBookingAdboardsNestedInput",
		createdBy:"UserUpdateOneWithoutAdBoardsNestedInput",
		workspace:"WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput",
		adBoardGroup:"AdBoardGroupUpdateOneWithoutAdBoardNestedInput",
		cameras:"CameraUpdateManyWithoutAdBoardNestedInput",
		publishedVersion:"AdBoardUpdateOneWithoutDraftAdboardNestedInput",
		draftAdboard:"AdBoardUpdateOneWithoutPublishedVersionNestedInput",
		history:"AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput",
		archivedBy:"UserUpdateOneWithoutArchivedAdboardsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"
	},
	AdBoardUpdateOneWithoutDraftAdboardNestedInput:{
		create:"AdBoardCreateWithoutDraftAdboardInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutDraftAdboardInput",
		upsert:"AdBoardUpsertWithoutDraftAdboardInput",
		delete:"AdBoardWhereInput",
		connect:"AdBoardWhereUniqueInput",
		update:"AdBoardUpdateToOneWithWhereWithoutDraftAdboardInput"
	},
	AdBoardUpsertWithoutDraftAdboardInput:{
		update:"AdBoardUpdateWithoutDraftAdboardInput",
		create:"AdBoardCreateWithoutDraftAdboardInput",
		where:"AdBoardWhereInput"
	},
	AdBoardUpdateWithoutDraftAdboardInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryUpdateOneWithoutAdBoardsNestedInput",
		locationSubCategory:"LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput",
		type:"AdBoardTypeUpdateOneWithoutAdBoardsNestedInput",
		category:"BusinessCategoryUpdateOneWithoutAdBoardsNestedInput",
		restrictedBusines:"BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput",
		bookings:"BookingUpdateManyWithoutAdBoardNestedInput",
		orders:"OrderUpdateManyWithoutAdBoardNestedInput",
		slotConfigurations:"SlotConfigurationUpdateManyWithoutAdBoardNestedInput",
		offlineBookingsContact:"UserUpdateOneWithoutOfflineBookingAdboardsNestedInput",
		createdBy:"UserUpdateOneWithoutAdBoardsNestedInput",
		workspace:"WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput",
		players:"PlayerUpdateManyWithoutAdBoardsNestedInput",
		adBoardGroup:"AdBoardGroupUpdateOneWithoutAdBoardNestedInput",
		cameras:"CameraUpdateManyWithoutAdBoardNestedInput",
		publishedVersion:"AdBoardUpdateOneWithoutDraftAdboardNestedInput",
		history:"AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput",
		archivedBy:"UserUpdateOneWithoutArchivedAdboardsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"
	},
	AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput:{
		create:"AdBoardPublishHistoryCreateWithoutAdBoardInput",
		connectOrCreate:"AdBoardPublishHistoryCreateOrConnectWithoutAdBoardInput",
		upsert:"AdBoardPublishHistoryUpsertWithWhereUniqueWithoutAdBoardInput",
		createMany:"AdBoardPublishHistoryCreateManyAdBoardInputEnvelope",
		set:"AdBoardPublishHistoryWhereUniqueInput",
		disconnect:"AdBoardPublishHistoryWhereUniqueInput",
		delete:"AdBoardPublishHistoryWhereUniqueInput",
		connect:"AdBoardPublishHistoryWhereUniqueInput",
		update:"AdBoardPublishHistoryUpdateWithWhereUniqueWithoutAdBoardInput",
		updateMany:"AdBoardPublishHistoryUpdateManyWithWhereWithoutAdBoardInput",
		deleteMany:"AdBoardPublishHistoryScalarWhereInput"
	},
	AdBoardPublishHistoryUpsertWithWhereUniqueWithoutAdBoardInput:{
		where:"AdBoardPublishHistoryWhereUniqueInput",
		update:"AdBoardPublishHistoryUpdateWithoutAdBoardInput",
		create:"AdBoardPublishHistoryCreateWithoutAdBoardInput"
	},
	AdBoardPublishHistoryUpdateWithoutAdBoardInput:{
		publishedAt:"DateTime",
		changesSnapshot:"JSON",
		publishedBy:"UserUpdateOneRequiredWithoutPublishAdboardVersionNestedInput"
	},
	UserUpdateOneRequiredWithoutPublishAdboardVersionNestedInput:{
		create:"UserCreateWithoutPublishAdboardVersionInput",
		connectOrCreate:"UserCreateOrConnectWithoutPublishAdboardVersionInput",
		upsert:"UserUpsertWithoutPublishAdboardVersionInput",
		connect:"UserWhereUniqueInput",
		update:"UserUpdateToOneWithWhereWithoutPublishAdboardVersionInput"
	},
	UserUpsertWithoutPublishAdboardVersionInput:{
		update:"UserUpdateWithoutPublishAdboardVersionInput",
		create:"UserCreateWithoutPublishAdboardVersionInput",
		where:"UserWhereInput"
	},
	UserUpdateWithoutPublishAdboardVersionInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingUpdateManyWithoutUserNestedInput",
		createdFiles:"FileUpdateManyWithoutCreatedByNestedInput",
		updatedFiles:"FileUpdateManyWithoutUpdatedByNestedInput",
		orders:"OrderUpdateManyWithoutCreatedByNestedInput",
		changelogs:"ChangelogUpdateManyWithoutUserNestedInput",
		adBoards:"AdBoardUpdateManyWithoutCreatedByNestedInput",
		audits:"AuditUpdateManyWithoutUserNestedInput",
		campaigns:"CampaignUpdateManyWithoutUserNestedInput",
		sharedBookings:"BookingUpdateManyWithoutSharedUsersNestedInput",
		memberships:"MembershipUpdateManyWithoutUserNestedInput",
		notifications:"NotificationUpdateManyWithoutRecipientNestedInput",
		offlineBookingAdboards:"AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutUserNestedInput",
		archivedAdboards:"AdBoardUpdateManyWithoutArchivedByNestedInput",
		ownedWorkspaces:"WorkspaceUpdateManyWithoutOwnerNestedInput",
		leadWorspaces:"WorkspaceUpdateManyWithoutLeadsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutUserNestedInput",
		userPositions:"UserPositionUpdateManyWithoutUserNestedInput"
	},
	AdBoardReviewCommentUpdateManyWithoutUserNestedInput:{
		create:"AdBoardReviewCommentCreateWithoutUserInput",
		connectOrCreate:"AdBoardReviewCommentCreateOrConnectWithoutUserInput",
		upsert:"AdBoardReviewCommentUpsertWithWhereUniqueWithoutUserInput",
		createMany:"AdBoardReviewCommentCreateManyUserInputEnvelope",
		set:"AdBoardReviewCommentWhereUniqueInput",
		disconnect:"AdBoardReviewCommentWhereUniqueInput",
		delete:"AdBoardReviewCommentWhereUniqueInput",
		connect:"AdBoardReviewCommentWhereUniqueInput",
		update:"AdBoardReviewCommentUpdateWithWhereUniqueWithoutUserInput",
		updateMany:"AdBoardReviewCommentUpdateManyWithWhereWithoutUserInput",
		deleteMany:"AdBoardReviewCommentScalarWhereInput"
	},
	AdBoardReviewCommentUpsertWithWhereUniqueWithoutUserInput:{
		where:"AdBoardReviewCommentWhereUniqueInput",
		update:"AdBoardReviewCommentUpdateWithoutUserInput",
		create:"AdBoardReviewCommentCreateWithoutUserInput"
	},
	AdBoardReviewCommentUpdateWithoutUserInput:{
		action:"ReviewAction",
		createdAt:"DateTime",
		adBoard:"AdBoardUpdateOneRequiredWithoutReviewCommentsNestedInput"
	},
	AdBoardUpdateOneRequiredWithoutReviewCommentsNestedInput:{
		create:"AdBoardCreateWithoutReviewCommentsInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutReviewCommentsInput",
		upsert:"AdBoardUpsertWithoutReviewCommentsInput",
		connect:"AdBoardWhereUniqueInput",
		update:"AdBoardUpdateToOneWithWhereWithoutReviewCommentsInput"
	},
	AdBoardUpsertWithoutReviewCommentsInput:{
		update:"AdBoardUpdateWithoutReviewCommentsInput",
		create:"AdBoardCreateWithoutReviewCommentsInput",
		where:"AdBoardWhereInput"
	},
	AdBoardUpdateWithoutReviewCommentsInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryUpdateOneWithoutAdBoardsNestedInput",
		locationSubCategory:"LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput",
		type:"AdBoardTypeUpdateOneWithoutAdBoardsNestedInput",
		category:"BusinessCategoryUpdateOneWithoutAdBoardsNestedInput",
		restrictedBusines:"BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput",
		bookings:"BookingUpdateManyWithoutAdBoardNestedInput",
		orders:"OrderUpdateManyWithoutAdBoardNestedInput",
		slotConfigurations:"SlotConfigurationUpdateManyWithoutAdBoardNestedInput",
		offlineBookingsContact:"UserUpdateOneWithoutOfflineBookingAdboardsNestedInput",
		createdBy:"UserUpdateOneWithoutAdBoardsNestedInput",
		workspace:"WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput",
		players:"PlayerUpdateManyWithoutAdBoardsNestedInput",
		adBoardGroup:"AdBoardGroupUpdateOneWithoutAdBoardNestedInput",
		cameras:"CameraUpdateManyWithoutAdBoardNestedInput",
		publishedVersion:"AdBoardUpdateOneWithoutDraftAdboardNestedInput",
		draftAdboard:"AdBoardUpdateOneWithoutPublishedVersionNestedInput",
		history:"AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput",
		archivedBy:"UserUpdateOneWithoutArchivedAdboardsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"
	},
	AdBoardUpdateOneWithoutPublishedVersionNestedInput:{
		create:"AdBoardCreateWithoutPublishedVersionInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutPublishedVersionInput",
		upsert:"AdBoardUpsertWithoutPublishedVersionInput",
		disconnect:"AdBoardWhereInput",
		delete:"AdBoardWhereInput",
		connect:"AdBoardWhereUniqueInput",
		update:"AdBoardUpdateToOneWithWhereWithoutPublishedVersionInput"
	},
	AdBoardUpsertWithoutPublishedVersionInput:{
		update:"AdBoardUpdateWithoutPublishedVersionInput",
		create:"AdBoardCreateWithoutPublishedVersionInput",
		where:"AdBoardWhereInput"
	},
	AdBoardUpdateWithoutPublishedVersionInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryUpdateOneWithoutAdBoardsNestedInput",
		locationSubCategory:"LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput",
		type:"AdBoardTypeUpdateOneWithoutAdBoardsNestedInput",
		category:"BusinessCategoryUpdateOneWithoutAdBoardsNestedInput",
		restrictedBusines:"BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput",
		bookings:"BookingUpdateManyWithoutAdBoardNestedInput",
		orders:"OrderUpdateManyWithoutAdBoardNestedInput",
		slotConfigurations:"SlotConfigurationUpdateManyWithoutAdBoardNestedInput",
		offlineBookingsContact:"UserUpdateOneWithoutOfflineBookingAdboardsNestedInput",
		createdBy:"UserUpdateOneWithoutAdBoardsNestedInput",
		workspace:"WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput",
		players:"PlayerUpdateManyWithoutAdBoardsNestedInput",
		adBoardGroup:"AdBoardGroupUpdateOneWithoutAdBoardNestedInput",
		cameras:"CameraUpdateManyWithoutAdBoardNestedInput",
		draftAdboard:"AdBoardUpdateOneWithoutPublishedVersionNestedInput",
		history:"AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput",
		archivedBy:"UserUpdateOneWithoutArchivedAdboardsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"
	},
	AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput:{
		create:"AdBoardReviewCommentCreateWithoutAdBoardInput",
		connectOrCreate:"AdBoardReviewCommentCreateOrConnectWithoutAdBoardInput",
		upsert:"AdBoardReviewCommentUpsertWithWhereUniqueWithoutAdBoardInput",
		createMany:"AdBoardReviewCommentCreateManyAdBoardInputEnvelope",
		set:"AdBoardReviewCommentWhereUniqueInput",
		disconnect:"AdBoardReviewCommentWhereUniqueInput",
		delete:"AdBoardReviewCommentWhereUniqueInput",
		connect:"AdBoardReviewCommentWhereUniqueInput",
		update:"AdBoardReviewCommentUpdateWithWhereUniqueWithoutAdBoardInput",
		updateMany:"AdBoardReviewCommentUpdateManyWithWhereWithoutAdBoardInput",
		deleteMany:"AdBoardReviewCommentScalarWhereInput"
	},
	AdBoardReviewCommentUpsertWithWhereUniqueWithoutAdBoardInput:{
		where:"AdBoardReviewCommentWhereUniqueInput",
		update:"AdBoardReviewCommentUpdateWithoutAdBoardInput",
		create:"AdBoardReviewCommentCreateWithoutAdBoardInput"
	},
	AdBoardReviewCommentUpdateWithoutAdBoardInput:{
		action:"ReviewAction",
		createdAt:"DateTime",
		user:"UserUpdateOneRequiredWithoutReviewCommentsNestedInput"
	},
	UserUpdateOneRequiredWithoutReviewCommentsNestedInput:{
		create:"UserCreateWithoutReviewCommentsInput",
		connectOrCreate:"UserCreateOrConnectWithoutReviewCommentsInput",
		upsert:"UserUpsertWithoutReviewCommentsInput",
		connect:"UserWhereUniqueInput",
		update:"UserUpdateToOneWithWhereWithoutReviewCommentsInput"
	},
	UserUpsertWithoutReviewCommentsInput:{
		update:"UserUpdateWithoutReviewCommentsInput",
		create:"UserCreateWithoutReviewCommentsInput",
		where:"UserWhereInput"
	},
	UserUpdateWithoutReviewCommentsInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingUpdateManyWithoutUserNestedInput",
		createdFiles:"FileUpdateManyWithoutCreatedByNestedInput",
		updatedFiles:"FileUpdateManyWithoutUpdatedByNestedInput",
		orders:"OrderUpdateManyWithoutCreatedByNestedInput",
		changelogs:"ChangelogUpdateManyWithoutUserNestedInput",
		adBoards:"AdBoardUpdateManyWithoutCreatedByNestedInput",
		audits:"AuditUpdateManyWithoutUserNestedInput",
		campaigns:"CampaignUpdateManyWithoutUserNestedInput",
		sharedBookings:"BookingUpdateManyWithoutSharedUsersNestedInput",
		memberships:"MembershipUpdateManyWithoutUserNestedInput",
		notifications:"NotificationUpdateManyWithoutRecipientNestedInput",
		publishAdboardVersion:"AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput",
		offlineBookingAdboards:"AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput",
		archivedAdboards:"AdBoardUpdateManyWithoutArchivedByNestedInput",
		ownedWorkspaces:"WorkspaceUpdateManyWithoutOwnerNestedInput",
		leadWorspaces:"WorkspaceUpdateManyWithoutLeadsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutUserNestedInput",
		userPositions:"UserPositionUpdateManyWithoutUserNestedInput"
	},
	AdBoardUpdateManyWithoutArchivedByNestedInput:{
		create:"AdBoardCreateWithoutArchivedByInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutArchivedByInput",
		upsert:"AdBoardUpsertWithWhereUniqueWithoutArchivedByInput",
		createMany:"AdBoardCreateManyArchivedByInputEnvelope",
		set:"AdBoardWhereUniqueInput",
		disconnect:"AdBoardWhereUniqueInput",
		delete:"AdBoardWhereUniqueInput",
		connect:"AdBoardWhereUniqueInput",
		update:"AdBoardUpdateWithWhereUniqueWithoutArchivedByInput",
		updateMany:"AdBoardUpdateManyWithWhereWithoutArchivedByInput",
		deleteMany:"AdBoardScalarWhereInput"
	},
	AdBoardUpsertWithWhereUniqueWithoutArchivedByInput:{
		where:"AdBoardWhereUniqueInput",
		update:"AdBoardUpdateWithoutArchivedByInput",
		create:"AdBoardCreateWithoutArchivedByInput"
	},
	AdBoardUpdateWithoutArchivedByInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryUpdateOneWithoutAdBoardsNestedInput",
		locationSubCategory:"LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput",
		type:"AdBoardTypeUpdateOneWithoutAdBoardsNestedInput",
		category:"BusinessCategoryUpdateOneWithoutAdBoardsNestedInput",
		restrictedBusines:"BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput",
		bookings:"BookingUpdateManyWithoutAdBoardNestedInput",
		orders:"OrderUpdateManyWithoutAdBoardNestedInput",
		slotConfigurations:"SlotConfigurationUpdateManyWithoutAdBoardNestedInput",
		offlineBookingsContact:"UserUpdateOneWithoutOfflineBookingAdboardsNestedInput",
		createdBy:"UserUpdateOneWithoutAdBoardsNestedInput",
		workspace:"WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput",
		players:"PlayerUpdateManyWithoutAdBoardsNestedInput",
		adBoardGroup:"AdBoardGroupUpdateOneWithoutAdBoardNestedInput",
		cameras:"CameraUpdateManyWithoutAdBoardNestedInput",
		publishedVersion:"AdBoardUpdateOneWithoutDraftAdboardNestedInput",
		draftAdboard:"AdBoardUpdateOneWithoutPublishedVersionNestedInput",
		history:"AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"
	},
	AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput:{
		create:"AdBoardPublicReviewCreateWithoutAdBoardInput",
		connectOrCreate:"AdBoardPublicReviewCreateOrConnectWithoutAdBoardInput",
		upsert:"AdBoardPublicReviewUpsertWithWhereUniqueWithoutAdBoardInput",
		createMany:"AdBoardPublicReviewCreateManyAdBoardInputEnvelope",
		set:"AdBoardPublicReviewWhereUniqueInput",
		disconnect:"AdBoardPublicReviewWhereUniqueInput",
		delete:"AdBoardPublicReviewWhereUniqueInput",
		connect:"AdBoardPublicReviewWhereUniqueInput",
		update:"AdBoardPublicReviewUpdateWithWhereUniqueWithoutAdBoardInput",
		updateMany:"AdBoardPublicReviewUpdateManyWithWhereWithoutAdBoardInput",
		deleteMany:"AdBoardPublicReviewScalarWhereInput"
	},
	AdBoardPublicReviewUpsertWithWhereUniqueWithoutAdBoardInput:{
		where:"AdBoardPublicReviewWhereUniqueInput",
		update:"AdBoardPublicReviewUpdateWithoutAdBoardInput",
		create:"AdBoardPublicReviewCreateWithoutAdBoardInput"
	},
	AdBoardPublicReviewUpdateWithoutAdBoardInput:{
		createdAt:"DateTime",
		user:"UserUpdateOneRequiredWithoutPublicReviewsNestedInput"
	},
	UserUpdateOneRequiredWithoutPublicReviewsNestedInput:{
		create:"UserCreateWithoutPublicReviewsInput",
		connectOrCreate:"UserCreateOrConnectWithoutPublicReviewsInput",
		upsert:"UserUpsertWithoutPublicReviewsInput",
		connect:"UserWhereUniqueInput",
		update:"UserUpdateToOneWithWhereWithoutPublicReviewsInput"
	},
	UserUpsertWithoutPublicReviewsInput:{
		update:"UserUpdateWithoutPublicReviewsInput",
		create:"UserCreateWithoutPublicReviewsInput",
		where:"UserWhereInput"
	},
	UserUpdateWithoutPublicReviewsInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingUpdateManyWithoutUserNestedInput",
		createdFiles:"FileUpdateManyWithoutCreatedByNestedInput",
		updatedFiles:"FileUpdateManyWithoutUpdatedByNestedInput",
		orders:"OrderUpdateManyWithoutCreatedByNestedInput",
		changelogs:"ChangelogUpdateManyWithoutUserNestedInput",
		adBoards:"AdBoardUpdateManyWithoutCreatedByNestedInput",
		audits:"AuditUpdateManyWithoutUserNestedInput",
		campaigns:"CampaignUpdateManyWithoutUserNestedInput",
		sharedBookings:"BookingUpdateManyWithoutSharedUsersNestedInput",
		memberships:"MembershipUpdateManyWithoutUserNestedInput",
		notifications:"NotificationUpdateManyWithoutRecipientNestedInput",
		publishAdboardVersion:"AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput",
		offlineBookingAdboards:"AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutUserNestedInput",
		archivedAdboards:"AdBoardUpdateManyWithoutArchivedByNestedInput",
		ownedWorkspaces:"WorkspaceUpdateManyWithoutOwnerNestedInput",
		leadWorspaces:"WorkspaceUpdateManyWithoutLeadsNestedInput",
		userPositions:"UserPositionUpdateManyWithoutUserNestedInput"
	},
	WorkspaceUpdateManyWithoutOwnerNestedInput:{
		create:"WorkspaceCreateWithoutOwnerInput",
		connectOrCreate:"WorkspaceCreateOrConnectWithoutOwnerInput",
		upsert:"WorkspaceUpsertWithWhereUniqueWithoutOwnerInput",
		createMany:"WorkspaceCreateManyOwnerInputEnvelope",
		set:"WorkspaceWhereUniqueInput",
		disconnect:"WorkspaceWhereUniqueInput",
		delete:"WorkspaceWhereUniqueInput",
		connect:"WorkspaceWhereUniqueInput",
		update:"WorkspaceUpdateWithWhereUniqueWithoutOwnerInput",
		updateMany:"WorkspaceUpdateManyWithWhereWithoutOwnerInput",
		deleteMany:"WorkspaceScalarWhereInput"
	},
	WorkspaceUpsertWithWhereUniqueWithoutOwnerInput:{
		where:"WorkspaceWhereUniqueInput",
		update:"WorkspaceUpdateWithoutOwnerInput",
		create:"WorkspaceCreateWithoutOwnerInput"
	},
	WorkspaceUpdateWithoutOwnerInput:{
		type:"WorkspaceType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		memberships:"MembershipUpdateManyWithoutWorkspaceNestedInput",
		adBoards:"AdBoardUpdateManyWithoutWorkspaceNestedInput",
		playlists:"PlaylistUpdateManyWithoutWorkspaceNestedInput",
		adBoardGroups:"AdBoardGroupUpdateManyWithoutWorkspaceNestedInput",
		audits:"AuditUpdateManyWithoutWorkspaceNestedInput",
		bookings:"BookingUpdateManyWithoutWorkspaceNestedInput",
		files:"FileUpdateManyWithoutWorkspaceNestedInput",
		players:"PlayerUpdateManyWithoutWorkspaceNestedInput",
		transactions:"TransactionUpdateManyWithoutWorkspaceNestedInput",
		activeSubscription:"SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput",
		cameraServers:"CameraServerUpdateManyWithoutWorkspaceNestedInput",
		workspaceRoles:"WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput",
		webhookLogs:"WebhookLogUpdateManyWithoutWorkspaceNestedInput",
		leads:"UserUpdateManyWithoutLeadWorspacesNestedInput"
	},
	SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput:{
		create:"SubscriptionCreateWithoutActiveWorkspaceInput",
		connectOrCreate:"SubscriptionCreateOrConnectWithoutActiveWorkspaceInput",
		upsert:"SubscriptionUpsertWithoutActiveWorkspaceInput",
		disconnect:"SubscriptionWhereInput",
		delete:"SubscriptionWhereInput",
		connect:"SubscriptionWhereUniqueInput",
		update:"SubscriptionUpdateToOneWithWhereWithoutActiveWorkspaceInput"
	},
	SubscriptionUpsertWithoutActiveWorkspaceInput:{
		update:"SubscriptionUpdateWithoutActiveWorkspaceInput",
		create:"SubscriptionCreateWithoutActiveWorkspaceInput",
		where:"SubscriptionWhereInput"
	},
	SubscriptionUpdateWithoutActiveWorkspaceInput:{
		startDate:"DateTime",
		endDate:"DateTime",
		cancellationDetails:"CancellationDetailsCreateInput"
	},
	SubscriptionUpdateToOneWithWhereWithoutActiveWorkspaceInput:{
		where:"SubscriptionWhereInput",
		data:"SubscriptionUpdateWithoutActiveWorkspaceInput"
	},
	CameraServerUpdateManyWithoutWorkspaceNestedInput:{
		create:"CameraServerCreateWithoutWorkspaceInput",
		connectOrCreate:"CameraServerCreateOrConnectWithoutWorkspaceInput",
		upsert:"CameraServerUpsertWithWhereUniqueWithoutWorkspaceInput",
		createMany:"CameraServerCreateManyWorkspaceInputEnvelope",
		set:"CameraServerWhereUniqueInput",
		disconnect:"CameraServerWhereUniqueInput",
		delete:"CameraServerWhereUniqueInput",
		connect:"CameraServerWhereUniqueInput",
		update:"CameraServerUpdateWithWhereUniqueWithoutWorkspaceInput",
		updateMany:"CameraServerUpdateManyWithWhereWithoutWorkspaceInput",
		deleteMany:"CameraServerScalarWhereInput"
	},
	CameraServerUpsertWithWhereUniqueWithoutWorkspaceInput:{
		where:"CameraServerWhereUniqueInput",
		update:"CameraServerUpdateWithoutWorkspaceInput",
		create:"CameraServerCreateWithoutWorkspaceInput"
	},
	CameraServerUpdateWithoutWorkspaceInput:{
		cameras:"CameraUpdateManyWithoutCameraServerNestedInput",
		feeds:"CameraFeedUpdateManyWithoutCameraServerNestedInput"
	},
	CameraUpdateManyWithoutCameraServerNestedInput:{
		create:"CameraCreateWithoutCameraServerInput",
		connectOrCreate:"CameraCreateOrConnectWithoutCameraServerInput",
		upsert:"CameraUpsertWithWhereUniqueWithoutCameraServerInput",
		createMany:"CameraCreateManyCameraServerInputEnvelope",
		set:"CameraWhereUniqueInput",
		disconnect:"CameraWhereUniqueInput",
		delete:"CameraWhereUniqueInput",
		connect:"CameraWhereUniqueInput",
		update:"CameraUpdateWithWhereUniqueWithoutCameraServerInput",
		updateMany:"CameraUpdateManyWithWhereWithoutCameraServerInput",
		deleteMany:"CameraScalarWhereInput"
	},
	CameraUpsertWithWhereUniqueWithoutCameraServerInput:{
		where:"CameraWhereUniqueInput",
		update:"CameraUpdateWithoutCameraServerInput",
		create:"CameraCreateWithoutCameraServerInput"
	},
	CameraUpdateWithoutCameraServerInput:{
		updatedAt:"DateTime",
		player:"PlayerUpdateOneWithoutCamerasNestedInput",
		feeds:"CameraFeedUpdateManyWithoutCameraNestedInput",
		adBoard:"AdBoardUpdateOneWithoutCamerasNestedInput"
	},
	CameraFeedUpdateManyWithoutCameraNestedInput:{
		create:"CameraFeedCreateWithoutCameraInput",
		connectOrCreate:"CameraFeedCreateOrConnectWithoutCameraInput",
		upsert:"CameraFeedUpsertWithWhereUniqueWithoutCameraInput",
		createMany:"CameraFeedCreateManyCameraInputEnvelope",
		set:"CameraFeedWhereUniqueInput",
		disconnect:"CameraFeedWhereUniqueInput",
		delete:"CameraFeedWhereUniqueInput",
		connect:"CameraFeedWhereUniqueInput",
		update:"CameraFeedUpdateWithWhereUniqueWithoutCameraInput",
		updateMany:"CameraFeedUpdateManyWithWhereWithoutCameraInput",
		deleteMany:"CameraFeedScalarWhereInput"
	},
	CameraFeedUpsertWithWhereUniqueWithoutCameraInput:{
		where:"CameraFeedWhereUniqueInput",
		update:"CameraFeedUpdateWithoutCameraInput",
		create:"CameraFeedCreateWithoutCameraInput"
	},
	CameraFeedUpdateWithoutCameraInput:{
		timestamp:"DateTime",
		data:"CameraFeedDataCreateInput",
		cameraServer:"CameraServerUpdateOneRequiredWithoutFeedsNestedInput"
	},
	CameraServerUpdateOneRequiredWithoutFeedsNestedInput:{
		create:"CameraServerCreateWithoutFeedsInput",
		connectOrCreate:"CameraServerCreateOrConnectWithoutFeedsInput",
		upsert:"CameraServerUpsertWithoutFeedsInput",
		connect:"CameraServerWhereUniqueInput",
		update:"CameraServerUpdateToOneWithWhereWithoutFeedsInput"
	},
	CameraServerUpsertWithoutFeedsInput:{
		update:"CameraServerUpdateWithoutFeedsInput",
		create:"CameraServerCreateWithoutFeedsInput",
		where:"CameraServerWhereInput"
	},
	CameraServerUpdateWithoutFeedsInput:{
		workspace:"WorkspaceUpdateOneWithoutCameraServersNestedInput",
		cameras:"CameraUpdateManyWithoutCameraServerNestedInput"
	},
	CameraServerUpdateToOneWithWhereWithoutFeedsInput:{
		where:"CameraServerWhereInput",
		data:"CameraServerUpdateWithoutFeedsInput"
	},
	CameraFeedUpdateWithWhereUniqueWithoutCameraInput:{
		where:"CameraFeedWhereUniqueInput",
		data:"CameraFeedUpdateWithoutCameraInput"
	},
	CameraFeedUpdateManyWithWhereWithoutCameraInput:{
		where:"CameraFeedScalarWhereInput",
		data:"CameraFeedUpdateManyMutationInput"
	},
	CameraFeedScalarWhereInput:{
		AND:"CameraFeedScalarWhereInput",
		OR:"CameraFeedScalarWhereInput",
		NOT:"CameraFeedScalarWhereInput",
		id:"StringFilter",
		cameraId:"StringFilter",
		timestamp:"DateTimeFilter",
		cameraServerId:"StringFilter"
	},
	CameraFeedUpdateManyMutationInput:{
		timestamp:"DateTime",
		data:"CameraFeedDataCreateInput"
	},
	AdBoardUpdateOneWithoutCamerasNestedInput:{
		create:"AdBoardCreateWithoutCamerasInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutCamerasInput",
		upsert:"AdBoardUpsertWithoutCamerasInput",
		delete:"AdBoardWhereInput",
		connect:"AdBoardWhereUniqueInput",
		update:"AdBoardUpdateToOneWithWhereWithoutCamerasInput"
	},
	AdBoardUpsertWithoutCamerasInput:{
		update:"AdBoardUpdateWithoutCamerasInput",
		create:"AdBoardCreateWithoutCamerasInput",
		where:"AdBoardWhereInput"
	},
	AdBoardUpdateWithoutCamerasInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryUpdateOneWithoutAdBoardsNestedInput",
		locationSubCategory:"LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput",
		type:"AdBoardTypeUpdateOneWithoutAdBoardsNestedInput",
		category:"BusinessCategoryUpdateOneWithoutAdBoardsNestedInput",
		restrictedBusines:"BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput",
		bookings:"BookingUpdateManyWithoutAdBoardNestedInput",
		orders:"OrderUpdateManyWithoutAdBoardNestedInput",
		slotConfigurations:"SlotConfigurationUpdateManyWithoutAdBoardNestedInput",
		offlineBookingsContact:"UserUpdateOneWithoutOfflineBookingAdboardsNestedInput",
		createdBy:"UserUpdateOneWithoutAdBoardsNestedInput",
		workspace:"WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput",
		players:"PlayerUpdateManyWithoutAdBoardsNestedInput",
		adBoardGroup:"AdBoardGroupUpdateOneWithoutAdBoardNestedInput",
		publishedVersion:"AdBoardUpdateOneWithoutDraftAdboardNestedInput",
		draftAdboard:"AdBoardUpdateOneWithoutPublishedVersionNestedInput",
		history:"AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput",
		archivedBy:"UserUpdateOneWithoutArchivedAdboardsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"
	},
	UserUpdateOneWithoutArchivedAdboardsNestedInput:{
		create:"UserCreateWithoutArchivedAdboardsInput",
		connectOrCreate:"UserCreateOrConnectWithoutArchivedAdboardsInput",
		upsert:"UserUpsertWithoutArchivedAdboardsInput",
		delete:"UserWhereInput",
		connect:"UserWhereUniqueInput",
		update:"UserUpdateToOneWithWhereWithoutArchivedAdboardsInput"
	},
	UserUpsertWithoutArchivedAdboardsInput:{
		update:"UserUpdateWithoutArchivedAdboardsInput",
		create:"UserCreateWithoutArchivedAdboardsInput",
		where:"UserWhereInput"
	},
	UserUpdateWithoutArchivedAdboardsInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingUpdateManyWithoutUserNestedInput",
		createdFiles:"FileUpdateManyWithoutCreatedByNestedInput",
		updatedFiles:"FileUpdateManyWithoutUpdatedByNestedInput",
		orders:"OrderUpdateManyWithoutCreatedByNestedInput",
		changelogs:"ChangelogUpdateManyWithoutUserNestedInput",
		adBoards:"AdBoardUpdateManyWithoutCreatedByNestedInput",
		audits:"AuditUpdateManyWithoutUserNestedInput",
		campaigns:"CampaignUpdateManyWithoutUserNestedInput",
		sharedBookings:"BookingUpdateManyWithoutSharedUsersNestedInput",
		memberships:"MembershipUpdateManyWithoutUserNestedInput",
		notifications:"NotificationUpdateManyWithoutRecipientNestedInput",
		publishAdboardVersion:"AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput",
		offlineBookingAdboards:"AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutUserNestedInput",
		ownedWorkspaces:"WorkspaceUpdateManyWithoutOwnerNestedInput",
		leadWorspaces:"WorkspaceUpdateManyWithoutLeadsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutUserNestedInput",
		userPositions:"UserPositionUpdateManyWithoutUserNestedInput"
	},
	WorkspaceUpdateManyWithoutLeadsNestedInput:{
		create:"WorkspaceCreateWithoutLeadsInput",
		connectOrCreate:"WorkspaceCreateOrConnectWithoutLeadsInput",
		upsert:"WorkspaceUpsertWithWhereUniqueWithoutLeadsInput",
		set:"WorkspaceWhereUniqueInput",
		disconnect:"WorkspaceWhereUniqueInput",
		delete:"WorkspaceWhereUniqueInput",
		connect:"WorkspaceWhereUniqueInput",
		update:"WorkspaceUpdateWithWhereUniqueWithoutLeadsInput",
		updateMany:"WorkspaceUpdateManyWithWhereWithoutLeadsInput",
		deleteMany:"WorkspaceScalarWhereInput"
	},
	WorkspaceUpsertWithWhereUniqueWithoutLeadsInput:{
		where:"WorkspaceWhereUniqueInput",
		update:"WorkspaceUpdateWithoutLeadsInput",
		create:"WorkspaceCreateWithoutLeadsInput"
	},
	WorkspaceUpdateWithoutLeadsInput:{
		type:"WorkspaceType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		owner:"UserUpdateOneWithoutOwnedWorkspacesNestedInput",
		memberships:"MembershipUpdateManyWithoutWorkspaceNestedInput",
		adBoards:"AdBoardUpdateManyWithoutWorkspaceNestedInput",
		playlists:"PlaylistUpdateManyWithoutWorkspaceNestedInput",
		adBoardGroups:"AdBoardGroupUpdateManyWithoutWorkspaceNestedInput",
		audits:"AuditUpdateManyWithoutWorkspaceNestedInput",
		bookings:"BookingUpdateManyWithoutWorkspaceNestedInput",
		files:"FileUpdateManyWithoutWorkspaceNestedInput",
		players:"PlayerUpdateManyWithoutWorkspaceNestedInput",
		transactions:"TransactionUpdateManyWithoutWorkspaceNestedInput",
		activeSubscription:"SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput",
		cameraServers:"CameraServerUpdateManyWithoutWorkspaceNestedInput",
		workspaceRoles:"WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput",
		webhookLogs:"WebhookLogUpdateManyWithoutWorkspaceNestedInput"
	},
	WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput:{
		create:"WorkspaceRoleCreateWithoutWorkspaceInput",
		connectOrCreate:"WorkspaceRoleCreateOrConnectWithoutWorkspaceInput",
		upsert:"WorkspaceRoleUpsertWithWhereUniqueWithoutWorkspaceInput",
		createMany:"WorkspaceRoleCreateManyWorkspaceInputEnvelope",
		set:"WorkspaceRoleWhereUniqueInput",
		disconnect:"WorkspaceRoleWhereUniqueInput",
		delete:"WorkspaceRoleWhereUniqueInput",
		connect:"WorkspaceRoleWhereUniqueInput",
		update:"WorkspaceRoleUpdateWithWhereUniqueWithoutWorkspaceInput",
		updateMany:"WorkspaceRoleUpdateManyWithWhereWithoutWorkspaceInput",
		deleteMany:"WorkspaceRoleScalarWhereInput"
	},
	WorkspaceRoleUpsertWithWhereUniqueWithoutWorkspaceInput:{
		where:"WorkspaceRoleWhereUniqueInput",
		update:"WorkspaceRoleUpdateWithoutWorkspaceInput",
		create:"WorkspaceRoleCreateWithoutWorkspaceInput"
	},
	WorkspaceRoleUpdateWithoutWorkspaceInput:{
		permissions:"Permission",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		memberships:"MembershipUpdateManyWithoutRoleNestedInput"
	},
	MembershipUpdateManyWithoutRoleNestedInput:{
		create:"MembershipCreateWithoutRoleInput",
		connectOrCreate:"MembershipCreateOrConnectWithoutRoleInput",
		upsert:"MembershipUpsertWithWhereUniqueWithoutRoleInput",
		createMany:"MembershipCreateManyRoleInputEnvelope",
		set:"MembershipWhereUniqueInput",
		disconnect:"MembershipWhereUniqueInput",
		delete:"MembershipWhereUniqueInput",
		connect:"MembershipWhereUniqueInput",
		update:"MembershipUpdateWithWhereUniqueWithoutRoleInput",
		updateMany:"MembershipUpdateManyWithWhereWithoutRoleInput",
		deleteMany:"MembershipScalarWhereInput"
	},
	MembershipUpsertWithWhereUniqueWithoutRoleInput:{
		where:"MembershipWhereUniqueInput",
		update:"MembershipUpdateWithoutRoleInput",
		create:"MembershipCreateWithoutRoleInput"
	},
	MembershipUpdateWithoutRoleInput:{
		createdAt:"DateTime",
		user:"UserUpdateOneRequiredWithoutMembershipsNestedInput",
		workspace:"WorkspaceUpdateOneRequiredWithoutMembershipsNestedInput"
	},
	MembershipUpdateWithWhereUniqueWithoutRoleInput:{
		where:"MembershipWhereUniqueInput",
		data:"MembershipUpdateWithoutRoleInput"
	},
	MembershipUpdateManyWithWhereWithoutRoleInput:{
		where:"MembershipScalarWhereInput",
		data:"MembershipUpdateManyMutationInput"
	},
	MembershipScalarWhereInput:{
		AND:"MembershipScalarWhereInput",
		OR:"MembershipScalarWhereInput",
		NOT:"MembershipScalarWhereInput",
		id:"StringFilter",
		createdAt:"DateTimeFilter",
		userId:"StringFilter",
		workspaceId:"StringFilter",
		roleId:"StringFilter"
	},
	MembershipUpdateManyMutationInput:{
		createdAt:"DateTime"
	},
	WorkspaceRoleUpdateWithWhereUniqueWithoutWorkspaceInput:{
		where:"WorkspaceRoleWhereUniqueInput",
		data:"WorkspaceRoleUpdateWithoutWorkspaceInput"
	},
	WorkspaceRoleUpdateManyWithWhereWithoutWorkspaceInput:{
		where:"WorkspaceRoleScalarWhereInput",
		data:"WorkspaceRoleUpdateManyMutationInput"
	},
	WorkspaceRoleScalarWhereInput:{
		AND:"WorkspaceRoleScalarWhereInput",
		OR:"WorkspaceRoleScalarWhereInput",
		NOT:"WorkspaceRoleScalarWhereInput",
		id:"StringFilter",
		name:"StringFilter",
		permissions:"EnumPermissionNullableListFilter",
		workspaceId:"StringNullableFilter",
		createdAt:"DateTimeFilter",
		updatedAt:"DateTimeFilter"
	},
	WorkspaceRoleUpdateManyMutationInput:{
		permissions:"Permission",
		createdAt:"DateTime",
		updatedAt:"DateTime"
	},
	WebhookLogUpdateManyWithoutWorkspaceNestedInput:{
		create:"WebhookLogCreateWithoutWorkspaceInput",
		connectOrCreate:"WebhookLogCreateOrConnectWithoutWorkspaceInput",
		upsert:"WebhookLogUpsertWithWhereUniqueWithoutWorkspaceInput",
		createMany:"WebhookLogCreateManyWorkspaceInputEnvelope",
		set:"WebhookLogWhereUniqueInput",
		disconnect:"WebhookLogWhereUniqueInput",
		delete:"WebhookLogWhereUniqueInput",
		connect:"WebhookLogWhereUniqueInput",
		update:"WebhookLogUpdateWithWhereUniqueWithoutWorkspaceInput",
		updateMany:"WebhookLogUpdateManyWithWhereWithoutWorkspaceInput",
		deleteMany:"WebhookLogScalarWhereInput"
	},
	WebhookLogUpsertWithWhereUniqueWithoutWorkspaceInput:{
		where:"WebhookLogWhereUniqueInput",
		update:"WebhookLogUpdateWithoutWorkspaceInput",
		create:"WebhookLogCreateWithoutWorkspaceInput"
	},
	WebhookLogUpdateWithoutWorkspaceInput:{
		response:"JSON",
		request:"JSON",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		booking:"BookingUpdateOneRequiredWithoutWebhookLogsNestedInput"
	},
	BookingUpdateOneRequiredWithoutWebhookLogsNestedInput:{
		create:"BookingCreateWithoutWebhookLogsInput",
		connectOrCreate:"BookingCreateOrConnectWithoutWebhookLogsInput",
		upsert:"BookingUpsertWithoutWebhookLogsInput",
		connect:"BookingWhereUniqueInput",
		update:"BookingUpdateToOneWithWhereWithoutWebhookLogsInput"
	},
	BookingUpsertWithoutWebhookLogsInput:{
		update:"BookingUpdateWithoutWebhookLogsInput",
		create:"BookingCreateWithoutWebhookLogsInput",
		where:"BookingWhereInput"
	},
	BookingUpdateWithoutWebhookLogsInput:{
		bookingDate:"DateTime",
		bookingDetails:"BookingDetailsCreateInput",
		bookingStatus:"BookingStatus",
		paymentStatus:"PaymentStatus",
		deploymentStatus:"DeploymentStatus",
		contentApprovalStatus:"ContentApprovalStatus",
		selectedDates:"OrderSelectedDateCreateInput",
		payment:"BookingPaymentCreateInput",
		priceSummary:"PriceSummaryCreateInput",
		pisignage:"BookingPisignageDataCreateInput",
		refund:"BookingRefundCreateInput",
		assets:"FileUpdateManyWithoutBookingsNestedInput",
		businessCategory:"BusinessCategoryUpdateOneWithoutBookingsNestedInput",
		proofs:"FileUpdateManyWithoutProofBookingNestedInput",
		user:"UserUpdateOneRequiredWithoutBookingsNestedInput",
		adBoard:"AdBoardUpdateOneRequiredWithoutBookingsNestedInput",
		changelogs:"ChangelogUpdateManyWithoutBookingNestedInput",
		workspace:"WorkspaceUpdateOneWithoutBookingsNestedInput",
		campaign:"CampaignUpdateOneWithoutBookingsNestedInput",
		payouts:"PayoutUpdateManyWithoutBookingNestedInput",
		sharedUsers:"UserUpdateManyWithoutSharedBookingsNestedInput",
		slotUsage:"SlotUsageUpdateManyWithoutBookingNestedInput"
	},
	SlotUsageUpdateManyWithoutBookingNestedInput:{
		create:"SlotUsageCreateWithoutBookingInput",
		connectOrCreate:"SlotUsageCreateOrConnectWithoutBookingInput",
		upsert:"SlotUsageUpsertWithWhereUniqueWithoutBookingInput",
		createMany:"SlotUsageCreateManyBookingInputEnvelope",
		set:"SlotUsageWhereUniqueInput",
		disconnect:"SlotUsageWhereUniqueInput",
		delete:"SlotUsageWhereUniqueInput",
		connect:"SlotUsageWhereUniqueInput",
		update:"SlotUsageUpdateWithWhereUniqueWithoutBookingInput",
		updateMany:"SlotUsageUpdateManyWithWhereWithoutBookingInput",
		deleteMany:"SlotUsageScalarWhereInput"
	},
	SlotUsageUpsertWithWhereUniqueWithoutBookingInput:{
		where:"SlotUsageWhereUniqueInput",
		update:"SlotUsageUpdateWithoutBookingInput",
		create:"SlotUsageCreateWithoutBookingInput"
	},
	SlotUsageUpdateWithoutBookingInput:{
		date:"DateTime"
	},
	SlotUsageUpdateWithWhereUniqueWithoutBookingInput:{
		where:"SlotUsageWhereUniqueInput",
		data:"SlotUsageUpdateWithoutBookingInput"
	},
	SlotUsageUpdateManyWithWhereWithoutBookingInput:{
		where:"SlotUsageScalarWhereInput",
		data:"SlotUsageUpdateManyMutationInput"
	},
	SlotUsageScalarWhereInput:{
		AND:"SlotUsageScalarWhereInput",
		OR:"SlotUsageScalarWhereInput",
		NOT:"SlotUsageScalarWhereInput",
		id:"StringFilter",
		bookingId:"StringFilter",
		date:"DateTimeFilter",
		slotUsed:"IntFilter"
	},
	SlotUsageUpdateManyMutationInput:{
		date:"DateTime"
	},
	BookingUpdateToOneWithWhereWithoutWebhookLogsInput:{
		where:"BookingWhereInput",
		data:"BookingUpdateWithoutWebhookLogsInput"
	},
	WebhookLogUpdateWithWhereUniqueWithoutWorkspaceInput:{
		where:"WebhookLogWhereUniqueInput",
		data:"WebhookLogUpdateWithoutWorkspaceInput"
	},
	WebhookLogUpdateManyWithWhereWithoutWorkspaceInput:{
		where:"WebhookLogScalarWhereInput",
		data:"WebhookLogUpdateManyMutationInput"
	},
	WebhookLogScalarWhereInput:{
		AND:"WebhookLogScalarWhereInput",
		OR:"WebhookLogScalarWhereInput",
		NOT:"WebhookLogScalarWhereInput",
		id:"StringFilter",
		bookingId:"StringFilter",
		response:"JsonNullableFilter",
		request:"JsonNullableFilter",
		status:"IntFilter",
		createdAt:"DateTimeFilter",
		updatedAt:"DateTimeFilter",
		workspaceId:"StringNullableFilter"
	},
	WebhookLogUpdateManyMutationInput:{
		response:"JSON",
		request:"JSON",
		createdAt:"DateTime",
		updatedAt:"DateTime"
	},
	WorkspaceUpdateWithWhereUniqueWithoutLeadsInput:{
		where:"WorkspaceWhereUniqueInput",
		data:"WorkspaceUpdateWithoutLeadsInput"
	},
	WorkspaceUpdateManyWithWhereWithoutLeadsInput:{
		where:"WorkspaceScalarWhereInput",
		data:"WorkspaceUpdateManyMutationInput"
	},
	WorkspaceScalarWhereInput:{
		AND:"WorkspaceScalarWhereInput",
		OR:"WorkspaceScalarWhereInput",
		NOT:"WorkspaceScalarWhereInput",
		id:"StringFilter",
		type:"EnumWorkspaceTypeFilter",
		name:"StringFilter",
		key:"StringFilter",
		userIds:"StringNullableListFilter",
		ownerId:"StringNullableFilter",
		avatar:"StringNullableFilter",
		createdAt:"DateTimeFilter",
		updatedAt:"DateTimeFilter",
		stripeSubscriptionId:"StringNullableFilter",
		leadIds:"StringNullableListFilter"
	},
	WorkspaceUpdateManyMutationInput:{
		type:"WorkspaceType",
		createdAt:"DateTime",
		updatedAt:"DateTime"
	},
	AdBoardPublicReviewUpdateManyWithoutUserNestedInput:{
		create:"AdBoardPublicReviewCreateWithoutUserInput",
		connectOrCreate:"AdBoardPublicReviewCreateOrConnectWithoutUserInput",
		upsert:"AdBoardPublicReviewUpsertWithWhereUniqueWithoutUserInput",
		createMany:"AdBoardPublicReviewCreateManyUserInputEnvelope",
		set:"AdBoardPublicReviewWhereUniqueInput",
		disconnect:"AdBoardPublicReviewWhereUniqueInput",
		delete:"AdBoardPublicReviewWhereUniqueInput",
		connect:"AdBoardPublicReviewWhereUniqueInput",
		update:"AdBoardPublicReviewUpdateWithWhereUniqueWithoutUserInput",
		updateMany:"AdBoardPublicReviewUpdateManyWithWhereWithoutUserInput",
		deleteMany:"AdBoardPublicReviewScalarWhereInput"
	},
	AdBoardPublicReviewUpsertWithWhereUniqueWithoutUserInput:{
		where:"AdBoardPublicReviewWhereUniqueInput",
		update:"AdBoardPublicReviewUpdateWithoutUserInput",
		create:"AdBoardPublicReviewCreateWithoutUserInput"
	},
	AdBoardPublicReviewUpdateWithoutUserInput:{
		createdAt:"DateTime",
		adBoard:"AdBoardUpdateOneRequiredWithoutPublicReviewsNestedInput"
	},
	AdBoardUpdateOneRequiredWithoutPublicReviewsNestedInput:{
		create:"AdBoardCreateWithoutPublicReviewsInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutPublicReviewsInput",
		upsert:"AdBoardUpsertWithoutPublicReviewsInput",
		connect:"AdBoardWhereUniqueInput",
		update:"AdBoardUpdateToOneWithWhereWithoutPublicReviewsInput"
	},
	AdBoardUpsertWithoutPublicReviewsInput:{
		update:"AdBoardUpdateWithoutPublicReviewsInput",
		create:"AdBoardCreateWithoutPublicReviewsInput",
		where:"AdBoardWhereInput"
	},
	AdBoardUpdateWithoutPublicReviewsInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryUpdateOneWithoutAdBoardsNestedInput",
		locationSubCategory:"LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput",
		type:"AdBoardTypeUpdateOneWithoutAdBoardsNestedInput",
		category:"BusinessCategoryUpdateOneWithoutAdBoardsNestedInput",
		restrictedBusines:"BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput",
		bookings:"BookingUpdateManyWithoutAdBoardNestedInput",
		orders:"OrderUpdateManyWithoutAdBoardNestedInput",
		slotConfigurations:"SlotConfigurationUpdateManyWithoutAdBoardNestedInput",
		offlineBookingsContact:"UserUpdateOneWithoutOfflineBookingAdboardsNestedInput",
		createdBy:"UserUpdateOneWithoutAdBoardsNestedInput",
		workspace:"WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput",
		players:"PlayerUpdateManyWithoutAdBoardsNestedInput",
		adBoardGroup:"AdBoardGroupUpdateOneWithoutAdBoardNestedInput",
		cameras:"CameraUpdateManyWithoutAdBoardNestedInput",
		publishedVersion:"AdBoardUpdateOneWithoutDraftAdboardNestedInput",
		draftAdboard:"AdBoardUpdateOneWithoutPublishedVersionNestedInput",
		history:"AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput",
		archivedBy:"UserUpdateOneWithoutArchivedAdboardsNestedInput"
	},
	AdBoardUpdateToOneWithWhereWithoutPublicReviewsInput:{
		where:"AdBoardWhereInput",
		data:"AdBoardUpdateWithoutPublicReviewsInput"
	},
	AdBoardPublicReviewUpdateWithWhereUniqueWithoutUserInput:{
		where:"AdBoardPublicReviewWhereUniqueInput",
		data:"AdBoardPublicReviewUpdateWithoutUserInput"
	},
	AdBoardPublicReviewUpdateManyWithWhereWithoutUserInput:{
		where:"AdBoardPublicReviewScalarWhereInput",
		data:"AdBoardPublicReviewUpdateManyMutationInput"
	},
	AdBoardPublicReviewScalarWhereInput:{
		AND:"AdBoardPublicReviewScalarWhereInput",
		OR:"AdBoardPublicReviewScalarWhereInput",
		NOT:"AdBoardPublicReviewScalarWhereInput",
		id:"StringFilter",
		adBoardId:"StringFilter",
		userId:"StringFilter",
		rating:"IntFilter",
		comment:"StringNullableFilter",
		createdAt:"DateTimeFilter"
	},
	AdBoardPublicReviewUpdateManyMutationInput:{
		createdAt:"DateTime"
	},
	UserPositionUpdateManyWithoutUserNestedInput:{
		create:"UserPositionCreateWithoutUserInput",
		connectOrCreate:"UserPositionCreateOrConnectWithoutUserInput",
		upsert:"UserPositionUpsertWithWhereUniqueWithoutUserInput",
		createMany:"UserPositionCreateManyUserInputEnvelope",
		set:"UserPositionWhereUniqueInput",
		disconnect:"UserPositionWhereUniqueInput",
		delete:"UserPositionWhereUniqueInput",
		connect:"UserPositionWhereUniqueInput",
		update:"UserPositionUpdateWithWhereUniqueWithoutUserInput",
		updateMany:"UserPositionUpdateManyWithWhereWithoutUserInput",
		deleteMany:"UserPositionScalarWhereInput"
	},
	UserPositionUpsertWithWhereUniqueWithoutUserInput:{
		where:"UserPositionWhereUniqueInput",
		update:"UserPositionUpdateWithoutUserInput",
		create:"UserPositionCreateWithoutUserInput"
	},
	UserPositionUpdateWithoutUserInput:{
		timestamp:"DateTime",
		createdAt:"DateTime",
		coords:"UserPositionCoordsCreateInput",
		location:"UserPositionLocationCreateInput"
	},
	UserPositionUpdateWithWhereUniqueWithoutUserInput:{
		where:"UserPositionWhereUniqueInput",
		data:"UserPositionUpdateWithoutUserInput"
	},
	UserPositionUpdateManyWithWhereWithoutUserInput:{
		where:"UserPositionScalarWhereInput",
		data:"UserPositionUpdateManyMutationInput"
	},
	UserPositionScalarWhereInput:{
		AND:"UserPositionScalarWhereInput",
		OR:"UserPositionScalarWhereInput",
		NOT:"UserPositionScalarWhereInput",
		id:"StringFilter",
		timestamp:"DateTimeFilter",
		createdAt:"DateTimeFilter",
		userId:"StringFilter"
	},
	UserPositionUpdateManyMutationInput:{
		timestamp:"DateTime",
		createdAt:"DateTime",
		coords:"UserPositionCoordsCreateInput",
		location:"UserPositionLocationCreateInput"
	},
	UserUpdateToOneWithWhereWithoutArchivedAdboardsInput:{
		where:"UserWhereInput",
		data:"UserUpdateWithoutArchivedAdboardsInput"
	},
	AdBoardUpdateToOneWithWhereWithoutCamerasInput:{
		where:"AdBoardWhereInput",
		data:"AdBoardUpdateWithoutCamerasInput"
	},
	CameraUpdateWithWhereUniqueWithoutCameraServerInput:{
		where:"CameraWhereUniqueInput",
		data:"CameraUpdateWithoutCameraServerInput"
	},
	CameraUpdateManyWithWhereWithoutCameraServerInput:{
		where:"CameraScalarWhereInput",
		data:"CameraUpdateManyMutationInput"
	},
	CameraScalarWhereInput:{
		AND:"CameraScalarWhereInput",
		OR:"CameraScalarWhereInput",
		NOT:"CameraScalarWhereInput",
		id:"StringFilter",
		name:"StringNullableFilter",
		isOnline:"BoolFilter",
		cameraServerId:"StringFilter",
		cameraUrl:"StringFilter",
		rtspUrl:"StringFilter",
		cameraIp:"StringFilter",
		connectedCpuSerialNumbers:"StringNullableListFilter",
		playerId:"StringNullableFilter",
		updatedAt:"DateTimeFilter",
		isPublic:"BoolFilter",
		adBoardId:"StringNullableFilter"
	},
	CameraUpdateManyMutationInput:{
		updatedAt:"DateTime"
	},
	CameraFeedUpdateManyWithoutCameraServerNestedInput:{
		create:"CameraFeedCreateWithoutCameraServerInput",
		connectOrCreate:"CameraFeedCreateOrConnectWithoutCameraServerInput",
		upsert:"CameraFeedUpsertWithWhereUniqueWithoutCameraServerInput",
		createMany:"CameraFeedCreateManyCameraServerInputEnvelope",
		set:"CameraFeedWhereUniqueInput",
		disconnect:"CameraFeedWhereUniqueInput",
		delete:"CameraFeedWhereUniqueInput",
		connect:"CameraFeedWhereUniqueInput",
		update:"CameraFeedUpdateWithWhereUniqueWithoutCameraServerInput",
		updateMany:"CameraFeedUpdateManyWithWhereWithoutCameraServerInput",
		deleteMany:"CameraFeedScalarWhereInput"
	},
	CameraFeedUpsertWithWhereUniqueWithoutCameraServerInput:{
		where:"CameraFeedWhereUniqueInput",
		update:"CameraFeedUpdateWithoutCameraServerInput",
		create:"CameraFeedCreateWithoutCameraServerInput"
	},
	CameraFeedUpdateWithoutCameraServerInput:{
		timestamp:"DateTime",
		data:"CameraFeedDataCreateInput",
		camera:"CameraUpdateOneRequiredWithoutFeedsNestedInput"
	},
	CameraUpdateOneRequiredWithoutFeedsNestedInput:{
		create:"CameraCreateWithoutFeedsInput",
		connectOrCreate:"CameraCreateOrConnectWithoutFeedsInput",
		upsert:"CameraUpsertWithoutFeedsInput",
		connect:"CameraWhereUniqueInput",
		update:"CameraUpdateToOneWithWhereWithoutFeedsInput"
	},
	CameraUpsertWithoutFeedsInput:{
		update:"CameraUpdateWithoutFeedsInput",
		create:"CameraCreateWithoutFeedsInput",
		where:"CameraWhereInput"
	},
	CameraUpdateWithoutFeedsInput:{
		updatedAt:"DateTime",
		cameraServer:"CameraServerUpdateOneRequiredWithoutCamerasNestedInput",
		player:"PlayerUpdateOneWithoutCamerasNestedInput",
		adBoard:"AdBoardUpdateOneWithoutCamerasNestedInput"
	},
	CameraUpdateToOneWithWhereWithoutFeedsInput:{
		where:"CameraWhereInput",
		data:"CameraUpdateWithoutFeedsInput"
	},
	CameraFeedUpdateWithWhereUniqueWithoutCameraServerInput:{
		where:"CameraFeedWhereUniqueInput",
		data:"CameraFeedUpdateWithoutCameraServerInput"
	},
	CameraFeedUpdateManyWithWhereWithoutCameraServerInput:{
		where:"CameraFeedScalarWhereInput",
		data:"CameraFeedUpdateManyMutationInput"
	},
	CameraServerUpdateWithWhereUniqueWithoutWorkspaceInput:{
		where:"CameraServerWhereUniqueInput",
		data:"CameraServerUpdateWithoutWorkspaceInput"
	},
	CameraServerUpdateManyWithWhereWithoutWorkspaceInput:{
		where:"CameraServerScalarWhereInput",
		data:"CameraServerUpdateManyMutationInput"
	},
	CameraServerScalarWhereInput:{
		AND:"CameraServerScalarWhereInput",
		OR:"CameraServerScalarWhereInput",
		NOT:"CameraServerScalarWhereInput",
		id:"StringFilter",
		name:"StringNullableFilter",
		isOnline:"BoolNullableFilter",
		hostName:"StringNullableFilter",
		userName:"StringNullableFilter",
		deviceId:"StringFilter",
		workspaceId:"StringNullableFilter",
		publicIp:"StringFilter",
		privateIp:"StringFilter"
	},
	CameraServerUpdateManyMutationInput:{

	},
	UserUpdateManyWithoutLeadWorspacesNestedInput:{
		create:"UserCreateWithoutLeadWorspacesInput",
		connectOrCreate:"UserCreateOrConnectWithoutLeadWorspacesInput",
		upsert:"UserUpsertWithWhereUniqueWithoutLeadWorspacesInput",
		set:"UserWhereUniqueInput",
		disconnect:"UserWhereUniqueInput",
		delete:"UserWhereUniqueInput",
		connect:"UserWhereUniqueInput",
		update:"UserUpdateWithWhereUniqueWithoutLeadWorspacesInput",
		updateMany:"UserUpdateManyWithWhereWithoutLeadWorspacesInput",
		deleteMany:"UserScalarWhereInput"
	},
	UserUpsertWithWhereUniqueWithoutLeadWorspacesInput:{
		where:"UserWhereUniqueInput",
		update:"UserUpdateWithoutLeadWorspacesInput",
		create:"UserCreateWithoutLeadWorspacesInput"
	},
	UserUpdateWithoutLeadWorspacesInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingUpdateManyWithoutUserNestedInput",
		createdFiles:"FileUpdateManyWithoutCreatedByNestedInput",
		updatedFiles:"FileUpdateManyWithoutUpdatedByNestedInput",
		orders:"OrderUpdateManyWithoutCreatedByNestedInput",
		changelogs:"ChangelogUpdateManyWithoutUserNestedInput",
		adBoards:"AdBoardUpdateManyWithoutCreatedByNestedInput",
		audits:"AuditUpdateManyWithoutUserNestedInput",
		campaigns:"CampaignUpdateManyWithoutUserNestedInput",
		sharedBookings:"BookingUpdateManyWithoutSharedUsersNestedInput",
		memberships:"MembershipUpdateManyWithoutUserNestedInput",
		notifications:"NotificationUpdateManyWithoutRecipientNestedInput",
		publishAdboardVersion:"AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput",
		offlineBookingAdboards:"AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutUserNestedInput",
		archivedAdboards:"AdBoardUpdateManyWithoutArchivedByNestedInput",
		ownedWorkspaces:"WorkspaceUpdateManyWithoutOwnerNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutUserNestedInput",
		userPositions:"UserPositionUpdateManyWithoutUserNestedInput"
	},
	UserUpdateWithWhereUniqueWithoutLeadWorspacesInput:{
		where:"UserWhereUniqueInput",
		data:"UserUpdateWithoutLeadWorspacesInput"
	},
	UserUpdateManyWithWhereWithoutLeadWorspacesInput:{
		where:"UserScalarWhereInput",
		data:"UserUpdateManyMutationInput"
	},
	UserScalarWhereInput:{
		AND:"UserScalarWhereInput",
		OR:"UserScalarWhereInput",
		NOT:"UserScalarWhereInput",
		id:"StringFilter",
		uid:"StringNullableFilter",
		email:"StringFilter",
		image:"StringNullableFilter",
		phoneNumber:"StringNullableFilter",
		displayName:"StringNullableFilter",
		otherDetails:"JsonNullableFilter",
		roles:"EnumRoleNullableListFilter",
		sharedBookingIds:"StringNullableListFilter",
		disclaimerAccepted:"BoolFilter",
		leadWorkspaceIds:"StringNullableListFilter"
	},
	UserUpdateManyMutationInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput"
	},
	WorkspaceUpdateWithWhereUniqueWithoutOwnerInput:{
		where:"WorkspaceWhereUniqueInput",
		data:"WorkspaceUpdateWithoutOwnerInput"
	},
	WorkspaceUpdateManyWithWhereWithoutOwnerInput:{
		where:"WorkspaceScalarWhereInput",
		data:"WorkspaceUpdateManyMutationInput"
	},
	UserUpdateToOneWithWhereWithoutPublicReviewsInput:{
		where:"UserWhereInput",
		data:"UserUpdateWithoutPublicReviewsInput"
	},
	AdBoardPublicReviewUpdateWithWhereUniqueWithoutAdBoardInput:{
		where:"AdBoardPublicReviewWhereUniqueInput",
		data:"AdBoardPublicReviewUpdateWithoutAdBoardInput"
	},
	AdBoardPublicReviewUpdateManyWithWhereWithoutAdBoardInput:{
		where:"AdBoardPublicReviewScalarWhereInput",
		data:"AdBoardPublicReviewUpdateManyMutationInput"
	},
	AdBoardUpdateWithWhereUniqueWithoutArchivedByInput:{
		where:"AdBoardWhereUniqueInput",
		data:"AdBoardUpdateWithoutArchivedByInput"
	},
	AdBoardUpdateManyWithWhereWithoutArchivedByInput:{
		where:"AdBoardScalarWhereInput",
		data:"AdBoardUpdateManyMutationInput"
	},
	AdBoardScalarWhereInput:{
		AND:"AdBoardScalarWhereInput",
		OR:"AdBoardScalarWhereInput",
		NOT:"AdBoardScalarWhereInput",
		id:"StringFilter",
		address:"StringFilter",
		state:"StringFilter",
		city:"StringFilter",
		country:"StringFilter",
		outdoor:"BoolFilter",
		locationCategoryId:"StringNullableFilter",
		locationSubCategoryId:"StringNullableFilter",
		alias:"StringNullableFilter",
		title:"StringFilter",
		pricePerDay:"IntFilter",
		originalPricePerDay:"IntNullableFilter",
		description:"StringNullableFilter",
		adBoardTypeId:"StringNullableFilter",
		tags:"StringNullableListFilter",
		featuredImage:"StringNullableFilter",
		galleryImgs:"StringNullableListFilter",
		notes:"StringNullableListFilter",
		impressionMultiplier:"FloatNullableFilter",
		categoryId:"StringNullableFilter",
		restrictedBusinessIds:"StringNullableListFilter",
		targetAudiences:"StringNullableListFilter",
		status:"EnumAdBoardStatusFilter",
		adboardDraftStatus:"EnumAdboardDraftStatusNullableFilter",
		isLive:"BoolFilter",
		numberOfDisplays:"IntFilter",
		dailyOperationHours:"IntFilter",
		adsPerLoop:"IntFilter",
		adsDuration:"IntFilter",
		monthlyPrice:"FloatNullableFilter",
		offlineBookingsEnabled:"BoolFilter",
		offlineBookingContactId:"StringNullableFilter",
		createdAt:"DateTimeNullableFilter",
		createdByUserId:"StringNullableFilter",
		href:"StringNullableFilter",
		workspaceId:"StringFilter",
		playerIds:"StringNullableListFilter",
		publisherScreenId:"StringNullableFilter",
		additionalData:"JsonNullableFilter",
		isDraft:"BoolFilter",
		publishedVersionId:"StringNullableFilter",
		lastPublishedAt:"DateTimeNullableFilter",
		isArchived:"BoolFilter",
		archivedAt:"DateTimeNullableFilter",
		archivedByUserId:"StringNullableFilter",
		updatedAt:"DateTimeNullableFilter"
	},
	UserUpdateToOneWithWhereWithoutReviewCommentsInput:{
		where:"UserWhereInput",
		data:"UserUpdateWithoutReviewCommentsInput"
	},
	AdBoardReviewCommentUpdateWithWhereUniqueWithoutAdBoardInput:{
		where:"AdBoardReviewCommentWhereUniqueInput",
		data:"AdBoardReviewCommentUpdateWithoutAdBoardInput"
	},
	AdBoardReviewCommentUpdateManyWithWhereWithoutAdBoardInput:{
		where:"AdBoardReviewCommentScalarWhereInput",
		data:"AdBoardReviewCommentUpdateManyMutationInput"
	},
	AdBoardReviewCommentScalarWhereInput:{
		AND:"AdBoardReviewCommentScalarWhereInput",
		OR:"AdBoardReviewCommentScalarWhereInput",
		NOT:"AdBoardReviewCommentScalarWhereInput",
		id:"StringFilter",
		adBoardId:"StringFilter",
		userId:"StringFilter",
		comment:"StringFilter",
		action:"EnumReviewActionFilter",
		createdAt:"DateTimeFilter"
	},
	AdBoardReviewCommentUpdateManyMutationInput:{
		action:"ReviewAction",
		createdAt:"DateTime"
	},
	AdBoardUpdateToOneWithWhereWithoutPublishedVersionInput:{
		where:"AdBoardWhereInput",
		data:"AdBoardUpdateWithoutPublishedVersionInput"
	},
	AdBoardUpdateToOneWithWhereWithoutReviewCommentsInput:{
		where:"AdBoardWhereInput",
		data:"AdBoardUpdateWithoutReviewCommentsInput"
	},
	AdBoardReviewCommentUpdateWithWhereUniqueWithoutUserInput:{
		where:"AdBoardReviewCommentWhereUniqueInput",
		data:"AdBoardReviewCommentUpdateWithoutUserInput"
	},
	AdBoardReviewCommentUpdateManyWithWhereWithoutUserInput:{
		where:"AdBoardReviewCommentScalarWhereInput",
		data:"AdBoardReviewCommentUpdateManyMutationInput"
	},
	UserUpdateToOneWithWhereWithoutPublishAdboardVersionInput:{
		where:"UserWhereInput",
		data:"UserUpdateWithoutPublishAdboardVersionInput"
	},
	AdBoardPublishHistoryUpdateWithWhereUniqueWithoutAdBoardInput:{
		where:"AdBoardPublishHistoryWhereUniqueInput",
		data:"AdBoardPublishHistoryUpdateWithoutAdBoardInput"
	},
	AdBoardPublishHistoryUpdateManyWithWhereWithoutAdBoardInput:{
		where:"AdBoardPublishHistoryScalarWhereInput",
		data:"AdBoardPublishHistoryUpdateManyMutationInput"
	},
	AdBoardPublishHistoryScalarWhereInput:{
		AND:"AdBoardPublishHistoryScalarWhereInput",
		OR:"AdBoardPublishHistoryScalarWhereInput",
		NOT:"AdBoardPublishHistoryScalarWhereInput",
		id:"StringFilter",
		adBoardId:"StringFilter",
		publishedAt:"DateTimeFilter",
		publishedByUserId:"StringFilter",
		changesSnapshot:"JsonFilter"
	},
	AdBoardPublishHistoryUpdateManyMutationInput:{
		publishedAt:"DateTime",
		changesSnapshot:"JSON"
	},
	AdBoardUpdateToOneWithWhereWithoutDraftAdboardInput:{
		where:"AdBoardWhereInput",
		data:"AdBoardUpdateWithoutDraftAdboardInput"
	},
	AdBoardUpdateWithWhereUniqueWithoutPlayersInput:{
		where:"AdBoardWhereUniqueInput",
		data:"AdBoardUpdateWithoutPlayersInput"
	},
	AdBoardUpdateManyWithWhereWithoutPlayersInput:{
		where:"AdBoardScalarWhereInput",
		data:"AdBoardUpdateManyMutationInput"
	},
	PlayerUpdateToOneWithWhereWithoutCamerasInput:{
		where:"PlayerWhereInput",
		data:"PlayerUpdateWithoutCamerasInput"
	},
	CameraUpdateWithWhereUniqueWithoutAdBoardInput:{
		where:"CameraWhereUniqueInput",
		data:"CameraUpdateWithoutAdBoardInput"
	},
	CameraUpdateManyWithWhereWithoutAdBoardInput:{
		where:"CameraScalarWhereInput",
		data:"CameraUpdateManyMutationInput"
	},
	AdBoardUpdateWithWhereUniqueWithoutOfflineBookingsContactInput:{
		where:"AdBoardWhereUniqueInput",
		data:"AdBoardUpdateWithoutOfflineBookingsContactInput"
	},
	AdBoardUpdateManyWithWhereWithoutOfflineBookingsContactInput:{
		where:"AdBoardScalarWhereInput",
		data:"AdBoardUpdateManyMutationInput"
	},
	UserUpdateWithWhereUniqueWithoutSharedBookingsInput:{
		where:"UserWhereUniqueInput",
		data:"UserUpdateWithoutSharedBookingsInput"
	},
	UserUpdateManyWithWhereWithoutSharedBookingsInput:{
		where:"UserScalarWhereInput",
		data:"UserUpdateManyMutationInput"
	},
	WebhookLogUpdateManyWithoutBookingNestedInput:{
		create:"WebhookLogCreateWithoutBookingInput",
		connectOrCreate:"WebhookLogCreateOrConnectWithoutBookingInput",
		upsert:"WebhookLogUpsertWithWhereUniqueWithoutBookingInput",
		createMany:"WebhookLogCreateManyBookingInputEnvelope",
		set:"WebhookLogWhereUniqueInput",
		disconnect:"WebhookLogWhereUniqueInput",
		delete:"WebhookLogWhereUniqueInput",
		connect:"WebhookLogWhereUniqueInput",
		update:"WebhookLogUpdateWithWhereUniqueWithoutBookingInput",
		updateMany:"WebhookLogUpdateManyWithWhereWithoutBookingInput",
		deleteMany:"WebhookLogScalarWhereInput"
	},
	WebhookLogUpsertWithWhereUniqueWithoutBookingInput:{
		where:"WebhookLogWhereUniqueInput",
		update:"WebhookLogUpdateWithoutBookingInput",
		create:"WebhookLogCreateWithoutBookingInput"
	},
	WebhookLogUpdateWithoutBookingInput:{
		response:"JSON",
		request:"JSON",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		workspace:"WorkspaceUpdateOneWithoutWebhookLogsNestedInput"
	},
	WorkspaceUpdateOneWithoutWebhookLogsNestedInput:{
		create:"WorkspaceCreateWithoutWebhookLogsInput",
		connectOrCreate:"WorkspaceCreateOrConnectWithoutWebhookLogsInput",
		upsert:"WorkspaceUpsertWithoutWebhookLogsInput",
		delete:"WorkspaceWhereInput",
		connect:"WorkspaceWhereUniqueInput",
		update:"WorkspaceUpdateToOneWithWhereWithoutWebhookLogsInput"
	},
	WorkspaceUpsertWithoutWebhookLogsInput:{
		update:"WorkspaceUpdateWithoutWebhookLogsInput",
		create:"WorkspaceCreateWithoutWebhookLogsInput",
		where:"WorkspaceWhereInput"
	},
	WorkspaceUpdateWithoutWebhookLogsInput:{
		type:"WorkspaceType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		owner:"UserUpdateOneWithoutOwnedWorkspacesNestedInput",
		memberships:"MembershipUpdateManyWithoutWorkspaceNestedInput",
		adBoards:"AdBoardUpdateManyWithoutWorkspaceNestedInput",
		playlists:"PlaylistUpdateManyWithoutWorkspaceNestedInput",
		adBoardGroups:"AdBoardGroupUpdateManyWithoutWorkspaceNestedInput",
		audits:"AuditUpdateManyWithoutWorkspaceNestedInput",
		bookings:"BookingUpdateManyWithoutWorkspaceNestedInput",
		files:"FileUpdateManyWithoutWorkspaceNestedInput",
		players:"PlayerUpdateManyWithoutWorkspaceNestedInput",
		transactions:"TransactionUpdateManyWithoutWorkspaceNestedInput",
		activeSubscription:"SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput",
		cameraServers:"CameraServerUpdateManyWithoutWorkspaceNestedInput",
		workspaceRoles:"WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput",
		leads:"UserUpdateManyWithoutLeadWorspacesNestedInput"
	},
	WorkspaceUpdateToOneWithWhereWithoutWebhookLogsInput:{
		where:"WorkspaceWhereInput",
		data:"WorkspaceUpdateWithoutWebhookLogsInput"
	},
	WebhookLogUpdateWithWhereUniqueWithoutBookingInput:{
		where:"WebhookLogWhereUniqueInput",
		data:"WebhookLogUpdateWithoutBookingInput"
	},
	WebhookLogUpdateManyWithWhereWithoutBookingInput:{
		where:"WebhookLogScalarWhereInput",
		data:"WebhookLogUpdateManyMutationInput"
	},
	BookingUpdateToOneWithWhereWithoutPayoutsInput:{
		where:"BookingWhereInput",
		data:"BookingUpdateWithoutPayoutsInput"
	},
	PayoutUpdateWithWhereUniqueWithoutTransactionInput:{
		where:"PayoutWhereUniqueInput",
		data:"PayoutUpdateWithoutTransactionInput"
	},
	PayoutUpdateManyWithWhereWithoutTransactionInput:{
		where:"PayoutScalarWhereInput",
		data:"PayoutUpdateManyMutationInput"
	},
	PayoutScalarWhereInput:{
		AND:"PayoutScalarWhereInput",
		OR:"PayoutScalarWhereInput",
		NOT:"PayoutScalarWhereInput",
		id:"StringFilter",
		totalAmount:"FloatFilter",
		commissionRate:"FloatFilter",
		commissionAmount:"FloatFilter",
		netAmount:"FloatFilter",
		createdAt:"DateTimeFilter",
		bookingId:"StringFilter",
		status:"EnumPayoutStatusFilter",
		transactionId:"StringNullableFilter"
	},
	PayoutUpdateManyMutationInput:{
		createdAt:"DateTime",
		status:"PayoutStatus"
	},
	TransactionUpdateWithWhereUniqueWithoutWorkspaceInput:{
		where:"TransactionWhereUniqueInput",
		data:"TransactionUpdateWithoutWorkspaceInput"
	},
	TransactionUpdateManyWithWhereWithoutWorkspaceInput:{
		where:"TransactionScalarWhereInput",
		data:"TransactionUpdateManyMutationInput"
	},
	TransactionScalarWhereInput:{
		AND:"TransactionScalarWhereInput",
		OR:"TransactionScalarWhereInput",
		NOT:"TransactionScalarWhereInput",
		workspaceId:"StringNullableFilter",
		id:"StringFilter",
		amount:"FloatFilter",
		createAt:"DateTimeFilter",
		processedAt:"DateTimeNullableFilter",
		relatedEntityId:"StringFilter",
		transactionStatus:"EnumTransactionStatusFilter",
		details:"JsonNullableFilter"
	},
	TransactionUpdateManyMutationInput:{
		createAt:"DateTime",
		processedAt:"DateTime",
		transactionStatus:"TransactionStatus",
		details:"JSON"
	},
	WorkspaceUpdateToOneWithWhereWithoutCameraServersInput:{
		where:"WorkspaceWhereInput",
		data:"WorkspaceUpdateWithoutCameraServersInput"
	},
	CameraServerUpdateToOneWithWhereWithoutCamerasInput:{
		where:"CameraServerWhereInput",
		data:"CameraServerUpdateWithoutCamerasInput"
	},
	CameraUpdateWithWhereUniqueWithoutPlayerInput:{
		where:"CameraWhereUniqueInput",
		data:"CameraUpdateWithoutPlayerInput"
	},
	CameraUpdateManyWithWhereWithoutPlayerInput:{
		where:"CameraScalarWhereInput",
		data:"CameraUpdateManyMutationInput"
	},
	PlayerUpdateWithWhereUniqueWithoutWorkspaceInput:{
		where:"PlayerWhereUniqueInput",
		data:"PlayerUpdateWithoutWorkspaceInput"
	},
	PlayerUpdateManyWithWhereWithoutWorkspaceInput:{
		where:"PlayerScalarWhereInput",
		data:"PlayerUpdateManyMutationInput"
	},
	PlayerScalarWhereInput:{
		AND:"PlayerScalarWhereInput",
		OR:"PlayerScalarWhereInput",
		NOT:"PlayerScalarWhereInput",
		id:"StringFilter",
		name:"StringFilter",
		configLocation:"StringNullableFilter",
		pisignagePlayerId:"StringNullableFilter",
		pisignageGroupId:"StringNullableFilter",
		cpuSerialNumber:"StringNullableFilter",
		displayName:"StringNullableFilter",
		adBoardGroupId:"StringNullableFilter",
		workpaceId:"StringNullableFilter",
		aspectRatioType:"EnumAspectRatioTypeFilter",
		customAspectRatioWidth:"IntNullableFilter",
		customAspectRatioHeight:"IntNullableFilter",
		isOnline:"BoolFilter",
		adBoardIds:"StringNullableListFilter"
	},
	PlayerUpdateManyMutationInput:{
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackDetails:"PlaybackTimeCreateInput",
		aspectRatioType:"AspectRatioType",
		map:"MapCreateInput"
	},
	WorkspaceUpdateToOneWithWhereWithoutTransactionsInput:{
		where:"WorkspaceWhereInput",
		data:"WorkspaceUpdateWithoutTransactionsInput"
	},
	TransactionUpdateToOneWithWhereWithoutPayoutsInput:{
		where:"TransactionWhereInput",
		data:"TransactionUpdateWithoutPayoutsInput"
	},
	PayoutUpdateWithWhereUniqueWithoutBookingInput:{
		where:"PayoutWhereUniqueInput",
		data:"PayoutUpdateWithoutBookingInput"
	},
	PayoutUpdateManyWithWhereWithoutBookingInput:{
		where:"PayoutScalarWhereInput",
		data:"PayoutUpdateManyMutationInput"
	},
	BookingUpdateWithWhereUniqueWithoutCampaignInput:{
		where:"BookingWhereUniqueInput",
		data:"BookingUpdateWithoutCampaignInput"
	},
	BookingUpdateManyWithWhereWithoutCampaignInput:{
		where:"BookingScalarWhereInput",
		data:"BookingUpdateManyMutationInput"
	},
	BookingScalarWhereInput:{
		AND:"BookingScalarWhereInput",
		OR:"BookingScalarWhereInput",
		NOT:"BookingScalarWhereInput",
		id:"StringFilter",
		additionalInformation:"StringNullableFilter",
		origin:"StringNullableFilter",
		assetsIDs:"StringNullableListFilter",
		bookingCode:"StringFilter",
		bookingDate:"DateTimeFilter",
		bookingFor:"StringFilter",
		purpose:"StringFilter",
		businessCategoryId:"StringNullableFilter",
		adBoardId:"StringFilter",
		workspaceId:"StringNullableFilter",
		campaignId:"StringNullableFilter",
		bookingStatus:"EnumBookingStatusFilter",
		paymentStatus:"EnumPaymentStatusNullableFilter",
		deploymentStatus:"EnumDeploymentStatusFilter",
		contentApprovalStatus:"EnumContentApprovalStatusFilter",
		userId:"StringFilter",
		sharedUserIds:"StringNullableListFilter",
		isOffline:"BoolFilter",
		isPaid:"BoolFilter",
		isScheduled:"BoolFilter",
		isDeployed:"BoolFilter",
		isCompleted:"BoolFilter"
	},
	BookingUpdateManyMutationInput:{
		bookingDate:"DateTime",
		bookingDetails:"BookingDetailsCreateInput",
		bookingStatus:"BookingStatus",
		paymentStatus:"PaymentStatus",
		deploymentStatus:"DeploymentStatus",
		contentApprovalStatus:"ContentApprovalStatus",
		selectedDates:"OrderSelectedDateCreateInput",
		payment:"BookingPaymentCreateInput",
		priceSummary:"PriceSummaryCreateInput",
		pisignage:"BookingPisignageDataCreateInput",
		refund:"BookingRefundCreateInput"
	},
	CampaignUpdateWithWhereUniqueWithoutAssetsInput:{
		where:"CampaignWhereUniqueInput",
		data:"CampaignUpdateWithoutAssetsInput"
	},
	CampaignUpdateManyWithWhereWithoutAssetsInput:{
		where:"CampaignScalarWhereInput",
		data:"CampaignUpdateManyMutationInput"
	},
	CampaignScalarWhereInput:{
		AND:"CampaignScalarWhereInput",
		OR:"CampaignScalarWhereInput",
		NOT:"CampaignScalarWhereInput",
		id:"StringFilter",
		name:"StringFilter",
		userId:"StringFilter",
		createdAt:"DateTimeFilter",
		updatedAt:"DateTimeFilter",
		bookingFor:"StringFilter",
		purpose:"StringFilter",
		businessCategoryId:"StringFilter",
		paymentStatus:"EnumPaymentStatusFilter",
		origin:"StringFilter",
		assetsIDs:"StringNullableListFilter"
	},
	CampaignUpdateManyMutationInput:{
		createdAt:"DateTime",
		updatedAt:"DateTime",
		payment:"BookingPaymentCreateInput",
		paymentStatus:"PaymentStatus",
		priceSummary:"PriceSummaryCreateInput",
		selectedDates:"OrderSelectedDateCreateInput"
	},
	FileUpdateWithWhereUniqueWithoutWorkspaceInput:{
		where:"FileWhereUniqueInput",
		data:"FileUpdateWithoutWorkspaceInput"
	},
	FileUpdateManyWithWhereWithoutWorkspaceInput:{
		where:"FileScalarWhereInput",
		data:"FileUpdateManyMutationInput"
	},
	FileScalarWhereInput:{
		AND:"FileScalarWhereInput",
		OR:"FileScalarWhereInput",
		NOT:"FileScalarWhereInput",
		id:"StringFilter",
		contentType:"StringFilter",
		ctime:"StringFilter",
		filename:"StringFilter",
		filepath:"StringNullableFilter",
		isDirectory:"BoolFilter",
		mtime:"StringFilter",
		name:"StringFilter",
		size:"IntFilter",
		status:"EnumFileStatusNullableFilter",
		type:"StringFilter",
		updatedAt:"DateTimeFilter",
		createdAt:"DateTimeFilter",
		updatedByUserId:"StringNullableFilter",
		createdByUserId:"StringFilter",
		url:"StringFilter",
		bookingsIDs:"StringNullableListFilter",
		workspaceId:"StringNullableFilter",
		proofBookingId:"StringNullableFilter",
		campaignIds:"StringNullableListFilter"
	},
	FileUpdateManyMutationInput:{
		meta:"FileMetaCreateInput",
		status:"FileStatus",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	WorkspaceUpdateToOneWithWhereWithoutBookingsInput:{
		where:"WorkspaceWhereInput",
		data:"WorkspaceUpdateWithoutBookingsInput"
	},
	BookingUpdateToOneWithWhereWithoutProofsInput:{
		where:"BookingWhereInput",
		data:"BookingUpdateWithoutProofsInput"
	},
	FileUpdateWithWhereUniqueWithoutCampaignsInput:{
		where:"FileWhereUniqueInput",
		data:"FileUpdateWithoutCampaignsInput"
	},
	FileUpdateManyWithWhereWithoutCampaignsInput:{
		where:"FileScalarWhereInput",
		data:"FileUpdateManyMutationInput"
	},
	CampaignUpdateToOneWithWhereWithoutBookingsInput:{
		where:"CampaignWhereInput",
		data:"CampaignUpdateWithoutBookingsInput"
	},
	BookingUpdateWithWhereUniqueWithoutWorkspaceInput:{
		where:"BookingWhereUniqueInput",
		data:"BookingUpdateWithoutWorkspaceInput"
	},
	BookingUpdateManyWithWhereWithoutWorkspaceInput:{
		where:"BookingScalarWhereInput",
		data:"BookingUpdateManyMutationInput"
	},
	WorkspaceUpdateToOneWithWhereWithoutAdBoardGroupsInput:{
		where:"WorkspaceWhereInput",
		data:"WorkspaceUpdateWithoutAdBoardGroupsInput"
	},
	AdBoardGroupUpdateToOneWithWhereWithoutAdBoardInput:{
		where:"AdBoardGroupWhereInput",
		data:"AdBoardGroupUpdateWithoutAdBoardInput"
	},
	AdBoardUpdateToOneWithWhereWithoutHistoryInput:{
		where:"AdBoardWhereInput",
		data:"AdBoardUpdateWithoutHistoryInput"
	},
	AdBoardPublishHistoryUpdateWithWhereUniqueWithoutPublishedByInput:{
		where:"AdBoardPublishHistoryWhereUniqueInput",
		data:"AdBoardPublishHistoryUpdateWithoutPublishedByInput"
	},
	AdBoardPublishHistoryUpdateManyWithWhereWithoutPublishedByInput:{
		where:"AdBoardPublishHistoryScalarWhereInput",
		data:"AdBoardPublishHistoryUpdateManyMutationInput"
	},
	UserUpdateToOneWithWhereWithoutAuditsInput:{
		where:"UserWhereInput",
		data:"UserUpdateWithoutAuditsInput"
	},
	AuditUpdateWithWhereUniqueWithoutWorkspaceInput:{
		where:"AuditWhereUniqueInput",
		data:"AuditUpdateWithoutWorkspaceInput"
	},
	AuditUpdateManyWithWhereWithoutWorkspaceInput:{
		where:"AuditScalarWhereInput",
		data:"AuditUpdateManyMutationInput"
	},
	AuditScalarWhereInput:{
		AND:"AuditScalarWhereInput",
		OR:"AuditScalarWhereInput",
		NOT:"AuditScalarWhereInput",
		id:"StringFilter",
		createdAt:"DateTimeFilter",
		message:"StringFilter",
		action:"StringFilter",
		userId:"StringFilter",
		workspaceId:"StringNullableFilter",
		updates:"JsonNullableFilter"
	},
	AuditUpdateManyMutationInput:{
		createdAt:"DateTime",
		entity:"EntityCreateInput",
		updates:"JSON"
	},
	WorkspaceUpdateToOneWithWhereWithoutPlayersInput:{
		where:"WorkspaceWhereInput",
		data:"WorkspaceUpdateWithoutPlayersInput"
	},
	PlayerUpdateWithWhereUniqueWithoutAdBoardGroupInput:{
		where:"PlayerWhereUniqueInput",
		data:"PlayerUpdateWithoutAdBoardGroupInput"
	},
	PlayerUpdateManyWithWhereWithoutAdBoardGroupInput:{
		where:"PlayerScalarWhereInput",
		data:"PlayerUpdateManyMutationInput"
	},
	AdBoardGroupUpdateWithWhereUniqueWithoutScheduledPlaylistsInput:{
		where:"AdBoardGroupWhereUniqueInput",
		data:"AdBoardGroupUpdateWithoutScheduledPlaylistsInput"
	},
	AdBoardGroupUpdateManyWithWhereWithoutScheduledPlaylistsInput:{
		where:"AdBoardGroupScalarWhereInput",
		data:"AdBoardGroupUpdateManyMutationInput"
	},
	AdBoardGroupScalarWhereInput:{
		AND:"AdBoardGroupScalarWhereInput",
		OR:"AdBoardGroupScalarWhereInput",
		NOT:"AdBoardGroupScalarWhereInput",
		id:"StringFilter",
		name:"StringFilter",
		adBoardId:"StringNullableFilter",
		scheduledPlaylistsIDs:"StringNullableListFilter",
		adsPlaylistID:"StringNullableFilter",
		pisignageGroupId:"StringFilter",
		fitImage:"EnumFitTypeFilter",
		fitVideo:"EnumFitTypeFilter",
		workspaceId:"StringFilter"
	},
	AdBoardGroupUpdateManyMutationInput:{
		schedules:"ScheduleCreateInput",
		fitImage:"FitType",
		fitVideo:"FitType"
	},
	PlaylistUpdateManyWithoutFooterPlaylistNestedInput:{
		create:"PlaylistCreateWithoutFooterPlaylistInput",
		connectOrCreate:"PlaylistCreateOrConnectWithoutFooterPlaylistInput",
		upsert:"PlaylistUpsertWithWhereUniqueWithoutFooterPlaylistInput",
		createMany:"PlaylistCreateManyFooterPlaylistInputEnvelope",
		set:"PlaylistWhereUniqueInput",
		disconnect:"PlaylistWhereUniqueInput",
		delete:"PlaylistWhereUniqueInput",
		connect:"PlaylistWhereUniqueInput",
		update:"PlaylistUpdateWithWhereUniqueWithoutFooterPlaylistInput",
		updateMany:"PlaylistUpdateManyWithWhereWithoutFooterPlaylistInput",
		deleteMany:"PlaylistScalarWhereInput"
	},
	PlaylistUpsertWithWhereUniqueWithoutFooterPlaylistInput:{
		where:"PlaylistWhereUniqueInput",
		update:"PlaylistUpdateWithoutFooterPlaylistInput",
		create:"PlaylistCreateWithoutFooterPlaylistInput"
	},
	PlaylistUpdateWithoutFooterPlaylistInput:{
		assets:"PisignageFileCreateInput",
		layout:"ScreenLayout",
		sidebarPlaylist:"PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput",
		adBoardGroupsForAds:"AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput",
		adBoardGroups:"AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput",
		workspace:"WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput",
		footerParentPlaylists:"PlaylistUpdateManyWithoutFooterPlaylistNestedInput",
		sidebarParentPlaylists:"PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"
	},
	PlaylistUpdateManyWithoutSidebarPlaylistNestedInput:{
		create:"PlaylistCreateWithoutSidebarPlaylistInput",
		connectOrCreate:"PlaylistCreateOrConnectWithoutSidebarPlaylistInput",
		upsert:"PlaylistUpsertWithWhereUniqueWithoutSidebarPlaylistInput",
		createMany:"PlaylistCreateManySidebarPlaylistInputEnvelope",
		set:"PlaylistWhereUniqueInput",
		disconnect:"PlaylistWhereUniqueInput",
		delete:"PlaylistWhereUniqueInput",
		connect:"PlaylistWhereUniqueInput",
		update:"PlaylistUpdateWithWhereUniqueWithoutSidebarPlaylistInput",
		updateMany:"PlaylistUpdateManyWithWhereWithoutSidebarPlaylistInput",
		deleteMany:"PlaylistScalarWhereInput"
	},
	PlaylistUpsertWithWhereUniqueWithoutSidebarPlaylistInput:{
		where:"PlaylistWhereUniqueInput",
		update:"PlaylistUpdateWithoutSidebarPlaylistInput",
		create:"PlaylistCreateWithoutSidebarPlaylistInput"
	},
	PlaylistUpdateWithoutSidebarPlaylistInput:{
		assets:"PisignageFileCreateInput",
		layout:"ScreenLayout",
		footerPlaylist:"PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput",
		adBoardGroupsForAds:"AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput",
		adBoardGroups:"AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput",
		workspace:"WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput",
		footerParentPlaylists:"PlaylistUpdateManyWithoutFooterPlaylistNestedInput",
		sidebarParentPlaylists:"PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"
	},
	PlaylistUpdateWithWhereUniqueWithoutSidebarPlaylistInput:{
		where:"PlaylistWhereUniqueInput",
		data:"PlaylistUpdateWithoutSidebarPlaylistInput"
	},
	PlaylistUpdateManyWithWhereWithoutSidebarPlaylistInput:{
		where:"PlaylistScalarWhereInput",
		data:"PlaylistUpdateManyMutationInput"
	},
	PlaylistScalarWhereInput:{
		AND:"PlaylistScalarWhereInput",
		OR:"PlaylistScalarWhereInput",
		NOT:"PlaylistScalarWhereInput",
		id:"StringFilter",
		name:"StringFilter",
		displayName:"StringNullableFilter",
		isAdsPlaylist:"BoolNullableFilter",
		footerPlaylistId:"StringNullableFilter",
		sidebarPlaylistId:"StringNullableFilter",
		layout:"EnumScreenLayoutFilter",
		isDeployed:"BoolNullableFilter",
		adBoardGroupIds:"StringNullableListFilter",
		workspaceId:"StringFilter"
	},
	PlaylistUpdateManyMutationInput:{
		assets:"PisignageFileCreateInput",
		layout:"ScreenLayout"
	},
	PlaylistUpdateWithWhereUniqueWithoutFooterPlaylistInput:{
		where:"PlaylistWhereUniqueInput",
		data:"PlaylistUpdateWithoutFooterPlaylistInput"
	},
	PlaylistUpdateManyWithWhereWithoutFooterPlaylistInput:{
		where:"PlaylistScalarWhereInput",
		data:"PlaylistUpdateManyMutationInput"
	},
	PlaylistUpdateToOneWithWhereWithoutAdBoardGroupsForAdsInput:{
		where:"PlaylistWhereInput",
		data:"PlaylistUpdateWithoutAdBoardGroupsForAdsInput"
	},
	AdBoardGroupUpdateWithWhereUniqueWithoutWorkspaceInput:{
		where:"AdBoardGroupWhereUniqueInput",
		data:"AdBoardGroupUpdateWithoutWorkspaceInput"
	},
	AdBoardGroupUpdateManyWithWhereWithoutWorkspaceInput:{
		where:"AdBoardGroupScalarWhereInput",
		data:"AdBoardGroupUpdateManyMutationInput"
	},
	WorkspaceUpdateToOneWithWhereWithoutPlaylistsInput:{
		where:"WorkspaceWhereInput",
		data:"WorkspaceUpdateWithoutPlaylistsInput"
	},
	PlaylistUpdateWithWhereUniqueWithoutAdBoardGroupsInput:{
		where:"PlaylistWhereUniqueInput",
		data:"PlaylistUpdateWithoutAdBoardGroupsInput"
	},
	PlaylistUpdateManyWithWhereWithoutAdBoardGroupsInput:{
		where:"PlaylistScalarWhereInput",
		data:"PlaylistUpdateManyMutationInput"
	},
	AdBoardGroupUpdateWithWhereUniqueWithoutAdsPlaylistInput:{
		where:"AdBoardGroupWhereUniqueInput",
		data:"AdBoardGroupUpdateWithoutAdsPlaylistInput"
	},
	AdBoardGroupUpdateManyWithWhereWithoutAdsPlaylistInput:{
		where:"AdBoardGroupScalarWhereInput",
		data:"AdBoardGroupUpdateManyMutationInput"
	},
	PlaylistUpdateToOneWithWhereWithoutSidebarParentPlaylistsInput:{
		where:"PlaylistWhereInput",
		data:"PlaylistUpdateWithoutSidebarParentPlaylistsInput"
	},
	PlaylistUpdateToOneWithWhereWithoutFooterParentPlaylistsInput:{
		where:"PlaylistWhereInput",
		data:"PlaylistUpdateWithoutFooterParentPlaylistsInput"
	},
	PlaylistUpdateWithWhereUniqueWithoutWorkspaceInput:{
		where:"PlaylistWhereUniqueInput",
		data:"PlaylistUpdateWithoutWorkspaceInput"
	},
	PlaylistUpdateManyWithWhereWithoutWorkspaceInput:{
		where:"PlaylistScalarWhereInput",
		data:"PlaylistUpdateManyMutationInput"
	},
	WorkspaceUpdateToOneWithWhereWithoutAdBoardsInput:{
		where:"WorkspaceWhereInput",
		data:"WorkspaceUpdateWithoutAdBoardsInput"
	},
	AdBoardUpdateToOneWithWhereWithoutAdBoardGroupInput:{
		where:"AdBoardWhereInput",
		data:"AdBoardUpdateWithoutAdBoardGroupInput"
	},
	AdBoardGroupUpdateToOneWithWhereWithoutPisignagePlayersInput:{
		where:"AdBoardGroupWhereInput",
		data:"AdBoardGroupUpdateWithoutPisignagePlayersInput"
	},
	PlayerUpdateWithWhereUniqueWithoutAdBoardsInput:{
		where:"PlayerWhereUniqueInput",
		data:"PlayerUpdateWithoutAdBoardsInput"
	},
	PlayerUpdateManyWithWhereWithoutAdBoardsInput:{
		where:"PlayerScalarWhereInput",
		data:"PlayerUpdateManyMutationInput"
	},
	AdBoardUpdateWithWhereUniqueWithoutWorkspaceInput:{
		where:"AdBoardWhereUniqueInput",
		data:"AdBoardUpdateWithoutWorkspaceInput"
	},
	AdBoardUpdateManyWithWhereWithoutWorkspaceInput:{
		where:"AdBoardScalarWhereInput",
		data:"AdBoardUpdateManyMutationInput"
	},
	WorkspaceUpdateToOneWithWhereWithoutMembershipsInput:{
		where:"WorkspaceWhereInput",
		data:"WorkspaceUpdateWithoutMembershipsInput"
	},
	WorkspaceRoleUpdateOneRequiredWithoutMembershipsNestedInput:{
		create:"WorkspaceRoleCreateWithoutMembershipsInput",
		connectOrCreate:"WorkspaceRoleCreateOrConnectWithoutMembershipsInput",
		upsert:"WorkspaceRoleUpsertWithoutMembershipsInput",
		connect:"WorkspaceRoleWhereUniqueInput",
		update:"WorkspaceRoleUpdateToOneWithWhereWithoutMembershipsInput"
	},
	WorkspaceRoleUpsertWithoutMembershipsInput:{
		update:"WorkspaceRoleUpdateWithoutMembershipsInput",
		create:"WorkspaceRoleCreateWithoutMembershipsInput",
		where:"WorkspaceRoleWhereInput"
	},
	WorkspaceRoleUpdateWithoutMembershipsInput:{
		permissions:"Permission",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		workspace:"WorkspaceUpdateOneWithoutWorkspaceRolesNestedInput"
	},
	WorkspaceUpdateOneWithoutWorkspaceRolesNestedInput:{
		create:"WorkspaceCreateWithoutWorkspaceRolesInput",
		connectOrCreate:"WorkspaceCreateOrConnectWithoutWorkspaceRolesInput",
		upsert:"WorkspaceUpsertWithoutWorkspaceRolesInput",
		delete:"WorkspaceWhereInput",
		connect:"WorkspaceWhereUniqueInput",
		update:"WorkspaceUpdateToOneWithWhereWithoutWorkspaceRolesInput"
	},
	WorkspaceUpsertWithoutWorkspaceRolesInput:{
		update:"WorkspaceUpdateWithoutWorkspaceRolesInput",
		create:"WorkspaceCreateWithoutWorkspaceRolesInput",
		where:"WorkspaceWhereInput"
	},
	WorkspaceUpdateWithoutWorkspaceRolesInput:{
		type:"WorkspaceType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		owner:"UserUpdateOneWithoutOwnedWorkspacesNestedInput",
		memberships:"MembershipUpdateManyWithoutWorkspaceNestedInput",
		adBoards:"AdBoardUpdateManyWithoutWorkspaceNestedInput",
		playlists:"PlaylistUpdateManyWithoutWorkspaceNestedInput",
		adBoardGroups:"AdBoardGroupUpdateManyWithoutWorkspaceNestedInput",
		audits:"AuditUpdateManyWithoutWorkspaceNestedInput",
		bookings:"BookingUpdateManyWithoutWorkspaceNestedInput",
		files:"FileUpdateManyWithoutWorkspaceNestedInput",
		players:"PlayerUpdateManyWithoutWorkspaceNestedInput",
		transactions:"TransactionUpdateManyWithoutWorkspaceNestedInput",
		activeSubscription:"SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput",
		cameraServers:"CameraServerUpdateManyWithoutWorkspaceNestedInput",
		webhookLogs:"WebhookLogUpdateManyWithoutWorkspaceNestedInput",
		leads:"UserUpdateManyWithoutLeadWorspacesNestedInput"
	},
	WorkspaceUpdateToOneWithWhereWithoutWorkspaceRolesInput:{
		where:"WorkspaceWhereInput",
		data:"WorkspaceUpdateWithoutWorkspaceRolesInput"
	},
	WorkspaceRoleUpdateToOneWithWhereWithoutMembershipsInput:{
		where:"WorkspaceRoleWhereInput",
		data:"WorkspaceRoleUpdateWithoutMembershipsInput"
	},
	MembershipUpdateWithWhereUniqueWithoutUserInput:{
		where:"MembershipWhereUniqueInput",
		data:"MembershipUpdateWithoutUserInput"
	},
	MembershipUpdateManyWithWhereWithoutUserInput:{
		where:"MembershipScalarWhereInput",
		data:"MembershipUpdateManyMutationInput"
	},
	UserUpdateToOneWithWhereWithoutChangelogsInput:{
		where:"UserWhereInput",
		data:"UserUpdateWithoutChangelogsInput"
	},
	ChangelogUpdateWithWhereUniqueWithoutBookingInput:{
		where:"ChangelogWhereUniqueInput",
		data:"ChangelogUpdateWithoutBookingInput"
	},
	ChangelogUpdateManyWithWhereWithoutBookingInput:{
		where:"ChangelogScalarWhereInput",
		data:"ChangelogUpdateManyMutationInput"
	},
	ChangelogScalarWhereInput:{
		AND:"ChangelogScalarWhereInput",
		OR:"ChangelogScalarWhereInput",
		NOT:"ChangelogScalarWhereInput",
		id:"StringFilter",
		createdAt:"DateTimeFilter",
		message:"StringFilter",
		action:"StringFilter",
		bookingId:"StringNullableFilter",
		userId:"StringFilter",
		updates:"JsonNullableFilter"
	},
	ChangelogUpdateManyMutationInput:{
		createdAt:"DateTime",
		updates:"JSON"
	},
	BookingUpdateWithWhereUniqueWithoutSharedUsersInput:{
		where:"BookingWhereUniqueInput",
		data:"BookingUpdateWithoutSharedUsersInput"
	},
	BookingUpdateManyWithWhereWithoutSharedUsersInput:{
		where:"BookingScalarWhereInput",
		data:"BookingUpdateManyMutationInput"
	},
	UserUpdateToOneWithWhereWithoutAdBoardsInput:{
		where:"UserWhereInput",
		data:"UserUpdateWithoutAdBoardsInput"
	},
	AdBoardUpdateToOneWithWhereWithoutBookingsInput:{
		where:"AdBoardWhereInput",
		data:"AdBoardUpdateWithoutBookingsInput"
	},
	BookingUpdateWithWhereUniqueWithoutBusinessCategoryInput:{
		where:"BookingWhereUniqueInput",
		data:"BookingUpdateWithoutBusinessCategoryInput"
	},
	BookingUpdateManyWithWhereWithoutBusinessCategoryInput:{
		where:"BookingScalarWhereInput",
		data:"BookingUpdateManyMutationInput"
	},
	BusinessCategoryUpdateToOneWithWhereWithoutCampaignsInput:{
		where:"BusinessCategoryWhereInput",
		data:"BusinessCategoryUpdateWithoutCampaignsInput"
	},
	CampaignUpdateWithWhereUniqueWithoutUserInput:{
		where:"CampaignWhereUniqueInput",
		data:"CampaignUpdateWithoutUserInput"
	},
	CampaignUpdateManyWithWhereWithoutUserInput:{
		where:"CampaignScalarWhereInput",
		data:"CampaignUpdateManyMutationInput"
	},
	UserUpdateToOneWithWhereWithoutMembershipsInput:{
		where:"UserWhereInput",
		data:"UserUpdateWithoutMembershipsInput"
	},
	MembershipUpdateWithWhereUniqueWithoutWorkspaceInput:{
		where:"MembershipWhereUniqueInput",
		data:"MembershipUpdateWithoutWorkspaceInput"
	},
	MembershipUpdateManyWithWhereWithoutWorkspaceInput:{
		where:"MembershipScalarWhereInput",
		data:"MembershipUpdateManyMutationInput"
	},
	WorkspaceUpdateToOneWithWhereWithoutAuditsInput:{
		where:"WorkspaceWhereInput",
		data:"WorkspaceUpdateWithoutAuditsInput"
	},
	AuditUpdateWithWhereUniqueWithoutUserInput:{
		where:"AuditWhereUniqueInput",
		data:"AuditUpdateWithoutUserInput"
	},
	AuditUpdateManyWithWhereWithoutUserInput:{
		where:"AuditScalarWhereInput",
		data:"AuditUpdateManyMutationInput"
	},
	UserUpdateToOneWithWhereWithoutOrdersInput:{
		where:"UserWhereInput",
		data:"UserUpdateWithoutOrdersInput"
	},
	OrderUpdateWithWhereUniqueWithoutAdBoardInput:{
		where:"OrderWhereUniqueInput",
		data:"OrderUpdateWithoutAdBoardInput"
	},
	OrderUpdateManyWithWhereWithoutAdBoardInput:{
		where:"OrderScalarWhereInput",
		data:"OrderUpdateManyMutationInput"
	},
	OrderScalarWhereInput:{
		AND:"OrderScalarWhereInput",
		OR:"OrderScalarWhereInput",
		NOT:"OrderScalarWhereInput",
		id:"StringFilter",
		adBoardId:"StringFilter",
		createdDate:"DateTimeFilter",
		createdByUserId:"StringNullableFilter",
		origin:"StringFilter",
		paymentId:"StringNullableFilter"
	},
	OrderUpdateManyMutationInput:{
		createdDate:"DateTime",
		priceSummary:"PriceSummaryCreateInput",
		selectedDate:"OrderSelectedDateCreateInput"
	},
	AdBoardUpdateWithWhereUniqueWithoutCreatedByInput:{
		where:"AdBoardWhereUniqueInput",
		data:"AdBoardUpdateWithoutCreatedByInput"
	},
	AdBoardUpdateManyWithWhereWithoutCreatedByInput:{
		where:"AdBoardScalarWhereInput",
		data:"AdBoardUpdateManyMutationInput"
	},
	UserUpdateToOneWithWhereWithoutBookingsInput:{
		where:"UserWhereInput",
		data:"UserUpdateWithoutBookingsInput"
	},
	BookingUpdateToOneWithWhereWithoutChangelogsInput:{
		where:"BookingWhereInput",
		data:"BookingUpdateWithoutChangelogsInput"
	},
	ChangelogUpdateWithWhereUniqueWithoutUserInput:{
		where:"ChangelogWhereUniqueInput",
		data:"ChangelogUpdateWithoutUserInput"
	},
	ChangelogUpdateManyWithWhereWithoutUserInput:{
		where:"ChangelogScalarWhereInput",
		data:"ChangelogUpdateManyMutationInput"
	},
	UserUpdateToOneWithWhereWithoutOfflineBookingAdboardsInput:{
		where:"UserWhereInput",
		data:"UserUpdateWithoutOfflineBookingAdboardsInput"
	},
	AdBoardUpdateToOneWithWhereWithoutOrdersInput:{
		where:"AdBoardWhereInput",
		data:"AdBoardUpdateWithoutOrdersInput"
	},
	OrderUpdateWithWhereUniqueWithoutCreatedByInput:{
		where:"OrderWhereUniqueInput",
		data:"OrderUpdateWithoutCreatedByInput"
	},
	OrderUpdateManyWithWhereWithoutCreatedByInput:{
		where:"OrderScalarWhereInput",
		data:"OrderUpdateManyMutationInput"
	},
	UserUpdateToOneWithWhereWithoutOwnedWorkspacesInput:{
		where:"UserWhereInput",
		data:"UserUpdateWithoutOwnedWorkspacesInput"
	},
	WorkspaceUpdateToOneWithWhereWithoutFilesInput:{
		where:"WorkspaceWhereInput",
		data:"WorkspaceUpdateWithoutFilesInput"
	},
	FileUpdateWithWhereUniqueWithoutUpdatedByInput:{
		where:"FileWhereUniqueInput",
		data:"FileUpdateWithoutUpdatedByInput"
	},
	FileUpdateManyWithWhereWithoutUpdatedByInput:{
		where:"FileScalarWhereInput",
		data:"FileUpdateManyMutationInput"
	},
	UserUpdateToOneWithWhereWithoutCreatedFilesInput:{
		where:"UserWhereInput",
		data:"UserUpdateWithoutCreatedFilesInput"
	},
	FileUpdateWithWhereUniqueWithoutProofBookingInput:{
		where:"FileWhereUniqueInput",
		data:"FileUpdateWithoutProofBookingInput"
	},
	FileUpdateManyWithWhereWithoutProofBookingInput:{
		where:"FileScalarWhereInput",
		data:"FileUpdateManyMutationInput"
	},
	BookingUpdateWithWhereUniqueWithoutAssetsInput:{
		where:"BookingWhereUniqueInput",
		data:"BookingUpdateWithoutAssetsInput"
	},
	BookingUpdateManyWithWhereWithoutAssetsInput:{
		where:"BookingScalarWhereInput",
		data:"BookingUpdateManyMutationInput"
	},
	FileUpdateWithWhereUniqueWithoutCreatedByInput:{
		where:"FileWhereUniqueInput",
		data:"FileUpdateWithoutCreatedByInput"
	},
	FileUpdateManyWithWhereWithoutCreatedByInput:{
		where:"FileScalarWhereInput",
		data:"FileUpdateManyMutationInput"
	},
	UserUpdateToOneWithWhereWithoutCampaignsInput:{
		where:"UserWhereInput",
		data:"UserUpdateWithoutCampaignsInput"
	},
	CampaignUpdateWithWhereUniqueWithoutBusinessCategoryInput:{
		where:"CampaignWhereUniqueInput",
		data:"CampaignUpdateWithoutBusinessCategoryInput"
	},
	CampaignUpdateManyWithWhereWithoutBusinessCategoryInput:{
		where:"CampaignScalarWhereInput",
		data:"CampaignUpdateManyMutationInput"
	},
	BusinessCategoryUpdateToOneWithWhereWithoutBookingsInput:{
		where:"BusinessCategoryWhereInput",
		data:"BusinessCategoryUpdateWithoutBookingsInput"
	},
	BookingUpdateWithWhereUniqueWithoutUserInput:{
		where:"BookingWhereUniqueInput",
		data:"BookingUpdateWithoutUserInput"
	},
	BookingUpdateManyWithWhereWithoutUserInput:{
		where:"BookingScalarWhereInput",
		data:"BookingUpdateManyMutationInput"
	},
	UserUpdateToOneWithWhereWithoutUpdatedFilesInput:{
		where:"UserWhereInput",
		data:"UserUpdateWithoutUpdatedFilesInput"
	},
	FileUpdateWithWhereUniqueWithoutBookingsInput:{
		where:"FileWhereUniqueInput",
		data:"FileUpdateWithoutBookingsInput"
	},
	FileUpdateManyWithWhereWithoutBookingsInput:{
		where:"FileScalarWhereInput",
		data:"FileUpdateManyMutationInput"
	},
	BookingUpdateWithWhereUniqueWithoutAdBoardInput:{
		where:"BookingWhereUniqueInput",
		data:"BookingUpdateWithoutAdBoardInput"
	},
	BookingUpdateManyWithWhereWithoutAdBoardInput:{
		where:"BookingScalarWhereInput",
		data:"BookingUpdateManyMutationInput"
	},
	AdBoardUpdateWithWhereUniqueWithoutCategoryInput:{
		where:"AdBoardWhereUniqueInput",
		data:"AdBoardUpdateWithoutCategoryInput"
	},
	AdBoardUpdateManyWithWhereWithoutCategoryInput:{
		where:"AdBoardScalarWhereInput",
		data:"AdBoardUpdateManyMutationInput"
	},
	BusinessCategoryUpdateWithWhereUniqueWithoutRestrictedByAdBoardsInput:{
		where:"BusinessCategoryWhereUniqueInput",
		data:"BusinessCategoryUpdateWithoutRestrictedByAdBoardsInput"
	},
	BusinessCategoryUpdateManyWithWhereWithoutRestrictedByAdBoardsInput:{
		where:"BusinessCategoryScalarWhereInput",
		data:"BusinessCategoryUpdateManyMutationInput"
	},
	BusinessCategoryScalarWhereInput:{
		AND:"BusinessCategoryScalarWhereInput",
		OR:"BusinessCategoryScalarWhereInput",
		NOT:"BusinessCategoryScalarWhereInput",
		id:"StringFilter",
		name:"StringFilter",
		description:"StringNullableFilter",
		restrictedByAdBoardIds:"StringNullableListFilter"
	},
	BusinessCategoryUpdateManyMutationInput:{

	},
	AdBoardUpdateWithWhereUniqueWithoutLocationCategoryInput:{
		where:"AdBoardWhereUniqueInput",
		data:"AdBoardUpdateWithoutLocationCategoryInput"
	},
	AdBoardUpdateManyWithWhereWithoutLocationCategoryInput:{
		where:"AdBoardScalarWhereInput",
		data:"AdBoardUpdateManyMutationInput"
	},
	LocationCategoryUpdateToOneWithWhereWithoutLocationSubCategoryInput:{
		where:"LocationCategoryWhereInput",
		data:"LocationCategoryUpdateWithoutLocationSubCategoryInput"
	},
	LocationSubCategoryUpdateToOneWithWhereWithoutAdBoardsInput:{
		where:"LocationSubCategoryWhereInput",
		data:"LocationSubCategoryUpdateWithoutAdBoardsInput"
	},
	AdBoardUpdateWithWhereUniqueWithoutRestrictedBusinesInput:{
		where:"AdBoardWhereUniqueInput",
		data:"AdBoardUpdateWithoutRestrictedBusinesInput"
	},
	AdBoardUpdateManyWithWhereWithoutRestrictedBusinesInput:{
		where:"AdBoardScalarWhereInput",
		data:"AdBoardUpdateManyMutationInput"
	},
	BusinessCategoryUpdateToOneWithWhereWithoutAdBoardsInput:{
		where:"BusinessCategoryWhereInput",
		data:"BusinessCategoryUpdateWithoutAdBoardsInput"
	},
	AdBoardUpdateWithWhereUniqueWithoutLocationSubCategoryInput:{
		where:"AdBoardWhereUniqueInput",
		data:"AdBoardUpdateWithoutLocationSubCategoryInput"
	},
	AdBoardUpdateManyWithWhereWithoutLocationSubCategoryInput:{
		where:"AdBoardScalarWhereInput",
		data:"AdBoardUpdateManyMutationInput"
	},
	LocationSubCategoryUpdateWithWhereUniqueWithoutLocationCategoryInput:{
		where:"LocationSubCategoryWhereUniqueInput",
		data:"LocationSubCategoryUpdateWithoutLocationCategoryInput"
	},
	LocationSubCategoryUpdateManyWithWhereWithoutLocationCategoryInput:{
		where:"LocationSubCategoryScalarWhereInput",
		data:"LocationSubCategoryUpdateManyMutationInput"
	},
	LocationSubCategoryScalarWhereInput:{
		AND:"LocationSubCategoryScalarWhereInput",
		OR:"LocationSubCategoryScalarWhereInput",
		NOT:"LocationSubCategoryScalarWhereInput",
		id:"StringFilter",
		name:"StringFilter",
		locationCategoryId:"StringNullableFilter"
	},
	LocationSubCategoryUpdateManyMutationInput:{

	},
	LocationCategoryUpdateToOneWithWhereWithoutAdBoardsInput:{
		where:"LocationCategoryWhereInput",
		data:"LocationCategoryUpdateWithoutAdBoardsInput"
	},
	AdBoardGroupCreateManyInput:{
		schedules:"ScheduleCreateInput",
		fitImage:"FitType",
		fitVideo:"FitType"
	},
	AdBoardGroupCreateInput:{
		schedules:"ScheduleCreateInput",
		fitImage:"FitType",
		fitVideo:"FitType",
		adBoard:"AdBoardCreateNestedOneWithoutAdBoardGroupInput",
		scheduledPlaylists:"PlaylistCreateNestedManyWithoutAdBoardGroupsInput",
		adsPlaylist:"PlaylistCreateNestedOneWithoutAdBoardGroupsForAdsInput",
		pisignagePlayers:"PlayerCreateNestedManyWithoutAdBoardGroupInput",
		workspace:"WorkspaceCreateNestedOneWithoutAdBoardGroupsInput"
	},
	AdBoardGroupUpdateInput:{
		schedules:"ScheduleCreateInput",
		fitImage:"FitType",
		fitVideo:"FitType",
		adBoard:"AdBoardUpdateOneWithoutAdBoardGroupNestedInput",
		scheduledPlaylists:"PlaylistUpdateManyWithoutAdBoardGroupsNestedInput",
		adsPlaylist:"PlaylistUpdateOneWithoutAdBoardGroupsForAdsNestedInput",
		pisignagePlayers:"PlayerUpdateManyWithoutAdBoardGroupNestedInput",
		workspace:"WorkspaceUpdateOneRequiredWithoutAdBoardGroupsNestedInput"
	},
	AdBoardTypeCreateManyInput:{

	},
	AdBoardTypeCreateInput:{
		adBoards:"AdBoardCreateNestedManyWithoutTypeInput"
	},
	AdBoardCreateNestedManyWithoutTypeInput:{
		create:"AdBoardCreateWithoutTypeInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutTypeInput",
		createMany:"AdBoardCreateManyTypeInputEnvelope",
		connect:"AdBoardWhereUniqueInput"
	},
	AdBoardCreateWithoutTypeInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryCreateNestedOneWithoutAdBoardsInput",
		locationSubCategory:"LocationSubCategoryCreateNestedOneWithoutAdBoardsInput",
		category:"BusinessCategoryCreateNestedOneWithoutAdBoardsInput",
		restrictedBusines:"BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput",
		bookings:"BookingCreateNestedManyWithoutAdBoardInput",
		orders:"OrderCreateNestedManyWithoutAdBoardInput",
		slotConfigurations:"SlotConfigurationCreateNestedManyWithoutAdBoardInput",
		offlineBookingsContact:"UserCreateNestedOneWithoutOfflineBookingAdboardsInput",
		createdBy:"UserCreateNestedOneWithoutAdBoardsInput",
		workspace:"WorkspaceCreateNestedOneWithoutAdBoardsInput",
		players:"PlayerCreateNestedManyWithoutAdBoardsInput",
		adBoardGroup:"AdBoardGroupCreateNestedOneWithoutAdBoardInput",
		cameras:"CameraCreateNestedManyWithoutAdBoardInput",
		publishedVersion:"AdBoardCreateNestedOneWithoutDraftAdboardInput",
		draftAdboard:"AdBoardCreateNestedOneWithoutPublishedVersionInput",
		history:"AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput",
		archivedBy:"UserCreateNestedOneWithoutArchivedAdboardsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"
	},
	AdBoardCreateOrConnectWithoutTypeInput:{
		where:"AdBoardWhereUniqueInput",
		create:"AdBoardCreateWithoutTypeInput"
	},
	AdBoardCreateManyTypeInputEnvelope:{
		data:"AdBoardCreateManyTypeInput"
	},
	AdBoardCreateManyTypeInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime"
	},
	AdBoardTypeUpdateManyMutationInput:{

	},
	AdBoardTypeUpdateInput:{
		adBoards:"AdBoardUpdateManyWithoutTypeNestedInput"
	},
	AdBoardUpdateManyWithoutTypeNestedInput:{
		create:"AdBoardCreateWithoutTypeInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutTypeInput",
		upsert:"AdBoardUpsertWithWhereUniqueWithoutTypeInput",
		createMany:"AdBoardCreateManyTypeInputEnvelope",
		set:"AdBoardWhereUniqueInput",
		disconnect:"AdBoardWhereUniqueInput",
		delete:"AdBoardWhereUniqueInput",
		connect:"AdBoardWhereUniqueInput",
		update:"AdBoardUpdateWithWhereUniqueWithoutTypeInput",
		updateMany:"AdBoardUpdateManyWithWhereWithoutTypeInput",
		deleteMany:"AdBoardScalarWhereInput"
	},
	AdBoardUpsertWithWhereUniqueWithoutTypeInput:{
		where:"AdBoardWhereUniqueInput",
		update:"AdBoardUpdateWithoutTypeInput",
		create:"AdBoardCreateWithoutTypeInput"
	},
	AdBoardUpdateWithoutTypeInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryUpdateOneWithoutAdBoardsNestedInput",
		locationSubCategory:"LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput",
		category:"BusinessCategoryUpdateOneWithoutAdBoardsNestedInput",
		restrictedBusines:"BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput",
		bookings:"BookingUpdateManyWithoutAdBoardNestedInput",
		orders:"OrderUpdateManyWithoutAdBoardNestedInput",
		slotConfigurations:"SlotConfigurationUpdateManyWithoutAdBoardNestedInput",
		offlineBookingsContact:"UserUpdateOneWithoutOfflineBookingAdboardsNestedInput",
		createdBy:"UserUpdateOneWithoutAdBoardsNestedInput",
		workspace:"WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput",
		players:"PlayerUpdateManyWithoutAdBoardsNestedInput",
		adBoardGroup:"AdBoardGroupUpdateOneWithoutAdBoardNestedInput",
		cameras:"CameraUpdateManyWithoutAdBoardNestedInput",
		publishedVersion:"AdBoardUpdateOneWithoutDraftAdboardNestedInput",
		draftAdboard:"AdBoardUpdateOneWithoutPublishedVersionNestedInput",
		history:"AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput",
		archivedBy:"UserUpdateOneWithoutArchivedAdboardsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"
	},
	AdBoardUpdateWithWhereUniqueWithoutTypeInput:{
		where:"AdBoardWhereUniqueInput",
		data:"AdBoardUpdateWithoutTypeInput"
	},
	AdBoardUpdateManyWithWhereWithoutTypeInput:{
		where:"AdBoardScalarWhereInput",
		data:"AdBoardUpdateManyMutationInput"
	},
	AuditCreateManyInput:{
		createdAt:"DateTime",
		entity:"EntityCreateInput",
		updates:"JSON"
	},
	AuditCreateInput:{
		createdAt:"DateTime",
		entity:"EntityCreateInput",
		updates:"JSON",
		user:"UserCreateNestedOneWithoutAuditsInput",
		workspace:"WorkspaceCreateNestedOneWithoutAuditsInput"
	},
	AuditUpdateInput:{
		createdAt:"DateTime",
		entity:"EntityCreateInput",
		updates:"JSON",
		user:"UserUpdateOneRequiredWithoutAuditsNestedInput",
		workspace:"WorkspaceUpdateOneWithoutAuditsNestedInput"
	},
	BookingCreateManyInput:{
		bookingDate:"DateTime",
		bookingDetails:"BookingDetailsCreateInput",
		bookingStatus:"BookingStatus",
		paymentStatus:"PaymentStatus",
		deploymentStatus:"DeploymentStatus",
		contentApprovalStatus:"ContentApprovalStatus",
		selectedDates:"OrderSelectedDateCreateInput",
		payment:"BookingPaymentCreateInput",
		priceSummary:"PriceSummaryCreateInput",
		pisignage:"BookingPisignageDataCreateInput",
		refund:"BookingRefundCreateInput"
	},
	BookingCreateInput:{
		bookingDate:"DateTime",
		bookingDetails:"BookingDetailsCreateInput",
		bookingStatus:"BookingStatus",
		paymentStatus:"PaymentStatus",
		deploymentStatus:"DeploymentStatus",
		contentApprovalStatus:"ContentApprovalStatus",
		selectedDates:"OrderSelectedDateCreateInput",
		payment:"BookingPaymentCreateInput",
		priceSummary:"PriceSummaryCreateInput",
		pisignage:"BookingPisignageDataCreateInput",
		refund:"BookingRefundCreateInput",
		assets:"FileCreateNestedManyWithoutBookingsInput",
		businessCategory:"BusinessCategoryCreateNestedOneWithoutBookingsInput",
		proofs:"FileCreateNestedManyWithoutProofBookingInput",
		user:"UserCreateNestedOneWithoutBookingsInput",
		adBoard:"AdBoardCreateNestedOneWithoutBookingsInput",
		changelogs:"ChangelogCreateNestedManyWithoutBookingInput",
		workspace:"WorkspaceCreateNestedOneWithoutBookingsInput",
		campaign:"CampaignCreateNestedOneWithoutBookingsInput",
		payouts:"PayoutCreateNestedManyWithoutBookingInput",
		sharedUsers:"UserCreateNestedManyWithoutSharedBookingsInput",
		webhookLogs:"WebhookLogCreateNestedManyWithoutBookingInput",
		slotUsage:"SlotUsageCreateNestedManyWithoutBookingInput"
	},
	BookingUpdateInput:{
		bookingDate:"DateTime",
		bookingDetails:"BookingDetailsCreateInput",
		bookingStatus:"BookingStatus",
		paymentStatus:"PaymentStatus",
		deploymentStatus:"DeploymentStatus",
		contentApprovalStatus:"ContentApprovalStatus",
		selectedDates:"OrderSelectedDateCreateInput",
		payment:"BookingPaymentCreateInput",
		priceSummary:"PriceSummaryCreateInput",
		pisignage:"BookingPisignageDataCreateInput",
		refund:"BookingRefundCreateInput",
		assets:"FileUpdateManyWithoutBookingsNestedInput",
		businessCategory:"BusinessCategoryUpdateOneWithoutBookingsNestedInput",
		proofs:"FileUpdateManyWithoutProofBookingNestedInput",
		user:"UserUpdateOneRequiredWithoutBookingsNestedInput",
		adBoard:"AdBoardUpdateOneRequiredWithoutBookingsNestedInput",
		changelogs:"ChangelogUpdateManyWithoutBookingNestedInput",
		workspace:"WorkspaceUpdateOneWithoutBookingsNestedInput",
		campaign:"CampaignUpdateOneWithoutBookingsNestedInput",
		payouts:"PayoutUpdateManyWithoutBookingNestedInput",
		sharedUsers:"UserUpdateManyWithoutSharedBookingsNestedInput",
		webhookLogs:"WebhookLogUpdateManyWithoutBookingNestedInput",
		slotUsage:"SlotUsageUpdateManyWithoutBookingNestedInput"
	},
	BusinessCategoryCreateManyInput:{

	},
	BusinessCategoryCreateInput:{
		adBoards:"AdBoardCreateNestedManyWithoutCategoryInput",
		restrictedByAdBoards:"AdBoardCreateNestedManyWithoutRestrictedBusinesInput",
		campaigns:"CampaignCreateNestedManyWithoutBusinessCategoryInput",
		bookings:"BookingCreateNestedManyWithoutBusinessCategoryInput"
	},
	BusinessCategoryUpdateInput:{
		adBoards:"AdBoardUpdateManyWithoutCategoryNestedInput",
		restrictedByAdBoards:"AdBoardUpdateManyWithoutRestrictedBusinesNestedInput",
		campaigns:"CampaignUpdateManyWithoutBusinessCategoryNestedInput",
		bookings:"BookingUpdateManyWithoutBusinessCategoryNestedInput"
	},
	CameraCreateManyInput:{
		updatedAt:"DateTime"
	},
	CameraCreateInput:{
		updatedAt:"DateTime",
		cameraServer:"CameraServerCreateNestedOneWithoutCamerasInput",
		player:"PlayerCreateNestedOneWithoutCamerasInput",
		feeds:"CameraFeedCreateNestedManyWithoutCameraInput",
		adBoard:"AdBoardCreateNestedOneWithoutCamerasInput"
	},
	CameraUpdateInput:{
		updatedAt:"DateTime",
		cameraServer:"CameraServerUpdateOneRequiredWithoutCamerasNestedInput",
		player:"PlayerUpdateOneWithoutCamerasNestedInput",
		feeds:"CameraFeedUpdateManyWithoutCameraNestedInput",
		adBoard:"AdBoardUpdateOneWithoutCamerasNestedInput"
	},
	CameraFeedCreateManyInput:{
		timestamp:"DateTime",
		data:"CameraFeedDataCreateInput"
	},
	CameraFeedCreateInput:{
		timestamp:"DateTime",
		data:"CameraFeedDataCreateInput",
		camera:"CameraCreateNestedOneWithoutFeedsInput",
		cameraServer:"CameraServerCreateNestedOneWithoutFeedsInput"
	},
	CameraFeedUpdateInput:{
		timestamp:"DateTime",
		data:"CameraFeedDataCreateInput",
		camera:"CameraUpdateOneRequiredWithoutFeedsNestedInput",
		cameraServer:"CameraServerUpdateOneRequiredWithoutFeedsNestedInput"
	},
	CameraServerCreateManyInput:{

	},
	CameraServerCreateInput:{
		workspace:"WorkspaceCreateNestedOneWithoutCameraServersInput",
		cameras:"CameraCreateNestedManyWithoutCameraServerInput",
		feeds:"CameraFeedCreateNestedManyWithoutCameraServerInput"
	},
	CameraServerUpdateInput:{
		workspace:"WorkspaceUpdateOneWithoutCameraServersNestedInput",
		cameras:"CameraUpdateManyWithoutCameraServerNestedInput",
		feeds:"CameraFeedUpdateManyWithoutCameraServerNestedInput"
	},
	CampaignCreateManyInput:{
		createdAt:"DateTime",
		updatedAt:"DateTime",
		payment:"BookingPaymentCreateInput",
		paymentStatus:"PaymentStatus",
		priceSummary:"PriceSummaryCreateInput",
		selectedDates:"OrderSelectedDateCreateInput"
	},
	CampaignCreateInput:{
		createdAt:"DateTime",
		updatedAt:"DateTime",
		payment:"BookingPaymentCreateInput",
		paymentStatus:"PaymentStatus",
		priceSummary:"PriceSummaryCreateInput",
		selectedDates:"OrderSelectedDateCreateInput",
		user:"UserCreateNestedOneWithoutCampaignsInput",
		businessCategory:"BusinessCategoryCreateNestedOneWithoutCampaignsInput",
		bookings:"BookingCreateNestedManyWithoutCampaignInput",
		payments:"PaymentCreateNestedManyWithoutCampaignInput",
		assets:"FileCreateNestedManyWithoutCampaignsInput"
	},
	CampaignUpdateInput:{
		createdAt:"DateTime",
		updatedAt:"DateTime",
		payment:"BookingPaymentCreateInput",
		paymentStatus:"PaymentStatus",
		priceSummary:"PriceSummaryCreateInput",
		selectedDates:"OrderSelectedDateCreateInput",
		user:"UserUpdateOneRequiredWithoutCampaignsNestedInput",
		businessCategory:"BusinessCategoryUpdateOneRequiredWithoutCampaignsNestedInput",
		bookings:"BookingUpdateManyWithoutCampaignNestedInput",
		payments:"PaymentUpdateManyWithoutCampaignNestedInput",
		assets:"FileUpdateManyWithoutCampaignsNestedInput"
	},
	FileCreateManyInput:{
		meta:"FileMetaCreateInput",
		status:"FileStatus",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	FileCreateInput:{
		meta:"FileMetaCreateInput",
		status:"FileStatus",
		updatedAt:"DateTime",
		createdAt:"DateTime",
		updatedBy:"UserCreateNestedOneWithoutUpdatedFilesInput",
		createdBy:"UserCreateNestedOneWithoutCreatedFilesInput",
		bookings:"BookingCreateNestedManyWithoutAssetsInput",
		workspace:"WorkspaceCreateNestedOneWithoutFilesInput",
		proofBooking:"BookingCreateNestedOneWithoutProofsInput",
		campaigns:"CampaignCreateNestedManyWithoutAssetsInput"
	},
	FileUpdateInput:{
		meta:"FileMetaCreateInput",
		status:"FileStatus",
		updatedAt:"DateTime",
		createdAt:"DateTime",
		updatedBy:"UserUpdateOneWithoutUpdatedFilesNestedInput",
		createdBy:"UserUpdateOneRequiredWithoutCreatedFilesNestedInput",
		bookings:"BookingUpdateManyWithoutAssetsNestedInput",
		workspace:"WorkspaceUpdateOneWithoutFilesNestedInput",
		proofBooking:"BookingUpdateOneWithoutProofsNestedInput",
		campaigns:"CampaignUpdateManyWithoutAssetsNestedInput"
	},
	MembershipCreateManyInput:{
		createdAt:"DateTime"
	},
	MembershipCreateInput:{
		createdAt:"DateTime",
		user:"UserCreateNestedOneWithoutMembershipsInput",
		workspace:"WorkspaceCreateNestedOneWithoutMembershipsInput",
		role:"WorkspaceRoleCreateNestedOneWithoutMembershipsInput"
	},
	MembershipUpdateInput:{
		createdAt:"DateTime",
		user:"UserUpdateOneRequiredWithoutMembershipsNestedInput",
		workspace:"WorkspaceUpdateOneRequiredWithoutMembershipsNestedInput",
		role:"WorkspaceRoleUpdateOneRequiredWithoutMembershipsNestedInput"
	},
	NotificationCreateManyInput:{
		type:"NotificationType",
		metadata:"JSON",
		createdAt:"DateTime",
		readAt:"DateTime",
		channels:"NotificationChannel"
	},
	NotificationCreateInput:{
		type:"NotificationType",
		metadata:"JSON",
		createdAt:"DateTime",
		readAt:"DateTime",
		channels:"NotificationChannel",
		recipient:"UserCreateNestedOneWithoutNotificationsInput"
	},
	UserCreateNestedOneWithoutNotificationsInput:{
		create:"UserCreateWithoutNotificationsInput",
		connectOrCreate:"UserCreateOrConnectWithoutNotificationsInput",
		connect:"UserWhereUniqueInput"
	},
	UserCreateWithoutNotificationsInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingCreateNestedManyWithoutUserInput",
		createdFiles:"FileCreateNestedManyWithoutCreatedByInput",
		updatedFiles:"FileCreateNestedManyWithoutUpdatedByInput",
		orders:"OrderCreateNestedManyWithoutCreatedByInput",
		changelogs:"ChangelogCreateNestedManyWithoutUserInput",
		adBoards:"AdBoardCreateNestedManyWithoutCreatedByInput",
		audits:"AuditCreateNestedManyWithoutUserInput",
		campaigns:"CampaignCreateNestedManyWithoutUserInput",
		sharedBookings:"BookingCreateNestedManyWithoutSharedUsersInput",
		memberships:"MembershipCreateNestedManyWithoutUserInput",
		publishAdboardVersion:"AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput",
		offlineBookingAdboards:"AdBoardCreateNestedManyWithoutOfflineBookingsContactInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutUserInput",
		archivedAdboards:"AdBoardCreateNestedManyWithoutArchivedByInput",
		ownedWorkspaces:"WorkspaceCreateNestedManyWithoutOwnerInput",
		leadWorspaces:"WorkspaceCreateNestedManyWithoutLeadsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutUserInput",
		userPositions:"UserPositionCreateNestedManyWithoutUserInput"
	},
	UserCreateOrConnectWithoutNotificationsInput:{
		where:"UserWhereUniqueInput",
		create:"UserCreateWithoutNotificationsInput"
	},
	NotificationUpdateInput:{
		type:"NotificationType",
		metadata:"JSON",
		createdAt:"DateTime",
		readAt:"DateTime",
		channels:"NotificationChannel",
		recipient:"UserUpdateOneRequiredWithoutNotificationsNestedInput"
	},
	UserUpdateOneRequiredWithoutNotificationsNestedInput:{
		create:"UserCreateWithoutNotificationsInput",
		connectOrCreate:"UserCreateOrConnectWithoutNotificationsInput",
		upsert:"UserUpsertWithoutNotificationsInput",
		connect:"UserWhereUniqueInput",
		update:"UserUpdateToOneWithWhereWithoutNotificationsInput"
	},
	UserUpsertWithoutNotificationsInput:{
		update:"UserUpdateWithoutNotificationsInput",
		create:"UserCreateWithoutNotificationsInput",
		where:"UserWhereInput"
	},
	UserUpdateWithoutNotificationsInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingUpdateManyWithoutUserNestedInput",
		createdFiles:"FileUpdateManyWithoutCreatedByNestedInput",
		updatedFiles:"FileUpdateManyWithoutUpdatedByNestedInput",
		orders:"OrderUpdateManyWithoutCreatedByNestedInput",
		changelogs:"ChangelogUpdateManyWithoutUserNestedInput",
		adBoards:"AdBoardUpdateManyWithoutCreatedByNestedInput",
		audits:"AuditUpdateManyWithoutUserNestedInput",
		campaigns:"CampaignUpdateManyWithoutUserNestedInput",
		sharedBookings:"BookingUpdateManyWithoutSharedUsersNestedInput",
		memberships:"MembershipUpdateManyWithoutUserNestedInput",
		publishAdboardVersion:"AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput",
		offlineBookingAdboards:"AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutUserNestedInput",
		archivedAdboards:"AdBoardUpdateManyWithoutArchivedByNestedInput",
		ownedWorkspaces:"WorkspaceUpdateManyWithoutOwnerNestedInput",
		leadWorspaces:"WorkspaceUpdateManyWithoutLeadsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutUserNestedInput",
		userPositions:"UserPositionUpdateManyWithoutUserNestedInput"
	},
	UserUpdateToOneWithWhereWithoutNotificationsInput:{
		where:"UserWhereInput",
		data:"UserUpdateWithoutNotificationsInput"
	},
	OrderCreateManyInput:{
		createdDate:"DateTime",
		priceSummary:"PriceSummaryCreateInput",
		selectedDate:"OrderSelectedDateCreateInput"
	},
	OrderCreateInput:{
		createdDate:"DateTime",
		priceSummary:"PriceSummaryCreateInput",
		selectedDate:"OrderSelectedDateCreateInput",
		adBoard:"AdBoardCreateNestedOneWithoutOrdersInput",
		createdBy:"UserCreateNestedOneWithoutOrdersInput"
	},
	OrderUpdateInput:{
		createdDate:"DateTime",
		priceSummary:"PriceSummaryCreateInput",
		selectedDate:"OrderSelectedDateCreateInput",
		adBoard:"AdBoardUpdateOneRequiredWithoutOrdersNestedInput",
		createdBy:"UserUpdateOneWithoutOrdersNestedInput"
	},
	PlayerCreateManyInput:{
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackDetails:"PlaybackTimeCreateInput",
		aspectRatioType:"AspectRatioType",
		map:"MapCreateInput"
	},
	PlayerCreateInput:{
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackDetails:"PlaybackTimeCreateInput",
		aspectRatioType:"AspectRatioType",
		map:"MapCreateInput",
		adBoardGroup:"AdBoardGroupCreateNestedOneWithoutPisignagePlayersInput",
		workspace:"WorkspaceCreateNestedOneWithoutPlayersInput",
		status:"PlayerUptimeStatusCreateNestedManyWithoutPlayerInput",
		cameras:"CameraCreateNestedManyWithoutPlayerInput",
		adBoards:"AdBoardCreateNestedManyWithoutPlayersInput"
	},
	PlayerUpdateInput:{
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackDetails:"PlaybackTimeCreateInput",
		aspectRatioType:"AspectRatioType",
		map:"MapCreateInput",
		adBoardGroup:"AdBoardGroupUpdateOneWithoutPisignagePlayersNestedInput",
		workspace:"WorkspaceUpdateOneWithoutPlayersNestedInput",
		status:"PlayerUptimeStatusUpdateManyWithoutPlayerNestedInput",
		cameras:"CameraUpdateManyWithoutPlayerNestedInput",
		adBoards:"AdBoardUpdateManyWithoutPlayersNestedInput"
	},
	PlaylistCreateManyInput:{
		assets:"PisignageFileCreateInput",
		layout:"ScreenLayout"
	},
	PlaylistCreateInput:{
		assets:"PisignageFileCreateInput",
		layout:"ScreenLayout",
		footerPlaylist:"PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput",
		sidebarPlaylist:"PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput",
		adBoardGroupsForAds:"AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput",
		adBoardGroups:"AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput",
		workspace:"WorkspaceCreateNestedOneWithoutPlaylistsInput",
		footerParentPlaylists:"PlaylistCreateNestedManyWithoutFooterPlaylistInput",
		sidebarParentPlaylists:"PlaylistCreateNestedManyWithoutSidebarPlaylistInput"
	},
	PlaylistUpdateInput:{
		assets:"PisignageFileCreateInput",
		layout:"ScreenLayout",
		footerPlaylist:"PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput",
		sidebarPlaylist:"PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput",
		adBoardGroupsForAds:"AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput",
		adBoardGroups:"AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput",
		workspace:"WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput",
		footerParentPlaylists:"PlaylistUpdateManyWithoutFooterPlaylistNestedInput",
		sidebarParentPlaylists:"PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"
	},
	SlotConfigurationCreateManyInput:{
		date:"DateTime"
	},
	SlotConfigurationCreateInput:{
		date:"DateTime",
		adBoard:"AdBoardCreateNestedOneWithoutSlotConfigurationsInput"
	},
	AdBoardCreateNestedOneWithoutSlotConfigurationsInput:{
		create:"AdBoardCreateWithoutSlotConfigurationsInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutSlotConfigurationsInput",
		connect:"AdBoardWhereUniqueInput"
	},
	AdBoardCreateWithoutSlotConfigurationsInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryCreateNestedOneWithoutAdBoardsInput",
		locationSubCategory:"LocationSubCategoryCreateNestedOneWithoutAdBoardsInput",
		type:"AdBoardTypeCreateNestedOneWithoutAdBoardsInput",
		category:"BusinessCategoryCreateNestedOneWithoutAdBoardsInput",
		restrictedBusines:"BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput",
		bookings:"BookingCreateNestedManyWithoutAdBoardInput",
		orders:"OrderCreateNestedManyWithoutAdBoardInput",
		offlineBookingsContact:"UserCreateNestedOneWithoutOfflineBookingAdboardsInput",
		createdBy:"UserCreateNestedOneWithoutAdBoardsInput",
		workspace:"WorkspaceCreateNestedOneWithoutAdBoardsInput",
		players:"PlayerCreateNestedManyWithoutAdBoardsInput",
		adBoardGroup:"AdBoardGroupCreateNestedOneWithoutAdBoardInput",
		cameras:"CameraCreateNestedManyWithoutAdBoardInput",
		publishedVersion:"AdBoardCreateNestedOneWithoutDraftAdboardInput",
		draftAdboard:"AdBoardCreateNestedOneWithoutPublishedVersionInput",
		history:"AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput",
		archivedBy:"UserCreateNestedOneWithoutArchivedAdboardsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"
	},
	AdBoardCreateOrConnectWithoutSlotConfigurationsInput:{
		where:"AdBoardWhereUniqueInput",
		create:"AdBoardCreateWithoutSlotConfigurationsInput"
	},
	SlotConfigurationUpdateInput:{
		date:"DateTime",
		adBoard:"AdBoardUpdateOneRequiredWithoutSlotConfigurationsNestedInput"
	},
	AdBoardUpdateOneRequiredWithoutSlotConfigurationsNestedInput:{
		create:"AdBoardCreateWithoutSlotConfigurationsInput",
		connectOrCreate:"AdBoardCreateOrConnectWithoutSlotConfigurationsInput",
		upsert:"AdBoardUpsertWithoutSlotConfigurationsInput",
		connect:"AdBoardWhereUniqueInput",
		update:"AdBoardUpdateToOneWithWhereWithoutSlotConfigurationsInput"
	},
	AdBoardUpsertWithoutSlotConfigurationsInput:{
		update:"AdBoardUpdateWithoutSlotConfigurationsInput",
		create:"AdBoardCreateWithoutSlotConfigurationsInput",
		where:"AdBoardWhereInput"
	},
	AdBoardUpdateWithoutSlotConfigurationsInput:{
		map:"MapCreateInput",
		aboutSpecifications:"SpecificationCreateInput",
		specifications:"SpecificationCreateInput",
		resolution:"ResolutionCreateInput",
		screenSize:"ScreenSizeCreateInput",
		playbackTimes:"PlaybackTimeCreateInput",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		webhooks:"WebhookCreateInput",
		createdAt:"DateTime",
		additionalData:"JSON",
		lastPublishedAt:"DateTime",
		archivedAt:"DateTime",
		updatedAt:"DateTime",
		locationCategory:"LocationCategoryUpdateOneWithoutAdBoardsNestedInput",
		locationSubCategory:"LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput",
		type:"AdBoardTypeUpdateOneWithoutAdBoardsNestedInput",
		category:"BusinessCategoryUpdateOneWithoutAdBoardsNestedInput",
		restrictedBusines:"BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput",
		bookings:"BookingUpdateManyWithoutAdBoardNestedInput",
		orders:"OrderUpdateManyWithoutAdBoardNestedInput",
		offlineBookingsContact:"UserUpdateOneWithoutOfflineBookingAdboardsNestedInput",
		createdBy:"UserUpdateOneWithoutAdBoardsNestedInput",
		workspace:"WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput",
		players:"PlayerUpdateManyWithoutAdBoardsNestedInput",
		adBoardGroup:"AdBoardGroupUpdateOneWithoutAdBoardNestedInput",
		cameras:"CameraUpdateManyWithoutAdBoardNestedInput",
		publishedVersion:"AdBoardUpdateOneWithoutDraftAdboardNestedInput",
		draftAdboard:"AdBoardUpdateOneWithoutPublishedVersionNestedInput",
		history:"AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput",
		archivedBy:"UserUpdateOneWithoutArchivedAdboardsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"
	},
	AdBoardUpdateToOneWithWhereWithoutSlotConfigurationsInput:{
		where:"AdBoardWhereInput",
		data:"AdBoardUpdateWithoutSlotConfigurationsInput"
	},
	SubscriptionCreateManyInput:{
		startDate:"DateTime",
		endDate:"DateTime",
		cancellationDetails:"CancellationDetailsCreateInput"
	},
	SubscriptionCreateInput:{
		startDate:"DateTime",
		endDate:"DateTime",
		cancellationDetails:"CancellationDetailsCreateInput",
		activeWorkspace:"WorkspaceCreateNestedOneWithoutActiveSubscriptionInput"
	},
	WorkspaceCreateNestedOneWithoutActiveSubscriptionInput:{
		create:"WorkspaceCreateWithoutActiveSubscriptionInput",
		connectOrCreate:"WorkspaceCreateOrConnectWithoutActiveSubscriptionInput",
		connect:"WorkspaceWhereUniqueInput"
	},
	WorkspaceCreateWithoutActiveSubscriptionInput:{
		type:"WorkspaceType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		owner:"UserCreateNestedOneWithoutOwnedWorkspacesInput",
		memberships:"MembershipCreateNestedManyWithoutWorkspaceInput",
		adBoards:"AdBoardCreateNestedManyWithoutWorkspaceInput",
		playlists:"PlaylistCreateNestedManyWithoutWorkspaceInput",
		adBoardGroups:"AdBoardGroupCreateNestedManyWithoutWorkspaceInput",
		audits:"AuditCreateNestedManyWithoutWorkspaceInput",
		bookings:"BookingCreateNestedManyWithoutWorkspaceInput",
		files:"FileCreateNestedManyWithoutWorkspaceInput",
		players:"PlayerCreateNestedManyWithoutWorkspaceInput",
		transactions:"TransactionCreateNestedManyWithoutWorkspaceInput",
		cameraServers:"CameraServerCreateNestedManyWithoutWorkspaceInput",
		workspaceRoles:"WorkspaceRoleCreateNestedManyWithoutWorkspaceInput",
		webhookLogs:"WebhookLogCreateNestedManyWithoutWorkspaceInput",
		leads:"UserCreateNestedManyWithoutLeadWorspacesInput"
	},
	WorkspaceCreateOrConnectWithoutActiveSubscriptionInput:{
		where:"WorkspaceWhereUniqueInput",
		create:"WorkspaceCreateWithoutActiveSubscriptionInput"
	},
	SubscriptionUpdateManyMutationInput:{
		startDate:"DateTime",
		endDate:"DateTime",
		cancellationDetails:"CancellationDetailsCreateInput"
	},
	SubscriptionUpdateInput:{
		startDate:"DateTime",
		endDate:"DateTime",
		cancellationDetails:"CancellationDetailsCreateInput",
		activeWorkspace:"WorkspaceUpdateOneRequiredWithoutActiveSubscriptionNestedInput"
	},
	WorkspaceUpdateOneRequiredWithoutActiveSubscriptionNestedInput:{
		create:"WorkspaceCreateWithoutActiveSubscriptionInput",
		connectOrCreate:"WorkspaceCreateOrConnectWithoutActiveSubscriptionInput",
		upsert:"WorkspaceUpsertWithoutActiveSubscriptionInput",
		connect:"WorkspaceWhereUniqueInput",
		update:"WorkspaceUpdateToOneWithWhereWithoutActiveSubscriptionInput"
	},
	WorkspaceUpsertWithoutActiveSubscriptionInput:{
		update:"WorkspaceUpdateWithoutActiveSubscriptionInput",
		create:"WorkspaceCreateWithoutActiveSubscriptionInput",
		where:"WorkspaceWhereInput"
	},
	WorkspaceUpdateWithoutActiveSubscriptionInput:{
		type:"WorkspaceType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		owner:"UserUpdateOneWithoutOwnedWorkspacesNestedInput",
		memberships:"MembershipUpdateManyWithoutWorkspaceNestedInput",
		adBoards:"AdBoardUpdateManyWithoutWorkspaceNestedInput",
		playlists:"PlaylistUpdateManyWithoutWorkspaceNestedInput",
		adBoardGroups:"AdBoardGroupUpdateManyWithoutWorkspaceNestedInput",
		audits:"AuditUpdateManyWithoutWorkspaceNestedInput",
		bookings:"BookingUpdateManyWithoutWorkspaceNestedInput",
		files:"FileUpdateManyWithoutWorkspaceNestedInput",
		players:"PlayerUpdateManyWithoutWorkspaceNestedInput",
		transactions:"TransactionUpdateManyWithoutWorkspaceNestedInput",
		cameraServers:"CameraServerUpdateManyWithoutWorkspaceNestedInput",
		workspaceRoles:"WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput",
		webhookLogs:"WebhookLogUpdateManyWithoutWorkspaceNestedInput",
		leads:"UserUpdateManyWithoutLeadWorspacesNestedInput"
	},
	WorkspaceUpdateToOneWithWhereWithoutActiveSubscriptionInput:{
		where:"WorkspaceWhereInput",
		data:"WorkspaceUpdateWithoutActiveSubscriptionInput"
	},
	UserCreateManyInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput"
	},
	UserCreateInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingCreateNestedManyWithoutUserInput",
		createdFiles:"FileCreateNestedManyWithoutCreatedByInput",
		updatedFiles:"FileCreateNestedManyWithoutUpdatedByInput",
		orders:"OrderCreateNestedManyWithoutCreatedByInput",
		changelogs:"ChangelogCreateNestedManyWithoutUserInput",
		adBoards:"AdBoardCreateNestedManyWithoutCreatedByInput",
		audits:"AuditCreateNestedManyWithoutUserInput",
		campaigns:"CampaignCreateNestedManyWithoutUserInput",
		sharedBookings:"BookingCreateNestedManyWithoutSharedUsersInput",
		memberships:"MembershipCreateNestedManyWithoutUserInput",
		notifications:"NotificationCreateNestedManyWithoutRecipientInput",
		publishAdboardVersion:"AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput",
		offlineBookingAdboards:"AdBoardCreateNestedManyWithoutOfflineBookingsContactInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutUserInput",
		archivedAdboards:"AdBoardCreateNestedManyWithoutArchivedByInput",
		ownedWorkspaces:"WorkspaceCreateNestedManyWithoutOwnerInput",
		leadWorspaces:"WorkspaceCreateNestedManyWithoutLeadsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutUserInput",
		userPositions:"UserPositionCreateNestedManyWithoutUserInput"
	},
	UserUpdateInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingUpdateManyWithoutUserNestedInput",
		createdFiles:"FileUpdateManyWithoutCreatedByNestedInput",
		updatedFiles:"FileUpdateManyWithoutUpdatedByNestedInput",
		orders:"OrderUpdateManyWithoutCreatedByNestedInput",
		changelogs:"ChangelogUpdateManyWithoutUserNestedInput",
		adBoards:"AdBoardUpdateManyWithoutCreatedByNestedInput",
		audits:"AuditUpdateManyWithoutUserNestedInput",
		campaigns:"CampaignUpdateManyWithoutUserNestedInput",
		sharedBookings:"BookingUpdateManyWithoutSharedUsersNestedInput",
		memberships:"MembershipUpdateManyWithoutUserNestedInput",
		notifications:"NotificationUpdateManyWithoutRecipientNestedInput",
		publishAdboardVersion:"AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput",
		offlineBookingAdboards:"AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutUserNestedInput",
		archivedAdboards:"AdBoardUpdateManyWithoutArchivedByNestedInput",
		ownedWorkspaces:"WorkspaceUpdateManyWithoutOwnerNestedInput",
		leadWorspaces:"WorkspaceUpdateManyWithoutLeadsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutUserNestedInput",
		userPositions:"UserPositionUpdateManyWithoutUserNestedInput"
	},
	UserPositionCreateManyInput:{
		timestamp:"DateTime",
		createdAt:"DateTime",
		coords:"UserPositionCoordsCreateInput",
		location:"UserPositionLocationCreateInput"
	},
	UserPositionCreateInput:{
		timestamp:"DateTime",
		createdAt:"DateTime",
		coords:"UserPositionCoordsCreateInput",
		location:"UserPositionLocationCreateInput",
		user:"UserCreateNestedOneWithoutUserPositionsInput"
	},
	UserCreateNestedOneWithoutUserPositionsInput:{
		create:"UserCreateWithoutUserPositionsInput",
		connectOrCreate:"UserCreateOrConnectWithoutUserPositionsInput",
		connect:"UserWhereUniqueInput"
	},
	UserCreateWithoutUserPositionsInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingCreateNestedManyWithoutUserInput",
		createdFiles:"FileCreateNestedManyWithoutCreatedByInput",
		updatedFiles:"FileCreateNestedManyWithoutUpdatedByInput",
		orders:"OrderCreateNestedManyWithoutCreatedByInput",
		changelogs:"ChangelogCreateNestedManyWithoutUserInput",
		adBoards:"AdBoardCreateNestedManyWithoutCreatedByInput",
		audits:"AuditCreateNestedManyWithoutUserInput",
		campaigns:"CampaignCreateNestedManyWithoutUserInput",
		sharedBookings:"BookingCreateNestedManyWithoutSharedUsersInput",
		memberships:"MembershipCreateNestedManyWithoutUserInput",
		notifications:"NotificationCreateNestedManyWithoutRecipientInput",
		publishAdboardVersion:"AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput",
		offlineBookingAdboards:"AdBoardCreateNestedManyWithoutOfflineBookingsContactInput",
		reviewComments:"AdBoardReviewCommentCreateNestedManyWithoutUserInput",
		archivedAdboards:"AdBoardCreateNestedManyWithoutArchivedByInput",
		ownedWorkspaces:"WorkspaceCreateNestedManyWithoutOwnerInput",
		leadWorspaces:"WorkspaceCreateNestedManyWithoutLeadsInput",
		publicReviews:"AdBoardPublicReviewCreateNestedManyWithoutUserInput"
	},
	UserCreateOrConnectWithoutUserPositionsInput:{
		where:"UserWhereUniqueInput",
		create:"UserCreateWithoutUserPositionsInput"
	},
	UserPositionUpdateInput:{
		timestamp:"DateTime",
		createdAt:"DateTime",
		coords:"UserPositionCoordsCreateInput",
		location:"UserPositionLocationCreateInput",
		user:"UserUpdateOneRequiredWithoutUserPositionsNestedInput"
	},
	UserUpdateOneRequiredWithoutUserPositionsNestedInput:{
		create:"UserCreateWithoutUserPositionsInput",
		connectOrCreate:"UserCreateOrConnectWithoutUserPositionsInput",
		upsert:"UserUpsertWithoutUserPositionsInput",
		connect:"UserWhereUniqueInput",
		update:"UserUpdateToOneWithWhereWithoutUserPositionsInput"
	},
	UserUpsertWithoutUserPositionsInput:{
		update:"UserUpdateWithoutUserPositionsInput",
		create:"UserCreateWithoutUserPositionsInput",
		where:"UserWhereInput"
	},
	UserUpdateWithoutUserPositionsInput:{
		otherDetails:"JSON",
		stripe:"UserStripeCreateInput",
		roles:"Role",
		refunds:"BookingRefundCreateInput",
		notificationPreference:"NotificationPreferenceCreateInput",
		bookings:"BookingUpdateManyWithoutUserNestedInput",
		createdFiles:"FileUpdateManyWithoutCreatedByNestedInput",
		updatedFiles:"FileUpdateManyWithoutUpdatedByNestedInput",
		orders:"OrderUpdateManyWithoutCreatedByNestedInput",
		changelogs:"ChangelogUpdateManyWithoutUserNestedInput",
		adBoards:"AdBoardUpdateManyWithoutCreatedByNestedInput",
		audits:"AuditUpdateManyWithoutUserNestedInput",
		campaigns:"CampaignUpdateManyWithoutUserNestedInput",
		sharedBookings:"BookingUpdateManyWithoutSharedUsersNestedInput",
		memberships:"MembershipUpdateManyWithoutUserNestedInput",
		notifications:"NotificationUpdateManyWithoutRecipientNestedInput",
		publishAdboardVersion:"AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput",
		offlineBookingAdboards:"AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput",
		reviewComments:"AdBoardReviewCommentUpdateManyWithoutUserNestedInput",
		archivedAdboards:"AdBoardUpdateManyWithoutArchivedByNestedInput",
		ownedWorkspaces:"WorkspaceUpdateManyWithoutOwnerNestedInput",
		leadWorspaces:"WorkspaceUpdateManyWithoutLeadsNestedInput",
		publicReviews:"AdBoardPublicReviewUpdateManyWithoutUserNestedInput"
	},
	UserUpdateToOneWithWhereWithoutUserPositionsInput:{
		where:"UserWhereInput",
		data:"UserUpdateWithoutUserPositionsInput"
	},
	WorkspaceCreateManyInput:{
		type:"WorkspaceType",
		createdAt:"DateTime",
		updatedAt:"DateTime"
	},
	WorkspaceCreateInput:{
		type:"WorkspaceType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		owner:"UserCreateNestedOneWithoutOwnedWorkspacesInput",
		memberships:"MembershipCreateNestedManyWithoutWorkspaceInput",
		adBoards:"AdBoardCreateNestedManyWithoutWorkspaceInput",
		playlists:"PlaylistCreateNestedManyWithoutWorkspaceInput",
		adBoardGroups:"AdBoardGroupCreateNestedManyWithoutWorkspaceInput",
		audits:"AuditCreateNestedManyWithoutWorkspaceInput",
		bookings:"BookingCreateNestedManyWithoutWorkspaceInput",
		files:"FileCreateNestedManyWithoutWorkspaceInput",
		players:"PlayerCreateNestedManyWithoutWorkspaceInput",
		transactions:"TransactionCreateNestedManyWithoutWorkspaceInput",
		activeSubscription:"SubscriptionCreateNestedOneWithoutActiveWorkspaceInput",
		cameraServers:"CameraServerCreateNestedManyWithoutWorkspaceInput",
		workspaceRoles:"WorkspaceRoleCreateNestedManyWithoutWorkspaceInput",
		webhookLogs:"WebhookLogCreateNestedManyWithoutWorkspaceInput",
		leads:"UserCreateNestedManyWithoutLeadWorspacesInput"
	},
	WorkspaceUpdateInput:{
		type:"WorkspaceType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		owner:"UserUpdateOneWithoutOwnedWorkspacesNestedInput",
		memberships:"MembershipUpdateManyWithoutWorkspaceNestedInput",
		adBoards:"AdBoardUpdateManyWithoutWorkspaceNestedInput",
		playlists:"PlaylistUpdateManyWithoutWorkspaceNestedInput",
		adBoardGroups:"AdBoardGroupUpdateManyWithoutWorkspaceNestedInput",
		audits:"AuditUpdateManyWithoutWorkspaceNestedInput",
		bookings:"BookingUpdateManyWithoutWorkspaceNestedInput",
		files:"FileUpdateManyWithoutWorkspaceNestedInput",
		players:"PlayerUpdateManyWithoutWorkspaceNestedInput",
		transactions:"TransactionUpdateManyWithoutWorkspaceNestedInput",
		activeSubscription:"SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput",
		cameraServers:"CameraServerUpdateManyWithoutWorkspaceNestedInput",
		workspaceRoles:"WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput",
		webhookLogs:"WebhookLogUpdateManyWithoutWorkspaceNestedInput",
		leads:"UserUpdateManyWithoutLeadWorspacesNestedInput"
	},
	WorkspaceRoleCreateManyInput:{
		permissions:"Permission",
		createdAt:"DateTime",
		updatedAt:"DateTime"
	},
	WorkspaceRoleCreateInput:{
		permissions:"Permission",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		workspace:"WorkspaceCreateNestedOneWithoutWorkspaceRolesInput",
		memberships:"MembershipCreateNestedManyWithoutRoleInput"
	},
	WorkspaceRoleUpdateInput:{
		permissions:"Permission",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		workspace:"WorkspaceUpdateOneWithoutWorkspaceRolesNestedInput",
		memberships:"MembershipUpdateManyWithoutRoleNestedInput"
	},
	AccountDetails:{

	},
	UserInput:{

	},
	TemplateIds: "enum" as const,
	AdBoardPublicReviewCreateInput:{
		createdAt:"DateTime",
		adBoard:"AdBoardCreateNestedOneWithoutPublicReviewsInput",
		user:"UserCreateNestedOneWithoutPublicReviewsInput"
	},
	BookingRequest:{
		bookingDetails:"BookingDetailsInput"
	},
	BookingDetailsInput:{

	}
}

export const ReturnTypes: Record<string,any> = {
	Query:{
		aggregateAdBoard:"AggregateAdBoard",
		findFirstAdBoard:"AdBoard",
		findFirstAdBoardOrThrow:"AdBoard",
		adBoards:"AdBoard",
		adBoard:"AdBoard",
		getAdBoard:"AdBoard",
		groupByAdBoard:"AdBoardGroupBy",
		aggregateAdBoardGroup:"AggregateAdBoardGroup",
		findFirstAdBoardGroup:"AdBoardGroup",
		findFirstAdBoardGroupOrThrow:"AdBoardGroup",
		adBoardGroups:"AdBoardGroup",
		adBoardGroup:"AdBoardGroup",
		getAdBoardGroup:"AdBoardGroup",
		groupByAdBoardGroup:"AdBoardGroupGroupBy",
		aggregateAdBoardType:"AggregateAdBoardType",
		findFirstAdBoardType:"AdBoardType",
		findFirstAdBoardTypeOrThrow:"AdBoardType",
		adBoardTypes:"AdBoardType",
		adBoardType:"AdBoardType",
		getAdBoardType:"AdBoardType",
		groupByAdBoardType:"AdBoardTypeGroupBy",
		aggregateAudit:"AggregateAudit",
		findFirstAudit:"Audit",
		findFirstAuditOrThrow:"Audit",
		audits:"Audit",
		audit:"Audit",
		getAudit:"Audit",
		groupByAudit:"AuditGroupBy",
		aggregateBooking:"AggregateBooking",
		findFirstBooking:"Booking",
		findFirstBookingOrThrow:"Booking",
		bookings:"Booking",
		booking:"Booking",
		getBooking:"Booking",
		groupByBooking:"BookingGroupBy",
		aggregateBusinessCategory:"AggregateBusinessCategory",
		findFirstBusinessCategory:"BusinessCategory",
		findFirstBusinessCategoryOrThrow:"BusinessCategory",
		businessCategories:"BusinessCategory",
		businessCategory:"BusinessCategory",
		getBusinessCategory:"BusinessCategory",
		groupByBusinessCategory:"BusinessCategoryGroupBy",
		aggregateCamera:"AggregateCamera",
		findFirstCamera:"Camera",
		findFirstCameraOrThrow:"Camera",
		cameras:"Camera",
		camera:"Camera",
		getCamera:"Camera",
		groupByCamera:"CameraGroupBy",
		aggregateCameraFeed:"AggregateCameraFeed",
		findFirstCameraFeed:"CameraFeed",
		findFirstCameraFeedOrThrow:"CameraFeed",
		cameraFeeds:"CameraFeed",
		cameraFeed:"CameraFeed",
		getCameraFeed:"CameraFeed",
		groupByCameraFeed:"CameraFeedGroupBy",
		aggregateCameraServer:"AggregateCameraServer",
		findFirstCameraServer:"CameraServer",
		findFirstCameraServerOrThrow:"CameraServer",
		cameraServers:"CameraServer",
		cameraServer:"CameraServer",
		getCameraServer:"CameraServer",
		groupByCameraServer:"CameraServerGroupBy",
		aggregateCampaign:"AggregateCampaign",
		findFirstCampaign:"Campaign",
		findFirstCampaignOrThrow:"Campaign",
		campaigns:"Campaign",
		campaign:"Campaign",
		getCampaign:"Campaign",
		groupByCampaign:"CampaignGroupBy",
		aggregateFile:"AggregateFile",
		findFirstFile:"File",
		findFirstFileOrThrow:"File",
		files:"File",
		file:"File",
		getFile:"File",
		groupByFile:"FileGroupBy",
		aggregateMembership:"AggregateMembership",
		findFirstMembership:"Membership",
		findFirstMembershipOrThrow:"Membership",
		memberships:"Membership",
		membership:"Membership",
		getMembership:"Membership",
		groupByMembership:"MembershipGroupBy",
		aggregateNotification:"AggregateNotification",
		findFirstNotification:"Notification",
		findFirstNotificationOrThrow:"Notification",
		notifications:"Notification",
		notification:"Notification",
		getNotification:"Notification",
		groupByNotification:"NotificationGroupBy",
		aggregateOrder:"AggregateOrder",
		findFirstOrder:"Order",
		findFirstOrderOrThrow:"Order",
		orders:"Order",
		order:"Order",
		getOrder:"Order",
		groupByOrder:"OrderGroupBy",
		aggregatePlayer:"AggregatePlayer",
		findFirstPlayer:"Player",
		findFirstPlayerOrThrow:"Player",
		players:"Player",
		player:"Player",
		getPlayer:"Player",
		groupByPlayer:"PlayerGroupBy",
		aggregatePlaylist:"AggregatePlaylist",
		findFirstPlaylist:"Playlist",
		findFirstPlaylistOrThrow:"Playlist",
		playlists:"Playlist",
		playlist:"Playlist",
		getPlaylist:"Playlist",
		groupByPlaylist:"PlaylistGroupBy",
		aggregateSlotConfiguration:"AggregateSlotConfiguration",
		findFirstSlotConfiguration:"SlotConfiguration",
		findFirstSlotConfigurationOrThrow:"SlotConfiguration",
		slotConfigurations:"SlotConfiguration",
		slotConfiguration:"SlotConfiguration",
		getSlotConfiguration:"SlotConfiguration",
		groupBySlotConfiguration:"SlotConfigurationGroupBy",
		aggregateSubscription:"AggregateSubscription",
		findFirstSubscription:"Subscription",
		findFirstSubscriptionOrThrow:"Subscription",
		subscriptions:"Subscription",
		subscription:"Subscription",
		getSubscription:"Subscription",
		groupBySubscription:"SubscriptionGroupBy",
		aggregateUser:"AggregateUser",
		findFirstUser:"User",
		findFirstUserOrThrow:"User",
		users:"User",
		user:"User",
		getUser:"User",
		groupByUser:"UserGroupBy",
		aggregateUserPosition:"AggregateUserPosition",
		findFirstUserPosition:"UserPosition",
		findFirstUserPositionOrThrow:"UserPosition",
		userPositions:"UserPosition",
		userPosition:"UserPosition",
		getUserPosition:"UserPosition",
		groupByUserPosition:"UserPositionGroupBy",
		aggregateWorkspace:"AggregateWorkspace",
		findFirstWorkspace:"Workspace",
		findFirstWorkspaceOrThrow:"Workspace",
		workspaces:"Workspace",
		workspace:"Workspace",
		getWorkspace:"Workspace",
		groupByWorkspace:"WorkspaceGroupBy",
		aggregateWorkspaceRole:"AggregateWorkspaceRole",
		findFirstWorkspaceRole:"WorkspaceRole",
		findFirstWorkspaceRoleOrThrow:"WorkspaceRole",
		workspaceRoles:"WorkspaceRole",
		workspaceRole:"WorkspaceRole",
		getWorkspaceRole:"WorkspaceRole",
		groupByWorkspaceRole:"WorkspaceRoleGroupBy",
		getAdBoardAvailability:"Slot"
	},
	AggregateAdBoard:{
		_count:"AdBoardCountAggregate",
		_avg:"AdBoardAvgAggregate",
		_sum:"AdBoardSumAggregate",
		_min:"AdBoardMinAggregate",
		_max:"AdBoardMaxAggregate"
	},
	AdBoardCountAggregate:{
		id:"Int",
		address:"Int",
		state:"Int",
		city:"Int",
		country:"Int",
		outdoor:"Int",
		locationCategoryId:"Int",
		locationSubCategoryId:"Int",
		alias:"Int",
		title:"Int",
		pricePerDay:"Int",
		originalPricePerDay:"Int",
		description:"Int",
		adBoardTypeId:"Int",
		tags:"Int",
		featuredImage:"Int",
		galleryImgs:"Int",
		notes:"Int",
		impressionMultiplier:"Int",
		categoryId:"Int",
		restrictedBusinessIds:"Int",
		targetAudiences:"Int",
		status:"Int",
		adboardDraftStatus:"Int",
		isLive:"Int",
		numberOfDisplays:"Int",
		dailyOperationHours:"Int",
		adsPerLoop:"Int",
		adsDuration:"Int",
		monthlyPrice:"Int",
		offlineBookingsEnabled:"Int",
		offlineBookingContactId:"Int",
		createdAt:"Int",
		createdByUserId:"Int",
		href:"Int",
		workspaceId:"Int",
		playerIds:"Int",
		publisherScreenId:"Int",
		additionalData:"Int",
		isDraft:"Int",
		publishedVersionId:"Int",
		lastPublishedAt:"Int",
		isArchived:"Int",
		archivedAt:"Int",
		archivedByUserId:"Int",
		updatedAt:"Int",
		_all:"Int"
	},
	AdBoardAvgAggregate:{
		pricePerDay:"Float",
		originalPricePerDay:"Float",
		impressionMultiplier:"Float",
		numberOfDisplays:"Float",
		dailyOperationHours:"Float",
		adsPerLoop:"Float",
		adsDuration:"Float",
		monthlyPrice:"Float"
	},
	AdBoardSumAggregate:{
		pricePerDay:"Int",
		originalPricePerDay:"Int",
		impressionMultiplier:"Float",
		numberOfDisplays:"Int",
		dailyOperationHours:"Int",
		adsPerLoop:"Int",
		adsDuration:"Int",
		monthlyPrice:"Float"
	},
	AdBoardMinAggregate:{
		id:"String",
		address:"String",
		state:"String",
		city:"String",
		country:"String",
		outdoor:"Boolean",
		locationCategoryId:"String",
		locationSubCategoryId:"String",
		alias:"String",
		title:"String",
		pricePerDay:"Int",
		originalPricePerDay:"Int",
		description:"String",
		adBoardTypeId:"String",
		featuredImage:"String",
		impressionMultiplier:"Float",
		categoryId:"String",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		isLive:"Boolean",
		numberOfDisplays:"Int",
		dailyOperationHours:"Int",
		adsPerLoop:"Int",
		adsDuration:"Int",
		monthlyPrice:"Float",
		offlineBookingsEnabled:"Boolean",
		offlineBookingContactId:"String",
		createdAt:"DateTime",
		createdByUserId:"String",
		href:"String",
		workspaceId:"String",
		publisherScreenId:"String",
		isDraft:"Boolean",
		publishedVersionId:"String",
		lastPublishedAt:"DateTime",
		isArchived:"Boolean",
		archivedAt:"DateTime",
		archivedByUserId:"String",
		updatedAt:"DateTime"
	},
	DateTime: `scalar.DateTime` as const,
	AdBoardMaxAggregate:{
		id:"String",
		address:"String",
		state:"String",
		city:"String",
		country:"String",
		outdoor:"Boolean",
		locationCategoryId:"String",
		locationSubCategoryId:"String",
		alias:"String",
		title:"String",
		pricePerDay:"Int",
		originalPricePerDay:"Int",
		description:"String",
		adBoardTypeId:"String",
		featuredImage:"String",
		impressionMultiplier:"Float",
		categoryId:"String",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		isLive:"Boolean",
		numberOfDisplays:"Int",
		dailyOperationHours:"Int",
		adsPerLoop:"Int",
		adsDuration:"Int",
		monthlyPrice:"Float",
		offlineBookingsEnabled:"Boolean",
		offlineBookingContactId:"String",
		createdAt:"DateTime",
		createdByUserId:"String",
		href:"String",
		workspaceId:"String",
		publisherScreenId:"String",
		isDraft:"Boolean",
		publishedVersionId:"String",
		lastPublishedAt:"DateTime",
		isArchived:"Boolean",
		archivedAt:"DateTime",
		archivedByUserId:"String",
		updatedAt:"DateTime"
	},
	JSON: `scalar.JSON` as const,
	AdBoard:{
		id:"String",
		address:"String",
		state:"String",
		city:"String",
		country:"String",
		map:"Map",
		outdoor:"Boolean",
		locationCategoryId:"String",
		locationSubCategoryId:"String",
		alias:"String",
		title:"String",
		pricePerDay:"Int",
		originalPricePerDay:"Int",
		description:"String",
		aboutSpecifications:"Specification",
		adBoardTypeId:"String",
		tags:"String",
		featuredImage:"String",
		galleryImgs:"String",
		specifications:"Specification",
		notes:"String",
		impressionMultiplier:"Float",
		resolution:"Resolution",
		screenSize:"ScreenSize",
		categoryId:"String",
		restrictedBusinessIds:"String",
		targetAudiences:"String",
		playbackTimes:"PlaybackTime",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		isLive:"Boolean",
		webhooks:"Webhook",
		numberOfDisplays:"Int",
		dailyOperationHours:"Int",
		adsPerLoop:"Int",
		adsDuration:"Int",
		monthlyPrice:"Float",
		offlineBookingsEnabled:"Boolean",
		offlineBookingContactId:"String",
		createdAt:"DateTime",
		createdByUserId:"String",
		href:"String",
		workspaceId:"String",
		playerIds:"String",
		publisherScreenId:"String",
		additionalData:"JSON",
		isDraft:"Boolean",
		publishedVersionId:"String",
		lastPublishedAt:"DateTime",
		isArchived:"Boolean",
		archivedAt:"DateTime",
		archivedByUserId:"String",
		updatedAt:"DateTime",
		_count:"AdBoardCount",
		locationCategory:"LocationCategory",
		locationSubCategory:"LocationSubCategory",
		type:"AdBoardType",
		category:"BusinessCategory",
		restrictedBusines:"BusinessCategory",
		bookings:"Booking",
		orders:"Order",
		slotConfigurations:"SlotConfiguration",
		offlineBookingsContact:"User",
		createdBy:"User",
		workspace:"Workspace",
		players:"Player",
		adBoardGroup:"AdBoardGroup",
		cameras:"Camera",
		publishedVersion:"AdBoard",
		draftAdboard:"AdBoard",
		history:"AdBoardPublishHistory",
		reviewComments:"AdBoardReviewComment",
		archivedBy:"User",
		publicReviews:"AdBoardPublicReview"
	},
	Map:{
		lat:"Float",
		lng:"Float",
		address:"String",
		city:"String",
		state:"String",
		country:"String"
	},
	Specification:{
		name:"String",
		value:"String"
	},
	Resolution:{
		height:"Int",
		width:"Int"
	},
	ScreenSize:{
		height:"Float",
		unit:"MeasurementUnit",
		width:"Float"
	},
	PlaybackTime:{
		name:"String",
		startTime:"String",
		endTime:"String"
	},
	Webhook:{
		url:"String",
		headers:"JSON",
		body:"JSON",
		method:"String"
	},
	AdBoardCount:{
		restrictedBusines:"Int",
		bookings:"Int",
		orders:"Int",
		slotConfigurations:"Int",
		players:"Int",
		cameras:"Int",
		history:"Int",
		reviewComments:"Int",
		publicReviews:"Int"
	},
	LocationCategory:{
		id:"String",
		name:"String",
		_count:"LocationCategoryCount",
		adBoards:"AdBoard",
		locationSubCategory:"LocationSubCategory"
	},
	LocationCategoryCount:{
		adBoards:"Int",
		locationSubCategory:"Int"
	},
	LocationSubCategory:{
		id:"String",
		name:"String",
		locationCategoryId:"String",
		_count:"LocationSubCategoryCount",
		locationCategory:"LocationCategory",
		adBoards:"AdBoard"
	},
	LocationSubCategoryCount:{
		adBoards:"Int"
	},
	AdBoardType:{
		id:"String",
		name:"String",
		description:"String",
		_count:"AdBoardTypeCount",
		adBoards:"AdBoard"
	},
	AdBoardTypeCount:{
		adBoards:"Int"
	},
	BusinessCategory:{
		id:"String",
		name:"String",
		description:"String",
		restrictedByAdBoardIds:"String",
		_count:"BusinessCategoryCount",
		adBoards:"AdBoard",
		restrictedByAdBoards:"AdBoard",
		campaigns:"Campaign",
		bookings:"Booking"
	},
	BusinessCategoryCount:{
		adBoards:"Int",
		restrictedByAdBoards:"Int",
		campaigns:"Int",
		bookings:"Int"
	},
	Campaign:{
		id:"String",
		name:"String",
		userId:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		bookingFor:"String",
		purpose:"String",
		businessCategoryId:"String",
		payment:"BookingPayment",
		paymentStatus:"PaymentStatus",
		priceSummary:"PriceSummary",
		selectedDates:"OrderSelectedDate",
		origin:"String",
		assetsIDs:"String",
		_count:"CampaignCount",
		user:"User",
		businessCategory:"BusinessCategory",
		bookings:"Booking",
		payments:"Payment",
		assets:"File"
	},
	BookingPayment:{
		liveMode:"Boolean",
		checkoutSessionId:"String",
		customer:"BookingCustomer",
		paymentGateway:"PaymentGatewayName",
		paymentId:"String",
		sessionId:"String",
		totalDetails:"PaymentTotalDetails",
		razorpay:"JSON",
		stripe:"JSON"
	},
	BookingCustomer:{
		customerId:"String",
		customerDetails:"PaymentCustomerDetails"
	},
	PaymentCustomerDetails:{
		email:"String",
		name:"String",
		phone:"String"
	},
	PaymentTotalDetails:{
		discount:"Float",
		shipping:"Float",
		subtotal:"Float",
		tax:"Float",
		total:"Float"
	},
	PriceSummary:{
		days:"Int",
		discount:"Float",
		payableRent:"Float",
		rentPerDay:"Int",
		serviceCharge:"Float",
		totalRent:"Int"
	},
	OrderSelectedDate:{
		endDate:"DateTime",
		startDate:"DateTime"
	},
	CampaignCount:{
		bookings:"Int",
		payments:"Int",
		assets:"Int"
	},
	User:{
		id:"String",
		uid:"String",
		email:"String",
		image:"String",
		phoneNumber:"String",
		displayName:"String",
		otherDetails:"JSON",
		stripe:"UserStripe",
		roles:"Role",
		refunds:"BookingRefund",
		sharedBookingIds:"String",
		disclaimerAccepted:"Boolean",
		notificationPreference:"NotificationPreference",
		leadWorkspaceIds:"String",
		_count:"UserCount",
		bookings:"Booking",
		createdFiles:"File",
		updatedFiles:"File",
		orders:"Order",
		changelogs:"Changelog",
		adBoards:"AdBoard",
		audits:"Audit",
		campaigns:"Campaign",
		sharedBookings:"Booking",
		memberships:"Membership",
		notifications:"Notification",
		publishAdboardVersion:"AdBoardPublishHistory",
		offlineBookingAdboards:"AdBoard",
		reviewComments:"AdBoardReviewComment",
		archivedAdboards:"AdBoard",
		ownedWorkspaces:"Workspace",
		leadWorspaces:"Workspace",
		publicReviews:"AdBoardPublicReview",
		userPositions:"UserPosition"
	},
	UserStripe:{
		customerId:"String"
	},
	BookingRefund:{
		id:"String",
		razorpay:"JSON",
		stripe:"JSON",
		createdByUserId:"String"
	},
	NotificationPreference:{
		type:"NotificationType",
		channels:"NotificationChannel",
		pushSetting:"PushSetting",
		enabled:"Boolean"
	},
	PushSetting:{
		fcmToken:"String"
	},
	UserCount:{
		bookings:"Int",
		createdFiles:"Int",
		updatedFiles:"Int",
		orders:"Int",
		changelogs:"Int",
		adBoards:"Int",
		audits:"Int",
		campaigns:"Int",
		sharedBookings:"Int",
		memberships:"Int",
		notifications:"Int",
		publishAdboardVersion:"Int",
		offlineBookingAdboards:"Int",
		reviewComments:"Int",
		archivedAdboards:"Int",
		ownedWorkspaces:"Int",
		leadWorspaces:"Int",
		publicReviews:"Int",
		userPositions:"Int"
	},
	Booking:{
		id:"String",
		additionalInformation:"String",
		origin:"String",
		assetsIDs:"String",
		bookingCode:"String",
		bookingDate:"DateTime",
		bookingFor:"String",
		purpose:"String",
		businessCategoryId:"String",
		adBoardId:"String",
		workspaceId:"String",
		campaignId:"String",
		bookingDetails:"BookingDetails",
		bookingStatus:"BookingStatus",
		paymentStatus:"PaymentStatus",
		deploymentStatus:"DeploymentStatus",
		contentApprovalStatus:"ContentApprovalStatus",
		selectedDates:"OrderSelectedDate",
		payment:"BookingPayment",
		priceSummary:"PriceSummary",
		pisignage:"BookingPisignageData",
		userId:"String",
		refund:"BookingRefund",
		sharedUserIds:"String",
		isOffline:"Boolean",
		isPaid:"Boolean",
		isScheduled:"Boolean",
		isDeployed:"Boolean",
		isCompleted:"Boolean",
		_count:"BookingCount",
		assets:"File",
		businessCategory:"BusinessCategory",
		proofs:"File",
		user:"User",
		adBoard:"AdBoard",
		changelogs:"Changelog",
		workspace:"Workspace",
		campaign:"Campaign",
		payouts:"Payout",
		sharedUsers:"User",
		webhookLogs:"WebhookLog",
		slotUsage:"SlotUsage"
	},
	BookingDetails:{
		additionalInformation:"String",
		email:"String",
		name:"String",
		phone:"String"
	},
	BookingPisignageData:{
		deployedFileName:"String",
		playlistId:"String"
	},
	BookingCount:{
		assets:"Int",
		proofs:"Int",
		changelogs:"Int",
		payouts:"Int",
		sharedUsers:"Int",
		webhookLogs:"Int",
		slotUsage:"Int"
	},
	File:{
		id:"String",
		contentType:"String",
		ctime:"String",
		filename:"String",
		filepath:"String",
		isDirectory:"Boolean",
		meta:"FileMeta",
		mtime:"String",
		name:"String",
		size:"Int",
		status:"FileStatus",
		type:"String",
		updatedAt:"DateTime",
		createdAt:"DateTime",
		updatedByUserId:"String",
		createdByUserId:"String",
		url:"String",
		bookingsIDs:"String",
		workspaceId:"String",
		proofBookingId:"String",
		campaignIds:"String",
		_count:"FileCount",
		updatedBy:"User",
		createdBy:"User",
		bookings:"Booking",
		workspace:"Workspace",
		proofBooking:"Booking",
		campaigns:"Campaign"
	},
	FileMeta:{
		duration:"Float",
		height:"Int",
		width:"Int"
	},
	FileCount:{
		bookings:"Int",
		campaigns:"Int"
	},
	Workspace:{
		id:"String",
		type:"WorkspaceType",
		name:"String",
		key:"String",
		userIds:"String",
		ownerId:"String",
		avatar:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		stripeSubscriptionId:"String",
		leadIds:"String",
		_count:"WorkspaceCount",
		owner:"User",
		memberships:"Membership",
		adBoards:"AdBoard",
		playlists:"Playlist",
		adBoardGroups:"AdBoardGroup",
		audits:"Audit",
		bookings:"Booking",
		files:"File",
		players:"Player",
		transactions:"Transaction",
		activeSubscription:"Subscription",
		cameraServers:"CameraServer",
		workspaceRoles:"WorkspaceRole",
		webhookLogs:"WebhookLog",
		leads:"User"
	},
	WorkspaceCount:{
		memberships:"Int",
		adBoards:"Int",
		playlists:"Int",
		adBoardGroups:"Int",
		audits:"Int",
		bookings:"Int",
		files:"Int",
		players:"Int",
		transactions:"Int",
		cameraServers:"Int",
		workspaceRoles:"Int",
		webhookLogs:"Int",
		leads:"Int"
	},
	Membership:{
		id:"String",
		createdAt:"DateTime",
		userId:"String",
		workspaceId:"String",
		roleId:"String",
		user:"User",
		workspace:"Workspace",
		role:"WorkspaceRole"
	},
	WorkspaceRole:{
		id:"String",
		name:"String",
		permissions:"Permission",
		workspaceId:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		_count:"WorkspaceRoleCount",
		workspace:"Workspace",
		memberships:"Membership"
	},
	WorkspaceRoleCount:{
		memberships:"Int"
	},
	Playlist:{
		id:"String",
		name:"String",
		displayName:"String",
		assets:"PisignageFile",
		isAdsPlaylist:"Boolean",
		footerPlaylistId:"String",
		sidebarPlaylistId:"String",
		layout:"ScreenLayout",
		isDeployed:"Boolean",
		adBoardGroupIds:"String",
		workspaceId:"String",
		_count:"PlaylistCount",
		footerPlaylist:"Playlist",
		sidebarPlaylist:"Playlist",
		adBoardGroupsForAds:"AdBoardGroup",
		adBoardGroups:"AdBoardGroup",
		workspace:"Workspace",
		footerParentPlaylists:"Playlist",
		sidebarParentPlaylists:"Playlist"
	},
	PisignageFile:{
		name:"String",
		duration:"Int",
		muteAudio:"Boolean",
		isVideo:"Boolean"
	},
	PlaylistCount:{
		adBoardGroupsForAds:"Int",
		adBoardGroups:"Int",
		footerParentPlaylists:"Int",
		sidebarParentPlaylists:"Int"
	},
	AdBoardGroup:{
		id:"String",
		name:"String",
		adBoardId:"String",
		schedules:"Schedule",
		scheduledPlaylistsIDs:"String",
		adsPlaylistID:"String",
		pisignageGroupId:"String",
		fitImage:"FitType",
		fitVideo:"FitType",
		workspaceId:"String",
		_count:"AdBoardGroupCount",
		adBoard:"AdBoard",
		scheduledPlaylists:"Playlist",
		adsPlaylist:"Playlist",
		pisignagePlayers:"Player",
		workspace:"Workspace"
	},
	Schedule:{
		playlistType:"String",
		skipForSchedule:"Boolean",
		playlistId:"String",
		startTime:"String",
		endTime:"String"
	},
	AdBoardGroupCount:{
		scheduledPlaylists:"Int",
		pisignagePlayers:"Int"
	},
	Player:{
		id:"String",
		name:"String",
		configLocation:"String",
		pisignagePlayerId:"String",
		pisignageGroupId:"String",
		cpuSerialNumber:"String",
		displayName:"String",
		adBoardGroupId:"String",
		workpaceId:"String",
		resolution:"Resolution",
		screenSize:"ScreenSize",
		playbackDetails:"PlaybackTime",
		aspectRatioType:"AspectRatioType",
		customAspectRatioWidth:"Int",
		customAspectRatioHeight:"Int",
		map:"Map",
		isOnline:"Boolean",
		adBoardIds:"String",
		_count:"PlayerCount",
		adBoardGroup:"AdBoardGroup",
		workspace:"Workspace",
		status:"PlayerUptimeStatus",
		cameras:"Camera",
		adBoards:"AdBoard"
	},
	PlayerCount:{
		status:"Int",
		cameras:"Int",
		adBoards:"Int"
	},
	PlayerUptimeStatus:{
		id:"String",
		playerId:"String",
		cpuSerialNumber:"String",
		date:"String",
		uptime:"Float",
		player:"Player"
	},
	Camera:{
		id:"String",
		name:"String",
		isOnline:"Boolean",
		cameraServerId:"String",
		cameraUrl:"String",
		rtspUrl:"String",
		cameraIp:"String",
		connectedCpuSerialNumbers:"String",
		playerId:"String",
		updatedAt:"DateTime",
		isPublic:"Boolean",
		adBoardId:"String",
		_count:"CameraCount",
		cameraServer:"CameraServer",
		player:"Player",
		feeds:"CameraFeed",
		adBoard:"AdBoard"
	},
	CameraCount:{
		feeds:"Int"
	},
	CameraServer:{
		id:"String",
		name:"String",
		isOnline:"Boolean",
		hostName:"String",
		userName:"String",
		deviceId:"String",
		workspaceId:"String",
		publicIp:"String",
		privateIp:"String",
		_count:"CameraServerCount",
		workspace:"Workspace",
		cameras:"Camera",
		feeds:"CameraFeed"
	},
	CameraServerCount:{
		cameras:"Int",
		feeds:"Int"
	},
	CameraFeed:{
		id:"String",
		cameraId:"String",
		timestamp:"DateTime",
		cameraServerId:"String",
		data:"CameraFeedData",
		camera:"Camera",
		cameraServer:"CameraServer"
	},
	CameraFeedData:{
		person:"Int",
		car:"Int",
		truck:"Int",
		motorcycle:"Int"
	},
	Audit:{
		id:"String",
		createdAt:"DateTime",
		message:"String",
		action:"String",
		entity:"Entity",
		userId:"String",
		workspaceId:"String",
		updates:"JSON",
		user:"User",
		workspace:"Workspace"
	},
	Entity:{
		type:"String",
		id:"String",
		name:"String"
	},
	Transaction:{
		workspaceId:"String",
		id:"String",
		amount:"Float",
		createAt:"DateTime",
		processedAt:"DateTime",
		relatedEntityId:"String",
		transactionStatus:"TransactionStatus",
		details:"JSON",
		_count:"TransactionCount",
		workspace:"Workspace",
		payouts:"Payout"
	},
	TransactionCount:{
		payouts:"Int"
	},
	Payout:{
		id:"String",
		totalAmount:"Float",
		commissionRate:"Float",
		commissionAmount:"Float",
		netAmount:"Float",
		createdAt:"DateTime",
		bookingId:"String",
		status:"PayoutStatus",
		transactionId:"String",
		booking:"Booking",
		transaction:"Transaction"
	},
	Subscription:{
		id:"String",
		packageId:"String",
		packageName:"String",
		priceLookupKey:"String",
		startDate:"DateTime",
		endDate:"DateTime",
		status:"String",
		stripePaymentIntentId:"String",
		stripeSubscriptionId:"String",
		stripeCheckoutSessionId:"String",
		cancellationDetails:"CancellationDetails",
		activeWorkspaceId:"String",
		activeWorkspace:"Workspace"
	},
	CancellationDetails:{
		canceledAt:"DateTime",
		cancelAt:"DateTime",
		cancelAtPeriodEnd:"Boolean",
		reason:"String"
	},
	WebhookLog:{
		id:"String",
		bookingId:"String",
		response:"JSON",
		request:"JSON",
		status:"Int",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		workspaceId:"String",
		booking:"Booking",
		workspace:"Workspace"
	},
	Changelog:{
		id:"String",
		createdAt:"DateTime",
		message:"String",
		action:"String",
		bookingId:"String",
		userId:"String",
		updates:"JSON",
		booking:"Booking",
		user:"User"
	},
	SlotUsage:{
		id:"String",
		bookingId:"String",
		date:"DateTime",
		slotUsed:"Int",
		booking:"Booking"
	},
	Order:{
		id:"String",
		adBoardId:"String",
		createdDate:"DateTime",
		createdByUserId:"String",
		origin:"String",
		paymentId:"String",
		priceSummary:"PriceSummary",
		selectedDate:"OrderSelectedDate",
		adBoard:"AdBoard",
		createdBy:"User"
	},
	Notification:{
		id:"String",
		type:"NotificationType",
		title:"String",
		content:"String",
		metadata:"JSON",
		createdAt:"DateTime",
		readAt:"DateTime",
		recipientId:"String",
		channels:"NotificationChannel",
		recipient:"User"
	},
	AdBoardPublishHistory:{
		id:"String",
		adBoardId:"String",
		publishedAt:"DateTime",
		publishedByUserId:"String",
		changesSnapshot:"JSON",
		adBoard:"AdBoard",
		publishedBy:"User"
	},
	AdBoardReviewComment:{
		id:"String",
		adBoardId:"String",
		userId:"String",
		comment:"String",
		action:"ReviewAction",
		createdAt:"DateTime",
		adBoard:"AdBoard",
		user:"User"
	},
	AdBoardPublicReview:{
		id:"String",
		adBoardId:"String",
		userId:"String",
		rating:"Int",
		comment:"String",
		createdAt:"DateTime",
		adBoard:"AdBoard",
		user:"User"
	},
	UserPosition:{
		id:"String",
		timestamp:"DateTime",
		createdAt:"DateTime",
		userId:"String",
		coords:"UserPositionCoords",
		location:"UserPositionLocation",
		user:"User"
	},
	UserPositionCoords:{
		accuracy:"Float",
		altitude:"Float",
		altitudeAccuracy:"Float",
		heading:"Float",
		latitude:"Float",
		longitude:"Float",
		speed:"Float"
	},
	UserPositionLocation:{
		address:"String",
		city:"String",
		state:"String",
		country:"String"
	},
	Payment:{
		id:"String",
		paymentIntentId:"String",
		checkoutSessionId:"String",
		eventId:"String",
		liveMode:"Boolean",
		paymentGateway:"PaymentGatewayName",
		subtotal:"Float",
		total:"Float",
		tax:"Float",
		discount:"Float",
		campaignId:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		campaign:"Campaign"
	},
	SlotConfiguration:{
		id:"String",
		adBoardId:"String",
		date:"DateTime",
		availableSlots:"Int",
		adBoard:"AdBoard"
	},
	AdBoardGroupBy:{
		id:"String",
		address:"String",
		state:"String",
		city:"String",
		country:"String",
		outdoor:"Boolean",
		locationCategoryId:"String",
		locationSubCategoryId:"String",
		alias:"String",
		title:"String",
		pricePerDay:"Int",
		originalPricePerDay:"Int",
		description:"String",
		adBoardTypeId:"String",
		tags:"String",
		featuredImage:"String",
		galleryImgs:"String",
		notes:"String",
		impressionMultiplier:"Float",
		categoryId:"String",
		restrictedBusinessIds:"String",
		targetAudiences:"String",
		status:"AdBoardStatus",
		adboardDraftStatus:"AdboardDraftStatus",
		isLive:"Boolean",
		numberOfDisplays:"Int",
		dailyOperationHours:"Int",
		adsPerLoop:"Int",
		adsDuration:"Int",
		monthlyPrice:"Float",
		offlineBookingsEnabled:"Boolean",
		offlineBookingContactId:"String",
		createdAt:"DateTime",
		createdByUserId:"String",
		href:"String",
		workspaceId:"String",
		playerIds:"String",
		publisherScreenId:"String",
		additionalData:"JSON",
		isDraft:"Boolean",
		publishedVersionId:"String",
		lastPublishedAt:"DateTime",
		isArchived:"Boolean",
		archivedAt:"DateTime",
		archivedByUserId:"String",
		updatedAt:"DateTime",
		_count:"AdBoardCountAggregate",
		_avg:"AdBoardAvgAggregate",
		_sum:"AdBoardSumAggregate",
		_min:"AdBoardMinAggregate",
		_max:"AdBoardMaxAggregate"
	},
	AggregateAdBoardGroup:{
		_count:"AdBoardGroupCountAggregate",
		_min:"AdBoardGroupMinAggregate",
		_max:"AdBoardGroupMaxAggregate"
	},
	AdBoardGroupCountAggregate:{
		id:"Int",
		name:"Int",
		adBoardId:"Int",
		scheduledPlaylistsIDs:"Int",
		adsPlaylistID:"Int",
		pisignageGroupId:"Int",
		fitImage:"Int",
		fitVideo:"Int",
		workspaceId:"Int",
		_all:"Int"
	},
	AdBoardGroupMinAggregate:{
		id:"String",
		name:"String",
		adBoardId:"String",
		adsPlaylistID:"String",
		pisignageGroupId:"String",
		fitImage:"FitType",
		fitVideo:"FitType",
		workspaceId:"String"
	},
	AdBoardGroupMaxAggregate:{
		id:"String",
		name:"String",
		adBoardId:"String",
		adsPlaylistID:"String",
		pisignageGroupId:"String",
		fitImage:"FitType",
		fitVideo:"FitType",
		workspaceId:"String"
	},
	AdBoardGroupGroupBy:{
		id:"String",
		name:"String",
		adBoardId:"String",
		scheduledPlaylistsIDs:"String",
		adsPlaylistID:"String",
		pisignageGroupId:"String",
		fitImage:"FitType",
		fitVideo:"FitType",
		workspaceId:"String",
		_count:"AdBoardGroupCountAggregate",
		_min:"AdBoardGroupMinAggregate",
		_max:"AdBoardGroupMaxAggregate"
	},
	AggregateAdBoardType:{
		_count:"AdBoardTypeCountAggregate",
		_min:"AdBoardTypeMinAggregate",
		_max:"AdBoardTypeMaxAggregate"
	},
	AdBoardTypeCountAggregate:{
		id:"Int",
		name:"Int",
		description:"Int",
		_all:"Int"
	},
	AdBoardTypeMinAggregate:{
		id:"String",
		name:"String",
		description:"String"
	},
	AdBoardTypeMaxAggregate:{
		id:"String",
		name:"String",
		description:"String"
	},
	AdBoardTypeGroupBy:{
		id:"String",
		name:"String",
		description:"String",
		_count:"AdBoardTypeCountAggregate",
		_min:"AdBoardTypeMinAggregate",
		_max:"AdBoardTypeMaxAggregate"
	},
	AggregateAudit:{
		_count:"AuditCountAggregate",
		_min:"AuditMinAggregate",
		_max:"AuditMaxAggregate"
	},
	AuditCountAggregate:{
		id:"Int",
		createdAt:"Int",
		message:"Int",
		action:"Int",
		userId:"Int",
		workspaceId:"Int",
		updates:"Int",
		_all:"Int"
	},
	AuditMinAggregate:{
		id:"String",
		createdAt:"DateTime",
		message:"String",
		action:"String",
		userId:"String",
		workspaceId:"String"
	},
	AuditMaxAggregate:{
		id:"String",
		createdAt:"DateTime",
		message:"String",
		action:"String",
		userId:"String",
		workspaceId:"String"
	},
	AuditGroupBy:{
		id:"String",
		createdAt:"DateTime",
		message:"String",
		action:"String",
		userId:"String",
		workspaceId:"String",
		updates:"JSON",
		_count:"AuditCountAggregate",
		_min:"AuditMinAggregate",
		_max:"AuditMaxAggregate"
	},
	AggregateBooking:{
		_count:"BookingCountAggregate",
		_min:"BookingMinAggregate",
		_max:"BookingMaxAggregate"
	},
	BookingCountAggregate:{
		id:"Int",
		additionalInformation:"Int",
		origin:"Int",
		assetsIDs:"Int",
		bookingCode:"Int",
		bookingDate:"Int",
		bookingFor:"Int",
		purpose:"Int",
		businessCategoryId:"Int",
		adBoardId:"Int",
		workspaceId:"Int",
		campaignId:"Int",
		bookingStatus:"Int",
		paymentStatus:"Int",
		deploymentStatus:"Int",
		contentApprovalStatus:"Int",
		userId:"Int",
		sharedUserIds:"Int",
		isOffline:"Int",
		isPaid:"Int",
		isScheduled:"Int",
		isDeployed:"Int",
		isCompleted:"Int",
		_all:"Int"
	},
	BookingMinAggregate:{
		id:"String",
		additionalInformation:"String",
		origin:"String",
		bookingCode:"String",
		bookingDate:"DateTime",
		bookingFor:"String",
		purpose:"String",
		businessCategoryId:"String",
		adBoardId:"String",
		workspaceId:"String",
		campaignId:"String",
		bookingStatus:"BookingStatus",
		paymentStatus:"PaymentStatus",
		deploymentStatus:"DeploymentStatus",
		contentApprovalStatus:"ContentApprovalStatus",
		userId:"String",
		isOffline:"Boolean",
		isPaid:"Boolean",
		isScheduled:"Boolean",
		isDeployed:"Boolean",
		isCompleted:"Boolean"
	},
	BookingMaxAggregate:{
		id:"String",
		additionalInformation:"String",
		origin:"String",
		bookingCode:"String",
		bookingDate:"DateTime",
		bookingFor:"String",
		purpose:"String",
		businessCategoryId:"String",
		adBoardId:"String",
		workspaceId:"String",
		campaignId:"String",
		bookingStatus:"BookingStatus",
		paymentStatus:"PaymentStatus",
		deploymentStatus:"DeploymentStatus",
		contentApprovalStatus:"ContentApprovalStatus",
		userId:"String",
		isOffline:"Boolean",
		isPaid:"Boolean",
		isScheduled:"Boolean",
		isDeployed:"Boolean",
		isCompleted:"Boolean"
	},
	BookingGroupBy:{
		id:"String",
		additionalInformation:"String",
		origin:"String",
		assetsIDs:"String",
		bookingCode:"String",
		bookingDate:"DateTime",
		bookingFor:"String",
		purpose:"String",
		businessCategoryId:"String",
		adBoardId:"String",
		workspaceId:"String",
		campaignId:"String",
		bookingStatus:"BookingStatus",
		paymentStatus:"PaymentStatus",
		deploymentStatus:"DeploymentStatus",
		contentApprovalStatus:"ContentApprovalStatus",
		userId:"String",
		sharedUserIds:"String",
		isOffline:"Boolean",
		isPaid:"Boolean",
		isScheduled:"Boolean",
		isDeployed:"Boolean",
		isCompleted:"Boolean",
		_count:"BookingCountAggregate",
		_min:"BookingMinAggregate",
		_max:"BookingMaxAggregate"
	},
	AggregateBusinessCategory:{
		_count:"BusinessCategoryCountAggregate",
		_min:"BusinessCategoryMinAggregate",
		_max:"BusinessCategoryMaxAggregate"
	},
	BusinessCategoryCountAggregate:{
		id:"Int",
		name:"Int",
		description:"Int",
		restrictedByAdBoardIds:"Int",
		_all:"Int"
	},
	BusinessCategoryMinAggregate:{
		id:"String",
		name:"String",
		description:"String"
	},
	BusinessCategoryMaxAggregate:{
		id:"String",
		name:"String",
		description:"String"
	},
	BusinessCategoryGroupBy:{
		id:"String",
		name:"String",
		description:"String",
		restrictedByAdBoardIds:"String",
		_count:"BusinessCategoryCountAggregate",
		_min:"BusinessCategoryMinAggregate",
		_max:"BusinessCategoryMaxAggregate"
	},
	AggregateCamera:{
		_count:"CameraCountAggregate",
		_min:"CameraMinAggregate",
		_max:"CameraMaxAggregate"
	},
	CameraCountAggregate:{
		id:"Int",
		name:"Int",
		isOnline:"Int",
		cameraServerId:"Int",
		cameraUrl:"Int",
		rtspUrl:"Int",
		cameraIp:"Int",
		connectedCpuSerialNumbers:"Int",
		playerId:"Int",
		updatedAt:"Int",
		isPublic:"Int",
		adBoardId:"Int",
		_all:"Int"
	},
	CameraMinAggregate:{
		id:"String",
		name:"String",
		isOnline:"Boolean",
		cameraServerId:"String",
		cameraUrl:"String",
		rtspUrl:"String",
		cameraIp:"String",
		playerId:"String",
		updatedAt:"DateTime",
		isPublic:"Boolean",
		adBoardId:"String"
	},
	CameraMaxAggregate:{
		id:"String",
		name:"String",
		isOnline:"Boolean",
		cameraServerId:"String",
		cameraUrl:"String",
		rtspUrl:"String",
		cameraIp:"String",
		playerId:"String",
		updatedAt:"DateTime",
		isPublic:"Boolean",
		adBoardId:"String"
	},
	CameraGroupBy:{
		id:"String",
		name:"String",
		isOnline:"Boolean",
		cameraServerId:"String",
		cameraUrl:"String",
		rtspUrl:"String",
		cameraIp:"String",
		connectedCpuSerialNumbers:"String",
		playerId:"String",
		updatedAt:"DateTime",
		isPublic:"Boolean",
		adBoardId:"String",
		_count:"CameraCountAggregate",
		_min:"CameraMinAggregate",
		_max:"CameraMaxAggregate"
	},
	AggregateCameraFeed:{
		_count:"CameraFeedCountAggregate",
		_min:"CameraFeedMinAggregate",
		_max:"CameraFeedMaxAggregate"
	},
	CameraFeedCountAggregate:{
		id:"Int",
		cameraId:"Int",
		timestamp:"Int",
		cameraServerId:"Int",
		_all:"Int"
	},
	CameraFeedMinAggregate:{
		id:"String",
		cameraId:"String",
		timestamp:"DateTime",
		cameraServerId:"String"
	},
	CameraFeedMaxAggregate:{
		id:"String",
		cameraId:"String",
		timestamp:"DateTime",
		cameraServerId:"String"
	},
	CameraFeedGroupBy:{
		id:"String",
		cameraId:"String",
		timestamp:"DateTime",
		cameraServerId:"String",
		_count:"CameraFeedCountAggregate",
		_min:"CameraFeedMinAggregate",
		_max:"CameraFeedMaxAggregate"
	},
	AggregateCameraServer:{
		_count:"CameraServerCountAggregate",
		_min:"CameraServerMinAggregate",
		_max:"CameraServerMaxAggregate"
	},
	CameraServerCountAggregate:{
		id:"Int",
		name:"Int",
		isOnline:"Int",
		hostName:"Int",
		userName:"Int",
		deviceId:"Int",
		workspaceId:"Int",
		publicIp:"Int",
		privateIp:"Int",
		_all:"Int"
	},
	CameraServerMinAggregate:{
		id:"String",
		name:"String",
		isOnline:"Boolean",
		hostName:"String",
		userName:"String",
		deviceId:"String",
		workspaceId:"String",
		publicIp:"String",
		privateIp:"String"
	},
	CameraServerMaxAggregate:{
		id:"String",
		name:"String",
		isOnline:"Boolean",
		hostName:"String",
		userName:"String",
		deviceId:"String",
		workspaceId:"String",
		publicIp:"String",
		privateIp:"String"
	},
	CameraServerGroupBy:{
		id:"String",
		name:"String",
		isOnline:"Boolean",
		hostName:"String",
		userName:"String",
		deviceId:"String",
		workspaceId:"String",
		publicIp:"String",
		privateIp:"String",
		_count:"CameraServerCountAggregate",
		_min:"CameraServerMinAggregate",
		_max:"CameraServerMaxAggregate"
	},
	AggregateCampaign:{
		_count:"CampaignCountAggregate",
		_min:"CampaignMinAggregate",
		_max:"CampaignMaxAggregate"
	},
	CampaignCountAggregate:{
		id:"Int",
		name:"Int",
		userId:"Int",
		createdAt:"Int",
		updatedAt:"Int",
		bookingFor:"Int",
		purpose:"Int",
		businessCategoryId:"Int",
		paymentStatus:"Int",
		origin:"Int",
		assetsIDs:"Int",
		_all:"Int"
	},
	CampaignMinAggregate:{
		id:"String",
		name:"String",
		userId:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		bookingFor:"String",
		purpose:"String",
		businessCategoryId:"String",
		paymentStatus:"PaymentStatus",
		origin:"String"
	},
	CampaignMaxAggregate:{
		id:"String",
		name:"String",
		userId:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		bookingFor:"String",
		purpose:"String",
		businessCategoryId:"String",
		paymentStatus:"PaymentStatus",
		origin:"String"
	},
	CampaignGroupBy:{
		id:"String",
		name:"String",
		userId:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		bookingFor:"String",
		purpose:"String",
		businessCategoryId:"String",
		paymentStatus:"PaymentStatus",
		origin:"String",
		assetsIDs:"String",
		_count:"CampaignCountAggregate",
		_min:"CampaignMinAggregate",
		_max:"CampaignMaxAggregate"
	},
	AggregateFile:{
		_count:"FileCountAggregate",
		_avg:"FileAvgAggregate",
		_sum:"FileSumAggregate",
		_min:"FileMinAggregate",
		_max:"FileMaxAggregate"
	},
	FileCountAggregate:{
		id:"Int",
		contentType:"Int",
		ctime:"Int",
		filename:"Int",
		filepath:"Int",
		isDirectory:"Int",
		mtime:"Int",
		name:"Int",
		size:"Int",
		status:"Int",
		type:"Int",
		updatedAt:"Int",
		createdAt:"Int",
		updatedByUserId:"Int",
		createdByUserId:"Int",
		url:"Int",
		bookingsIDs:"Int",
		workspaceId:"Int",
		proofBookingId:"Int",
		campaignIds:"Int",
		_all:"Int"
	},
	FileAvgAggregate:{
		size:"Float"
	},
	FileSumAggregate:{
		size:"Int"
	},
	FileMinAggregate:{
		id:"String",
		contentType:"String",
		ctime:"String",
		filename:"String",
		filepath:"String",
		isDirectory:"Boolean",
		mtime:"String",
		name:"String",
		size:"Int",
		status:"FileStatus",
		type:"String",
		updatedAt:"DateTime",
		createdAt:"DateTime",
		updatedByUserId:"String",
		createdByUserId:"String",
		url:"String",
		workspaceId:"String",
		proofBookingId:"String"
	},
	FileMaxAggregate:{
		id:"String",
		contentType:"String",
		ctime:"String",
		filename:"String",
		filepath:"String",
		isDirectory:"Boolean",
		mtime:"String",
		name:"String",
		size:"Int",
		status:"FileStatus",
		type:"String",
		updatedAt:"DateTime",
		createdAt:"DateTime",
		updatedByUserId:"String",
		createdByUserId:"String",
		url:"String",
		workspaceId:"String",
		proofBookingId:"String"
	},
	FileGroupBy:{
		id:"String",
		contentType:"String",
		ctime:"String",
		filename:"String",
		filepath:"String",
		isDirectory:"Boolean",
		mtime:"String",
		name:"String",
		size:"Int",
		status:"FileStatus",
		type:"String",
		updatedAt:"DateTime",
		createdAt:"DateTime",
		updatedByUserId:"String",
		createdByUserId:"String",
		url:"String",
		bookingsIDs:"String",
		workspaceId:"String",
		proofBookingId:"String",
		campaignIds:"String",
		_count:"FileCountAggregate",
		_avg:"FileAvgAggregate",
		_sum:"FileSumAggregate",
		_min:"FileMinAggregate",
		_max:"FileMaxAggregate"
	},
	AggregateMembership:{
		_count:"MembershipCountAggregate",
		_min:"MembershipMinAggregate",
		_max:"MembershipMaxAggregate"
	},
	MembershipCountAggregate:{
		id:"Int",
		createdAt:"Int",
		userId:"Int",
		workspaceId:"Int",
		roleId:"Int",
		_all:"Int"
	},
	MembershipMinAggregate:{
		id:"String",
		createdAt:"DateTime",
		userId:"String",
		workspaceId:"String",
		roleId:"String"
	},
	MembershipMaxAggregate:{
		id:"String",
		createdAt:"DateTime",
		userId:"String",
		workspaceId:"String",
		roleId:"String"
	},
	MembershipGroupBy:{
		id:"String",
		createdAt:"DateTime",
		userId:"String",
		workspaceId:"String",
		roleId:"String",
		_count:"MembershipCountAggregate",
		_min:"MembershipMinAggregate",
		_max:"MembershipMaxAggregate"
	},
	AggregateNotification:{
		_count:"NotificationCountAggregate",
		_min:"NotificationMinAggregate",
		_max:"NotificationMaxAggregate"
	},
	NotificationCountAggregate:{
		id:"Int",
		type:"Int",
		title:"Int",
		content:"Int",
		metadata:"Int",
		createdAt:"Int",
		readAt:"Int",
		recipientId:"Int",
		channels:"Int",
		_all:"Int"
	},
	NotificationMinAggregate:{
		id:"String",
		type:"NotificationType",
		title:"String",
		content:"String",
		createdAt:"DateTime",
		readAt:"DateTime",
		recipientId:"String"
	},
	NotificationMaxAggregate:{
		id:"String",
		type:"NotificationType",
		title:"String",
		content:"String",
		createdAt:"DateTime",
		readAt:"DateTime",
		recipientId:"String"
	},
	NotificationGroupBy:{
		id:"String",
		type:"NotificationType",
		title:"String",
		content:"String",
		metadata:"JSON",
		createdAt:"DateTime",
		readAt:"DateTime",
		recipientId:"String",
		channels:"NotificationChannel",
		_count:"NotificationCountAggregate",
		_min:"NotificationMinAggregate",
		_max:"NotificationMaxAggregate"
	},
	AggregateOrder:{
		_count:"OrderCountAggregate",
		_min:"OrderMinAggregate",
		_max:"OrderMaxAggregate"
	},
	OrderCountAggregate:{
		id:"Int",
		adBoardId:"Int",
		createdDate:"Int",
		createdByUserId:"Int",
		origin:"Int",
		paymentId:"Int",
		_all:"Int"
	},
	OrderMinAggregate:{
		id:"String",
		adBoardId:"String",
		createdDate:"DateTime",
		createdByUserId:"String",
		origin:"String",
		paymentId:"String"
	},
	OrderMaxAggregate:{
		id:"String",
		adBoardId:"String",
		createdDate:"DateTime",
		createdByUserId:"String",
		origin:"String",
		paymentId:"String"
	},
	OrderGroupBy:{
		id:"String",
		adBoardId:"String",
		createdDate:"DateTime",
		createdByUserId:"String",
		origin:"String",
		paymentId:"String",
		_count:"OrderCountAggregate",
		_min:"OrderMinAggregate",
		_max:"OrderMaxAggregate"
	},
	AggregatePlayer:{
		_count:"PlayerCountAggregate",
		_avg:"PlayerAvgAggregate",
		_sum:"PlayerSumAggregate",
		_min:"PlayerMinAggregate",
		_max:"PlayerMaxAggregate"
	},
	PlayerCountAggregate:{
		id:"Int",
		name:"Int",
		configLocation:"Int",
		pisignagePlayerId:"Int",
		pisignageGroupId:"Int",
		cpuSerialNumber:"Int",
		displayName:"Int",
		adBoardGroupId:"Int",
		workpaceId:"Int",
		aspectRatioType:"Int",
		customAspectRatioWidth:"Int",
		customAspectRatioHeight:"Int",
		isOnline:"Int",
		adBoardIds:"Int",
		_all:"Int"
	},
	PlayerAvgAggregate:{
		customAspectRatioWidth:"Float",
		customAspectRatioHeight:"Float"
	},
	PlayerSumAggregate:{
		customAspectRatioWidth:"Int",
		customAspectRatioHeight:"Int"
	},
	PlayerMinAggregate:{
		id:"String",
		name:"String",
		configLocation:"String",
		pisignagePlayerId:"String",
		pisignageGroupId:"String",
		cpuSerialNumber:"String",
		displayName:"String",
		adBoardGroupId:"String",
		workpaceId:"String",
		aspectRatioType:"AspectRatioType",
		customAspectRatioWidth:"Int",
		customAspectRatioHeight:"Int",
		isOnline:"Boolean"
	},
	PlayerMaxAggregate:{
		id:"String",
		name:"String",
		configLocation:"String",
		pisignagePlayerId:"String",
		pisignageGroupId:"String",
		cpuSerialNumber:"String",
		displayName:"String",
		adBoardGroupId:"String",
		workpaceId:"String",
		aspectRatioType:"AspectRatioType",
		customAspectRatioWidth:"Int",
		customAspectRatioHeight:"Int",
		isOnline:"Boolean"
	},
	PlayerGroupBy:{
		id:"String",
		name:"String",
		configLocation:"String",
		pisignagePlayerId:"String",
		pisignageGroupId:"String",
		cpuSerialNumber:"String",
		displayName:"String",
		adBoardGroupId:"String",
		workpaceId:"String",
		aspectRatioType:"AspectRatioType",
		customAspectRatioWidth:"Int",
		customAspectRatioHeight:"Int",
		isOnline:"Boolean",
		adBoardIds:"String",
		_count:"PlayerCountAggregate",
		_avg:"PlayerAvgAggregate",
		_sum:"PlayerSumAggregate",
		_min:"PlayerMinAggregate",
		_max:"PlayerMaxAggregate"
	},
	AggregatePlaylist:{
		_count:"PlaylistCountAggregate",
		_min:"PlaylistMinAggregate",
		_max:"PlaylistMaxAggregate"
	},
	PlaylistCountAggregate:{
		id:"Int",
		name:"Int",
		displayName:"Int",
		isAdsPlaylist:"Int",
		footerPlaylistId:"Int",
		sidebarPlaylistId:"Int",
		layout:"Int",
		isDeployed:"Int",
		adBoardGroupIds:"Int",
		workspaceId:"Int",
		_all:"Int"
	},
	PlaylistMinAggregate:{
		id:"String",
		name:"String",
		displayName:"String",
		isAdsPlaylist:"Boolean",
		footerPlaylistId:"String",
		sidebarPlaylistId:"String",
		layout:"ScreenLayout",
		isDeployed:"Boolean",
		workspaceId:"String"
	},
	PlaylistMaxAggregate:{
		id:"String",
		name:"String",
		displayName:"String",
		isAdsPlaylist:"Boolean",
		footerPlaylistId:"String",
		sidebarPlaylistId:"String",
		layout:"ScreenLayout",
		isDeployed:"Boolean",
		workspaceId:"String"
	},
	PlaylistGroupBy:{
		id:"String",
		name:"String",
		displayName:"String",
		isAdsPlaylist:"Boolean",
		footerPlaylistId:"String",
		sidebarPlaylistId:"String",
		layout:"ScreenLayout",
		isDeployed:"Boolean",
		adBoardGroupIds:"String",
		workspaceId:"String",
		_count:"PlaylistCountAggregate",
		_min:"PlaylistMinAggregate",
		_max:"PlaylistMaxAggregate"
	},
	AggregateSlotConfiguration:{
		_count:"SlotConfigurationCountAggregate",
		_avg:"SlotConfigurationAvgAggregate",
		_sum:"SlotConfigurationSumAggregate",
		_min:"SlotConfigurationMinAggregate",
		_max:"SlotConfigurationMaxAggregate"
	},
	SlotConfigurationCountAggregate:{
		id:"Int",
		adBoardId:"Int",
		date:"Int",
		availableSlots:"Int",
		_all:"Int"
	},
	SlotConfigurationAvgAggregate:{
		availableSlots:"Float"
	},
	SlotConfigurationSumAggregate:{
		availableSlots:"Int"
	},
	SlotConfigurationMinAggregate:{
		id:"String",
		adBoardId:"String",
		date:"DateTime",
		availableSlots:"Int"
	},
	SlotConfigurationMaxAggregate:{
		id:"String",
		adBoardId:"String",
		date:"DateTime",
		availableSlots:"Int"
	},
	SlotConfigurationGroupBy:{
		id:"String",
		adBoardId:"String",
		date:"DateTime",
		availableSlots:"Int",
		_count:"SlotConfigurationCountAggregate",
		_avg:"SlotConfigurationAvgAggregate",
		_sum:"SlotConfigurationSumAggregate",
		_min:"SlotConfigurationMinAggregate",
		_max:"SlotConfigurationMaxAggregate"
	},
	AggregateSubscription:{
		_count:"SubscriptionCountAggregate",
		_min:"SubscriptionMinAggregate",
		_max:"SubscriptionMaxAggregate"
	},
	SubscriptionCountAggregate:{
		id:"Int",
		packageId:"Int",
		packageName:"Int",
		priceLookupKey:"Int",
		startDate:"Int",
		endDate:"Int",
		status:"Int",
		stripePaymentIntentId:"Int",
		stripeSubscriptionId:"Int",
		stripeCheckoutSessionId:"Int",
		activeWorkspaceId:"Int",
		_all:"Int"
	},
	SubscriptionMinAggregate:{
		id:"String",
		packageId:"String",
		packageName:"String",
		priceLookupKey:"String",
		startDate:"DateTime",
		endDate:"DateTime",
		status:"String",
		stripePaymentIntentId:"String",
		stripeSubscriptionId:"String",
		stripeCheckoutSessionId:"String",
		activeWorkspaceId:"String"
	},
	SubscriptionMaxAggregate:{
		id:"String",
		packageId:"String",
		packageName:"String",
		priceLookupKey:"String",
		startDate:"DateTime",
		endDate:"DateTime",
		status:"String",
		stripePaymentIntentId:"String",
		stripeSubscriptionId:"String",
		stripeCheckoutSessionId:"String",
		activeWorkspaceId:"String"
	},
	SubscriptionGroupBy:{
		id:"String",
		packageId:"String",
		packageName:"String",
		priceLookupKey:"String",
		startDate:"DateTime",
		endDate:"DateTime",
		status:"String",
		stripePaymentIntentId:"String",
		stripeSubscriptionId:"String",
		stripeCheckoutSessionId:"String",
		activeWorkspaceId:"String",
		_count:"SubscriptionCountAggregate",
		_min:"SubscriptionMinAggregate",
		_max:"SubscriptionMaxAggregate"
	},
	AggregateUser:{
		_count:"UserCountAggregate",
		_min:"UserMinAggregate",
		_max:"UserMaxAggregate"
	},
	UserCountAggregate:{
		id:"Int",
		uid:"Int",
		email:"Int",
		image:"Int",
		phoneNumber:"Int",
		displayName:"Int",
		otherDetails:"Int",
		roles:"Int",
		sharedBookingIds:"Int",
		disclaimerAccepted:"Int",
		leadWorkspaceIds:"Int",
		_all:"Int"
	},
	UserMinAggregate:{
		id:"String",
		uid:"String",
		email:"String",
		image:"String",
		phoneNumber:"String",
		displayName:"String",
		disclaimerAccepted:"Boolean"
	},
	UserMaxAggregate:{
		id:"String",
		uid:"String",
		email:"String",
		image:"String",
		phoneNumber:"String",
		displayName:"String",
		disclaimerAccepted:"Boolean"
	},
	UserGroupBy:{
		id:"String",
		uid:"String",
		email:"String",
		image:"String",
		phoneNumber:"String",
		displayName:"String",
		otherDetails:"JSON",
		roles:"Role",
		sharedBookingIds:"String",
		disclaimerAccepted:"Boolean",
		leadWorkspaceIds:"String",
		_count:"UserCountAggregate",
		_min:"UserMinAggregate",
		_max:"UserMaxAggregate"
	},
	AggregateUserPosition:{
		_count:"UserPositionCountAggregate",
		_min:"UserPositionMinAggregate",
		_max:"UserPositionMaxAggregate"
	},
	UserPositionCountAggregate:{
		id:"Int",
		timestamp:"Int",
		createdAt:"Int",
		userId:"Int",
		_all:"Int"
	},
	UserPositionMinAggregate:{
		id:"String",
		timestamp:"DateTime",
		createdAt:"DateTime",
		userId:"String"
	},
	UserPositionMaxAggregate:{
		id:"String",
		timestamp:"DateTime",
		createdAt:"DateTime",
		userId:"String"
	},
	UserPositionGroupBy:{
		id:"String",
		timestamp:"DateTime",
		createdAt:"DateTime",
		userId:"String",
		_count:"UserPositionCountAggregate",
		_min:"UserPositionMinAggregate",
		_max:"UserPositionMaxAggregate"
	},
	AggregateWorkspace:{
		_count:"WorkspaceCountAggregate",
		_min:"WorkspaceMinAggregate",
		_max:"WorkspaceMaxAggregate"
	},
	WorkspaceCountAggregate:{
		id:"Int",
		type:"Int",
		name:"Int",
		key:"Int",
		userIds:"Int",
		ownerId:"Int",
		avatar:"Int",
		createdAt:"Int",
		updatedAt:"Int",
		stripeSubscriptionId:"Int",
		leadIds:"Int",
		_all:"Int"
	},
	WorkspaceMinAggregate:{
		id:"String",
		type:"WorkspaceType",
		name:"String",
		key:"String",
		ownerId:"String",
		avatar:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		stripeSubscriptionId:"String"
	},
	WorkspaceMaxAggregate:{
		id:"String",
		type:"WorkspaceType",
		name:"String",
		key:"String",
		ownerId:"String",
		avatar:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		stripeSubscriptionId:"String"
	},
	WorkspaceGroupBy:{
		id:"String",
		type:"WorkspaceType",
		name:"String",
		key:"String",
		userIds:"String",
		ownerId:"String",
		avatar:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		stripeSubscriptionId:"String",
		leadIds:"String",
		_count:"WorkspaceCountAggregate",
		_min:"WorkspaceMinAggregate",
		_max:"WorkspaceMaxAggregate"
	},
	AggregateWorkspaceRole:{
		_count:"WorkspaceRoleCountAggregate",
		_min:"WorkspaceRoleMinAggregate",
		_max:"WorkspaceRoleMaxAggregate"
	},
	WorkspaceRoleCountAggregate:{
		id:"Int",
		name:"Int",
		permissions:"Int",
		workspaceId:"Int",
		createdAt:"Int",
		updatedAt:"Int",
		_all:"Int"
	},
	WorkspaceRoleMinAggregate:{
		id:"String",
		name:"String",
		workspaceId:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime"
	},
	WorkspaceRoleMaxAggregate:{
		id:"String",
		name:"String",
		workspaceId:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime"
	},
	WorkspaceRoleGroupBy:{
		id:"String",
		name:"String",
		permissions:"Permission",
		workspaceId:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		_count:"WorkspaceRoleCountAggregate",
		_min:"WorkspaceRoleMinAggregate",
		_max:"WorkspaceRoleMaxAggregate"
	},
	Slot:{
		date:"String",
		slots:"Float"
	},
	Mutation:{
		createManyAdBoard:"AffectedRowsOutput",
		createOneAdBoard:"AdBoard",
		deleteManyAdBoard:"AffectedRowsOutput",
		deleteOneAdBoard:"AdBoard",
		updateManyAdBoard:"AffectedRowsOutput",
		updateOneAdBoard:"AdBoard",
		upsertOneAdBoard:"AdBoard",
		createManyAdBoardGroup:"AffectedRowsOutput",
		createOneAdBoardGroup:"AdBoardGroup",
		deleteManyAdBoardGroup:"AffectedRowsOutput",
		deleteOneAdBoardGroup:"AdBoardGroup",
		updateManyAdBoardGroup:"AffectedRowsOutput",
		updateOneAdBoardGroup:"AdBoardGroup",
		upsertOneAdBoardGroup:"AdBoardGroup",
		createManyAdBoardType:"AffectedRowsOutput",
		createOneAdBoardType:"AdBoardType",
		deleteManyAdBoardType:"AffectedRowsOutput",
		deleteOneAdBoardType:"AdBoardType",
		updateManyAdBoardType:"AffectedRowsOutput",
		updateOneAdBoardType:"AdBoardType",
		upsertOneAdBoardType:"AdBoardType",
		createManyAudit:"AffectedRowsOutput",
		createOneAudit:"Audit",
		deleteManyAudit:"AffectedRowsOutput",
		deleteOneAudit:"Audit",
		updateManyAudit:"AffectedRowsOutput",
		updateOneAudit:"Audit",
		upsertOneAudit:"Audit",
		createManyBooking:"AffectedRowsOutput",
		createOneBooking:"Booking",
		deleteManyBooking:"AffectedRowsOutput",
		deleteOneBooking:"Booking",
		updateManyBooking:"AffectedRowsOutput",
		updateOneBooking:"Booking",
		upsertOneBooking:"Booking",
		createManyBusinessCategory:"AffectedRowsOutput",
		createOneBusinessCategory:"BusinessCategory",
		deleteManyBusinessCategory:"AffectedRowsOutput",
		deleteOneBusinessCategory:"BusinessCategory",
		updateManyBusinessCategory:"AffectedRowsOutput",
		updateOneBusinessCategory:"BusinessCategory",
		upsertOneBusinessCategory:"BusinessCategory",
		createManyCamera:"AffectedRowsOutput",
		createOneCamera:"Camera",
		deleteManyCamera:"AffectedRowsOutput",
		deleteOneCamera:"Camera",
		updateManyCamera:"AffectedRowsOutput",
		updateOneCamera:"Camera",
		upsertOneCamera:"Camera",
		createManyCameraFeed:"AffectedRowsOutput",
		createOneCameraFeed:"CameraFeed",
		deleteManyCameraFeed:"AffectedRowsOutput",
		deleteOneCameraFeed:"CameraFeed",
		updateManyCameraFeed:"AffectedRowsOutput",
		updateOneCameraFeed:"CameraFeed",
		upsertOneCameraFeed:"CameraFeed",
		createManyCameraServer:"AffectedRowsOutput",
		createOneCameraServer:"CameraServer",
		deleteManyCameraServer:"AffectedRowsOutput",
		deleteOneCameraServer:"CameraServer",
		updateManyCameraServer:"AffectedRowsOutput",
		updateOneCameraServer:"CameraServer",
		upsertOneCameraServer:"CameraServer",
		createManyCampaign:"AffectedRowsOutput",
		createOneCampaign:"Campaign",
		deleteManyCampaign:"AffectedRowsOutput",
		deleteOneCampaign:"Campaign",
		updateManyCampaign:"AffectedRowsOutput",
		updateOneCampaign:"Campaign",
		upsertOneCampaign:"Campaign",
		createManyFile:"AffectedRowsOutput",
		createOneFile:"File",
		deleteManyFile:"AffectedRowsOutput",
		deleteOneFile:"File",
		updateManyFile:"AffectedRowsOutput",
		updateOneFile:"File",
		upsertOneFile:"File",
		createManyMembership:"AffectedRowsOutput",
		createOneMembership:"Membership",
		deleteManyMembership:"AffectedRowsOutput",
		deleteOneMembership:"Membership",
		updateManyMembership:"AffectedRowsOutput",
		updateOneMembership:"Membership",
		upsertOneMembership:"Membership",
		createManyNotification:"AffectedRowsOutput",
		createOneNotification:"Notification",
		deleteManyNotification:"AffectedRowsOutput",
		deleteOneNotification:"Notification",
		updateManyNotification:"AffectedRowsOutput",
		updateOneNotification:"Notification",
		upsertOneNotification:"Notification",
		createManyOrder:"AffectedRowsOutput",
		createOneOrder:"Order",
		deleteManyOrder:"AffectedRowsOutput",
		deleteOneOrder:"Order",
		updateManyOrder:"AffectedRowsOutput",
		updateOneOrder:"Order",
		upsertOneOrder:"Order",
		createManyPlayer:"AffectedRowsOutput",
		createOnePlayer:"Player",
		deleteManyPlayer:"AffectedRowsOutput",
		deleteOnePlayer:"Player",
		updateManyPlayer:"AffectedRowsOutput",
		updateOnePlayer:"Player",
		upsertOnePlayer:"Player",
		createManyPlaylist:"AffectedRowsOutput",
		createOnePlaylist:"Playlist",
		deleteManyPlaylist:"AffectedRowsOutput",
		deleteOnePlaylist:"Playlist",
		updateManyPlaylist:"AffectedRowsOutput",
		updateOnePlaylist:"Playlist",
		upsertOnePlaylist:"Playlist",
		createManySlotConfiguration:"AffectedRowsOutput",
		createOneSlotConfiguration:"SlotConfiguration",
		deleteManySlotConfiguration:"AffectedRowsOutput",
		deleteOneSlotConfiguration:"SlotConfiguration",
		updateManySlotConfiguration:"AffectedRowsOutput",
		updateOneSlotConfiguration:"SlotConfiguration",
		upsertOneSlotConfiguration:"SlotConfiguration",
		createManySubscription:"AffectedRowsOutput",
		createOneSubscription:"Subscription",
		deleteManySubscription:"AffectedRowsOutput",
		deleteOneSubscription:"Subscription",
		updateManySubscription:"AffectedRowsOutput",
		updateOneSubscription:"Subscription",
		upsertOneSubscription:"Subscription",
		createManyUser:"AffectedRowsOutput",
		createOneUser:"User",
		deleteManyUser:"AffectedRowsOutput",
		deleteOneUser:"User",
		updateManyUser:"AffectedRowsOutput",
		updateOneUser:"User",
		upsertOneUser:"User",
		createManyUserPosition:"AffectedRowsOutput",
		createOneUserPosition:"UserPosition",
		deleteManyUserPosition:"AffectedRowsOutput",
		deleteOneUserPosition:"UserPosition",
		updateManyUserPosition:"AffectedRowsOutput",
		updateOneUserPosition:"UserPosition",
		upsertOneUserPosition:"UserPosition",
		createManyWorkspace:"AffectedRowsOutput",
		createOneWorkspace:"Workspace",
		deleteManyWorkspace:"AffectedRowsOutput",
		deleteOneWorkspace:"Workspace",
		updateManyWorkspace:"AffectedRowsOutput",
		updateOneWorkspace:"Workspace",
		upsertOneWorkspace:"Workspace",
		createManyWorkspaceRole:"AffectedRowsOutput",
		createOneWorkspaceRole:"WorkspaceRole",
		deleteManyWorkspaceRole:"AffectedRowsOutput",
		deleteOneWorkspaceRole:"WorkspaceRole",
		updateManyWorkspaceRole:"AffectedRowsOutput",
		updateOneWorkspaceRole:"WorkspaceRole",
		upsertOneWorkspaceRole:"WorkspaceRole",
		updateUser:"User",
		createCustomUser:"User",
		deleteUserCustom:"GenericResponse",
		sendMail:"EmailOutput",
		createOneCampaignBooking:"Campaign",
		createOneAdboardPublicReview:"AdBoardPublicReview",
		refreshSubscription:"TypedGenericResponse",
		cancelSubscription:"TypedGenericResponse",
		updateBookingStatus:"Booking",
		updateBookingAsset:"Boolean",
		prepareBooking:"Booking",
		cancelBooking:"Booking",
		deployBooking:"Booking",
		updateDraft:"AdBoard",
		deleteDraft:"Boolean",
		addReviewComment:"AdBoardReviewComment",
		updateAdBoardStatus:"AdBoard",
		publishApprovedDraft:"AdBoard",
		addPlayersToAdboard:"AdBoard",
		createOneAdboardWithGroup:"AdBoard",
		deleteOneAdboardWithGroup:"AdBoard",
		updateOneScheduler:"AdBoardGroup",
		setupOnePlayer:"Player"
	},
	AffectedRowsOutput:{
		count:"Int"
	},
	GenericResponse:{
		success:"Boolean"
	},
	EmailOutput:{
		success:"Boolean"
	},
	TypedGenericResponse:{
		success:"Boolean"
	}
}

export const Ops = {
query: "Query" as const,
	mutation: "Mutation" as const
}