import StayCard from "@/components/StayCard/StayCard";
import { CarDataType, ExperiencesDataType } from "@/data/types";
import { AdboardFragment } from "@/hooks/useAdboards";
import { Transition } from "@headlessui/react";
import { FC, Fragment, useState } from "react";

export interface AnyReactComponentProps {
  className?: string;
  disabled?: boolean;
  listing?: AdboardFragment;
  experiences?: ExperiencesDataType;
  car?: CarDataType;
  onClick?: (listing: AdboardFragment) => void;
  onHover?: (listing?: AdboardFragment) => void;
  isSelected?: boolean;
  lat: number;
  isHovered?: boolean;
  lng: number;
}

function SelectedMarker({
  color
}){
  return  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-8 w-8"
    viewBox="0 0 20 20"
    fill={color}
  >
    <path
      fillRule="evenodd"
      d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
      clipRule="evenodd"
      stroke="white"      
      stroke-width="1"  
    />
  </svg>
}

function MarkerIcon(){
  return <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-8 w-8"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
      clipRule="evenodd"
      stroke="white"      
      stroke-width="1" 
    />
  </svg>
}

const MapMarker: FC<AnyReactComponentProps> = ({
  className = "",
  listing,
  isHovered,
  disabled,
  onClick,
  onHover,
  isSelected,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  if(!listing){
    return <></>
  }

  return (
    <div
      className={`nc-AnyReactComponent${listing?.id} relative  ${className} flex gap-2 ${disabled?"pointer-events-none":""}`}
      data-nc-id={"AnyReactComponent"+(listing?.id)}
      onClick={()=>onClick?.(listing)}
      onMouseEnter={() => {
        onHover?.(listing)
        setIsOpen(false)
      }}
      onMouseLeave={() =>{
        setIsOpen(false)
        onHover?.(undefined)
      }}
    >
      <span
        onClick={() => setIsOpen(!isOpen)}
        className={`${disabled?'text-gray-600':""} 
        ${isSelected?"z-10 text-black":""}
        ${isHovered?"scale-125 z-10 text-blue-800 -translate-y-10":""} 
        flex transition-all p-1 rounded-md  text-sm font-semibold items-center justify-center min-w-max hover:scale-150 hover:text-blue-800 text-primary-500 -translate-y-8 -translate-x-5`}
      >
        {/* ₹{shortenPrice(listing?.pricePerDay)} */}
        {/* <TvIcon className="w-5 h-5"/> */}
        {isHovered||isSelected ? 
          <SelectedMarker color={!isSelected?'#2196F3':'black'}/>
          :
          <MarkerIcon/>
        }

      </span>
      
      <Transition
        show={isOpen}
        as={Fragment}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div>
          <div className="absolute z-50 bottom-full pb-3 -left-12 w-[260px] aspect-w-1">
            <StayCard section="map-marker" size="small" data={listing} className="shadow-2xl" />
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default MapMarker;
