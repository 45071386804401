import { Popover, Transition } from '@headlessui/react';
import React, { Fragment, useEffect } from 'react'

import Checkbox from '@/shared/Checkbox/Checkbox';
import ButtonThird from '@/shared/Button/ButtonThird';
import ButtonPrimary from '@/shared/Button/ButtonPrimary';
import { XClear } from '@/containers/Bookings/RenderXClear';

export default function CheckboxFilter<T>({ title, options, onSave, selectedStatuses: defaultSelectedStatuses }: { selectedStatuses?: T[], title: string, options: { value: T, name: string }[], onSave?: (selectedStatuses: T[]) => void }) {

  const [selectedStatuses, setSelectedStatus] = React.useState<(T)[]>(defaultSelectedStatuses || [])

  function handleChangeAirlines(checked: boolean, value: T) {

    if (checked) {
      setSelectedStatus([...selectedStatuses, value]);
    } else {
      setSelectedStatus(selectedStatuses.filter((item) => item != value));
    }
  }
  function handleSave() {
    onSave?.(selectedStatuses.filter(item => item != 'all') as unknown as T[]);
  }

  useEffect(() => {
    setSelectedStatus(defaultSelectedStatuses || [])
  }, [defaultSelectedStatuses])

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button
            className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none
               ${open ? "!border-primary-500 " : ""}
                ${!!(defaultSelectedStatuses && defaultSelectedStatuses.length > 0)
          ? "!border-primary-500 bg-primary-50 text-primary-700"
          : ""
        }`}
          >
            <span>{defaultSelectedStatuses?.join(", ") || title}</span>
            {defaultSelectedStatuses && defaultSelectedStatuses.length ? (
              <span onClick={() => setSelectedStatus([])}>
                <XClear />
              </span>) : (
              <i className="las la-angle-down ml-2"></i>

            )}
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
              <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                <div className="relative flex flex-col px-5 py-6 space-y-5">

                  {options.map((item) => (
                    <div key={item.name} className="">
                      <Checkbox
                        name={item.name}
                        label={item.name}
                        checked={selectedStatuses.includes(item.value)}
                        onChange={(checked) =>
                          handleChangeAirlines(checked, item.value)
                        }
                      />
                    </div>
                  ))}
                </div>
                <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                  <ButtonThird
                    onClick={() => {
                      close();
                      setSelectedStatus([]);
                      onSave?.([]);
                    }}
                    sizeClass="px-4 py-2 sm:px-5"
                  >
                    Clear
                  </ButtonThird>
                  <ButtonPrimary
                    onClick={() => {
                      close();
                      handleSave()
                    }}

                    sizeClass="px-4 py-2 sm:px-5"
                  >
                    Apply
                  </ButtonPrimary>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );

}
