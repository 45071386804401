import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache, NormalizedCacheObject, from } from "@apollo/client";
import React, { PropsWithChildren, useContext, useMemo } from "react";

const typeLink = new HttpLink({
  uri:import.meta.env.VITE_PUBLIC_API_URL+"/api/typegraphql"
});

const ApolloCLientContext = React.createContext<{typeClient: ApolloClient<NormalizedCacheObject>; }|null>(null);

export const ApolloProviderWrapper = ({ children }: PropsWithChildren) => {

  // const {token} = useFirebaseContext();
  const typeClient = useMemo(() => {
    const authMiddleware = new ApolloLink( (operation, forward) => {
      const token = sessionStorage.getItem("token");
      // add the authorization to the headers
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          authorization: `Bearer ${token}`
        }
      }));
    
      // loadingBar?.continuousStart()
      return forward(operation).map(response => {
        // loadingBar?.complete();
        return response;
      });
    })

    return new ApolloClient({
      link: from([authMiddleware, typeLink]),
      cache: new InMemoryCache(),
      name: 'adboard-booking-web',
      version: '1.0'
    })
  }, [])

  return <ApolloCLientContext.Provider value={{typeClient}}>
    <ApolloProvider client={typeClient}>{children}</ApolloProvider>
  </ApolloCLientContext.Provider>
}

export const useApolloClient = () => {
  const context = useContext(ApolloCLientContext);
  if (context === undefined) {
    throw new Error('useApolloClient must be used within a ApolloProviderWrapper');
  }
  return context;
}