import Heading2 from "@/components/Heading/Heading2";
import { FC, useState } from "react";
import BookingCardsTab from "./BookingCardsTab";
import BookingStatusFilter from "./BookingStatusFilter";
import { BookingStatus } from "@/generated/zeus";

export interface SectionGridFilterCardProps {
  className?: string;
}

type Filters={
  status:BookingStatus[]
}

const BookingListTile: FC<SectionGridFilterCardProps> = ({
  className = "",
}) => {

  const [filters,setFilters] = useState<Filters>({
    status:[]
  });

  function handleSave(value:BookingStatus[]){
    setFilters({
      ...filters,
      status:value
    })
  }

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2
        heading="Your bookings"
        subHeading={<></>}
      />
 
      <div className="flex flex-col gap-4">
        
        <BookingStatusFilter onSave={handleSave}/>
        <div className="lg:col-span-3">
          <BookingCardsTab></BookingCardsTab>
        </div>
        
      </div>
      
    </div>
  );
};

export default BookingListTile;