/* eslint-disable */
// @ts-nocheck
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Category = {
  __typename?: 'Category';
  _id: Scalars['ID'];
  count?: Maybe<Scalars['Int']>;
  href?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  status?: Maybe<CategoryStatus>;
  thumbnail?: Maybe<Scalars['String']>;
};

export type CategoryInput = {
  count?: InputMaybe<Scalars['Int']>;
  href?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  status: CategoryStatus;
  thumbnail?: InputMaybe<Scalars['String']>;
};

export type CategoryMutationResponse = {
  __typename?: 'CategoryMutationResponse';
  success: Scalars['Boolean'];
};

export enum CategoryStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type CategoryUpdates = {
  status?: InputMaybe<CategoryStatus>;
};

export type Flag = {
  __typename?: 'Flag';
  _id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedBy: Scalars['String'];
  value: Scalars['String'];
};

export type FlagInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  value: Scalars['String'];
};

export type FlagUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  value: Scalars['String'];
};

export type GenericResponse = {
  __typename?: 'GenericResponse';
  success: Scalars['Boolean'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createCategory?: Maybe<CategoryMutationResponse>;
  createFlag?: Maybe<GenericResponse>;
  deleteCategory?: Maybe<CategoryMutationResponse>;
  deleteFlag?: Maybe<GenericResponse>;
  subscribe?: Maybe<GenericResponse>;
  unSubscribe?: Maybe<GenericResponse>;
  updateCategory?: Maybe<CategoryMutationResponse>;
  updateFlag?: Maybe<GenericResponse>;
};


export type MutationCreateCategoryArgs = {
  catetory: CategoryInput;
};


export type MutationCreateFlagArgs = {
  flag: FlagInput;
};


export type MutationDeleteCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteFlagArgs = {
  name: Scalars['String'];
};


export type MutationSubscribeArgs = {
  email: Scalars['String'];
};


export type MutationUnSubscribeArgs = {
  email: Scalars['String'];
};


export type MutationUpdateCategoryArgs = {
  id: Scalars['ID'];
  updates: CategoryUpdates;
};


export type MutationUpdateFlagArgs = {
  flag: FlagUpdateInput;
  name: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  flags?: Maybe<Array<Flag>>;
  getCategories?: Maybe<Array<Category>>;
  subscribers: Array<Subscriber>;
};

export type Subscriber = {
  __typename?: 'Subscriber';
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  status: SubscriptionStatus;
};

export enum SubscriptionStatus {
  Subscribed = 'SUBSCRIBED',
  Unsubscribed = 'UNSUBSCRIBED'
}

export type SubscribeMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type SubscribeMutation = { __typename?: 'Mutation', subscribe?: { __typename?: 'GenericResponse', success: boolean } | null };


export const SubscribeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"Subscribe"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"email"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"subscribe"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"email"},"value":{"kind":"Variable","name":{"kind":"Name","value":"email"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}}]}}]}}]} as unknown as DocumentNode<SubscribeMutation, SubscribeMutationVariables>;