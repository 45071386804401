import avatar1 from "@/images/BecomeAnAuthorImg.png";
import { FC } from "react";

export interface AvatarProps {
  containerClassName?: string;
  sizeClass?: string;
  radius?: string;
  imgUrl?: string;
  userName?: string;
  hasChecked?: boolean;
  hasCheckedClass?: string;
}

const Avatar: FC<AvatarProps> = ({
  containerClassName = "ring-1 ring-white dark:ring-neutral-900",
  sizeClass = "h-6 w-6 text-sm",
  radius = "rounded-full",
  imgUrl = avatar1,
}) => {

  const url = imgUrl || avatar1;

  return (
    <div className={`sm:w-12 sm:h-12 overflow-hidden ${sizeClass} ${containerClassName} ${radius}`}>
      <img className="w-full h-full" src={url} />
    </div>
  );
};

export default Avatar;
