import React from 'react'
import { Helmet } from 'react-helmet'
import SeoMap from './SeoMap'
import TopicalMap from './TopicalMap'
import BlogsMap from './BlogsMap'

export default function Sitemap() {
  return (
    <div className="nc-PageHome3 relative overflow-hidden">
      <Helmet>
        <title>Sitemap | AdBoard Booking</title>
      </Helmet>
      {/* GLASSMOPHIN */}
      {/* <BgGlassmorphism /> */}

      {/* SECTION HERO */}

      {/* <SectionOurFeatures type="type2" rightImg={rightImgPng} /> */}
      <div className="mb-24 lg:mb-32" id="contactUs">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            Sitemap
        </h2>
        <div className="container mx-auto mb-10">
          <div className="flex-shrink-0 grid grid-cols-1">
            <div className="flex flex-col lg:flex-row gap-4 items-start" >
              <SeoMap/>
              <TopicalMap/>
              <BlogsMap/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
