import useUserContext from "@/hooks/useUserContext";
import { useTypedLazyQuery } from "@/hooks/useZeus";
import { getDateTimeString } from "@/lib/utils";
import { Bell } from "lucide-react";
import { FC, useEffect } from "react";
import CommonLayout from "../PageAddListing1/CommonLayout";
import LoadingButton from "@/components/LoadingButton";
import Loader from "@/shared/FullScreenLoader/Loading";
import { $ } from "@/generated/zeus";

export interface PageAddListing1Props {}

const NotificationsPage: FC<PageAddListing1Props> = () => {

  const {user} = useUserContext()
  const [fetchNotification,{data,loading,fetchMore}] = useTypedLazyQuery({
    notifications:[{
      take:10,
      skip:$('skip','Int'),
      where:{
        recipientId:{
          equals: user?.id
        }
      }
    },{
      id:true,
      readAt:true,
      createdAt:true,
      title:true,
      content:true,
    }]
  })

  useEffect(()=>{
    if(user){
      fetchNotification()
    }
  },[user])

  // const unreadNotifications = data?.notifications.some((item)=>!item.readAt) 
  
  const solutions = data?.notifications||[];

  async function loadMoreNotifications(){
    await fetchMore({
      variables:{
        skip:solutions.length
      },
      updateQuery(previousData, { fetchMoreResult,variables:{skip}}) {
        const updatedFeed = previousData.notifications.slice(0);
        for (let i = 0; i < fetchMoreResult.notifications.length; ++i) {
          updatedFeed[skip + i] = fetchMoreResult.notifications[i];
        }
        return { ...previousData, notifications: updatedFeed };
      },
    },)
  }

  return (
    <CommonLayout
      index=""
      footer={data?.notifications && <>
        <div className="w-full flex justify-center">
          <LoadingButton onClick={loadMoreNotifications}>Load more</LoadingButton>
        </div>
      </>}
    >
      <>
        <h2 className="text-2xl font-semibold">Notifications</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8 relative">
          {/* ITEM */}

          {(loading || !user) && <Loader/>}
          {!solutions.length && <>
            <p className="text-sm text-gray-500 dark:text-gray-400">No new notifications</p>
          </>}
          {solutions.map((item, index) => (
            <a
              // onClick={()=>handleItemClick(item)}
              key={index}
              href={"#"}
              className="flex p-2 pr-8 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 relative items-center"
            >
              <Bell/>
              <div className="ml-3 sm:ml-4 space-y-1">
                <p className="text-sm font-medium text-gray-900 dark:text-gray-200">
                  {item.title}
                </p>
                <p className="text-xs sm:text-sm text-gray-500 dark:text-gray-400">
                  {item.content}
                </p>
                <p className="text-xs text-gray-400 dark:text-gray-400">
                  {getDateTimeString(item.createdAt)}
                </p>
              </div>
              {!item.readAt && 
                <span className="absolute right-1 top-1/2 transform -translate-y-1/2 w-2 h-2 rounded-full bg-blue-500"></span>
              }
            </a>
          ))}
        </div>
      </>
    </CommonLayout>
  );
};

export default NotificationsPage;
