import useUserContext from "@/hooks/useUserContext";
import { useTypedLazyQuery } from "@/hooks/useZeus";
import { FC, useEffect } from "react";
import CampaignCard from "./CampaignCard";
import { campaignListSelector } from "./CampaignCard";
import { SortOrder } from "@/generated/zeus";
import Pagination from "@/shared/Pagination/Pagination";
import Loader from "@/shared/FullScreenLoader/Loading";

export interface SectionGridFilterCardProps {
  className?: string;
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
}) => {
  const {user} = useUserContext();

  const [getCampaigns,{data:campaignsData,loading}] = useTypedLazyQuery({
    campaigns: [{
      orderBy:[{
        createdAt:SortOrder.desc
      }],
      where: {
        user:{
          is:{
            uid: {
              equals:user?.uid
            }
          }
        }
      }
    },campaignListSelector ]
  });

  useEffect(()=>{
    if(user?.uid){
      getCampaigns()
    }
  },[user])
 
  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <div className="mb-8 lg:mb-11">
        {/* <TabFilters /> */}
      </div>
      {loading && <Loader/>}

      <div className="lg:p-10 lg:bg-neutral-50 lg:dark:bg-black/20 grid grid-cols-1 gap-6  rounded-3xl">
        {campaignsData?.campaigns.map((item, index) => (
          <CampaignCard key={index} data={item} />
        ))}
        {!loading && campaignsData?.campaigns.length === 0 && (
  
          <div className="flex flex-col items-center justify-center">
            <h1 className="text-2xl font-semibold">You have no campaigns yet</h1>
            <p className="text-sm text-neutral-500 dark:text-neutral-400 mt-2">
              Please create a new campaign from your cart.
            </p>
    
          </div>
        )}
      </div>
      <div className="flex mt-16 justify-center items-center">
        <Pagination/>
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
