import BtnLikeIcon from "@/components/BtnLikeIcon/BtnLikeIcon";
import GallerySlider from "@/components/GallerySlider/GallerySlider";
// import { useCartContext } from "@/context/CartContext";
import { getBillboardInfo } from "@/containers/ListingDetailPage/BillboardInfo";
import { useCartContext } from "@/context/CartContext";
import { AdboardFragment } from "@/hooks/useAdboards";
import { fireEvent, titleToUrl } from "@/lib/utils";
import Badge from "@/shared/Badge/Badge";
import ButtonPrimary from "@/shared/Button/ButtonPrimary";
import ButtonSecondary from "@/shared/Button/ButtonSecondary";
import { ShoppingCart } from "lucide-react";
import { FC } from "react";
import { Link } from "react-router-dom";
import SaleOffBadge from "../SaleOffBadge/SaleOffBadge";

export interface StayCardHProps {
  className?: string;
  data?: AdboardFragment;
}

const StayCardH: FC<StayCardHProps> = ({
  className = "",
  data,
}) => {

  const {adBoards,toggleFromCart} = useCartContext()
  const isCartAdded = adBoards.some(item=>item.id==data?.id)
  if(!data){
    return <></>;
  }
  
  const {
    galleryImgs,
    title,
    originalPricePerDay,
    pricePerDay,
    id:id,
  } = data;

  const {impressionPerHour} = getBillboardInfo(data)

  const renderSliderGallery = () => {
    const discount = originalPricePerDay ? ((-(originalPricePerDay||0) + (pricePerDay||0))*100/(originalPricePerDay||0)) : 0;

    return (
      <div className="relative flex-shrink-0 w-full md:w-72 ">
        <GallerySlider
          ratioClass="aspect-w-6 aspect-h-5"
          galleryImgs={galleryImgs}
          uniqueID={`StayCardH_${id}`}
          href={'/hoardings/'+titleToUrl(data.title)+"/" + id + window.location.search}
        />
        <BtnLikeIcon className="absolute right-3 top-3" />
        {!!discount && <SaleOffBadge className="absolute left-3 top-3" desc={discount?`${discount.toFixed(0)}% off today`:""}/>}
      </div>
    );
  };

  const renderTienIch = () => {

    const specs = [{
      icon: "la la-buysellads",
      name: `${data.adsPerLoop} ads per loop`
    },{
      icon: "la-sync",
      name: `Loop duration ${data.adsDuration*data.adsPerLoop}s`
    },{
      icon: "la-clock",
      name: `Ads duration ${data.adsDuration}s`
    },{
      className:"",
      icon: "la-copy",
      name: `${impressionPerHour} impressions per hour`
    }]

    return (
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 mt-2 w-full">
        {specs.map(item => {
          return <div className={"col-span-1 "+item.className}>
            <div className="flex items-center space-x-3">
              <i className={"las text-lg " + item.icon}></i>
              <span className="text-sm text-neutral-500 dark:text-neutral-400">
                {item.name}
              </span>
            </div>
          </div>
        })}

      </div>
    );
  };

  function handleClick(){
    fireEvent('select_item',{
      ecommerce:{
        item_list_id: data?.id,
        item_list_name: data?.title,
        currency: "INR",
        items:[{
          item_id: data?.id,
          item_name: data?.title,
          item_category: data?.city,
          price: data?.pricePerDay,
          quantity: 1
        }]
      }
    })
  }

  const renderContent = () => {
    return (
      <Link to={'/hoardings/'+titleToUrl(data.title)+"/" + id + window.location.search} className="flex w-full">
        <div className="flex-grow py-3 px-3 sm:px-5 flex flex-col justify-between">
          <div className="space-y-2">
            <div className="flex flex-col space-x-2">
              <div className="flex flex-col justify-between w-full gap-2">
                <div className="flex gap-2">
                  <h2 className="text-lg font-medium capitalize flex gap-2">
                    <span className="line-clamp-2">{title}</span>
                  </h2>
                  <div>
                    <Badge className="whitespace-nowrap" name={data.players.length+" screen"+(data.players.length>1?'s':'') } color="green" />
                  </div>
                </div>
                <div className="flex items-center space-x-3">
                  <i className={"las text-lg la-map-marker-alt "}></i>
                  <span className="text-sm text-neutral-500 dark:text-neutral-400">
                    {data.city}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden sm:block w-full border-b border-neutral-100 dark:border-neutral-800 my-2"></div>
          {renderTienIch()}
          <div className="w-full border-b border-neutral-100 dark:border-neutral-800 my-2"></div>
          <div className="flex justify-between flex-row items-center w-full">
            <span className="text-base font-semibold text-secondary-500">
              {originalPricePerDay != pricePerDay && <>₹<s>{originalPricePerDay}</s>&nbsp;</>}₹{pricePerDay}
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                  /day
              </span>
            </span>
            <div className="flex gap-2">
              <Link onClick={handleClick} to={"/hoardings/"+titleToUrl(data.title)+"/"+data.id+window.location.search}  >
                <ButtonPrimary>
                  View Details
                </ButtonPrimary>
              </Link>
              {isCartAdded ? <ButtonPrimary
                bgClassName="bg-black"
                onClick={(e)=>{
                  e.preventDefault();
                  toggleFromCart(data.id)
                }}>
                <ShoppingCart size={16}/>
              </ButtonPrimary>:
                <ButtonSecondary onClick={(e)=>{
                  e.preventDefault();
                  toggleFromCart(data.id)
                }}><ShoppingCart size={16}/></ButtonSecondary>
              } 
             
            </div>
          </div>
        </div>
      </Link>
    );
  };

  return (
    <div
      className={`nc-StayCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow will-change-transform ${className}`}
      data-nc-id="StayCardH"
    >
      <div className="grid grid-cols-1 md:flex md:flex-row ">
        {renderSliderGallery()}
       
        {renderContent()}
       
      </div> 
    </div>
  );
};

export default StayCardH;
