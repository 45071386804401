
import firebase from 'firebase/compat/app';
import useUserContext from "@/hooks/useUserContext";
import { useEffect } from 'react';
import {auth} from 'firebaseui';
import { Helmet } from "react-helmet";
import Loader from '@/shared/FullScreenLoader/Loading';

export function SocialLogin({onSuccess}:{onSuccess?:()=>void}) {
  const {user,loading} = useUserContext();
  if(user?.uid){
    onSuccess?.() 
  }

  useEffect(()=>{

    if(loading){
      return;
    }

    const ui = auth.AuthUI.getInstance() || new auth.AuthUI(firebase.auth());

    ui.start('#firebaseui-auth-container',{
      // Popup signin flow rather than redirect flow.
      signInFlow: 'popup',
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
      ],
      
      callbacks:{
        signInSuccessWithAuthResult: ()=>{
          return false;
        }
      }
    }
    );
  },[loading])

  if(loading){
    return <Loader>
      <div>Authenticating user...</div>
    </Loader>
  }
  
  return (
    <>
      <Helmet>
        <link type="text/css" rel="stylesheet" href="https://www.gstatic.com/firebasejs/ui/6.1.0/firebase-ui-auth.css" />
      </Helmet>
      <div id="firebaseui-auth-container">
      </div>
    </>
    
  );
  
}