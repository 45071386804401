
import { mutation, useTypedQuery } from '@/hooks/useZeus'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { Button, Checkbox, Table } from 'flowbite-react';
import { getDateString } from '@/lib/utils';
import { BookingStatus } from '@/generated/zeus';
import useUserContext from '@/hooks/useUserContext';
import StatusBadge from '@/shared/Badge/StatusBadge';

export default function AssetBookings() {
  const {id} = useParams<{id:string}>();
  const { user} = useUserContext();
  const {data,refetch} = useTypedQuery({
    bookings:[{
      where:{
        OR:[{
          user:{
            is:{
              uid:{
                equals:user?.uid||""
              }
            } 
          }
        },{
          sharedUsers:{
            some:{
              uid:{
                equals:user?.uid||""
              }
            }
          }
        }]
        ,
        bookingStatus:{
          notIn:[BookingStatus.COMPLETED]
        },
        
      }
    },{
      id:true,
      selectedDates:{
        startDate:true,
        endDate:true
      },
      bookingStatus:true,
      adBoard:{
        title:true,
      },
      assets:[{},{
        id:true
      }]
    }]
  });

  async function deployFile(bookingId:string){
    await mutation({
      updateOneBooking:[{
        where:{
          id:bookingId
        },
        data:{
          bookingStatus:BookingStatus.PENDING_APPROVAL,
          assets:{
            set:[{
              id
            }]
          }
        }
      },{
        id:true
      }]
    })
    refetch();
  }

  return (
    <Table> 
      <Table.Head>
        <Table.HeadCell></Table.HeadCell>
        <Table.HeadCell>Adboard</Table.HeadCell>
        <Table.HeadCell>Booking status</Table.HeadCell>
        <Table.HeadCell>Booked dates</Table.HeadCell>
        <Table.HeadCell>Actions</Table.HeadCell>
      </Table.Head>
      <Table.Body className="divide-y">

        {data?.bookings.map(item=>{
          return <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
              <Checkbox checked={!!item.assets.some(item=>item.id==id)}/>
            </Table.Cell>
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
              <Link to={'/booking/'+item.id}>
                {item.adBoard.title}
              </Link>
            </Table.Cell>
            <Table.Cell><StatusBadge>{item.bookingStatus}</StatusBadge></Table.Cell>
            <Table.Cell>{getDateString(item.selectedDates.startDate)} to {getDateString(item.selectedDates.endDate)}</Table.Cell>
            <Table.Cell>
              {item.assets.some(item=>item.id==id) ? 'Deployed':<Button size={'xs'} color='light' onClick={()=>deployFile(item.id)}>Deploy</Button>}
              
            </Table.Cell>
          </Table.Row>
        })}
        
      </Table.Body>
    </Table> 
  )
}
