import { NavItemType } from "@/shared/Navigation/NavigationItem";

export const NAVIGATION_DEMO: NavItemType[] = [
  {
    id:'account',
    name: "Profile",
    href: "/account",
    // icon: UserCircleIco,
  },
  {
    id:'assets',
    name: "Media libary",
    href: "/media-assets",
    // icon: UserCircleIco,
  },
  {
    id:'assets',
    name: "My Campaigns",
    href: "/campaigns",
    // icon: UserCircleIco,
  },
  // {
  //   id:"messages",
  //   name: "Messages",
  //   href: "##",
  //   // icon: ChatBubbleBottomCenterTextIcon,
  // },
  // {
  //   id:"wishlists",
  //   name: "Wishlists",
  //   href: "/account-savelists",
  //   // icon: HeartIcon,
  // },
  {
    id:"bookings",
    name: "Booking",
    href: "/bookings",
    // icon: HomeIcon,
  },
  {
    name: "Help",
    href: "/contact",
    id:"help"
  },

  {
    name: "Logout",
    href: "/logout",
    id:"logout"
  },
];
