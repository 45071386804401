import React, { ReactElement } from 'react'


export function createPageContext<P extends {}>(initialValue:P){

  const PageContext = React.createContext<P & {
    setValue?:(key:string,value:unknown)=>void
      }>(initialValue);
  
  function PageProvider({children}:{children:ReactElement}){

    const [state,setState] = React.useState(initialValue);

    function setValue(key:string,value:unknown){
      setState({
        ...state,
        [key]:value
      })
    }

    return <PageContext.Provider value={{...state,setValue}}>
      {children}
    </PageContext.Provider>
  }

  return {
    PageContext,
    PageProvider
  }
}

