import React, { useEffect } from 'react'

export default function LiveStream({url}:{url:string}) {

  const [offline,setOffline] = React.useState(false)
  useEffect(()=>{
    fetch(url).catch(()=>{
      setOffline(true)
    })
  },[])

  if(offline){
    return <div className='bg-red-500 text-white p-2'>Camera is offline</div>
  }

  return (
    <iframe className='rounded-md absolute top-0 w-full h-full' src={url}/>
  )
}
