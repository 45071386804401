/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\nmutation UpdateUser( $accountDetails: AccountDetails!){\n  updateUser( accountDetails: $accountDetails) {\n    id\n  }\n}\n": types.UpdateUserDocument,
    "\n fragment BookingPart2 on Booking{\n    id\n    bookingDate\n    assets {\n      url\n      type\n      name\n    }\n    user{\n      email\n    }\n    bookingStatus\n    adBoard{\n      title\n      pricePerDay\n      address\n      galleryImgs\n    }\n    selectedDates{\n        startDate\n        endDate\n    }\n  }\n": types.BookingPart2FragmentDoc,
    "\n  query GetBookings ($where: BookingWhereInput){\n    bookings(where: $where) {\n      ...BookingPart2\n    }\n  }\n": types.GetBookingsDocument,
    "\n  query GetBookingsAccess($where: AccessLabelWhereInput!) {\n    accessLabels(where: $where) {\n      bookingIDs\n    }\n  }\n": types.GetBookingsAccessDocument,
    "\n  query GetBookingDetails($getBookingWhere2: BookingWhereUniqueInput!) {\n    getBooking(where: $getBookingWhere2) {\n      id\n      assets {\n        name\n        type\n        url\n      }\n      proofs{\n        url\n        mtime\n      }\n      adBoard {\n        slots\n        id\n        galleryImgs\n        screenSize {\n          height\n          width\n          unit\n        }\n        resolution {\n          height\n          width\n        }\n        address\n        title\n      }\n      bookingDate\n      bookingStatus\n      bookingCode\n      payment{\n        totalDetails{\n          total\n          subtotal\n          tax\n          discount\n        }\n      }\n    }\n  }\n": types.GetBookingDetailsDocument,
    "\n  query GetChangelogs($where: ChangelogWhereInput) {\n  changelogs(where: $where) {\n    userId\n    user {\n      displayName\n    }\n    createdAt\n    action\n    updates\n  }\n}\n": types.GetChangelogsDocument,
    "\n  query GetOrder($where: OrderWhereUniqueInput!) {\n    order(where: $where) {\n      paymentId\n      selectedSlots\n      origin\n      id\n      selectedDate{\n        startDate\n        endDate\n      }\n      adBoard{\n        id  \n        address \n        title\n        galleryImgs\n        pricePerDay\n      }\n      priceSummary{\n        rentPerDay \n        discount\n        totalRent  \n        serviceCharge\n        payableRent\n        days\n      }\n    }\n  }\n": types.GetOrderDocument,
    "\n  mutation PrepareBooking($bookingRequest: BookingRequest!){\n    prepareBooking(bookingRequest: $bookingRequest) {\n      id\n    }\n  }\n": types.PrepareBookingDocument,
    "  \n  query GetAdBoard($where: AdBoardWhereUniqueInput!){ \n    \n    adBoard(where: $where){\n      title\n      address\n      id\n      slots \n      description\n      status\n      resolution{\n        width\n        height\n      }\n      screenSize{\n        width \n        height\n        unit   \n      }\n      specifications{\n        name\n      }\n      aboutSpecifications{\n        name\n        value\n      }\n      pricePerDay\n      galleryImgs \n      map{\n        lat\n        lng\n      }\n    }\n  } \n": types.GetAdBoardDocument,
    "\nquery GetSlots($where: SlotWhereInput) {\n  blockedSlots: slots(where: $where) {\n    date,\n    bookingId\n    time\n  }\n} \n\n": types.GetSlotsDocument,
    "\nquery GetBookedSlots($where2: SlotWhereInput) {\n  getBookedSlots: slots(where: $where2) {\n    date,\n    bookingId\n    time\n  }\n} \n\n": types.GetBookedSlotsDocument,
    "\n  mutation DeleteOneFile($where: FileWhereUniqueInput!){\n    deleteOneFile(where: $where) {\n      url\n    }\n  }\n": types.DeleteOneFileDocument,
    "\n  fragment UploadedFilesFragment on File {\n      id\n      name,\n      url,\n      mtime\n      status\n      type\n      size\n      isDirectory\n      meta {\n        width \n        height \n        duration\n      }\n  }\n": types.UploadedFilesFragmentFragmentDoc,
    "\n  query UploadedFiles($filesWhere2: FileWhereInput,$sort: [FileOrderByWithRelationInput!]) {\n    files(where: $filesWhere2,orderBy:$sort) {\n        ...UploadedFilesFragment\n      }\n    }\n": types.UploadedFilesDocument,
    "\nquery GetUser($userWhere2: UserWhereUniqueInput!){\n  user(where: $userWhere2) {\n    id\n  }\n}": types.GetUserDocument,
    "\n\nquery GetBooking($getBookingWhere2: BookingWhereUniqueInput!) {\n  getBooking(where: $getBookingWhere2) {\n      id\n      bookingCode\n      bookingDetails{\n        email\n        phone\n      }\n      assets{\n        name\n        url\n        type\n      }\n      payment{\n        totalDetails{\n          total\n          subtotal\n          tax\n          discount\n        }\n      }\n      adBoard{\n        id\n        galleryImgs\n        address\n        title\n        pricePerDay\n      }\n      selectedDates{\n        startDate\n        endDate\n      }\n\n    }\n  }\n": types.GetBookingDocument,
    "\nfragment AdBoardFragment on AdBoard {\n    pricePerDay\n    status\n    id\n    status\n    slots\n    href\n    title\n    featuredImage\n    address\n    galleryImgs\n    resolution{\n      width\n      height\n    }\n    screenSize{\n      width\n      height\n      unit\n    }\n    map {\n      lat\n      lng\n    }\n}\n": types.AdBoardFragmentFragmentDoc,
    "\n  mutation UploadFile($data: FileCreateInput!){\n    createOneFile(data: $data) {\n      id\n    }\n  }\n": types.UploadFileDocument,
    "\nquery GetAdBoards($adBoardsWhere: AdBoardWhereInput) {\n  adBoards(where: $adBoardsWhere) {\n    ...AdBoardFragment\n  } \n}": types.GetAdBoardsDocument,
    "\n  mutation CreateOneOrder($data: OrderCreateInput!){\n    createOneOrder(data: $data) {\n      id\n    }\n  }\n": types.CreateOneOrderDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nmutation UpdateUser( $accountDetails: AccountDetails!){\n  updateUser( accountDetails: $accountDetails) {\n    id\n  }\n}\n"): (typeof documents)["\nmutation UpdateUser( $accountDetails: AccountDetails!){\n  updateUser( accountDetails: $accountDetails) {\n    id\n  }\n}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n fragment BookingPart2 on Booking{\n    id\n    bookingDate\n    assets {\n      url\n      type\n      name\n    }\n    user{\n      email\n    }\n    bookingStatus\n    adBoard{\n      title\n      pricePerDay\n      address\n      galleryImgs\n    }\n    selectedDates{\n        startDate\n        endDate\n    }\n  }\n"): (typeof documents)["\n fragment BookingPart2 on Booking{\n    id\n    bookingDate\n    assets {\n      url\n      type\n      name\n    }\n    user{\n      email\n    }\n    bookingStatus\n    adBoard{\n      title\n      pricePerDay\n      address\n      galleryImgs\n    }\n    selectedDates{\n        startDate\n        endDate\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetBookings ($where: BookingWhereInput){\n    bookings(where: $where) {\n      ...BookingPart2\n    }\n  }\n"): (typeof documents)["\n  query GetBookings ($where: BookingWhereInput){\n    bookings(where: $where) {\n      ...BookingPart2\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetBookingsAccess($where: AccessLabelWhereInput!) {\n    accessLabels(where: $where) {\n      bookingIDs\n    }\n  }\n"): (typeof documents)["\n  query GetBookingsAccess($where: AccessLabelWhereInput!) {\n    accessLabels(where: $where) {\n      bookingIDs\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetBookingDetails($getBookingWhere2: BookingWhereUniqueInput!) {\n    getBooking(where: $getBookingWhere2) {\n      id\n      assets {\n        name\n        type\n        url\n      }\n      proofs{\n        url\n        mtime\n      }\n      adBoard {\n        slots\n        id\n        galleryImgs\n        screenSize {\n          height\n          width\n          unit\n        }\n        resolution {\n          height\n          width\n        }\n        address\n        title\n      }\n      bookingDate\n      bookingStatus\n      bookingCode\n      payment{\n        totalDetails{\n          total\n          subtotal\n          tax\n          discount\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetBookingDetails($getBookingWhere2: BookingWhereUniqueInput!) {\n    getBooking(where: $getBookingWhere2) {\n      id\n      assets {\n        name\n        type\n        url\n      }\n      proofs{\n        url\n        mtime\n      }\n      adBoard {\n        slots\n        id\n        galleryImgs\n        screenSize {\n          height\n          width\n          unit\n        }\n        resolution {\n          height\n          width\n        }\n        address\n        title\n      }\n      bookingDate\n      bookingStatus\n      bookingCode\n      payment{\n        totalDetails{\n          total\n          subtotal\n          tax\n          discount\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetChangelogs($where: ChangelogWhereInput) {\n  changelogs(where: $where) {\n    userId\n    user {\n      displayName\n    }\n    createdAt\n    action\n    updates\n  }\n}\n"): (typeof documents)["\n  query GetChangelogs($where: ChangelogWhereInput) {\n  changelogs(where: $where) {\n    userId\n    user {\n      displayName\n    }\n    createdAt\n    action\n    updates\n  }\n}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetOrder($where: OrderWhereUniqueInput!) {\n    order(where: $where) {\n      paymentId\n      selectedSlots\n      origin\n      id\n      selectedDate{\n        startDate\n        endDate\n      }\n      adBoard{\n        id  \n        address \n        title\n        galleryImgs\n        pricePerDay\n      }\n      priceSummary{\n        rentPerDay \n        discount\n        totalRent  \n        serviceCharge\n        payableRent\n        days\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetOrder($where: OrderWhereUniqueInput!) {\n    order(where: $where) {\n      paymentId\n      selectedSlots\n      origin\n      id\n      selectedDate{\n        startDate\n        endDate\n      }\n      adBoard{\n        id  \n        address \n        title\n        galleryImgs\n        pricePerDay\n      }\n      priceSummary{\n        rentPerDay \n        discount\n        totalRent  \n        serviceCharge\n        payableRent\n        days\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation PrepareBooking($bookingRequest: BookingRequest!){\n    prepareBooking(bookingRequest: $bookingRequest) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation PrepareBooking($bookingRequest: BookingRequest!){\n    prepareBooking(bookingRequest: $bookingRequest) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "  \n  query GetAdBoard($where: AdBoardWhereUniqueInput!){ \n    \n    adBoard(where: $where){\n      title\n      address\n      id\n      slots \n      description\n      status\n      resolution{\n        width\n        height\n      }\n      screenSize{\n        width \n        height\n        unit   \n      }\n      specifications{\n        name\n      }\n      aboutSpecifications{\n        name\n        value\n      }\n      pricePerDay\n      galleryImgs \n      map{\n        lat\n        lng\n      }\n    }\n  } \n"): (typeof documents)["  \n  query GetAdBoard($where: AdBoardWhereUniqueInput!){ \n    \n    adBoard(where: $where){\n      title\n      address\n      id\n      slots \n      description\n      status\n      resolution{\n        width\n        height\n      }\n      screenSize{\n        width \n        height\n        unit   \n      }\n      specifications{\n        name\n      }\n      aboutSpecifications{\n        name\n        value\n      }\n      pricePerDay\n      galleryImgs \n      map{\n        lat\n        lng\n      }\n    }\n  } \n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nquery GetSlots($where: SlotWhereInput) {\n  blockedSlots: slots(where: $where) {\n    date,\n    bookingId\n    time\n  }\n} \n\n"): (typeof documents)["\nquery GetSlots($where: SlotWhereInput) {\n  blockedSlots: slots(where: $where) {\n    date,\n    bookingId\n    time\n  }\n} \n\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nquery GetBookedSlots($where2: SlotWhereInput) {\n  getBookedSlots: slots(where: $where2) {\n    date,\n    bookingId\n    time\n  }\n} \n\n"): (typeof documents)["\nquery GetBookedSlots($where2: SlotWhereInput) {\n  getBookedSlots: slots(where: $where2) {\n    date,\n    bookingId\n    time\n  }\n} \n\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteOneFile($where: FileWhereUniqueInput!){\n    deleteOneFile(where: $where) {\n      url\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteOneFile($where: FileWhereUniqueInput!){\n    deleteOneFile(where: $where) {\n      url\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment UploadedFilesFragment on File {\n      id\n      name,\n      url,\n      mtime\n      status\n      type\n      size\n      isDirectory\n      meta {\n        width \n        height \n        duration\n      }\n  }\n"): (typeof documents)["\n  fragment UploadedFilesFragment on File {\n      id\n      name,\n      url,\n      mtime\n      status\n      type\n      size\n      isDirectory\n      meta {\n        width \n        height \n        duration\n      }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query UploadedFiles($filesWhere2: FileWhereInput,$sort: [FileOrderByWithRelationInput!]) {\n    files(where: $filesWhere2,orderBy:$sort) {\n        ...UploadedFilesFragment\n      }\n    }\n"): (typeof documents)["\n  query UploadedFiles($filesWhere2: FileWhereInput,$sort: [FileOrderByWithRelationInput!]) {\n    files(where: $filesWhere2,orderBy:$sort) {\n        ...UploadedFilesFragment\n      }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nquery GetUser($userWhere2: UserWhereUniqueInput!){\n  user(where: $userWhere2) {\n    id\n  }\n}"): (typeof documents)["\nquery GetUser($userWhere2: UserWhereUniqueInput!){\n  user(where: $userWhere2) {\n    id\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\nquery GetBooking($getBookingWhere2: BookingWhereUniqueInput!) {\n  getBooking(where: $getBookingWhere2) {\n      id\n      bookingCode\n      bookingDetails{\n        email\n        phone\n      }\n      assets{\n        name\n        url\n        type\n      }\n      payment{\n        totalDetails{\n          total\n          subtotal\n          tax\n          discount\n        }\n      }\n      adBoard{\n        id\n        galleryImgs\n        address\n        title\n        pricePerDay\n      }\n      selectedDates{\n        startDate\n        endDate\n      }\n\n    }\n  }\n"): (typeof documents)["\n\nquery GetBooking($getBookingWhere2: BookingWhereUniqueInput!) {\n  getBooking(where: $getBookingWhere2) {\n      id\n      bookingCode\n      bookingDetails{\n        email\n        phone\n      }\n      assets{\n        name\n        url\n        type\n      }\n      payment{\n        totalDetails{\n          total\n          subtotal\n          tax\n          discount\n        }\n      }\n      adBoard{\n        id\n        galleryImgs\n        address\n        title\n        pricePerDay\n      }\n      selectedDates{\n        startDate\n        endDate\n      }\n\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nfragment AdBoardFragment on AdBoard {\n    pricePerDay\n    status\n    id\n    status\n    slots\n    href\n    title\n    featuredImage\n    address\n    galleryImgs\n    resolution{\n      width\n      height\n    }\n    screenSize{\n      width\n      height\n      unit\n    }\n    map {\n      lat\n      lng\n    }\n}\n"): (typeof documents)["\nfragment AdBoardFragment on AdBoard {\n    pricePerDay\n    status\n    id\n    status\n    slots\n    href\n    title\n    featuredImage\n    address\n    galleryImgs\n    resolution{\n      width\n      height\n    }\n    screenSize{\n      width\n      height\n      unit\n    }\n    map {\n      lat\n      lng\n    }\n}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UploadFile($data: FileCreateInput!){\n    createOneFile(data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation UploadFile($data: FileCreateInput!){\n    createOneFile(data: $data) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nquery GetAdBoards($adBoardsWhere: AdBoardWhereInput) {\n  adBoards(where: $adBoardsWhere) {\n    ...AdBoardFragment\n  } \n}"): (typeof documents)["\nquery GetAdBoards($adBoardsWhere: AdBoardWhereInput) {\n  adBoards(where: $adBoardsWhere) {\n    ...AdBoardFragment\n  } \n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateOneOrder($data: OrderCreateInput!){\n    createOneOrder(data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CreateOneOrder($data: OrderCreateInput!){\n    createOneOrder(data: $data) {\n      id\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;