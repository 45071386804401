import BgGlassmorphism from "@/components/BgGlassmorphism/BgGlassmorphism";
import { FC } from "react";
import { Helmet } from "react-helmet";

import Heading2 from "@/components/Heading/Heading2";
import Block from "@/components/Block";
import AssetBookings from "./AssetBookings";
import { useParams } from "react-router-dom";
import { useTypedQuery } from "@/hooks/useZeus";
import StatusBadge from "@/shared/Badge/StatusBadge";

export interface ListingFlightsPageProps {
  className?: string;
}

const MediaAsset: FC<ListingFlightsPageProps> = ({
  className = "",
}) => {

  const {id} = useParams<{id:string}>();

  const {data} = useTypedQuery({
    file:[{
      where:{
        id
      }
    },{
      status:true,
      name:true,
      size:true,
      meta:{
        duration:true,
        width:true,
        height:true,
      },
      mtime:true,
      id:true,
      url:true
    }]
  })

  return (
    <div
      className={`nc-ListingFlightsPage relative overflow-hidden ${className}`}
      data-nc-id="ListingFlightsPage"
    >
      <Helmet>
        <title>Adbaord Booking || Assets</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative">
        {/* SECTION */}
        <div
          className={`nc-SectionGridFilterCard ${className} min-h-screen mt-4`}
          data-nc-id="SectionGridFilterCard"
        >
          <Heading2
            heading="Media library"
            subHeading={"You can manage your media assets here"
            }
          />

          <div className="flex gap-4 flex-col mb-6">

            <Block title="Asset details">
              <div className="grid grid-cols-3">
                <div className="col-span-2">
                  
                  <div className="flex flex-col gap-2">
                    <div className="flex flex-col gap-2">
                      <div className="flex flex-col gap-1">
                        <div className="text-sm text-gray-400">Status</div>
                        <div className="text-sm font-medium"><StatusBadge>{data?.file?.status}</StatusBadge></div>
                      </div>
                      <div className="flex flex-col gap-1">
                        <div className="text-sm text-gray-400">Name</div>
                        <div className="text-sm font-medium">{data?.file?.name}</div>
                      </div>
                      <div className="flex flex-col gap-1">
                        <div className="text-sm text-gray-400">Size</div>
                        <div className="text-sm font-medium">{data?.file?.size}</div>
                      </div>
                      <div className="flex flex-col gap-1">
                        <div className="text-sm text-gray-400">Modified</div>
                        <div className="text-sm font-medium">{data?.file?.mtime}</div>
                      </div>
                    </div>
                    <div className="flex flex-col gap-2">
                      <div className="flex flex-col gap-1">
                        <div className="text-sm text-gray-400">Width</div>
                        <div className="text-sm font-medium">{data?.file?.meta?.width}</div>
                      </div>
                      <div className="flex flex-col gap-1">
                        <div className="text-sm text-gray-400">Height</div>
                        <div className="text-sm font-medium">{data?.file?.meta?.height}</div>
                      </div>
                      {!!data?.file?.meta.duration && 
                      <div className="flex flex-col gap-1">
                        <div className="text-sm text-gray-400">Duration</div>
                        <div className="text-sm font-medium">{data?.file?.meta?.duration}</div>
                      </div>
                      }
                    </div>
                  </div>
                </div>
                <div className="p-4">
                  {data?.file?.url &&
                    <div className={'Sirv'} data-options="fit:crop" data-src={data.file.url} ></div>
                  }
                </div>
              </div>
            </Block>
            <Block title="Active Bookings">
              <AssetBookings></AssetBookings>
            </Block>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaAsset;
