import React, { ReactNode } from 'react'
import Badge from './Badge'
import { BookingStatus, FileStatus } from '@/generated/zeus'

export default function StatusBadge(props:{name?:string,children?:ReactNode}) {
  if(!props.name && !props.children){
    return <></>
  }

  const colorMap:any = {
    [FileStatus.APPROVED]:"green",
    [FileStatus.REQUEST_APPROVAL]:"yellow",
    [FileStatus.REJECTED]:"red",
    [BookingStatus.PENDING_APPROVAL]:"yellow",
    [BookingStatus.LIVE]:"blue",
    [BookingStatus.CANCELLED]:"red",
    [BookingStatus.COMPLETED]:"green",
  }

  const name = (props.name||props.children||"") as string

  return (
    <Badge color={colorMap[name] || 'blue'} name={name} ></Badge>
  )
}
