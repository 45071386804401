import LocationMarker from "@/components/AnyReactComponent/LocationMarker";
import StayDatesRangeInput from "@/components/HeroSearchForm/StayDatesRangeInput";
import { DateRage } from "@/components/HeroSearchForm/StaySearchForm";
import SectionSubscribe2 from "@/components/SectionSubscribe2/SectionSubscribe2";
import { baseTitle, DATE_FORMAT, FEES, FEES_AFTER_DISCOUNT, GMAP_API_KEY, GST } from "@/contains/contants";
import GoogleMapReact from "google-map-react";
import useWindowSize from "@/hooks/useWindowResize";
// import carUtilities4 from "@/images/carUtilities/4.png";
import moment, { Moment } from "moment";
import React, { FC, useEffect, useState } from "react";
import {
  DayPickerRangeController,
  FocusedInputShape,
  isInclusivelyAfterDay
} from "react-dates";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import Badge from "@/shared/Badge/Badge";
import ButtonPrimary from "@/shared/Button/ButtonPrimary";
import Loader from "@/shared/FullScreenLoader/Loading";
import NcImage from "@/shared/NcImage/NcImage";
import MobileFooterSticky from "./MobileFooterSticky";
import ModalPhotos from "./ModalPhotos";
import ButtonSecondary from '@/shared/Button/ButtonSecondary';
import { useCartContext } from '@/context/CartContext';
import { fireEvent, formatPrice, getDisplayName, mapAdboardToGtagItem, setPageTitle } from '@/lib/utils';
import { CheckIcon } from '@heroicons/react/24/solid';
import { useTypedLazyQuery, useTypedQuery } from '@/hooks/useZeus';
import { $, AdBoardStatus, WorkspaceType } from "@/generated/zeus";
import { adBoardSelector } from "./types";
// import RuntimeHistory from "./RuntimeHistory";
import ButtonThird from "@/shared/Button/ButtonThird";
import { Dialog } from "@/components/Dialog/Dialog";
import PlayerLivestream from "./PlayerLivestream";
import { findCenter } from "./map.utils";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { toast } from "react-toastify";
import BillboardInfo from "./BillboardInfo";
import Avatar from "@/shared/Avatar/Avatar";
import PublisherSection from "./PublisherSection";

export interface ListingStayDetailPageProps {
  className?: string;
  isPreviewMode?: boolean;
}

const AdboardDetailPage: FC<ListingStayDetailPageProps> = ({
  className = "",
  isPreviewMode,
}) => { 
  const mapsRef = React.useRef<any>(null);
  const mapRef = React.useRef<any>(null);
  const [mapsLoaded, setMapsLoaded] = React.useState(false);  
  const { id } = useParams<{ id: string }>();
  const [loading] = React.useState(false);
  const navigate = useNavigate();
  const {toggleFromCart,adBoards} = useCartContext()
  const isCartAdded = adBoards.some(item=>item.id==id);
  const [visibleMonth, setVisibleMonth] = useState<Moment>();

  const { data,refetch } = useTypedQuery({
    adBoard:[{
      where:{
        id:$('id','String!')
      }
    },adBoardSelector]
  },{
    variables:{
      id
    }
  })

  useEffect(()=>{
    refetch({
      id
    });
  },[id])

  const [slotMap,setSlotMap] = React.useState<{[key:string]:number}>({});

  const [getSlotsAvailibility,{data:getAdBoardAvailabilityData}] = useTypedLazyQuery({
    getAdBoardAvailability:[{
      adBoardId:id as string,
      month: $('month','Float!'),
      year:$('year','Float!'),
    },{
      date:true,
      slots:true
    }],
  })

  useEffect(()=>{
    
    if(!visibleMonth){
      return;
    }
    const year = visibleMonth?.get('year')
    const month =  visibleMonth?.get('month')+1

    getSlotsAvailibility({
      variables:{
        month,
        year
      }
    })

  },[visibleMonth])

  useEffect(()=>{
    const currentMonth = moment().get('month')+1;
    const year = moment().get('year')
    
    getSlotsAvailibility({
      variables:{
        month:currentMonth,
        year
      }
    }).then(()=>{
      getSlotsAvailibility({
        variables:{
          month:currentMonth+1,
          year:currentMonth==12?year+1:year
        }
      })
    })
    
  },[])

  useEffect(()=>{
    const map={}

    for(const item of getAdBoardAvailabilityData?.getAdBoardAvailability||[]){
      map[item.date] = item.slots
    }

    setSlotMap(prev=>{
      return {
        ...prev,
        ...map
      }
    })

  },[getAdBoardAvailabilityData])

  // console.log({slotMap})

  const blockedDates = []

  const listingDetails = data?.adBoard;
  const params = new URLSearchParams(window.location.search);
  const startDate = moment(params.get('startDate') || new Date(), DATE_FORMAT);
  // const [selectedSlots, setSelectedSlots] = useState([-1])

  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);

  const endDate = moment(params.get('endDate') || new Date(), DATE_FORMAT);
  // const [maxDate, setMaxDate] = React.useState<Moment>();
  const [selectedDate, setSelectedDate] = useState<DateRage>({
    startDate,
    endDate
  });

  useEffect(()=>{
    if(data?.adBoard?.id){
      setPageTitle(`Book ${data.adBoard?.title} billboard in ${data.adBoard.city} | ${baseTitle}`)
      fireEvent('view_item',{
        ecommerce:{
          currency: "INR",
          // value: data.adBoard.pricePerDay,
          items: [
            mapAdboardToGtagItem(data.adBoard,{
              startDate:startDate.toISOString(),
              endDate:endDate.toISOString(),
            })
          ]
        }
      })
    }
  },[data?.adBoard])

  useEffect(()=>{
    if(data?.adBoard && mapsLoaded){
      const bounds = new mapsRef.current.LatLngBounds();
      for(const item of data.adBoard.players||[]){
        if(item.map){
          bounds.extend({ lat: item.map.lat, lng: item.map.lng })
        }
      }
      mapRef.current.fitBounds(bounds);
    }
  },[data?.adBoard,mapsLoaded])

  const [focusedInputSectionCheckDate, setFocusedInputSectionCheckDate] =
    useState<FocusedInputShape>("startDate");

  const windowSize = useWindowSize();

  const getDaySize = () => {
    if (windowSize.width <= 375) {
      return 34;
    }
    if (windowSize.width <= 500) {
      return undefined;
    }
    if (windowSize.width <= 1280) {
      return 56;
    }
    return 48;
  };

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  if (!listingDetails) {
    return <Loader fullScreen />
  }

  const renderSection1 = () => {
    if (!listingDetails) return null;
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex gap-4 lg:items-center justify-between flex-col lg:flex-row items-start">

          <div className="flex gap-2 items-center">
            {data.adBoard?.offlineBookingsEnabled && <Badge name="Offline booking" />}
            <Badge name="Outdoor" />
            {/* <LikeSaveBtns /> */}
          </div>
          <div>
            <Dialog title={"Adboard live stream"}>
              <Dialog.Trigger>
                <ButtonThird>
                  View live stream
                </ButtonThird>
              </Dialog.Trigger>
              <Dialog.Body>
                <PlayerLivestream adBoardId={listingDetails.id}></PlayerLivestream>
              </Dialog.Body>
            </Dialog>
          </div>

        </div>

        {/* 2 */}
        <div className=''>
        
          <h1 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
            {listingDetails.title}
            {/* View live stream button */}
          </h1>
       
          {listingDetails.status == AdBoardStatus.COMING_SOON && 
          <div>
            <Badge
              name="Coming soon"
              color="yellow"
            /></div>}
        </div>
        {/* 3 */}
        <div className="flex items-center space-x-4">
          {/* <StartRating /> */}
          {/* <span>·</span> */}
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> {listingDetails.city}</span>
          </span>
        </div>
        <div className="flex items-center space-x-3 text-sm text-neutral-700  dark:text-neutral-300">
          <Avatar
            hasChecked
            hasCheckedClass="w-6 h-6 -top-0.5 right-2"
            sizeClass="w-6 h-6"
          />
          <span className="inline-block">
            <span className="inline">Hosted by</span>{" "}
            <a href={"/publisher/"+listingDetails.workspace.key} className="underline">
              {listingDetails.workspace.name}
            </a>
          </span>
        </div>
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        {/* 6 */}
        <div className="flex lg:items-center items-start justify-between xl:justify-start lg:space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300 lg:flex-row flex-col">
          
          <div className="flex items-center space-x-3">
            <i className=" las la-door-open text-2xl"></i>
            <span className=" ">
              <span className="sm:inline-block">Ads durations &nbsp;</span>{listingDetails.adsDuration}s
            </span>
          </div>
          <div className="flex items-center space-x-3"> 
            <i className=" las la-door-open text-2xl"></i>
            <span className=" ">
              <span className="sm:inline-block">Ads per loop &nbsp;</span>{listingDetails.adsPerLoop}
            </span>
          </div>
          <div className="flex items-center space-x-3"> 
            <i className=" las la-door-open text-2xl"></i>
            <span className=" ">
              <span className="sm:inline-block">Loop time &nbsp;</span>{listingDetails.adsDuration *listingDetails.adsPerLoop }s
            </span>
          </div>
        </div>
      </div>
    );
  }; 

  const renderAbout = () => {

    // const aboutSpecifications = (data.adBoard?.aboutSpecifications || []).map(item => {
    //   return {
    //     ...item,
    //     icon: carUtilities4
    //   }
    // });
   
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">About</h2>
          <span className="block my-2 mb-4 text-neutral-500 dark:text-neutral-400 whitespace-pre-wrap">
            {data.adBoard?.description}
          </span>
          {data.adBoard && 
            <BillboardInfo adBoard={data.adBoard}/>
          }
        </div>
        {/* {!!aboutSpecifications.length &&<>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-6 gap-x-10 text-sm text-neutral-700 dark:text-neutral-300 ">
            {aboutSpecifications.map((item, index) => (
              <div key={index} className="flex items-center space-x-4 ">
                <div className="w-10 flex-shrink-0">
                  <img src={item.icon} alt="" />
                </div>
                <span>{item.name}</span>
                <span>-</span>
                <span>{item.value}</span>
              </div>
            ))}
          </div>
        </> 
        } */}
        {/* CONTENT */}
       
      </div>
    );
  };

  const renderAvailibility = () => {
    if (!listingDetails) return null;

    const isBlocked = (day:Moment) => {
      return (blockedDates.some(date => day.isSame(date, 'day')))
    } 
    return (
      <div className="listingSection__wrap overflow-hidden">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Availability</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Select the dates for your booking
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}

        <div className="listingSection__wrap__DayPickerRangeController flow-root">
          <div className="-mx-4 sm:mx-auto">
            <DayPickerRangeController
              isDayBlocked={isBlocked}
              minimumNights={0}
              startDate={selectedDate.startDate}
              endDate={selectedDate.endDate}
              onDatesChange={(date) => { setSelectedDate(date) }}
              focusedInput={focusedInputSectionCheckDate}
              onFocusChange={(focusedInput) =>
                setFocusedInputSectionCheckDate(focusedInput || "startDate")
              }
              onNextMonthClick={(newVisibleMonth) => setVisibleMonth(newVisibleMonth)}
              initialVisibleMonth={null}
              numberOfMonths={windowSize.width < 1280 ? 1 : 2}
              daySize={getDaySize()}
              isOutsideRange={(day) => {
                return !isInclusivelyAfterDay(day, moment()) || slotMap[day.format('YYYY-MM-DD')]==0
              }}
              hideKeyboardShortcutsPanel={true}
              renderDayContents={(day) => {
                const slots = slotMap[day.format('YYYY-MM-DD')] == undefined ? data.adBoard?.adsPerLoop : slotMap[day.format('YYYY-MM-DD')];
                const fraction = (slots||0)/(data.adBoard?.adsPerLoop||1);
                let color = 'text-green-500'
                if(fraction == 0){
                  color = 'text-red-500'
                }else if(fraction>0.5){
                  color = 'text-green-500'
                }else{
                  color = 'text-yellow-500'
                }

                return (
                  <div className="relative flex flex-col">
                    {day.format("D")}
                    {day.isSameOrAfter(moment(),'D') && 
                    <div className={"absolute top-3 w-full text-center text-[9px] " + color}>
                      {slots!=undefined?slots:data.adBoard?.adsPerLoop} slots
                    </div>
                    }
                  </div>
                );
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderLocation = () => {
    if (!listingDetails) return null;
    let center = findCenter(listingDetails.players.map(item=>item.map))
    if(isNaN(center.lat) || isNaN(center.lng)){
      center = {
        lat:listingDetails.map.lat,
        lng:listingDetails.map.lng
      }
    }
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Screen location</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            This adboard has {listingDetails.players.length||0} screen(s).
          </span>
        </div>
        <Table className=''>
          <TableHeader>
            <TableRow>
              <TableHead>Screen name</TableHead>
              <TableHead>Addresss</TableHead>
              <TableHead>Resolution</TableHead>
              <TableHead>Size(in ft)</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody className=''>
            {[...listingDetails.players].sort((a,b)=>{
              return ( b.screenSize?.width||0)*(b.screenSize?.height||0) - ( a.screenSize?.width||0)*(a.screenSize?.height||0)
            }).map((item)=>(  
              <TableRow key="user1">
                <TableCell>{getDisplayName(item.name)}</TableCell>
                <TableCell>{item.map?.address||item.map?.city}</TableCell>
                <TableCell>
                  {item.resolution?.width} x {item.resolution?.height} 
                </TableCell>
                <TableCell className="whitespace-nowrap">{item.screenSize?.width} x {item.screenSize?.height} ft</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
          <div className="rounded-xl overflow-hidden">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: GMAP_API_KEY,
              }}
              yesIWantToUseGoogleMapApiInternals
              defaultZoom={15}
              defaultCenter={center}
              onGoogleApiLoaded={({maps,map}) => {
                mapsRef.current = maps  
                mapRef.current = map;
                setMapsLoaded(true)
              }}
            >
              {/* <LocationMarker lat={listingDetails.map.lat} lng={listingDetails.map.lng} /> */}
              {listingDetails.players.map(item=>{
                if(item.map){
                  return <LocationMarker lat={item.map?.lat} lng={item.map.lng} />
                }
              })}
            </GoogleMapReact>
          </div>
        </div>
      </div>
    );
  };

  const renderThingsToKnow = () => {
    if (!listingDetails) return null;
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Things to know</h2>

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Cancellation policy</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            This booking is non-refundable.
          </span>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            You will be able to update your media file after booking in case uploaded media not approved by admin.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        {listingDetails.playbackTimes.length ?
          <>
            <div>
              <h4 className="text-lg font-semibold">Playback times</h4>
              <div className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
                {listingDetails.playbackTimes?.map((item, index) => ( 
                  <div key={index} className="mb-2 flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
                    <span>SLOT - {index+1}</span>
                    <span>{item.startTime} - {item.endTime}</span>
                  </div>
                ))}
              </div>
            </div>

            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
          </>
          :null}

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Special Note</h4>
          <div className="prose sm:prose">
            <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
              <li>
                Ads will be played in shared mode, in a loop with other ads
              </li>
              <li>Ads will not be played outsite the playback runtime</li>
              <li>Ads will be played only once approved by Administrator</li>
              <li>Assets can be updated multiple times before your scheduled playback time</li>
              {/* <li>10 to 30 seconds ad will be played 15 times in an hour</li> */}
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const renderSidebar = () => {
    if (!listingDetails) return null;
    const diff = (selectedDate.endDate?.diff(selectedDate.startDate, 'days') || 0) + 1;
    const rental = listingDetails.pricePerDay * diff;
    const serviceCharge = 18 / 100 * rental;
    const total = rental + serviceCharge;

    // const priceSummary = {
    //   rentPerDay: listingDetails?.pricePerDay,
    //   totalRent: rental,
    //   days: diff,
    //   serviceCharge,
    //   payableRent: total,
    // }

    async function onClickReserve() {

      const startDate = selectedDate.startDate?.format(DATE_FORMAT);
      const endDate = selectedDate.endDate?.format(DATE_FORMAT);
      if(!startDate || !endDate){
        toast.error('Please select start and end date')
        return
      }   

      for(let i = 0; i < diff; i++){
        const date = moment(startDate).add(i,'days').format(DATE_FORMAT)
        if(slotMap[date] == 0){
          toast.error('This adboard is not available for booking on selected date')
          return
        }
      }

      navigate('/campaigns/new?adboardId='+id+'&startDate='+selectedDate.startDate?.format(DATE_FORMAT)+'&endDate='+selectedDate.endDate?.format(DATE_FORMAT))
      // setLoading(true);
    }

    async function onClickToggleCart(){
      if(data?.adBoard){
        fireEvent(!isCartAdded ? 'add_to_cart' : 'remove_from_cart',{
          ecommerce: {
            currency: "INR",
            value: data.adBoard.pricePerDay,
            items: [
              mapAdboardToGtagItem(data.adBoard,{
                startDate:startDate.toISOString(),
                endDate:endDate.toISOString(),
              })
            ]
          }
        })
      }
      id && toggleFromCart(id)
    }

    if (!listingDetails) return null;
    const isBookingDisabled = (listingDetails.workspace.type == WorkspaceType.TEST && window.location.hostname == 'www.adboardbooking.com');

    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-3xl font-semibold">
            {formatPrice(listingDetails.pricePerDay)}
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              /day
            </span>
          </span>
          {/* <StartRating /> */}
        </div>

        {/* FORM */}
        <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
          <StayDatesRangeInput
            slotMap={slotMap}
            adsPerLoop={data.adBoard?.adsPerLoop||1}
            blockedDates={blockedDates}
            wrapClassName="divide-x divide-neutral-200 dark:divide-neutral-700 !grid-cols-1 sm:!grid-cols-2"
            onChange={(date) => setSelectedDate(date)}
            fieldClassName="p-3"
            defaultValue={selectedDate}
            anchorDirection={"right"}
            className="nc-ListingStayDetailPage__stayDatesRangeInput flex-1"
          />
        </form>
       
        <div className="flex flex-col space-y-4">
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>{formatPrice(listingDetails?.pricePerDay)} x {diff} days</span>
            <span>{formatPrice(rental)}</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>GST (@{GST})</span>
            <span>{formatPrice(serviceCharge)}</span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>{formatPrice(total)}</span>
          </div>
          <div className="flex justify-between font-normal">
            <span>Pay now (platform fees)</span>
            <span><s>{formatPrice(FEES)}</s> {formatPrice(FEES_AFTER_DISCOUNT)} + GST</span>
          </div>
          <div className="flex justify-between font-normal">
            <span>Pay on booking approval</span>
            <span>{formatPrice(total)}</span>
          </div>
        </div>

        {/* SUBMIT */}
        {isCartAdded?<ButtonSecondary onClick={(e)=>{
          e.preventDefault();
          onClickToggleCart()}}>Added to cart
          <CheckIcon className="ml-2 w-4 h-4 font-bold" />
        </ButtonSecondary>:
          <ButtonSecondary onClick={(e)=>{
            e.preventDefault();
            onClickToggleCart()
          }}>Add to cart</ButtonSecondary>
        }
        {/* <ButtonSecondary loading={loading} onClick={onClickAddCart}>Add to cart</ButtonSecondary> */}
        <ButtonPrimary
          disabled={isBookingDisabled }
          loading={loading} onClick={onClickReserve}>Book now</ButtonPrimary>
        {(isBookingDisabled) && <span className="text-red-500">This adboard is not available for booking</span>}
      </div>
    );
  };

  return (
    <div
      className={`ListingDetailPage nc-ListingStayDetailPage ${className}`}
      data-nc-id="ListingStayDetailPage"
    >
      <Helmet>
        <meta property="og:description"
          content={`Book this ${data.adBoard?.title} digital billboard in ${data.adBoard?.city}. High-visibility spot with real-time analytics. View specs and availability on AdboardBooking.`} />
        <link rel="canonical" href={`https://www.adboardbooking.com/hoardings/${data.adBoard?.title}/${data.adBoard?.id}`} />
      </Helmet>
      {/* <PageView title={`Adboard | ${listingDetails.title}`}/> */}

      {/* SINGLE HEADER */}
      <>
        <header className="mt-4 container 2xl:px-14 rounded-md sm:rounded-xl">
          <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
            <div
              className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
              onClick={() => handleOpenModal(0)}
            >
              <NcImage
                containerClassName="absolute inset-0"
                className="object-cover w-full h-full rounded-md sm:rounded-xl"
                src={listingDetails.galleryImgs[0] || ""}
              />
             
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>
            {listingDetails.galleryImgs?.filter((_, i) => i >= 1 && i < 5).map((item, index) => (
              <div
                key={index}
                className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                }`}
              >
                <div className='aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5 w-full h-full'>
                  <img className=" image-fill" data-options="fit:cover" src={item+"-webimage"}/>
                </div>
                {/* OVERLAY */}
                <div
                  className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                  onClick={() => handleOpenModal(index + 1)}
                />
              </div>
            ))}

            <div
              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
              onClick={() => handleOpenModal(0)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                />
              </svg>
              <span className="ml-2 text-neutral-800 text-sm font-medium">
                Show all photos
              </span>
            </div>
          </div>
        </header>
        {/* MODAL PHOTOS */}
        <ModalPhotos
          imgs={[listingDetails.featuredImage,...listingDetails.galleryImgs]}
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocusIndex}
          uniqueClassName="nc-ListingStayDetailPage-modalPhotos"
        />
      </>

      {/* MAIn */}
      <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          {renderSection1()}
          {renderAbout()}
          {/* {renderAbout()} */}
          {/* {renderTargets()} */}
          {/* {renderIncludedSection()} */}
          {renderAvailibility()}
          {/* {renderHostInformation()} */}
          {/* {renderReviews()} */}
          {renderLocation()}
          {renderThingsToKnow()}
          <PublisherSection workspace={listingDetails.workspace}/>
          {/* <AdboardReview/> */}
          {/* <RuntimeHistory/> */}
        </div>

        {/* SIDEBAR */}
        <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-28">{renderSidebar()}</div>
        </div>
      </main>

      {/* STICKY FOOTER MOBILE */}
      {!isPreviewMode && <MobileFooterSticky 
        selectedDate={selectedDate} 
        slotMap={slotMap}
        setSelectedDate={setSelectedDate} 
        blockedDates={blockedDates}
        listingDetails={listingDetails} />}

      {/* OTHER SECTION */}
      {!isPreviewMode && (
        <div className="container py-24 lg:py-32">
          <SectionSubscribe2 className="pt-24 lg:pt-32" />
        </div>
      )}
    </div>
  );
};

export default AdboardDetailPage;
