import Heading2 from "@/components/Heading/Heading2";
import StayCard from "@/components/StayCard/StayCard";
import { CustomLink } from "@/data/types";
import React, { FC, useEffect } from "react";
import Pagination from "@/shared/Pagination/Pagination";
import TabFilters from "./TabFilters";
import { AdboardFragment } from "./types";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: AdboardFragment[];
}

// const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);

const DEMO_PAGINATION: CustomLink[] = [
  {
    label: "1",
    href: "#",
  }
];

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  // data = DEMO_DATA,
}) => {

  const [data,setData] = React.useState<AdboardFragment[]>([]);

  useEffect(()=>{
    setData([])
  },[])

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2 />

      <div className="mb-8 lg:mb-11">
        <TabFilters />
      </div>
      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {data.map((stay) => (
          <StayCard key={stay.id} data={stay as any} />
        ))}
      </div>
      <div className="flex mt-16 justify-center items-center">
        <Pagination pagination={DEMO_PAGINATION}/>
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
