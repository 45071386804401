import React, { FC } from "react";
import NcImage from "@/shared/NcImage/NcImage";
import rightImgDemo from "@/images/BecomeAnAuthorImg.png";

export interface SectionBecomeAnAuthorProps {
  className?: string;
  rightImg?: string;
}

const features = {
  "services":  [
    {
      "title": "Adboard Screen Management",
      "description": "Seamless control and scheduling."
    },
    {
      "title": "Live Streaming",
      "description": "Engage your audience with live content."
    },
    {
      "title": "Marketplace Access",
      "description": "Reach a wider audience with our commission-based marketplace."
    },
    {
      "title": "Realtime Billboard Monitoring",
      "description": "Monitor your billboards live with camera streaming and track video playcount metrics."
    }
  ]
}

const SectionBecomeAnPublisher: FC<SectionBecomeAnAuthorProps> = ({
  className = "",
  rightImg = rightImgDemo,
}) => {
  return (
    <div
      className={`nc-SectionBecomeAnAuthor relative flex flex-col lg:flex-row items-center  ${className}`}
      data-nc-id="SectionBecomeAnAuthor"
    >
      <div className="flex-shrink-0 mb-16 lg:mb-0 lg:mr-10 lg:w-2/5">
        {/* <Logo className="w-40" /> */}
        <h2 className="font-semibold text-3xl sm:text-4xl mt-6 sm:mt-11">
          Why onboard with us?
        </h2>
        <ul className="space-y-5 mt-8">
          {features.services.map(feature=>{
            return   <li className="space-y-4">
              {/* <Badge name={feature.tag} /> */}
              <span className="block text-xl font-semibold">
                {feature.title}
              </span>
              <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                {feature.description}
              </span>
            </li>
          })}
        
        </ul>
        {/* <Link to="/publisher">
          <ButtonPrimary className="mt-6 sm:mt-11">
          List your screen here
          </ButtonPrimary>
        </Link> */}
      </div>
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
    </div>
  );
};

export default SectionBecomeAnPublisher;
