import {
  gql,
  LazyQueryHookOptions,
  MutationHookOptions,
  QueryHookOptions,
  useLazyQuery,
  useMutation,
  useQuery
} from '@apollo/client';
import { useApolloClient } from '@/context/ApolloClientContext';

import { Chain, MapType, ModelTypes, ValueTypes, Zeus } from '@/generated/zeus';

type query_root = ModelTypes['Query']; 
type mutation_root = ModelTypes['Query'];

export function useTypedQuery<Q extends ValueTypes['Query']>(
  query: Q,
  options?: QueryHookOptions<MapType<query_root, Q,{}>, Record<string, any>>,
) {
  return useQuery<MapType<query_root, Q,{}>>(gql(Zeus('query',query)), {
    ...options,
    client: useApolloClient()?.typeClient,
    fetchPolicy: 'cache-and-network'
  });
}

export function useTypedMutation<Q extends ValueTypes['Mutation']>(
  mutation: Q,
  options?: MutationHookOptions<MapType<mutation_root, Q,{}>, Record<string, any>>,
) {
  return useMutation<MapType<mutation_root, Q,{}>>(gql(Zeus('mutation',mutation)),{
    ...options,
    client: useApolloClient()?.typeClient
  });
}

export function useTypedLazyQuery<Q extends ValueTypes['Query']>(
  query: Q,
  options?: LazyQueryHookOptions<MapType<query_root, Q,{}>, Record<string, any>>,
) {
  return useLazyQuery<MapType<query_root, Q,{}>>(gql(Zeus('query',query)), {
    ...options,
    client: useApolloClient()?.typeClient
  });
}

export const chain = Chain(import.meta.env.VITE_PUBLIC_API_URL+"/api/typegraphql",{
  headers:{
    "apollographql-client-name":"adboard-booking-web",
    "content-type": 'application/json',
    // authorization: `Bearer ${sessionStorage.getItem('token')}`
  }
});

const queryChain = chain('query');
const mutationChain = chain('mutation');

export const query:typeof queryChain = function (...args){
  const chain = Chain(import.meta.env.VITE_PUBLIC_API_URL+"/api/typegraphql",{
    headers:{
      "content-type": 'application/json',
      "apollographql-client-name":"adboard-booking-web",
      authorization: `Bearer ${sessionStorage.getItem('token')}`
    }
  });
  //@ts-ignore
  return chain('query')(...args);
}

export const mutation:typeof mutationChain = function (...args){
  const chain = Chain(import.meta.env.VITE_PUBLIC_API_URL+"/api/typegraphql",{
    headers:{
      "apollographql-client-name":"adboard-booking-web",
      "content-type": 'application/json',
      authorization: `Bearer ${sessionStorage.getItem('token')}`
    }
  });
  return chain('mutation')(...args);
}
