import { Popover, Transition } from "@headlessui/react";
import { useCartContext } from "@/context/CartContext";
import { FC, Fragment, useEffect } from "react";
import ButtonThird from "@/shared/Button/ButtonThird";
import ButtonPrimary from "@/shared/Button/ButtonPrimary";
import { query } from "@/hooks/useZeus";
import { ShoppingCart, Trash2 } from "lucide-react";
import { Link } from "react-router-dom";
import { fireEvent, titleToUrl } from "@/lib/utils";

interface Props {
  className?: string;
}

const CartDropdown: FC<Props> = ({ className = "" }) => {
  const {adBoards,addManyToCart,clearCart,removeFromCart} = useCartContext()
  
  useEffect(()=>{
    const witoutTitleAdboards = adBoards.filter(item=>!item.title);
    if(witoutTitleAdboards.length){
      query({
        adBoards:[{
          where:{
            id:{
              in: witoutTitleAdboards.map(item=>item.id)  
            }
          }
        },{
          id:true,
          title:true
        }]
      }).then(({adBoards})=>{
        addManyToCart(adBoards)
      })
    }
  },[adBoards])

  if(adBoards.length == 0){
    return null;
  }

  const params = new URLSearchParams(window.location.search);
  const startDate = params.get('startDate')||""
  const endDate = params.get('endDate')||""
  
  function handleClick(open:boolean){
    fireEvent('header.viewCart.'+(!open?'open':'close'))
  }

  return (
    <div className={className}>
     
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              onClick={()=>handleClick(open)}
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span className="flex gap-2 items-center ">
                <ShoppingCart size={16}/>
                Cart ({adBoards.length})</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-96 max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="max-h-80 shadow-inner overflow-auto relative flex  flex-col px-5 py-6 space-y-5">
                    <ul className="">
                      {adBoards.map(item=><li className="py-2 pr-4 flex justify-between items-center border-b">
                        <Link onClick={close} to={"/hoardings/"+titleToUrl(item.title)+"/"+item.id}>
                          {item.title}
                        </Link>
                        <Trash2 size={18} onClick={()=>removeFromCart(item.id)} className="cursor-pointer"/>
                      </li>)}
                    </ul>
                  </div>
                  <div className=" border-t-neutral-600 p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird onClick={()=>{
                      close();
                      clearCart();
                    }} sizeClass="px-4 py-2 sm:px-5">
                      Clear
                    </ButtonThird>
                    <Link to={"/campaigns/new?startDate="+startDate+"&endDate="+endDate}>
                      <ButtonPrimary
                        onClick={close}
                        sizeClass="px-4 py-2 sm:px-5"
                      >
                      Create Campaign
                      </ButtonPrimary>
                    </Link>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default CartDropdown;
