import React, { useEffect, useState } from 'react'

export type AdboardFragment = {
  id: string,
  title?: string
}

type CartContextProps = {
  adBoards: AdboardFragment[],
  clearCart: () => void,
  addToCart: (adBoardId: string,title?:string) => void,
  removeFromCart: (adBoardId: string) => void,
  toggleFromCart: (adBoardId: string) => void,
  addManyToCart: (args:{id: string,title?:string}[]) => void,
  isInCart: (adBoardId: string) => boolean,
}

export const CartContext = React.createContext<CartContextProps>({} as CartContextProps);

export default function CartProvider(props: { children: React.ReactNode }) {

  const [adBoards, setAdboards] = useState<AdboardFragment[]>([]);

  async function addToCart(adBoardId: string,title?:string) {
    
    if (!adBoards.some(item=>item.id == adBoardId)) {
      if(adBoardId){
        setAdboards(prevAdboards =>[...prevAdboards, {id:adBoardId,title:title||""}]);
      }
    }
  }

  async function addManyToCart(args:{id: string,title?:string}[]) {
    
    const mergedAdboards:AdboardFragment[] = adBoards||[]
    for (const adBoard of args) {
      const existingAdboard = adBoards.find(item=>item.id == adBoard.id);
      if(!existingAdboard){
        mergedAdboards.push({
          id: adBoard.id,
          title: adBoard.title
        });
      }else{
        existingAdboard.title = adBoard.title;
      }
    }

    setAdboards(mergedAdboards);
    
  }

  function removeFromCart(adBoardId: string) {
    if (adBoards.some(item=>item.id == adBoardId)) {
      setAdboards(adBoards.filter(item => item.id != adBoardId));
    }
  }

  useEffect(()=>{
    const cart = localStorage.getItem('cart');
    if(cart) {
      const {adBoards} = JSON.parse(cart) as {adBoards: AdboardFragment[]};
      setAdboards(adBoards.filter(item=>item.id));
    }
  },[])
  
  useEffect(()=>{
    localStorage.setItem('cart', JSON.stringify({adBoards}));
  },[adBoards])

  function clearCart() {
    localStorage.setItem('cart', JSON.stringify({adBoards:[]}));
    setAdboards([]);
  }

  function toggleFromCart(adBoardId: string) {
    if (adBoards.some(item=>item.id == adBoardId)) {
      removeFromCart(adBoardId);
    } else {
      addToCart(adBoardId);
    }
  }
  function isInCart(adBoardId: string) {
    return adBoards.some(item=>item.id == adBoardId);
  }

  return (
    <CartContext.Provider value={{ adBoards, addManyToCart,addToCart, removeFromCart,clearCart,toggleFromCart,isInCart}}>
      {props.children}
    </CartContext.Provider>
  )
}

export const useCartContext = () => React.useContext(CartContext);
