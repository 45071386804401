
import { LazyQueryHookOptions, MutationHookOptions, QueryHookOptions, TypedDocumentNode, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useApolloClient } from "@/context/ApolloClientContext";

export function usePMutation<S,T>(args:TypedDocumentNode<S, T>, options?:MutationHookOptions<S,T>) {
  return useMutation(args,{
    ...options,
    client: useApolloClient()?.typeClient
  });
}

export function usePQuery<S,T>(args:TypedDocumentNode<S, T>, options?:QueryHookOptions<S,T>) {
  return useQuery(args,{
    ...options,
    client: useApolloClient()?.typeClient
  });
}

export function usePLazyQuery<S,T>(args:TypedDocumentNode<S, T>, options?:LazyQueryHookOptions<S,T>) {
  return useLazyQuery(args,{
    ...options,
    client: useApolloClient()?.typeClient
  });
}